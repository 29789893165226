import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import {
  AUTH_FIND_PASSWORD_ACTION,
  AUTH_FIND_PASSWORD_GET_NUMBER_ACTION,
  AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
} from '@constants/actionNames';
import Completed from '@containers/UserInfoSetting/EditDormantAccountModal/Completed';
import ResetAccount from '@containers/UserInfoSetting/EditDormantAccountModal/ResetAccount';
import { useReduxData } from '@hooks/useReduxData';

export type TCurrentStep = 'RESET' | 'COMPLETED';

type TEditDormantAccountModalProps = {
  setIsOpenEditDormantAccountModal: () => void;
  isOpenEditDormantAccountModal: boolean;
};

function EditDormantAccountModal({
  isOpenEditDormantAccountModal,
  setIsOpenEditDormantAccountModal,
}: TEditDormantAccountModalProps) {
  const [isShowAuthNumberInput, setIsShowAuthNumberInput] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('RESET');
  const { clearData: clearFindPasswordData } = useReduxData(AUTH_FIND_PASSWORD_ACTION);
  const { clearData: clearFindPasswordGetAuthNumberData } = useReduxData(AUTH_FIND_PASSWORD_GET_NUMBER_ACTION);
  const { clearData: clearFindPasswordIsExistId } = useReduxData(AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION);

  useEffect(() => {
    if (!isOpenEditDormantAccountModal) {
      clearFindPasswordData();
      clearFindPasswordGetAuthNumberData();
      clearFindPasswordIsExistId();
      setCurrentStep('RESET');
      setIsShowAuthNumberInput(false);
    }
  }, [isOpenEditDormantAccountModal]);

  return (
    <Modal headerText="계좌 정보 변경" hide={setIsOpenEditDormantAccountModal} isShown={isOpenEditDormantAccountModal}>
      <>
        {currentStep === 'RESET' && (
          <ResetAccount
            isShowAuthNumberInput={isShowAuthNumberInput}
            setCurrentStep={setCurrentStep}
            setIsShowAuthNumberInput={setIsShowAuthNumberInput}
          />
        )}
        {currentStep === 'COMPLETED' && (
          <Completed setCurrentStep={setCurrentStep} setIsOpenEditDormantAccountModal={setIsOpenEditDormantAccountModal} />
        )}
      </>
    </Modal>
  );
}

export default EditDormantAccountModal;
