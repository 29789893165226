import Button from '@components/form/Button';
import styled from '@emotion/styled';

// export const SignUpButton = styled(Button)`
//   height: 36px;
//   border-radius: 60px;
//   padding: 2px 22px 0 22px;
//   border: 1px solid #5b5b5b;
//   background: #fff;
//   color: #121212;
//   font-weight: 400;
//   font-size: 14px;
// `;

export const FindButton = styled(Button)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  border: 0;
  background: transparent;
  color: #767676;
`;

export const LoginButton = styled(Button)`
  padding: 22px 209px 23px 209px;
`;

export const SignUpButton = styled(Button)`
  padding: 22px 209px 23px 209px;
  background-color: #effd60;
  color: #191919;
`;
