import React, { Dispatch, SetStateAction } from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import { EditButtonModal } from '@components/molecules/UserInfoSetting/Button';
import { Icon } from '@images/Icon';

import { TCurrentStep } from '.';

type TFinishProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  setIsOpenEditPhoneNumberModal: any;
  callback:any
};

function Finish({ setCurrentStep, setIsOpenEditPhoneNumberModal, callback=null }: TFinishProps) {
  const confirm = () => {
    // const redirectUri = routePath.login.path;
    setIsOpenEditPhoneNumberModal();
    setCurrentStep('INIT');
    if(callback){
      callback()
    }
  };

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="19px" type="TRANSPARENT" />
        <Row justify="CENTER">
          <Icon icon="iconComplete" style={{ width: '64px', height: '64px' }} />
        </Row>
        <HDivider margin="14px" type="TRANSPARENT" />
        <Row direction="column" justify="CENTER">
          <Text textType="DESCRIPTION">휴대전화 번호 변경이 완료되었습니다.</Text>
          <Text textType="DESCRIPTION">확인 버튼을 누르면 팝업이 닫힙니다.</Text>
        </Row>
        <HDivider margin="18px" type="TRANSPARENT" />
        <EditButtonModal
          onClick={() => {
            confirm();
          }}
        >
          확인
        </EditButtonModal>
      </Form>
    </Layout>
  );
}

export default Finish;
