import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import styled from '@emotion/styled';
import { Button, ButtonGroup, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Privacy from './Privacy';
import TermsOfUse from './TermsOfUse';
import TermsOfPayUse from './TermsOfPayUse';
import { useParams } from 'react-router';

const TermsButtonGroup = styled(ButtonGroup)`
  & button {
    border-radius: 0;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 40px;
  }
`;

const Terms = () => {
  const [curCategory, setCategory] = useState<'Privacy' | 'TermsOfUse' | 'TermsOfPayUse'>('Privacy');

  const { type } = useParams();

  useEffect(() => {
    switch (type) {
      case 'privacy':
        setCategory('Privacy');
        return;
      case 'terms-of-use':
        setCategory('TermsOfUse');
        return;
      case 'terms-of-pay-use':
        setCategory('TermsOfPayUse');
        return;
      default:
        setCategory('Privacy');
        return;
    }
  }, []);

  const getButtonStyle = (id: string) => {
    const style = {
      borderColor: '#707070',
      color: '#191919',
    };

    if (id === curCategory) {
      style.backgroundColor = '#EDEDED';
    }
    return style;
  };

  return (
    <>
      <MainHeader logo="logoW" />
      <Stack direction="column" justifyContent="center" alignItems="center" paddingY="200px" bgcolor="#fff" color="#191919">
        <Stack direction="column" alignItems="center" style={{ minWidth: '1024px', maxWidth: '1024px' }}>
          <Typography fontSize="50px" fontWeight="bold">
            약관 및 개인정보 보호
          </Typography>
          <TermsButtonGroup variant="outlined" style={{ margin: '80px 0' }}>
            <Button
              sx={getButtonStyle('Privacy')}
              onClick={() => {
                setCategory('Privacy');
              }}
            >
              개인정보처리방침
            </Button>
            <Button
              sx={getButtonStyle('TermsOfUse')}
              onClick={() => {
                setCategory('TermsOfUse');
              }}
            >
              이용약관
            </Button>
            <Button
              sx={getButtonStyle('TermsOfPayUse')}
              onClick={() => {
                setCategory('TermsOfPayUse');
              }}
            >
              유료서비스 이용약관
            </Button>
          </TermsButtonGroup>
          {curCategory === 'Privacy' && <Privacy />}
          {curCategory === 'TermsOfUse' && <TermsOfUse />}
          {curCategory === 'TermsOfPayUse' && <TermsOfPayUse />}
        </Stack>
      </Stack>
      <MainFooter />
    </>
  );
};

export default Terms;
