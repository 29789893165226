import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';

import styled from '@emotion/styled';

export type TLabelParams = any;

const StyledLabel = styled.label<TLabelParams>`
  min-width: 37px;
  height: 17px;

  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;

  color: #5b5b5b;
`;

const Label = ({ children, required = false, ...rest }: TLabelParams) => (
  <StyledLabel {...rest} required={required}>
    <Stack sx={{ fontSize: '14px', fontFamily: 'Pretendard !important' }} direction="row" spacing={0.5} alignItems="center">
      <span>{children}</span>
      {required && (
        <Typography
          sx={{
            color: 'red',
            fontFamily: 'Pretendard',
            fontWeight: 'bold',
          }}
        >
          *
        </Typography>
      )}
    </Stack>
  </StyledLabel>
);

export default Label;
