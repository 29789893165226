
import Text from '@components/form/Text';
import { routePath } from '@constants/path';
import { ProfileContext } from '@containers/App';
import { callProfileData, sendHttpGet } from '@utils/api';
import { getAccessToken, getAuthInSessionStorage } from '@utils/auth';
import history from '@utils/history';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import date from 'date-and-time';
import HDivider from '@components/form/HDivider';
import Row from '@components/form/Row';
import VDivider from '@components/form/VDivider';
import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import { ShowMoreButton } from '@components/molecules/ProfileManagement/Button';
import { ProfileManagementLayout } from '@components/molecules/ProfileManagement/Layout';
import {
  ProfileMgmtDescText,
  ProfileMgmtDescTitle,
  ProfileMgmtReleaseDateText,
  ProfileMgmtSingerTitleText,
} from '@components/molecules/ProfileManagement/Text';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ProfileList from './ProfileList';
import { noImage } from '@images/png';
import { Avatar, Skeleton, Stack, styled } from '@mui/material';


const StyledSkeleton = styled(Skeleton)`
  background: #444;
`


// <StyledSkeleton variant='rounded' width={320} height={320} />
//   <StyledSkeleton variant='text' width={300} />

function ProfileManagement(): ReactElement {
  const {
    curProfileContext,
    changeProfile,
    refreshProfileData,
  } = useContext(ProfileContext);
  // 프로필 선택하면 로컬데이터에 저장한다.

  const [profileInfo, setProFileInfo] = useState(null);
  const [profileArray, setProfileArray] = useState([]);

  async function getProfiles() {
    const profiles = await callProfileData();

    refreshProfileData(profiles);
    if (profiles?.length > 0) {
      changeCurrentProfile(profiles[0]);
    }

  }

  const changeCurrentProfile = (newPrf: any) => {

    changeProfile(newPrf);
    //console.log({curProfileContext})
  };

  useEffect(() => {
    getProfiles();

  }, []);
  useEffect(() => {
    const auth = getAccessToken();
    if (!auth) {

      const redirectUri = routePath.login.path;
      history.push(redirectUri);
    }
  }, []);

  // 우측 리스트에서 선택한 프로필 처리
  const [uc_selectedProfile, uf_setSelectedProfile] = useState({});
  useEffect(() => {
    uf_setSelectedProfile(curProfileContext);
  }, [curProfileContext]);

  // @ts-ignore
  return (
    <>
      <MainHeader logo='logoW' />
        <ProfileManagementLayout>
          <Stack spacing={10} sx={{maxWidth:'1200px'}} >
            <Stack direction='row' spacing={10}>
              {/* 프로필 대형 아이콘 */}
              <Avatar
                src={uc_selectedProfile.artist_image && uc_selectedProfile.artist_image.length > 0 ? uc_selectedProfile.artist_image[uc_selectedProfile.artist_image.length - 1].image_url : noImage}
                sx={{
                  width: '240px',
                  height: '240px',
                }} />

              <Row direction='column' justify='FLEX_START'>
                <Row direction='row' justify='CENTER'>

                  <ProfileMgmtSingerTitleText>{uc_selectedProfile.artist_name}</ProfileMgmtSingerTitleText>
                  <VDivider margin='12px' type='TRANSPARENT' />
                  <FiberManualRecordIcon sx={{color:'#FFFFFF', fontSize:10}}/>
                  {/* <Row justify='CENTER' style={{ */}
                  {/*   background: '#777', */}
                  {/*   width: '7px', */}
                  {/*   height: '7px', */}
                  {/*   borderRadius: '50%', */}
                  {/* }} /> */}
                  <VDivider margin='12px' type='TRANSPARENT' />
                  <ProfileMgmtSingerTitleText
                    style={{
                      width: '302px',
                      textOverflow: 'ellipsis',
                      display: 'inlineBox',
                      overflow: 'hidden',
                    }}
                  >
                    {!uc_selectedProfile.languages ? (<StyledSkeleton variant='rounded' width={200} height={50} /> ):
                    uc_selectedProfile.languages && uc_selectedProfile.languages.length > 0 ? uc_selectedProfile.languages[0].name : 'No  name'}
                  </ProfileMgmtSingerTitleText>
                </Row>
                <HDivider margin='12px' type='TRANSPARENT' />
                {!uc_selectedProfile.created_at ? (<StyledSkeleton variant='rounded' width={140} height={20} /> ) :(
                <ProfileMgmtReleaseDateText>

                  {date.format(new Date(uc_selectedProfile.created_at), 'YYYY.MM.DD')}</ProfileMgmtReleaseDateText>)}
              </Row>
            </Stack>

            <Stack spacing={4}>
              <ProfileMgmtDescTitle>아티스트 소개글</ProfileMgmtDescTitle>

              <ProfileMgmtDescText className='close'>
                {uc_selectedProfile.biography ?? `소개글이 없습니다.\n소개글을 등록하여 주십시오.`}
                <ShowMoreButton style={{ display: 'none' }}>더보기</ShowMoreButton>
              </ProfileMgmtDescText>
            </Stack>
          </Stack>

          <ProfileList
            selectedProfile={uc_selectedProfile}
            setSelectedProfile={uf_setSelectedProfile}
                       profileRecords={profileArray} />

        </ProfileManagementLayout>

      <MainFooter />
    </>
  );
}

export default ProfileManagement;
