import Row from '@components/form/Row';
import { routePath } from '@constants/path';
import { AlbumContext } from '@containers/App';
import Membership from '@containers/Login/Membership';
import { BackdropContext } from '@hooks/usePopupMessage';
import { Box, Card, Grid, Typography } from '@mui/material';
import { $mixtapeGetUser, $mixtapeUserSeq, getAccessToken, getUserSeq, loadLocalStorage } from '@utils/auth';
import history from '@utils/history';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import { TEXT } from 'react-dnd-html5-backend/dist/NativeTypes';

import AlbumList from './AlbumList';
import ReleaseFirstAlbum from './ReleaseFirstAlbum';
import SignUpMembershipForRelease from './SignUpMembershipForRelease';
import TermsPopup from '@containers/Portal/popup/TermsPopup';
import ExcludedServiceCountries from './ConfirmModal/ExcludedServiceCountries';

///[TODO] 로그인 상태값과 멤버십 확인해야함
function AlbumManagement(): ReactElement {
	const [isExistFirstAlbum, setIsExistFirstAlbum] = useState(-1);
	const [isMembership, setIsMembership] = useState(false);
	const [uc_loadingNow, uf_setLoadingNow] = useState(true);
	const sessionUserInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')!) : null;
	const { ctf_sendHttpGet, ctf_sendHttpPost } = useContext(BackdropContext);

	const getAlbums = async () => {
		const userSeq = $mixtapeUserSeq();
		if (!userSeq) {
			history.push(routePath.login.path);
			return;
		}
		const res = await ctf_sendHttpGet('/albums', {
			limit: 1,
			page: 1,
			user: userSeq
		});

		if (res.error || res.data.data.length === 0) {
			setIsExistFirstAlbum(0);
		} else {
			setIsExistFirstAlbum(1);
		}
	};

	useEffect(() => {
		const auth = getAccessToken();
		if (!auth) {
			history.push(routePath.login.path);
			return;
		}
		// setIsMembership(userInfo.is_membership === 1);
		getAlbums();
	}, []);

	const { ctc_albumInfo, ctf_setAlbumInfo } = useContext(AlbumContext);

	useEffect(() => {
		if (ctc_albumInfo.refresh === true || ctc_albumInfo.refreshDetail === true) {
			getAlbums();
		}
	}, [ctc_albumInfo]);
	return (
		<>
			<MainHeader logo="logoW" />

			<>
				{/* {!isMembership && <SignUpMembershipForRelease />} */}
				{(isExistFirstAlbum === -1 || isExistFirstAlbum === 1) && <AlbumList />}
				{isExistFirstAlbum === 0 && <ReleaseFirstAlbum />}
			</>

			<MainFooter />
			{/* {sessionUserInfo && sessionUserInfo.is_membership === 1 && <TermsPopup />} */}
		</>
	);
}

export default AlbumManagement;
