import React, { Dispatch, SetStateAction } from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import { ConfirmButton } from '@components/molecules/FindId/Button';
import { routePath } from '@constants/path';
import history from '@utils/history';

import { TCurrentStep } from '.';

type TCantFoundIdProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  setIsOpenFindIdModal: any;
};

function CantFoundId({ setCurrentStep, setIsOpenFindIdModal }: TCantFoundIdProps) {
  const confirm = () => {
    const redirectUri = routePath.login.path;
    setIsOpenFindIdModal();
    setCurrentStep('INIT');
    history.push(redirectUri);
  };

  return (
    <Layout>
      <Form>
        <HDivider margin="48px" type="TRANSPARENT" />
        <Row justify="CENTER">
          <Text textType="DESCRIPTION">입력하신 정보와 일치하는 아이디가 없습니다.</Text>
        </Row>
        <HDivider margin="46px" type="TRANSPARENT" />
        <ConfirmButton
          onClick={() => {
            confirm();
          }}
        >
          확인
        </ConfirmButton>
      </Form>
    </Layout>
  );
}

export default CantFoundId;
