import { storageNullCheck } from '@utils/data';
import axios from 'axios';

function setAuthInSessionStorage(auth: any) {
  sessionStorage.setItem('auth', auth);
}

function setAuthInLocalStorage(auth: any) {

  localStorage.setItem('auth', auth);
}

export function saveLocalStorage(key: string, data:any) {
  localStorage.setItem(key,  JSON.stringify(data));
}

export function loadLocalStorage(key: string) {
  const stringifiedAuth = localStorage.getItem(key);
  return storageNullCheck(stringifiedAuth) ? JSON.parse(stringifiedAuth as string) : null;
}


///
/// 사이트내에서 사용할 글로벌 변수 초기화
/// 전역변수 사용은 각 필드값을 쉽게 넣고 빼기 위함임 --> 세션스토리지를 사용하면 번거로운 작업이 병행되어 어플리케이션에 로드가 갈수 있음
///
export function $mixtapeInit(){
  window.$mixtape = {$user:{}}
}

export function $mixtapeSetUser(userRecord){
  sessionStorage.setItem('userInfo', JSON.stringify(userRecord));
  const sessUserInfoString = sessionStorage.getItem('userInfo')
  window.$mixtape.$user = userRecord


}



///
/// 전역변수는 새로고침이나 페이지 이동을 하면 데이터가 날라감
/// 세션변수에 저장되어있는 백업본을 리턴시키고 그것을 전역변수에 다시 세팅한다.
///
export function $mixtapeGetUser(){
  let userInfo = {}
  if(window.$mixtape && !!window.$mixtape.$user.seq){
    userInfo = window.$mixtape.$user
  }else{
    const sessUserInfoString = sessionStorage.getItem('userInfo')
    userInfo = !!sessUserInfoString ? JSON.parse(sessUserInfoString + '') : {}
    $mixtapeInit()
    window.$mixtape.$user = userInfo
  }
  return userInfo
}

/**
 * @return {{
 *  access_token: string;
 *  token_type: string;
 *  refresh_token: string;
 *  expires_in: number;
 *  scope: string;
 *  userId: string;
 * } | null} auth 계정 인증정보
 */
export function getAuthInSessionStorage() {
  const stringifiedAuth = sessionStorage.getItem('auth');
  return storageNullCheck(stringifiedAuth) ? JSON.parse(stringifiedAuth as string) : null;
}
/**
 * @return {{
 *  access_token: string;
 *  token_type: string;
 *  refresh_token: string;
 *  expires_in: number;
 *  scope: string;
 *  userId: string;
 * } | null} auth 계정 인증정보
 */
export function getAuthInLocalStorage() {
  const stringifiedAuth = localStorage.getItem('auth');
  return storageNullCheck(stringifiedAuth) ? JSON.parse(stringifiedAuth as string) : null;
}

/**
 * @param {{
 *  access_token: string;
 *  token_type: string;
 *  refresh_token: string;
 *  expires_in: number;
 *  scope: string;
 *  userId: string;
 * }} auth 계정 인증정보
 * @param {boolean} doesRememberThis 기억할지 여부
 */
export function setAuth(auth: any, doesRememberThis?: boolean) {
  if (doesRememberThis) {
    setAuthInLocalStorage(JSON.stringify(auth));
  } else {
    setAuthInSessionStorage(JSON.stringify(auth));
  }
}

function getRefreshTokenInSessionStorage() {
  return getAuthInSessionStorage()?.refresh_token;
}

function getRefreshTokenInLocalStorage() {
  return getAuthInLocalStorage()?.refresh_token;
}

/**
 * @return {null | string}
 */
export function getRefreshToken() {
  return getRefreshTokenInSessionStorage() || getRefreshTokenInLocalStorage();
}
function getUserIdInSessionStorage() {
  return getAuthInSessionStorage()?.userId;
}


export function saveUserId(userId:any){
  localStorage.setItem('userId', userId);
}

export function loadUserId(){
  localStorage.getItem('userId');
}

///
/// 로그인후 받은 사용자 정보에서 user seq값을 가져온다.
///
export function getUserSeq() {
    return getAuthInSessionStorage()?.seq
}

export function $mixtapeUserSeq(){
  return $mixtapeGetUser()?.seq
}

function getUserIdInLocalStorage() {
  return getAuthInLocalStorage()?.userId;
}
function getAccessTokenInSessionStorage() {
  return getAuthInSessionStorage()?.access_token;
}

function getAccessTokenInLocalStorage() {
  return getAuthInLocalStorage()?.access_token;
}
function getUuidInSessionStorage() {
  return getAuthInSessionStorage()?.uuid;
}

function getUuidInLocalStorage() {
  return getAuthInLocalStorage()?.uuid;
}

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      // eslint-disable-next-line func-names
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

/**
 * @return {null | string}
 */
export function getUserId() {
  return getUserIdInSessionStorage() || getUserIdInLocalStorage();
}
/**
 * @return {null | string}
 */
export function getAccessToken() {
  return getAccessTokenInSessionStorage() || getAccessTokenInLocalStorage();
}
/**
 * @return {null | string}
 */
export function getUuid() {
  return getUuidInSessionStorage() || getUuidInLocalStorage();
}

/**
 * @return {null | int}
 */
export function getTokenExp() {
  const token = getAccessToken();
  return token ? parseJwt(token).exp : null;
}

export function removeAuth() {
  sessionStorage.clear()
  localStorage.clear();

}
