type TLifeTimeKeys = {
  [key: string]: Array<string>;
};

export default function getLifeTimeKeys(reduxActionName: string): Array<string> {
  const lifeTimeKeysObj: TLifeTimeKeys = {
    app: ['/'],
  };

  return lifeTimeKeysObj[reduxActionName] ?? [];
}
