import React, { Dispatch, SetStateAction } from 'react';

import { activateUser, logout } from '@api/mapper/User';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { GoLogoutButton, SetActivationButton } from '@components/molecules/DormantAccount/Button';
import { FoundIdBoxRow } from '@components/molecules/FindId/Row';
import { FundIdValueText } from '@components/molecules/FindId/Text';
import { AUTH_DORMANT_ACCOUNT_ACTION, AUTH_LOGIN_ACTION, AUTH_LOGOUT_ACTION } from '@constants/actionNames';
import { routePath } from '@constants/path';
import { useReduxData } from '@hooks/useReduxData';
import { useRequest } from '@hooks/useRequest';
import history from '@utils/history';

import { TCurrentStep } from '.';

type TFoundIdProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  setIsOpenDormantAccountModal: any;
};

function SetActivation({ setCurrentStep, setIsOpenDormantAccountModal }: TFoundIdProps) {
  const { data } = useReduxData(AUTH_LOGIN_ACTION);
  const { request: setActivationReq, resData: setActivationRes } = useRequest({
    reduxActionName: AUTH_DORMANT_ACCOUNT_ACTION,
    mapperFunc: activateUser,
  });
  const { request: logoutReq, resData: logoutRes } = useRequest({
    reduxActionName: AUTH_LOGOUT_ACTION,
    mapperFunc: logout,
  });

  const goLogout = (e: any) => {
    e.preventDefault();
    const redirectUri = routePath.login.path;
    setIsOpenDormantAccountModal();
    setCurrentStep('INIT');
    history.push(redirectUri);
    logoutReq();
  };

  const setActivation = (e: any) => {
    e.preventDefault();
    const redirectUri = routePath.login.path;
    setCurrentStep('COMPLETED');
    history.push(redirectUri);
    setActivationReq();
  };

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="12px" type="TRANSPARENT" />
        <FoundIdBoxRow direction="column" justify="CENTER">
          <Row direction="row" justify="CENTER">
            <FundIdValueText>{data.loginId ?? 'aa'}</FundIdValueText>
          </Row>
        </FoundIdBoxRow>
        <HDivider margin="15px" type="TRANSPARENT" />
        <Text textType="DESCRIPTION">회원님의 계정이 1년간 로그인하지 않아 휴면 계정으로 전환되었습니다.</Text>
        <HDivider margin="15px" type="TRANSPARENT" />
        <Text textType="DESCRIPTION">지금 휴면 상태를 해제 하시겠습니까?</Text>
        <HDivider margin="15px" type="TRANSPARENT" />
        <Text textType="DESCRIPTION">
          *mixtape.은 정보통신망법에 따라, 회원님의 소중한 개인정보를 보호 하기 위해 1년 동안 로그인 기록이 없는 계정을
          휴면계정으로 전환하고 개인정보를 별도 분리하여 보관합니다.
        </Text>
        <HDivider margin="8px" type="TRANSPARENT" />
        <Row justify="SPACE_BETWEEN">
          <GoLogoutButton onClick={goLogout} style={{ flex: 1 }}>
            로그아웃
          </GoLogoutButton>
          <VDivider margin="5px" type="TRANSPARENT" />
          <SetActivationButton onClick={setActivation} style={{ minWidth: '252px' }}>
            휴면상태를 해제합니다.
          </SetActivationButton>
        </Row>
      </Form>
    </Layout>
  );
}

export default SetActivation;
