import { $mixtapeGetUser } from '@utils/auth';
import React, { useState } from 'react';

import Modal from '@components/form/Modal';
import { Radio, Stack, Typography } from '@mui/material';
import MixButton from '@components/ui/MixButton';
import VDivider from '@components/form/VDivider';

type TChangeMembershipModalProps = {
  setIsOpenChangeMembershipModal: () => void;
  isOpenChangeMembershipModal: boolean;
  callback: (type: string) => void;
};

const ChangeMembershipModal = ({
  isOpenChangeMembershipModal,
  setIsOpenChangeMembershipModal,
  callback,
}: TChangeMembershipModalProps) => {
  const [membershipType, setMembershipType] = useState('Basic');
  const user = $mixtapeGetUser()
  return (
    <Modal
      modalStyle={{ width: '660px' }}
      closeButtonStyle={{ width: '24px', heigth: '24px' }}
      hide={setIsOpenChangeMembershipModal}
      isShown={isOpenChangeMembershipModal}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" padding="15px" gap="50px">
        <Typography fontSize="34px" fontWeight="bold">
          멤버십 변경
        </Typography>
        <Stack direction="row" width="540px" padding="40px 20px" borderRadius="12px" bgcolor="#F6F6F6">
          <Stack direction="column" gap="20px" flex="0 0 250px">
            <Stack direction="row" alignItems="center" gap="6px">
              <Radio
                style={{ padding: 0 }}
                checked={membershipType === 'Basic'}
                onChange={() => {
                  setMembershipType('Basic');
                }}
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                size="small"
              />
              <Typography fontSize="18px" fontWeight="bold">
                Basic
              </Typography>
            </Stack>
            <Typography fontSize="18px" lineHeight="30px" pl="26px">
              · 유통 수수료 0% <br />
              · 주 1회 발매 가능 <br />
              · 전 세계 음악 플랫폼, 소셜 발매 <br />
              · 발매 이후 2회 수정 가능 <br />· 트랙 수 최대 20개
            </Typography>
            <div style={{ paddingLeft: '26px' }}>
              <Typography fontSize="18px" fontWeight="bold">
                연 66,000원
              </Typography>
              <Typography fontSize="16px" color="#767676">
                (부가세 포함 월 5,500원)
              </Typography>
            </div>
            <span
              style={{
                width: '74px',
                height: '24px',
                padding: '8px 23px',
                marginLeft: '26px',
                borderRadius: '50px',
                background: '#EFFD60',
              }}
            >
              현재 멤버십
            </span>
          </Stack>
          <VDivider type="NONE" margin="20px" style={{ flex: '0 0 1px', background: ' #767676' }}></VDivider>
          <Stack direction="column" gap="20px" flex="0 0 250px">
            <Stack direction="row" alignItems="center" gap="6px">
              <Radio
                style={{ padding: 0 }}
                checked={membershipType === 'Pro'}
                onChange={() => {
                  setMembershipType('Pro');
                }}
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                size="small"
              />
              <Typography fontSize="18px" fontWeight="bold">
                Pro
              </Typography>
            </Stack>
            <Typography fontSize="18px" lineHeight="30px" pl="26px">
              · 유통 수수료 0% <br />
              · 주 3회 발매 가능 <br />
              · 전 세계 음악 플랫폼, 소셜 발매 <br />
              · YouTube OAC 신청 가능 <br />
              · Youtube Content ID 발급 요청 가능 (수수료 15%) <br/>
              · 믹스테이프 유튜브 채널 MV 업로드 <br/>
              · 발매 이후 무제한 수정 가능 <br />
              · 트랙 수 최대 30개 <br />
            </Typography>
            <div style={{ paddingLeft: '26px' }}>
              <Stack direction="row" gap="3px">
                { user.pro_yn === 'N'&& <Typography fontSize="18px" sx={{ textDecoration: 'line-through' }}> &nbsp;99,000원 &nbsp; </Typography>}

                <Typography fontSize="18px" fontWeight="bold" color="#FF3120">
                  { user.pro_yn === 'N' ? '연 88,000원' : '연 99,000원'}

                </Typography>
              </Stack>
              <Typography fontSize="16px" color="#767676">
                (부가세 포함 월 {  user.pro_yn === 'N' ?' 7,333' : '8,250'}원)
              </Typography>
            </div>
            {  user.pro_yn === 'N' &&
              <span
              style={{
                width: '52px',
                height: '24px',
                padding: '7px 33px',
                marginLeft: '26px',
                borderRadius: '50px',
                background: 'white',
                border: '1px solid #FF3120',
                fontWeight: 'bold',
                color: '#FF3120',
              }}
            >
              EVENT
            </span>

            }

          </Stack>
        </Stack>
        <Stack direction="row" gap="22px" mb="35px">
          <MixButton
            onClick={() => {
              setIsOpenChangeMembershipModal();
            }}
            text="유지"
            butonType="OUTLINE"
            padding="22px 124px"
            bgColor="white"
            fontColor="black"
          />
          <MixButton
            onClick={() => {
              callback(membershipType);
            }}
            disabled={membershipType === 'Basic'}
            text="변경"
            padding="22px 124px"
            bgColor={membershipType === 'Basic' ? '#ccc' :undefined}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ChangeMembershipModal;
