import AlertModal from '@components/form/AlertModal';
import { getPaymentFailUrl } from '@config/env';
import { $mixtapeGetUser } from '@utils/auth';

import { startTossPayment } from '@utils/toss';
import React, { useState } from 'react';

import Checkbox from '@components/form/Checkbox';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';

import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';

import { SignUpMembershipButton } from '@components/molecules/SignUp/Button';
import { SignUpLayout } from '@components/molecules/SignUp/Layout';
import MembershipBox from '@components/molecules/SignUp/MembershipBox';
import { MembershipDescText, MembershipTitleText } from '@components/molecules/SignUp/Text';

import { Icon } from '@images/Icon';

import { TermsBox2 } from '@components/molecules/SignUp/TermsBox';
import { Box, Divider, Stack, Typography } from '@mui/material';

import MixSwiper from '@components/ui/MixSwiper';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import styled from '@emotion/styled';
import { useHistory, useParams } from 'react-router';

const MembershipCard = styled.div<{ isBasic: boolean }>`
  display: flex;
  flex-direction: column;
  width: 460px;
  box-shadow: 0px 3px 20px #0000001a;

  .card-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 30px 0;
    background: ${(props) => (props.isBasic ? '#88ebff' : '#EFFD60')};
    text-align: center;

    .membership-name {
      font-size: 28px;
      font-weight: 600;
      color: #fff;
      text-shadow: 1px 2px 3px #0000005c;
    }

    .badge-container {
      display: flex;
      justify-content: center;

      span {
        display: inline-block;
        padding: 6px 20px;
        background: #331e1e1e;
        border-radius: 4px;
        color: #fff;
      }
    }

    .first-use {
      margin-bottom: 4px;
      font-size: 22px;
      font-weight: bold;
      color: #b760fc;
    }

    .price-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .price {
        font-size: 32px;
        font-weight: 600;
        color: #191919;
      }
      .year {
        font-size: 18px;
        color: #191919;
      }
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 40px;
    background: #f8f8fa;

    .title {
      font-size: 16px;
      font-weight: 600;
      color: #191919;
    }

    li {
      position: relative;
      padding-left: 26px;
      margin-bottom: 5px;
    }

    li::before {
      content: '✓';
      position: absolute;
      left: 0;
      color: ${(props) => (props.isBasic ? '#60e2fd' : '#A7B043')};
      font-size: 14px;
      font-weight: bold;
    }

    li:last-child {
      margin-bottom: 0;
    }

    .subscribe-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 18px;
      gap: 12px;
      width: 100%;
      padding: 20px 0;
      background: #191919;
      border-radius: 12px;
      font-size: 20px;
      font-weight: 600;
      color: #fff;
    }
  }
`;

const textStyle = {
  fontSize: '16px',
  lineHeight: '28px',
};

const pointTextStyle = {
  fontWeight: 'bold',
  color: '#B760FC',
};

const terms = `제1장\t총칙

제1조\t목적
mixtape 유료서비스 이용약관(이하 “본 약관”)은 회사가 회원에게 제공하는 유료서비스의 이용에 있어서 회사와 회원의 권리 및 의무 등에 관한 사항을 규정함을 목적으로 합니다.

제2조\t용어의 정의
① 본 약관에서 사용하는 용어의 뜻은 다음과 같습니다.
1. 유료서비스 : 플랫폼을 통해 서비스 명 “mixtape”으로 운영되는, 음악 창작자 및 음반 제작자를 위하여 그들이 플랫폼에 등록하는 콘텐츠를 DSP에게 공급하고 해당 음원의 사용료 정산을 대행하는 음원 유통∙정산 서비스를 의미합니다.
2. 유료이용계약 : 회원과 회사 간 유료서비스 이용에 관한 권리 및 의무를 정하기 위하여 체결되는 계약을 의미합니다. 즉, 유료이용계약은 본 약관에 따라 회원이 회사가 제공하는 유료서비스를 이용하기 위하여 회사와 체결한 계약을 의미합니다. 
3. 유료이용회원 : 유료이용계약 체결의 당사자인 회원을 의미합니다.
4. 발매 : 음원을 공중에게 공개하거나 발행하는 것을 의미합니다.
5. 발매수급자료 : 유통사업을 위하여 필요한 콘텐츠에 관한 각종 정보로서 음반원의 또는 음반원에 관한 아트워크, 가사, 소개자료, 사진, 이미지, 뮤직비디오, 가창자, 연주자, 창작자 등에 대한 정보를 의미합니다.
6. 음원 : 음을 디지털화하여 고정한 것을 의미합니다.
7. 콘텐츠 : 음원에 부수하거나 관련된 일체의 자료 및 정보로서, 음반원 자체, 음반원에 실린 개별 음, 아트워크, 뮤직비디오, 가사, 크레딧, 홍보물, 메타데이터 등을 의미합니다.
8. DSP : Digital Service Provider의 약어로서, 다양한 형태 및 방식으로 콘텐츠에 대한 서비스를 제공하는 자를 의미합니다.
9. YouTube CID : ‘YouTube Content ID’를 의미하고, 콘텐츠 소유자가 YouTube에서 자신의 저작물을 보호하고, 이익을 창출하도록 도와주는 도구를 의미합니다.
② 제1항에 규정되지 아니한 사항은 mixtape 이용 약관에서 정의된 용어의 의미를 따르며, 위 약관에도 규정되지 아니한 사항은 업계 통상의 관행 및 관련 법령에서 정한 의미를 따릅니다.

제3조\t적용 약관 등
유료서비스 관련하여 본 약관에서 정하지 아니한 사항에 관하여는 ‘mixtape 이용 약관’ 및 관련 법령을 따릅니다. 이에 따라 유료서비스 관련하여 ‘mixtape 이용 약관’이 적용되는 경우 “약관 등”에는 본 약관이 포함되는 것으로 봅니다.


제2장\t유료이용계약의 체결

제4조\t유료이용계약의 신청
유료이용계약 체결을 희망하는 회원(이하 “청약자”)은 본 약관의 내용에 동의하고 유료이용계약의 체결을 위하여 요청되는 필수 정보를 기재한 후 “청약”, “신청”, “구독” 또는 이에 준하는 플랫폼 상의 버튼을 클릭하는 등의 방법으로 유료이용계약을 청약합니다.

제5조\t청약의 승낙, 유보 또는 거절
① 회사는 제4조에 따른 청약자의 유료이용계약 청약에 대하여 승낙함을 원칙으로 합니다. 
② 회사는 제1항에도 불구하고 아래 각 호에 해당하는 경우에는 유료이용계약 청약에 대하여 해당 사유가 해소될 때까지 승낙을 유보하거나, 승낙을 거절할 수 있습니다.
1. 허위의 정보를 기재하거나 청약 시 요청된 필수 정보를 기재하지 아니한 경우
2. 타인의 개인정보를 무단으로 이용하여 청약한 경우
3. 만 19세 미만의 미성년자가 가입신청한 경우
4. ‘mixtape 이용 약관’에 따라 청약자의 회원가입이 유보 또는 거절된 경우
5. 청약자가 대한민국 외의 지역에 거주 중인 경우
6. 법령을 위반하여, 또는 사회의 안녕질서 내지 미풍양속을 해할 수 있는 목적으로 청약한 경우
7. 악성 프로그램 또는 버그를 이용하거나 기타 플랫폼의 취약점을 악용하는 등의 부정한 방법을 통하여 유료서비스를 이용한 적이 있거나 이용할 목적인 경우
8. 회사의 플랫폼 또는 유료서비스 운영 관련 기술상, 설비상 또는 업무상 문제가 있어 유료서비스의 원활한 제공이 어려운 경우
9. 기타 청약자의 귀책사유로 인하여 회사가 승낙을 유보하거나 승낙하지 아니하기로 결정한 경우
③ 회사는, 제2항에 따라 청약자의 청약을 유보하거나 승낙하지 아니하기로 결정한 경우, 이를 해당 청약자에게 ‘mixtape 이용 약관’ 제12조에 따라 통지하여야 합니다.

제6조\t유료이용계약의 체결 및 효력발생
① 유료이용계약은 회사가 청약자의 청약에 대하여 “승낙” 또는 이에 준하는 표시를 계약 체결 절차 중 또는 그 후 표시한 시점에 체결되며, 그 체결 시점부터 효력이 발생됩니다.
② 유료이용회원은 청약 시 등록 또는 기재한 사항에 변경이 있는 경우, 상당한 기간 내 해당 정보 수정 등의 방법으로 그 변경 내용을 회사에 알려야 합니다.

제7조\t유료이용계약의 구성
유료이용계약은, 각 약관 또는 각 방침에서 명시적으로 달리 규정하고 있지 아니한 이상, 본 약관 및 ‘mixtape 이용 약관’, 개인정보처리방침, 기타 회사가 필요에 의하여 제정 및 개정하는 방침으로 구성됩니다.

제8조\t계약서의 교부
회사는 유료이용계약이 체결된 경우에 다음 각 호의 사항이 기재된 계약서(전자문서를 포함하며,이하 “계약서”라 할 때에는 같습니다)를 지체 없이 유료이용회원의 전자우편주소 또는 주소로 발급합니다. 유료이용회원이 회사에 제공한 위 정보가 정확하지 않거나 변경된 정보를 제공하지 아니함으로 인하여 계약서가 송달되지 아니하는 경우 회사는 그에 관하여 어떠한 책임도 부담하지 아니합니다.
1. 유료서비스 운영자의 상호, 대표자의 성명, 주소 및 전화번호
2. 유료서비스의 주요 내용
3. 유료서비스 이용 가격(가격이 결정되어 있지 아니한 경우에는 가격을 결정하는 구체적인 방법)과 그 지급방법 및 지급시기
4. 유료서비스의 이용 가능 시점
5. 청약의 철회 및 계약의 해제(이하 “청약철회 등”이라 합니다)의 기한ㆍ행사방법 및 효과에 관한 사항
6. 유료서비스 이용 시의 필요한 기술적 사항
7. 유료서비스에 대한 불만 처리 및 유료이용회원과 사업자 사이의 분쟁 처리에 관한 사항
8. 관련 약관(그 약관의 내용을 확인할 수 있는 방법을 포함합니다)
9. 유료서비스 이용 가격 외 이용자가 추가로 부담하여야 할 사항이 있는 경우 그 내용 및 금액
10. 유료서비스 이용 조건과 관련하여 제한이 있는 경우 그 내용
11. 계속거래에 해당하는 경우, 계약 해지와 그 행사 방법ㆍ효과에 관한 사항

제9조\t조작실수방지절차의 마련
①\t회사는 유료이용회원이 유료이용계약을 체결함에 있어 조작 실수 등을 하지 않도록 필요한 절차를 마련하여 제공합니다.
②\t회사가 제1항에 따른 절차를 제공하지 않아 유료이용회원이 중요부분에 대한 착오로 유료이용계약을 체결한 경우, 유료이용회원은 그 계약을 취소할 수 있습니다. 단, 유료이용회원에게 중대한 과실이 있는 경우에는 그러하지 않습니다.
③\t유료이용회원이 제2항에 따라 유료이용계약을 취소한 경우에 회사는 유료이용회원이 지급한 대금을 환급합니다.

제10조\t청약철회 등
① 회사와 유료이용계약을 체결한 유료이용회원은 아래 각 호의 기간(거래당사자가 아래 각 호의 기간보다 긴 기간으로 약정한 경우에는 그 기간을 말합니다) 이내에 그 계약에 대해 청약철회 등을 할 수 있습니다. 
1. 제8조에 따른 계약서를 받은 날부터 30일
2. 제8조에 따른 계약서를 받지 아니한 경우, 회사의 주소 등이 적혀 있지 아니한 계약서를 받은 경우 또는 회사의 주소 변경 등의 사유로 제1호의 기간에 청약철회 등을 할 수 없는 경우에는 회사의 주소 등을 안 날 또는 알 수 있었던 날부터 7일(해당일이 제1호의 기간 보다 먼저 도달하는 경우에는 제1호의 기간 내)
3. 거짓 또는 과장된 사실을 알리거나 기만적 방법을 사용하여 회원의 청약철회 등을 방해하는 행위 또는 청약철회 등을 방해할 목적으로 주소, 전화번호, 인터넷도메인 이름 등을 변경하거나 폐지하는 행위가 있는 경우에는 그 방해 행위가 종료한 날부터 7일(해당일이 제1호의 기간 보다 먼저 도달하는 경우에는 제1호의 기간 내)
② 유료이용회원은 청약철회가능기간을 도과한 경우, 청약철회가능기간 내라고 하더라도 유료서비스를 통해 플랫폼 상에서 음원을 이미 발매한 경우 및 기타 「전자상거래 등에서의 소비자보호에 관한 법률」 등 관련 법령에서 정한 청약철회제한사유에 해당되는 경우 회사의 의사에 반하여 제1항에 따른 청약철회 등을 할 수 없습니다. 회사는 위 내용을 유료이용계약 체결 시 청약자가 쉽게 알 수 있는 곳에 명확하게 표시합니다.
③ 유료이용회원은 제1항 및 제2항에도 불구하고 유료서비스의 내용이 표시ㆍ광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 유료이용계약의 체결일부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
④ 제1항 또는 제3항에 따른 청약철회 등을 서면으로 하는 경우에는 그 의사표시가 적힌 서면을 발송한 날에 그 효력이 발생하며, 회사는 이와 같은 청약철회 등이 된 경우 이용권 구매금액 전액을 환불합니다.
⑤ 제1항부터 제3항까지를 적용할 때 유료이용계약이 체결된 사실 및 그 시기, 플랫폼 상에서 이미 음원이 발매된 사실 및 그 시기 등에 관하여 다툼이 있는 경우에는 회사가 이를 증명합니다.


제3장\t이용권 결제

제11조\t이용권 및 유효기간
① 유료서비스에는 아래의 각호의 종류가 존재하며, 회사의 사정, 기타 제반 여건에 따라 서비스의 내용이 추가되거나 변경될 수 있습니다. 각 유료서비스의 가격 및 상세 서비스 내용은 홈페이지(https://mixtape.so/) 내의 멤버십 안내 페이지 또는 고객센터(FAQ) 페이지에서 확인할 수 있습니다. 
1. BASIC 이용권 
2. PRO 이용권
② 이용권의 유효기간(이하 “계약기간”) : 원칙적으로 계약기간은 유료이용계약 체결일부터 1년이 되는 날까지입니다. 단, 회사는 “계약기간” 만료 30일 전까지 서면(이메일, 문자 등 전자 서면 포함)으로 유료이용회원에게 개별적으로 계약의 연장 여부를 묻는 통지를 하고, 유료이용회원이 위 통지를 받은 날로부터 30일 이내에 별도로 해지의 의사표시를 하지 않으면 본 계약은 동일한 조건으로 1년간 연장됩니다.

제12조\t결제
① 유료이용회원에 이용권 구매를 위하여 제공되는 결제수단은 결제수단을 제공하는 회사(이하 “결제회사”)에 따라 아래 각 호와 같습니다. 단, 회사 또는 결제회사의 사정에 따라 특정 결제수단이 추가되거나 특정 결제수단의 제공이 중단 또는 종료될 수 있습니다.
1. 제휴 된 신용카드, 직불카드, 선불카드 등의 각종 카드
2. 회사가 결제회사와 제휴계약 등을 체결하여 제공하는 전자적 지급수단
② 유료이용계약이 체결되는 시점 및 갱신되는 시점에 유료이용회원이 등록한 결제수단을 통해 연 단위 이용권 가격이 결제됩니다.
③ 유료이용회원은 경우에 따라 이용권 구매 시 제3자와 거래해야 할 수도 있으며, 이와 같은 경우 유료이용회원의 결제 수단에 이용권 대금을 청구하고 환불을 비롯해 해당 결제에 관한 문제를 관리 내지 조치할 책임은 해당 제3자에 있습니다.

제13조\t갱신 관련 조건
① 등록된 결제수단이 카드의 만료 또는 기타 사유로 실효되어 다음 결제 기간에 이용권 가격의 결제가 불가능해지는 경우 해당 유료이용회원에 대하여 결제수단 정보의 업데이트가 있을 때까지 유료서비스 이용이 제한될 수 있습니다.
② 제1항의 경우 회사는 해당 유료이용회원에게 그 사실을 통지합니다. 회사는 위 통지 후 상당한 기간 내 결제수단 정보의 업데이트가 이루어지지 아니하는 경우 유료이용계약을 해지할 수 있으며, 이로 인하여 유료이용회원에게 어떠한 손해가 발생되더라도 회사는 그에 대하여 책임을 부담하지 아니 합니다.

제14조\t이용권 가격 및 수수료 등의 변경
① 회사는 이용권 가격 및 이용권 종류에 따라 제공되는 서비스에 따른 수수료 등을 변경할 수 있습니다. 단, 변경된 이용권 가격 및 수수료 등은 기 결제된 유료이용계약에 소급하여 적용되지 아니하며 그다음 계약부터 적용됩니다.
② 회사는 이용권 가격 및/또는 이용권 종류에 따라 제공되는 서비스에 따른 수수료의 변경이 필요한 경우, 그 사정을 유료이용회원에게 개별적으로 알려야 하고, 유료이용회원은 이용권 가격의 변경 및 수수료 변경에 동의하지 아니할 경우 언제든지 유료이용계약을 해지할 수 있습니다

제15조\t과오금
① 회사는 이용권 대금 결제와 관련하여 과오금이 발생한 경우 그 결제수단과 동일한 방법으로 과오금 전액을 환불합니다. 단, 동일한 방법으로 과오금 환급이 어려운 경우 이를 사전에 고지합니다. 
② 회원의 책임 있는 사유로 과오금이 발생한 경우, 회사는 과오금 환불에 소요되는 합리적인 범위 내의 비용을 회원에게 청구할 수 있습니다.
③ 회사는 회원이 주장하는 과오금에 대한 환불을 거부할 경우, 해당 결제 금액이 정당했음을 입증할 책임을 부담합니다.


제4장\t유료서비스

제16조\t라이선스
① 유료이용회원은 계약기간 동안 회사에게 유료이용회원이 플랫폼에 등록하는 음원에 대한 독점적 유통권을 부여합니다.
② 회사는 약관 등에 따라 독점적 유통권을 행사함에 있어 더 다양한 DSP에 음원을 공급하기 위해 필요한 경우 제3자에게 본 계약에 따른 유통권을 대행하여 행사하도록 할 수 있습니다(sub-license).
③ 회사는 음원유통을 위한 범위 내에서 유료이용회원이 등록한 음원 및 콘텐츠를 복제, 배포, 전송 등 할 수 있습니다.
④ 회사는 음원유통을 수행함에 있어 유료이용회원이 등록한 음원 및 콘텐츠 중 오탈자를 포함하여 잘못된 정보 내지 자료가 있을 경우 그러한 오류를 정정할 수 있습니다.
⑤ 회사는 음원유통을 수행함에 있어 DSP가 운영하는 서비스에 필요한 범위 내에서 유료이용회원이 등록한 음원에 대하여 하이라이트 구간 설정, 일부 구간 추출(벨, 통화연결음, User Generated Content 등), 음질 조정, 포맷 변환, 가창 볼륨 조정 등의 조치를 하거나 DSP로 하여금 그러한 조치를 하도록 허락할 수 있습니다.
⑥ 회사는 유료서비스의 마케팅 또는 프로모션 범위 내에서 유료이용회원이 등록한 음원 및 콘텐츠를 별도의 동의 없이 무상으로 이용할 수 있습니다. 이 때, 회사는 유료이용회원의 명예나 평판이 훼손되지 아니하도록 주의의무를 다합니다.

제17조\t유료이용회원의 콘텐츠 등록 및 삭제
① 유료이용회원은 유통하기 원하는 음원 및 이를 위해 필요한 콘텐츠를 플랫폼에 등록합니다. 
② 유료이용회원은 언제든지 기 등록한 음원 및 콘텐츠의 삭제를 회사에게 요청할 수 있습니다. 
③ 유료이용회원은 언제든지 등록한 음원 및 콘텐츠의 유통 중지를 회사에게 요청할 수 있습니다.
④ 회사는 유료이용회원에게 제1항부터 제3항까지의 등록 및 요청에 필요한 방법 및 절차를 적정한 방법으로 안내합니다.

제18조\t회사의 콘텐츠 등록 유보 또는 거절, 삭제 등
① 회사는 유료이용회원이 플랫폼에 등록한 음원 및/또는 콘텐츠가 아래 각 호 중 어느 하나에 해당되는 경우 그 등록을 유보하거나 거절할 수 있습니다.
1. 해당 음원 및/또는 콘텐츠가 제3자의 저작권, 초상권, 퍼블리시티권 등을 침해하였음이 합리적으로 의심되는 경우
2. 법령에 따라 금지되는 또는 법령에 위반되는 내용이 포함된 경우
3. 범죄를 목적으로 하거나 교사 또는 방조하는 내용이 포함된 경우
4. 발매 및/또는 유통되는 경우 제3자의 사생활, 명예, 신용, 비밀 등이 침해될 가능성이 상당한 내용이 포함된 경우
5. 플랫폼 및 관련 설비, 통신망, 정보통신장치, 데이터 등에 장애를 일으킬 것이 상당한 바이러스, 프로그램 등이 포함된 경우
6. 기타 발매 및/또는 유통되는 경우 건전한 사회질서 또는 미풍양속에 반하여 유료이용회원, 회사 및/또는 사회에 중대한 부정적 영향을 미칠 가능성이 현저한 경우
② 회사는 기 등록된 음원 및/또는 콘텐츠 중 제1항 각 호의 어느 하나에 해당됨을 발견한 경우 해당 음원 및 콘텐츠의 유통을 중지하거나 취소ㆍ삭제할 수 있습니다.
③ 회사는 제1항 또는 제2항의 경우 지체 없이 그 사실을 해당 유료이용회원에게 통지하고, 1주일 이상의 기간 동안 소명기회를 부여합니다. 회사는 위 소명기간 동안에도 해당 음원 및 콘텐츠의 유통을 중지할 수 있으며, 충분한 소명이 이루어졌다고 판단하는 경우에는 해당 음원 및 콘텐츠 유통을 재개할 수 있습니다.
④ 본 조에 따른 등록의 유보나 거절, 유통의 중지 및 콘텐츠의 등록취소나 삭제에 대하여 회사는 어떠한 책임도 부담하지 아니 합니다. 단, 회사의 고의 또는 중대한 과실이 인정되는 경우에는 그러하지 않습니다.

제19조\t유통 범위
① 회사가 플랫폼에 등록된 음원의 유통을 수행할 수 있는 지역은 대한민국을 포함한 전 세계의 모든 지역입니다.
② 회사가 플랫폼에 등록된 음원을 유통할 수 있는 대상 DSP의 종류 및 범위에는, 회사와 유료이용회원 간 달리 합의하지 아니한 이상, 제한을 두지 아니 합니다. 명확히 하면, 아래와 같은 서비스를 제공하는 DSP 및 그러한 서비스가 포함되나 이에 한정되지 아니하며, 유료이용회원은 본인이 등록한 음원 및 콘텐츠가 아래 각 호를 포함하여 다양한 서비스에 이용될 수 있다는 것에 동의합니다.
1. 유/무선 MOD(Music on Demand) 서비스 (음악 스트리밍ㆍ다운로드 청취 서비스 등)
2. 유/무선 VOD(Video on Demand) 서비스 (비디오 스트리밍ㆍ다운로드 청취 서비스 등)
3. 웹캐스팅
4. User Generated Content 제작지원, 전송, 공연, 공유 등의 서비스
5. 배경음악, 통화연결음, 통화배경음, 벨소리, 음악편지, ARS 등 음원을 이용한 부가가치 서비스
6. 비디오게임(웹, 모바일, 콘솔 등 디바이스 종류는 불문) 관련 음악 서비스
7. 온/오프라인 매장 및 각종 운송수단(기내, 선박, 자동차, 기차, 버스 등 불문)내 공연, 전송 등을 위한 관련 음악 서비스
8. 광고, 영화, 드라마 등의 제작 및 유통 관련 서비스

제20조\t발매일의 결정
유료이용회원은 플랫폼에 음원을 등록할 때 해당 음원의 발매일을 선택합니다. 회사는 유료이용회원이 선택한 위 발매일에 해당 음원 및 콘텐츠의 유통을 개시합니다.  

제21조\t유료서비스의 변경 및 중지
① 회사는 유료서비스를 변경하여 제공할 수 있습니다. 이 경우 회사는 변경될 서비스의 내용 및 제공일자를 회원에게 ‘mixtape 이용 약관’ 제12조에 따라 통지합니다.
② 회사는 아래 각 호에 해당하는 경우 유료서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.
1. 유료서비스용 설비의 보수 등 공사로 인하여 부득이한 경우
2. 회원 또는 제3자가 불법행위, 범죄행위 등으로 회사의 영업활동을 방해하는 경우
3. 정전, 시스템 및 기타 유료서비스 설비의 장애, 정보통신장치의 장애, 유무선 네트워크 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
4. DSP와의 계약 종료 등과 같은 회사의 제반 사정으로 유료서비스를 유지할 수 없는 경우
5. 회사의 분할ㆍ합병ㆍ영업양도ㆍ영업의 폐지ㆍ당해 서비스의 수익 악화 등 회사의 경영상 중대한 필요에 의한 경우
6. 전쟁, 내란, 소요사태, 천재지변 또는 이에 준하는 불가항력적 사유 등 회사가 통제할 수 없는 불가항력적 사유가 있는 경우
③ 회사는 제1항 및 제2항의 변경 또는 중단에 대하여 변경 또는 중단 사유, 변경 또는 중단될 서비스의 내용 및 중단 일자 등을 플랫폼 초기 화면에 게시하거나 회원에게 개별 통지합니다. 단, 회사가 통제할 수 없는 사유로 인하여 사전 통지가 불가능한 경우에는 그러하지 않습니다.
④ 회사는 제2항의 각 호에 해당하는 사유가 아닌 회사의 귀책사유로 서비스의 제공이 중단됨으로 인하여 회원이 입은 손해를 배상합니다.


제5장\t수익의 정산

제22조\t정산 대상 수익 
① 유료서비스에 따라 유료이용회원에게 정산하는 대상 수익은 국내 원천 수익의 경우, 부가가치세액을 제외한 공급가액을 의미하며 해외 원천 수익의 경우, 각 국가별 원천세액을 차감한 후의 금액을 의미합니다.
② 회사가 국내 저작권 신탁단체와의 협약체결로 인하여 저작권료, 실연권료 등을 별도로 산정하여 납부하여야 하는 경우 해당 납부금액을 차감한 후의 금액을 정산 대상 수익으로 합니다. 
③ 회사가 DSP에게 음원 및 콘텐츠를 유통하고 그들로부터 외화로 정산 대상 수익을 지급받는 경우, 해당 외화를 회사의 회계처리 정책에 따라 정산금이 확정되는 날의 하나은행 매도환율을 각 통화별로 적용하여 원화로 환산합니다.
④ 회사가 DSP로부터 수익을 지급받음에 있어 해외 송금수수료, 전신료 등의 부대 비용이 발생하는 경우, 해당 부대 비용을 차감한 후의 금액을 정산 대상 수익으로 합니다.
⑤ 본조 제1항에도 불구하고, YouTube Content ID (이하 ‘YouTube CID‘)를 발급한 회원의 경우, 회사는 ‘YouTube CID’를 통하여 발생한 수익으로 YouTube의 정책에 따라 YouTube로부터 지급받은 총금액의 15%에 해당하는 금액을 회사의 유통 수수료로 차감하고, 위 유통 수수료를 차감한 금액에서 관련 법령에 따라 발생한 세금 및 일체의 부대비용을 차감한 금액을 기준으로 해당 회원에게 수익을 정산합니다.

제23조\t정산
① 회사는 정산 대상 수익을 인식한 월(n)의 익익월(n+2) 말일까지 유료이용회원의 전자메일로 정산 공지를 발송하고 홈페이지에 정산 결과를 공개합니다.
② 회사는 정산 공지 발표일의 익월(n+3) 말일까지 유료이용회원의 등록 계좌로 원천징수세액[개인소득세 3% + 지방소득세(개인소득세의 10%)]을 차감한 후의 정산금을 지급합니다.
③ 계좌정보 오류, 계좌개설 상태변동 등의 사유로 회사가 정상적으로 정산금을 지급할 수 없는 경우, 해당 정산금 지급을 보류하고 유료이용회원에게 별도로 공지하며 유료이용회원이 정상적인 계좌정보를 제공한 날로부터 30일 이내에 지급보류액을 송금하되, 해당 기간 동안의 이자발생액은 지급대상에서 제외합니다.
④ 회사가 공지한 정산금 지급내역에 오류가 발생하여 기 지급된 정산금을 조정하여야 하는 경우, 회사는 유료이용회원에게 오류발생 내역을 별도 공지하고 해당 과오금액을 차기 지급 예정 정산금액에 일괄적으로 차가감하여 지급합니다.


제6장\t유료이용계약의 변경, 해지 및 종료

제24조\t유료이용계약의 변경 등
유료이용회원은 아래의 방법으로 유료이용서비스의 이용권을 변경할 수 있습니다. 
① 변경예약 : 유료이용회원은 기존 이용 기간이 만료되는 날까지 기존 구매한 이용권을 이용할 수 있고, 이용 기간 만료일 익일부터 변경예약한 새로운 이용권으로 변경하여 서비스를 이용할 수 있습니다. 
② 즉시변경 : 기존의 유료이용회원이 자신이 사용하던 이용권의 종류를 즉시변경 하고자 할 경우, 아래의 내용이 적용됩니다. 
1. 음원 발매 이력이 있거나 결제일로부터 30일 이후 유료이용회원의 요청 시
가. 정가로 가입한 경우 : 잔여기간에 따라 일할 계산하여 부분 환불 처리 후, 변경 이용권 요금 결제하여 멤버십 변경 
나. 할인가로 가입한 경우 : 할인가가 아닌 정가 기준으로 잔여기간에 따라 일할 계산하여 부분 환불 처리 후, 변경 이용권 요금 결제하여 멤버십 변경 
2. 음원 발매 이력이 없고 결제일로부터 30일 이내 유료이용회원의 요청 시 : 기존 이용권 가입비 전액 환불 후, 변경 이용권 요금 결제하여 멤버십 변경 

제25조\t유료이용계약의 해지 등
① 회사는 제5조 제2항 각 호의 사유를 발견하는 경우 유료이용계약을 즉시 해지할 수 있으며, 회사의 해지의 의사표시가 회원에게 도달한 때(회원이 제공한 이메일 또는 주소로 해지의 의사표시를 송달하였으나 반송 등 된 때에는 그 송달일로부터 7일이 경과한 때) 해지의 효력이 발생됩니다. 이에 따라 본 계약이 해지되는 경우 회사는 회원에게 이용권 구매금액을 일체 환불하지 않습니다.
② 청약철회가능기간이 도과된 것과 별도로, 회원은 유료이용계약을 중도 해지할 수 있으며, 회원의 해지의 의사표시를 회사에게 발송한 때 해지의 효력이 발생됩니다. 이에 따라 본 계약이 해지되는 경우 회사는 이용권의 유효기간 중 잔여 기간에 해당하는 이용권 구매금액을 일월 할 계산하여 환불합니다.
③ 유료이용회원 또는 회사는, 유료이용계약의 개별 조항에서 달리 규정한 경우 외, 상대방이 유료이용계약의 진술 및 보증, 의무, 조건 등을 위반하는 경우 상당한 기간을 정하여 해당 귀책당사자에게 그 시정을 최고하고 위 기간 내에 시정되지 않을 때에는 계약을 해지할 수 있다.
④ 어느 일방 당사자의 귀책사유로 상대방 당사자 또는 제3자에게 손해가 발생된 경우, 귀책당사자는 그 손해 일체를 배상하여야 하며, 자신의 비용과 책임으로 상대방 당사자가 제3자에 대한 관계에서 면책될 수 있도록 하여야 한다.
⑤ 회원이 할인 프로모션을 통하여 취득한 이용권을 중도 해지한 경우 회사는 회사의 환불 기준에 따라 환불을 진행합니다. 회사의 할인 프로모션을 통하여 취득한 이용권에 대한 환불기준은 홈페이지(https://mixtape.so/) 내 해당 멤버십 안내 페이지 또는 고객센터(FAQ)에서 확인할 수 있습니다.
⑥ 회사는 유료이용회원이 유료이용계약을 해지하는 경우에도 「전자상거래 등에서의 소비자보호에 관한 법률」 및 관련 법령에서 정하는 바에 따라 거래 기록을 보존합니다.


제26조\t유료이용계약 종료의 효과
회사는 유료이용계약이 종료되는 경우(해지에 따른 종료를 포함) 유료서비스의 원활한 중지를 위하여 30일 동안 위 종료일로부터 30일 동안 유료이용회원이 발매한 음원의 유통을 계속할 수 있으며, 이와 같이 유통한 음원에 대한 정산금을 제23조에 따라 유료이용회원에게 정산합니다.


제7장\t기타 권리 및 의무

제27조\t저작권 등의 귀속
유료이용회원이 플랫폼에 등록한 음원 및 콘텐츠에 대한 저작권 기타 권리는 해당 유료이용회원또는 원래의 권리자에 귀속되며, 회사는 본 약관에 따른 유통권 외 어떠한 권리도 갖지 않습니다.

제28조\t보증
① 유료이용회원은 본인이 플랫폼에 등록한 음원 및 콘텐츠에 대하여 그 등록 및 유통을 허락할 수 있는 적법한 권한을 가지고 있음과, 해당 음원 및 콘텐츠가 제3자의 저작권, 초상권, 상표권, 퍼블리시티권 등 적법한 법적 권리 내지 이익을 침해하지 아니 한다는 점을 진술 및 보증합니다.
② 제3자로부터 제1항의 진술 및 보증에 반하는 주장이 제기되는 경우, 유료이용회원은 회사를 해당 분쟁에서 면책되도록 하여야 하며, 관련하여 회사에게 발생되는 손해를 배상하여야 합니다.
③ 회사는 제2항에서와 같은 제3자의 주장이 제기되는 경우, 유료이용회원에게 그에 대한 소명 기회를 제공 후 유료이용회원의 소명에도 불구하고 위 제3자의 주장에 따른 합리적 의심이 계속되는 때에는, 유료이용회원에 대하여 ‘mixtape 이용 약관’ 제12조에 따른 통지 후 해당 음원 및/또는 콘텐츠의 유료서비스를 즉시 중지할 수 있고 해당 수익의 정산금 지급을 유보할 수 있으며, 해당 유보금에 대하여는 이자가 발생되지 아니 하는 것으로 봅니다. 단, 위 제3자의 주장에 확실한 법적 근거가 있거나 즉시 중지하여야 할 긴급한 사정이 있는 때에는 유료이용회원에 대한 사전 통지 없이 해당 음원 및/또는 콘텐츠의 유료서비스를 즉시 중지하고 해당 수익의 정산금 지급을 유보할 수 있습니다. 회사는 관련 분쟁이 법적 절차의 확정, 당사자 간 합의 등으로 종료되는 경우 그 결과에 따라 유료이용회원에게 유보하였던 정산금을 지급합니다.

제29조\t접근권한에 대한 동의
① 회사는 유료서비스를 유료이용회원에게 제공하기 위하여 유료이용회원의 이동통신단말장치 내에 저장되어 있는 정보 및 이동통신단말장치에 설치된 기능에 대하여 접근할 수 있는 권한(이하 “접근권한”)이 필요한 경우에 아래 각 호의 사항을 유료이용회원이 명확하게 인지할 수 있도록 알리고, 동의를 받습니다.
1. 유료서비스를 제공하기 위하여 반드시 필요한 접근권한인 경우
가. 접근권한이 필요한 정보 및 기능의 항목
나. 접근권한이 필요한 이유
2. 유료서비스를 제공하기 위하여 반드시 필요한 접근권한이 아닌 경우
가. 접근권한이 필요한 정보 및 기능의 항목
나. 접근권한이 필요한 이유
다. 접근권한 허용에 대하여 동의하지 아니할 수 있다는 사실
② 회사는 유료서비스를 제공하기 위하여 반드시 필요하지 아니한 접근권한을 설정하는 데 유료이용회원이 동의하지 아니한다는 이유로 이용자에게 해당 유료서비스의 제공을 거부하지 않습니다.

제30조\t청소년유해매체물
① 유료이용회원은 「청소년보호법」에 따라 본인이 등록하는 음원 및/또는 콘텐츠가 청소년유해매체물에 해당되지 않는지 결정하고, 청소년유해매체물에 해당된다고 결정하는 경우 해당 사실을 회사에 통지하여야 합니다.
② 유료이용회원이 본인이 등록하는 음원 및/또는 콘텐츠에 대하여 청소년유해매체물이 아니라고 결정하였으나, 회사의 자율적 판단에 따라 또는 DSP, 감독기관 등에 의하여 청소년유해매체물을 표시할 것을 요구받음에 따라 청소년유해매체물로 분류할 필요가 있는 경우, 회사는 해당 음원 및/또는 콘텐츠를 청소년유해매체물로 분류하고 관련 법령에 따라 필요한 조치를 취할 수 있습니다.
③ 유료이용회원은 제2항에 따른 회사의 청소년유해매체물 분류에 동의하지 아니하는 경우 언제든지 유료이용계약을 해지할 수 있습니다.

부칙
제1조 시행일
본 약관은 2024년 8월 1일부터 적용됩니다.
				`;

const TypographyList = [
  <>
    <span style={pointTextStyle}>한국의 음악 유통업의 혁신</span>
    이며 염원인 음원발매를 실현시켜 줄 선물같은 플랫폼
  </>,
  <>
    <span style={pointTextStyle}>이름있는 기업</span>
    이라 믿고 맡길수 있고 거기다 수수료 0퍼인 아티스트들을 위한 회사!
  </>,
  <>
    <span style={pointTextStyle}>음악에 간절한 뮤지션</span>을 위한 최적화 서비스 플랫폼
  </>,
  <>
    연간 구독료 지불하면, 수수료 0%에&nbsp;
    <span style={pointTextStyle}>전세계 스트리밍서비스 유통 </span>
    시켜주고, 음원 발매도 간편해서 좋아
  </>,
  <>
    정말 간편하게 음반을 발매할 수 있어서 ~
    <span style={pointTextStyle}>음악에 집중할 수 있고 많은 음악을 만들고 싶어지게 하는 유통 서비스 </span>
    입니다.
  </>,
  <>
    “유명하지 않은 아티스트들은 유통사와 일을 진행하는게 음악 제작만큼 피로도가 높다. 그것에 &nbsp;
    <span style={pointTextStyle}>많은 것들을 해결해주는 플랫폼 </span>
  </>,

  <>
    큰 유통사와 계약이 되었다해도 음원수익의 결과가 없으면 발매의뢰 할때마다 눈치가 보이는데&nbsp;
    <span style={pointTextStyle}>자유롭게 발매를 </span>
    결정할수 있는 유통사여서 <span style={pointTextStyle}>인디뮤지션들에게 강력추천</span>
    합니다.
  </>,
  <>
    <span style={pointTextStyle}>편의성과 실리를 얻는데 연 66,000원</span>
    이라면 지불할만하다고 생각한다.
  </>,
];

const PointPercent = ({ value }: { value: string }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        '::before': {
          position: 'absolute',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '17px',
          display: 'block',
          content: '""',
          width: '87px',
          height: '87px',
          background: '#EFFD60',
          filter: 'blur(21px)',
        },
      }}
    >
      <Typography fontSize="50px" fontWeight="bold" color="#191919" position="relative" textAlign="center">
        {value}%
      </Typography>
    </Box>
  );
};

const TextCard = ({ textComponent }: { textComponent: React.ReactNode }) => {
  return (
    <Stack
      direction="column"
      width="320px"
      height="280px"
      p={'20px'}
      boxSizing="border-box"
      border="1px solid #DBDBDB"
      borderRadius="16px"
      boxShadow="0px 0px 20px #0000001A"
      justifyContent="space-between"
    >
      <div>
        <img width={'18px'} src="/assets/icon/quotes-s.svg" style={{ marginBottom: '25px' }}></img>
        <Typography sx={textStyle} gutterBottom>
          {textComponent}
        </Typography>
      </div>

      <Stack direction="row" gap="3px">
        <img width={'11px'} src="/assets/icon/star.svg"></img>
        <img width={'11px'} src="/assets/icon/star.svg"></img>
        <img width={'11px'} src="/assets/icon/star.svg"></img>
        <img width={'11px'} src="/assets/icon/star.svg"></img>
        <img width={'11px'} src="/assets/icon/star.svg"></img>
      </Stack>

      {/* <Stack direction="column" width="100%" height="100%" justifyContent="space-between">
        
      </Stack> */}
    </Stack>
  );
};

const UserReview = () => {
  const [swiperCurIdx, setSwiperCurIdx] = useState<number>(0);

  const onSlideChange = (idx: number) => {
    setSwiperCurIdx(idx);
  };

  return (
    <>
      <Typography
        mb="20px"
        p="13px 48px"
        fontSize="20px"
        fontWeight="bold"
        sx={{ border: '1px solid #191919', borderRadius: '39px' }}
      >
        멤버십 이용 후기
      </Typography>
      <Typography mb="60px" fontSize="16px" color="#767676">
        앨범 발매 완료 아티스트 대상 설문 결과
      </Typography>
      <Stack direction="row" justifyContent="center" mb="110px">
        <Stack direction="column" alignItems="center">
          <Typography mb="30px" fontSize="20px">
            믹스테이프 추천 의향
          </Typography>
          <PointPercent value="95.5" />
        </Stack>
        <Divider sx={{ mx: '42px' }} orientation="vertical" color="#D4D4D8" flexItem></Divider>
        <Stack direction="column" alignItems="center">
          <Typography mb="30px" fontSize="20px">
            서비스 품질 만족도
          </Typography>
          <Typography fontSize="50px" fontWeight="bold" color="#191919">
            <PointPercent value="97.4" />
          </Typography>
        </Stack>
      </Stack>
      <Stack mb="100px" direction="row" width="100%" height="100%" px={'350px'}>
        <MixSwiper
          slidesPerView="auto"
          centeredSlides={true}
          style={{ width: '100%', height: '100%' }}
          onSlideChange={onSlideChange}
        >
          {TypographyList.map((typograpy, idx) => (
            <SwiperSlide key={idx} className="mixswiper-active">
              <TextCard textComponent={typograpy} />

              {idx === swiperCurIdx && (
                <img
                  width="68px"
                  src="/assets/icon/quotes-l.svg"
                  style={{
                    position: 'relative',
                    top: '-32.3px',
                    right: '0px',
                    left: '212px',
                  }}
                ></img>
              )}
            </SwiperSlide>
          ))}
        </MixSwiper>
      </Stack>
    </>
  );
};

const PayMembership = ({ membershipType }: { membershipType: string }) => {
  const [isMembershipAgreed, setIsMembershipAgreed] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  // TODO: 결제 api만 확인하면 됩니다~!
  const payToss = async () => {
    const result = await startTossPayment({ mode: 'create', membershipType });

    if (result) {
      popupMessage(result);
    }
  };

  const signUpMembership = () => {
    payToss();
  };
  const popupMessage = (txt: any) => {
    setAlertText(txt);
    setIsAlertOpen(true);
  };
  const hideAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <>
      <Typography mb="40px" fontSize="16px" color="#767676" lineHeight="28px" textAlign="center">
        멤버십은 1년 단위로 자동 청구가 되며, 추가 비용이나 약정은 없습니다.
        <br /> 멤버십을 해지할 경우 음원 유통은 중지됩니다.
      </Typography>
      <Checkbox
        checked={isMembershipAgreed}
        id="use"
        label={
          <Stack direction="row" mb="20px">
            <Typography fontSize="16px" color="#767676">
              mixtape. 유료 이용약관 동의&nbsp;
            </Typography>
            <Typography fontSize="16px" color="#FF3120">
              (필수)
            </Typography>
          </Stack>
        }
        onChange={(e) => {
          setIsMembershipAgreed(e.target.checked);
        }}
      />
      <TermsBox2 style={{ width: '460px', marginBottom: '30px' }}>
        <div
          style={{
            padding: '0 30px',
            whiteSpace: 'pre-line',
            fontSize: '13px',
            fontWeight: 300,
            color: '#5b5b5b',
            height: '240px',
            overflow: 'auto',
          }}
        >
          {terms}
        </div>
      </TermsBox2>
      <SignUpMembershipButton disabled={!isMembershipAgreed} onClick={signUpMembership}>
        mixtape. 멤버십 가입
      </SignUpMembershipButton>
      <AlertModal isPopupOpen={isAlertOpen} alertText={alertText} hidePopup={hideAlert} />
    </>
  );
};

const Membership = () => {
  const { type } = useParams();
  const history = useHistory();
  const userInfo = $mixtapeGetUser();

  const usedUserProMembership = {
    Y: {
      eventText: '',
      price: '99,000',
      monthPrice: '8,250',
    },
    N: {
      eventText: '멤버십 첫 이용 EVENT',
      price: '88,000',
      monthPrice: '7,333',
    },
  };

  const typeParams = type === 'basic' ? 'Basic' : 'Pro';

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      flex="1"
      bgcolor="#fff"
      overflow="hidden"
      pt="190px"
      pb="200px"
      px="320px"
    >
      <Typography mb="30px" fontSize="30px" fontWeight="bold">
        {type === 'basic' && 'Basic'} {type === 'pro' && 'Pro'} 멤버십 구독하기
      </Typography>
      <Typography mb="50px" fontSize="22px" color="#5B5B5B" lineHeight="36px" textAlign="center">
        {type ? (
          <>
            한 달 {type === 'basic' ? '5,500' : usedUserProMembership[userInfo.pro_yn]?.monthPrice}원으로 유통 수수료 없이 <br />
            지금 자유롭게 <b> 앨범 발매</b>를 시작하세요!
          </>
        ) : (
          <>
            믹스테이프 멤버십 구독하여 유통 수수료 없이
            <br />
            지금 자유롭게<b> 앨범 발매</b>를 시작하세요!
          </>
        )}
      </Typography>
      <Stack direction="row" justifyContent="center" alignItems="center" gap="40px" mb={type ? '40px' : '115px'}>
        {(!type || type === 'basic') && (
          <MembershipCard isBasic>
            <div className="card-header">
              <span className="membership-name">BASIC</span>
              <div className="badge-container">
                <span>Billed annually</span>
              </div>
              <div className="price-container">
                <span className="price">₩ 66,000</span>
                <span className="year">&nbsp;/ year</span>
              </div>
              <span style={{ color: '#29616C' }}>
                ( <span style={{ fontWeight: 'bold' }}>월 5,500원</span>에 이용 / 부가세 포함 )
              </span>
            </div>
            <div className="card-body">
              <span className="title">Basic 멤버십 회원이 누리는 특별한 혜택!</span>
              <ul>
                <li>유통 수수료 0%</li>
                <li>자유로운 발매 일정 선택 (주 1회 발매 가능)</li>
                <li>전 세계 190여 개 국가 앨범 유통</li>
                <li>발매 이후 2회 수정 가능</li>
                <li>트랙 수 최대 20개</li>
              </ul>
              {!type && (
                <button className="subscribe-btn" onClick={() => history.push('/membership-info/basic')}>
                  <img width="75px" src="/assets/icon/membership_basic_L.svg"></img>멤버십 구독하기
                </button>
              )}
            </div>
          </MembershipCard>
        )}
        {(!type || type === 'pro') && (
          <MembershipCard isBasic={false}>
            <div className="card-header">
              <span className="membership-name">Pro</span>
              <div className="badge-container">
                <span>Billed annually</span>
              </div>

              {userInfo.pro_yn === 'N' && <span className="first-use">멤버십 첫 이용 EVENT</span>}

              <div className="price-container">
                {userInfo.pro_yn === 'N' && (
                  <span style={{ fontSize: '24px', color: '#A7B043', textDecoration: 'line-through' }}>
                    {' '}
                    &nbsp;99,000원&nbsp;
                  </span>
                )}
                <span className="price">&nbsp;₩ {usedUserProMembership[userInfo.pro_yn].price}</span>
                <span className="year">&nbsp;/ year</span>
              </div>
              <span style={{ color: '#A7B043' }}>
                ( <span style={{ fontWeight: 'bold' }}>월 {usedUserProMembership[userInfo.pro_yn].monthPrice}원</span>에 이용 /
                부가세 포함 )
              </span>
            </div>
            <div className="card-body">
              <span className="title">Pro 멤버십 회원이 누리는 특별한 혜택!</span>
              <ul>
                <li>유통 수수료 0%</li>
                <li>자유로운 발매 일정 선택 (주 3회 발매 가능)</li>
                <li>전 세계 음악 플랫폼, 소셜미디어 채널 발매</li>
                <li>YouTube 공식 아티스트 채널(OAC) 신청 가능</li>
                <li>Youtube Content ID 발급 요청 가능 (수수료 15%)</li>
                <li>믹스테이프 유튜브 채널 MV 업로드</li>
                <li>발매 이후 무제한 수정 가능</li>
                <li>트랙 수 최대 30개</li>
              </ul>
              {!type ? (
                <button className="subscribe-btn" onClick={() => history.push('/membership-info/pro')}>
                  <img width="75px" src="/assets/icon/membership_pro_L.svg"></img>멤버십 구독하기
                </button>
              ) : (
                <>
                  {/* <Divider style={{ margin: '20px 0' }} color="#D4D4D8" />
                  <span style={{ fontSize: '14px', color: '#767676' }}>
                    ▪ 해당 할인가 적용 기간은 1년이며 다음 결제일에는 정상가로 결제됩니다. 해당 멤버십을 중도 해지하시는 경우,
                    추후 동일 혜택 적용이 어렵습니다.
                  </span> */}
                </>
              )}
            </div>
          </MembershipCard>
        )}
      </Stack>

      {type ? <PayMembership membershipType={typeParams} /> : <UserReview />}
    </Stack>
  );
};

export default Membership;
