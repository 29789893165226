import HTTPError from '@/core/error/HTTPError';
import getActionCreators from '@/core/redux/getActionCreators';
import { setAuth } from '@utils/auth';
import { Dispatch } from 'redux';

type TRequestParams = {
  reduxActionName: string;
  mapperFunc: (...args: any[]) => any;
  dispatch: Dispatch;
  successMessage?: string;
  params?: any;
  isSingleRequest?: boolean;
};

export const request = async ({
  reduxActionName,
  mapperFunc,
  dispatch,
  successMessage,
  params,
  isSingleRequest,
}: TRequestParams): Promise<void> => {
  const { requestReady: globalRequestReady, requestStart: globalRequestStart } = getActionCreators('global');
  const { setParameters, setReqParameters, requestReady, requestStart, requestSuccess, requestFail } = getActionCreators(
    reduxActionName,
  );

  dispatch(setParameters(params));
  dispatch(setReqParameters(params));
  dispatch(requestStart());

  if (!isSingleRequest) {
    dispatch(globalRequestStart());
  }

  try {
    const response = await mapperFunc(params);

    const resData = await response?.data;
    const resHeaders = await response?.headers;

    // setAuth(resHeaders);

    if (resData.error) {
      throw resData.error;
    }

    dispatch(requestSuccess({ data: resData }));

    // if (successMessage) {
    //   window.alert(successMessage);
    // }
  } catch (error) {
    const errorMessage = typeof error?.response?.data === 'string' ? error.response.data : error?.response?.data?.message;

    dispatch(requestFail({ error, errorMessage }));
  } finally {
    dispatch(requestReady());
    if (!isSingleRequest) {
      dispatch(globalRequestReady());
    }
  }
};
