/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { noImage } from '@images/png';

import * as Icons from '@images/png';

type TIconType = keyof typeof Icons;

export const iconTypes: TIconType[] = Object.keys(Icons) as any[];

export type TIconProps = {
  icon?: TIconType;
  alt?: string;
  style?: React.CSSProperties;
  onClick?: any;
  reversedY?: boolean;
  url?:string;
};

const StyledImg = styled.img<any>`
  ${(props: any) =>
    props.reversedY &&
    css`
      transform: scaleY(-1) !important;
    `}
`;
export const Icon = ({ icon, alt, style, onClick, reversedY, url }: TIconProps) => {

  let CIcon = null
  if(icon){
    CIcon = Icons[icon];
  }
  if(url){
    CIcon = url
  }
  if (onClick) {
    return (
      <StyledImg
        alt={alt}
        onClick={() => {
          onClick();
        }}
        reversedY={reversedY}
        src={CIcon}
        style={style}
      />
    );
  }
  return <StyledImg alt={alt} reversedY={reversedY} src={CIcon} style={style} onError={e => {
    e.target.src = noImage
  }} />;
};
