import { DataGridPro, GridColumnHeaderParams, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { ReactNode, useEffect } from 'react';
import { Checkbox, Collapse, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const MixTableStyle = styled.table`
	width: 100%;
	font-family: 'Pretendard';

	& thead th {
		padding: 20px;
		text-align: start;
		font-size: 14px;
		font-weight: 600;
	}

	& tr {
		cursor: pointer;
	}

	& th,
	td {
		padding: 20px;
		border-top: 1px solid #d4d4d8;
		border-bottom: 1px solid #d4d4d8;
		font-size: 14px;
		font-weight: 300;
	}

	/* & tbody tr:nth-of-type(2n) td {
		padding: 20px 0;
	} */

	& td img {
		cursor: pointer;
	}

	& .mix-checkbox-white {
		color: white;
		padding: 0;
	}
`;

const ToggleRow = styled.tr<{ open: boolean }>`
	& td {
		color: #effd60;
	}

	${(props) => {
		if (props.open) {
			return css`
				background: #444444;
			`;
		} else {
			return css`
				background: #1e1e1e;
				& td {
					padding: 0;
				}
			`;
		}
	}};
`;

const ToggleContents = styled.div`
	div,
	span,
	font {
		color: #effd60 !important;
	}

	div[role='note'] {
		.notranslate {
			color: #000000 !important;
			.notion-enable-hover {
				color: #000000 !important;
			}
		}
	}
`;

interface IMixToggleGrid {
	columns: any;
	rows: any;
	getToggleContents: (param: any) => ReactNode;
}

const noRowsOverlay = () => {
	return (
		<Stack height="100%" alignItems="center" justifyContent="center">
			데이터 없음
		</Stack>
	);
};

const noResultsOverlay = () => {
	return (
		<Stack height="100%" alignItems="center" justifyContent="center">
			조회한 결과가 없습니다.
		</Stack>
	);
};

const datagridSx = {
	fontSize: '14px',
	color: 'white'
};

interface IMixGrid {
	columns: Array<IColumn>;
	rows: Array<any>;
	isCheck?: boolean;
	headerElement?: React.ReactNode;
	toggleCompoents?: React.ReactNode;
	toggleOption?: { components?: React.ReactNode; field?: string };
	pointField?: string;
}

const TableRow = ({
	columns,
	row,
	toggleOption,
	pointField,
	rowIndex,
	onClickRow
}: {
	columns: Array<IColumn>;
	row: any;
	toggleCompoents?: React.ReactNode;
	toggleOption?: { components?: React.ReactNode; field?: string };
	pointField?: string;
	rowIndex: number;
	onClickRow?: () => void;
}) => {
	const [open, setOpen] = React.useState(false);

	// const handleClick = () => {
	//   setOpen(!open);
	// };

	useEffect(() => {
		setOpen(false);
	}, [row]);

	const getTd = () => {
		return columns.map((column) => {
			switch (column.field) {
				case 'checkbox':
					return (
						<td key={column.field}>
							<Checkbox
								size="small"
								checked={false}
								className="mix-checkbox-white"
								// onChange={changeHandler}
							/>
						</td>
					);
				case 'button':
					return (
						// onClick={handleClick}
						<td key={column.field}>
							{open ? (
								<img height="14px" style={{ transform: `rotate(-90deg)` }} src="/assets/icon/next-w.svg"></img>
							) : (
								<img height="14px" style={{ transform: `rotate(90deg)` }} src="/assets/icon/next-w.svg"></img>
							)}
						</td>
					);
				default:
					return (
						<td key={column.field} style={{ textAlign: column.textAlign ? column.textAlign : 'start' }}>
							{column.customCell
								? column.customCell(row, rowIndex)
								: column.formatValue
									? column.formatValue(row[column.field])
									: row[column.field]}
						</td>
					);
			}
		});
	};

	return (
		<>
			<tr
				onClick={() => {
					if (onClickRow) {
						setOpen(!open);
						onClickRow();
					}
				}}
				style={{ background: open ? '#444444' : '#1E1E1E', color: row[pointField] ? '#EFFD60' : '#F7F7F7' }}
			>
				{getTd()}
			</tr>
			{toggleOption && (
				<ToggleRow open={open}>
					<td></td>
					<td></td>
					<td></td>
					{/* FAQ임시코드 추후 토글 옵션 받는 것으로 변경해야함 */}
					<td colSpan={columns.length - 3}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							{toggleOption.field ? (
								<ToggleContents dangerouslySetInnerHTML={{ __html: row[toggleOption.field] }}></ToggleContents>
							) : (
								toggleOption.components
							)}
						</Collapse>
					</td>
				</ToggleRow>
			)}
		</>
	);
};

interface IColumn {
	field: string;
	headerName: string;
	width?: string;
	textAlign?: string;

	formatValue?: (value: string) => void;
	customCell?: (value: any, index: number) => void;
	onClickRow?: (row: any) => void;
}

export const MixGrid = ({ columns, rows, toggleOption, pointField, onClickRow }: IMixGrid) => {
	return (
		<MixTableStyle>
			<thead>
				<tr>
					{columns.map((column: IColumn) => (
						<th
							key={column.field}
							style={{ width: column.width, textAlign: column.textAlign ? column.textAlign : 'start' }}
						>
							{column.headerName}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{rows.length > 0 ? (
					rows.map((row: any, idx: number) => (
						<TableRow
							key={idx}
							columns={columns}
							row={row}
							rowIndex={idx}
							pointField={pointField}
							toggleOption={toggleOption}
							onClickRow={() => {
								if (onClickRow) {
									onClickRow(row);
								}
							}}
						></TableRow>
					))
				) : (
					<tr>
						<td colSpan={columns.length} style={{ textAlign: 'center' }}>
							데이터가 존재하지 않습니다.
						</td>
					</tr>
				)}
			</tbody>
		</MixTableStyle>
	);
};

export const MixToggleGrid = ({ columns, rows, getToggleContents }: IMixToggleGrid) => {
	const apiRef = useGridApiRef();

	const columnList = columns.map((column: any) => {
		return {
			...column,
			width: column.width ? column.width : 100,
			headerAlign: 'center',
			align: 'center',
			filterable: false,
			// headerClassName: 'CustomHeader',
			renderHeader: (params: GridColumnHeaderParams) => {
				return (
					<p
						style={{
							lineHeight: 1.6,
							textAlign: 'center'
						}}
						dangerouslySetInnerHTML={{ __html: params.colDef.headerName!.replace(/\n/g, '<br />') }}
					/>
				);
			}
		};
	});

	return (
		<DataGridPro
			apiRef={apiRef}
			columns={columnList}
			rows={rows}
			sx={datagridSx}
			rowThreshold={0}
			getDetailPanelContent={getToggleContents}
			getDetailPanelHeight={() => 'auto'}
			hideFooter
			autoHeight={rows.length === 0}
			slots={{
				noRowsOverlay,
				noResultsOverlay
				// columnHeaders
			}}
		/>
	);
};
