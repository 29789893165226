import TrackEditorDialog from '@containers/AlbumManagement/TrackInfoRegistrationModal/TrackEditorDialog';
import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import { Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import AlertModal from '@components/form/AlertModal';
import Row from '@components/form/Row';
import Text from '@components/form/Text';

import TrackFile from '@components/form/TrackFile';

import TrackCard from '@components/molecules/AlbumManagement/TrackCard';

import { SignUpLayout } from '@components/molecules/SignUp/Layout';

import { useFormError } from '@hooks/useFormError';

import { Icon } from '@images/Icon';

import update from 'immutability-helper';
import moment from 'moment';
import { WaveFile } from 'wavefile';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';

type TInfoProps = {
  setCurrentStep: Dispatch<SetStateAction<any>>;
  reduxForm: any;
  setIsDisabledNextButton: any;
};

function Track({ setCurrentStep, reduxForm, setIsDisabledNextButton }: TInfoProps) {
  const [selectedTrackNumber, setSelectedTrackNumber] = useState(0);
  const [isOpenTrackInfoRegistrationModal, setIsOpenTrackInfoRegistrationModal] = useState(false);
  const [trackFiles, setTrackFiles] = useState<any>([]);
  const { ctf_sendHttpDelete } = useContext(BackdropContext);
  const {
    openState: uc_confirmOpen,
    setOpenState: uf_setConfirmOpen,
    message: uc_confirmMessage,
    popupMessage: uf_confirmPopupMessage,
  } = usePopupMessage();

  const { getRootProps: getTrackFileRootProps, getInputProps: getTrackFileInputProps } = useDropzone({
    accept: { 'audio/wav': ['.wav'] },

    // 파일타입, 비트퍼샘플, 샘플레이트 체크로직추가
    onDrop: (acceptedFiles: any) => {
      handleDropFile(acceptedFiles);
    },
  });

  const { setFormData, formData, formFieldsError, validation } = reduxForm;
  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const _retSet = (error: boolean, errorMessage: string) => {
    return {
      error,
      errorMessage,
    };
  };

  ///
  /// 음원파일 선택시 처리 함수
  ///
  async function handleDropFile(dropFiles: any, idx = -1) {
    if (dropFiles.length && dropFiles.length > 1) {
      popupMessage('음원 파일은 한 개씩 선택하여주십시오.');
      return;
    }
    for (const df of dropFiles) {
      if (!df.type.endsWith('wav')) {
        popupMessage('음원 파일은 44.1kHz/16bit 이상의 WAV 파일만 업로드 가능합니다.');
        return;
      }

      const duplicated = trackFiles.find((f) => {
        return f.name === df.name && f.size === df.size;
      });

      if (duplicated) {
        popupMessage('동일한 파일이 존재합니다.');
        return;
      }
      const checkResult = await checkWaveFile(df);

      if (checkResult.error === true) {
        popupMessage(checkResult.errorMessage);
        return;
      } else {
        // idx 인수가 -1이면 추가파일
        if (idx === -1) {
          const fileInfo = {
            info: {
              id: `NT_${Math.random()}_${moment().format('x')}`,
            },
            trackFile: df,
          };

          setTrackFiles((prev: any) => [...prev, fileInfo]);
        } else {
          setTrackFiles((prev: any) => {
            const clones = prev.map((tt: any, ttIdx: any) => {
              if (ttIdx === idx) {
                return {
                  info: tt.info,
                  trackFile: df,
                };
              }
              return tt;
            });

            return [...clones];
          });
        }
      }
    }
  }

  const checkWaveFile = async (file: any) => {
    const getWaveInfo = (fileReader: any) => {
      try {
        const binaryStr = fileReader.result;
        const uint8View = new Uint8Array(binaryStr);
        const wav = new WaveFile(uint8View);
        return wav;
      } catch (ex) {
        return null;
      }
    };
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const wav = getWaveInfo(reader);

        if (!wav) {
          resolve(_retSet(true, '음원 파일을 체크해 주세요.\n44.1kHz/16bit 이상의 WAV 파일만 업로드 가능합니다.'));
        }

        // @ts-ignore
        if (wav && (wav.fmt.sampleRate < 44100 || wav.fmt.bitsPerSample < 16)) {
          const msg = `44.1kHz이상 16비트이상의 음원 파일만 가능합니다. \n 선택한 파일:${wav.fmt.sampleRate / 1000}khz ${
            wav.fmt.bitsPerSample
          }bit`;
          resolve(_retSet(true, msg));
        } else {
          Object.assign(file, {
            remoteFile: false,
            preview: URL.createObjectURL(file),
          });
          resolve(_retSet(false, ''));
          //setTrackFiles(acceptedFiles)
        }
      };
      reader.readAsArrayBuffer(file);
    });

    return await promise;
  };
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setTrackFiles((prevCards: any) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, []);

  ///
  /// 트랙리스트에서 휴지통 삭제버튼을 클릭하면 호출
  /// 서버저장정보는 삭제명령을 내려야함
  ///
  const deleteTrack = (id: any) => {
    if (!id) {
      popupMessage('TRACK ID 에러로 삭제를 실패하였습니다.');
      return;
    }

    // ID가 NT로 시작하면 로컬에서 신규추가한 트랙이다.
    if (typeof id === 'string' && id.startsWith('NT_')) {
      setTrackFiles((prev: any) => prev?.filter((item: any) => item.info.id !== id));
      return;
    }

    // 이하 서버 저장된 트랙에 대한 삭제 처리
    const found = formData.track.find((tf) => tf.info.id === id);
    if (!found) {
      popupMessage('삭제하려는 트랙이 존재하지 않습니다.');
      return;
    }

    // 서버저장 파일인가 확인
    if (!found.trackFile || found.trackFile?.remoteFile) {
      //결과는 deleteRemodetrack에서 처리
      uf_setDelTrkId(id);
      uf_confirmPopupMessage('트랙정보를 삭제하시겠습니까?');
      return;
    } else {
      // 서버에 저장된 트랙정보가 아니면...
    }
  };

  const [uc_delTrkId, uf_setDelTrkId] = useState(null);
  ///
  /// 트랙삭제 컨펌창에서 확인 버튼을 누르면 처리된다.
  ///
  async function deleteRemotetrack() {
    const res = await ctf_sendHttpDelete(`/track/${uc_delTrkId}`);
    if (res.error) {
      popupMessage('트랙정보 삭제를 실패하였습니다.');
      return;
    }

    setTrackFiles((prev: any) => prev?.filter((item: any) => item.info.id !== uc_delTrkId));
  }

  const selectTrackNumber = (index: number) => {
    setSelectedTrackNumber(index);
    setIsOpenTrackInfoRegistrationModal(true);
  };

  const renderCard = useCallback((card, index) => {
    return (
      <TrackCard
        deleteTrack={deleteTrack}
        id={card.info.id}
        index={index}
        key={card.info.id}
        moveCard={moveCard}
        preview={card.preview ?? card.trackFile?.preview}
        setSelectedTrackNumber={selectTrackNumber}
        text={card.trackFile?.name}
        trackInfo={card.info}
        wavfile={card.trackFile}
        changeFile={handleDropFile}
      />
    );
  }, []);
  const setAlbumRegistrationFormData = (e: any, dataIndex: string, isValue?: boolean) => {
    let value = '';
    if (isValue) {
      value = e;
    } else {
      value = e.target.value;
    }
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState('');
  const hideAlert = () => {
    setIsAlertOpen(false);
  };
  const popupMessage = (txt: any) => {
    setAlertText(txt);
    setIsAlertOpen(true);
  };

  const _style = {
    flexColumn: {
      flex: 5,
      flexDirection: 'column',
    },
  };

  ///
  /// useEffect 정의
  ///
  useEffect(() => {
    //
    if (!trackFiles || trackFiles.length === 0 || formData?.track?.every((track: any) => !track.info.is_main)) {
      setIsDisabledNextButton(true);
    } else {
      // 다음 버튼 비활성화 여부처리
      const okTrkFiles = trackFiles.reduce((acc, cur) => acc && cur?.info?.track_artist?.length > 0, true);
      setIsDisabledNextButton(!okTrkFiles);
    }

    setAlbumRegistrationFormData(trackFiles, 'track', true);
  }, [trackFiles, formData?.track]);

  useEffect(() => {
    setTrackFiles(formData?.track ?? []);
  }, []);

  // @ts-ignore
  return (
    <SignUpLayout style={{ background: '#121212' }}>
      <Form style={{ width: '1200px' }}>
        <Text
          style={{
            textAlign: 'center',
            color: '#fff',
          }}
          textType="TITLE"
        >
          트랙정보
        </Text>
        <Stack direction="row" justifyContent="start" alignItems="center" gap="5px" mt="28px" mb="10px">
          <ErrorSharpIcon style={{ color: '#effd60' }} />
          <Typography fontSize="16px" fontWeight="bold" color="white">
            기발매된 음원은 발매가 불가합니다. 발매 이력이 없는 신규 음원만 등록해주세요.
          </Typography>
        </Stack>
        {/* 트랙리스트 출력부분 */}
        <Row justify="FLEX_START" style={_style.flexColumn}>
          {formData?.track?.map((card: any, i: number) => renderCard(card, i))}
        </Row>
        <Stack sx={{ width: '100%' }} spacing={1}>
          <TrackFile
            description={
              <Row direction="column" justify="CENTER" style={{ width: '320px' }}>
                <HDivider margin="15px" type="TRANSPARENT" />
                <Icon
                  icon="addImage"
                  style={{
                    width: '60px',
                    height: '60px',
                  }}
                />
                <HDivider margin="15px" type="TRANSPARENT" />
                <Text style={{ color: '#DDDDDD' }} textType="DESCRIPTION">
                  음원 파일을 업로드 하려면 이곳을 클릭하거나
                </Text>
                <Text style={{ color: '#DDDDDD' }} textType="DESCRIPTION">
                  원하는 파일을 드래그하세요.
                </Text>
                <HDivider margin="15px" type="TRANSPARENT" />
              </Row>
            }
            getInputProps={getTrackFileInputProps}
            getRootProps={getTrackFileRootProps}
          />

          <Text style={{ color: '#BCBCBC' }} textType="DESCRIPTION">
            음원 파일은 44.1kHz/16bit 이상의 WAV 파일만 업로드 가능합니다.
          </Text>
        </Stack>
        <HDivider margin="70px" type="TRANSPARENT" />
      </Form>
      <TrackEditorDialog
        isOpenTrackInfoRegistrationModal={isOpenTrackInfoRegistrationModal}
        selectedTrackNumber={selectedTrackNumber}
        setIsOpenTrackInfoRegistrationModal={setIsOpenTrackInfoRegistrationModal}
        setTrackFiles={setTrackFiles}
        trackFiles={trackFiles}
        trackDatum={formData.track}
      />
      {/* <TrackInfoRegistrationModal */}
      {/*   isOpenTrackInfoRegistrationModal={isOpenTrackInfoRegistrationModal} */}
      {/*   selectedTrackNumber={selectedTrackNumber} */}
      {/*   setIsOpenTrackInfoRegistrationModal={setIsOpenTrackInfoRegistrationModal} */}
      {/*   setTrackFiles={setTrackFiles} */}
      {/*   trackFiles={trackFiles} */}
      {/* /> */}
      <AlertModal isPopupOpen={isAlertOpen} alertText={alertText} hidePopup={hideAlert} />

      {/* 창닫을때 컨펌 팝업창 */}
      <AlertModal
        isPopupOpen={uc_confirmOpen}
        alertText={uc_confirmMessage}
        hidePopup={() => uf_setConfirmOpen(false)}
        cancelButton={true}
        onOkButtonClick={deleteRemotetrack}
      />
    </SignUpLayout>
  );
}

export default Track;
