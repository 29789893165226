import MainHeader from '@components/molecules/MainHeader';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import MainFooter from '@components/molecules/MainFooter';

const Qna = () => {
  return (
    <>
      <MainHeader logo="logoW" />
      <Stack direction="column" paddingY="200px" color="white" justifyContent="center" alignItems="center" bgcolor="#1E1E1E">
        <Stack direction="column" alignItems="center" style={{ minWidth: '1280px', maxWidth: '1280px' }}>
          <Typography fontSize="50px" fontWeight="bold" mb="80px">
            1:1문의
          </Typography>
          <Typography fontSize="24px" mb="40px">
            이메일과 채널톡으로 문의를 남겨주시면 최대한 빨리 답변드릴게요.
          </Typography>

          <Typography fontSize="24px" mb="23px">
            <span style={{ marginRight: '12px', color: '#EFFD60' }}>°</span>이메일 : mixtape@ygmail.net
          </Typography>
          <Typography fontSize="24px" mb="23px">
            <span style={{ marginRight: '12px', color: '#EFFD60' }}>°</span>채널톡 : 화면 우측 하단 채널톡 버튼 클릭
          </Typography>
          <Typography fontSize="24px" color="#F6FDAC" mb="100px">
            (운영시간 : 평일 오전 10시 ~ 오후 6시)
          </Typography>
          <Stack direction="column" padding="60px 174px" borderRadius="24px" gap="40px" bgcolor="#ffffff19" textAlign="center">
            <Typography fontSize="24px" fontWeight="bold" color="#60E2FD">
              비즈니스 제휴 문의
            </Typography>
            <Typography fontSize="24px" lineHeight="40px">
              비즈니스 제휴는 언제든 환영합니다!
              <br />
              아래 이메일 주소로 제휴 문의를 주시면,꼼꼼하게 살펴보고 답변드릴게요.
            </Typography>
            <Typography fontSize="24px">
              <span style={{ marginRight: '12px', color: '#EFFD60' }}>°</span>이메일 : mixtape@ygmail.net
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <MainFooter />
    </>
  );
};

export default Qna;
