import { rakuten } from '@images/png';
export const flatforms = [
  {
    title: '국내 음악 플랫폼',
    channels: [
      {
        title: 'Melon',
        image: '/assets/channel/Melon.png',
        link: 'https://www.melon.com/index.htm',
        countries: ['대한민국'],
      },
      {
        title: 'VIBE',
        image: '/assets/channel/Vibe.png',
        link: 'https://vibe.naver.com/today',
        countries: ['대한민국'],
      },
      {
        title: 'genie',
        image: '/assets/channel/GenieMusic.png',
        link: 'https://www.genie.co.kr/',
        countries: ['대한민국'],
      },
      {
        title: 'FLO',
        image: '/assets/channel/FloMusic.png',
        link: 'https://www.music-flo.com/',
        countries: ['대한민국'],
      },
      {
        title: 'Bugs',
        image: '/assets/channel/BugsMusic.png',
        link: 'https://music.bugs.co.kr/',
        countries: ['대한민국'],
      },
      {
        title: 'KakaoMusic',
        image: '/assets/channel/KakaoMusic.png',
        link: 'https://music.kakao.com/share',
        countries: ['대한민국'],
      },
    ],
  },
  {
    title: '해외 음악 플랫폼',
    channels: [
      {
        title: 'YouTube',
        image: '/assets/channel/Youtube.png',
        link: 'https://www.youtube.com/',
        countries: ['전세계'],
      },
      {
        title: 'YouTube Music',
        image: '/assets/channel/YoutubeMusic.png',
        link: 'https://www.youtube.com/premium',
        countries: ['전세계'],
      },
      {
        title: 'Spotify',
        image: '/assets/channel/Spotify.png',
        link: 'https://open.spotify.com/',
        countries: ['전세계'],
      },
      {
        title: 'Apple Music',
        image: '/assets/channel/AppleMusic.png',
        link: 'https://music.apple.com/',
        countries: ['전세계'],
      },
      {
        title: 'amazon music',
        image: '/assets/channel/AmazonMusic.png',
        link: 'https://music.amazon.com/',
        countries: ['전세계'],
      },
      {
        title: 'SoundCloud',
        image: '/assets/channel/SoundCloud.png',
        link: 'https://soundcloud.com/',
        countries: ['전세계'],
      },
      {
        title: 'deezer',
        image: '/assets/channel/Deezer.png',
        link: 'https://www.deezer.com/soon',
        countries: ['전세계'],
      },
      {
        title: 'TIDAL',
        image: '/assets/channel/Tidal.png',
        link: 'https://tidal.com/',
        countries: ['전세계'],
      },
      {
        title: 'TikTok Music',
        image: '/assets/channel/TiktokMusic.png',
        link: 'https://music.tiktok.com/au/',
        countries: ['호주', '브라질', '싱가포르', '멕시코', '인도네시아'],
      },
      {
        title: 'Pandora',
        image: '/assets/channel/Pandora.png',
        link: 'https://www.pandora.com/restricted',
        countries: ['미국'],
      },
      {
        title: 'siriusxm',
        image: '/assets/channel/siriusxm.png',
        link: 'https://www.siriusxm.com/',
        countries: ['미국'],
      },
      {
        title: 'LINE MUSIC',
        image: '/assets/channel/LineMusic.png',
        link: 'https://music.line.me/about/',
        countries: ['일본', '대만'],
      },
      {
        title: 'KKBOX',
        image: '/assets/channel/Kkbox.png',
        link: 'https://www.kkbox.com/intl/',
        countries: ['대만', '홍콩', '마카오', '일본', '싱가폴', '말레이시아'],
      },
      {
        title: 'JOOX',
        image: '/assets/channel/Joox.png',
        link: 'https://www.joox.com/intl',
        countries: ['홍콩', '마카오', '태국', '인도네시아', '말레이시아'],
      },
      {
        title: 'Yandex Music',
        image: '/assets/channel/YandexMusic.png',
        link: 'https://music.yandex.com/',
        countries: ['동유럽 20여 개 국가', '러시아', '체코'],
      },
      {
        title: 'anghami',
        image: '/assets/channel/Anghami.png',
        link: 'https://www.anghami.com/',
        countries: ['중동 20여 개 국가', '사우디아라비아', 'UAE'],
      },
      {
        title: 'resso',
        image: '/assets/channel/Resso.png',
        link: 'https://www.resso.com/in/',
        countries: ['미국'],
      },
      {
        title: 'AWA',
        image: '/assets/channel/AWA.png',
        link: 'https://awa.fm/',
        countries: ['일본'],
      },
      {
        title: 'au Smartpass Premium Music',
        image: '/assets/channel/auSmartpassPremiumMusic.png',
        link: 'https://au.utapass.auone.jp/',
        countries: ['일본'],
      },
      {
        title: 'QQ Music',
        image: '/assets/channel/QQmusic.png',
        link: 'https://y.qq.com/',
        countries: ['미국'],
      },
      {
        title: 'KuGou',
        image: '/assets/channel/KuGou.png',
        link: 'https://www.kugou.com/',
        countries: ['중국'],
      },
      {
        title: 'KUWO',
        image: '/assets/channel/Kuwo.png',
        link: 'https://www.kuwo.cn/',
        countries: ['중국'],
      },
      {
        title: 'ULTIMATE MUSIC',
        image: '/assets/channel/UltimateMusic.png',
        link: 'https://www.ultimatemusic.cn/',
        countries: ['중국'],
      },
      {
        title: 'WeSing',
        image: '/assets/channel/WeSing.png',
        link: 'https://www.wesingapp.com/',
        countries: ['중국'],
      },
      {
        title: 'NetEase',
        image: '/assets/channel/NetEase.png',
        link: 'https://music.163.com/',
        countries: ['중국'],
      },
      {
        title: 'Soda Music',
        image: '/assets/channel/SodaMusic.png',
        link: 'https://music.douyin.com/qishui',
        countries: ['중국'],
      },

      {
        title: 'JioSaavn',
        image: '/assets/channel/JioSaavn.png',
        link: 'https://www.jiosaavn.com/',
        countries: ['인도'],
      },

      {
        title: 'Zing MP3',
        image: '/assets/channel/ZingMP3.png',
        link: 'https://zingmp3.vn/',
        countries: ['베트남'],
      },
      {
        title: 'NCT',
        image: '/assets/channel/NCT.png',
        link: 'https://www.nhaccuatui.com/',
        countries: ['베트남'],
      },
      {
        title: 'MOOV',
        image: '/assets/channel/MOOV.png',
        link: 'https://moov.hk/',
        countries: ['홍콩'],
      },
      {
        title: 'Boomplay',
        image: '/assets/channel/Boomplay.png',
        link: 'https://www.boomplay.com/',
        countries: ['아프리카 9개 국가', '나이지리아', '가나', '케냐', '탄자니아', '남아프리카공화국', '우간다'],
      },
      {
        title: 'Rakuten music',
        imgShape: true,
        image: rakuten,
        link: 'https://music.rakuten.co.jp/',
        countries: ['일본'],
      },
    ],
  },
  {
    title: '소셜 플랫폼',
    channels: [
      {
        title: 'TikTok',
        image: '/assets/channel/Tiktok.png',
        link: 'https://www.tiktok.com/explore',
        countries: ['전세계'],
      },
      {
        title: 'Instagram',
        image: '/assets/channel/Instagram.png',
        link: 'https://www.instagram.com/',
        countries: ['전세계'],
      },
      {
        title: 'Facebook',
        image: '/assets/channel/Facebook.png',
        link: 'https://www.facebook.com/',
        countries: ['전세계'],
      },
    ],
  },
];
