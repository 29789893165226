import React from 'react';

import styled from '@emotion/styled';

export type TInputParams = any;

const StyledInput = styled.input<TInputParams>`
  height: 15px;
  padding: 17px 20px 16px 20px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  font-size: 13px;
  font-family: 'Pretendard';

  &:hover {
    outline: 0;
    box-shadow: none;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &:disabled {
    border: 1px solid #dcdcdc;
  }
`;

const Input = ({ ...rest }: TInputParams) => <StyledInput {...rest} maxLength={rest?.maxLength ?? 100} />;

export default Input;
