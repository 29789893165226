import React, { ReactElement } from 'react';

import { css, Global } from '@emotion/react';

function GlobalStyle(): ReactElement {
  return (
    <Global
      styles={css`
        textarea::placeholder,
        input::placeholder {
          color: #ccc !important;
          font-size: 14px;
        }

        * {
          -webkit-text-stroke: 0.25px !important;
        }

        html,
        body {
          height: 100%;
          width: 100%;
          line-height: 1.5;

          font-family: 'Pretendard' !important;
          margin: 0 auto;
          background: #121212;
        }

        table {
          padding: 0;
          border: 0;
          border-spacing: 0px;
          border-collapse: collapse;
        }

        th,
        td {
          padding: 0;
        }

        button {
          background: inherit;
          border: none;
          box-shadow: none;
          border-radius: 0;
          padding: 0;
          overflow: visible;
          white-space: nowrap;
          cursor: pointer;
        }

        .MuiTypography-root {
          font-family: 'Pretendard' !important;
        }

        @keyframes slide {
          from {
            transform: translateX(0);
          }

          to {
            transform: translateX(-100%);
          }
        }

        .logos {
          overflow: hidden;
          padding: 60px 0;
          white-space: nowrap;
        }

        .logo-slide {
          display: inline-block;
          animation: 30s slide infinite linear;
        }

        .logo-slide img {
          height: 50px;
          margin: 0 60px;
        }

        //::-webkit-scrollbar {
        //    width: 10px;
        //    height: 10px;
        //    /*display: none;*
        //}
        //::-webkit-scrollbar-button:start:decrement,
        //::-webkit-scrollbar-button:end:increment  {
        //    height: 0px;
        //    background-color: transparent;
        //}
        //::-webkit-scrollbar-track-piece  {
        //    background-color: transparent;
        //    -webkit-border-radius: 16px;
        //}
        //::-webkit-scrollbar-thumb:vertical {
        //    height: 50px;
        //    background-color: #66666680;
        //    border: 1px solid #eee;
        //    -webkit-border-radius: 6px;
        //}
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type='number'] {
          -moz-appearance: textfield; /* Firefox */
        }

        #root {
          min-height: 100%;
          min-width: 100%;
          font-family: 'Pretendard';
          overflow: hidden;
          --swiper-pagination-color: #b760fc;
          --swiper-pagination-bullet-size: 10px;
          --swiper-pagination-bullet-width: 10px;
          --swiper-pagination-bullet-height: 10px;
          --swiper-pagination-bullet-horizontal-gap: 8px;
        }

        .swiper-pagination-color-dark {
          --swiper-pagination-color: white;
          --swiper-pagination-bullet-size: 10px;
          --swiper-pagination-bullet-width: 10px;
          --swiper-pagination-bullet-height: 10px;
          --swiper-pagination-bullet-horizontal-gap: 8px;
          --swiper-pagination-bullet-inactive-color: white;
          --swiper-pagination-bullet-inactive-opacity: 1;
        }

        .swiper-pagination-horizontal {
          bottom: -50px !important;
        }

        .MuiFormHelperText-root {
          color: red !important;
        }

        .MuiFormControlLabel-root {
          font-family: 'Pretendard';
        }
        .MuiChip-label {
          font-family: 'Pretendard';
          font-size: 13px;
        }

        #myVideo {
          position: absolute;
          top: 50%;
          left: 50%;
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          -o-object-fit: cover;
          object-fit: cover;
          transform: translateX(-50%) translateY(-50%) translateZ(0);
        }

        :root {
          --video-width: 100vw;
          --video-height: 100vh;
        }

        @media (min-aspect-ratio: 16/9) {
          :root {
            --video-height: 56.25vw;
          }
        }
        @media (max-aspect-ratio: 16/9) {
          :root {
            --video-width: 177.78vh;
          }
        }

        .MuiDialogContent-root::-webkit-scrollbar,
        .MuiAutocomplete-paper::-webkit-scrollbar,
        textarea::-webkit-scrollbar,
        .MuiPaper-root::-webkit-scrollbar {
          width: 10px;
        }

        .MuiDialogContent-root::-webkit-scrollbar-thumb,
        .MuiAutocomplete-paper::-webkit-scrollbar-thumb,
        textarea::-webkit-scrollbar-thumb,
        .MuiPaper-root::-webkit-scrollbar-thumb {
          background-color: #66666680;
          border-radius: 10px;
          background-clip: padding-box;
          border: 2px solid transparent;
        }

        .MuiDialogContent-root::-webkit-scrollbar-track,
        .MuiAutocomplete-paper::-webkit-scrollbar-track,
        .MuiPaper-root::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 10px;
          box-shadow: inset 0px 0px 5px white;
        }

        .poster-slide-bg iframe {
          position: absolute;
          top: 50%;
          left: 50%;
          width: var(--video-width);
          height: var(--video-height);
          transform: translate(-50%, -50%);
        }

        .one {
          background-color: #ffffff80;
          width: 100%;

          /* 스크롤바가 absolute로 들어가기 때문에
             여기다가 relative를 준다 */

          overflow: auto;
        }

        .two {
          width: 100%;
          height: 250px;
          overflow-y: scroll;
          /* 여기다가 padding값 입력하면 
             스크롤바가 상자 밖으로 나감 */
        }

        .two::-webkit-scrollbar {
          /* 스크롤바 너비 조절하는 부분 */
          width: 10px;
        }

        .two::-webkit-scrollbar,
        .two::-webkit-scrollbar-thumb {
          overflow: visible;
          border-radius: 4px;
        }

        .two::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.2);
        }

        .cover-bar {
          width: 10px;
          height: 10vh;
          position: absolute;
          top: 0;
          right: 0;
          -webkit-transition: all 0.5s;
          opacity: 1;
          /* 배경색을 상자색과 똑같이 맞춰준다 */
          background-color: #ffffff80;
        }

        /* 중요한 부분 */

        .one:hover .cover-bar {
          opacity: 0;
          -webkit-transition: all 0.5s;
        }

        /* 메인 애니메이션 효과*/

        @-webkit-keyframes spinner {
          0% {
            transform: rotate(0deg) translateZ(0);
          }
          to {
            transform: rotate(1turn) translateZ(0);
          }
        }
        @keyframes spinner {
          0% {
            transform: rotate(0deg) translateZ(0);
          }
          to {
            transform: rotate(1turn) translateZ(0);
          }
        }
        @-webkit-keyframes rainbow-swirl {
          0% {
            background-position: 100%;
            transform: rotate(0deg) translateZ(0);
          }
          to {
            background-position: 0;
            transform: rotate(1turn) translateZ(0);
          }
        }
        @keyframes rainbow-swirl {
          0% {
            background-position: 100%;
            transform: rotate(0deg) translateZ(0);
          }
          to {
            background-position: 0;
            transform: rotate(1turn) translateZ(0);
          }
        }
        @-webkit-keyframes flicker {
          0%,
          to {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
        }
        @keyframes flicker {
          0%,
          to {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
        }
        @-webkit-keyframes back {
          0%,
          to {
            transform: translateY(0) translateZ(0);
          }
          50% {
            transform: translateY(44px) translateZ(0);
          }
        }
        @keyframes back {
          0%,
          to {
            transform: translateY(0) translateZ(0);
          }
          50% {
            transform: translateY(44px) translateZ(0);
          }
        }
        @-webkit-keyframes beyond {
          0%,
          to {
            transform: translateY(0) translateZ(0);
          }
          50% {
            transform: translateY(-44px) translateZ(0);
          }
        }
        @keyframes beyond {
          0%,
          to {
            transform: translateY(0) translateZ(0);
          }
          50% {
            transform: translateY(-44px) translateZ(0);
          }
        }
        @-webkit-keyframes banner-scroll-1 {
          0% {
            transform: translateX(0) translateZ(0);
          }
          to {
            transform: translateX(-100%) translateZ(0);
          }
        }
        @keyframes banner-scroll-1 {
          0% {
            transform: translateX(0) translateZ(0);
          }
          to {
            transform: translateX(-100%) translateZ(0);
          }
        }
        @-webkit-keyframes banner-scroll-2 {
          0% {
            transform: translateX(100%) translateZ(0);
          }
          to {
            transform: translateX(0) translateZ(0);
          }
        }
        @keyframes banner-scroll-2 {
          0% {
            transform: translateX(100%) translateZ(0);
          }
          to {
            transform: translateX(0) translateZ(0);
          }
        }
        @-webkit-keyframes ripple {
          0% {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes ripple {
          0% {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @-webkit-keyframes unripple {
          0% {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
        @keyframes unripple {
          0% {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }

        #home-page {
          height: 100vh;
          height: calc(var(--vh, 1vh) * 100);
          background-color: #000;
        }

        #home-page-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          cursor: -webkit-grab;
          cursor: grab;
        }

        .intro-text {
          font-family: 'Titillium Web';
          line-height: 1.6;
          color: #fff;
          font-size: 50px;
        }

        .intro-sub-text {
          font-family: 'Pretendard';
          font-weight: normal;
          line-height: 1.6;
          color: #fff;
          font-size: 28px;
          margin-bottom: 5px;
        }

        #intro-footer {
          display: none;
        }

        #home-page-inner.grabbing {
          cursor: -webkit-grabbing;
          cursor: grabbing;
        }

        #home-page-inner .slide-content h1,
        #home-page-inner .slide-content h2 {
          margin-right: 21.4285714286vw;
        }

        #home-page-inner .slide-content h1 br,
        #home-page-inner .slide-content h1 br:after,
        #home-page-inner .slide-content h2 br,
        #home-page-inner .slide-content h2 br:after {
          content: ' ';
        }

        #home-page-inner .slide-content h1 span,
        #home-page-inner .slide-content h2 span {
          display: block;
        }

        #poster-slides,
        #poster-slides .poster-slide {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        #poster-slides .poster-slide {
          background-color: #000;
          opacity: 0;
          transition: opacity 666ms cubic-bezier(0, 0, 0.666, 0.976);
        }

        #poster-slides .poster-slide.active {
          opacity: 1;
        }

        #poster-slides .poster-slide.no-trans {
          transition: none;
        }

        #poster-slides .poster-slide .poster-slide-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-color: #000;
        }

        #poster-slides .poster-slide .poster-slide-bg img,
        #poster-slides .poster-slide .poster-slide-bg video {
          position: absolute;
          top: 50%;
          left: 50%;
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          -o-object-fit: cover;
          object-fit: cover;
          transform: translateX(-50%) translateY(-50%) translateZ(0);
        }

        #poster-slides .poster-slide .poster-slide-bg .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-color: #000;
          opacity: 0.5;
        }

        #home-slider-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          transform: translateY(0) translateZ(0);
        }

        #home-slider-wrapper.back {
          -webkit-animation: back 333ms cubic-bezier(0.666, 0, 0.666, 0.976) forwards;
          animation: back 333ms cubic-bezier(0.666, 0, 0.666, 0.976) forwards;
        }

        #info-slider-wrapper {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 50%;
          min-height: 320px;
          overflow: hidden;
          transform: translateY(-50%) translateZ(0);
        }

        #info-slider-wrapper #info-slider {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: translateY(0) translateZ(0);
          transition: transform 666ms cubic-bezier(0.666, 0, 0.666, 0.976);
          will-change: transform;
        }

        #info-slider-wrapper #info-slider.no-trans {
          transition: none;
        }

        #info-slider-wrapper #info-slider .info-slide {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          opacity: 0;
          transform: translateZ(0);
          transition: opacity 666ms cubic-bezier(0.666, 0, 0.666, 0.976);
          will-change: opacity;
        }

        #info-slider-wrapper #info-slider .info-slide.active {
          opacity: 1;
        }

        #info-slider-wrapper #info-slider .info-slide.no-trans {
          transition: none;
        }

        #info-slider-wrapper .gutter {
          height: 100%;
          align-items: center;
          display: flex;
          position: relative;
          margin: 0 5.3571428571vw;
        }

        #slider-pagination {
          position: absolute;
          top: 50%;
          right: 5.3571428571vw;
          width: 40px;
          margin-right: -14px;
          transform: translateY(-50%) translateZ(0);
        }

        #slider-pagination .pagination-item {
          position: relative;
          width: 100%;
          height: 30px;
          overflow: hidden;
          cursor: pointer;
        }

        #slider-pagination .pagination-item:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 12px;
          height: 2px;
          background-color: #f4f4f4;
          transform-origin: 0 0;
          transform: rotate(-90deg) translateX(-50%) translateY(-50%) translateZ(0);
          transition: background-color 666ms cubic-bezier(0.666, 0, 0.666, 0.976),
            transform 666ms cubic-bezier(0.666, 0, 0.666, 0.976);
        }

        #slider-pagination .pagination-item.current {
          cursor: default;
        }

        #slider-pagination .pagination-item.current:after {
          transform: rotate(0deg) translateX(-50%) translateY(-50%) translateZ(0);
        }

        #slider-pagination.invert .pagination-item:after {
          background-color: #000;
        }

        @media (min-width: 768px) {
          #home-page-inner .slide-content > br {
            display: none;
          }

          #home-page-inner .slide-content a.alt-link {
            margin-left: 2em;
          }

          #home-page-inner .slide-content h1,
          #home-page-inner .slide-content h2 {
            margin-right: 10.7142857143vw;
            margin-top: 0;
          }

          #home-page-inner .slide-content h1 br,
          #home-page-inner .slide-content h2 br {
            content: '';
            display: block;
          }

          #home-page-inner .slide-content h1 br:after,
          #home-page-inner .slide-content h2 br:after {
            display: none;
          }

          #home-page-inner .slide-content h1 span,
          #home-page-inner .slide-content h2 span {
            display: inline;
          }

          #home-page-inner .slide-content h1 span:first-of-type:after,
          #home-page-inner .slide-content h2 span:first-of-type:after {
            content: ' ';
          }

          #slider-pagination {
            margin-right: -13px;
          }

          #slider-pagination .pagination-item {
            height: 34px;
          }

          #slider-pagination .pagination-item:after {
            width: 14px;
          }
        }

        .swiper {
          overflow: visible;
        }

        .swiper-slide {
          width: fit-content;
        }

        .mixswiper-active.swiper-slide-active {
          margin-top: -40px;
        }

        hr {
          box-sizing: content-box;
          border-style: none;
          overflow: visible;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
      `}
    />
  );
}

export default GlobalStyle;
