import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import {
  AUTH_FIND_PASSWORD_ACTION,
  AUTH_FIND_PASSWORD_GET_NUMBER_ACTION,
  AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
} from '@constants/actionNames';
import AuthNumberCertification from '@containers/UserInfoSetting/EditPasswordModal/AuthNumberCertification';
import Completed from '@containers/UserInfoSetting/EditPasswordModal/Completed';
import ResetPassword from '@containers/UserInfoSetting/EditPasswordModal/ResetPassword';
import { useReduxData } from '@hooks/useReduxData';
import { $mixtapeGetUser } from '@utils/auth';
import { Button, Stack } from '@mui/material';
import styled from '@emotion/styled';
import MixButton from '@components/ui/MixButton';
import history from '@utils/history';
import { useModal } from '@hooks/useModal';

export type TCurrentStep = 'AUTH_NUMBER' | 'RESET' | 'COMPLETED';

type TCancelMembershipModalProps = {
  setIsOpenCancelMembershipModal: () => void;
  isOpenCancelMembershipModal: boolean;
  callback: () => void;
};

const CancelMembershipModal = ({
  isOpenCancelMembershipModal,
  setIsOpenCancelMembershipModal,
  callback,
}: TCancelMembershipModalProps) => {
  return (
    <Modal
      modalStyle={{ width: '660px' }}
      closeButtonStyle={{ width: '24px', heigth: '24px' }}
      hide={setIsOpenCancelMembershipModal}
      isShown={isOpenCancelMembershipModal}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" padding="15px">
        <span style={{ fontSize: '18px', fontWeight: 600 }}>연간 이용권을 사용 중입니다.</span>
        <span style={{ fontSize: '34px', fontWeight: 600 }}>
          <span style={{ color: '#FF3120' }}>정기결제를 해지</span>할까요?
        </span>
        <ul
          style={{
            listStyle: 'disc',
            paddingLeft: '51px',
            margin: '50px 0',
            fontSize: '18px',
            fontWeight: 300,
            color: '#767676',
            lineHeight: '30px',
          }}
        >
          <li>
            다음 정기결제일까지 멤버십 이용이 가능하며, <br /> 연간 이용권 사용이 종료되면 정기결제가 해지됩니다.
          </li>
          <li style={{ margin: '10px 0' }}>
            믹스테이프를 통해 발매된 앨범은 이용권 종료일 이후 <br /> 모든 음원 플랫폼에서 순차적으로 서비스 중지됩니다.
          </li>
          <li>
            환불 또는 중도 해지를 원하시는 경우,{' '}
            <MixButton
              onClick={() => {
                history.push('/intro/membership');
              }}
              text="환불 안내"
              butonType="TEXT"
              fontColor="#FF3120"
              style={{ textDecoration: 'underline' }}
            />{' '}
            내용을 참고해 주세요.
          </li>
        </ul>
        <Stack direction="row" gap="22px" mb="35px">
          <MixButton
            onClick={() => {
              setIsOpenCancelMembershipModal();
            }}
            text="닫기"
            butonType="OUTLINE"
            padding="22px 124px"
            bgColor="white"
            fontColor="black"
          />
          <MixButton
            onClick={() => {
              callback();
            }}
            text="해지"
            padding="22px 124px"
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default CancelMembershipModal;
