import React from 'react';

import styled from '@emotion/styled';

const Wrapper = styled.div`
  height: auto;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  flex: 1;
  display: flex;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
`;

const RadioButtonLabel = styled.label<any>`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #dcdcdc;
  ${(props) =>
    props.checked &&
    `
    background: radial-gradient(circle, transparent 40%, #121212 40%);;
    content: "";
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 4.5px;
      
    }
  `}
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
`;

const Label = styled.div`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* color/txt_3b3b3b */

  color: #3b3b3b;
`;

const Radio = ({ options, onChange, value }: any) => (
  <Wrapper>
    {options.map((option: Record<string, any>) => (
      <Item key={option.value} style={{ flex: 1 / options.length }}>
        <RadioButton name="radio" onChange={onChange} type="radio" value={option.value} />
        <RadioButtonLabel checked={value === option.value} />
        <Label>{option.label}</Label>
      </Item>
    ))}
  </Wrapper>
);

export default Radio;
