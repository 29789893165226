import Button from '@components/form/Button';
import styled from '@emotion/styled';

export const GetAuthNumberButton = styled(Button)`
  height: 50px;
  border-radius: 10px;
  padding: 17px 26px 16px;
  background: #121212;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;

  &:disabled {
    background: #fff;
    color: #000;
    border: 1px solid #dedede;
    cursor: not-allowed;
  }
`;

export const FindPasswordButton = styled(Button)`
  height: 52px;
  border-radius: 10px;
  padding: 17px 50px;
  background: #121212;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
`;

export const RegisterButton = styled(Button)`
  height: 52px;
  border-radius: 10px;
  padding: 17px 20px;
  background: #121212;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: #fff;
`;

export const ResendAuthNumberButton = styled(Button)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #5b5b5b;
  height: 0;
  background: none;
  padding: 0;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const ConfirmButton = styled(Button)`
  height: 52px;
  padding: 17px 50px;
  background: #121212;
  border-radius: 10px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  text-align: center;

  color: #ffffff;
`;
