import React from 'react';

import styled from '@emotion/styled';

const ContainerBox = styled.div`
  width: 310px;
  height: 340px;
  left: 0px;
  top: 0px;

  /* color/222 */

  background: #222222;
  border-radius: 10px;
  color: #e8e8e8;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

function ReleaseAlbumDescBox({ children, ...rest }: { children: JSX.Element | Array<JSX.Element> }) {
  return <ContainerBox {...rest}>{children}</ContainerBox>;
}

export default ReleaseAlbumDescBox;
