import React, { ReactElement, useMemo, useState } from 'react';

import HDivider from '@components/form/HDivider';
import { SettlementTableContainer, SettlementTitleBox } from '@components/molecules/SettlementManagment/Layout';
import { SettlementManagementListTitle } from '@components/molecules/SettlementManagment/Text';

import SelectableTable, { TTableProps } from './SelectableTable';

type TSettlementListProps = TTableProps;

function SettlementList({ columns, data }: TSettlementListProps): ReactElement {
  return (
    <>
      {data && data.length > 0 && (
        <SettlementTitleBox>
          <HDivider margin="40px" type="TRANSPARENT" />
          <SettlementManagementListTitle>정산 내역</SettlementManagementListTitle>
          <HDivider margin="12px" type="TRANSPARENT" />
        </SettlementTitleBox>
      )}

      <SettlementTableContainer>
        <SelectableTable columns={columns} data={data} />
      </SettlementTableContainer>
    </>
  );
}

export default SettlementList;
