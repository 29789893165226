import React, { HTMLAttributes, ReactElement } from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

interface ITextAreaParams extends HTMLAttributes<HTMLTextAreaElement> {
  maxLength: number;
}

const StyledTextArea = styled.textarea<ITextAreaParams>`
  white-space: pre-line;
  padding: 17px 20px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  /* color/txt_5b5b5b */

  color: #5b5b5b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  resize: none;
  &:hover {
    outline: 0;
    box-shadow: none;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

const Textarea = ({ ...rest }: ITextAreaParams): ReactElement => <StyledTextArea {...rest} />;

export default Textarea;
