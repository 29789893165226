import ScrollToTop from '@containers/App/Nav/ScrollToTop';
import Payment from '@containers/Payment';
import MembershipCompleted from '@containers/Payment/MembershipCompleted';
import PaymentFailed from '@containers/Payment/PaymentFailed';
import { useBackdrop, BackdropContext } from '@hooks/usePopupMessage';
import { Backdrop, CircularProgress, createTheme, GlobalStyles, Stack, ThemeProvider, Typography } from '@mui/material';
import { apiRequest } from '@utils/api';
import { $mixtapeUserSeq, getUserSeq } from '@utils/auth';
import React, { ReactElement, createContext, useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation } from 'react-router';
import { Route, Switch} from 'react-router-dom';

import {
  AlbumManagement,
  Login,
  NotFoundError,
  Portal,
  ProfileManagement,
  SettlementManagement,
  SignUp,
  UserInfoSetting,
} from '@/core/routes/Loadable';
import RequireAuthRoute from '@/core/routes/RequireAuthRoute';
import RoutesLayout from '@/core/routes/RoutesLayout';
import GlobalStyle from '@/global-styles';
import { routePath } from '@constants/path';
import { useModal } from '@hooks/useModal';
import Modal from '@components/form/Modal';

// 프로필 관련 컨텍스트
export const ProfileContext = createContext({});

// 앨범등록 관리 컨텍스트
export const AlbumContext = createContext({});

// function AfterLoginLayout() {
//   return (
//     <Switch>
//       <RequireAuthRoute component={RoutesLayout} />
//     </Switch>
//   );
// }

const theme = createTheme({
  typography: {
    fontFamily: 'Pretendard',
    fontWeightBold: 600,
    fontWeightRegular: 300,
  },
});

function App(): ReactElement {
  // 프로필 컨텍스트 관련
  const [curProfileContext, changeProfileContext] = useState({});
  const [profileData, setProfileData] = useState([]);
  const { pathname, search } = useLocation();

  const changeProfile = (newProfile: any) => {
    changeProfileContext(newProfile);
  };

  // 앨범관리 컨텍스트 관련
  const [ctc_albumInfo, ctf_setAlbumInfo] = useState({});

  async function ctf_sendHttpPost(url: any, params: any, freezing: boolean = true) {
    if (freezing) {
      ctf_showBackdrop(true);
    }

    try {
      const result = await apiRequest({
        url,
        method: 'POST',
        data: params,
      });

      ctf_showBackdrop(false);
      if (!result.headers || !result.data) {
        return {
          error: true,
          errorMsg: '서버에서 처리결과를 전달하지 않았습니다.',
          data: null,
        };
      }
      return result;
    } catch (ex) {
      ctf_showBackdrop(false);
      return {
        error: true,
        errorMsg: ex,
        data: ex.response?.data,
      };
    }
  }

  async function ctf_sendHttpPatch(url: any, params: any) {
    ctf_showBackdrop(true);

    try {
      const result = await apiRequest({
        url,
        method: 'PATCH',
        data: params,
      });
      ctf_showBackdrop(false);
      return result;
    } catch (ex) {
      ctf_showBackdrop(false);
      return {
        error: true,
        errorMsg: ex,
        data: null,
      };
    }
  }

  async function ctf_sendHttpPut(url: any, params: any) {
    ctf_showBackdrop(true);
    try {
      const result = await apiRequest({
        url,
        method: 'PUT',
        data: params,
      });
      ctf_showBackdrop(false);
      return result;
    } catch (ex) {
      ctf_showBackdrop(false);
      return {
        error: true,
        errorMsg: ex,
        data: null,
      };
    }
  }

  async function ctf_sendHttpGet(url: any, params: any, freezing: boolean = true) {
    if (freezing) ctf_showBackdrop(true);
    try {
      const result = await apiRequest({
        url,
        method: 'GET',
        params,
      });
      ctf_showBackdrop(false);
      return result;
    } catch (ex) {
      ctf_showBackdrop(false);
      return {
        error: true,
        errorMsg: ex,
        data: null,
      };
    }
  }

  async function ctf_sendHttpDelete(url: any, params: any, freezing: boolean = true) {
    if (freezing) ctf_showBackdrop(true);
    try {
      const result = await apiRequest({
        url,
        method: 'DELETE',
        params,
      });
      ctf_showBackdrop(false);
      return result;
    } catch (ex) {
      ctf_showBackdrop(false);
      return {
        error: true,
        errorMsg: ex,
        data: null,
      };
    }
  }

  async function ctf_callProfileData() {
    const userseq = $mixtapeUserSeq();
    const res = await ctf_sendHttpGet(`/profile/${userseq}`, {}, false);

    if (res.error) {
      return [];
    } else {
      if (res.data && res.data.length > 0) {
        // @ts-ignore
        const arr = res.data
          .filter((profile) => profile.artists.is_deleted === 0)
          .map((ele) => {
            return { ...ele.artists };
          });
        return arr;
      } else {
        return [];
      }
    }
  }

  const refreshProfileData = (arr: any) => {
    if (!arr) {
      return;
    }
    setProfileData(arr);
  };

  const { ctc_backdropOpen, ctf_setBackdropOpen, ctc_loadingText, ctf_setLoadingText, ctf_showBackdrop } = useBackdrop();

  function testIt(t: any) {
    ctf_showBackdrop(true, t);
    setTimeout(() => {
      ctf_showBackdrop(false);
    }, 5400);
  }
  useEffect(() => {
    const isMobile = /Mobi/i.test(window.navigator.userAgent)
    if (isMobile){
      window.open(`${process.env.REACT_APP_M_URL}${pathname}${search}`,'_self')
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <GlobalStyle />
        <Backdrop
          open={ctc_backdropOpen}
          sx={{
            color: '#fff',
            zIndex: 9999999,
          }}
        >
          <Stack justifyContent="center" alignItems="center" spacing={3}>
            <CircularProgress sx={{ color: '#EFFD60' }} />
            {ctc_loadingText !== '' && <Typography>{ctc_loadingText}</Typography>}
          </Stack>
        </Backdrop>
        <Switch>
          <ProfileContext.Provider
            value={{
              curProfileContext,
              changeProfile,
              profileData,
              refreshProfileData,
            }}
          >
            <AlbumContext.Provider
              value={{
                ctc_albumInfo,
                ctf_setAlbumInfo,
              }}
            >
              <BackdropContext.Provider
                value={{
                  ctf_showBackdrop,
                  ctf_setLoadingText,
                  ctf_sendHttpGet,
                  ctf_sendHttpDelete,
                  ctf_sendHttpPatch,
                  ctf_callProfileData,
                  ctf_sendHttpPost,
                  ctf_sendHttpPut,
                }}
              >
                <Route component={Portal} exact path={routePath.portal.path} />
                <Route component={Login} exact path={routePath.login.path} />
                <Route component={SignUp} exact path={routePath.signup.path} />
                <Route component={Payment} exact path={routePath.payment.path} />
                <Route component={MembershipCompleted} exact path={routePath.paymentCompleted.path} />
                <Route component={PaymentFailed} exact path={routePath.paymentFailed.path} />
                <Route component={AlbumManagement} exact path={routePath.album.albumManagement.path} />

                <Route component={RoutesLayout} />

                {/* <Route component={AfterLoginLayout} /> */}
              </BackdropContext.Provider>
            </AlbumContext.Provider>

            {/*
          <Route component={AlbumManagement} exact path={routePath.album.albumManagement.path} />
          <Route component={ProfileManagement} exact path={routePath.profile.profileManagement.path} />
          <Route component={SettlementManagement} exact path={routePath.settlement.settlementManagement.path} />
          <Route component={UserInfoSetting} exact path={routePath.userInfo.userInfoSetting.path} /> */}
          </ProfileContext.Provider>
        </Switch>
      </DndProvider>
    </ThemeProvider>
  );
}

export default App;
