export const routePath = {
  portal: {
    path: '/',
  },
  login: {
    path: '/login',
  },
  signup: {
    path: '/signup',
  },
  intro: {
    introduction: {
      path: '/intro/introduction',
      name: '믹스테이프 소개',
    },
    manual: {
      path: '/intro/manual',
      name: '서비스 이용방법',
    },
    membership: {
      path: '/intro/membership',
      name: '멤버십 안내',
    },
    channel: {
      path: '/intro/channel',
      name: '유통서비스 채널',
    },
  },
  album: {
    albumManagement: {
      path: '/album/album-management',
      name: '앨범관리',
    },
    albumInfoDetail: {
      path: '/album/album-info-detail',
      name: '앨범상세',
    },
    settlementManagement: {
      path: '/settlement/settlement-management',
      name: '정산관리',
    },
  },
  profile: {
    profileManagement: {
      path: '/profile/profile-management',
      name: '프로필관리',
    },
  },
  promotion: {
    accelerating: {
      path: '/promotion/accelerating',
      name: '엑셀러레이팅',
    },
  },
  help: {
    notice: {
      path: '/help/notice',
      name: '공지사항',
    },
    noticeDetail: {
      path: '/notice/:id',
      name: '공지사항 내용',
    },
    faq: {
      path: '/help/faq',
      name: 'FAQ',
    },
    faqWrite: {
      path: '/help/faq-write',
      name: 'FAQ 글쓰기',
    },
    update: {
      path: '/help/updateInfo',
      name: '수정요청',
    },
    qna: {
      path: '/help/qna',
      name: '1:1문의',
    },
  },
  userInfo: {
    userInfoSetting: {
      path: '/userinfo/userinfo-setting',
      name: '계정설정',
    },
  },
  payment: {
    path: '/payment',
    name: '결제',
  },
  paymentCompleted: {
    path: '/payment-completed',
    name: '결제완료',
  },
  paymentFailed: {
    path: '/payment-failed',
    name: '결제실패',
  },
  default: {
    path: '/',
  },
  terms: {
    path: '/terms/:type',
    name: '약관',
  },
};
