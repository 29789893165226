import React, { useEffect, useState } from 'react';

import { Icon } from '@images/Icon';

import Button from './Button';
import Row from './Row';
import VDivider from './VDivider';

const PAGES_PER_LIST = 10;

const Pagination = ({ currentPage, setCurrentPage, totalPage }: any) => {
  const [showingNum, setShowingNum] = useState({
    start: 1,
    end: PAGES_PER_LIST,
  });

  const onPageSelect = (pageNo: number) => setCurrentPage(pageNo);

  const onFirstPage = () => {
    const current = 1;
    const lessThanPagesPerList = totalPage <= PAGES_PER_LIST;
    if (lessThanPagesPerList) {
      setShowingNum((prev) => ({ ...prev, start: 1, end: totalPage }));
    } else {
      setShowingNum((prev) => ({ ...prev, start: 1, end: PAGES_PER_LIST }));
    }
    setCurrentPage(current);
  };

  const onEndPage = () => {
    setShowingNum({
      start: Math.floor(totalPage / 10) * 10 + 1,
      end: totalPage,
    });
    setCurrentPage(totalPage);
  };

  const onPrevPage = () => {
    const current = currentPage - 1;
    if (current % PAGES_PER_LIST === 0) {
      setShowingNum({
        start: current - 9,
        end: current,
      });
    }
    setCurrentPage(current);
  };

  const onNextPage = () => {
    const current = currentPage + 1;
    if(current > totalPage){return}
    if (current % PAGES_PER_LIST === 1) {
      setShowingNum({
        start: current,
        end: current + PAGES_PER_LIST - 1 > totalPage ? totalPage : current + PAGES_PER_LIST - 1,
      });
    }
    setCurrentPage(current);
  };

  useEffect(() => {
    const lessThanPagesPerList = totalPage <= PAGES_PER_LIST;
    if (lessThanPagesPerList) {
      setShowingNum((prev) => ({ ...prev, start: 1, end: totalPage }));
    } else {
      setShowingNum((prev) => ({ ...prev, start: 1, end: PAGES_PER_LIST }));
    }
  }, [totalPage]);

  // useEffect(() => {
  //   setCurrentPage(showingNum.start);
  // }, [showingNum, setCurrentPage]);

  const isFirstPage = showingNum.start === 1;
  const isLastPage = showingNum.end === totalPage;
  const pages = Array(showingNum.end - showingNum.start + 1)
    .fill(null)
    .map((_, index) => showingNum.start + index);

  return (
    <>
      {totalPage > 1 ? (
        <div>
          <Row justify="CENTER">
            <Button onClick={onFirstPage} style={{ width: '36px', height: '36px', background: '#272727' }}>
              <Icon icon="tableOnFirst" style={{ width: '13px', height: '10px' }} />
            </Button>
            <VDivider margin="3px" type="TRANSPARENT" />
            <Button
              disabled={currentPage === 1}
              onClick={onPrevPage}
              style={{ width: '36px', height: '36px', background: '#272727' }}
            >
              <Icon icon="tableOnPrev" style={{ width: '10px', height: '10px' }} />
            </Button>
            <VDivider margin="3px" type="TRANSPARENT" />
            {pages.map((page: number, idx: number) => {
              const isActive = page === currentPage;
              return (
                <div key={`pageNumber-${idx + 1}`}>
                  <Button
                    onClick={() => onPageSelect(page)}
                    style={{
                      minWidth: '36px',
                      width: 'auto',
                      height: '36px',
                      background: isActive ? '#EFFD60' : 'transparent',
                      color: isActive ? '#121212' : '#fff',
                      fontSize: '14px',
                    }}
                  >
                    {page}
                  </Button>
                  <VDivider margin="3px" type="TRANSPARENT" />
                </div>
              );
            })}
            <Button

              onClick={onNextPage}
              style={{ width: '36px', height: '36px', background: '#272727' }}
            >
              <Icon icon="tableOnNext" style={{ width: '10px', height: '10px' }} />
            </Button>
            <VDivider margin="3px" type="TRANSPARENT" />
            <Button onClick={onEndPage} style={{ width: '36px', height: '36px', background: '#272727' }}>
              <Icon icon="tableOnEnd" style={{ width: '13px', height: '10px' }} />
            </Button>
          </Row>
        </div>
      ) : null}
    </>
  );
};

export default Pagination;
