import AlertDialog from '@components/form/AlertDialog';
import { BaseSelect } from '@components/form/ChipSelect';
import DatePicker from '@components/form/DatePicker';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Input from '@components/form/Input';
import Label from '@components/form/Label';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import TDatePicker from '@components/form/TDatePicker';
import Text from '@components/form/Text';
import TTextField from '@components/form/TTextField';
import VDivider from '@components/form/VDivider';
import { SERVICE_TIME } from '@constants/enum';
import { AlbumContext } from '@containers/App';
import styled from '@emotion/styled';

import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { gf_getImageInfo } from '@utils/misc';
import date from 'date-and-time';
import moment from 'moment';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import SimpleFile from '@components/form/SimpleFile';
import { ConfirmButton, FindPasswordButton } from '@components/molecules/FindPassword/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioGroup from '@mui/material/RadioGroup';

function TCheckBox(props) {
  return (
    <Checkbox
      {...props}
      checkedIcon={<CheckCircleIcon sx={{ fontSize: '20px' }} />}
      icon={<CheckCircleOutlineIcon sx={{ fontSize: '20px' }} />}
      sx={{
        '&.Mui-checked': {
          color: '#555',
        },
        color: '#999',
        ...props.sx,
      }}
    />
  );
}

function TRadio(props) {
  return (
    <Radio
      color="default"
      checkedIcon={<CheckCircleIcon sx={{ fontSize: '20px' }} />}
      icon={<RadioButtonUncheckedIcon sx={{ fontSize: '20px' }} />}
      {...props}
    />
  );
}

const TLabelBox = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: bold;
  color: #121212;
  width: 200px;
`;

const TFormLabelBox = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  color: #121212;
`;

function TLabelControlBox({ label, children }) {
  return (
    <Stack direction="row" alignItems="center">
      <TLabelBox>{label}</TLabelBox>
      {children}
    </Stack>
  );
}

///
/// 방송사인 경우
///
function BroadcastForm({ stateHandler, state }) {
  const handleChange = (event) => {
    stateHandler({
      ...state,
      [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    });
  };

  return (
    <Stack spacing={2}>
      <TLabelControlBox label="심의 방송사">
        <TTextField
          name="broadcaster"
          value={state.broadcaster}
          onChange={handleChange}
          placeholder="심의 방송사"
          sx={{ flex: 1 }}
        />
      </TLabelControlBox>
      <TLabelControlBox label="심의 일자">
        {/* <DatePicker */}

        {/*   customInput={ */}
        {/*     <Input */}
        {/*       id='serviceDate' */}
        {/*       onKeyDown={(e: any) => { */}
        {/*         e.preventDefault(); */}
        {/*       }} */}
        {/*       placeholder='심의일자' */}
        {/*       style={{ width: '100%' }} */}
        {/*       type='text' */}
        {/*     /> */}
        {/*   } */}
        {/*   date={state.reviewDate} */}
        {/*   disabled */}
        {/*   placeholderText='심의일자' */}
        {/*   setDate={(selectedDate) => { */}

        {/*     stateHandler({ */}
        {/*       ...state, */}
        {/*       ['reviewDate']: selectedDate, */}
        {/*     }); */}
        {/*   }} */}
        {/* /> */}
        <TDatePicker
          placeholder="심의 일자"
          sx={{ flex: 1 }}
          onChange={(selectedDate) => {
            stateHandler({
              ...state,
              ['reviewDate']: selectedDate,
            });
          }}
        />
      </TLabelControlBox>
    </Stack>
  );
}

///
/// 영상물 등급위원회인 경우
///
function VideoReviewForm({ stateHandler, state, fileList, fileHandler }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {},
    onDrop: async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) {
        // uf_popupMessage('뮤직비디오는 3GB 이하의 MP4/MOV 파일만 등록 가능합니다.')
        return;
      }

      if (acceptedFiles.length && acceptedFiles.length > 1) {
        // uf_popupMessage('동영상 파일은 하나만 선택할 수 있습니다.');
        return;
      }

      fileHandler(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Stack spacing={2}>
      <SimpleFile
        style={{ flex: 1 }}
        description="비디오물 등급물 분류 필증 업로드"
        fileList={fileList}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        onClickDelete={() => fileHandler([])}
      />
      <p style={{ fontSize: '12px' }}>
        <Link
          sx={{ color: '#121212' }}
          href="https://www.kmrb.or.kr/kor/CMS/Board/Board.do?mCode=MN028&mode=view&mgr_seq=5&board_seq=28142"
          target="_blank"
        >
          비디오물 등급 분류 필증
        </Link>
        이 제출 되지 않으면 뮤직비디오 서비스가 불가능합니다.
      </p>
    </Stack>
  );
}

function ReleaseDateTimeForm({ stateHandler, state, albumReleaseDate }) {
  const ard = moment(moment(albumReleaseDate).format('YYYY-MM-DD'));
  const td = moment(moment().format('YYYY-MM-DD'));
  return (
    <Stack spacing={1.5} pt={2} pb={2}>
      <TLabelBox>뮤직비디오 서비스 희망 일시</TLabelBox>

      <Stack direction="row" spacing={1}>
        <TDatePicker
          placeholder="서비스 희망 날짜"
          shouldDisableDate={(d) => {
            if (albumReleaseDate <= td) {
              return d <= td;
            } else {
              return d < moment(moment(albumReleaseDate).format('YYYY-MM-DD'));
            }
          }}
          onChange={(v) => {
            stateHandler({
              ...state,
              ['serviceDate']: v,
            });
          }}
        />

        <VDivider margin="5px" type="TRANSPARENT" />
        <BaseSelect
          id="serviceTime"
          onChange={(e: ChangeEvent) => {
            stateHandler({
              ...state,
              ['serviceTime']: e.target.value,
            });
          }}
          sx={{ width: '100%' }}
          itemData={SERVICE_TIME.map((ele) => {
            return {
              id: ele.value,
              code_name: ele.label,
            };
          })}
          placeholder="시간선택"
          value={state.serviceTime ?? ''}
        />
      </Stack>
      <Typography sx={{ fontSize: '12px' }} paragraph={true}>
        희망 날짜에 임박하여 제출하는 경우 서비스가 지연될 수 있습니다. (2주 내외 소요)
      </Typography>
    </Stack>
  );
}

function AddSuccessBox({ openHandler }) {
  return (
    <Layout style={{ width: '600px' }}>
      <Stack alignItems="center" pb={12} pt={12} spacing={8}>
        <Typography sx={{ fontSize: '16px' }}>뮤직비디오 추가 제출이 완료되었습니다.</Typography>

        <ConfirmButton
          onClick={() => {
            openHandler(false);
          }}
        >
          확인
        </ConfirmButton>
      </Stack>
    </Layout>
  );
}

function MusicVideoAddDialog({ open, openHanlder, callback = null, update = false, trackId = null, releaseDate = null }) {
  const [isDisabledConfirmButton, setIsDisabledConfirmButton] = useState(true);
  const [videoFiles, setVideoFiles] = useState([]);
  const [thumnailFiles, setThumbnailFiles] = useState([]);
  const [uc_videoRatingCertiFile, uf_setVideoRatingCertiFile] = useState([]);
  const { getRootProps: getVideoRootProps, getInputProps: getVideoInputProps } = useDropzone({
    accept: {
      'video/mp4': [],
      'video/quicktime': [],
    },
    onDrop: (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) {
        uf_popupMessage('뮤직비디오는 3GB 이하의 MP4/MOV 파일만 등록 가능합니다.');
        return;
      }

      if (acceptedFiles.length && acceptedFiles.length > 1) {
        uf_popupMessage('동영상 파일은 하나만 선택할 수 있습니다.');
        return;
      }

      const df = acceptedFiles[0];

      if (df.size / 1024 / 1024 / 1024 > 3) {
        uf_popupMessage('뮤직비디오는 3GB 이하의 MP4/MOV 파일만 등록 가능합니다.');
        return;
      }

      setVideoFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const { getRootProps: getThumbnailRootProps, getInputProps: getThumbnailInputProps } = useDropzone({
    accept: { 'image/jpeg': [] },
    onDrop: async (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) {
        uf_popupMessage('썸네일은 2MB 이하의 1,280 X 720 px JPG 파일만 등록 가능합니다.');
        return;
      }

      if (acceptedFiles.length && acceptedFiles.length > 1) {
        uf_popupMessage('썸네일 파일은 하나만 선택할 수 있습니다.');
        return;
      }

      const df = acceptedFiles[0];
      const imgInfo = await gf_getImageInfo(df);

      if (imgInfo.info.size / 1024 / 1024 > 2) {
        uf_popupMessage('썸네일은 2MB 이하의 1,280 X 720 px JPG 파일만 등록 가능합니다.');
        return;
      }

      if (imgInfo.info.width != 1280 || imgInfo.info.height != 720) {
        uf_popupMessage('썸네일은 2MB 이하의 1,280 X 720 px JPG 파일만 등록 가능합니다.');
        return;
      }
      setThumbnailFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const handleClickDeleteVideoFile = (e: any) => {
    setVideoFiles([]);
  };

  const handleClickDeleteThumbnailFile = (e: any) => {
    setThumbnailFiles([]);
  };

  useEffect(() => {
    return () => {
      // setVideoFiles(formData?.track_video ?? []);
      // setThumbnailFiles(formData?.track_thumnail ?? []);
    };
  }, []);

  // 일반 팝업창 팝업용
  const { openState: uc_open, setOpenState: uf_setOpen, message: uc_message, popupMessage: uf_popupMessage } = usePopupMessage();

  const defaultState = {
    check1: false,
    check2: false,
    check3: false,
    reviewAgent: -1,
    movieRating1: -1,
    movieRating2: -1,
    broadcaster: '',
    reviewDate: '',
    videoRatingCertiFile: null,
  };
  const [uc_state, uf_setState] = useState(defaultState);

  const handleChange = (event) => {
    // console.log(event.target.name);
    // console.log(event.target.value);
    uf_setState({
      ...uc_state,
      [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    });
  };

  const getResultRecord = () => {
    const record = {
      ...uc_state,
      videoFiles: videoFiles,
      thumbnailFiles: thumnailFiles,
      videoRatingCertiFile: uc_videoRatingCertiFile,
    };
    return record;
  };

  const checkButtonState = () => {
    // 활성화 가능조건 부터 파악
    let enabled = uc_state.check1 && uc_state.check2 && uc_state.check3;
    enabled = enabled && videoFiles?.length > 0;
    enabled = enabled && thumnailFiles?.length > 0;
    enabled = enabled && uc_state.reviewAgent > -1;
    enabled = enabled && uc_state.movieRating > -1;

    if (update) {
      enabled = enabled && !!uc_state.serviceDate && !!uc_state.serviceTime;
    }

    if (uc_state.reviewAgent == 1) {
      enabled = enabled && !!uc_state.broadcaster && !!uc_state.reviewDate;
    }

    if (uc_state.reviewAgent == 0) {
      enabled = enabled && uc_videoRatingCertiFile?.length > 0;
    }

    return !enabled;
  };

  useEffect(() => {
    if (open) {
      resetState();
    }
  }, [open]);
  useEffect(() => {
    const disabled = checkButtonState();

    setIsDisabledConfirmButton(disabled);
  }, [uc_state, uc_videoRatingCertiFile, videoFiles, thumnailFiles]);

  const [uc_addCompleted, uf_setAddCompleted] = useState(false);
  const resetState = () => {
    uf_setState(defaultState);
    setVideoFiles([]);
    setThumbnailFiles([]);
    uf_setVideoRatingCertiFile([]);
  };

  const { ctf_sendHttpPost } = useContext(BackdropContext);
  const callAddMusicVideo = async () => {
    const params = getResultRecord();
    //const rdate = new Date(date.format(params.serviceDate, 'YYYY-MM-DD') + ' ' + params.serviceTime);
    const rdate = moment(params.serviceDate).format('YYYY-MM-DD') + ' ' + params.serviceTime;
    const ratingDate = moment(params.reviewDate).format('YYYY-MM-DD');
    const videoFrm = new FormData();

    videoFrm.append('rating_agency', params.reviewAgent);
    videoFrm.append('rating_agency_name', params.broadcaster);
    videoFrm.append('rating_cd', params.movieRating);
    videoFrm.append('rating_date', ratingDate);
    videoFrm.append('track_id', trackId);
    videoFrm.append('mv_releae_date', rdate);
    videoFrm.append('file', params.videoFiles[0]);
    // thumbnailFiles: thumnailFiles,
    //   videoRatingCertiFile: uc_videoRatingCertiFile,
    videoFrm.append('mv_img', params.thumbnailFiles[0]);

    // 영심위 심의 인증 파일
    if (params.videoRatingCertiFile && params.videoRatingCertiFile.length > 0) {
      videoFrm.append('mv_doc', params.videoRatingCertiFile[0]);
    }

    const res = await ctf_sendHttpPost('/musicvideos', videoFrm);

    if (res.error) {
      uf_popupMessage('뮤직비디오 추가 신청을 실패하였습니다.');
      return;
    }

    uf_setAddCompleted(true);
    callback();
  };

  return (
    <Dialog
      fullWidth={false}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        openHanlder(false);
      }}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px',
        },
      }}
    >
      {uc_addCompleted && <AddSuccessBox openHandler={openHanlder} />}
      {!uc_addCompleted && (
        <>
          <DialogTitle>
            <p
              style={{
                textAlign: 'left',
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Pretendard',
              }}
            >
              아래 안내 사항을 확인하고
              <br />
              뮤직비디오 파일을 제출해 주세요.
            </p>
            <IconButton
              onClick={(e: any) => {
                e.preventDefault();
                openHanlder(false);
              }}
              sx={{
                backgroud: '#121212',
                position: 'absolute',
                right: 25,
                top: 22,
                color: '#121212',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent p={5}>
            <Stack spacing={2.5} sx={{ width: '100%' }}>
              <Stack alignItems="flex-start">
                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                    paddingBottom: '15px',
                    fontSize: '14px',
                  }}
                  control={<TCheckBox checked={uc_state.check1} onChange={handleChange} name="check1" sx={{ marginTop: -1 }} />}
                  label={
                    <TFormLabelBox>
                      뮤직비디오는 3GB 이하의 MP4/MOV 파일, 썸네일은 2MB 이하의 1,280 X 720 px JPG 파일이어야 합니다.
                    </TFormLabelBox>
                  }
                />

                <FormControlLabel
                  sx={{
                    alignItems: 'flex-start',
                    paddingBottom: '15px',
                    fontSize: '12px',
                  }}
                  control={<TCheckBox checked={uc_state.check2} onChange={handleChange} name="check2" sx={{ marginTop: -1 }} />}
                  label={
                    <TFormLabelBox>
                      뮤직비디오는 심의가 완료된 '심의본(방송사 로고, 심의날짜, 등급 기호 포함) 파일'을 첨부해 주세요.
                    </TFormLabelBox>
                  }
                />

                <FormControlLabel
                  sx={{ fontSize: '14px' }}
                  control={<TCheckBox checked={uc_state.check3} onChange={handleChange} name="check3" />}
                  label={<TFormLabelBox>뮤직비디오는 앨범 발매와 동시에 서비스 됩니다.</TFormLabelBox>}
                />
              </Stack>

              {update && (
                <ReleaseDateTimeForm albumReleaseDate={new Date(releaseDate)} stateHandler={uf_setState} state={uc_state} />
              )}

              <Stack direction="row" spacing={2}>
                <SimpleFile
                  style={{ flex: 1 }}
                  description="뮤직비디오 파일 업로드"
                  fileList={videoFiles}
                  getInputProps={getVideoInputProps}
                  getRootProps={getVideoRootProps}
                  onClickDelete={handleClickDeleteVideoFile}
                />

                <SimpleFile
                  style={{ flex: 1 }}
                  description="썸네일 파일 업로드"
                  fileList={thumnailFiles}
                  getInputProps={getThumbnailInputProps}
                  getRootProps={getThumbnailRootProps}
                  onClickDelete={handleClickDeleteThumbnailFile}
                />
              </Stack>

              <Stack spacing={1} pt={4}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontFamily: 'Pretendard',
                    fontWeight: 'bold',
                  }}
                >
                  심의처를 선택해 주세요.
                </Typography>
                <RadioGroup
                  defaultValue={1}
                  row={true}
                  name="reviewAgent"
                  value={uc_state.reviewAgent}
                  onChange={handleChange}
                  sx={{
                    justifyContent: 'space-between',
                  }}
                >
                  <FormControlLabel value={0} control={<TRadio />} label={<TLabelBox>영상물등급위원회</TLabelBox>} />
                  <FormControlLabel value={1} control={<TRadio />} label={<TLabelBox>방송사(MBC, SBS, Mnet 등)</TLabelBox>} />
                </RadioGroup>
              </Stack>
              <>
                {uc_state.reviewAgent == 1 && <BroadcastForm stateHandler={uf_setState} state={uc_state} />}
                {uc_state.reviewAgent == 0 && (
                  <VideoReviewForm
                    stateHandler={uf_setState}
                    state={uc_state}
                    fileList={uc_videoRatingCertiFile}
                    fileHandler={uf_setVideoRatingCertiFile}
                  />
                )}

                {uc_state.reviewAgent !== -1 && (
                  <TLabelControlBox label="상영 등급">
                    <RadioGroup name="movieRating" value={uc_state.movieRating} onChange={handleChange}>
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{
                          xs: 1,
                          sm: 2,
                          md: 3,
                        }}
                      >
                        <Grid item xs={6}>
                          <FormControlLabel value={0} control={<TRadio />} label={<TFormLabelBox>전체</TFormLabelBox>} />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel value={1} control={<TRadio />} label={<TFormLabelBox>7세이상</TFormLabelBox>} />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel value={2} control={<TRadio />} label={<TFormLabelBox>12세이상</TFormLabelBox>} />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value={3}
                            control={<TRadio />}
                            label={<TFormLabelBox>15세이상</TFormLabelBox>}
                            label={<TFormLabelBox>15세이상</TFormLabelBox>}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel value={4} control={<TRadio />} label={<TFormLabelBox>19세이상</TFormLabelBox>} />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </TLabelControlBox>
                )}
              </>
              <FindPasswordButton
                disabled={isDisabledConfirmButton}
                onClick={() => {
                  if (update) {
                    callAddMusicVideo();
                    return;
                  }
                  if (callback) {
                    const record = getResultRecord();
                    callback(record);
                    openHanlder(false);
                  }
                }}
              >
                제출
              </FindPasswordButton>
            </Stack>
          </DialogContent>
        </>
      )}
      <AlertDialog isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />
    </Dialog>
  );
}

export default MusicVideoAddDialog;
