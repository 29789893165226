export const getCookies = (name: string) => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return value ? unescape(value[2]) : undefined;
};

export const setCookies = (name: string, value: string, expire: string) => {
  const now = new Date().getTime();
  const max = new Date(expire).getTime();
  const temp = Math.floor((max - now) / 1000);
  document.cookie = `${name}=${value};max-age=${temp};path=/;`;
};

export const removeCookies = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
};
