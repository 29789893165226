import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Button, Stack, Typography } from '@mui/material';
import { $mixtapeGetUser } from '@utils/auth';
import history from '@utils/history';
import React from 'react';

const LineBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 60px;
`;

const LineBox = styled.div<{ isLeft: boolean; borderColor: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  border: ${(props) => `2px solid ${props.borderColor}`};

  & .badge {
    width: max-content;
    padding: 5px 26px;
    border-radius: 20px;
    background-color: #ededed;
    font-size: 12px;
    font-weight: 300;
    color: #191919;
  }

  ${(props) => {
    if (props.isLeft) {
      return css`
        justify-content: space-between;
        min-width: 852px;
        max-width: 852px;
        padding: 40px 60px 40px 110px;
        border-left: 0;
        border-radius: 0px 100px 0px 0px;
      `;
    } else {
      return css`
        min-width: 962px;
        max-width: 962px;
        gap: 100px;
        padding: 40px 0px 40px 60px;
        border-right: 0;
        border-radius: 100px 0px 0px 0px;
      `;
    }
  }};
`;

const IconOuterWrapper = styled.div<{ color: string }>`
  position: relative;
  border: 1px solid white;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
`;

const IconInnerWrapper = styled.div<{ color: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid white;
  width: 106px;
  height: 106px;
  border-radius: 100%;
  background-color: ${(props) => props.color};

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SubscribeBtn = styled(Button)`
  padding: 15px 168px;
  background-color: #effd60;
  font-size: 20px;
  font-weight: 600;
  color: #191919;
  &:hover {
    background-color: #effd60;
  }
`;

const Manual = () => {
  const sessionUserInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')!) : null;

  const goSubcribe = () => {
    if (!sessionUserInfo) {
      history.push('/login');
    } else {
      history.push('/membership-info');
    }
  };

  return (
    <>
      <MainHeader logo="logoW" />
      <Stack direction="column" paddingY="200px" color="white" justifyContent="center" alignItems="center" bgcolor="#1E1E1E">
        <Stack direction="column" gap="40px" alignItems="center" style={{ minWidth: '1024px', maxWidth: '1024px' }}>
          <Typography fontSize="50px" fontWeight="bold" mb="80px">
            서비스 이용 방법
          </Typography>
          <LineBoxWrapper>
            <LineBox isLeft borderColor="#EEFD60">
              <Stack direction="column" gap="16px">
                <span className="badge">STEP. 01</span>
                <Typography fontSize="24px" fontWeight="bold">
                  멤버십 구독
                </Typography>
                <Typography fontSize="16px" color="#909090">
                  회원 가입 후 멤버십을 구독해 주세요.
                </Typography>
              </Stack>

              <IconOuterWrapper color="#f6fdac">
                <IconInnerWrapper color="#f6fdac">
                  <img width="53px" src="/assets/icon/step01.svg" />
                </IconInnerWrapper>
              </IconOuterWrapper>
            </LineBox>
            <LineBox isLeft={false} borderColor="#60E2FD">
              <IconOuterWrapper color="#88EBFE">
                <IconInnerWrapper color="#88EBFE">
                  <img width="53px" src="/assets/icon/step02.svg" />
                </IconInnerWrapper>
              </IconOuterWrapper>
              <Stack direction="column" gap="16px">
                <span className="badge">STEP. 02</span>
                <Typography fontSize="24px" fontWeight="bold">
                  앨범 정보 입력
                </Typography>
                <Typography fontSize="16px" color="#909090">
                  앨범명, 아티스트명, 장르, 앨범 타입, 기획사명, 앨범 커버 등을 입력해 주세요.
                </Typography>
              </Stack>
            </LineBox>
            <LineBox isLeft borderColor="#B760FC">
              <Stack direction="column" gap="16px">
                <span className="badge">STEP. 03</span>
                <Typography fontSize="24px" fontWeight="bold">
                  트랙 상세 정보 입력
                </Typography>
                <Typography fontSize="16px" color="#909090">
                  WAV 파일을 업로드하고, 세부 트랙 정보 (트랙명, 아티스트명, 장르 등)를 입력해 주세요.
                </Typography>
              </Stack>

              <IconOuterWrapper color="#CB8CFC">
                <IconInnerWrapper color="#CB8CFC">
                  <img width="53px" src="/assets/icon/step03.svg" />
                </IconInnerWrapper>
              </IconOuterWrapper>
            </LineBox>
            <LineBox isLeft={false} borderColor="#FF3120">
              <IconOuterWrapper color="#FF695D">
                <IconInnerWrapper color="#FF695D">
                  <img width="53px" src="/assets/icon/step04.svg" />
                </IconInnerWrapper>
              </IconOuterWrapper>
              <Stack direction="column" gap="16px">
                <span className="badge">STEP. 04</span>
                <Typography fontSize="24px" fontWeight="bold">
                  일정 선택 후 발매 신청
                </Typography>
                <Typography fontSize="16px" color="#909090">
                  원하는 발매 날짜와 시간을 선택하고, 최종 발매 자료를 제출해 주세요. <br />
                  발매 일정은 즉시 확정되며, 제출된 자료는 담당자가 발매일 기준으로 2주 전까지 검토 진행하여 <br />
                  수정이 필요한 경우 별도 안내드립니다.
                </Typography>
              </Stack>
            </LineBox>
          </LineBoxWrapper>

          {(!sessionUserInfo ||
            !sessionUserInfo.is_membership ||
            (sessionUserInfo.is_membership && sessionUserInfo.is_membership_delay)) && (
            <SubscribeBtn onClick={() => goSubcribe()}>멤버십 구독하기</SubscribeBtn>
          )}
        </Stack>
      </Stack>
      <MainFooter />
    </>
  );
};

export default Manual;
