import MainFooter from '@components/molecules/MainFooter';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import Row from '@components/form/Row';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

import { routePath } from '@constants/path';
import { Icon } from '@images/Icon';
import history from '@utils/history';
import AcceleratingPopup from './popup/AcceleratingPopup';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import SchedulePopup from './popup/SchedulePopup';
import ProMembershipPopup from './popup/ProMembershipPopup';
import { $mixtapeGetUser } from '@utils/auth';

let _footerShowing = false;
let _selectedIndex = 0;
let _wheeling = false;
let _movableUpper = true;
let _waitTime = 700;
let _lastShowTime = new Date();
let intId = null;
let justStarted = false;

const INTRODUCTIONS = [
	{
		title: 'Easy, Simple, Distribution',
		subTitle: '우리는 단순 앨범 발매를 넘어서 \n' + '더 가치 있는 완성을 제공하고자 합니다.'
	},

	{
		title: 'Distribution Fee 0%',
		subTitle: '모든 음원 수익은 100% 아티스트에게 돌아갑니다.\n' + '앨범 작업에만 집중하세요.'
	},

	{
		title: 'Worldwide',
		subTitle:
			'우리는 전 세계 음악 플랫폼들과 직접 소통하고 있습니다.\n' +
			'YouTube, Spotify, Apple Music, Melon 등 \n' +
			'전 세계 모든 곳에 당신의 음악을 발매해 보세요.'
	},
	{
		title: 'Free Release',
		subTitle:
			'mixtape. 멤버십 회원은 자유롭게 앨범 발매가 가능합니다.\n' + '내가 원하는 일정에 음악을 선보일 수 있어요.'
	},
	{
		title: 'Promotion Support',
		subTitle:
			'더 많은 사람들에게 음악이 발견될 수 있도록 서포트 하고 있습니다.\n' +
			'사람들이 모이는 곳엔 항상 당신의 음악이 있을 거예요.'
	},
	{
		title: 'It’s Your Turn!',
		subTitle: '  아티스트 커리어의 시작!\n' + '우리와 함께 시작해 보세요.'
	}
];

const TextSlide = () => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const startSlideShow = (e) => {
		const checkNum = parseInt(Math.abs(e.wheelDeltaY) / 100);
		if (checkNum < 1) {
			_waitTime = 1500;
		} else {
			_waitTime = 700;
		}

		const now = new Date();
		if (now - _lastShowTime < _waitTime) {
			return;
		}

		_lastShowTime = now;

		_wheeling = true;
		const nextDir = e.deltaY / 100;
		if (nextDir < 0 && _selectedIndex > 0) {
			if (!_movableUpper) {
				_wheeling = false;
				return;
			}
			_selectedIndex--;
		} else if (nextDir > 0 && _selectedIndex < INTRODUCTIONS.length - 1) {
			if (_footerShowing) {
				_wheeling = false;
				return;
			}
			_selectedIndex++;
		}
		setIntroAction(_selectedIndex);
	};

	///
	/// 스크롤 이벤트
	///
	useEffect(() => {
		window.addEventListener('wheel', startSlideShow);
		// window.addEventListener('scroll', handleInroScroll);
		return () => {
			window.removeEventListener('wheel', startSlideShow);
			// window.removeEventListener('scroll', handleInroScroll);
		};
	}, []);

	const [uc_autoRevolver, uf_setAutoRevolver] = useState(true);

	const revolveText = () => {
		if (_selectedIndex === INTRODUCTIONS.length - 1 && !justStarted) {
			clearInterval(intId);
			intId = null;
			return;
		}

		if (justStarted === true) justStarted = false;

		const nextIndex = _selectedIndex === INTRODUCTIONS.length - 1 ? 0 : _selectedIndex + 1;
		_selectedIndex = nextIndex;
		setCurrentIndex(_selectedIndex);
	};

	// const startRevolveText = () => {
	//   if (intId === null) {
	//     justStarted = true;
	//     intId = setInterval(revolveText, 8000);
	//     revolveText();
	//   }
	// };

	useEffect(() => {
		// let intCheck = setInterval(checkIdleTime, 3000);
		intId = setInterval(revolveText, 8000);
		//startRevolveText()
		return () => {
			if (intId !== null) {
				clearInterval(intId);
			}
			// clearInterval(intCheck);
		};
	}, [uc_autoRevolver]);

	// const handleMouseEnter = (me) => {
	//   startRevolveText();
	//   //curMouseTime = new Date()
	//   // clearInterval(intId);
	// };

	const handlePagerClick = (idx: number) => {
		if (_selectedIndex === idx) {
			return;
		}
		_selectedIndex = idx;

		setIntroAction(idx);
	};

	const setIntroAction = (idx: number) => {
		setCurrentIndex(idx);

		setTimeout(() => {
			_wheeling = false;
			if (_selectedIndex === INTRODUCTIONS.length - 1) {
				_footerShowing = true;
				document.getElementById('intro-footer').style.display = 'block';
			} else {
				_footerShowing = false;
				document.getElementById('intro-footer').style.display = 'none';
			}
		}, _waitTime);
	};

	return (
		<>
			{' '}
			<div id="home-slider-wrapper" className="" key="portal8">
				<div id="info-slider-wrapper" key="portal9">
					<div
						id="info-slider"
						style={{ transform: `translateY(${currentIndex * -100}%) translateZ(0px)` }}
						key="portal10"
					>
						{INTRODUCTIONS.map((intro, idx) => (
							<div className={idx === currentIndex ? 'info-slide active' : 'info-slide'} key={`portal11_${idx}`}>
								<div className="gutter" key="portal12">
									<div className="slide-content" key="portal13">
										<h2 className="intro-text" key="portal14">
											{intro.title}
										</h2>
										{intro.subTitle.split('\n').map((ele, subIdx) => (
											<div className="intro-sub-text" key={`portal15_${subIdx}`}>
												{ele}
											</div>
										))}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div id="slider-pagination" key="portal16">
				{INTRODUCTIONS?.map((el2, index2) => (
					<div
						className={currentIndex !== index2 ? 'pagination-item ' : 'pagination-item current'}
						onClick={() => {
							handlePagerClick(index2);
						}}
						key={`portal16_${index2}`}
					></div>
				))}
			</div>
		</>
	);
};

function Portal(): ReactElement {
	const videoRef = useRef(null);
	const [soundMute, setSoundMute] = useState<boolean>(true);
	const sessionUserInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')!) : null;

	const goJoin = () => {
		const redirectUri = routePath.login.path;
		history.push(redirectUri);
	};

	const handlePlay = () => {
		videoRef.current.muted = !soundMute;
		setSoundMute(!soundMute);
		// videoRef.current.play();  // 재생
	};

	useEffect(() => {
		videoRef.current.currentTime = 34;
	}, []);

	return (
		<>
			<Row
				justify="CENTER"
				style={{
					position: 'absolute',
					top: '30px',
					left: 0,
					zIndex: 100,
					alignItems: 'center',
					width: '100%'
				}}
			>
				<Row
					justify="SPACE_BETWEEN"
					style={{
						alignItems: 'center',
						paddingRight: '60px',
						paddingLeft: '95px'
					}}
				>
					<Icon
						icon="logoPortal"
						style={{
							width: '200px'
						}}
					/>

					<IconButton
						sx={{
							width: '160px',
							height: '160px',
							borderRadius: '50%',
							background: '#edfb5f',
							color: '#000',
							'&:hover': {
								background: '#edfb5f80'
							}
						}}
						onClick={goJoin}
					>
						<Stack spacing={0.7}>
							<Typography
								sx={{
									fontFamily: 'Titillium Web',
									fontSize: '30px'
								}}
							>
								Join
							</Typography>
							<ArrowOutwardIcon />
						</Stack>
					</IconButton>

					{/* <Icon icon='btnJoin' onClick={goJoin} style={{ */}
					{/*   width: '160px', */}
					{/*   height: '160px', */}
					{/*   cursor: 'pointer', */}
					{/* }} /> */}
				</Row>
			</Row>

			<div id="home-page" className="page" key="portal1" style={{ position: 'relative' }}>
				<div id="home-page-inner" className="" key="portal2">
					<div id="poster-slides" key="portal3">
						<div className="poster-slide active" key="portal4">
							{/* <iframe src="https://www.youtube.com/embed/Yj2iELI6OeI?controls=0&autoplay=1&mute=1&playsinline=1&playlist=Yj2iELI6OeI&loop=1"> */}
							{/* </iframe> */}

							{/* <iframe src="https://www.youtube.com/embed/Yj2iELI6OeI?controls=0&autoplay=1&mute=1&playsinline=1&loop=1&playlist=Yj2iELI6OeI"></iframe> */}

							<div className="poster-slide-bg" key="portal5">
								<video autoPlay loop muted ref={videoRef}>
									<source src="https://down.mixtape.so/NAS/intro.webm" type="video/webm"></source>
								</video>
							</div>

							<div className="overlay" key="portal7"></div>
						</div>
					</div>

					<TextSlide />
				</div>
				<IconButton
					onClick={handlePlay}
					sx={{
						position: 'absolute',
						bottom: 36,
						left: 70,
						width: '56px',
						height: '56px',
						background: 'rgba(255,255,255,0.2)'
					}}
				>
					{soundMute ? (
						<VolumeUpIcon sx={{ color: 'rgba(255,255,255,0.8)' }} />
					) : (
						<VolumeOffIcon sx={{ color: 'rgba(255,255,255,0.8)' }} />
					)}
				</IconButton>
			</div>

			<div id="intro-footer">
				<MainFooter />
			</div>

			{/* <AcceleratingPopup /> */}
			{/* <ProMembershipPopup /> */}

			{/* <SchedulePopup /> */}
			{/* <ChuseokPopup /> */}
		</>
	);
}

export default Portal;
