import React, { ReactElement } from 'react';

import { VALIDATION_MESSAGES } from '@constants/validation';

type TErrorMessageProps = {
  formError?: {
    id: string;
  };
  message?: string;
};

export default function ErrorMessage({ formError, message }: TErrorMessageProps): ReactElement | null {
  if (message) {
    return (
      <div style={{ marginTop: '10px' }}>
        <span style={{ color: '#FF6465', fontSize: '12px', fontWeight: 400 }}>{message}</span>
      </div>
    );
  }
  return formError?.id ? (
    <div style={{ marginTop: '10px' }}>
      <span style={{ color: '#FF6465', fontSize: '12px', fontWeight: 400 }}>
        {VALIDATION_MESSAGES[formError.id] ?? formError.id}
      </span>
    </div>
  ) : null;
}
