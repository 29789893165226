import React, { CSSProperties } from 'react';

const MixLogoSlide = ({ style }: { style?: CSSProperties }) => {
  return (
    <div className="logos" style={style}>
      <div className="logo-slide">
        <img src="/assets/logo/applymusic_logo.png"></img>
        <img src="/assets/logo/flo_logo.png"></img>
        <img src="/assets/logo/genie_logo.png"></img>
        <img src="/assets/logo/spotify_logo.png"></img>
        <img src="/assets/logo/tiktok_logo.png"></img>
        <img src="/assets/logo/vibe_logo.png"></img>
        <img src="/assets/logo/youtube_logo.png"></img>
      </div>
      <div className="logo-slide">
        <img src="/assets/logo/applymusic_logo.png"></img>
        <img src="/assets/logo/flo_logo.png"></img>
        <img src="/assets/logo/genie_logo.png"></img>
        <img src="/assets/logo/spotify_logo.png"></img>
        <img src="/assets/logo/tiktok_logo.png"></img>
        <img src="/assets/logo/vibe_logo.png"></img>
        <img src="/assets/logo/youtube_logo.png"></img>
      </div>
      <div className="logo-slide">
        <img src="/assets/logo/applymusic_logo.png"></img>
        <img src="/assets/logo/flo_logo.png"></img>
        <img src="/assets/logo/genie_logo.png"></img>
        <img src="/assets/logo/spotify_logo.png"></img>
        <img src="/assets/logo/tiktok_logo.png"></img>
        <img src="/assets/logo/vibe_logo.png"></img>
        <img src="/assets/logo/youtube_logo.png"></img>
      </div>
    </div>
  );
};

export default MixLogoSlide;
