import React, { HTMLAttributes, ReactChild, ReactChildren, ReactElement } from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

type ThandleCheckRowJustifyParams = 'SPACE_BETWEEN' | 'FLEX_START' | 'FLEX_END' | 'CENTER' | 'SPACE_AROUND';
type ThandleCheckRowDirectionParams = 'row' | 'column' | undefined;
type ThandleCheckRowAlignParams = 'center' | 'flex-start' | undefined;

interface IRowParams extends HTMLAttributes<HTMLDivElement> {
  justify: ThandleCheckRowJustifyParams;
  direction?: ThandleCheckRowDirectionParams;
  align?: ThandleCheckRowAlignParams;
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[] | any;
  style?: Record<string, any>;
}

const handleCheckRowType = (justify: ThandleCheckRowJustifyParams) => {
  switch (justify) {
    case 'SPACE_BETWEEN':
      return css`
        justify-content: space-between;
      `;
    case 'FLEX_START':
      return css`
        justify-content: flex-start;
        align-items: flex-start;
      `;
    case 'FLEX_END':
      return css`
        justify-content: flex-end;
      `;
    case 'CENTER':
      return css`
        justify-content: center;
      `;
    case 'SPACE_AROUND':
      return css`
        justify-content: space-around;
      `;
    default:
      return css``;
  }
};

const StyledRow = styled.div<IRowParams>`
  flex: 1;
  display: flex;
  ${({ direction }) => `flex-direction: ${direction || 'row'}`};
  ${({ align }) => `align-items: ${align || 'center'}`};
  ${({ justify }) => handleCheckRowType(justify)}
`;

const Row = ({ children, ...rest }: IRowParams): ReactElement => <StyledRow {...rest}>{children}</StyledRow>;

export default Row;
