import Row from '@components/form/Row';
import styled from '@emotion/styled';

export const SettlementAccountBox = styled(Row)`
  background: #121212;
  border-radius: 28px;
  color: #777;
  padding: 17px 40px;
  justify-content: center;
  align-items: center;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;
