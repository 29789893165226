type TEnumElement<T> = {
  label: string;
  value: T;
};
type TEnum<T> = TEnumElement<T>[];

export const DEFAULT_USER_ID = 'user';

export const BANK_LIST = [
  {
    label: '국민은행',
    value: '국민은행',
  },
  {
    label: '신한은행',
    value: '신한은행',
  },
];

export const ARTIST_LIST = [
  {
    label: '아티스트AAAAAA아티스트AAAAAA아티스트AAAAAA',
    value: 'A',
    webSite: 'https://vibe.naver.com/artist/000000100000010000001',
  },
  {
    label: '아티스트B',
    value: 'B',
    webSite: 'https://vibe.naver.com/ar000000100000010000001000000100000010000001tist/0000002',
  },
  {
    label: '아티스트B',
    value: 'B',
    webSite: 'https://vibe.naver.com/artist/0000002',
  },
  {
    label: '아티스트B',
    value: 'B',
    webSite: 'https://vibe.naver.com/artist/0000002',
  },
  {
    label: '아티스트B',
    value: 'B',
    webSite: 'https://vibe.naver.com/artist/0000002',
  },
];

export const ARTIST_TYPE_LIST = [
  {
    label: '솔로',
    options: [
      {
        label: '싱어송라이터',
        value: '싱어송라이터',
      },
    ],
  },
  {
    label: '그룹',
    options: [
      {
        label: '프로젝트',
        value: '프로젝트',
      },
    ],
  },
];

export const ARTIST_GENDER_LIST = [
  {
    label: '남성',
    value: 'M',
  },
  {
    label: '여성',
    value: 'W',
  },
  {
    label: '혼성',
    value: 'MNW',
  },
  {
    label: '선택 안함',
    value: 'NONE',
  },
];

export const GENRE_LIST = [
  {
    label: '발라드',
    value: '발라드',
  },
  {
    label: '댄스',
    value: '댄스',
  },
  {
    label: 'R&B/Soul',
    value: 'R&B/Soul',
  },
];

export const ARTIST_ROLE_LIST = [
  {
    label: '작곡',
    value: '작곡',
  },
  {
    label: '작사',
    value: '작사',
  },
  {
    label: '작사',
    value: '작사',
  },
  {
    label: '작사',
    value: '작사',
  },
  {
    label: '작사',
    value: '작사',
  },
  {
    label: '작사',
    value: '작사',
  },
];

export const SERVICE_TIME = [

  {
    label: '12PM (KST)',
    value: '12:00:00',
  },
  {
    label: '6PM (KST)',
    value: '18:00:00',
  },
];
