import { createSelector } from 'reselect';

export default function getSelectors(reduxActionName: string): any {
  const selectState = (state: any) => state[reduxActionName] || { isUndefined: true, reduxActionName };
  const makeSelectStateFactory = (stateName: string) => createSelector(selectState, (state) => state[stateName]);

  return {
    // related to queryString or requestBody
    makeSelectParameters: makeSelectStateFactory('parameters'),
    makeSelectReqParameters: makeSelectStateFactory('reqParameters'),

    // related to request OR response
    makeSelectData: makeSelectStateFactory('data'),
    makeSelectRequestLoading: makeSelectStateFactory('requestLoading'),
    makeSelectRequestSuccess: makeSelectStateFactory('requestSuccess'),
    makeSelectRequestError: makeSelectStateFactory('requestError'),
    makeSelectRequestErrorMessage: makeSelectStateFactory('requestErrorMessage'),
    makeSelectRequestErrorData: makeSelectStateFactory('requestErrorData'),
  };
}
