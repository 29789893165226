import React from 'react';

import styled from '@emotion/styled';

export type TInputParams = any;

const StyledInputBox = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  overflow: hidden;
  padding: 0 !important;

  div {
    line-height: 15px;
    padding-left: 20px;
    padding-right: 15px;
  }

  .time {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    padding-right: 0;

    text-align: right;

    /* color/error */

    color: #ff6465;
  }
`;

const StyledInput = styled.input<TInputParams>`
  flex-grow: 1;
  height: 15px !important;
  padding: 16px 16px 16px 16px;
  border: none;
  outline: none;

  &:hover {
    outline: 0;
    box-shadow: none;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &:disabled {
    border-color: rgba(0, 0, 0, 0.32);
    color: #363c50;
  }
`;

const InputPrefixSuffix = ({ prefix, suffix, containerStyle, ...rest }: TInputParams) => (
  <StyledInputBox style={{ ...containerStyle }}>
    {prefix}
    <StyledInput {...rest} maxLength={100} />
    {suffix}
  </StyledInputBox>
);

export default InputPrefixSuffix;
