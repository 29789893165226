///
import S001 from '@containers/ArtistAddModal/S001';
import S002 from '@containers/ArtistAddModal/S002';
import S002_01 from '@containers/ArtistAddModal/S002_01';
import S002_02 from '@containers/ArtistAddModal/S002_02';
import { Box, Dialog, DialogTitle, IconButton, Stack } from '@mui/material';
/// 아티스트 추가
///
import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import { HeaderText } from '@components/molecules/AlbumManagement/FullScreenModal';
import Row from '@components/form/Row';
import CloseIcon from '@mui/icons-material/Close';

interface IArtistAddModalParams {
  isOpenArtistAddModal: boolean;
  setIsOpenArtistAddModal: (value: boolean) => void;
  setResult: (value: any) => void;
  mode: 'S001' | 'S002' | 'S002_01' | 'S002_02';
  roleId?: number;
  partTypeId?: number;
}

/// STEP 단계
/// S001 - 검색페이지
/// S002 - 등록단계 1
/// S003 - 등록단계 2

/// 주의점 : 프로필 신규등록의 경우... 검색단계를 거치지 않는다.
/// 호출할때 검색부터 할것인지 검색단계를 건너뛸 것인지 알려주는 방법이 필요하다.

// modalOpener : 함수를 호출시 넘겨주는 정보 -> 결과값 전달시 되돌려준다.
//@ts-ignore
function ArtistAddModal({
  isOpenArtistAddModal,
  setIsOpenArtistAddModal,
  setResult,
  mode = 'S001',
  roleId,
  partTypeId,
}: IArtistAddModalParams) {
  const _HEADER_TITLE = {
    S001: '아티스트 검색',
    S002: '아티스트 신규등록',
    S002_01: '아티스트 신규등록',
    S002_02: '아티스트 신규등록',
  };
  const _ERROR = {
    error: false,
    errorMsg: '',
  };

  if (!mode) {
    _ERROR.error = true;
    _ERROR.errorMsg = 'mode 인자가 안넘어 왔습니다.';
  }
  // DEFAULT 스텝은 SOO1 - 아티스트 검색페이지
  const [curStep, setCurStep] = useState(mode);
  const [_uc_headerTitle, _uf_setHeaderTitle] = useState(_HEADER_TITLE.S001);
  useEffect(() => {
    // 팝업창이 뜰땐 언제나 검색페이지가 출력되어야한다.
    if (isOpenArtistAddModal) {
      setCurStep(mode);
    }
  }, [isOpenArtistAddModal]);

  useEffect(() => {
    _uf_setHeaderTitle(_HEADER_TITLE[curStep]);
  }, [curStep]);

  const getResult = (artist: any) => {
    let param = {
      ...artist,
    };

    if (roleId) {
      param.role_id = roleId;
    }
    if (partTypeId) {
      param.part_type_id = partTypeId;
    }
    setResult(param);
  };

  return (
    <Dialog
      open={isOpenArtistAddModal}
      fullWidth={true}
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px',
        },
      }}
      // headerText={_uc_headerTitle}
      // hide={() => {
      //   setIsOpenArtistAddModal(false);
      // }}
      // isShown={isOpenArtistAddModal}
      // modalStyle={{
      //   // height: '700px',
      //   maxHeight: '800px',
      //   width: mode === 'S002_02' ? '600px' : '900px',
      // }}
      // modalStyle={{ width: mode === 'S002_02' ? '600px' : '900px', maxHeight: '700px' }}
    >
      <>
        <DialogTitle style={{ padding: '6px 24px' }}>
          <Stack direction="row" justifyContent="center" p={2}>
            <HeaderText style={{ color: '#121212' }}>
              <Row justify="FLEX_START">{_uc_headerTitle}</Row>
            </HeaderText>

            <IconButton
              onClick={(e: any) => {
                e.preventDefault();
                setIsOpenArtistAddModal(false);
              }}
              sx={{
                backgroud: '#121212',
                position: 'absolute',
                right: 25,
                top: 22,
                color: '#121212',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        {_ERROR.error === true && <Box>{_ERROR.errorMsg}</Box>}
        {/* 아티스트 신규등록 */}
        {/*   */}
        {(curStep === 'S002' || curStep === 'S002_01' || curStep === 'S002_02') && (
          <S002
            setCurStep={setCurStep}
            setResult={setResult}
            setIsOpenArtistAddModal={setIsOpenArtistAddModal}
            curStep={curStep}
            // isProfile={isProfile}
          />
        )}
        {/* 아티스트 검색 */}
        {curStep === 'S001' && (
          <S001 setCurStep={setCurStep} setResult={getResult} setIsOpenArtistAddModal={setIsOpenArtistAddModal} />
        )}
        {/* {curStep === 'S002_01' && <S002_01 setResult={setResult} setIsOpenArtistAddModal={setIsOpenArtistAddModal} />}
        {curStep === 'S002_02' && <S002_02 setResult={setResult} setIsOpenArtistAddModal={setIsOpenArtistAddModal} />} */}
      </>
    </Dialog>
  );
}

export default ArtistAddModal;
