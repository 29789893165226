import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { resetPassword } from '@api/mapper/User';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Input from '@components/form/Input';
import InputSuffix from '@components/form/InputSuffix';
import Label from '@components/form/Label';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';
import { FindPasswordButton } from '@components/molecules/FindPassword/Button';
import { FindPasswordInputWithErrorText } from '@components/molecules/FindPassword/Input';
import { FindPasswordErrorText } from '@components/molecules/FindPassword/Text';
import {
  AUTH_FIND_PASSWORD_ACTION,
  AUTH_FIND_PASSWORD_GET_NUMBER_ACTION,
  AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
} from '@constants/actionNames';
import { useFormError } from '@hooks/useFormError';
import { useReduxData } from '@hooks/useReduxData';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import { Icon } from '@images/Icon';
import { autoHypenPhone, getDisabledWithFormError, isEmpty } from '@utils/data';

import { TCurrentStep } from '.';

type TResetPasswordProps = {
  authId: string;
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
};

function ResetPassword({ setCurrentStep, authId }: TResetPasswordProps) {
  const [isNotSamePassword, setIsNotSamePassword] = useState<boolean>(true);
  const [formErrorNetwork, setFormErrorNetwork] = useState<any>(null);
  const { parameters: phoneInfo } = useReduxData(AUTH_FIND_PASSWORD_GET_NUMBER_ACTION);
  const { parameters: idInfo } = useReduxData(AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION);

  const { request, resData, errorMessage } = useRequest({
    reduxActionName: AUTH_FIND_PASSWORD_ACTION,
    mapperFunc: resetPassword,
  });

  const { formData, formFieldsError, validation, setFormData, handleSubmit } = useReduxForm(
    AUTH_FIND_PASSWORD_ACTION,
    (params) => {
      if (phoneInfo.phone_num && phoneInfo.name && idInfo.user_id && params.password) {
        request({
          phone_number: autoHypenPhone(phoneInfo.phone_num),
          name: phoneInfo.name,
          user_id: idInfo.user_id,
          password: params.password,
          res_auth_id: authId,
        });
      }
    },
  );

  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const setFindPasswordResetFormData = (e: any, dataIndex: string) => {
    const { value } = e.target;
    if (dataIndex === 'password' || dataIndex === 'passwordConfirm') {
      if (dataIndex === 'password') {
        if (value !== formData.passwordConfirm) {
          setIsNotSamePassword(true);
        } else {
          setIsNotSamePassword(false);
        }
      }
      if (dataIndex === 'passwordConfirm') {
        if (value !== formData.password) {
          setIsNotSamePassword(true);
        } else {
          setIsNotSamePassword(false);
        }
      }
    }
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
  };

  const getIsDisabledResetPasswordBtn = () => {
    const isDisabled = getDisabledWithFormError(formError, ['password', 'passwordConfirm']);
    if (isDisabled) {
      return isDisabled;
    }
    if (isNotSamePassword) {
      return true;
    }

    return false;
  };

  const handleClickFindPasswordReset = (e: any) => {
    e.preventDefault();
    handleSubmit();
  };

  useEffect(() => {
    if (resData) {
      setCurrentStep('COMPLETED');
    }
    if (errorMessage) {
      setFormErrorNetwork(errorMessage);
      setFormData({
        password: '',
        passwordConfirm: '',
      });
    }
  }, [resData, errorMessage]);

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="9px" type="TRANSPARENT" />
        <Text textType="DESCRIPTION">{`새로운 비밀번호로 재설정해 주세요.\n8~30자 영문 대/소문자, 숫자, 특수문자를 사용하세요.`}</Text>
        <HDivider margin="8px" type="TRANSPARENT" />
        <Label htmlFor="password">새 비밀번호</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <Input
          id="password"
          onChange={(e: ChangeEvent) => {
            setFindPasswordResetFormData(e, 'password');
          }}
          placeholder="8~30자 영문 대/소문자, 숫자, 특수문자를 사용하세요."
          type="password"
          value={formData.password ?? ''}
        />
        <ErrorMessage formError={formError.password} />
        <HDivider margin="10px" type="TRANSPARENT" />
        <Label htmlFor="passwordConfirm">비밀번호 확인</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <Input
          id="passwordConfirm"
          onChange={(e: ChangeEvent) => {
            setFindPasswordResetFormData(e, 'passwordConfirm');
          }}
          placeholder="비밀번호 확인"
          type="password"
          value={formData.passwordConfirm ?? ''}
        />
        {formErrorNetwork ? (
          <>
            <Row justify="CENTER">{formErrorNetwork && <ErrorMessage message={formErrorNetwork} />}</Row>
            <HDivider margin="12px" type="TRANSPARENT" />
          </>
        ) : (
          <>
            <HDivider margin="15px" type="TRANSPARENT" />
          </>
        )}
        <FindPasswordButton disabled={getIsDisabledResetPasswordBtn()} onClick={handleClickFindPasswordReset}>
          비밀번호 재설정
        </FindPasswordButton>
      </Form>
    </Layout>
  );
}

export default ResetPassword;
