import { TCommonCode } from '@/types/common';
import { getDspList } from '@api/mapper/AlbumManagement';
import { DSP_LIST_ACTION } from '@constants/actionNames';
import ReleasePlatformCheckBox from '@containers/AlbumManagement/ConfirmModal/ReleasePlatformCheckBox';
import { usePlatformCheckBoxControl } from '@containers/AlbumManagement/ConfirmModal/usePlatformCheckBoxControl';
import { useRequest } from '@hooks/useRequest';
import { isUndefined } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import history from '@utils/history';

const ReleasePlatform = ({ allCheckedToPlatform }) => {
	// @ts-ignore
	const { requestForSingle: getDspListReq, resData: getDspCodeListRes }: { requestForSingle: any; resData: any[] } =
		useRequest({
			reduxActionName: DSP_LIST_ACTION,
			mapperFunc: getDspList
		});

	const dspCodeList = useMemo(() => {
		return isUndefined(getDspCodeListRes)
			? []
			: getDspCodeListRes.map((item: TCommonCode) => ({ ...item, checked: false }));
	}, [getDspCodeListRes]);

	const { platformList, allPlatformCheckbox, handleAllCheckboxes, handleChangeCheckedPlatform } =
		usePlatformCheckBoxControl(allCheckedToPlatform, dspCodeList);

	useEffect(() => {
		getDspListReq();
	}, []);

	return (
		<div>
			<h4 style={{ color: 'gray', margin: '5px' }}>발매를 원하는 플랫폼을 아래에서 선택해 주세요.</h4>
			<p style={{ color: 'gray', margin: '0 0 10px 5px', fontWeight: 'normal' }}>
				아래 플랫폼의 국가에 대한 정보는{' '}
				<span style={{ color: '#49b6e0', textDecoration: 'underline' }} onClick={() => history.push('/intro/channel')}>
					유통서비스 채널
				</span>{' '}
				내용을 참고해 주세요.
			</p>
			<ReleasePlatformCheckBox
				section={{ id: 'domestic', label: '국내 전체' }}
				platformList={platformList}
				allPlatformCheckbox={allPlatformCheckbox}
				handleChangeCheckedPlatform={(e, type) => handleChangeCheckedPlatform(e, type)}
				onAllCheckboxes={handleAllCheckboxes}
			/>
			<ReleasePlatformCheckBox
				section={{ id: 'overseas', label: '해외 전체' }}
				platformList={platformList}
				allPlatformCheckbox={allPlatformCheckbox}
				handleChangeCheckedPlatform={(e, type) => handleChangeCheckedPlatform(e, type)}
				onAllCheckboxes={handleAllCheckboxes}
			/>
		</div>
	);
};

export default ReleasePlatform;
