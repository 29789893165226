import { routePath } from '@constants/path';
import history from '@utils/history';
import React, { ReactElement } from 'react';

import Layout from '@components/form/Layout';
import { Icon } from '@images/Icon';
import { TIcon } from '@images/png';

type TAuthHeaderProps = {
  logo: TIcon;
};

function AuthHeader({ logo }: TAuthHeaderProps): ReactElement {
  return (
    <Layout layoutType="HEADER">
      <Icon icon={logo} style={{ width: '180px',cursor:'pointer' }} onClick={()=>{
        const redirectUri = routePath.portal.path;
        history.push(redirectUri);
      }
      } />
    </Layout>
  );
}

export default AuthHeader;
