import AlertDialog from '@components/form/AlertDialog';
import AlertModal from '@components/form/AlertModal';
import { ProfileContext } from '@containers/App';
import ArtistAddDialog from '@containers/ArtistAddModal';
import { noImage } from '@images/png';
import { Stack, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { callProfileData } from '@utils/api';
import date from 'date-and-time';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { getArtistList } from '@api/mapper/Artist';
import Button from '@components/form/Button';
import HDivider from '@components/form/HDivider';

import Row from '@components/form/Row';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { ProfileListLayout } from '@components/molecules/ProfileManagement/Layout';
import {
  ProfileListTitle,
  ProfileMgmtReleaseDateText,
  ProfileMgmtSingerTitleText,
} from '@components/molecules/ProfileManagement/Text';
import { ARTIST_LIST_ACTION } from '@constants/actionNames';

import styled from '@emotion/styled';
import { useModal } from '@hooks/useModal';
import { useRequest } from '@hooks/useRequest';
import { Icon } from '@images/Icon';

import { MacScrollbar } from 'mac-scrollbar';

import UpdateProfileModal from './UpdateProfileModal';

const ProcessText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: right;

  /* color/bcbcbc */

  color: #bcbcbc;
`;

const FooterText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: right;
  text-decoration-line: underline;

  /* color/777 */

  color: #777777;
`;

const ListRow = styled(Row)`
  width: 100%;
  cursor: pointer;

  &:hover {
    background: #121212;
  }
`;

// @ts-ignore
function ProfileList({ profileRecords, selectedProfile, setSelectedProfile }) {
  // 현재프로필 관리 컨텍스트
  //@ts-ignore
  const { curProfileContext, changeProfile, profileData, refreshProfileData } = useContext(ProfileContext);

  const [isOpenArtistAddModal, setIsOpenArtistAddModal] = useState(false);

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState('');

  const ur_eventTarget = useRef([]);

  const { isShown: isOpenUpdateProfileModal, toggle: setIsOpenUpdateProfileModal } = useModal();
  const { request, resData, parameters } = useRequest({
    reduxActionName: ARTIST_LIST_ACTION,
    mapperFunc: getArtistList,
  });

  const handleSetSelectedProfile = (item: any) => {
    setSelectedProfile(item);
    // changeProfile(item);
  };

  const handleClickOpenUpdateProfileModal = () => {
    if (!Object.keys(selectedProfile)?.length) {
      popupMessage('수정할 아티스트 정보를 선택해 주세요.');
      return;
    }
    if (selectedProfile.state !== 'approve') {
      popupMessage('아티스트 정보 수정 신청은\n관리자 검토 완료 후 가능합니다.');
      return;
    }

    setIsOpenUpdateProfileModal();
  };

  useEffect(() => {
    setSelectedProfile(curProfileContext);
  }, []);

  // const [artistAddResult, setArtistAddResult] = useState({});
  const setArtistAddResult = (result: any) => {
    // 호출이 성공하면 리프레시한다.
    if (!result) {
      popupMessage('신규 프로필 등록을 실패하였습니다.');
    } else {
      popupMessage('신규 프로필 등록완료하였습니다.');
      recallProfileData();
    }
  };

  const recallProfileData = async () => {
    const profileRes = await callProfileData();
    refreshProfileData(profileRes);
    if (ur_eventTarget.current && ur_eventTarget.current.length > 0) {
      ur_eventTarget.current[0]?.click();
    }
  };

  const popupMessage = (txt: any) => {
    setAlertText(txt);
    setIsAlertOpen(true);
  };
  const tooltipStyle = {
    color: '#ccc',
    fontSize: '14px',
    fontFamily: 'Pretendard',
  };

  const getTooltipContent = () => {
    return (
      <Stack spacing={1} p={1}>
        <Typography sx={{ ...tooltipStyle }}>✓ 아티스트 정보 수정 신청은 이메일(mixtape@ygmail.net)로 요청해 주세요.</Typography>
        <Typography sx={{ ...tooltipStyle }}>✓ 프로필 검토 및 실제 반영까지 일주일 내외 소요될 수 있습니다.</Typography>
        <Typography sx={{ ...tooltipStyle }}>✓ 여러 프로필을 사용해도 정산은 회원가입 시 등록한 계좌로만 지급됩니다.</Typography>
      </Stack>
    );
  };
  return (
    <ProfileListLayout>
      <Row
        direction="row"
        justify="SPACE_BETWEEN"
        style={{
          width: '100%',
          maxHeight: '22px',
        }}
      >
        <Stack direction="row" alignItems="center" pl={3} spacing={1}>
          <ProfileListTitle>프로필</ProfileListTitle>

          <Tooltip
            title={getTooltipContent()}
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#111111',
                  borderRadius: '10px',
                  maxWidth: 'none',
                  '& .MuiTooltip-arrow': {
                    color: 'black',
                  },
                },
              },
            }}
          >
            <HelpOutlineRoundedIcon fontSize="small" sx={{ color: '#FFFFFF80' }} />
          </Tooltip>
        </Stack>
        <Button
          onClick={() => setIsOpenArtistAddModal(true)}
          style={{
            background: 'transparent',
            paddingRight: '32px',
          }}
        >
          <Icon
            icon="btnAdd"
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </Button>
      </Row>
      <HDivider margin="12px" type="TRANSPARENT" />
      <MacScrollbar
        style={{
          width: '100%',
          height: '480px',
          overflow: 'scroll',
        }}
      >
        {profileData?.map((item, idx) => (
          <ListRow
            justify="SPACE_BETWEEN"
            onClick={() => handleSetSelectedProfile(item)}
            style={selectedProfile.id === item.id ? { background: '#121212' } : {}}
          >
            <Row
              justify="FLEX_START"
              style={{
                alignItems: 'center',
                flex: 0.7,
                paddingLeft: '32px',
              }}
            >
              <input
                type="button"
                style={{ display: 'none' }}
                ref={(el) => (ur_eventTarget.current[idx] = el)}
                onClick={() => handleSetSelectedProfile(item)}
              />
              <div
                style={{
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  paddingLeft: '14px',
                }}
              >
                <img
                  src={
                    item.artist_image && item.artist_image.length > 0
                      ? item.artist_image[item.artist_image.length - 1].image_url
                      : noImage
                  }
                  style={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '50%',
                  }}
                />
              </div>
              <VDivider margin="10px" type="TRANSPARENT" />
              <Row direction="column" justify="FLEX_START">
                <Row direction="row" justify="CENTER">
                  <ProfileMgmtSingerTitleText
                    style={{
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}
                  >
                    {item.artist_name}
                  </ProfileMgmtSingerTitleText>
                  <VDivider margin="4px" type="TRANSPARENT" />
                  <Row
                    justify="CENTER"
                    style={{
                      background: '#777',
                      width: '3px',
                      height: '3px',
                      borderRadius: '50%',
                    }}
                  />
                  <VDivider margin="4px" type="TRANSPARENT" />
                  <ProfileMgmtSingerTitleText
                    style={{
                      textOverflow: 'ellipsis',
                      display: 'inlineBox',
                      overflow: 'hidden',
                      fontSize: '16px',
                      lineHeight: '19px',
                    }}
                  >
                    {item.languages && item.languages.length > 0 ? item.languages[0].name : <span>No name</span>}
                  </ProfileMgmtSingerTitleText>
                </Row>
                <HDivider margin="2px" type="TRANSPARENT" />
                <ProfileMgmtReleaseDateText
                  style={{
                    fontSize: '12px',
                    lineHeight: '20px',
                  }}
                >
                  {date.format(new Date(item.created_at), 'YYYY.MM.DD')}
                </ProfileMgmtReleaseDateText>
              </Row>
            </Row>
            <Row
              justify="FLEX_END"
              style={{
                flex: 0.3,
                paddingRight: '32px',
              }}
            >
              <ProcessText>{item.state_name}</ProcessText>
            </Row>
          </ListRow>
        ))}
      </MacScrollbar>
      {/* <Row
        direction="row"
        justify="FLEX_END"
        onClick={handleClickOpenUpdateProfileModal}
        style={{
          textAlign: 'right',
          maxHeight: '64px',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        <FooterText
          style={{
            paddingRight: '32px',
            color: '#aaa',
            fontSize: '14px',
          }}
        >
          아티스트 정보 수정 신청
        </FooterText>
      </Row> */}

      <ArtistAddDialog
        isOpenArtistAddModal={isOpenArtistAddModal}
        setIsOpenArtistAddModal={setIsOpenArtistAddModal}
        mode="S002_02"
        setResult={setArtistAddResult}
      />

      <UpdateProfileModal
        isOpenUpdateProfileModal={isOpenUpdateProfileModal}
        selectedProfile={selectedProfile}
        setIsOpenUpdateProfileModal={setIsOpenUpdateProfileModal}
        callback={handleSetSelectedProfile}
      />

      <AlertDialog
        isPopupOpen={isAlertOpen}
        alertText={alertText}
        hidePopup={() => {
          setIsAlertOpen(false);
        }}
      />
    </ProfileListLayout>
  );
}

export default ProfileList;
