import { ALBUM_INFO_REGISTRATION_ACTION } from '@constants/actionNames';
import { AlbumContext } from '@containers/App';
import { useReduxData } from '@hooks/useReduxData';
import { cloneDeep, findIndex, isEmpty, isUndefined } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { BackdropContext } from '@hooks/usePopupMessage';

export interface IPlatformList {
	checked: boolean;
	created_at: string;
	deleted_at: null | string;
	dsp_code: string;
	dsp_name: string;
	dsp_name_en: string;
	dsp_view_name: string;
	id: number;
	is_deleted: number;
	is_domestic: number;
	is_used: number;
	party_id: string;
	party_name: string;
	sort: number;
	updated_at: string;
}
export type TPlatformKind = 'domestic' | 'overseas';
export type TPlatform = { [key in TPlatformKind]: IPlatformList[] | [] };

export const usePlatformCheckBoxControl = (allCheckedToPlatform: boolean, getDspCodeListRes: any[]) => {
	// @ts-ignore
	const { data: platformIds, setData: setPlatformIds } = useReduxData(ALBUM_INFO_REGISTRATION_ACTION);
	const [platformList, setPlatformList] = useState<TPlatform>({
		domestic: [],
		overseas: []
	});
	const [allPlatformCheckbox, setAllPlatformCheckbox] = useState<{ domestic: boolean; overseas: boolean }>({
		domestic: true,
		overseas: true
	});

	// state is affected by the overall agreement.
	useEffect(() => {
		const updateDomesticPlatformList = (platformList['domestic'] as IPlatformList[]).map((item) => ({
			...item,
			checked: allCheckedToPlatform || false
		}));
		const updateOverseasPlatformList = (platformList['overseas'] as IPlatformList[]).map((item, index) => ({
			...item,
			checked: allCheckedToPlatform || false
		}));
		setPlatformList({
			domestic: updateDomesticPlatformList,
			overseas: updateOverseasPlatformList
		});

		setAllPlatformCheckbox({ domestic: allCheckedToPlatform || false, overseas: allCheckedToPlatform || false });
	}, [allCheckedToPlatform]);

	// state setting
	useEffect(() => {
		if (!isUndefined(getDspCodeListRes)) {
			const releasePlatform = platformIds?.releasePlatform || [];
			const mappingWithExistingValue = getDspCodeListRes.map((value) => {
				value.checked = !!releasePlatform.includes(value.id);
				return value;
			});
			setPlatformList({
				domestic: mappingWithExistingValue.filter((item) => item.is_domestic === 1),
				overseas: mappingWithExistingValue.filter((item) => item.is_domestic === 0)
			});
		}
	}, [getDspCodeListRes]);

	const handleAllCheckboxes = (e, type: string) => {
		const updateTypeState = platformList[type].map((item) => {
			item.checked = !allPlatformCheckbox[type];
			return item;
		});
		const updateState = { ...platformList, [type]: updateTypeState };
		setPlatformList(updateState);
		setAllPlatformCheckbox((prevState) => ({ ...prevState, [type]: !prevState[type] }));
		setExtractCheckedIds(updateState);
	};

	const handleChangeCheckedPlatform = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		const { target } = event;
		const copyPlatformData = cloneDeep(platformList[type]);
		const findIdx = findIndex(copyPlatformData, { id: Number(target.id) }); // copyPlatformData.findIndex = copyPlatformData.findIndex
		copyPlatformData[findIdx].checked = !platformList[type][findIdx].checked; //!target.checked;
		const allFlag = copyPlatformData.every((key) => key.checked);
		setAllPlatformCheckbox((prevState) => ({ ...prevState, [type]: allFlag }));
		setPlatformList({ ...platformList, [type]: copyPlatformData });
		setExtractCheckedIds({ ...platformList, [type]: copyPlatformData });
	};

	const setExtractCheckedIds = (list) => {
		let result: number[] = [];
		for (let type in list) {
			list[type].forEach((v) => {
				if (v.checked) {
					result.push(v.id);
				}
			});
		}
		setPlatformIds({ ...platformIds, releasePlatform: result });
	};

	return {
		platformList,
		allPlatformCheckbox,
		handleAllCheckboxes,
		handleChangeCheckedPlatform
	};
};
