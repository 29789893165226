import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import { AUTH_FIND_ID_GET_NUMBER_ACTION, USER_INFO_EDIT_PHONE_NUMBER_ACTION } from '@constants/actionNames';
import { useReduxData } from '@hooks/useReduxData';

import Finish from './Finish';
import Init from './Init';

export type TCurrentStep = 'INIT' | 'FINISH';

type TEditPhoneNumberModalProps = {
  setIsOpenEditPhoneNumberModal: () => void;
  isOpenEditPhoneNumberModal: boolean;
  callback: () => void;
  userData:any;
};

function EditPhoneNumberModal({ setIsOpenEditPhoneNumberModal, isOpenEditPhoneNumberModal, callback = null, userData }: TEditPhoneNumberModalProps) {
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('INIT');
  const [isShowAuthNumberInput, setIsShowAuthNumberInput] = useState<boolean>(false);
  const { clearData: clearFindIdGetAuthNumberData } = useReduxData(AUTH_FIND_ID_GET_NUMBER_ACTION);
  const { clearData: clearEditPhoneNumberData } = useReduxData(USER_INFO_EDIT_PHONE_NUMBER_ACTION);

  useEffect(() => {
    if (!isOpenEditPhoneNumberModal) {
      setCurrentStep('INIT');
      clearFindIdGetAuthNumberData();
      clearEditPhoneNumberData();
      setIsShowAuthNumberInput(false);
    }
  }, [isOpenEditPhoneNumberModal]);

  return (
    <Modal
      headerText={currentStep === 'INIT' ? '휴대전화 변경' : '휴대전화 변경 완료'}
      hide={setIsOpenEditPhoneNumberModal}
      isShown={isOpenEditPhoneNumberModal}
    >
      <>
        {currentStep === 'INIT' && (
          <Init
            isShowAuthNumberInput={isShowAuthNumberInput}
            setCurrentStep={setCurrentStep}
            setIsShowAuthNumberInput={setIsShowAuthNumberInput}
            userData={userData}
          />
        )}
        {currentStep === 'FINISH' && (
          <Finish
            setCurrentStep={setCurrentStep}
            setIsOpenEditPhoneNumberModal={setIsOpenEditPhoneNumberModal}
            callback={callback}
          />
        )}
      </>
    </Modal>
  );
}

export default EditPhoneNumberModal;
