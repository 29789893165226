import Text from '@components/form/Text';
import styled from '@emotion/styled';

export const SettlementViewBoxTitle = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  /* color/#e8e8e8 */

  color: #e8e8e8;
`;

export const SettlementAmountText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  /* color/primary/lemon */

  color: #effd60;
`;

export const SettlementAmountZeroText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  text-align: center;

  /* color/777 */

  color: #777777;
`;

export const SettlementAmountZeroDesc = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* color/777 */

  color: #777777;
`;
export const SettlementManagementListTitle = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* color/fff */

  color: #ffffff;
`;
