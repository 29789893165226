import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

import Row from '@components/form/Row';
import styled from '@emotion/styled';
import { Icon } from '@images/Icon';
import { MacScrollbar } from 'mac-scrollbar';

export interface ModalProps {
  wrapperStyle?: any;
  isShown: boolean;
  hide: () => void;
  header: any;
  children: any;
}

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
  height: 100%;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
`;

export const StyledModal = styled(MacScrollbar)`
  z-index: 100;
  background: #121212;
  position: relative;
  margin: auto;
  width: 100%;
  overflow: scroll;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  background: #121212;
  border-bottom: #121212 solid 1px;
  height: 90px;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  height: 140px;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0;
`;

export const HeaderText = styled.div`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  flex-direction: row;

  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  /* color/fff */

  color: #ffffff;

  .album_type {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* color/primary/lemon */

    color: #effd60;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.8rem;
  border: none;
  background: #effd60;
  width: 90px;
  height: 90px;
  :hover {
    cursor: pointer;
  }
  align-self: flex-end;
`;

export const Content = styled.div`
  padding: 0px 30px 30px 30px;
  padding-left: calc(36% - 360px);
  padding-right: calc(36% - 360px);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
`;

const FullScreenMoal: FunctionComponent<ModalProps> = ({ wrapperStyle, isShown, hide, header, children }) => {
  const modal = (
    <>
      <Backdrop onClick={hide} />
      <Wrapper style={{ ...wrapperStyle }}>
        <StyledModal>
          <Header>{header}</Header>
          {children}
        </StyledModal>
      </Wrapper>
    </>
  );

  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export default FullScreenMoal;
