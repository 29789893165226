import { Typography } from '@mui/material';
import React from 'react';

import Button from '@components/form/Button';
import Row from '@components/form/Row';
import { Icon } from '@images/Icon';

import Text from './Text';
import VDivider from './VDivider';

const defaultStyle = {
  border: '1px dashed #DDDDDD',
  borderRadius: '10px',
  padding: '20px 10px',
  color: '#121212',
  fontSize: '14px',
  textAlign: 'center',
  cursor: 'pointer',
  flex:1
 // maxWidth: '391px',
}


function SimpleFile({ getRootProps, getInputProps, description, fileList, onClickDelete, style={} }) {

  const descBR = description.replace(/\n/gi, '<br/>')

  if ((Array.isArray(fileList) && fileList.length > 0) ||fileList?.name) {
    return (
      <Row
        {...getRootProps({ className: 'dropzone_exist' })}
        align="center"
        direction="row"
        justify="CENTER"
        style={{
                ...defaultStyle,
                ...style,
              }}
      >
        <Text style={{ color: '#121212', fontSize: '13px'}}>

          {fileList[0]?.name || fileList.name}

        </Text>

      </Row>
    );
  }
  return (
    <Row
      align="center"
      direction='column'
      justify="CENTER"
      {...getRootProps({ className: 'dropzone' })}
      style={{
        ...defaultStyle,
        ...style,
      }}
    >
      <input {...getInputProps()} />
        <div dangerouslySetInnerHTML={{__html: descBR}} style={{fontSize:'13px', fontFamily:'inherit'}} />
    </Row>
  );
}

export default SimpleFile;
