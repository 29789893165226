const DEFAULT_DEPLOYMENT_ENVIRONMENT = 'development';

export function getDeploymentEnv() {
	const env = process.env.REACT_APP_MODE ?? DEFAULT_DEPLOYMENT_ENVIRONMENT;
	return env;
}

export function isDevelopmentEnv() {
	return getDeploymentEnv() === 'development';
}

export function getApiServerURL() {
	return process.env.REACT_APP_API_SERVER_URL;
}

export function getPaymentSuccessUrl(membershipType: string) {
	return process.env.REACT_APP_PAYMENT_SUCCESS + '?membershipType=' + membershipType;
}

export function getPaymentFailUrl() {
	return process.env.REACT_APP_PAYMENT_FAIL;
}

export function getPaymentModifySuccessUrl(membershipType?: string) {
	if (membershipType) {
		return process.env.REACT_APP_MODIFY_PAYMENT_SUCCESS + '?membershipType=' + membershipType;
	} else {
		return process.env.REACT_APP_MODIFY_PAYMENT_SUCCESS;
	}
}

export function getPaymentModifyFailUrl() {
	return process.env.REACT_APP_MODIFY_PAYMENT_FAIL;
}

export function getPaymentClientKey() {
	if (isDevelopmentEnv()) {
		return process.env.REACT_APP_PAYMENT_CLIENT_KEY;
	} else {
		return process.env.REACT_APP_PAYMENT_CLIENT_KEY;
	}
}

export function getPaymentTossUrl() {
	if (isDevelopmentEnv()) {
		return process.env.REACT_APP_PAYMENT_TOSS_URL;
	} else {
		return process.env.REACT_APP_PAYMENT_TOSS_URL;
	}
}
