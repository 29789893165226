import AlertModal from '@components/form/AlertModal';
import ChipSelect from '@components/form/ChipSelect';
import { AlbumContext, ProfileContext } from '@containers/App';
import ArtistAddModal from '@containers/ArtistAddModal';

import { Stack, Tooltip, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { getAuthInSessionStorage, loadLocalStorage } from '@utils/auth';
import { floor } from 'lodash';
import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
//import AsyncSelect from 'react-select/async';
import AsyncSelect from '@components/form/AsyncSelect';
import { getCodeList } from '@api/mapper/Code';
import { getAuthCode, getAuthNumber } from '@api/mapper/User';
import AlbumCoverFile from '@components/form/AlbumCoverFile';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Input from '@components/form/Input';

import Label from '@components/form/Label';
import MultipleImagFile from '@components/form/MultipleImageFile';

import Row from '@components/form/Row';
import Select from '@components/form/Select';
import Text from '@components/form/Text';
import Textarea from '@components/form/Textarea';
import VDivider from '@components/form/VDivider';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';

import { SignUpLayout } from '@components/molecules/SignUp/Layout';

import {
	AUTH_SIGN_UP_GET_CODE_ACTION,
	AUTH_SIGN_UP_GET_NUMBER_ACTION,
	CODE_LIST_ALBUM_TYPE_ACTION,
	CODE_LIST_COUNTRY_ACTION,
	CODE_LIST_GENRE_ACTION,
	CODE_LIST_LANGUAGE_ACTION
} from '@constants/actionNames';

import { useFormError } from '@hooks/useFormError';
import { useRequest } from '@hooks/useRequest';
import { Icon } from '@images/Icon';
import { getDisabledWithFormError, isEmpty } from '@utils/data';
import { sendHttpGet } from '@utils/api';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const MAX_LENGTH_OF_ALBUM_INTRODUCTION = 4000;

type TInfoProps = {
	setCurrentStep: Dispatch<SetStateAction<any>>;
	reduxForm: any;
	setIsDisabledNextButton: any;
};

const ImageAddIcon = () => {
	return <AddIcon sx={{ fontSize: '100px', color: '#bfbfbf' }} />;
};

function ImageGuideBox() {
	return (
		<Stack justifyContent="center" alignItems="center" sx={{ width: '320px', height: '320px' }} spacing={8}>
			<ImageAddIcon />
			{/* <AddIcon sx={{fontSize:'100px',color:'#bbb'}} /> */}
			<p style={{ color: '#bfbfbf' }}>
				업로드 하려면 이곳을 클릭하거나
				<br />
				원하는 파일을 드래그하세요.
			</p>
		</Stack>
	);
}

function Info({ setCurrentStep, reduxForm, setIsDisabledNextButton }: TInfoProps) {
	const { curProfileContext } = useContext(ProfileContext);

	const [currentBookletIndex, setCurrentBookletIndex] = useState<any>(null);
	const { setFormData, formData, formFieldsError, validation } = reduxForm;

	const { formError, checkFormError } = useFormError({
		validation,
		formFieldsError
	});

	const { ctc_albumInfo } = useContext(AlbumContext);

	const setAlbumRegistrationFormData = (e: any, dataIndex: string, isValue?: boolean) => {
		let value = '';
		if (isValue) {
			value = e;
		} else {
			value = e.target.value;
		}

		setFormData(dataIndex, value);
		checkFormError(dataIndex, value, formData);
	};

	///
	/// 부클릿과 앨범커버4{ 이미지 선택시 이상여부 처리하는 함수
	///
	const valideImageType = (image: any) => {
		const result = ['image/jpeg', 'image/jpg', 'image/png'].indexOf(image.type) > -1;
		return result;
	};

	const checkImgFile = (file: any, sizePx: any, param: any, func: any) => {
		const fm = sizePx.toLocaleString('ko-KR');
		if (!file.type.startsWith('image/')) {
			popupMessage(`${fm} X ${fm} px, 10MB 이하의 정방형 JPG, PNG 이미지 파일만 등록 가능합니다.`);
			return;
		}
		if (!valideImageType(file)) {
			popupMessage('JPG, JPEG, PNG 이미지 포맷만 가능합니다.');
			return;
		}

		// 동일파일 체크
		if (param && param.length > 0) {
			const dupFile = param.find((im: any) => im.name == file.name && im.size == file.size);
			if (dupFile) {
				popupMessage('이미 등록된 파일입니다.');
				return;
			}
		}

		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onload = () => {
			const fileMB = floor((file.size / 1024 / 1024) * 100) / 100;
			if (fileMB > 10) {
				popupMessage(`${fm} X ${fm} px, 10MB 이하의 정방형 JPG, PNG 이미지 파일만 등록 가능합니다.`);
				return;
			}

			// @ts-ignore
			const uint = new Uint8Array(reader.result);
			const blob = new Blob([uint], { type: file.type });
			const img = new Image();
			img.src = URL.createObjectURL(blob);
			img.onload = () => {
				if (img.width != img.height) {
					popupMessage(`${fm} X ${fm} px, 10MB 이하의 정방형 JPG, PNG 이미지 파일만 등록 가능합니다.`);
					return;
				}
				if (img.width !== sizePx) {
					popupMessage(`${fm} X ${fm} px, 10MB 이하의 정방형 JPG, PNG 이미지 파일만 등록 가능합니다.`);
					return;
				}
				func(file);
			};
		};
	};

	///
	/// 앨범커버 선택하였을 경우 호출되는 함수
	///
	const { getRootProps: getAlbumCoverRootProps, getInputProps: getAlbumCoverInputProps } = useDropzone({
		// accept: { 'image/*': [] },
		maxFiles: 1,
		onDrop: (acceptedFiles: any) => {
			if (acceptedFiles.length > 1) {
				popupMessage('파일을 하나만 선택하여 주십시오.');
				return;
			}
			const file = acceptedFiles[0];

			checkImgFile(file, 3000, null, albumCoverFunc);
		}
	});

	const albumCoverFunc = (f: any) => {
		setAlbumRegistrationFormData([Object.assign(f, { preview: URL.createObjectURL(f) })], 'album_cover', true);
	};

	// 부클릿 파일 선택시 체크결과 이상없을 때 최종 처리되는 함수
	const bookletFunc = (f: any) => {
		let bookletFiles = [];
		if (currentBookletIndex !== null) {
			bookletFiles = formData?.booklet?.map((item: any, index: number) => {
				if (currentBookletIndex === index) {
					return Object.assign(f, { preview: URL.createObjectURL(f) });
				}
				return item;
			});
		} else {
			if (formData.booklet) {
				bookletFiles = [...formData.booklet, Object.assign(f, { preview: URL.createObjectURL(f) })];
			} else {
				bookletFiles = [Object.assign(f, { preview: URL.createObjectURL(f) })];
			}
		}

		setAlbumRegistrationFormData(bookletFiles, 'booklet', true);
		setCurrentBookletIndex(null);
	};

	const { getRootProps: getBookletRootProps, getInputProps: getBookletInputProps } = useDropzone({
		accept: { 'image/*': [] },
		maxFiles: 1,
		onDrop: (acceptedFiles: any) => {
			if (acceptedFiles.length > 1) {
				popupMessage('파일을 하나만 선택하여 주십시오.');
				return;
			}
			const file = acceptedFiles[0];
			checkImgFile(file, 2000, formData.booklet, bookletFunc);
		}
	});

	const { requestForSingle: getCountryCodeListReq, resData: getCountryCodeListRes } = useRequest({
		reduxActionName: CODE_LIST_COUNTRY_ACTION,
		mapperFunc: getCodeList
	});

	const { requestForSingle: getGenreCodeListReq, resData: getGenreCodeListRes } = useRequest({
		reduxActionName: CODE_LIST_GENRE_ACTION,
		mapperFunc: getCodeList
	});
	const { requestForSingle: getAlbumTypeCodeListReq, resData: getAlbumTypeCodeListRes } = useRequest({
		reduxActionName: CODE_LIST_ALBUM_TYPE_ACTION,
		mapperFunc: getCodeList
	});
	const { requestForSingle: getLanguageCodeListReq, resData: getLanguageCodeListRes } = useRequest({
		reduxActionName: CODE_LIST_LANGUAGE_ACTION,
		mapperFunc: getCodeList
	});

	const deleteBookletFile = (index: number) => {
		const copied = formData?.booklet ?? [];
		const filtered = copied.filter((item: any, prevIndex: number) => prevIndex !== index);
		setAlbumRegistrationFormData(filtered, 'booklet', true);
		setCurrentBookletIndex(null);
	};

	const deleteArtist = (id: any) => {
		const result = formData?.artist?.filter((item: any) => item.id !== id);
		setAlbumRegistrationFormData(result, 'artist', true);
	};

	useEffect(() => {
		getCountryCodeListReq({ parent_code: 'country' });
		getGenreCodeListReq({ parent_code: 'genre' });
		getAlbumTypeCodeListReq({ parent_code: 'album_type' });
		getLanguageCodeListReq({ parent_code: 'language' });
		setTimeout(() => {
			setFormData('country_id', {
				label: '대한민국',
				value: 453
			});
		}, 100);
	}, []);

	const requiredFlds = [
		'album_name',
		'album_name_foriegn',
		'artist',
		'genre_id',
		'type_id',
		'agency_name',
		// 'country_id',
		'description'
	];

	useEffect(() => {
		let isDisabled = getDisabledWithFormError(formError, requiredFlds, formData);

		if (formData.description?.replace(/<br\s*\/?>/gm, '\n').length > MAX_LENGTH_OF_ALBUM_INTRODUCTION) {
			isDisabled = true;
		}
		if (!formData.album_cover || formData.album_cover.length < 1) {
			isDisabled = true;
		}
		setIsDisabledNextButton(isDisabled);
		// if (isDisabled) {
		//   setIsDisabledNextButton(true);
		// } else {
		//   setIsDisabledNextButton(false);
		// }
	}, [formError]);

	// 아티스트 초기값 세팅
	//  { id: e, national: '블랙핑크', foreign: 'BLACKPINK' }
	//  setAlbumRegistrationFormData(copied, 'artist', true);
	useEffect(() => {
		if (ctc_albumInfo.editing === true) {
			return;
		}
		if (!curProfileContext || !curProfileContext.id) {
			return;
		}
		const artist = {
			id: curProfileContext.id,
			label: curProfileContext.artist_name,
			foreign: curProfileContext.languages?.reduce((acc, cur) => acc + ',' + cur.name, '').replace(/\s*,/g, '')
		};

		if (!formData.artist || formData.artist.length === 0) {
			setAlbumRegistrationFormData([artist], 'artist', true);
		}
	}, [curProfileContext]);

	const [artistList, setArtistList] = useState([]);

	const loadOptions = async (inputValue: string, callback: any) => {
		const data = await getArtistList(inputValue);
		callback(data);
	};
	///
	/// 아티스트필드에 글자를 입력하면 서버에서 가져와서 세팅한다.
	///
	async function getArtistList(keyword: String) {
		if (keyword.length < 2) return;
		const params = { artist_name: keyword };
		const res = await sendHttpGet(`/artists/search`, params, false);
		//https://devapi.mixtape.so/artists/search?artist_name=김민석

		const artists = res.data.artists?.artist?.map((ele: any) => {
			return {
				label: ele.artistName,
				value: ele.artistId,
				icon: ele.imageUrl
			};
		});
		return artists;
	}

	const [uc_artistAddOpen, uf_setAristAddOpen] = useState(false);

	///
	/// 아티스트 검색창에서 결과값 chip으로 추가
	///
	const setArtistAddResult = (artistRecord: any) => {
		if (artistRecord === null) {
			return;
		}
		let fidx = -1;
		if (formData?.artist?.length) {
			fidx = formData.artist.findIndex((ele) => ele.id === artistRecord.id);
			if (fidx > -1) {
				popupMessage('이미 선택한 아티스트입니다.');
				return;
			}
		}
		const nrd = {
			id: artistRecord.id,
			label: artistRecord.artist_name,
			foreign: artistRecord.languages.length > 0 ? artistRecord.languages[0].name : '-'
		};
		const copied = formData?.artist ? [...formData?.artist, nrd] : [nrd];
		setAlbumRegistrationFormData(copied, 'artist', true);
	};

	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const [alertText, setAlertText] = useState('');
	const popupMessage = (txt: any) => {
		setAlertText(txt);
		setIsAlertOpen(true);
	};
	return (
		<SignUpLayout style={{ background: '#fff' }}>
			<Form style={{ width: '720px' }}>
				<Text style={{ textAlign: 'center' }} textType="TITLE">
					앨범정보
				</Text>

				<Stack direction="row" justifyContent="start" alignItems="center" gap="5px" mt="20px" mb="40px">
					<ErrorSharpIcon />
					<Typography fontSize="16px" fontWeight="bold">
						발매 신청 완료 후 수정 요청하시는 경우에는 반영까지 시간이 오래 걸릴 수 있으니 신중하게 등록해 주세요.
					</Typography>
				</Stack>

				<Label htmlFor="album_name">앨범명</Label>
				<HDivider margin="5.5px" type="TRANSPARENT" />
				<Label
					htmlFor="album_name"
					style={{
						fontSize: '12px',
						fontWeight: 400
					}}
					required={requiredFlds.findIndex((ele) => ele === 'album_name') > -1}
				>
					국내
				</Label>
				<HDivider margin="3px" type="TRANSPARENT" />

				<Input
					id="album_name"
					maxLength={251}
					onChange={(e: ChangeEvent) => {
						setAlbumRegistrationFormData(e, 'album_name');
					}}
					placeholder="앨범 제목을 입력하세요. (최대 한글 128자/영문 250자, 이모지 반영 불가)"
					type="text"
					value={formData.album_name ?? ''}
				/>
				<ErrorMessage formError={formError.album_name} />

				<HDivider margin="8px" type="TRANSPARENT" />
				<Label
					htmlFor="album_name_foriegn"
					style={{
						fontSize: '12px',
						fontWeight: 400
					}}
					required={requiredFlds.findIndex((ele) => ele === 'album_name_foriegn') > -1}
				>
					해외
				</Label>
				<HDivider margin="3px" type="TRANSPARENT" />
				<Input
					id="album_name_foriegn"
					maxLength={251}
					onChange={(e: ChangeEvent) => {
						setAlbumRegistrationFormData(e, 'album_name_foriegn');
					}}
					placeholder="앨범 제목을 입력하세요. (최대 한글 128자/영문 250자, 이모지 반영 불가)"
					type="text"
					value={formData.album_name_foriegn ?? ''}
				/>
				<ErrorMessage formError={formError.album_name_foriegn} />

				<HDivider margin="8px" type="TRANSPARENT" />
				<Label
					htmlFor="title_version"
					style={{
						fontSize: '12px',
						fontWeight: 400
					}}
				>
					버전 정보
				</Label>
				<HDivider margin="3px" type="TRANSPARENT" />
				<Input
					id="title_version"
					maxLength={251}
					onChange={(e: ChangeEvent) => {
						setAlbumRegistrationFormData(e, 'title_version');
					}}
					placeholder="앨범 버전 정보를 입력하세요. (ex. Prod. mixtape. / Holiday Remix / Instrumental Version)"
					type="text"
					value={formData.title_version ?? ''}
				/>
				<ErrorMessage formError={formError.title_version} />

				<HDivider margin="8px" type="TRANSPARENT" />
				<Label htmlFor="artist_name" required={true}>
					아티스트
				</Label>
				<HDivider margin="5.5px" type="TRANSPARENT" />
				{/* <Label
          htmlFor="artist_name"
          style={{
            fontSize: '12px',
            fontWeight: 400,
          }}
        >
          국내
        </Label> */}
				{/* <HDivider margin="3px" type="TRANSPARENT" /> */}
				<Row
					justify="FLEX_START"
					style={{
						alignItems: 'flex-start',
						flexDirection: 'row',
						flexWrap: 'wrap',
						gap: '10px'
					}}
				>
					{formData.artist?.map((item: any, index: number) => (
						<>
							<Row
								justify="FLEX_START"
								style={{
									minWidth: 'fit-content',
									flex: 0,
									color: '#121212',
									fontSize: '12px',
									fontWeight: 400,
									lineHeight: '20px',
									border: '1px solid #121212',
									borderRadius: '20px',
									padding: '6px 8px 6px 16px'
									// marginRight: '8px',
								}}
							>
								{item.label}
								<VDivider margin="4px" type="TRANSPARENT" />
								<Icon
									icon="btnCloseArtist"
									onClick={() => deleteArtist(item.id)}
									style={{
										width: '20px',
										height: '20px',
										cursor: 'pointer'
									}}
								/>
							</Row>
						</>
					))}
					<Icon
						icon="btnAddArtist"
						onClick={() => uf_setAristAddOpen(true)}
						style={{
							width: '32px',
							height: '32px',
							cursor: 'pointer'
						}}
					/>
				</Row>
				<ErrorMessage formError={formError.artist} />

				{/* <Stack mt={2}>
          <Label
            htmlFor="artist_name_foriegn"
            style={{
              fontSize: '12px',
              marginBottom: '5px',
            }}
          >
            해외
          </Label>

          <Row
            justify="FLEX_START"
            style={{
              alignItems: 'flex-start',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            {formData?.artist?.map((item: any) => (
              <Row
                justify="FLEX_START"
                style={{
                  minWidth: 'fit-content',
                  flex: 0,
                  color: '#121212',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  border: '1px solid #121212',
                  borderRadius: '18px',
                  padding: '6px 8px 6px 16px',
                }}
              >
                {item.foreign}

                <Icon
                  icon="btnCloseArtist"
                  onClick={() => deleteArtist(item.id)}
                  style={{
                    width: '20px',
                    height: '20px',
                    cursor: 'pointer',
                  }}
                />
              </Row>
            ))}
          </Row>
        </Stack> */}
				<Stack direction="row" justifyContent="space-between" spacing={3} mt={4}>
					{/* 장르선택 */}
					<Stack>
						<Label htmlFor="genre_id" required={true} style={{ marginBottom: '5px' }}>
							장르
						</Label>
						<ChipSelect
							maxSelect={2}
							maxTooltip="장르는 최대 2개까지 선택 가능합니다."
							style={{ width: '320px' }}
							value={formData.genre_id ? [...formData.genre_id] : []}
							placeholder="장르 선택 (최대 2개 선택)"
							onChange={(e: ChangeEvent) => {
								setAlbumRegistrationFormData(e, 'genre_id');
							}}
							itemData={getGenreCodeListRes}
						/>

						<ErrorMessage formError={formError.genre_id} />
					</Stack>

					<Stack>
						<Label htmlFor="type_id" required={true} style={{ marginBottom: '5px' }}>
							앨범 타입
						</Label>
						<Select
							controlStyle={{ width: '320px' }}
							onChange={(e: ChangeEvent) => {
								setAlbumRegistrationFormData(e, 'type_id', true);
							}}
							options={getAlbumTypeCodeListRes?.map((item: any) => ({
								label: item.code_name,
								value: item.id
							}))}
							placeholder="앨범 타입 선택"
							value={formData.type_id ?? ''}
						/>
						<ErrorMessage formError={formError.type_id} />
					</Stack>
				</Stack>

				<HDivider margin="8px" type="TRANSPARENT" />

				<Label
					htmlFor="agency_name"
					style={{
						fontSize: '12px',
						fontWeight: 400
					}}
					required={requiredFlds.findIndex((ele) => ele === 'agency_name') > -1}
				>
					기획사
				</Label>
				<HDivider margin="3px" type="TRANSPARENT" />

				<Input
					id="agency_name"
					maxLength={251}
					onChange={(e: ChangeEvent) => {
						setAlbumRegistrationFormData(e, 'agency_name');
					}}
					placeholder="기획사가 없을 경우 아티스트명을 입력해 주세요. (최대 300자)"
					type="text"
					value={formData.agency_name ?? ''}
				/>
				<ErrorMessage formError={formError.agency_name} />

				{/* <Label htmlFor="agency_name" required={true} style={{ marginBottom: '5px' }}>
          기획사
        </Label>
        <Input
          id="agency_name"
          onChange={(e: ChangeEvent) => {
            setAlbumRegistrationFormData(e, 'agency_name');
          }}
          placeholder="기획사가 없을 경우 아티스트명을 입력해 주세요. (최대 300자)"
          style={{
            minWidth: '280px',
            maxWidth: '320px',
          }}
          type="text"
          value={formData.agency_name ?? ''}
        />
        <ErrorMessage formError={formError.agency_name} /> */}

				<HDivider margin="14px" type="TRANSPARENT" />

				<Label htmlFor="description" required={true}>
					앨범 소개
				</Label>
				<HDivider margin="5.5px" type="TRANSPARENT" />
				<Textarea
					maxLength={4000}
					onChange={(e: any) => {
						setAlbumRegistrationFormData(e, 'description');
					}}
					placeholder="앨범 소개글"
					style={{ height: '232px' }}
				>
					{formData.description ?? ''}
				</Textarea>
				<ErrorMessage formError={formError.description} />
				<HDivider margin="5px" type="TRANSPARENT" />
				<Row justify="SPACE_BETWEEN">
					{formData.description?.replace(/<br\s*\/?>/gm, '\n').length >= MAX_LENGTH_OF_ALBUM_INTRODUCTION && (
						<Text
							style={{
								fontSize: '12px',
								color: 'red'
							}}
						>
							최대 입력 가능한 글자 수를 초과했습니다.
						</Text>
					)}
					<Row justify="FLEX_END">
						<Text
							style={{
								fontSize: '12px',
								paddingRight: '3px'
							}}
						>
							{formData.description?.replace(/<br\s*\/?>/gm, '\n').length.toLocaleString('ko-KR') ?? 0}
						</Text>
						<Text style={{ fontSize: '12px' }}>{`/ ${MAX_LENGTH_OF_ALBUM_INTRODUCTION.toLocaleString('ko-KR')}`}</Text>
					</Row>
				</Row>
				<HDivider margin="13px" type="TRANSPARENT" />
				<Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
					<Stack spacing={1}>
						<Stack direction="row" alignItems="center" gap="5px">
							<Label required={true}>앨범커버</Label>
							<Stack direction="row" alignItems="center" gap="5px">
								<InfoOutlinedIcon fontSize="small" sx={{ color: '#5b5b5b' }} />
								<Text
									textType="DESCRIPTION"
									style={{
										fontSize: '13px',
										fontWeight: 400,
										color: '#5b5b5b'
									}}
								>
									상표나 상업용 이미지는 사용 불가합니다.
								</Text>
							</Stack>
							{/* <InfoOutlinedIcon color="rgb(191, 191, 191)" /> */}
							{/* i 상표나 상업용 이미지는 사용 불가합니다. */}
						</Stack>

						<AlbumCoverFile
							description={ImageGuideBox()}
							fileList={formData.album_cover}
							getInputProps={getAlbumCoverInputProps}
							getRootProps={getAlbumCoverRootProps}
							isExistDescription={ImageGuideBox()}
						/>

						<Text textType="DESCRIPTION">
							앨범커버는 아래 규격을 반드시 지켜야 합니다.
							<br />
							<Text style={{ fontWeight: 700 }} textType="DESCRIPTION">
								3,000 X 3,000 px, 10MB 이하의 정방형 JPG, PNG 이미지
							</Text>
						</Text>
						<ErrorMessage formError={formError.album_cover} />
					</Stack>

					<Stack spacing={1}>
						<Stack direction="row" alignItems="center" gap="5px">
							<Tooltip
								title={
									<ul>
										<li style={{ marginBottom: '5px' }}>
											- 부클릿 이미지는 <b>앨범 커버 이미지와는 다른 이미지</b>를 등록해 주세요.
											<br />
											(앨범 커버와 사이즈만 다른 이미지는 부클릿으로 적용되 지 않습니다.)
										</li>
										<li>- 부클릿의 경우 멜론, 벅스, 바이브 플랫폼에만 적용이 가능합니다.</li>
									</ul>
								}
								arrow
								placement="top"
								componentsProps={{
									tooltip: {
										sx: {
											backgroundColor: '#f5f5f9',
											color: 'rgba(0, 0, 0, 0.87)',
											maxWidth: '270px',
											fontSize: '12px',
											border: '1px solid #dadde9'
										}
									}
								}}
							>
								<InfoOutlinedIcon fontSize="small" sx={{ color: '#5b5b5b' }} />
							</Tooltip>
							<Label htmlFor="booklet">부클릿</Label>
						</Stack>
						<MultipleImagFile
							deleteFile={deleteBookletFile}
							description={ImageGuideBox()}
							fileList={formData?.booklet}
							getInputProps={getBookletInputProps}
							getRootProps={getBookletRootProps}
							isExistDescription={<ImageAddIcon />}
							setCurrentIndex={setCurrentBookletIndex}
						/>
						<HDivider margin="5px" type="TRANSPARENT" />
						<Text textType="DESCRIPTION">
							부클릿은 아래 규격을 반드시 지켜야 합니다.
							<br />
							<Text style={{ fontWeight: 700 }} textType="DESCRIPTION">
								2,000 X 2,000 px, 10MB 이하의 정방형 JPG, PNG 이미지
							</Text>
						</Text>
					</Stack>
				</Stack>

				<HDivider margin="70px" type="TRANSPARENT" />
			</Form>
			<ArtistAddModal
				isOpenArtistAddModal={uc_artistAddOpen}
				setIsOpenArtistAddModal={uf_setAristAddOpen}
				mode="S001"
				setResult={setArtistAddResult}
			/>
			<AlertModal
				isPopupOpen={isAlertOpen}
				alertText={alertText}
				hidePopup={() => {
					setIsAlertOpen(false);
				}}
			/>
		</SignUpLayout>
	);
}

export default Info;
