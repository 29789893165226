import Label from '@components/form/Label';
import TTextField from '@components/form/TTextField';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';
import { Divider, Stack, Typography } from '@mui/material';
import { gf_checkSSN } from '@utils/misc';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import Text from '@components/form/Text';

import { checkOnlyNumber } from '@containers/SignUp';

import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form';
import { $mixtapeGetUser } from '@utils/auth';

function RegidentNoForm() {
  const {
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    control,
  } = useFormContext();

  const [user_rrn_front, user_rrn_back, isForeigner] = watch(['user_rrn_front', 'user_rrn_back', 'isForeigner']);
  const [uc_title, uf_setTitle] = useState('');
  const userInfo = $mixtapeGetUser();

  useEffect(() => {
    if (userInfo.foreigner_state === 0) {
      uf_setTitle('주민등록번호');
    } else if (userInfo.foreigner_state === 1) {
      uf_setTitle('외국인 등록번호');
    }
  }, []);

  useEffect(() => {
    if (user_rrn_front && user_rrn_back) {
      const checked = gf_checkSSN(user_rrn_front + '' + user_rrn_back);

      if (!checked) {
        setError('user_rrn_front', { message: `올바르지 않은 ${uc_title}입니다.` });
        setValue('regiNoChecked', false);
      } else {
        clearErrors(['user_rrn_front']);
        setValue('regiNoChecked', true);
      }
    }
  }, [user_rrn_front, user_rrn_back]);

  return (
    <Stack alignItems="flex-start" sx={{ width: '100%' }} spacing={2}>
      <Label htmlFor="signup_user_rrn_front" required={true}>
        <Text textType="HEADER3">{uc_title}</Text> (원천징수 세금 신고 목적 수집)
      </Label>
      {/* <Divider flexItem /> */}
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }} spacing={2} pt={2}>
        <Controller
          name="user_rrn_front"
          control={control}
          rules={{ required: `${uc_title} 앞자리를 입력하세요` }}
          render={({ field }) => (
            <TTextField
              {...field}
              sx={{ width: '50%' }}
              id="signup_user_rrn_front"
              onKeyDown={checkOnlyNumber}
              inputProps={{ maxLength: 6 }}
              placeholder="앞 6자리"
            />
          )}
        />
        <span>-</span>
        <Controller
          name="user_rrn_back"
          control={control}
          rules={{ required: `${uc_title} 뒷자리를 입력하세요` }}
          render={({ field }) => (
            <TTextField
              {...field}
              sx={{ width: '50%' }}
              id="user_rrn_back"
              onKeyDown={checkOnlyNumber}
              inputProps={{ maxLength: 7 }}
              placeholder="뒤 7자리"
              type="password"
            />
          )}
        />
      </Stack>

      {(errors?.user_rrn_front || errors?.user_rrn_back) && errors?.user_rrn_front ? (
        <ErrorMessage message={errors?.user_rrn_front?.message ?? ''} />
      ) : (
        <ErrorMessage message={errors?.user_rrn_back?.message ?? ''} />
      )}
    </Stack>
  );
}

export default RegidentNoForm;
