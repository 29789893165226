import Checkbox from '@components/form/Checkbox';
import { TPlatform, TPlatformKind } from '@containers/AlbumManagement/ConfirmModal/usePlatformCheckBoxControl';
import React, { useContext, useEffect, useState } from 'react';

interface IProps {
	section: { [key: string]: string };
	platformList: TPlatform;
	handleChangeCheckedPlatform: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
	onAllCheckboxes: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
	allPlatformCheckbox: {
		[key in TPlatformKind]: boolean;
	};
}

const ReleasePlatformCheckBox = ({
	section,
	platformList,
	allPlatformCheckbox,
	handleChangeCheckedPlatform,
	onAllCheckboxes
}: IProps) => {
	return (
		<div
			style={{ background: '#f5f5f5', overflow: 'hidden', width: '100%', marginBottom: '20px', padding: '10px 15px' }}
		>
			<p>
				<Checkbox
					checked={allPlatformCheckbox[section['id']]}
					id={section['id']}
					label={section.label}
					onChange={(e) => onAllCheckboxes(e, section['id'])}
				/>
			</p>
			{platformList[section['id']].map((country) => (
				<p key={country.dsp_code} style={{ float: 'left', marginRight: '15px', color: '#5b5b5b' }}>
					<Checkbox
						checked={country.checked}
						id={country.id}
						label={country.dsp_view_name}
						onChange={(e) => {
							handleChangeCheckedPlatform(e, section['id']);
						}}
					/>
				</p>
			))}
		</div>
	);
};

export default ReleasePlatformCheckBox;
