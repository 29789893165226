import React, { FunctionComponent, ReactElement, useState } from 'react';
import ReactDOM from 'react-dom';

import styled from '@emotion/styled';
import { Icon } from '@images/Icon';
import { MacScrollbar } from 'mac-scrollbar';

export interface ModalProps {
	wrapperStyle?: any;
	isShown: boolean;
	hide: () => void;
	children: Array<JSX.Element> | JSX.Element;
	headerText?: any;
	closeButtonStyle?: any;
	modalStyle?: any;
	hideCloseButton?: boolean;
	header?: any;
	contentStyle?: any;
}

export const Wrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 800;
	width: inherit;
	outline: 0;
`;

export const Backdrop = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 700;
`;

export const StyledModal = styled(MacScrollbar)`
	z-index: 100;
	background: white;
	position: relative;
	margin: auto;
	border-radius: 20px;
	width: 480px;
	max-height: 900px;
	overflow: scroll;
`;

export const Header = styled.div`
	border-radius: 8px 8px 0 0;
	display: flex;
	justify-content: space-between;
	padding: 26px 29px 0px 30px;
`;

export const HeaderText = styled.div`
	font-family: Pretendard;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	white-space: pre-line;
`;

export const CloseButton = styled.button`
	font-size: 0.8rem;
	border: none;
	border-radius: 3px;
	margin-left: 0.5rem;
	background: none;
	:hover {
		cursor: pointer;
	}
`;

export const Content = styled.div`
	padding: 0px 30px 30px 30px;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	justify-content: center;
`;

const Modal: FunctionComponent<ModalProps> = ({
	wrapperStyle,
	isShown,
	hide,
	children,
	headerText,
	closeButtonStyle,
	modalStyle,
	hideCloseButton,
	header,
	contentStyle
}) => {
	const modal = (
		<>
			<Backdrop onClick={hide} />
			<Wrapper style={{ ...wrapperStyle }}>
				<StyledModal style={{ ...modalStyle }}>
					<Header>
						{header || <HeaderText>{headerText}</HeaderText>}
						{!hideCloseButton && (
							<CloseButton onClick={hide} style={{ ...closeButtonStyle }}>
								<Icon icon="btnClose" style={{ width: '19px', height: '19px' }} />
							</CloseButton>
						)}
					</Header>
					<Content style={{ ...contentStyle }}>{children}</Content>
				</StyledModal>
			</Wrapper>
		</>
	);

	return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
