import AlertDialog from '@components/form/AlertDialog';
import Modal from '@components/form/Modal';
import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import MixButton from '@components/ui/MixButton';
import styled from '@emotion/styled';
import { useModal } from '@hooks/useModal';
import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { $mixtapeSetUser, $mixtapeUserSeq } from '@utils/auth';
import history from '@utils/history';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';

const ActionButton = styled(Button)`
	width: 170px;
	height: 54px;
	padding: 17px 70px;
	font-size: 18px;
	font-weight: 300;
	border: 1px solid black;
	color: #121212;
`;

const NoticeTable = styled.table`
	width: 100%;
	margin-bottom: 40px;

	& th {
		width: 20%;
		text-align: center;
	}

	& th,
	td {
		padding: 25px 0px;
		border-top: 1px solid #767676;
		border-bottom: 1px solid #767676;
		font-size: 16px;
		font-weight: 300;
	}

	& td,
	td > a {
		text-decoration: none;
		color: #909090;
	}
`;

const ModalList = ({ sessionUserInfo }: any) => {
	const { pathname, search } = useLocation();
	const { isShown: isGuideOpen, toggle: setIsGuideOpen } = useModal();
	const { isShown: isQuestionOpen, toggle: setIsQuestionOpen } = useModal();
	const { isShown: isNotMembershipOpen, toggle: setIsNotMembershipOpen } = useModal();
	const { isShown: isCompleteAgreeOpen, toggle: setIsCompleteAgreeOpen } = useModal();
	const { openState, setOpenState, message, popupMessage } = usePopupMessage();
	const { ctf_sendHttpPost } = useContext(BackdropContext);
	const [agreeState, setAgreeState] = useState();

	useEffect(() => {
		// 비회원
		if (!sessionUserInfo) {
			history.push('/login');
		} else {
			if (sessionUserInfo.is_membership === 0) {
				setIsNotMembershipOpen();
			} else {
				if (sessionUserInfo.agree_chk === 1) {
					setIsCompleteAgreeOpen();
				} else {
					// 동의
					if (new URLSearchParams(search).get('agree') === 'true') {
						agreeNotice(1);
					}
					// 비동의
					else {
						setIsQuestionOpen();
					}
				}
			}
		}
	}, []);

	const agreeNotice = async (check: number) => {
		/** agree_check -> 1 : 동의, 2 : 비동의  */
		const params = {
			agree_check: check,
			path: 'pc_main'
		};
		setAgreeState(check === 1 ? true : false);

		const res = await ctf_sendHttpPost('/users/agree', params);
		if (res.data.code === '200') {
			setIsGuideOpen();
		} else {
			popupMessage(res.data.message);
		}
		if (isQuestionOpen) {
			setIsQuestionOpen();
		}
	};

	return (
		<>
			<Modal
				modalStyle={{ width: '660px' }}
				closeButtonStyle={{ width: '24px', heigth: '24px' }}
				hide={setIsGuideOpen}
				isShown={isGuideOpen}
			>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					padding="32px"
					gap="50px"
				>
					<Typography fontSize="34px" fontWeight="bold">
						<span style={{ color: '#FF3120' }}>{agreeState ? '동의' : '비동의'}</span> 처리되었습니다.
					</Typography>
					<Typography fontSize="18px" lineHeight="30px">
						{agreeState ? (
							<>
								<span style={{ fontWeight: 600 }}>8월 1일</span>부터 개정 약관이 적용될 예정입니다.
								<br /> 동의 철회를 원하시거나 궁금한 사항이 있는 경우
								<br />
								고객센터를 통해 연락해 주세요.
							</>
						) : (
							<>
								<span style={{ fontWeight: 600 }}>2024년 7월 31일까지</span> 믹스테이프를
								<br /> 이용하실 수 있으며, 비동의 철회를 원하시는 경우
								<br /> 공지사항에서 다시 선택해 주세요.
							</>
						)}
					</Typography>
					<MixButton
						onClick={() => {
							setIsGuideOpen();
							window.location.replace(pathname);
						}}
						text={agreeState ? '동의완료' : '비동의완료'}
						style={{ width: '200px', height: '64px', marginBottom: '18px' }}
					/>
				</Stack>
			</Modal>
			<Modal
				modalStyle={{ width: '660px' }}
				closeButtonStyle={{ width: '24px', heigth: '24px' }}
				hide={setIsQuestionOpen}
				isShown={isQuestionOpen}
			>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					padding="32px"
					gap="50px"
				>
					<Typography fontSize="34px" fontWeight="bold">
						개정 약관 <span style={{ color: '#FF3120' }}>비동의</span> 처리할까요?
					</Typography>
					<ul
						style={{
							listStyle: 'disc',
							fontSize: '18px',
							fontWeight: 300,
							lineHeight: '30px',
							textAlign: 'left'
						}}
					>
						<li>
							개정 약관 적용에 비동의하시면{' '}
							<span style={{ color: '#FF3120', fontWeight: 'bold' }}>8월 1일부로 멤버십이 종료</span>되며,
							<br /> 멤버십 잔여기간에 따라 <span style={{ fontWeight: 'bold' }}>부분 환불</span> 처리될 예정입니다.
						</li>
						<li style={{ margin: '10px 0' }}>
							멤버십 종료에 따라 발매된 앨범들은 모든 음원 플랫폼에서 <br /> 순차적으로{' '}
							<span style={{ color: '#FF3120', fontWeight: 'bold' }}>서비스 중지</span> 처리되며, 7월 판매분까지
							정산됩니다.
						</li>
					</ul>

					<Stack direction="row" gap="22px">
						<MixButton
							onClick={() => {
								agreeNotice(1);
								setIsQuestionOpen();
							}}
							text="동의"
							butonType="OUTLINE"
							style={{ width: '200px', height: '64px', marginBottom: '18px' }}
							padding="0"
							bgColor="white"
							fontColor="black"
						/>
						<MixButton
							onClick={() => {
								agreeNotice(2);
							}}
							text="비동의"
							style={{ width: '200px', height: '64px', marginBottom: '18px' }}
						/>
					</Stack>
				</Stack>
			</Modal>
			<Modal
				modalStyle={{ width: '660px' }}
				closeButtonStyle={{ width: '24px', heigth: '24px' }}
				hide={setIsNotMembershipOpen}
				isShown={isNotMembershipOpen}
			>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					padding="32px"
					gap="50px"
				>
					<Typography fontSize="34px" fontWeight="bold">
						안내
					</Typography>
					<Typography fontSize="18px" lineHeight="30px">
						고객님은 개정 약관 동의 대상이 아닙니다. <br />
						멤버십에 가입한 mixtape. 회원만 동의 여부를 <br />
						선택할 수 있습니다.
					</Typography>
					<MixButton
						onClick={() => {
							setIsNotMembershipOpen();
						}}
						text="닫기"
						style={{ width: '200px', height: '64px', marginBottom: '18px' }}
					/>
				</Stack>
			</Modal>

			<Modal
				modalStyle={{ width: '660px' }}
				closeButtonStyle={{ width: '24px', heigth: '24px' }}
				hide={setIsCompleteAgreeOpen}
				isShown={isCompleteAgreeOpen}
			>
				<Stack
					direction="column"
					justifyContent="center"
					alignItems="center"
					textAlign="center"
					padding="32px"
					gap="50px"
				>
					<Typography fontSize="34px" fontWeight="bold">
						안내
					</Typography>
					<Typography fontSize="18px" lineHeight="30px">
						고객님은 개정 약관 동의 여부 선택을 <br />
						완료하셨습니다. 동의 여부 변경을 원하시는 경우 <br />
						고객센터를 통해 연락주세요.
					</Typography>
					<MixButton
						onClick={() => {
							setIsCompleteAgreeOpen();
						}}
						text="닫기"
						style={{ width: '200px', height: '64px', marginBottom: '18px' }}
					/>
				</Stack>
			</Modal>
			<AlertDialog isPopupOpen={openState} alertText={message} hidePopup={() => setOpenState(false)} />
		</>
	);
};

const Detail = () => {
	const history = useHistory();
	const { id } = useParams();
	const { search } = useLocation();
	const sessionUserInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')!) : null;
	const { ctf_sendHttpGet } = useContext(BackdropContext);
	const [noticeDetail, setNoticeDetail] = useState(null);

	const callUserInfo = async () => {
		const userSeq = $mixtapeUserSeq();
		const res = await ctf_sendHttpGet(`/users/${userSeq}`);

		if (!res.error) {
			$mixtapeSetUser(res.data);
		}
	};

	const getNoticeDetail = async () => {
		const sessionUserInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')!) : null;

		const result = await ctf_sendHttpGet(sessionUserInfo === null ? `/board` : '/user/board', {
			board_name: 'board_notices',
			id
		});

		setNoticeDetail(result.data.contents.data[0]);
	};

	const handleDownload = (file: any) => {
		const a = document.createElement('a');
		a.href = 'https://down.mixtape.so' + file.path;
		a.setAttribute('download', file.origin_name);
		a.setAttribute('target', '_blank');
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};
	useEffect(() => {
		if (sessionUserInfo) {
			callUserInfo();
		}
		getNoticeDetail();
	}, [id]);

	return noticeDetail ? (
		<>
			<MainHeader logo="logoW" />
			<Stack
				direction="column"
				paddingY="200px"
				color="#191919"
				justifyContent="center"
				alignItems="center"
				bgcolor="#FFFFFF"
			>
				<Stack direction="column" alignItems="center" style={{ minWidth: '1280px', maxWidth: '1280px' }}>
					<Typography fontSize="50px" fontWeight="bold" mb="105px">
						공지사항
					</Typography>
					{/* 글 부분 */}
					<Stack
						direction="column"
						width="100%"
						borderTop="2px solid #767676"
						borderBottom="2px solid #767676"
						mb="40px"
					>
						{/* 타이틀 부분 */}
						<Stack direction="column" padding="26px 40px" gap="12px" borderBottom="1px solid #D4D4D8">
							<Typography fontSize="20px" fontWeight="bold" lineHeight="24px">
								{noticeDetail.subject}
							</Typography>
							<Stack direction="row" alignItems="center" gap="12px">
								<Typography fontSize="16px" color="#909090">
									{noticeDetail.category_name || ''}
								</Typography>
								<div style={{ width: '1px', height: '16px', background: '#767676' }}></div>
								<Typography fontSize="16px" color="#909090">
									{moment(noticeDetail.created_at).format('YYYY-MM-DD')}
								</Typography>
							</Stack>
							<Stack direction="column" padding="40px">
								<div dangerouslySetInnerHTML={{ __html: noticeDetail.content }}></div>
								{/* 이미지 미리보기 */}
								{noticeDetail.files &&
									noticeDetail.files.length > 0 &&
									noticeDetail.files.map((file: any) => {
										if (
											file.format === 'jpg' ||
											file.format === 'jpeg' ||
											file.format === 'png' ||
											file.format === 'gif'
										) {
											return (
												<img
													key={file.id}
													style={{ marginTop: '20px' }}
													alt={file.origin_name}
													src={'https://down.mixtape.so' + file.path}
												></img>
											);
										} else {
											return (
												<span style={{ color: 'blue' }} onClick={() => handleDownload(file)}>
													{file.origin_name}
												</span>
											);
										}
									})}
							</Stack>
						</Stack>
					</Stack>
					<NoticeTable>
						<tbody>
							<tr>
								<th>이전글</th>
								<td>
									{noticeDetail.prev_post ? (
										<Link to={`/notice/${noticeDetail.prev_post.id}`}>{noticeDetail.prev_post.subject}</Link>
									) : (
										'이전글이 존재하지 않습니다.'
									)}
								</td>
							</tr>
							<tr>
								<th>다음글</th>
								<td>
									{noticeDetail.next_post ? (
										<Link to={`/notice/${noticeDetail.next_post.id}`}>{noticeDetail.next_post.subject}</Link>
									) : (
										'다음글이 존재하지 않습니다.'
									)}
								</td>
							</tr>
						</tbody>
					</NoticeTable>
					<Stack direction="row" justifyContent="start" width="100%">
						<ActionButton
							onClick={() => {
								history.push('/help/notice');
							}}
						>
							목록
						</ActionButton>
					</Stack>
					{/* 이전글 다음글 */}
				</Stack>
			</Stack>
			<MainFooter />
			{new URLSearchParams(search).get('agree') !== null && <ModalList sessionUserInfo={sessionUserInfo} />}
		</>
	) : (
		<></>
	);
};

export default Detail;
