import MainHeader from '@components/molecules/MainHeader';
import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { Button, ButtonGroup, Pagination, Stack, ThemeProvider, Typography, createTheme } from '@mui/material';
import MainFooter from '@components/molecules/MainFooter';
import { MixGrid } from '@components/ui/MixGrid';
import styled from '@emotion/styled';
import { BackdropContext } from '@hooks/usePopupMessage';
import Input from '@components/form/Input';
import SearchIcon from '@mui/icons-material/Search';

const FaqFilterButtonGroup = styled(ButtonGroup)`
	& button {
		border-radius: 0;
		font-size: 14px;
		padding-top: 13px;
		padding-bottom: 13px;
	}
`;

// const SelectRow = styled(Stack)`
//   margin-bottom: 30px;

//   & select {
//     width: 190px;
//     border-color: white;
//     border-radius: 5px;
//     background-color: transparent;
//   }
// `;

const SelectRow = styled(Stack)({
	marginBottom: '30px',
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: 'white',
		height: '38px'
	}
});

const WriteButton = styled(Button)`
	flex: none;
	/* width: 60px;
  height: 36px; */
	padding: 10px 16px;
	font-size: 14px;
	font-weight: 300;
	background-color: #effd60;
	color: #121212;
`;

const ActionButton = styled(Button)`
	flex: none;
	/* width: 60px;
  height: 36px; */
	padding: 6px 12px;
	font-size: 14px;
	font-weight: 300;
	/* background-color: transparent; */
	color: white;
`;

const StyledInputBox = styled.div`
	border: 1px solid #fff;
	border-radius: 6px;
	display: flex;
	align-items: center;
	background: #1d1d1d;
	overflow: hidden;
	padding: 0 !important;
`;

interface IFaqGridParams {
	faqList: any;
	pageInfo: any;
}

const FaqGrid = ({ faqList, pageInfo }: IFaqGridParams) => {
	const getPostNumber = (index, curPage, total, perpage) => {
		const startNumber = total - (curPage - 1) * perpage;

		return startNumber - index;
	};

	const columns = [
		{
			field: 'null',
			headerName: '',
			width: '2%'
		},
		{
			field: 'id',
			headerName: '번호',
			width: '10%',
			customCell: (_: any, index: number) => {
				return <>{pageInfo && <>{getPostNumber(index, pageInfo.current_page, pageInfo.total, pageInfo.per_page)}</>}</>;
			}
		},
		{
			field: 'category_name',
			headerName: '분류',
			width: '20%'
		},
		{
			field: 'subject',
			headerName: '제목',
			width: '63%'
		},
		{
			field: 'button',
			headerName: '',
			width: '5%'
		}
	];

	return <MixGrid columns={columns} rows={faqList} toggleOption={{ field: 'content' }}></MixGrid>;
};

const darkTheme = createTheme({
	typography: {
		fontFamily: 'Pretendard',
		fontWeightBold: 600,
		fontWeightRegular: 300
	},
	palette: {
		mode: 'dark',
		secondary: {
			main: '#EFFD60'
		}
	}
});

const Faq = () => {
	const [curPage, setCurpage] = useState(1);
	const [curCategory, setCategory] = useState(612);
	const [pageInfo, setPageInfo] = useState<{
		current_page: number;
		last_page: number;
		total: number;
		per_page: number;
		isFirst: boolean;
		isLast: boolean;
	}>();
	const [keyword, setKeyword] = useState('');

	const [faqList, setFaqList] = useState([]);

	const { ctf_sendHttpGet } = useContext(BackdropContext);

	const getFaqList = async () => {
		const result = await ctf_sendHttpGet(`/board`, {
			board_name: 'board_faqs',
			page: curPage,
			keyword,
			category_id: curCategory
		});

		setFaqList(result.data.contents.data);
		setPageInfo(result.data.contents.pageInfo);
	};

	useEffect(() => {
		getFaqList();
	}, [curPage, curCategory]);

	const changeCurPage = (event: React.ChangeEvent<unknown>, value: number) => {
		setCurpage(value);
	};

	const changeCategory = (id: number) => {
		if (id === curCategory) {
			return;
		}
		setCategory(id);
	};

	const getButtonStyle = (id: number) => {
		const style = {
			backgroundColor: '#1D1D1D',
			color: '#FFFFFF',
			border: '1px solid #fff',
			'&:hover': {
				color: '#fff'
			}
		};

		if (id === curCategory) {
			style.backgroundColor = '#FFFFFF';
			style.color = '#191919';
		}
		return style;
	};

	return (
		<>
			<MainHeader logo="logoW" />
			<ThemeProvider theme={darkTheme}>
				<Stack
					direction="column"
					paddingY="200px"
					color="white"
					justifyContent="center"
					alignItems="center"
					bgcolor="#1E1E1E"
				>
					<Stack direction="column" alignItems="center" style={{ minWidth: '1280px', maxWidth: '1280px' }}>
						<Typography fontSize="50px" fontWeight="bold">
							FAQ
						</Typography>

						<FaqFilterButtonGroup variant="outlined" color="inherit" fullWidth style={{ margin: '80px 0' }}>
							<Button
								sx={getButtonStyle(612)}
								onClick={() => {
									changeCategory(612);
								}}
							>
								전체
							</Button>
							<Button
								sx={getButtonStyle(608)}
								onClick={() => {
									changeCategory(608);
								}}
							>
								회원 / 정책 문의
							</Button>
							<Button
								sx={getButtonStyle(609)}
								onClick={() => {
									changeCategory(609);
								}}
							>
								앨범 발매/수정 문의
							</Button>
							<Button
								sx={getButtonStyle(610)}
								onClick={() => {
									changeCategory(610);
								}}
							>
								정산 문의
							</Button>
							<Button
								sx={getButtonStyle(611)}
								onClick={() => {
									changeCategory(611);
								}}
							>
								프로모션 / 기타 문의
							</Button>
						</FaqFilterButtonGroup>
						<SelectRow width="100%" direction="row" justifyContent="flex-end" gap="12px">
							{/* <Select
              controlStyle={{ width: '190px', height: '38px', backgroundColor: '#1D1D1D', borderRadius: '6px' }}
              // onChange={(e: ChangeEvent) => {
              //   setAlbumRegistrationFormData(e, 'type_id', true);
              // }}

              options={[
                { label: '재목', value: 'subject' },
                { label: '내용', value: 'contents' },
              ]}
              placeholder="검색 조건"
              // value={formData.type_id ?? ''}
            ></Select> */}
							<StyledInputBox>
								<Input
									id="keyword"
									type="text"
									placeholder="검색어를 입력해주세요."
									onChange={(e: ChangeEvent) => {
										setKeyword(e.target.value);
									}}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											if (curPage !== 1) {
												setCurpage(1);
											} else {
												getFaqList();
											}
										}
									}}
									value={keyword}
									style={{
										flexGrow: 1,
										width: '190px',
										height: '14px',
										backgroundColor: '#1D1D1D',
										padding: '12px 20px 12px 20px',
										color: 'white',
										border: 0
									}}
								/>
								<Button
									style={{
										backgroundColor: '#1D1D1D',
										borderWidth: '1px',
										height: '20px'
									}}
									onClick={() => {
										if (curPage !== 1) {
											setCurpage(1);
										} else {
											getFaqList();
										}
									}}
								>
									<SearchIcon sx={{ color: '#fff' }} />
								</Button>
							</StyledInputBox>
						</SelectRow>

						<FaqGrid faqList={faqList} pageInfo={pageInfo}></FaqGrid>
						{/* <Stack width="100%" direction="row" justifyContent="flex-end" mt="30px">
              <WriteButton
                onClick={() => {
                  history.push('/help/faq-write');
                }}
              >
                글쓰기
              </WriteButton>
            </Stack> */}
						{pageInfo && pageInfo.total > 0 && (
							<Stack mt="97px">
								<Pagination
									count={Math.ceil(pageInfo.total / pageInfo.per_page)}
									page={curPage}
									onChange={changeCurPage}
									color="secondary"
									shape="rounded"
								/>
							</Stack>
						)}

						{/* <hr style={{ width: '100%', height: '1px', marginBottom: '30px', backgroundColor: '#767676' }}></hr>
            <Stack width="100%" direction="column" gap="30px">
              <Stack width="100%" direction="row" justifyContent="flex-start" alignItems="center">
                <span style={{ marginRight: '20px' }}>• 해당 글을</span>
                <ActionButton variant="outlined">삭제</ActionButton>
              </Stack>
              <Stack width="100%" direction="row" justifyContent="flex-start" alignItems="center">
                <span style={{ marginRight: '20px' }}>• 해당 글을</span>
                <ActionButton variant="outlined">글 이동</ActionButton>
              </Stack>
            </Stack> */}
					</Stack>
				</Stack>
			</ThemeProvider>
			<MainFooter />
		</>
	);
};

export default Faq;
