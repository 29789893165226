import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import { AUTH_DORMANT_ACCOUNT_ACTION } from '@constants/actionNames';
import { useReduxData } from '@hooks/useReduxData';

import Completed from './Completed';
import SetActivation from './SetActivation';

export type TCurrentStep = 'INIT' | 'COMPLETED';

type TDormantAccountModalProps = {
  setIsOpenDormantAccountModal: () => void;
  isOpenDormantAccountModal: boolean;
};

function DormantAccountModal({ setIsOpenDormantAccountModal, isOpenDormantAccountModal }: TDormantAccountModalProps) {
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('INIT');
  const { clearData: cleartDormantAccount } = useReduxData(AUTH_DORMANT_ACCOUNT_ACTION);

  useEffect(() => {
    if (!isOpenDormantAccountModal) {
      cleartDormantAccount();
      setCurrentStep('INIT');
    }
  }, [isOpenDormantAccountModal]);

  return (
    <Modal
      headerText={currentStep === 'INIT' ? '휴면계정 해제 안내' : '휴면계정 해제 완료'}
      hide={setIsOpenDormantAccountModal}
      isShown={isOpenDormantAccountModal}
    >
      <>
        {currentStep === 'INIT' && (
          <SetActivation setCurrentStep={setCurrentStep} setIsOpenDormantAccountModal={setIsOpenDormantAccountModal} />
        )}
        {currentStep === 'COMPLETED' && (
          <Completed setCurrentStep={setCurrentStep} setIsOpenDormantAccountModal={setIsOpenDormantAccountModal} />
        )}
      </>
    </Modal>
  );
}

export default DormantAccountModal;
