import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import React from 'react';

import mixtapeLogo from '@images/png/logo_w.png';

const MembershipTable = styled.table`
  width: 100%;
  margin-bottom: 80px;
  font-size: 18px;
  font-weight: 300;

  & th {
    width: calc(100% / 3);
    padding: 20px 24px;
    font-size: 24px;
    border-bottom: 8px solid #ffffff;
  }

  & td {
    padding: 20px 24px;
    border-bottom: 1px solid #d4d4d8;
  }

  & td:nth-child(2) {
    color: #eefd60;
  }

  & td:nth-child(3) {
    color: #60e2fd;
  }
`;

const YoutubeCID = styled(Stack)`
  width: 1150px;
  padding: 60px 65px;
  border-radius: 24px;
  background-color: #fff;

  div {
    padding: 10px 36px;
    border: 2px solid #ff3120;
    border-radius: 25px;
    font-size: 18px;
    font-weight: bold;
    color: #ff3120;
  }
`;

const ProMembership = () => {
  return (
    <>
      <MainHeader logo="logoW"></MainHeader>
      <Stack direction="column" color="white" justifyContent="center" alignItems="center" paddingY="200px" bgcolor="#1E1E1E">
        <Stack direction="column" justifyContent="center" alignItems="center" style={{ minWidth: '1280px', maxWidth: '1280px' }}>
          <Typography fontSize="50px" fontWeight="bold" mb="80px">
            PRO 요금제 출시
          </Typography>

          <Typography fontSize="18px" textAlign="center" lineHeight="32px" mb="80px">
            👋 안녕하세요! Team mixtape입니다.
            <br />
            아티스트들의 노력과 열정이 깃든 소중한 앨범들이 모여 mixtape가 정식 서비스로 거듭나게 되었습니다! <br />
            여러분의 뜨거운 성원에 힘입어 더 열심히 하는 mixtape가 되겠습니다! 🙇 <br />
            mixtape가 정식 서비스로 출시되며{' '}
            <span style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#EEFD60' }}>PRO 요금제</span>가 추가될
            예정입니다. <br />또 다시 많은 관심과 사랑 부탁드리며 새로 탄생한 PRO 요금제와 기존 요금제를 비교해드리겠습니다!{' '}
            <br />
            아래 내용을 참고하셔서 자신에게 맞는 요금제를 선택해주세요. 🤭
          </Typography>
          <MembershipTable>
            <thead>
              <tr>
                <th style={{ backgroundColor: '#767676' }}>구분</th>
                <th style={{ backgroundColor: '#EEFD60', color: '#191919' }}>베이직</th>
                <th style={{ backgroundColor: '#60E2FD', color: '#191919' }}>프로</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>가격</td>
                <td>연 66,000원 (월 5,500원)</td>
                <td>연 99,000원 (월 8,250원)</td>
              </tr>
              <tr>
                <td>유통 수수료</td>
                <td>0%</td>
                <td>0%</td>
              </tr>
              <tr>
                <td>전세계 음원 플랫폼</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>소셜 플랫폼 (FB/IG/TT 등)</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Youtube Content ID</td>
                <td>-</td>
                <td style={{ fontWeight: 'bold' }}>✓ (수수료 15%)</td>
              </tr>
              <tr>
                <td>앨범 발매 가능 횟수</td>
                <td>주 1회</td>
                <td style={{ fontWeight: 'bold' }}>주 3회</td>
              </tr>
              <tr>
                <td>아티스트 프로필 무제한 등록</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>신보 앨범 출시일 예약</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>무료 ISRC/UPC 발급</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>서비스 채널, 국가 제외 제한 설정</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>공식 정산 리포트 제공</td>
                <td>✓</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>발매 후 앨범 수정 가능 횟수</td>
                <td>앨범 당 총 2회</td>
                <td style={{ fontWeight: 'bold' }}>무제한</td>
              </tr>
              <tr>
                <td>트랙 수 제한</td>
                <td>최대 20 트랙</td>
                <td style={{ fontWeight: 'bold' }}>최대 30 트랙</td>
              </tr>
            </tbody>
          </MembershipTable>
          <Typography fontSize="18px" textAlign="center" lineHeight="32px" mb="80px">
            기존 BASIC 요금제에서도 발급할 수 있었던 Youtube CID를 아티스트에게 수익을 더 많이 돌려드리기 위해
            <br />
            정식으로 관리함에 따라 PRO요금제에서만 발급 받을 수 있도록 개편될 예정입니다. 😢 <br />
            또한, <span style={{ color: '#EEFD60' }}>Youtube CID의 경우 처리 난이도가 높고 절차가 복잡해 운영 업무의 증가</span>로
            양질의 서비스를 제공하기 위해 불가피하게 <br />
            <span style={{ color: '#EEFD60' }}>Youtube CID로 발생하는 수익에 대해서만 수수료가 발생</span>하게 된 점 양해
            부탁드립니다. <br />
            (기존에 발급 받으셨던 Youtube CID를 요금제에 따라 철회하지는 않습니다. 다만 안내 드린 대로 정식 <br />
            <span style={{ color: '#EEFD60' }}>서비스 개편됨에 따라 10월 정산분부터 수수료 산정</span>이 이루어질 예정인 점 참고
            부탁드립니다. 🙇)
          </Typography>
          {/* direction="row" justifyContent="space-between" alignItems="center" gap="60px" */}
          <YoutubeCID direction="row" gap="60px" alignItems="center">
            <div>Youtube CID란?</div>
            <Typography width="840px" fontSize="24px" color="#000">
              ‘Youtube Content ID’로 콘텐츠 소유자가 자신의 저작물을 보호하고 관리할 수 있도록 도와주는 도구입니다. 콘텐츠
              소유자가 저작물을 인증할 수 있는 파일(음악, 비디오 등)을 제공하게 되면 이 파일을 통해 저작권이 포함된 콘텐츠를 찾아
              소유자에게 알려 주는 역할을 하고 있어요.
            </Typography>
          </YoutubeCID>
          <img src={mixtapeLogo} width="100%" style={{ maxWidth: '269px', marginTop: '80px', marginBottom: '80px' }}></img>
          <Typography fontSize="18px" textAlign="center" lineHeight="32px">
            아티스트들의 뜨거운 열정에 못지 않게 더 열심히 하는 믹스테이프가 될테니 많은 응원과 격려 부탁드려요.
            <br />
            서비스 개선 의견이 있다면 언제든 저희{' '}
            <a href="https://mixtape.so/help/qna" target="_blank" style={{ color: '#fff', textDecoration: 'underline' }}>
              고객센터
            </a>
            를 통해 연락주세요.
          </Typography>
        </Stack>
      </Stack>
      <MainFooter />
    </>
  );
};

export default ProMembership;
