import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import React from 'react';

const PrivacyTable = styled.table`
  width: 100%;
  overflow-y: scroll;
  border-collapse: collapse;
  tr {
    /* border-bottom: 2px solid #cccccc; */
  }
  th,
  td {
    padding: 17px 10px;
  }
  .th {
    font-weight: 700;
    border-bottom: 2px solid #000000;
    text-align: left;
  }
`;

const Privacy = () => {
  return (
    <Stack direction="column" width="100%">
      <Typography fontSize="14px" color="#CB8CFC">
        믹스테이프는 이용자 개인정보 보호를 위해 항상 최선을 다하고 있습니다.
      </Typography>
      <div style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
        {`
1. 개인정보처리방침이란? 
			
‘개인정보처리방침’이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미합니다.
주식회사 와이지플러스(이하 ‘회사’라 함)는 이용자의 동의를 기반으로 개인정보를 수집·이용 및 제공하고 있으며, 이용자의 권리(개인정보 자기결정권)를 적극적으로 보장합니다. 회사는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을 준수하고 있습니다.

2. 개인정보 수집
회사는 서비스 제공을 위한 필요 최소한의 개인정보를 수집하고 있습니다.
모든 이용자는 회원가입을 통해 회사가 제공하는 음원 및 콘텐츠 유통 서비스를 이용할 수 있습니다. 이용자의 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하도록 하겠습니다.
회원 가입 및 서비스 이용 과정에서 아래와 같은 최소한의 개인정보를 수집하고 있으며, 이용자의 동의 없이 민감정보를 수집하지 않습니다.
 - 필수정보란 : 해당 서비스의 본질적 기능을 수행하기 위한 정보
 - 선택정보란 : 보다 특화된 서비스를 제공하기 위해 추가 수집하는 정보 (선택 정보를 입력하지 않은 경우에도 서비스 이용 제한은 없습니다.)

[개인정보 수집 방법]
 - 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의하고 직접 정보를 입력하는 경우
 - 고객센터 및 1:1 문의를 통한 상담 과정에서 웹페이지, 메일, 전화 등을 통해 개인정보를 제공 받은 경우
 - 온/오프라인에서 진행되는 이벤트, 행사 등 참여를 통해 제공 받는 경우
 - 제휴 서비스 또는 단체 등으로부터 개인정보를 제공 받은 경우

[개인정보 수집 항목]
회사가 수집하는 개인정보는 다음과 같습니다.

[회원가입]
< 필수정보 >
 - 아이디 / 비밀번호 / 성명(실명) / 이메일 주소 / 휴대폰 번호

[본인인증]
< 필수정보 >
 - 성명(실명) / 생년월일 / 성별 / 내/외국인 정보 / 암호화된 동일인 식별정보(CI) / 휴대전화번호 / 이동통신사 정보

[유료서비스(멤버십) 이용]
< 필수정보 >
 - 주민등록번호 또는 외국인등록번호 / 계좌정보(은행명, 계좌번호, 예금주 성명) / 신용카드 결제정보

[이용권 환불]
< 필수정보 >
 - 주민등록번호 또는 외국인등록번호 / 계좌정보(은행명, 계좌번호, 예금주 성명) / 신용카드 결제정보 / 이메일 주소

[마케팅/프로모션]
< 필수정보 >
 - 성명(실명) / 휴대전화번호 / 이메일 주소
 - 상품배송의 경우: 주소지
 - 제세공과금 처리 시: 성명(실명) / 주민등록번호 / 휴대전화번호 / 주소지 / 이메일 주소

[고객상담]
< 필수정보 >
 - 서비스 문의/오류, 결제/해지/환불, 회원 정보, 이벤트 등 : 아이디 / 이메일 주소 / 휴대전화번호 / 사용환경(운영체제, 브라우저, 사용 모델/버전)
고객센터 또는 채널톡 문의/상담 시 위와 같은 정보를 수집할 수 있으며, 문의/상담 유형에 따라 회원님께서 추가로 입력하시는 개인정보가 있을 수 있습니다.
또한, 서비스 이용과정에서 다음과 같이 정보가 자동으로 생성 또는 수집될 수 있습니다.
단말기 정보(모델명, 디바이스 아이디, MAC주소, OS), IP주소, 쿠키, 부정이용기록, 서비스이용기록 등의 정보가 자동으로 생성되어 수집될 수 있습니다.

3. 개인정보 이용
이용자의 개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는 반드시 사전에 이용자에게 동의를 구하도록 하겠습니다.
 - 회원 식별/가입의사 확인, 본인/연령 확인, 부정이용 방지
 - 유료 서비스 이용 시 정산금 지급, 이용권 환불처리
 - 신규 서비스 개발, 다양한 서비스 제공, 문의사항 또는 불만처리, 공지사항 전달
 - 서비스의 원활한 운영에 지장을 주는 행위(계정 도용 및 부정 이용 행위 등 포함)에 대한 방지 및 제재
 - 이벤트/행사 참여 확인, 마케팅 및 광고 등에 활용

4. 개인정보 제공
이용자의 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 이용자의 개인정보를 제3자에게 제공하지 않습니다. 서비스 원활한 운영 및 이용자의 편의를 위하여 제3자와의 서비스 연결하는 경우에는 필요한 범위 내에서 개인정보를 제공할 수 있습니다.

5. 개인정보 처리 위탁
아래와 같이 서비스 제공에 필요한 업무를 외부 업체에 위탁하여 개인정보를 처리하고 있으며, 위탁한 업체가 관계 법령을 위반하지 않도록 관리•감독하고 있습니다.

[개인정보 제3자 제공현황]
				`}
        <div>
          <PrivacyTable>
            <tbody>
              <tr className="th">
                <th>수탁업체</th>
                <th>위탁업무내용</th>
              </tr>
              <tr>
                <td>토스페이먼츠(주)</td>
                <td>유료 멤버십 신용카드 결제</td>
              </tr>
              <tr>
                <td>(주)채널코퍼레이션</td>
                <td>고객 상담 채팅 서비스</td>
              </tr>
              <tr>
                <td>스티비(주)</td>
                <td>이메일 공지 및 뉴스레터 서비스</td>
              </tr>
            </tbody>
          </PrivacyTable>
        </div>
        {`
				6. 개인정보 파기
				개인정보는 수집 및 이용 목적이 달성되면 지체없이 파기되며 절차 및 방법은 다음과 같습니다.
				개인정보는 수집 및 이용목적이 달성되면 지체없이 재생이 불가능한 방법으로 파기합니다. 회사는 전자적 파일 형태로 기록/저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이문서에 기록/저장된 개인정보는 분쇄하거나 소각하여 파기합니다. 또한, ‘개인정보 유효기간제’에 따라 일정기간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하고 있으며, 분리 보관된 개인정보는 4년간 보관 후 지체없이 파기합니다. 다만, 법령 및 서비스 정책에 따라 보관이 필요한 경우에는 아래와 같이 보관한 후 파기합니다.
				[탈퇴회원]
				불량이용자 재가입 방지 및 부정이용 방지 목적을 위해 내부 식별 정보는 12개월 간 보관 후 지체없이 파기합니다.
				이 외에 법령에 따라 일정기간 보관해야 하는 개인정보 및 해당 법령은 아래와 같습니다.
				
				`}
        <PrivacyTable>
          <tbody>
            <tr className="th">
              <th>보관 정보</th>
              <th>보존 이유</th>
              <th>보존 기간</th>
            </tr>
            <tr>
              <td>계약 또는 청약철회 등에 관한 기록</td>
              <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>대금결제 및 재화 등의 공급에 관한 기록</td>
              <td>5년</td>
              <td></td>
            </tr>
            <tr>
              <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
              <td>3년</td>
              <td></td>
            </tr>
            <tr>
              <td>표시/광고에 관한 기록</td>
              <td>6개월</td>
              <td></td>
            </tr>
            <tr>
              <td>전자금융 거래에 관한 기록</td>
              <td>전자금융거래법</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
              <td>국세기본법</td>
              <td>5년</td>
            </tr>
            <tr>
              <td>서비스 이용 관련 로그인 기록</td>
              <td>통신비밀보호법</td>
              <td>3개월</td>
            </tr>
          </tbody>
        </PrivacyTable>
        {`
				7. 이용자의 권리/의무
				회사는 이용자의 소중한 개인정보보호를 위해 다음의 노력을 하고 있습니다.
				이용자는 언제든지 자신의 개인정보를 조회 또는 수정하거나 회원 탈퇴 및 유료멤버십 결제 해지를 통해 개인정보 삭제를 요구할 수 있습니다.
				개인정보 열람 및 처리정지 요구는 「개인정보 보호법」에 따라 정보주체의 권리가 제한될 수 있으며, 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
				보다 구체적으로는 유료 멤버십 서비스 해지(동의 철회) 및 회원 탈퇴를 위해서는 고객센터(mixtape@ygmail.net)를 통해 이메일로 연락하시면 지체 없이 조치하겠습니다. 개인정보의 오류에 대한 정정을 요청하신 경우 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다.
				회사는 이용자의 사전 동의 없이 영리 목적의 광고성 정보를 전송하지 않습니다. 해당 내용을 전송하는 경우 관련 법령이 정하는 방법을 준수하고 필요한 조치를 취하고 온라인 맞춤형 광고를 위해 최소한의 행태정보를 수집하겠습니다.
				회사는 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있으며, 외부 사이트로부터 제공받는 서비스나 자료의 유용성에 대해 책임을 부담하거나 이를 보증하지 아니합니다.
				홈페이지에 포함되어 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 이용에 관하여는 더 이상 회사와 본 서비스의 「개인정보처리방침」이 적용되지 아니합니다.
				
				8. 개인정보 보호조치
				회사는 이용자의 개인정보를 암호화된 통신구간을 이용하여 전송하고, 비밀번호 등 중요정보는 암호화하여 보관하고 있습니다.
				회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다. 해커 등의 침입을 탐지하고 차단할 수 있는 시스템을 설치하여 24시간 감시하고 있으며, 백신 프로그램을 설치하여 시스템이 최신 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다. 또한 새로운 해킹/보안 기술에 대해 지속적으로 연구하여 서비스에 적용하고 있습니다.
				회사는 개인정보를 취급하는 직원을 최소화합니다. 또한 개인정보를 보관하는 데이터베이스 시스템과 개인정보를 처리하는 시스템에 대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한 체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
				개인정보를 취급하는 모든 임직원들을 대상으로 개인정보보호 의무와 보안에 대한 정기적인 교육을 실시하고 있습니다.
				
				9. 기타사항
				회사는 웹기반 서비스의 제공을 위하여 인터넷 서비스를 통하여 고객의 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 설치/운용하고 있습니다. 쿠키란 웹 서버가 웹 브라우저에 보내어 저장했다가 서버의 부가적인 요청이 있을 때 다시 서버로 보내주는 문자열 정보를 말합니다.
				
				- 사용목적 : 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 쿠키를 사용합니다. 이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게 됩니다. 쿠키는 이용자가 웹 사이트를 방문할 때, 웹 사이트 사용을 설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다. 또한, 이용자의 웹사이트 방문 기록, 이용 형태를 통해서 최적화된 광고 등 맞춤형 정보를 제공하기 위해 활용됩니다.
				- 쿠키 수집 거부 : 쿠키에는 이름, 전화번호 등 개인을 식별하는 정보를 저장하지 않으며, 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키 설치를 거부할 경우 웹 사용이 불편해지며, 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
				
				[브라우저별 설정 방법]
				1) Chrome의 경우 : 웹 브라우저 우측 상단 [설정] 메뉴 > [개인정보 및 보안] > [사이트설정] > [쿠키 및 기타 사이트 데이터]
				2) Edge의 경우 : 웹 브라우저 우측 상단 [설정] 메뉴 > [쿠키 및 사이트 권한] > [쿠키 및 사이트 권한]
				3) Internet Explorer의 경우 : 웹 브라우저 우측 상단 [설정] 메뉴 > [인터넷 옵션] > [개인정보] > [고급]
				
				10. 개인정보 보호책임 및 담당
				회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 개인정보보호와 관련해서 궁금하신 사항은 아래의 연락처로 문의해 주시기 바랍니다.
				서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만, 조언이나 기타 사항은 아래의 연락처로 문의해주시기 바라며, 여러분의 목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록 최선을 다하겠습니다.
				
				< 개인정보 보호책임자 >
					성명 : 김 태 현
					담당부서 : 경영기획셀
					연락처 : 02-3140-4686
					이메일 : mixtape@ygmail.net
					
					이용자는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 이용자의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
					
					< 개인정보 열람청구 접수·처리 부서 >
					성명 : 김 태 현
					담당부서 : 경영기획셀
					연락처 : 02-3140-4686
					이메일 : mixtape@ygmail.net
					
					이용자는 제1항의 열람청구 접수 처리부서 이외에, 행정안전부의 ‘개인정보보호 종합지원 포털’ 웹사이트(www.privacy.go.kr)를 통하여서도 개인정보 열람청구를 하실 수 있습니다.
					* 행정안전부 개인정보보호 종합지원 포털 → 개인정보 민원 → 개인정보 열람등 요구 (본인확인을 위하여 아이핀(I-PIN)이 있어야 함)
					
					11. 이용자의 권익침해에 대한 구제
					이용자의 개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
					
					`}
        <PrivacyTable>
          <tbody>
            <tr className="th">
              <th>구분</th>
              <th>연락처</th>
              <th>Site URL</th>
            </tr>

            <tr>
              <td>개인정보침해 신고센터</td>
              <td>(국번없이)118</td>
              <td>https://privacy.kisa.or.kr</td>
            </tr>
            <tr>
              <td>대검찰청 사이버수사과</td>
              <td>(국번없이)1301</td>
              <td></td>
            </tr>
            <tr>
              <td>경찰청 사이버안전국</td>
              <td>(국번없이)182</td>
              <td>https://ecrm.cyber.go.kr</td>
            </tr>
            <tr>
              <td>개인정보분쟁조정위원회</td>
              <td>(국번없이) 1833-6972</td>
              <td>https://www.kopico.go.kr</td>
            </tr>
          </tbody>
        </PrivacyTable>
        {`
				12. 개인정보처리방침 변경 회사는 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을
				수정할 수 있습니다. 개인정보처리방침이 변경되는 경우 변경 사항을 게시하며, 변경된 개인정보처리방침은 게시한
				날로부터 7일 후부터 효력이 발생합니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의
				중대한 변경이 발생할 때에는 최소 30일 전에 미리 알려드리겠습니다. 
				
				부칙 제1조 시행일 본 개인정보처리방침은 2023년 3월 20일부터 적용됩니다.
				`}
      </div>
      <Typography fontSize="14px" color="#CB8CFC" mt="40px">
        이전 개인정보처리방침 보기 (2023년 03월 01일 ~ 2024년 03월 01일)
      </Typography>
    </Stack>
  );
};

export default Privacy;
