import { getCodeList } from '@api/mapper/Code';
import AlertDialog from '@components/form/AlertDialog';
import ChipSelect, { BaseSelect } from '@components/form/ChipSelect';
import ImageFile from '@components/form/ImageFile';
import Text from '@components/form/Text';
import TTextField from '@components/form/TTextField';
import { CancelButton } from '@components/molecules/FindId/Button';
import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

import { Box, Link, MenuItem, Stack, Tooltip, Typography } from '@mui/material';

import { $mixtapeUserSeq } from '@utils/auth';
import { floor } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import HDivider from '@components/form/HDivider';

import Label from '@components/form/Label';

import Chip from '@mui/material/Chip';

import { FindPasswordButton } from '@components/molecules/FindPassword/Button';

import {
  CODE_LIST_ARTIST_ROLE_ACTION,
  CODE_LIST_ARTIST_TYPE_ACTION,
  CODE_LIST_BANK_ACTION,
  CODE_LIST_GENDER_ACTION,
  CODE_LIST_GENRE_ACTION,
  PROFILE_INFO_ADD_ACTION,
  USER_INFO_EDIT_DORMANT_ACCOUNT_ACTION,
} from '@constants/actionNames';

import { useRequest } from '@hooks/useRequest';
import { useDropzone } from 'react-dropzone';

import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';

//@ts-ignore
function S002_02({ setIsOpenArtistAddModal, setResult }) {
  const { openState: uc_open, setOpenState: uf_setOpen, message: uc_message, popupMessage: uf_popupMessage } = usePopupMessage();
  // sessData
  const userSeq = $mixtapeUserSeq();

  // 셀렉트 옵션에 사용할 코드 데이터들

  const { requestForSingle: getGenderCodeListReq, resData: rxc_sexCodes } = useRequest({
    reduxActionName: CODE_LIST_GENDER_ACTION,
    mapperFunc: getCodeList,
  });
  const { requestForSingle: getGenreCodeListReq, resData: rxc_genreCodes } = useRequest({
    reduxActionName: CODE_LIST_GENRE_ACTION,
    mapperFunc: getCodeList,
  });
  const { requestForSingle: getArtistTypeCodeListReq, resData: rxc_artistTypeCodes } = useRequest({
    reduxActionName: CODE_LIST_ARTIST_TYPE_ACTION,
    mapperFunc: getCodeList,
  });
  const { requestForSingle: getArtistRoleCodeListReq, resData: rxc_roleCodes } = useRequest({
    reduxActionName: CODE_LIST_ARTIST_ROLE_ACTION,
    mapperFunc: getCodeList,
  });

  const { ctf_sendHttpPost, ctf_sendHttpGet, ctf_sendHttpPatch } = useContext(BackdropContext);

  const {
    setValue,
    watch,
    formState: { errors },
    getValues,
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      isExistRelease: 'false',
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    getGenderCodeListReq({ parent_code: 'gender' });
    getGenreCodeListReq({ parent_code: 'genre' });
    getArtistTypeCodeListReq({ parent_code: 'artist_type' });
    getArtistRoleCodeListReq({ parent_code: 'artist_role' });
  }, []);

  const validTest = async (value: any) => {
    if (value.length === 0) {
      return false || '아티스트 명을 입력해주세요.';
    }
    if (value.length > 100) {
      return false || '최대 한글 100글자, 이모지 반영 불가';
    }

    const res = await ctf_sendHttpGet('/artists/check-artists', { keyword: value });

    return !res.data.response || '이미 등록한 아티스트명 입니다.';
  };

  const sendProfileData = async () => {
    const v = getValues();

    const params = {
      user_seq: userSeq,
      artist_name: v.artist_name,
      gender_id: v.gender_id,
      type_id: v.type_id,
      genres: v.genres,
      roles: v.roles,
      languages: [v.artist_name_en],
      biography: '',
      real_name: v.real_name,
    };

    const res = await ctf_sendHttpPost('/profile', params);

    if (res.error || !res.data || res.data.length < 1) {
      setResult(null);
    } else {
      // 결과값 전달
      if (v.artist_image && v.artist_image.length > 0) {
        const id = res.data[0].artist_id;
        await upLoadImage(id);
      }

      const addedData = { sender: 'S002_02', ...res.data[0].artists };
      setResult(addedData);
      // 정상처리되면 화면 내용을 바꾸고 문을 닫는다.
      setIsOpenArtistAddModal(false);
    }
  };

  async function upLoadImage(id) {
    const v = getValues();
    const frm = new FormData();
    const file = v.artist_image[0];
    if (file.preview.startsWith('http')) {
      return null;
    }

    frm.append('type', 'artist');
    frm.append('target_id', id);
    frm.append('file', file);

    await ctf_sendHttpPost('/uploads', frm);
  }

  const getCodeOptions = (ele: any) => {
    return {
      label: ele.code_name,
      value: ele.id,
    };
  };

  // artist_name
  // artist_name_en

  // gender_id

  // genres

  // isExistRelease

  // roles

  // type_id

  const watchAll = watch();
  const [uc_sendButtonDisbled, uf_setSendButtonDisabled] = useState(true);
  useEffect(() => {
    let disabled = !watchAll.artist_name || !watchAll.artist_name_en || !watchAll.real_name || !watchAll.gender_id;
    disabled = disabled || !watchAll.genres || !watchAll.genres?.length || watchAll.genres?.length === 0;
    disabled = disabled || !watchAll.roles || !watchAll.roles?.length || watchAll.roles?.length === 0;
    disabled = disabled || !watchAll.type_id;

    uf_setSendButtonDisabled(disabled);
  }, [watchAll]);

  const getCodeMenuItems = (item: any) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.code_name}
      </MenuItem>
    );
  };

  const checkImgFile = (file: any, sizePx: any, param: any, func: any) => {
    if (!file.type.startsWith('image/')) {
      uf_popupMessage('이미지파일만 선택가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const fileMB = floor((file.size / 1024 / 1024) * 100) / 100;
      if (fileMB > 10) {
        uf_popupMessage('10메가를 초과하면 안됩니다.');
        return;
      }

      const uint = new Uint8Array(reader.result);
      const blob = new Blob([uint], { type: file.type });
      const img = new Image();
      img.src = URL.createObjectURL(blob);
      img.onload = () => {
        if (img.width != img.height) {
          uf_popupMessage('프로필 이미지는 10MB 이하의 2,000 X 2,000 px, JPG 파일만 등록 가능합니다.');
          return;
        }
        if (img.width > sizePx) {
          uf_popupMessage(
            `이미지는 아래 규격을 반드시 지켜야 합니다\n1300 X 1300 px 이상 2000 X 2000 px 이하, 10MB 이하의 정방형`,
          );
          return;
        }
        func(file);
      };
    };
  };

  const { getRootProps: getThumnailRootProps, getInputProps: getThumnailInputProps } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: (acceptedFiles: any) => {
      if (acceptedFiles.length !== 1) {
        uf_popupMessage('파일을 하나만 선택하여 주십시오.');
        return;
      }

      const file = acceptedFiles[0];
      checkImgFile(file, 3000, null, (f: any) => {
        setValue('artist_image', [Object.assign(f, { preview: URL.createObjectURL(f) })]);
      });
    },
  });

  const makeChips = (selected, data) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.5,
        }}
      >
        {selected.map((value) => {
          const lbl = data.find((ele) => ele.id === value) ?? value;
          return <Chip key={value} label={lbl.code_name} sx={{ fontFamily: 'Pretendard' }} />;
        })}
      </Box>
    );
  };

  const getTooltipContent = () => {
    return (
      <Stack spacing={2} alignItems="center">
        <Typography
          sx={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
          }}
          align="center"
        >
          1,300 X 1,300 px 이상 2,000 X 2,000 px 이하, 10MB 이하의 정방형 JPG 파일만 업로드 가능하며
          <br /> Apple Music, Spotify는 아티스트가 직접 교체해야 합니다.
        </Typography>

        <Link
          href="https://drive.google.com/file/d/1C2pAe-5G1zripBlrrpqgaAFAXiH8o_co/view?usp=sharing"
          target="_blank"
          sx={{
            color: '#222',
            fontSize: '14px',
            '&.MuiLink-underlineAlways': {
              textDecorationColor: '#888888',
            },
            '&.MuiLink-underlineAlways:hover': {
              textDecorationColor: '#222',
            },
          }}
        >
          Apple Music, Spotify 아티스트 페이지 사용 가이드북 다운로드
        </Link>
      </Stack>
    );
  };

  return (
    <>
      <Stack direction="row" gap="20px" my="20px">
        {/* 이미지 */}
        <Stack direction="column" gap="5px">
          <Stack direction="row" alignItems="center" gap="5px">
            <Text
              style={{
                color: '#3B3B3B',
                fontWeight: 700,
              }}
            >
              아티스트 프로필 사진
            </Text>

            <Tooltip
              title={getTooltipContent()}
              componentsProps={{
                tooltip: {
                  sx: {
                    color: '#222222',
                    bgcolor: '#FFFFFF',
                    borderRadius: '10px',
                    border: '#444444 solid 1px',
                    maxWidth: 'none',
                    fontSize: '14px',
                    fontFamily: 'inherit',
                  },
                },
              }}
            >
              <HelpOutlineRoundedIcon fontSize="small" sx={{ color: '#00000080' }} />
            </Tooltip>
          </Stack>

          <ImageFile
            description={
              <Stack alignItems="center" sx={{ width: '320px' }} spacing={4} mt={3} mb={4}>
                <AddIcon sx={{ fontSize: 120 }} />
                <p>
                  업로드 하려면 이곳을 클릭하거나
                  <br />
                  원하는 파일을 드래그하세요.
                </p>
              </Stack>
            }
            fileList={watch('artist_image')}
            getInputProps={getThumnailInputProps}
            getRootProps={getThumnailRootProps}
          />
          <p style={{ fontSize: '12px' }}>
            프로필 이미지는 아래 규격을 반드시 지켜야 합니다.
            <br />
            <span style={{ fontWeight: 'bold' }}>2,000 X 2,000 px, 10MB 이하의 정방형 JPG 이미지</span>
          </p>
        </Stack>

        {/* 아티스트 명 */}
        <Stack spacing={1.5} sx={{ flex: 1 }}>
          <Label htmlFor="artist_name" required={true}>
            아티스트명
          </Label>
          <Stack gap="8px" mb="16px">
            <Label>국내</Label>

            <Controller
              name="artist_name"
              control={control}
              rules={{
                required: true,
                max: 2,
                validate: validTest,
              }}
              render={({ field }) => <TTextField {...field} placeholder="최대 한글 100자, 이모지 반영 불가" type="text" />}
            />
            {errors.artist_name && <ErrorMessage message={errors.artist_name.message} />}
          </Stack>

          <Stack gap="8px" mb="16px">
            <Label htmlFor="artist_name_en" required={true}>
              해외
            </Label>
            <Controller
              name="artist_name_en"
              control={control}
              rules={{
                required: true,
                max: 3,
              }}
              render={({ field }) => <TTextField {...field} placeholder="최대 영문 100자, 이모지 반영 불가" type="text" />}
            />
          </Stack>
          <Stack gap="8px" mb="16px">
            <Label htmlFor="real_name" required={true}>
              아티스트 본명
            </Label>
            <Controller
              name="real_name"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => <TTextField {...field} placeholder="아티스트의 본명을 입력해주세요." type="text" />}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack sx={{ width: '100%' }} gap="20px" mb="20px">
        <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Label htmlFor="artistType" required={true}>
              아티스트 타입
            </Label>
            <HDivider margin="3px" type="TRANSPARENT" />

            <Controller
              name="type_id"
              control={control}
              rules={{ required: 'Area is required' }}
              render={({ field }) => (
                <BaseSelect value={field.value} control={control} onChange={field.onChange} placeholder="아티스트 타입">
                  {rxc_artistTypeCodes?.map((item: any) => getCodeMenuItems(item))}
                </BaseSelect>
              )}
            />
          </Stack>
          <HDivider margin="13px" type="TRANSPARENT" />

          <Stack spacing={1} sx={{ flex: 1 }}>
            <Label htmlFor="gender_id" required={true}>
              아티스트 성별
            </Label>
            <HDivider margin="3px" type="TRANSPARENT" />
            <Controller
              name="gender_id"
              control={control}
              rules={{ required: 'Area is required' }}
              render={({ field }) => (
                <BaseSelect value={field.value} control={control} onChange={field.onChange} placeholder="아티스트 성별">
                  {rxc_sexCodes?.map((item: any) => getCodeMenuItems(item))}
                </BaseSelect>
              )}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={4} sx={{ width: '100%' }}>
          <Stack spacing={1} sx={{ flex: 1 }}>
            <Label htmlFor="artistGenre" required={true}>
              주요 장르
            </Label>
            <HDivider margin="3px" type="TRANSPARENT" />
            <Controller
              name="genres"
              control={control}
              rules={{ required: 'genres is required' }}
              render={({ field }) => (
                <ChipSelect
                  maxTooltip="장르는 최대 2개까지 선택 가능합니다."
                  value={field.value ? [...field.value] : []}
                  maxSelect={2}
                  placeholder="주요 장르 (최대 2개 선택)"
                  onChange={field.onChange}
                  itemData={rxc_genreCodes}
                />
              )}
            />
          </Stack>

          <Stack spacing={1} sx={{ flex: 1 }}>
            <Label htmlFor="artistRole" required={true}>
              주요 역할
            </Label>
            <HDivider margin="3px" type="TRANSPARENT" />
            <Controller
              name="roles"
              control={control}
              rules={{ required: '아티스트 역할을 선택하여주세요.' }}
              render={({ field }) => (
                <ChipSelect
                  value={field.value ? [...field.value] : []}
                  placeholder="역할 선택"
                  onChange={field.onChange}
                  itemData={rxc_roleCodes}
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="center" mt="26px">
        <CancelButton onClick={() => setIsOpenArtistAddModal(false)} style={{ width: '150px' }}>
          취소
        </CancelButton>
        <FindPasswordButton disabled={uc_sendButtonDisbled} style={{ width: '200px' }} onClick={sendProfileData}>
          프로필 추가 신청
        </FindPasswordButton>
      </Stack>

      <AlertDialog isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />
    </>
  );
}

export default S002_02;
