/* eslint-disable no-nested-ternary */
import { Box, Divider, Stack, Tooltip } from '@mui/material';
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { Icon } from '@images/Icon';
import moment, { Moment as MomentTypes } from 'moment';

import Row from './Row';

const Wrapper = styled(Row)`
  width: 900px;
  height: 385px;
  background: #ffffff;
  border: none;
`;

const CalendarHead = styled(Row)`
  max-height: 24px;

  .title {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    /* color/121212 */

    color: #121212;
  }
`;

const CalendarBody = styled(Row)`
  .box {
    max-height: 32px;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    text-align: center;

    /* color/121212 */

    color: #121212;

    width: 120px;
    cursor: pointer;
  }
  .selected {
    background: #121212;
    width: 30px;
    height: 30px;
    margin-left: 45px;
    margin-right: 45px;
    color: #fff;
    line-height: 29px;
    border-radius: 50%;
  }
  .grayed {
    color: #dddddd;
  }
  .disabled {
    color: #dddddd;
    cursor: default;
  }
  .day {
    cursor: default;
  }

  width: 100%;
  // height: 512px;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  border: none;
`;

function Calendar({ date = new Date(), setDate, availableDates = [] }: any) {
  // func
  const handleDayClick = (current) => {
    uf_setSelectedDate(current);
    setDate(current);
  };

  const jumpToMonth = (num: number) => {
    if (num === -1) {
      const prevBaseDate = moment(uc_baseDate).subtract(1, 'months');
      uf_setBaseDate(prevBaseDate);
    } else {
      const nextBaseDate = moment(uc_baseDate).add(1, 'months');
      uf_setBaseDate(nextBaseDate);
    }
  };

  // useEffect(()=>{
  //   handleDayClick(date);
  // }, [date])
  const [uc_availDateTxts, uf_setAvailDateTxts] = useState([]);
  useEffect(() => {
    uf_setAvailDateTxts(Object.keys(availableDates));
  }, [availableDates]);

  // 기준일 선택일보다 기준일이 날짜 변경의 핵심임
  const [uc_baseDate, uf_setBaseDate] = useState(new Date());
  const [uc_selectedDate, uf_setSelectedDate] = useState(null);

  useEffect(() => {
    uf_setSelectedDate(date);
    uf_setBaseDate(date);
  }, [date]);
  // chalandar generate logic
  function getTooltipBox(dateString) {
    return (
      <Stack>
        <p style={{ textAlign: 'center' }}>{dateString} 잔여 구좌</p>
        <Stack spacing={1}>
          <Divider color="#f1f1f1" />
          <Stack spacing={1}>
            <Stack direction="row" spacing={1}>
              <Box sx={{ width: 100 }} justifyContent="center">
                오후 12시
              </Box>
              <Box sx={{ width: 30, textAlign: 'right' }}>{availableDates[dateString][12]}</Box>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Box sx={{ width: 100 }} justifyContent="center">
                오후 6시
              </Box>
              <Box sx={{ width: 30, textAlign: 'right' }}>{availableDates[dateString][18]}</Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  function generate() {
    const today = moment(uc_baseDate);
    // startOf('month') : 이번 달의 첫번 째 날로 설정 set to the first of this month, 12:00 am
    // week() : Week of Year. 이번 년도의 몇번째 주인가? => 3월 8일이면 10이겠죠?
    const startWeek = today.clone().startOf('month').week();

    // endOf('month').week() : 이번 달의 마지막 날로 설정 한 후 그것이 이번 년도의 몇번째 주인지 체크
    // 만약 이번 해의 첫번째 주(1월 1일이 속한 주)라면 53으로 세팅, 아니라면 그대로 유지
    // 이런 작업의 이유는 마지막 주가 첫 주가 될 수 없기 때문에 당연한 것임
    const endWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

    const calendar = [];

    // 시작 주부터 마지막 주까지 +1 씩 증가시킴
    // 이제 주마다 일을 표기해야 하므로 len이 7인 arr를 생성 후 index를 기반으로 day를 표기하자
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <Row justify="CENTER" key={week} style={{ maxHeight: '70px' }}>
          {Array(7)
            .fill(0)
            .map((n, i) => {
              // 오늘 => 주어진 주의 시작 => n + i일 만큼 더해서 각 주의 '일'을 표기한다.
              const current = today
                .clone()
                .week(week)
                .startOf('week')
                .add(n + i, 'day');

              // 오늘이 current와 같다면 우선 '선택'으로 두자
              const isSelected =
                uc_selectedDate && moment(uc_selectedDate).format('YYYY-MM-DD') === current.format('YYYY-MM-DD')
                  ? 'selected'
                  : '';

              const dateString = current.format('YYYY-MM-DD');
              // // 만약, 이번 달이 아닌 다른 달의 날짜라면 회색으로 표시하자
              // const isGrayed = isDisabled ? true : current.format('MM') !== today.format('MM') ? 'grayed' : '';
              const active =
                uc_availDateTxts.findIndex((ele) => ele === dateString) > -1 &&
                availableDates[dateString] &&
                (availableDates[dateString][12] > 0 || availableDates[dateString][18] > 0);
              const grayed = active ? '' : 'grayed';
              if (current.format('MM') === today.format('MM')) {
                return active ? (
                  <Tooltip title={getTooltipBox(current.format('YYYY-MM-DD'))}>
                    <div
                      className={`box ${isSelected} ${grayed}`}
                      key={`${i + 1}`}
                      onClick={() => {
                        handleDayClick(current);
                      }}
                    >
                      <span className="text">{current?.format('D')}</span>
                    </div>
                  </Tooltip>
                ) : (
                  <div className={`box ${isSelected} ${grayed} `} style={{ cursor: 'not-allowed' }} key={`${i + 1}`}>
                    <span className="text">{current?.format('D')}</span>
                  </div>
                );
              } else {
                return <div className="box disabled">&nbsp;&nbsp;&nbsp;</div>;
              }
            })}
        </Row>,
      );
    }

    return calendar;
  }

  return (
    <Wrapper justify="CENTER" style={{ flexDirection: 'column' }}>
      <CalendarHead justify="CENTER">
        <Icon
          icon="arrowLeft"
          onClick={() => jumpToMonth(-1)}
          style={{ width: '24px', height: '24px', marginRight: '19px', cursor: 'pointer' }}
        />
        <span className="title">{moment(uc_baseDate).format('YYYY.MM')}</span>
        <Icon
          icon="arrowRight"
          onClick={() => jumpToMonth(1)}
          style={{ width: '24px', height: '24px', marginLeft: '19px', cursor: 'pointer' }}
        />
      </CalendarHead>
      <CalendarBody justify="CENTER" style={{ flexDirection: 'column' }}>
        <Row justify="CENTER" style={{ maxHeight: '70px' }}>
          {['일', '월', '화', '수', '목', '금', '토'].map((el) => (
            <div className="box day" key={el}>
              <span className="text">{el}</span>
            </div>
          ))}
        </Row>
        {generate()}
      </CalendarBody>
    </Wrapper>
  );
}
export default Calendar;
