import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { MacScrollbar } from 'mac-scrollbar';
import React, { Dispatch, SetStateAction } from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Label from '@components/form/Label';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { FindPasswordButton, GoLoginButton } from '@components/molecules/FindId/Button';
import { FoundIdBoxRow } from '@components/molecules/FindId/Row';
import { FundIdValueText } from '@components/molecules/FindId/Text';
import { AUTH_FIND_ID_ACTION } from '@constants/actionNames';
import { routePath } from '@constants/path';
import { useReduxData } from '@hooks/useReduxData';
import history from '@utils/history';
import moment from 'moment';

import { TCurrentStep } from '.';

type TFoundIdProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  setIsOpenFindIdModal: any;
  setIsOpenFindPasswordModal: any;
};

function FoundId({
                   setCurrentStep,
                   setIsOpenFindIdModal,
                   setIsOpenFindPasswordModal,
                 }: TFoundIdProps) {
  const { data } = useReduxData(AUTH_FIND_ID_ACTION);

  const goLogin = () => {
    const redirectUri = routePath.login.path;
    setIsOpenFindIdModal();
    setCurrentStep('INIT');
    history.push(redirectUri);
  };

  const goFindPassword = () => {
    const redirectUri = routePath.login.path;
    setIsOpenFindIdModal();
    setCurrentStep('INIT');
    history.push(redirectUri);
    setIsOpenFindPasswordModal();
  };

  return (
    <Layout>
      <HDivider margin='12px' type='TRANSPARENT' />
      {(!data || ! data.length || data.length < 1) ? (
        <Box sx={{height:100, pt:10}}>
          해당 휴대폰번호로 가입된 아이디가 없습니다.
        </Box>
      ):(
      <MacScrollbar style={{
        height: '400px',
        width: '100%',
      }}>



        {data && data.length && data?.map((ele: any, idx: number) => {
          return <>
            <FoundIdBoxRow direction='column' justify='CENTER'>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '70%',
              }}>
                <FundIdValueText>{ele.user_id ?? ''}</FundIdValueText>
                <Text textType='DESCRIPTION'>
                  {ele.created_at ? `(${moment(ele.created_at)
                    .format('YYYY년 MM월 DD일')} 가입)` : ''}
                </Text>
              </div>

            </FoundIdBoxRow>
            <HDivider margin='4px' type='TRANSPARENT' />
          </>;
        })
        }

      </MacScrollbar>)}
      <HDivider margin='15px' type='TRANSPARENT' />
      <Row justify='CENTER'>
        <Text textType='DESCRIPTION'>전체 아이디는 고객센터로 문의해 주세요.</Text>
      </Row>
      <HDivider margin='14px' type='TRANSPARENT' />
      <Row direction='row' justify='SPACE_BETWEEN'>
        <GoLoginButton
          onClick={() => {
            goLogin();
          }}
        >
          로그인 하기
        </GoLoginButton>
        <VDivider margin='5px' type='TRANSPARENT' />
        <FindPasswordButton
          onClick={() => {
            goFindPassword();
          }}
        >
          비밀번호 찾기
        </FindPasswordButton>
      </Row>

    </Layout>
  );
}

export default FoundId;
