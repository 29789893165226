/* eslint-disable no-nested-ternary */
import TTextField from '@components/form/TTextField';
import { BackdropContext } from '@hooks/usePopupMessage';
import { Autocomplete, Avatar, debounce, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ASelect from 'react-select/async';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

// const StyledSelect = styled(ASelect)`
//   &.Select__control {
//     min-height: 56px !important;
//     width: 100%;
//     border: 1px solid #a1a1a1;
//     border-radius: 0;
//     cursor: pointer;
//   }
//
//   &.Select__control:hover {
//     border-color: #a1a1a1;
//   }
//
//   &.Select__control--is-focused {
//     box-shadow: 0 0 0 1px black;
//     outline: none;
//   }
//
//   &.Select__indicator-separator {
//     display: none;
//   }
//
//   &.Select__menu {
//     color: #3c3d3e;
//     min-width:400px;
//   }
// `;
export default function VibeSelect({ ...rest }: any) {
  const {
    ctf_sendHttpGet,
    ctf_sendHttpPost,
  } = useContext(BackdropContext);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [value, setVale] = useState('');
  const searchVibe = async (keyword: any) => {

    const res = await ctf_sendHttpGet('/artists/search', { artist_name: keyword }, false);
    if (res.error) {
      setOptions([]);
    } else {

      setOptions(res.data.artists.artist);
    }

  };

  const fetch = React.useMemo(
    () =>
      debounce(
        (artistName: any) => {
          searchVibe(artistName);

        },
        200,
      ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '' || inputValue.length < 2) {
      setOptions([]);
      //return undefined;
    } else {

      fetch(inputValue);

    }

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  useEffect(()=>{
    if(options && !Array.isArray(options)){
      setOptions([options])
    }

  },[options])

  return (
    <Autocomplete
      id='vive-search'
      {...rest}
      getOptionLabel={(option) => option?.artistName ?? ''}
      filterOptions={(x) => {

        try {
          return x ?? [];
        } catch (e) {

          return [];
        }

      }}



      options={options ?? [{ artistName: 'Loading Now...' }]}
      autoComplete
      includeInputInList
      // filterSelectedOptions
      noOptionsText='No Artist'
      // onChange={(event: any, newValue:any) => {
      //   setOptions(newValue ? [newValue, ...options] : options);
      // }}
      onInputChange={(event, newInputValue) => {

        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TTextField {...params} placeholder={rest.placeholder} fullWidth />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.artistId}>
            <Grid container alignItems='center'>
              <Grid item sx={{
                display: 'flex',
                width: 80,
              }}>
                <Avatar sx={{
                  width: 70,
                  height: 70,
                }} src={option.imageUrl} />
              </Grid>
              <Grid item sx={{
                width: 'calc(100% - 100px)',
                wordWrap: 'break-word',
              }}>
                <Stack>
                  <Typography sx={{ fontSize: 15,fontWeight:'bold'  }} color='text.secondary'>
                    {option.artistName}
                  </Typography>
                  <Typography sx={{ fontSize: 13, paddingLeft:'1px', fontWeight:'bold' }} color='text.secondary'>
                    {typeof option.debutDate === 'string' ? `데뷔일 ${option.debutDate}` : ''}
                  </Typography>
                </Stack>

              </Grid>

            </Grid>

          </li>
        );
      }}
    />
  );
}
