import React, { Dispatch, SetStateAction } from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import { ConfirmButton } from '@components/molecules/FindPassword/Button';
import { Icon } from '@images/Icon';

import { TCurrentStep } from '.';

type TCantFoundIdProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  setIsOpenDormantAccountModal: any;
};

function Completed({ setCurrentStep, setIsOpenDormantAccountModal }: TCantFoundIdProps) {
  const confirm = () => {
    // const redirectUri = routePath.login.path;
    setIsOpenDormantAccountModal();
    setCurrentStep('INIT');
  };

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="19px" type="TRANSPARENT" />
        <Row justify="CENTER">
          <Icon icon="iconComplete" style={{ width: '64px', height: '64px' }} />
        </Row>
        <HDivider margin="14px" type="TRANSPARENT" />
        <Row direction="column" justify="CENTER">
          <Text textType="DESCRIPTION">계정 휴면 상태가 해제되었습니다. </Text>
          <Text textType="DESCRIPTION">mixtape의 모든 서비스를 정상 이용 가능합니다.</Text>
        </Row>
        <HDivider margin="18px" type="TRANSPARENT" />
        <ConfirmButton
          onClick={() => {
            confirm();
          }}
        >
          확인
        </ConfirmButton>
      </Form>
    </Layout>
  );
}

export default Completed;
