import AlertDialog from '@components/form/AlertDialog';
import styled from '@emotion/styled/dist/emotion-styled.cjs';
import { usePopupMessage } from '@hooks/usePopupMessage';
import { Dialog, DialogTitle, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Row from '@components/form/Row';

import { HeaderText } from '@components/molecules/AlbumManagement/FullScreenModal';

import { ALBUM_INFO_REGISTRATION_ACTION, ALBUM_INFO_REGISTRATION_TRACK_INFO_ADD_ACTION } from '@constants/actionNames';

import { useReduxData } from '@hooks/useReduxData';

import TrackEditorBox from './TrackEditorBox';

export const SaveButton = styled.button`
  height: 45px;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  background: #121212;
  color: #ffffff;
  font-family: Pretendard;
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  right: 80px;
  top: 20px;

  &:disabled {
    background: #dedede;
    color: #fff;
    cursor: not-allowed;
  }
`;

const getPrevNextPrevButtonStyle = (isDisabled: boolean): Record<string, any> => {
  if (isDisabled) {
    return {
      TRACKINFO: {
        PREV: {},
        NEXT: {
          width: '128px',
          background: '#DEDEDE',
        },
      },
    };
  }
  return {
    TRACKINFO: {
      PREV: {},
      NEXT: {
        width: '128px',
        background: '#121212',
      },
    },
  };
};

function TrackEditorDialog({
  setIsOpenTrackInfoRegistrationModal,
  isOpenTrackInfoRegistrationModal,
  selectedTrackNumber,
  setTrackFiles,
  trackFiles,
  trackDatum,
}) {
  const [isDisabledNextButton, setIsDisabledNextButton] = useState<boolean>(false);
  const { parameters: albumInfoFormData } = useReduxData(ALBUM_INFO_REGISTRATION_ACTION);
  const {
    parameters: trackParams,
    setParameters: setTrackParams,
    clearData: clearTrack,
  } = useReduxData(ALBUM_INFO_REGISTRATION_TRACK_INFO_ADD_ACTION);
  // 일반 팝업창 팝업용
  const { openState: uc_open, setOpenState: uf_setOpen, message: uc_message, popupMessage: uf_popupMessage } = usePopupMessage();

  const handleClickConfirm = (e: any) => {
    e.preventDefault();

    const fidx = trackDatum.findIndex((trk) => trk.info.track_name == trackParams.track_name && trk.info.id != trackParams.id);

    if (fidx > -1) {
      uf_popupMessage('동일한 트랙명이 있습니다.\n다시 입력하여 주십시오.');
      return;
    }
    setTrackFiles((prev: any) => {
      const result = prev?.map((item: any, index: number) => {
        if (index === selectedTrackNumber) {
          return {
            trackFile: item.trackFile ?? item,
            info: trackParams,
          };
          // return Object.assign(item, {info:trackParams})
        } else {
          return {
            trackFile: item.trackFile ?? item,
            info: item.info ? { ...item.info } : {},
          };
        }
      });
      return result;
    });
    setIsOpenTrackInfoRegistrationModal(false);
  };

  useEffect(() => {
    if (!isOpenTrackInfoRegistrationModal) {
      clearTrack();
    } else if (trackFiles?.[selectedTrackNumber]?.info) {
      setTrackParams({ ...trackFiles?.[selectedTrackNumber]?.info });
    }
  }, [isOpenTrackInfoRegistrationModal]);
  // hide={setIsOpenTrackInfoRegistrationModal}
  // isShown={isOpenTrackInfoRegistrationModal}
  // @ts-ignore
  return (
    <Dialog
      open={isOpenTrackInfoRegistrationModal}
      fullWidth={true}
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px',
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="center" p={2}>
          <HeaderText style={{ color: '#121212' }}>
            <Row justify="FLEX_START">{`${selectedTrackNumber + 1 ?? ''}번 트랙 상세정보 입력`}</Row>
          </HeaderText>

          <IconButton
            onClick={(e: any) => {
              e.preventDefault();
              setIsOpenTrackInfoRegistrationModal();
            }}
            sx={{
              backgroud: '#121212',
              position: 'absolute',
              right: 25,
              top: 22,
              color: '#121212',
            }}
          >
            <CloseIcon />
          </IconButton>
          <SaveButton
            disabled={isDisabledNextButton}
            onClick={handleClickConfirm}
            style={getPrevNextPrevButtonStyle(isDisabledNextButton).TRACKINFO.NEXT}
          >
            저장
          </SaveButton>
        </Stack>
      </DialogTitle>

      <TrackEditorBox
        selectedTrackNumber={selectedTrackNumber}
        setIsDisabledNextButton={setIsDisabledNextButton}
        trackFiles={trackFiles}
        trackDatum={trackDatum}
        albumInfoFormData={albumInfoFormData}
      />
      <AlertDialog isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />
    </Dialog>
  );
}

export default TrackEditorDialog;
