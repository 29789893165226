import styled from '@emotion/styled';
import React from 'react';

const ButtonStyle = styled.button<{ buttonProps: IMixButtonPros }>`
  ${(props) => {
    let buttonTypeStyle = null;
    switch (props.buttonProps.butonType) {
      case 'OUTLINE':
        buttonTypeStyle = {
          border: `1px solid ${props.buttonProps.borderColor || '#000000'}`,
        };
        break;
      case 'TEXT':
        buttonTypeStyle = {
          padding: 0,
          backgroundColor: 'transparent',
          border: 'none',
        };
        break;
      default:
        buttonTypeStyle = {
          border: 'none',
        };
    }

    return {
      fontFamily: 'Pretendard',
      padding: props.buttonProps.padding || '20px 40px',
      borderRadius: props.buttonProps.borderRadius || '10px',
      fontSize: props.buttonProps.fontSize || '18px',
      fontWeight: props.buttonProps.fontWeight || 300,
      backgroundColor: props.buttonProps.bgColor || 'black',
      color: props.buttonProps.fontColor || 'white',
      ...buttonTypeStyle,
      ...props.buttonProps.style,
    };
  }}
`;

interface IMixButtonPros {
  text: string;
  onClick: () => void;
  butonType?: 'NORMAL' | 'OUTLINE' | 'TEXT';
  padding?: string;
  bgColor?: string;
  borderRadius?: string;
  borderColor?: string;
  fontSize?: string;
  fontWeight?: number;
  fontColor?: string;
  style?: any;
  disabled?: boolean;
}

// TODO: 프리셋 만들기
const MixButton = (props: IMixButtonPros) => {
  return (
    <ButtonStyle disabled={props?.disabled ||  false} buttonProps={props} onClick={props.onClick}>
      {props.text}
    </ButtonStyle>
  );
};

export default MixButton;
