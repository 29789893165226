import React, { useRef } from 'react';
import { Swiper } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import { Autoplay, Pagination } from 'swiper';

interface IMixSwiperProps {
  slidesPerView: number | 'auto';
  centeredSlides: boolean;
  spaceBetween?: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
  isDarkMode?: boolean;
  onSlideChange?: (idx: number) => void;
}

const MixSwiper = ({
  slidesPerView,
  centeredSlides,
  spaceBetween,
  style,
  children,
  isDarkMode,
  onSlideChange,
}: IMixSwiperProps) => {
  const swiperRef = useRef(null);

  const handleSlideChange = () => {
    if (onSlideChange) {
      onSlideChange(swiperRef.current.swiper.realIndex);
    }
  };

  return (
    <>
      <Swiper
        ref={swiperRef}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween || 30}
        centeredSlides={centeredSlides}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        style={style}
        className={isDarkMode ? 'swiper-pagination-color-dark' : ''}
        onSlideChange={handleSlideChange}
      >
        {children}
      </Swiper>
    </>
  );
};

export default MixSwiper;
