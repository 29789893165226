import Button from '@components/form/Button';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';

// export const SelectUserTypeButton = styled(Button)`
//   height: 52px;
//   padding: 17px 32px 16px 32px;
//   min-width: 190px;
//   border-radius: 10px;
//   border: none;
//   cursor: pointer;
//   background: #121212;
//   color: #ffffff;
//   font-family: Pretendard;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 19px;
// `;

export const SelectUserTypeButton = styled(Stack)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  padding: 8px;
  min-width: 180px;
  border-radius: 8px;
  border: none;
  background: #effd60;
  cursor: pointer;
  box-shadow: 0px 8px 6px 0px rgba(35, 35, 35, 0.2);

  &:hover {
    background-color: #f6fdac;
  }
  /* color: #191919; */
  /* font-weight: 700; */
  /* font-size: 16px; */
  /* line-height: 19px; */
`;

export const NextButton = styled(Button)`
  height: 64px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: #121212;
  color: #ffffff;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

export const ResendAuthNumberButton = styled(Button)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: #5b5b5b;
  height: 0;
  background: none;
  padding: 0;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const GetAuthNumberButton = styled(Button)`
  height: 50px;
  border-radius: 10px;
  padding: 17px 26px 16px;
  background: #121212;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;

  &:disabled {
    background: #fff;
    color: #999;
    border: 1px solid #dedede;
    cursor: not-allowed;
  }
`;

export const FinishSignUpButton = styled(Button)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-decoration-line: underline;
  color: #5b5b5b;
  height: 0;
  background: none;
  padding: 0;
  line-height: 0;
`;

export const SignUpMembershipButton = styled(Button)`
  width: 400px;
  height: 64px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: #121212;
  color: #ffffff;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

export const PrevButton = styled(Button)`
  height: 64px;
  border-radius: 10px;
  border-color: #bbb;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  background: #fff;
  color: #bbb;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;
