import Button from '@components/form/Button';
import styled from '@emotion/styled';

export const DownloadDetailListButton = styled(Button)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: right;

  /* color/primary/lemon */

  color: #effd60;
  height: 0;
  padding: 0;
  background: transparent;
  &:disabled {
    color: #5b5b5b;
  }
`;
