import getActionTypes from './getActionTypes';

export default function getActionCreators(reduxActionName: string): any {
  const {
    SET_PARAMETERS,
    SET_REQ_PARAMETERS,
    SET_DATA,
    REQUEST_READY,
    REQUEST_START,
    REQUEST_SUCCEEDED,
    REQUEST_FAILED,
    CLEAR_DATA,
  } = getActionTypes(reduxActionName);

  return {
    setParameters: (parameters: any) => ({
      type: SET_PARAMETERS,
      parameters,
    }),

    setReqParameters: (reqParameters: any) => ({
      type: SET_REQ_PARAMETERS,
      reqParameters,
    }),

    setData: (data: any) => ({
      type: SET_DATA,
      data,
    }),

    requestReady: () => ({
      type: REQUEST_READY,
    }),

    requestStart: () => ({
      type: REQUEST_START,
    }),

    requestSuccess: (response: any) => ({
      type: REQUEST_SUCCEEDED,
      ...response,
    }),

    requestFail: (response: any) => ({
      type: REQUEST_FAILED,
      ...response,
    }),

    clearData: () => ({
      type: CLEAR_DATA,
    }),
  };
}
