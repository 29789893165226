/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import Button from '@components/form/Button';
import Row from '@components/form/Row';
import { Icon } from '@images/Icon';

import VDivider from './VDivider';

type TImageFileProps = {
  getRootProps: any;
  getInputProps: any;
  description: any;
  fileList: Array<any>;
  isExistDescription?: any;
  setCurrentIndex: any;
  deleteFile: any;
};

function MultipleImagFile({
                            setCurrentIndex,
                            getRootProps,
                            getInputProps,
                            description,
                            isExistDescription,
                            fileList,
                            deleteFile,
                          }: TImageFileProps) {
  const [isMouseEnter1, setIsMouseEnter1] = useState(false);
  const [isMouseEnter2, setIsMouseEnter2] = useState(false);
  const [isMouseEnter3, setIsMouseEnter3] = useState(false);
  const [isMouseEnter4, setIsMouseEnter4] = useState(false);

  if (fileList?.length) {
    return (
      <Row direction='column' justify='FLEX_START'>
        <Row align='flex-start' direction='row' justify='FLEX_START'>
          <Row
            justify='FLEX_START'
            onMouseEnter={() => {
              setIsMouseEnter1(true);
              setCurrentIndex(0);
            }}
            onMouseLeave={() => {
              setIsMouseEnter1(false);
              setCurrentIndex(null);
            }}
            style={{ flex: 0.5 }}
          >
            <img
              alt='preview'
              onDragEnter={(e) => {

                e.stopPropagation();
                setIsMouseEnter1(true);
                setCurrentIndex(0);
              }
              }
              src={fileList[0]?.preview}
              style={{
                borderRadius: '10px',
                width: '150px',
                height: '150px',
              }}
            />
            {isMouseEnter1 && (
              <Row
                align='center'
                justify='CENTER'
                {...getRootProps({ className: 'dropzone' })}
                style={{
                  background: 'rgba(0, 0, 0, 0.6)',
                  border: '1px dashed #DDDDDD',
                  borderRadius: '10px',
                  color: '#DDDDDD !important',
                  fontSize: '14px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  width: '150px',
                  height: '150px',
                  position: 'absolute',
                  right: '',
                }}
              >
                <input {...getInputProps()} />
                {isExistDescription || description}
              </Row>
            )}
            <Row
              justify='CENTER'
              onClick={(e: any) => {
                deleteFile(0);
              }}
              style={{
                position: 'relative',
                right: '10px',
                bottom: '10px',
                cursor: 'pointer',
              }}
            >
              <Icon icon='btnCloseFile' style={{
                width: '20px',
                height: '20px',
              }} />
            </Row>
          </Row>
          {fileList[1] ? (
            <Row
              justify='FLEX_START'
              onMouseEnter={() => {
                setIsMouseEnter2(true);
                setCurrentIndex(1);
              }}
              onMouseLeave={() => {
                setIsMouseEnter2(false);
                setCurrentIndex(null);
              }}
              style={{ flex: 0.5 }}
            >
              <img
                alt='preview'
                onDragEnter={(e) => {

                  e.stopPropagation();
                  setIsMouseEnter2(true);
                  setCurrentIndex(1);
                }
                }
                src={fileList[1]?.preview}
                style={{
                  borderRadius: '10px',
                  width: '150px',
                  height: '150px',
                }}
              />
              {isMouseEnter2 && (
                <Row
                  align='center'
                  justify='CENTER'
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    background: 'rgba(0, 0, 0, 0.6)',
                    border: '1px dashed #DDDDDD',
                    borderRadius: '10px',
                    color: '#DDDDDD !important',
                    fontSize: '14px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    width: '150px',
                    height: '150px',
                    position: 'absolute',
                    right: '',
                  }}
                >
                  <input {...getInputProps()} />
                  {isExistDescription || description}
                </Row>
              )}
              <Row
                justify='CENTER'
                onClick={(e: any) => {
                  deleteFile(1);
                }}
                style={{
                  position: 'relative',
                  right: '10px',
                  bottom: '10px',
                  cursor: 'pointer',
                }}
              >
                <Icon icon='btnCloseFile' style={{
                  width: '20px',
                  height: '20px',
                }} />
              </Row>
            </Row>
          ) : (
            <Row
              align='center'
              justify='CENTER'
              {...getRootProps({ className: 'dropzone' })}
              style={{
                border: '1px dashed #DDDDDD',
                borderRadius: '10px',
                color: '#777',
                fontSize: '14px',
                textAlign: 'center',
                cursor: 'pointer',
                width: '150px',
                height: '150px',
              }}
            >
              <input {...getInputProps()} />
              {isExistDescription}
            </Row>
          )}
        </Row>
        <Row align='flex-start' direction='row' justify='FLEX_START'>
          {fileList[2] ? (
            <Row
              justify='FLEX_START'
              onMouseEnter={() => {
                setIsMouseEnter3(true);
                setCurrentIndex(2);
              }}
              onMouseLeave={() => {
                setIsMouseEnter3(false);
                setCurrentIndex(null);
              }}
              style={{ flex: 0.5 }}
            >
              <img
                alt='preview'
                onDragEnter={(e) => {

                  e.stopPropagation();
                  setIsMouseEnter3(true);
                  setCurrentIndex(2);
                }
                }
                src={fileList[2]?.preview}
                style={{
                  borderRadius: '10px',
                  width: '150px',
                  height: '150px',
                  marginTop: '20px',
                }}
              />
              {isMouseEnter3 && (
                <Row
                  align='center'
                  justify='CENTER'
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    background: 'rgba(0, 0, 0, 0.6)',
                    border: '1px dashed #DDDDDD',
                    borderRadius: '10px',
                    color: '#DDDDDD !important',
                    fontSize: '14px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    width: '150px',
                    height: '150px',
                    position: 'absolute',
                    marginTop: '20px',
                    right: '',
                  }}
                >
                  <input {...getInputProps()} />
                  {isExistDescription || description}
                </Row>
              )}
              <Row
                justify='CENTER'
                onClick={(e: any) => {
                  deleteFile(2);
                }}
                style={{
                  position: 'relative',
                  right: '10px',
                  top: '10px',
                  cursor: 'pointer',
                }}
              >
                <Icon icon='btnCloseFile' style={{
                  width: '20px',
                  height: '20px',
                }} />
              </Row>
            </Row>
          ) : fileList[1] ? (
            <Row
              align='center'
              justify='CENTER'
              {...getRootProps({ className: 'dropzone' })}
              style={{
                border: '1px dashed #DDDDDD',
                borderRadius: '10px',
                color: '#777',
                fontSize: '14px',
                textAlign: 'center',
                cursor: 'pointer',
                width: '150px',
                height: '150px',
                marginTop: '20px',
              }}
            >
              <input {...getInputProps()} />
              {isExistDescription}
            </Row>
          ) : (
            <Row justify='FLEX_START' />
          )}
          {fileList[3] ? (
            <Row
              justify='FLEX_START'
              onMouseEnter={() => {
                setIsMouseEnter4(true);
                setCurrentIndex(3);
              }}
              onMouseLeave={() => {
                setIsMouseEnter4(false);
                setCurrentIndex(null);
              }}
              style={{ flex: 0.5 }}
            >
              <img
                alt='preview'
                // onLoad={() => {
                //   URL.revokeObjectURL(fileList[3]?.preview);
                // }}
                onDragEnter={(e) => {

                  e.stopPropagation();
                  setIsMouseEnter4(true);
                  setCurrentIndex(3);
                }
                }
                src={fileList[3]?.preview}
                style={{
                  borderRadius: '10px',
                  width: '150px',
                  height: '150px',
                  marginTop: '20px',
                }}
              />
              {isMouseEnter4 && (
                <Row
                  align='center'
                  justify='CENTER'
                  {...getRootProps({ className: 'dropzone' })}
                  style={{
                    background: 'rgba(0, 0, 0, 0.6)',
                    border: '1px dashed #DDDDDD',
                    borderRadius: '10px',
                    color: '#DDDDDD !important',
                    fontSize: '14px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    width: '150px',
                    height: '150px',
                    position: 'absolute',
                    marginTop: '20px',

                    right: '',
                  }}
                >
                  <input {...getInputProps()} />
                  {isExistDescription || description}
                </Row>
              )}
              <Row
                justify='CENTER'
                onClick={(e: any) => {
                  deleteFile(3);
                }}
                style={{
                  position: 'relative',
                  right: '10px',
                  top: '10px',
                  cursor: 'pointer',
                }}
              >
                <Icon icon='btnCloseFile' style={{
                  width: '20px',
                  height: '20px',
                }} />
              </Row>
            </Row>
          ) : fileList[2] ? (
            <Row
              align='center'
              justify='CENTER'
              {...getRootProps({ className: 'dropzone' })}
              style={{
                border: '1px dashed #DDDDDD',
                borderRadius: '10px',
                color: '#777',
                fontSize: '14px',
                textAlign: 'center',
                cursor: 'pointer',
                width: '150px',
                height: '150px',
                marginTop: '20px',
              }}
            >
              <input {...getInputProps()} />
              {isExistDescription}
            </Row>
          ) : null}
        </Row>
      </Row>
    );
  }
  return (
    <Row
      align='center'
      justify='CENTER'
      {...getRootProps({ className: 'dropzone' })}
      style={{
        background: 'transparent',
        border: '1px dashed #DDDDDD',
        borderRadius: '10px',
        color: '#777',
        fontSize: '14px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '320px',
      }}
    >
      <input {...getInputProps()} />
      {description}
    </Row>
  );
}

export default MultipleImagFile;
