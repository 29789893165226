import React, { ReactElement } from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

type THandleCheckHDividerTypeParams = 'TRANSPARENT' | 'CONFIRMED' | 'UNCONFIRMED' | 'USERINFO_SETTING' | undefined;

type TDividerParams = {
  type?: THandleCheckHDividerTypeParams;
  margin?: string;
  style?: Record<string, any>;
};

const handleCheckHDividerType = (type: THandleCheckHDividerTypeParams) => {
  switch (type) {
    case 'TRANSPARENT':
      return css`
        border: 0;
        border-color: transparent !important;
        background-color: transparent !important;
      `;
    case 'CONFIRMED':
      return css`
        border: 0;
        border-color: #effd60;
        background: #effd60;
        width: 180px;
        border-radius: 2px;
        height: 4px;
      `;
    case 'UNCONFIRMED':
      return css`
        border-color: #777;
        background: #777;
        border-radius: 2px;
        width:180px;
        //width: 218px;
      `;
    case 'USERINFO_SETTING':
      return css`
        border: 0;
        background: #3b3b3b;
      `;
    default:
      return css`
        background: #121212;
      `;
  }
};

const StyledHDivider = styled.div<TDividerParams>`
  height: 1px;
  ${({ type }) => handleCheckHDividerType(type)}
  ${({ margin }) =>
    margin &&
    css`
      margin-top: ${margin} !important;
      margin-bottom: ${margin} !important;
    `}
`;

const HDivider = ({ ...rest }: TDividerParams): ReactElement => <StyledHDivider {...rest} />;

export default HDivider;
