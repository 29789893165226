import React from 'react';

import styled from '@emotion/styled';

export type TInputParams = any;

const StyledInputBox = styled.div`
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  overflow: hidden;
  padding: 0 !important;

  div {
    line-height: 15px;
    padding-right: 15px;
  }

  .time {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: right;

    /* color/error */

    color: #ff6465;
  }

  .time_enable {
    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: right;

    /* color/error */

    color: #121212;
  }
`;

const StyledInput = styled.input<TInputParams>`
  flex-grow: 1;
  height: 15px !important;
  padding: 17px 20px 16px 20px;
  border: none;
  outline: none;

  &:hover {
    outline: 0;
    box-shadow: none;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &:disabled {
    border-color: rgba(0, 0, 0, 0.32);
    color: #363c50;
  }
`;

const Input = ({ suffix, ...rest }: TInputParams) => (
  <StyledInputBox>
    <StyledInput
      {...rest}
      maxLength={100}
      onKeyPress={(e: any) => {
        // if (e.key === 'Enter') e.preventDefault();
      }}
    />
    {suffix}
  </StyledInputBox>
);

export default Input;
