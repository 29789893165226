import { BackdropContext } from '@hooks/usePopupMessage';
import React, { useContext, useEffect, useState } from 'react';
import { usePagination, useRowSelect, useTable } from 'react-table';

import HDivider from '@components/form/HDivider';
import Row from '@components/form/Row';
import { DownloadDetailListButton } from '@components/molecules/SettlementManagment/Button';
import styled from '@emotion/styled';
import { Icon } from '@images/Icon';
import xlsx from 'json-as-xlsx';

const CheckBox = styled.label<{ checked: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;

  & > svg {
    position: absolute;
  }
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Styles = styled.div`
  width: 100%;

  table {
    border-spacing: 0;
    width: 100%;
    background: transparent;

    tr th {
      &:first-of-type {
        padding-left: 20px;
        text-align: left;
      }

      &:nth-of-type(2) {
        text-align: center;
        width: 20%;
      }

      &:last-child {
        text-align: left;
        width: 80%;
      }

      input {
        background: #3b3b3b;
        border-radius: 4px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }

    th {
      background: transparent;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      border-bottom: 12px solid #1e1e1e;
      text-align: center;
      color: #777777;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-bottom: 12px;
      padding-top: 5px;
    }

    tr td {
      &:first-of-type {
        border-radius: 10px 0 0 10px;
        line-height: 20px;
        padding-top: 25px;
        padding-left: 20px;
      }

      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }

    td {
      background: #222222;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #e8e8e8;
      flex: none;
      order: 0;
      flex-grow: 0;
      padding-top: 20px;

      padding-bottom: 20px;
    }
  }
`;

export type TTableProps = {
  columns: Array<any>;
  data: Array<any>;
};

///
/// 체크버튼 객체
///
const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = (ref || defaultRef) as any;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <CheckBox checked={rest.checked}>
      <HiddenCheckbox checked={rest.checked} onChange={rest.onChange} ref={resolvedRef} type="checkbox" {...rest} />
      <Icon
        icon={rest.checked ? 'selectedCheckRec' : 'unselectedCheckRec'}
        style={{
          width: '20px',
          height: '20px',
        }}
      />
    </CheckBox>
  );
});

const SelectableTable = ({ columns, data }: TTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows }: any = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((column: any) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: any) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...column,
      ]);
    },
  );

  const { ctf_sendHttpGet, ctf_sendHttpPost } = useContext(BackdropContext);

  const [uc_buttonDisable, uf_setButtonDisable] = useState(true);
  useEffect(() => {
    uf_setButtonDisable(!selectedFlatRows || selectedFlatRows.length == 0);
  }, [selectedFlatRows]);

  const saveAsExcel = async () => {
    let excelData = [];
    selectedFlatRows.forEach((ele) => {
      const subData = ele.original.albums.map((al) => {
        return {
          month: ele.original.date,
          album_name: al.album_name,
          revenue: al.revenue,
        };
      });

      excelData = excelData.concat(subData);
    });

    const detailData = await callMoneyDetailData();

    const data = [
      {
        sheet: '앨범정산',
        columns: [
          { label: '연-월', value: 'month' },
          { label: '앨범명', value: 'album_name' },
          { label: '정산금액', value: 'revenue', format: '₩#,##' }, // Run functions
        ],
        content: excelData,
      },
      {
        sheet: '정산상세내역',

        columns: [
          { label: '정산월', value: 'adate' },
          { label: '판매월', value: 'sdate' },
          { label: '유통사', value: 'prod_name' },
          { label: 'CP코드', value: 'cp_cd' }, // Run functions
          { label: 'CP명', value: 'cp_nm' },
          { label: '플랫폼코드', value: 'cp_platform_cd' },
          { label: '플랫폼명', value: 'cp_platform_nm' },
          { label: '플랫폼상세', value: 'cp_platform_detail' },
          { label: '국가코드', value: 'territory' },
          { label: '서비스코드', value: 'poc_goods_code' },
          { label: '서비스대분류', value: 'poc_goods_classify1' },
          { label: '서비스중분류', value: 'poc_goods_classify2' },
          { label: '서비스명', value: 'poc_goods_nm' },
          { label: '아티스트', value: 'artist_nm' },
          { label: '앨범명', value: 'albm_nm' },
          { label: '곡명', value: 'track_nm' },
          { label: '앨범정산코드', value: 'albm_cd' },
          { label: '트랙정산코드', value: 'track_cd' },
          { label: 'UPC', value: 'poc_upc' },
          { label: 'ISRC', value: 'poc_isrc' },
          { label: '히트수', value: 'poc_cnt' },
          { label: 'POC매출액', value: 'krw_poc_price' },
          { label: '저작권료', value: 'copyright_fee' },
          { label: '실연권료', value: 'performance_fee_ygp' },
          { label: '저작인접권료', value: 'poc_nbr' },
          { label: '유통수수료', value: 'dist_fee' },
          { label: '정산금', value: 'agency_fee' },
        ],
        content: detailData,
      },
    ];

    xlsx(data, {
      fileName: '믹스테이프 정산상세내역',
      extraLength: 10,
    });
  };

  const callMoneyDetailData = async () => {
    const months = selectedFlatRows.map((ele) => ele.original.date);
    const res = await ctf_sendHttpGet('/settlement-details', { adate: months.join(',') });
    return res.data;
  };

  return (
    <Styles>
      {rows.length > 0 ? (
        <>
          <Row
            justify="FLEX_END"
            style={{
              right: 20,
              alignItems: 'center',
              top: '7px',
              position: 'relative',
            }}
          >
            <DownloadDetailListButton disabled={uc_buttonDisable} onClick={saveAsExcel}>
              상세 내역 다운로드
            </DownloadDetailListButton>
          </Row>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((header: any, idx: number) => (
                <tr {...header.getHeaderGroupProps()} key={idx}>
                  {header.headers.map((col: any, hIdx: number) => {
                    return (
                      <th key={hIdx} {...col.getHeaderProps()}>
                        {col.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, idx: number) => {
                prepareRow(row);
                return (
                  <>
                    <tr key={idx} {...row.getRowProps()}>
                      {row.cells.map((cell: any, cIdx: number) => (
                        <td key={cIdx} {...cell.getCellProps()} style={cell.column.style ?? {}}>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                    <HDivider margin="10px" type="TRANSPARENT" />
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <Row
          direction="column"
          style={{
            background: '#1e1e1e',
            padding: 0,
            color: '#777',
            width: '100%',
          }}
        >
          <HDivider margin="50px" type="TRANSPARENT" />
          정산된 내역이 없습니다.
          <HDivider margin="60px" type="TRANSPARENT" />
        </Row>
      )}
    </Styles>
  );
};

export default SelectableTable;
