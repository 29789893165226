import AlertModal from '@components/form/AlertModal';
import AlbumStepper from '@components/molecules/AlbumManagement/AlbumStepper';
import { ALBUM_INFO_REGISTRATION_ACTION } from '@constants/actionNames';
import { BlueOnGreenTooltip, DarkTooltip } from '@containers/AlbumManagement/AlbumInfoDetailModal/StyledTooltip';
import AlbumInfoRegistrationModal from '@containers/AlbumManagement/AlbumInfoRegistrationModal';
import { AlbumContext } from '@containers/App';
import { useReduxData } from '@hooks/useReduxData';
import { ImageList, ImageListItem, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { sendHttpGet } from '@utils/api';

import date from 'date-and-time';
import { isEmpty } from 'lodash';

import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import HDivider from '@components/form/HDivider';
import Row from '@components/form/Row';
import VDivider from '@components/form/VDivider';
import { AlbumDetailActionButton } from '@components/molecules/AlbumManagement/Button';
import FullScreenModal, {
	CloseButton,
	Content,
	HeaderText
} from '@components/molecules/AlbumManagement/FullScreenModal';
import { AlbumDetailLayout } from '@components/molecules/AlbumManagement/Layout';
import { AlbumDetailBadge, AlbumDetailTrackBox } from '@components/molecules/AlbumManagement/Row';
import {
	AlbumDetailDateText,
	AlbumDetailLabelText,
	AlbumDetailLyricsText,
	AlbumDetailTrackTitle,
	AlbumDetailValueText
} from '@components/molecules/AlbumManagement/Text';
import AddMusicVideoModal from '@containers/AlbumManagement/AddMusicVideoModal';
import { useModal } from '@hooks/useModal';
import { usePopupMessage } from '@hooks/usePopupMessage';
import { Icon } from '@images/Icon';
import { MacScrollbar } from 'mac-scrollbar';

function AlbumInfoDetailModal({ setIsOpenAlbumInfoDetailModal, isOpenAlbumInfoDetailModal, albumnId }) {
	const { isShown: isOpenAddMusicVedioModal, toggle: setIsOpenAddMusicVedioModal } = useModal();

	// 앨범수정창 팝업용
	const [uc_albumModiOpen, uf_setAlbumModiOpen] = useState(false);

	// 일반 팝업창 팝업용
	const {
		openState: uc_open,
		setOpenState: uf_setOpen,
		message: uc_message,
		popupMessage: uf_popupMessage
	} = usePopupMessage();

	// 앨범정보
	const [uc_record, uf_setRecord] = useState({});

	// 트랙정보
	const [uc_tracks, uf_setTracks] = useState([]);
	const addMusicVideo = () => {
		setIsOpenAddMusicVedioModal();
	};

	// 정보수정관련
	const [uc_modiBtnEnabled, uf_setModiBtnEnabled] = useState(false);
	useEffect(() => {
		// 데이터가 바뀌면 정보수정 버튼을 수정할 수 있는지 확인한다.
		const step = uc_record?.state;

		if (
			!moment().isBefore(moment(uc_record.release_date).subtract(3, 'days')) ||
			step === 'approve' ||
			step === 'release'
		) {
			uf_setModiBtnEnabled(false);
		} else {
			uf_setModiBtnEnabled(true);
		}
	}, [uc_record]);

	async function searchAlbumInfo() {
		const res = await sendHttpGet(`/album/${albumnId}`, {});
		if (res.error) {
			uf_popupMessage('앨범 트랙 조회를 실패하였습니다.');

			setIsOpenAlbumInfoDetailModal(false);
			return;
		}

		uf_setRecord(res.data[0]);
	}

	//컨텍스트 호출
	const { ctc_albumInfo, ctf_setAlbumInfo } = useContext(AlbumContext);

	async function searchTracks() {
		const res = await sendHttpGet(`/albums/${albumnId}/tracks`, {});
		if (res.error) {
			uf_popupMessage('앨범 트랙 조회를 실패하였습니다.');
			setIsOpenAlbumInfoDetailModal(false);
			return;
		}

		uf_setTracks(res.data);
	}

	function refreshDetail() {
		searchAlbumInfo();
		searchTracks();
	}

	useEffect(() => {
		if (albumnId) {
			searchAlbumInfo();
			searchTracks();
		}
		return () => {
			uf_setRecord({});
			uf_setTracks([]);
		};
	}, [albumnId]);

	useEffect(() => {
		if (ctc_albumInfo.refreshDetail === true) {
			searchAlbumInfo();
			searchTracks();
			ctf_setAlbumInfo({});
		}
	}, [ctc_albumInfo]);

	function modifyAlbumInfo() {
		ctf_setAlbumInfo({ editing: true });
		uf_setAlbumModiOpen(true);
	}

	const getReleaseDateTxt = (dateTxt: any, timeTxt: any) => {
		const dDay = new Date(dateTxt);

		const txt = date.format(dDay, 'YYYY.MM.DD hA ([KST])');
		return txt;
	};

	return (
		<FullScreenModal
			header={
				<>
					<HeaderText>
						<Row className="album_type" justify="CENTER">
							{uc_record?.type_name}
						</Row>
						<Row justify="CENTER"> {uc_record?.album_name}</Row>
					</HeaderText>
					<CloseButton
						onClick={(e: any) => {
							e.preventDefault();
							setIsOpenAlbumInfoDetailModal(false);
						}}
					>
						<Icon
							icon="btnClose"
							style={{
								width: '20px',
								height: '20px'
							}}
						/>
					</CloseButton>
				</>
			}
			hide={() => {
				setIsOpenAlbumInfoDetailModal(false);
			}}
			isShown={isOpenAlbumInfoDetailModal}
		>
			<Content>
				<AlbumDetailLayout>
					<Row direction="column" justify="FLEX_START" style={{ flex: 0.3 }}>
						<AlbumDetailLabelText>발매(예정)일</AlbumDetailLabelText>
						<HDivider margin="12px" type="TRANSPARENT" />
						<AlbumDetailDateText>
							{' '}
							{uc_record.release_date ? (
								getReleaseDateTxt(uc_record.release_date, uc_record.release_time)
							) : (
								<Skeleton variant="rounded" sx={{ bgcolor: '#444' }} width={250} height={30} />
							)}
						</AlbumDetailDateText>
						<HDivider margin="28px" type="TRANSPARENT" />
						{uc_record.cover ? (
							<Icon
								url={uc_record.cover && uc_record.cover.length > 0 ? uc_record.cover[0].image_url : null}
								style={{
									width: '320px',
									height: '320px',
									borderRadius: '10px'
								}}
							/>
						) : (
							<Skeleton variant="rounded" sx={{ bgcolor: '#444' }} width={320} height={320} />
						)}
						<HDivider margin="15px" type="TRANSPARENT" />
						<Row
							direction="column"
							justify="FLEX_START"
							style={{
								alignItems: 'flex-start',
								textAlign: 'left',
								width: '100%'
							}}
						>
							<Row direction="row" justify="SPACE_BETWEEN" style={{ width: '100%' }}>
								<AlbumDetailLabelText>아티스트</AlbumDetailLabelText>
								<AlbumDetailValueText>
									{uc_record?.artists?.length > 0
										? uc_record.artists.reduce((a, c) => a + ', ' + c.artist_name, '').replace(/^,/, '')
										: ''}
								</AlbumDetailValueText>
							</Row>
							<HDivider margin="8px" type="TRANSPARENT" />
							<Row direction="row" justify="SPACE_BETWEEN" style={{ width: '100%' }}>
								<AlbumDetailLabelText>장르</AlbumDetailLabelText>
								<AlbumDetailValueText>
									{uc_record?.genres?.length > 0
										? uc_record.genres.reduce((a, c) => a + ', ' + c.genre_name, '').replace(/^,/, '')
										: ''}
								</AlbumDetailValueText>
							</Row>
							<HDivider margin="8px" type="TRANSPARENT" />
							<Row direction="row" justify="SPACE_BETWEEN" style={{ width: '100%' }}>
								<AlbumDetailLabelText>UPC</AlbumDetailLabelText>
								<AlbumDetailValueText>{uc_record.upc}</AlbumDetailValueText>
							</Row>
							<HDivider margin="8px" type="TRANSPARENT" />
							{uc_record?.album_id && (
								<>
									<Row direction="row" justify="SPACE_BETWEEN" style={{ width: '100%' }}>
										<AlbumDetailLabelText>앨범 ID</AlbumDetailLabelText>
										<AlbumDetailValueText>{uc_record.album_id}</AlbumDetailValueText>
									</Row>
									<HDivider margin="8px" type="TRANSPARENT" />
								</>
							)}

							<Row direction="row" justify="SPACE_BETWEEN" style={{ width: '100%' }}>
								<AlbumDetailLabelText>유통범위</AlbumDetailLabelText>
								<AlbumDetailValueText>국내, 해외</AlbumDetailValueText>
							</Row>
						</Row>

						{/* 앨범설명 */}
						{uc_record.description && (
							<Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between" alignItems="start" pt={1.8}>
								<AlbumDetailLabelText>앨범소개</AlbumDetailLabelText>

								<MacScrollbar style={{ width: '240px' }}>
									<div
										style={{
											fontSize: '14px',
											maxHeight: '150px',
											wordBreak: 'break-all'
										}}
										dangerouslySetInnerHTML={{ __html: uc_record.description.replace(/\n/g, '<br />') }}
									/>
								</MacScrollbar>
							</Stack>
						)}
						{/* 부클릿 */}
						{uc_record.booklet && uc_record.booklet.length > 1 && (
							<Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between" pt={1.8} spacing={2}>
								<AlbumDetailLabelText>부클릿</AlbumDetailLabelText>
								<ImageList sx={{ width: '100%' }} cols={2} sx={{ alignItems: 'right' }} gap={10}>
									{uc_record.booklet.map((item) => (
										<ImageListItem key={item.id}>
											<img
												src={item.image_url}
												style={{
													width: '115px',
													borderRadius: '10px'
												}}
												loading="lazy"
											/>
										</ImageListItem>
									))}
								</ImageList>
							</Stack>
						)}

						{/* 수정요청 */}
						<Stack pt={3} sx={{ width: '100%' }} spacing={3}>
							<DarkTooltip title={uc_modiBtnEnabled ? '' : '앨범 발매 3일 전까지 앨범정보 수정 신청이 가능합니다.'}>
								<span>
									<AlbumDetailActionButton disabled={!uc_modiBtnEnabled} onClick={modifyAlbumInfo}>
										정보 수정 요청
									</AlbumDetailActionButton>
								</span>
							</DarkTooltip>
							<AlbumDetailActionButton disabled={!uc_modiBtnEnabled} onClick={addMusicVideo}>
								뮤직비디오 추가 제출
							</AlbumDetailActionButton>
						</Stack>
					</Row>
					<VDivider margin="60px" type="TRANSPARENT" />
					<Row direction="column" justify="FLEX_END" style={{ flex: 0.7 }} style={{ alignItems: 'flex-end' }}>
						<AlbumStepper record={uc_record} />
						<HDivider margin="50px" type="TRANSPARENT" />

						{/* 트랙리스트 */}
						{(!uc_tracks.length || uc_tracks.length === 0) && (
							<Skeleton variant="rounded" sx={{ bgcolor: '#444' }} width={800} height={480} />
						)}
						{uc_tracks.map((ele, idx) => {
							return (
								<>
									<AlbumDetailTrackBox
										direction="column"
										justify="FLEX_START"
										style={{
											width: '800px',
											background: '#222222',
											borderRadius: '10px'
										}}
									>
										<Row direction="column" justify="FLEX_START" style={{ width: '100%' }}>
											<Row direction="row" justify="FLEX_START" style={{ alignItems: 'center' }}>
												<AlbumDetailTrackTitle>{String(idx + 1).padStart(2, '0')}</AlbumDetailTrackTitle>
												<VDivider margin="7px" type="TRANSPARENT" />
												<Tooltip title={ele.track_name}>
													<Typography
														sx={{
															fontSize: '18px',
															fontWeight: 700,
															color: '#fff',
															textOverflow: 'ellipsis',
															wordBreak: 'break-all',
															overflow: 'hidden',
															maxWidth: '560px',
															whiteSpace: 'nowrap',
															fontFamily: 'Pretendard'
														}}
													>
														{ele.track_name}
													</Typography>
												</Tooltip>
												{/* <AlbumDetailTrackTitle>{ele.track_name}</AlbumDetailTrackTitle> */}
												<VDivider margin="7px" type="TRANSPARENT" />
												{ele.musicvideos !== false ? (
													ele.musicvideos?.length > 0 && ele.musicvideos[0].release_date ? (
														<Tooltip
															title={moment(ele.musicvideos[0].release_date).format('YYYY-MM-DD hhA ([KST]) 공개예정')}
														>
															<Chip
																label="MV"
																sx={{
																	backgroundColor: '#EFFD60',
																	fontSize: '12px',
																	fontWeight: 'bold',
																	padding: '0 2px'
																}}
															/>
														</Tooltip>
													) : (
														<AlbumDetailBadge
															justify="CENTER"
															style={{
																width: '44px',
																maxWidth: '44px'
															}}
														>
															MV
														</AlbumDetailBadge>
													)
												) : (
													''
												)}
												<VDivider margin="3px" type="TRANSPARENT" />
												{ele.is_title === 1 && (
													<Chip
														label="TITLE"
														sx={{
															backgroundColor: '#EFFD60',
															fontSize: '12px',
															fontWeight: 'bold',
															padding: '0 2px'
														}}
													/>
												)}
												<VDivider margin="3px" type="TRANSPARENT" />
												{ele.is_adult === 1 && (
													<Icon
														icon="iconFlag19"
														style={{
															width: '28px',
															height: '28px'
														}}
													/>
												)}
											</Row>
											<HDivider margin="3px" type="TRANSPARENT" />

											<Row justify="FLEX_START">
												<VDivider margin="16.5px" type="TRANSPARENT" />
												<AlbumDetailValueText>
													{ele.artists &&
													ele.artists.length > 0 &&
													ele.artists.filter((artist: any) => artist.role_id === 90)
														? ele.artists
																.filter((artist: any) => artist.role_id === 90)
																.reduce((a, c) => a + ', ' + c.artist_name, '')
																.replace(/^\s*,/, '')
														: ''}
												</AlbumDetailValueText>
											</Row>
										</Row>
										<HDivider margin="10px" type="TRANSPARENT" />
										<HDivider margin="0px" style={{ width: '100%' }} type="UNCONFIRMED" />
										<HDivider margin="10px" type="TRANSPARENT" />
										<Row align="flex-start" justify="SPACE_BETWEEN" style={{ width: '100%' }}>
											<Row direction="column" justify="FLEX_START" style={{ flex: 0.6 }}>
												<Row direction="row" justify="FLEX_START" style={{ width: '100%' }}>
													<AlbumDetailLabelText style={{ minWidth: '77px' }}>업로드 파일명</AlbumDetailLabelText>
													<VDivider margin="12px" type="TRANSPARENT" />
													<AlbumDetailValueText>
														{ele.sources.find((ele) => ele.target_type === 'track')?.origin_name ?? 'No Music File'}
													</AlbumDetailValueText>
												</Row>
												<HDivider margin="6px" type="TRANSPARENT" />
												<Row direction="row" justify="FLEX_START" style={{ width: '100%' }}>
													<AlbumDetailLabelText style={{ minWidth: '77px' }}>트랙 ID</AlbumDetailLabelText>
													<VDivider margin="12px" type="TRANSPARENT" />
													<AlbumDetailValueText>{ele.id}</AlbumDetailValueText>
												</Row>
												<HDivider margin="6px" type="TRANSPARENT" />
												<Row direction="row" justify="FLEX_START" style={{ width: '100%' }}>
													<AlbumDetailLabelText style={{ minWidth: '77px' }}>작사</AlbumDetailLabelText>
													<VDivider margin="12px" type="TRANSPARENT" />
													<AlbumDetailValueText>
														{ele.artists &&
														ele.artists.length > 0 &&
														ele.artists.filter((artist: any) => artist.role_id === 97)
															? ele.artists
																	.filter((artist: any) => artist.role_id === 97)
																	.reduce((a, c) => a + ', ' + c.artist_name, '')
																	.replace(/^\s*,/, '')
															: ''}
													</AlbumDetailValueText>
												</Row>
												<HDivider margin="6px" type="TRANSPARENT" />
												<Row direction="row" justify="FLEX_START" style={{ width: '100%' }}>
													<AlbumDetailLabelText style={{ minWidth: '77px' }}>작곡</AlbumDetailLabelText>
													<VDivider margin="12px" type="TRANSPARENT" />
													<AlbumDetailValueText>
														{ele.artists &&
														ele.artists.length > 0 &&
														ele.artists.filter((artist: any) => artist.role_id === 96)
															? ele.artists
																	.filter((artist: any) => artist.role_id === 96)
																	.reduce((a, c) => a + ', ' + c.artist_name, '')
																	.replace(/^\s*,/, '')
															: ''}
													</AlbumDetailValueText>
												</Row>
												<HDivider margin="6px" type="TRANSPARENT" />
												<Row direction="row" justify="FLEX_START" style={{ width: '100%' }}>
													<AlbumDetailLabelText style={{ minWidth: '77px' }}>편곡</AlbumDetailLabelText>
													<VDivider margin="12px" type="TRANSPARENT" />
													<AlbumDetailValueText>
														{ele.artists &&
														ele.artists.length > 0 &&
														ele.artists.filter((artist: any) => artist.role_id === 98)
															? ele.artists
																	.filter((artist: any) => artist.role_id === 98)
																	.reduce((a, c) => a + ', ' + c.artist_name, '')
																	.replace(/^\s*,/, '')
															: ''}
													</AlbumDetailValueText>
												</Row>
												<HDivider margin="6px" type="TRANSPARENT" />

												{/* ISRC */}
												{ele.isrc && (
													<>
														<Row direction="row" justify="FLEX_START" style={{ width: '100%' }}>
															<AlbumDetailLabelText style={{ minWidth: '77px' }}>ISRC</AlbumDetailLabelText>
															<VDivider margin="12px" type="TRANSPARENT" />
															<AlbumDetailValueText>{ele.isrc}</AlbumDetailValueText>
														</Row>
														<HDivider margin="6px" type="TRANSPARENT" />
													</>
												)}

												{/* UCI */}
												{ele.uci && (
													<>
														<Row direction="row" justify="FLEX_START" style={{ width: '100%' }}>
															<AlbumDetailLabelText style={{ minWidth: '77px' }}>UCI</AlbumDetailLabelText>
															<VDivider margin="12px" type="TRANSPARENT" />
															<AlbumDetailValueText>{ele.uci}</AlbumDetailValueText>
														</Row>
														<HDivider margin="6px" type="TRANSPARENT" />
													</>
												)}

												{/* 정산코드 */}
												{ele.calc_code && (
													<>
														<Row direction="row" justify="FLEX_START" style={{ width: '100%' }}>
															<AlbumDetailLabelText style={{ minWidth: '77px' }}>정산코드</AlbumDetailLabelText>
															<VDivider margin="12px" type="TRANSPARENT" />
															<AlbumDetailValueText>{ele.calc_code}</AlbumDetailValueText>
														</Row>
													</>
												)}
											</Row>
											<Row direction="column" justify="FLEX_START" style={{ flex: 0.4 }}>
												<AlbumDetailLyricsText>가사</AlbumDetailLyricsText>
												<HDivider margin="5.5px" type="TRANSPARENT" />
												<MacScrollbar
													style={{
														width: '100%',
														maxHeight: '242px',
														whiteSpace: 'pre-line',
														overflow: 'scroll',
														fontWeight: 400,
														fontSize: '14px'
													}}
												>
													{ele.lyrics}
												</MacScrollbar>
											</Row>
										</Row>
									</AlbumDetailTrackBox>
									<HDivider margin="10px" type="TRANSPARENT" />
								</>
							);
						})}

						<HDivider margin="10px" type="TRANSPARENT" />
					</Row>
					{/* <ProfileList /> */}
				</AlbumDetailLayout>

				{/* 뮤직비디오 추가제출용 화면 */}
				<AddMusicVideoModal
					tracks={uc_tracks}
					album={uc_record}
					isOpenAddMusicVedioModal={isOpenAddMusicVedioModal}
					setIsOpenAddMusicVedioModal={setIsOpenAddMusicVedioModal}
				/>
			</Content>
			<AlertModal isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />
			<AlbumInfoRegistrationModal
				record={uc_record}
				tracks={uc_tracks}
				editing={true}
				isOpenAlbumInfoRegistrationModal={uc_albumModiOpen}
				setIsOpenAlbumInfoRegistrationModal={uf_setAlbumModiOpen}
				refreshDetail={refreshDetail}
			/>
		</FullScreenModal>
	);
}

export default AlbumInfoDetailModal;
