///
/// 프로필 수정화면
///
///
///

import { TMapper } from '@api/mapper/types';
import { apiRequest, sendHttpPost } from '@utils/api';
import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import { PROFILE_INFO_UPDATE_ACTION } from '@constants/actionNames';
import { useReduxData } from '@hooks/useReduxData';

import Completed from './Completed';
import UpdateProfile from './UpdateProfile';

export type TCurrentStep = 'UPDATE' | 'COMPLETED';

type TAddProfileModalProps = {
  selectedProfile: Record<string, any>;
  setIsOpenUpdateProfileModal: () => void;
  isOpenUpdateProfileModal: boolean;
};

function UpdateProfileModal({
  selectedProfile,
  isOpenUpdateProfileModal,
  setIsOpenUpdateProfileModal,
  callback
}) {
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('UPDATE');
  const { clearData: clearUpdateProfileData } = useReduxData(PROFILE_INFO_UPDATE_ACTION);

  useEffect(() => {
    if (!isOpenUpdateProfileModal) {
      clearUpdateProfileData();
      setCurrentStep('UPDATE');
    }
  }, [isOpenUpdateProfileModal]);

  useEffect(() => {}, [selectedProfile]);





  return (
    <Modal
      headerText="아티스트 정보 수정 신청"
      hide={setIsOpenUpdateProfileModal}
      isShown={isOpenUpdateProfileModal}
      modalStyle={currentStep === 'UPDATE' && { width: '810px' }}
    >
      <>
        {currentStep === 'UPDATE' && <UpdateProfile selectedProfile={selectedProfile} setCurrentStep={setCurrentStep} setIsOpenUpdateProfileModal={setIsOpenUpdateProfileModal} callback={callback}/>}
        {currentStep === 'COMPLETED' && (
          <Completed setCurrentStep={setCurrentStep} setIsOpenUpdateProfileModal={setIsOpenUpdateProfileModal} />
        )}
      </>
    </Modal>
  );
}

export default UpdateProfileModal;
