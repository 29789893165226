import Text from '@components/form/Text';
import styled from '@emotion/styled';

export const ReConfirmNumberText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ff6465;
`;

export const MembershipTitleText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #121212;
`;

export const MembershipDescTitleText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #121212;
`;

export const MembershipDescText = styled(Text)`
  font-family: Pretendard, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8;
  color: #3b3b3b;
  text-align: justify;
`;
