import TTextField from '@components/form/TTextField';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';
import { BackdropContext } from '@hooks/usePopupMessage';
import { useTimer } from '@hooks/useTimer';
import { InputAdornment } from '@mui/material';
import { apiRequest } from '@utils/api';
import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { findId, getAuthNumber, getUserInfo } from '@api/mapper/User';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Input from '@components/form/Input';
import InputSuffix from '@components/form/InputSuffix';
import Label from '@components/form/Label';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { FindIdButton, GetAuthNumberButton, ResendAuthNumberButton } from '@components/molecules/FindId/Button';
import { ReConfirmNumberText } from '@components/molecules/FindId/Text';
import { AUTH_FIND_ID_GET_NUMBER_ACTION, USER_INFO_EDIT_PHONE_NUMBER_ACTION } from '@constants/actionNames';
import { useFormError } from '@hooks/useFormError';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import { Icon } from '@images/Icon';
import { autoHypenPhone, isEmpty, replaceAll } from '@utils/data';

import { TCurrentStep } from '.';

type TAuthNumberCertificationProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  isShowAuthNumberInput: boolean;
  setIsShowAuthNumberInput: any;
  userData: any;
};

function Init({ setCurrentStep, isShowAuthNumberInput, setIsShowAuthNumberInput, userData }: TAuthNumberCertificationProps) {
  const [phoneCertificationStatus, setPhoneCertificationStatus] = useState<'READY' | 'SUCCESS' | 'FAIL'>('READY');
  const { request, resData } = useRequest({
    reduxActionName: USER_INFO_EDIT_PHONE_NUMBER_ACTION,
    mapperFunc: findId,
  });
  const { request: getAuthNumberReq, resData: getAuthNumberRes } = useRequest({
    reduxActionName: AUTH_FIND_ID_GET_NUMBER_ACTION,
    mapperFunc: getAuthNumber,
  });
  ///
  /// 전화인증 버튼 후크로 활성화 여부 결정
  ///
  const [mobileAuthBtnEnabled, setMobileAuthBtnEnabled] = useState(true);

  const { formData, formFieldsError, validation, setFormData, handleSubmit } = useReduxForm(
    USER_INFO_EDIT_PHONE_NUMBER_ACTION,
    (params) => request({ ...params, res_auth_id: authId }),
  );

  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const {
    minutes: phoneMinutes,
    seconds: phoneSeconds,
    setMinutes: setPhoneMinutes,
    setSeconds: setPhoneSeconds,
  } = useTimer({
    stopCondition: phoneCertificationStatus === 'SUCCESS',
  });

  const { ctf_sendHttpPost, ctf_sendHttpPatch } = useContext(BackdropContext);

  const setFindIdFormData = (e: any, dataIndex: string) => {
    const { value } = e.target;
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
  };

  const [errorMessage, setErrorMessage] = useState('');

  // const result = await apiRequest({
  //   url: `/token/sms-check`,
  //   method: 'POST',
  //   data: params,
  // });

  async function callMobileAuthCheck(authCode: any) {
    setErrorMessage('');
    const params = {
      phone_num: replaceAll(formData.phone_number, '-', ''),
      auth_code: authCode,
      res_auth_id: authId,
    };
    const res = await ctf_sendHttpPost(`/token/sms-auth-check`, params);

    if (!res.error) {
      setErrorMessage(res.data?.message ?? '인증되었습니다.');
      setPhoneCertificationStatus('SUCCESS');
    } else {
      setErrorMessage(res.data?.message ?? '');
      setPhoneCertificationStatus('FAIL');
    }
  }

  async function callMobileChange() {
    const params = {
      phone_number: autoHypenPhone(formData.phone_number),
    };

    const res = await ctf_sendHttpPatch(`/user/${userData.seq}`, params);

    if (!res.error) {
      setErrorMessage('');
      setCurrentStep('FINISH');
    } else {
      setErrorMessage(res.data?.message ?? '휴대전화 변경을 실패하였습니다.');
    }
  }

  useEffect(() => {
    if (getAuthNumberRes && getAuthNumberRes.code !== "404") {
      setIsShowAuthNumberInput(true);
    }
  }, [getAuthNumberRes]);
  const checkOnlyNumber = (e) => {
    if (
      !/\d/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'Delete' &&
      e.key !== 'Tab'
    ) {
      e.preventDefault();
    }
  };

  const [authId, setAuthId] = useState('');

  async function callMobileAuth() {
    const params = {
      phone_num: replaceAll(formData.phone_number, '-', ''),
      auth_type: 'sign-up',
    };
    const res = await ctf_sendHttpPost(`/token/sms-check`, params);

    if (!res.error && res.data?.code == 200) {
      setIsShowAuthNumberInput(true);
      setPhoneSeconds(0);
      setPhoneMinutes(3);
      setPhoneCertificationStatus('READY');
      setErrorMessage('');

      setAuthId(res.data.res_auth_id);
    } else {
      setErrorMessage(res.data?.message ?? '번호를 확인해주세요.');
    }
  }

  /// 전화인증버튼 활성화 체크
  function checkMobileButtonState(val: string) {
    const curPhoneNum = userData.phone_number.replace(/-/g, '');
    const inputNum = val.replace(/-/g, '');

    if (curPhoneNum == inputNum) {
      setErrorMessage('기존 번호와 동일한 번호입니다.');

      setMobileAuthBtnEnabled(true);
      return;
    }

    const enabled = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(val);
    setMobileAuthBtnEnabled(!enabled);
    setErrorMessage('');
  }

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="9px" type="TRANSPARENT" />
        <Row direction="column" justify="FLEX_START">
          <Text textType="DESCRIPTION">전화번호를 수정하기 위해 인증절차가 필요합니다.</Text>
        </Row>
        <HDivider margin="8px" type="TRANSPARENT" />
        <Label>이름</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <TTextField inputProps={{ readOnly: true }} value={userData.name} />
        <HDivider margin="10px" type="TRANSPARENT" />
        <Label htmlFor="phone_number" required={true} style={{ marginBottom: '6px' }}>
          휴대전화
        </Label>
        <Row justify="SPACE_BETWEEN">
          <TTextField
            id="phone_number"
            InputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            onChange={(e: ChangeEvent) => {
              setFindIdFormData(e, 'phone_number');
              //@ts-ignore
              checkMobileButtonState(e.target.value.replace(/-/gi, ''));
            }}
            onKeyDown={checkOnlyNumber}
            placeholder="숫자만 입력하세요."
            style={{ flex: 7 }}
            type="text"
            value={formData.phone_number ?? ''}
          />
          <VDivider margin="5px" type="TRANSPARENT" />
          <GetAuthNumberButton
            disabled={mobileAuthBtnEnabled}
            onClick={(e) => {
              e.preventDefault();
              callMobileAuth();
            }}
            style={{ flex: 3 }}
          >
            인증번호 받기
          </GetAuthNumberButton>
        </Row>

        <p style={{ fontSize: '12px', color: 'red' }}> {errorMessage} </p>

        <HDivider margin="13px" type="TRANSPARENT" />
        {isShowAuthNumberInput && (
          <>
            <Label htmlFor="auth_code" style={{ marginBottom: '6px' }}>
              인증번호
            </Label>
            <HDivider margin="6px" type="TRANSPARENT" />
            <TTextField
              id="auth_code"
              disabled={phoneCertificationStatus === 'SUCCESS'}
              onChange={(e: ChangeEvent) => {
                setFindIdFormData(e, 'auth_code');
                //폰인증이 성공이면 인증 체크를 할필요가 없다.
                if (phoneCertificationStatus === 'SUCCESS') {
                  return;
                }
                const { value } = e.target as any;
                if (value.length === 6) {
                  (async () => {
                    await callMobileAuthCheck(value);
                  })();
                }
              }}
              placeholder="인증번호 입력"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                endAdornment: (
                  <InputAdornment position="end">
                    {phoneCertificationStatus !== 'SUCCESS' && (
                      <div className={phoneMinutes === 0 && parseInt(phoneSeconds, 10) === 0 ? 'time' : 'time_enable'}>
                        {`${phoneMinutes}:${phoneSeconds}`}&nbsp;
                      </div>
                    )}
                    <Icon icon={phoneCertificationStatus === 'SUCCESS' ? 'btnCheck' : 'btnUnCheck'} />
                  </InputAdornment>
                ),
              }}
              type="number"
              value={formData.auth_code ?? ''}
            />
            {phoneCertificationStatus === 'FAIL' && (
              <>
                <HDivider margin="5px" type="TRANSPARENT" />
                <Row justify="FLEX_START">
                  <ReConfirmNumberText>인증번호를 다시 확인해 주세요</ReConfirmNumberText>
                  <VDivider margin="8px" type="TRANSPARENT" />
                  <ResendAuthNumberButton
                    onClick={(e) => {
                      e.preventDefault();
                      callMobileAuth();
                    }}
                  >
                    인증번호 재전송
                  </ResendAuthNumberButton>
                </Row>
              </>
            )}
          </>
        )}
        <HDivider margin="15px" type="TRANSPARENT" />
        <FindIdButton disabled={phoneCertificationStatus !== 'SUCCESS'} onClick={callMobileChange}>
          휴대폰번호 변경
        </FindIdButton>
      </Form>
    </Layout>
  );
}

export default Init;
