import Row from '@components/form/Row';
import styled from '@emotion/styled';

export const AlbumDetailTrackBox = styled(Row)`
  padding: 31px 30px;
  background: #222222;
  border-radius: 10px;
  color: #fff;
`;

export const AlbumDetailBadge = styled(Row)`
  background: #effd60;
  border-radius: 16px;
  padding: 6px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */
  text-align: center;

  color: #121212;
`;
