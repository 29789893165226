import React, { ReactElement } from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

type THandleCheckVDividerTypeParams = 'TRANSPARENT' | 'GRAY' | 'GRAY2' | 'NONE';

type TDividerParams = {
  type: THandleCheckVDividerTypeParams;
  margin: string;
  height?: string;
  style?: any;
};

const handleCheckVDividerType = (type: THandleCheckVDividerTypeParams) => {
  switch (type) {
    case 'TRANSPARENT':
      return css`
        border: 0;
        border-color: transparent !important;
        background-color: transparent !important;
      `;
    case 'GRAY':
      return css`
        border: 0.5px solid #dddddd;
      `;
    case 'GRAY2':
      return css`
        border: 0.5px solid #3b3b3b;
      `;
    default:
      return css``;
  }
};

const StyledVDivider = styled.div<TDividerParams>`
  width: 1px;
  ${({ type }) => handleCheckVDividerType(type)}
  ${({ margin }) =>
    margin &&
    css`
      margin-left: ${margin} !important;
      margin-right: ${margin} !important;
    `}
  ${({ height }) =>
    css`
      height: ${height};
    `}
`;

const VDivider = ({ ...rest }: TDividerParams): ReactElement => <StyledVDivider {...rest} />;

export default VDivider;
