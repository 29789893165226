import AlertModal from '@components/form/AlertModal';
import { routePath } from '@constants/path';
import { AlbumContext } from '@containers/App';
import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import { useReduxData } from '@hooks/useReduxData';
import { apiRequest } from '@utils/api';
import { $mixtapeGetUser, $mixtapeUserSeq, getUserSeq } from '@utils/auth';
import history from '@utils/history';
import date from 'date-and-time';

import React, { useContext, useEffect, useState } from 'react';

import { addAlbumInfo } from '@api/mapper/AlbumManagement';

import HDivider from '@components/form/HDivider';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import FullScreenModal, {
	CloseButton,
	Footer,
	HeaderText
} from '@components/molecules/AlbumManagement/FullScreenModal';
import { AlbumText } from '@components/molecules/AlbumManagement/Text';
import { NextButton } from '@components/molecules/SignUp/Button';
import { ALBUM_INFO_REGISTRATION_ACTION, CODE_LIST_LANGUAGE_ACTION } from '@constants/actionNames';
import ConfirmModal from '@containers/AlbumManagement/ConfirmModal';

import { useModal } from '@hooks/useModal';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import { Icon } from '@images/Icon';

import Info from './Info';
import Schedule from './Schedule';
import Track from './Track';
import { getCodeList } from '@api/mapper/Code';
import { Tooltip } from '@mui/material';

const getPrevNextPrevButtonStyle = (isDisabled: boolean): Record<string, any> => {
	if (isDisabled) {
		return {
			INFO: {
				PREV: {},
				NEXT: {
					width: '128px',
					background: '#DEDEDE'
				}
			},
			TRACK: {
				PREV: {
					width: '128px',
					border: '1px solid #EFFD60',
					color: '#EFFD60',
					background: 'transparent'
				},
				NEXT: {
					width: '128px',
					background: '#55592A',
					color: '#121212'
				}
			},
			SCHEDULE: {
				PREV: {
					width: '128px',
					border: '1px solid #777777',
					background: 'transparent',
					color: '#121212'
				},
				NEXT: {
					width: '128px',
					background: '#DEDEDE'
				}
			}
		};
	}
	return {
		INFO: {
			PREV: {},
			NEXT: {
				width: '128px',
				background: '#121212'
			}
		},
		TRACK: {
			PREV: {
				width: '128px',
				border: '1px solid #EFFD60',
				color: '#EFFD60',
				background: 'transparent'
			},
			NEXT: {
				width: '128px',
				background: '#EFFD60',
				color: '#121212'
			}
		},
		SCHEDULE: {
			PREV: {
				width: '128px',
				border: '1px solid #777777',
				background: 'transparent',
				color: '#121212'
			},
			NEXT: {
				width: '128px',
				background: '#121212'
			}
		}
	};
};

function AlbumInfoRegistrationModal({
	record = null,
	tracks = null,
	editing = false,
	setIsOpenAlbumInfoRegistrationModal,
	isOpenAlbumInfoRegistrationModal,
	refreshDetail = null
}) {
	const { isShown: isOpenConfirmModal, toggle: setIsOpenConfirmModal } = useModal();
	const [isDisabledNextButton, setIsDisabledNextButton] = useState<boolean>(true);
	const [currentStep, setCurrentStep] = useState('INFO');
	//컨텍스트 호출
	const { ctc_albumInfo, ctf_setAlbumInfo } = useContext(AlbumContext);
	const { ctf_setLoadingText, ctf_sendHttpPost, ctf_sendHttpPut } = useContext(BackdropContext);
	const { requestForSingle: getLanguageCodeListReq, resData: getLanguageCodeListRes } = useRequest({
		reduxActionName: CODE_LIST_LANGUAGE_ACTION,
		mapperFunc: getCodeList
	});
	// 제외국가 & 발매플랫폼 data
	const { data: albumAdditionalInfoData, setData: setAlbumAdditionalInfoData } =
		useReduxData(ALBUM_INFO_REGISTRATION_ACTION);

	///
	/// 리덕스폼에서 수정단계에서는 트랙다음에 바로 서버전송처리한다.
	///
	const reduxForm = useReduxForm(ALBUM_INFO_REGISTRATION_ACTION, (params) => {
		switch (currentStep) {
			case 'INFO':
				setCurrentStep('TRACK');
				setIsDisabledNextButton(false);
				break;
			case 'TRACK':
				// 수정모드이면 스케줄선택은 없고 내용확인 후 바로 서버 처리한다.

				// 타이틀곡 미지정이나 복수 지정시 타이틀곡을 재지정하는 로직
				// 버그 양산이라 이용정지 --> getActuaclParams에서 서버보내는 패러미터 설정에서 지정하도록 변경
				// setTrackTitle();
				if (ctc_albumInfo.editing === true) {
					setIsOpenConfirmModal(true);
					//updateAlbumData()
				} else {
					setCurrentStep('SCHEDULE');
					setIsDisabledNextButton(false);
				}
				break;
			default:
				setIsOpenConfirmModal(true);
		}
	});

	const { formData, formFieldsError, validation, handleSubmit, setFormData, clearData } = reduxForm;

	const handleShowCurrentComponent = () => {
		if (currentStep === 'INFO') {
			return (
				<Info reduxForm={reduxForm} setCurrentStep={setCurrentStep} setIsDisabledNextButton={setIsDisabledNextButton} />
			);
		}
		if (currentStep === 'SCHEDULE') {
			return (
				<Schedule
					reduxForm={reduxForm}
					setCurrentStep={setCurrentStep}
					setIsDisabledNextButton={setIsDisabledNextButton}
				/>
			);
		}
		if (currentStep === 'TRACK') {
			return (
				<Track
					reduxForm={reduxForm}
					setCurrentStep={setCurrentStep}
					setIsDisabledNextButton={setIsDisabledNextButton}
				/>
			);
		}
		return null;

		// return null;
	};
	///
	/// 화면 수정때 사용
	///
	function loadAlbumInfo(rd, tk) {
		const rldate = new Date(rd.release_date);
		const releaseDate = {
			date: date.format(rldate, 'YYYY-MM-DD'),
			time: date.format(rldate, 'HH')
		};

		const info = {
			description: rd.description,
			album_name: rd.album_name,
			album_name_foriegn: rd.languages && rd.languages.length > 0 ? rd.languages[0].text : '',
			agency_name: rd.agency_name,
			type_id: {
				value: rd.type_id,
				label: rd.type_name
			},
			country_id:
				rd.country_id && rd.country_name
					? {
							label: rd.country_name,
							value: rd.country_id
						}
					: null,

			genre_id: rd.genres?.map((g) => g.genre_id),
			album_cover: rd.cover?.map((img) => {
				return {
					remoteFile: true,
					preview: img.image_url
				};
			}),
			title_version: rd.title_version,
			booklet: rd.booklet?.map((img) => {
				return {
					remoteFile: true,
					preview: img.image_url
				};
			}),
			artist: rd.artists?.map((ast) => {
				return {
					id: ast.artist_id,
					label: ast.artist_name,
					foreign: ast.languages?.reduce((acc, cur) => acc + ', ' + cur.name, '').replace(/^,/g, '')
				};
			}),
			selectedTime: releaseDate.time,
			releaseDate: releaseDate.date,
			startDate: releaseDate.date,
			track: loadTrackInfo(tk)
		};
		setAlbumAdditionalInfoData({
			excludedCountries: record.excluded_release_countries ?? [],
			releasePlatform: record.allowed_release_platforms ?? []
		});
		setFormData(info);
	}

	///
	/// 앨범수정할 때에, 서버에서 받아온 저장정보를 폼에 맞도록 트랙정보 재편성
	///
	function loadTrackInfo(traks) {
		if (!tracks || tracks.length === 0) {
			return [];
		}

		const getTest = (trackInfo: any) => {
			const findIdx = getLanguageCodeListRes.findIndex((item: any) => item.id === trackInfo.language_id);

			return {
				label: getLanguageCodeListRes[findIdx]?.code_name,
				value: getLanguageCodeListRes[findIdx]?.id
			};
		};

		return tracks.map((tk) => {
			const info = {
				track_name: tk.track_name,
				track_video: tk.musicvideos,
				track_name_foriegn: tk.languages && tk.languages.length > 0 ? tk.languages[0].text : '',
				is_remake: tk.is_remake === 1 ? 'true' : 'false',
				is_owner: tk.is_owned === 1 ? 'true' : 'false',
				is_main: tk.is_title === 1,
				is_adult: tk.is_adult === 1,
				lyrics: tk.lyrics,
				track_artist: tk.artists,
				title_version: tk.title_version,
				language_id: getTest(tk),
				track_genre_id: tk.genres?.map((at) => {
					return at.genre_id;
				})
			};

			if (tk.id) {
				info.id = tk.id;
				info.track_id = tk.id;
			}
			let trackInfo = null;
			if (tk.sources && tk.sources.length > 0) {
				// 트랙파일 찾기
				const trakcFile = tk.sources.find((src) => src.target_type === 'track');

				trackInfo = ((file) =>
					file && file.url
						? {
								remoteFile: true,
								name: file.origin_name,
								path: file.origin_name,
								preview: file.url
							}
						: null)(trakcFile);

				const remakeFile = tk.sources.find((src) => src.target_type === 'remake');
				const remakeInfo = ((file) =>
					file && file.url
						? [
								{
									remoteFile: true,
									preview: file.url
								}
							]
						: null)(remakeFile);
				info.remakeFiles = remakeInfo;

				const ownerFile = tk.sources.find((src) => src.target_type === 'owner');
				const ownerInfo = ((file) =>
					file && file.url
						? [
								{
									remoteFile: true,
									preview: file.url
								}
							]
						: null)(ownerFile);
				info.ownerFiles = ownerInfo;
			}

			const track = {};
			track.info = info;
			if (trackInfo) {
				track.trackFile = trackInfo;
			}
			return track;
		});
	}

	// 슬립 -> 파일 루프 전송시 사용하기 위해서 정의
	const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

	///
	/// 앨범정보 전송
	///
	async function sendNewAlbumData(excepts) {
		const params = {
			...formData,
			...excepts
		};
		const requestJson = getActualParams(params);

		if (!params.album_cover || params.album_cover.length === 0) {
			uf_popupMessage(`앨범파일을 선택하여 주십시오.`);
			return;
		}

		ctf_setLoadingText(`밀앨범 정보를 등록 중입니다.`);
		const result = await ctf_sendHttpPost(`/albums`, requestJson);

		if (result.error) {
			uf_popupMessage(`앨범등록을 실패하였습니다.\n${result.errorMsg}`);
			return;
		}

		if (result.data.code === '422') {
			uf_popupMessage(`앨범수정을 실패하였습니다.\n${result.data.message}`);
			return;
		}

		// 에러가 없는 경우도 제대로 리스판스가 왔는지 확인
		if (!result.data.id || !result.data.tracks || result.data.tracks.length == 0) {
			uf_popupMessage(`앨범등록을 실패하였습니다.\n서버 결과값이 없습니다.`);
			return;
		}
		// file upload
		// 커버파일

		const frm = new FormData();
		const converFile = params.album_cover[0];

		frm.append('type', 'cover');
		frm.append('target_id', result.data.id);
		frm.append('file', converFile);

		ctf_setLoadingText(`앨범커버 파일을 등록 중입니다..`);
		const coverRes = await ctf_sendHttpPost('/uploads', frm);
		if (coverRes.error) {
			console.error('Error: 앨범커퍼 이미지');
			ctf_setLoadingText(`앨범커버 파일 업로드를 실패하였습니다.`);
		}

		// 부클릿
		const bkFrm = new FormData();
		let fidx = 0;
		if (params.booklet && params.booklet.length > 0) {
			for (const booklet of params.booklet) {
				fidx++;
				bkFrm.append('type', 'booklet');

				bkFrm.append('target_id', result.data.id);
				bkFrm.append('file', booklet);

				ctf_setLoadingText(`${fidx}번째 부클릿 파일을 등록 중입니다.`);
				const bkRes = await ctf_sendHttpPost('/uploads', bkFrm);
				if (bkRes.error) {
					console.error('Error: 부클릿 이미지');
					ctf_setLoadingText(`${fidx}번째 부클릿 파일 업로드 실패입니다.`);
				}
			}
		}

		// 트랙파일
		fidx = 0;
		for (const track of params.track) {
			fidx++;
			// TrackID를 찾아야함
			const found = result.data.tracks.find((ele: any) => {
				return ele.track_name === track.info.track_name;
			});

			if (!found) {
				ctf_setLoadingText(`${fidx}번째 트랙정보의 서버처리 결과를 찾을 수 없습니다.`);
				continue;
			}

			const tf = track.trackFile;
			if (!tf || !tf.name || !tf.size || tf.size === 0) {
				ctf_setLoadingText(`${fidx}번째 트랙의 음원 파일이 비정상입니다.`);
			} else {
				const trkFrm = new FormData();
				trkFrm.append('type', 'track');
				trkFrm.append('target_id', found.id);
				trkFrm.append('file', tf);
				ctf_setLoadingText(`${fidx}번째 트랙 음원 파일을 등록 중입니다.`);
				sleep(777);
				const trkRes = await ctf_sendHttpPost('/uploads', trkFrm);
				if (trkRes.error) {
					console.error('Error: 트랙파일');
					ctf_setLoadingText(`${fidx}번째 트랙 음원 파일 업로드 실패입니다.`);
				}
			}

			// 뮤직비디오 파일
			// 구버전 로직 -- 주석처리할것
			// if (track.info.track_video && track.info.track_video.length > 0) {
			//   const videoFrm = new FormData();
			//   const mf = track.info.track_video[0]; // 파일
			//   videoFrm.append('type', 'track_mv');
			//   videoFrm.append('target_id', found.id);
			//   videoFrm.append('file', mf);
			//
			//   // 썸네일 파일
			//   if (track.info.track_thumnail && track.info.track_thumnail.length > 0) {
			//     videoFrm.append('mv_img', track.info.track_thumnail[0]);
			//   }
			//   ctf_setLoadingText(`${fidx}번째 트랙 뮤직비디오 파일 업로드중입니다.`);
			//   sleep(777);
			//   const mfRes = await ctf_sendHttpPost('/uploads', videoFrm);
			//   if (mfRes.error) {
			//     console.error('Error: 뮤직비디오');
			//     ctf_setLoadingText(`${fidx}번째 트랙 뮤직비디오 파일 업로드 실패입니다.`);
			//   }
			//
			// }

			if (track.info.track_video && track.info.track_video.length > 0) {
				const videoFrm = new FormData();

				const mf = track.info.track_video[0]; // 파일
				videoFrm.append('rating_agency', track.info.rating_agency);
				videoFrm.append('rating_agency_name', track.info.rating_agency_name);
				videoFrm.append('rating_cd', track.info.rating_cd);
				videoFrm.append('rating_date', track.info.rating_date);
				videoFrm.append('track_id', found.id);
				videoFrm.append('file', mf);

				// 썸네일 파일
				if (track.info.track_thumnail && track.info.track_thumnail.length > 0) {
					videoFrm.append('mv_img', track.info.track_thumnail[0]);
				}

				// 영심위 심의 인증 파일
				if (track.info.mv_doc && track.info.mv_doc.length > 0) {
					videoFrm.append('mv_doc', track.info.mv_doc[0]);
				}

				ctf_setLoadingText(`${fidx}번째 트랙 뮤직비디오 파일을 등록 중입니다.`);
				sleep(777);

				const mfRes = await ctf_sendHttpPost('/musicvideos', videoFrm);
				if (mfRes.error) {
					console.error('Error: 뮤직비디오');
					console.error(mfRes.errorMsg);
					ctf_setLoadingText(`${fidx}번째 트랙 뮤직비디오 파일 업로드 실패입니다.`);
				}
			}

			// 개작동의서 파일
			if (!!track.info.remakeFiles && track.info.remakeFiles.length > 0) {
				const subfrm = new FormData();
				subfrm.append('type', 'track_remake');
				subfrm.append('target_id', found.id);
				subfrm.append('file', track.info.remakeFiles[0]);
				ctf_setLoadingText(`${fidx}번째 트랙 개작동의서 파일을 등록 중입니다.`);
				sleep(777);
				const subRes = await ctf_sendHttpPost('/uploads', subfrm);
				if (subRes.error) {
					console.error('Error: 개작동의서');
				}
			}

			// 독점소유 파일
			if (!!track.info.ownerFiles && track.info.ownerFiles.length > 0) {
				const subfrm = new FormData();
				subfrm.append('type', 'track_own');
				subfrm.append('target_id', found.id);
				subfrm.append('file', track.info.ownerFiles[0]);
				ctf_setLoadingText(`${fidx}번째 트랙 독점소유 인증파일 파일을 등록 중입니다.`);
				sleep(777);
				const subRes = await ctf_sendHttpPost('/uploads', subfrm);

				if (subRes.error) {
					console.error('Error: 독점소유 인증파일');
					ctf_setLoadingText(`${fidx}번째 독점소유 인증파일 업로드 실패입니다.`);
				}
			}
		}

		// 파일 성공되고 나면...
		uf_popupPassMessage(
			'앨범 발매 신청이 완료되었습니다.<br/>등록된 앨범이 mixtape. 정책에 맞지 않을 경우<br/><b>반려</b> 처리될 수 있으니 <b>메일 확인</b> 부탁드리며<br/> <b>기한 내에 수정되지 않을 시 지정하신 날짜에서<br/>발매 취소</b>될 수 있는 점 참고 부탁드립니다.'
		);
	}

	///
	/// 앨범정보 수정
	///
	async function updateAlbumData(excepts) {
		const params = {
			...formData,
			...excepts
		};
		const requestJson = getUpdateParams(params);

		ctf_setLoadingText('앨범 정보를 등록 중입니다.');
		const result = await ctf_sendHttpPut(`/albums/${record.id}`, requestJson);

		if (result.error) {
			uf_popupMessage(`앨범수정을 실패하였습니다.\n${result.errorMsg}`);
			// todo: error-logs 키값 분리 예정
			const userInfo = $mixtapeGetUser();
			const errorMsg = {
				errors: {
					user_seq: userInfo.seq,
					parameter: { ...requestJson, ...result.error }
				}
			};
			return await apiRequest({
				url: '/error-logs',
				method: 'POST',
				data: errorMsg
			});
		}

		if (result.data.code === '422') {
			uf_popupMessage(`앨범수정을 실패하였습니다.\n${result.data.message}`);
			// todo: error-logs 키값 분리 예정
			const userInfo = $mixtapeGetUser();
			const errorMsg = {
				errors: {
					user_seq: userInfo?.seq,
					parameter: { ...requestJson, ...result.error }
				}
			};
			return await apiRequest({
				url: '/error-logs',
				method: 'POST',
				data: errorMsg
			});
			return;
		}

		// 에러가 없는 경우도 제대로 리스판스가 왔는지 확인
		if (!result.data.id || !result.data.tracks || result.data.tracks.length == 0) {
			uf_popupMessage(`앨범등록을 실패하였습니다.\n서버 결과값이 없습니다.`);
			return;
		}
		// file upload
		// 커버파일

		let file = params.album_cover[0];
		if (file.remoteFile !== true) {
			let frm = new FormData();
			frm.append('type', 'cover');
			frm.append('target_id', result.data.id);
			frm.append('file', file);

			ctf_setLoadingText('앨범 커버 파일을 등록 중입니다.');
			const response = await ctf_sendHttpPost('/uploads', frm);
			if (response.error) {
				console.error('Error: 앨범커퍼 이미지');
				ctf_setLoadingText('앨범커버 이미지 업로드를 실패하였습니다.');
			}
		}

		let fidx = 0;
		// 부클릿
		let bkFrm = new FormData();
		if (params.booklet && params.booklet.length > 0) {
			for (const booklet of params.booklet) {
				if (booklet.preview.startsWith('http')) {
					continue;
				}
				fidx++;

				bkFrm.append('type', 'booklet');
				bkFrm.append('target_id', result.data.id);
				bkFrm.append('file', booklet);
				ctf_setLoadingText(`${fidx}번째 부클릿 이미지 업로드중입니다.`);
				const response = await ctf_sendHttpPost('/uploads', bkFrm);
				if (response.error) {
					console.error('Error: 부클릿 이미지');
					ctf_setLoadingText(`${fidx}번째 부클릿 이미지 업로드를 실패하였습니다.`);
					// return false
				}
			}
		}

		fidx = 0;
		// 트랙파일
		for (const track of params.track) {
			// TrackID를 찾아야함
			const found = result.data.tracks.find((ele: any) => {
				return ele.track_name === track.info.track_name;
			});

			if (!found) {
				continue;
			}

			fidx++;

			const tkfile = track.trackFile;
			if (tkfile.preview.startsWith('blob:')) {
				const trkFrm = new FormData();
				trkFrm.append('type', 'track');
				trkFrm.append('target_id', found.id);
				trkFrm.append('file', tkfile);

				ctf_setLoadingText(`${fidx}번째 트랙 음원 파일을 등록 중입니다.`);
				const mfileRes = await ctf_sendHttpPost('/uploads', trkFrm);
				if (mfileRes.error) {
					console.error('Error: 트랙파일');
					ctf_setLoadingText(`${fidx}번째 트랙 음원 파일 업로드릀 실패하였습니다.`);
				}
			}

			// 뮤직비디오 파일
			if (track.info.track_video && track.info.track_video.length > 0) {
				if (track.info.track_video[0].name) {
					const videoFrm = new FormData();
					videoFrm.append('type', 'track_mv');
					videoFrm.append('target_id', found.id);
					videoFrm.append('file', track.info.track_video[0]);
					// 썸네일 파일
					if (track.info.track_thumnail && track.info.track_thumnail.length > 0) {
						videoFrm.append('mv_img', track.info.track_thumnail[0]);
					}

					ctf_setLoadingText(`${fidx}번째 트랙 뮤직파일을 등록 중입니다.`);
					const response = await ctf_sendHttpPost('/uploads', videoFrm);
					if (response.error) {
						ctf_setLoadingText(`${fidx}번째 트랙 뮤직파일 업로드를 실패하였습니다.`);
					}
				}
			}

			// 개작동의서 파일
			if (track.info.remakeFiles && track.info.remakeFiles.length > 0 && track.info.remakeFiles[0].name) {
				const subfrm = new FormData();
				subfrm.append('type', 'track_remake');
				subfrm.append('target_id', found.id);
				subfrm.append('file', track.info.remakeFiles[0]);

				ctf_setLoadingText(`${fidx}번째 트랙 개작동의서 파일을 등록 중입니다.`);
				const response = await ctf_sendHttpPost('/uploads', subfrm);
				if (response.error) {
					console.error('Error: 개작동의서');
					ctf_setLoadingText(`${fidx}번째 개작동의서 파일 업로드를 실패하였습니다.`);
				}
			}

			// 독점소유 파일
			if (track.info.ownerFiles && track.info.ownerFiles.length > 0 && track.info.ownerFiles[0].name) {
				const subfrm = new FormData();
				subfrm.append('type', 'track_own');
				subfrm.append('target_id', found.id);
				subfrm.append('file', track.info.ownerFiles[0]);
				ctf_setLoadingText(`${fidx}번째 트랙 독점소유 인증파일을 등록 중입니다.`);
				const response = await ctf_sendHttpPost('/uploads', subfrm);
				if (response.error) {
					console.error('Error: 독점소유 인증파일');
					ctf_setLoadingText(`${fidx}번째 트랙 독점소유 인증파일 업로드를 실패하였습니다.`);
				}
			}
		}

		// 파일 성공되고 나면...
		uf_popupPassMessage('앨범 수정 신청을 완료하였습니다.');
	}

	///
	/// 성공 팝업창에서 오케이 쿠르면 호출
	///
	const finishRegister = () => {
		if (ctc_albumInfo.editing === true) {
			ctf_setAlbumInfo({ refreshDetail: true });
			clearData();
			setCurrentStep('INFO');
			setIsOpenAlbumInfoRegistrationModal(false);
		} else {
			ctf_setAlbumInfo({ refresh: true });
			clearData();
			setCurrentStep('INFO');
			setIsOpenAlbumInfoRegistrationModal(false);
			history.push(routePath.album.albumManagement.path);
		}
	};

	function getActualParams(p: any) {
		const rdate = date.format(new Date(p.releaseDate), 'YYYY-MM-DD') + ' ' + p.selectedTime + ':00:00';
		try {
			// 1. 앨범정보 부터 시작
			const albums = {
				user_seq: $mixtapeUserSeq(),
				album_name: p.album_name,
				agency_name: p.agency_name,
				description: p.description,
				type_id: p.type_id.value,
				// country_id: p.country_id.value,
				release_date: rdate,
				start_date: rdate,
				excluded_countries: p.excluded_countries,
				excluded_dsps: p.excluded_dsps,
				genres: p.genre_id.map((item: any) => {
					return { genre_id: item };
				}),
				languages: [
					{
						language_id: 56,
						text: p.album_name_foriegn
					}
				],
				artists: p.artist.map((a: any) => ({
					artist_id: a.id
				})),
				title_version: p.title_version
			};

			// 타이틀곡이 지정되어있지 않으면
			// 첫번째곡을 타이틀로, 두 곡이상지정된 경우는 인덱스가 빠른걸로 지정
			let titleIndex = p.track.findIndex((ele) => ele.info.is_main === true);

			const tracks = p.track.map((t: any, no: any) => ({
				track_name: t.info.track_name,
				is_remake: t.info.is_remake === 'true' ? 1 : 0,
				is_title: t.info.is_main === true || (titleIndex === -1 && no === 0) ? 1 : 0,
				//is_title: titleIndex === no ? 1 : 0,
				is_adult: t.info.is_adult === true ? 1 : 0,
				//is_owner: t.info.is_owner === 'true' ? 1 : 0,
				is_owned: t.info.is_owner == 'true' ? 1 : 0,
				track_no: no + 1,
				lyrics: t.info.lyrics,
				title_version: t.info.title_version,
				language_id: t.info.language_id.value,
				languages: [
					{
						language_id: 56,
						text: t.info.track_name_foriegn
					}
				],
				genres: t.info.track_genre_id.map((ele: any) => {
					return { genre_id: ele };
				}),
				artists: t.info.track_artist
			}));

			return {
				albums: {
					...albums,
					tracks: tracks,
					allowed_release_platforms: albumAdditionalInfoData.releasePlatform,
					excluded_release_countries: albumAdditionalInfoData.excludedCountries
				}
			};
		} catch (err) {
			console.log('데이터변환 ERROR : ', err);
		}
	}

	///
	/// update용 패러미터를
	///
	function getUpdateParams(p: any) {
		try {
			// 1. 앨범정보 부터 시작
			const albums = {
				user_seq: $mixtapeUserSeq(),
				album_name: p.album_name,
				agency_name: p.agency_name,
				description: p.description,
				type_id: p.type_id.value,
				//country_id: p.country_id.value,
				excluded_countries: p.excluded_countries,
				excluded_dsps: p.excluded_dsps,
				genres: p.genre_id.map((item: any) => {
					return { genre_id: item };
				}),
				title_version: p.title_version,

				languages: [
					{
						language_id: 56,
						text: p.album_name_foriegn
					}
				],
				artists: p.artist.map((a: any) => ({
					artist_id: a.id
				}))
			};

			// 타이틀곡이 지정되어있지 않으면
			// 첫번째곡을 타이틀로, 두 곡이상지정된 경우는 인덱스가 빠른걸로 지정
			// let titleIndex = 0
			// for(let i = 0; i < p.track.length; i++) {
			//   if(p.track[i].info.is_main === true){
			//     titleIndex = i
			//     break;
			//   }
			// }

			let titleIndex = p.track.findIndex((ele) => ele.info.is_main === true);
			const tracks = p.track.map((t: any, no: any) => {
				return {
					id: t.info.track_id,
					track_name: t.info.track_name,
					is_remake: t.info.is_remake === 'true' ? 1 : 0,
					is_title: t.info.is_main === true || (titleIndex === -1 && no === 0) ? 1 : 0,
					// is_title: titleIndex === no ? 1 : 0,
					is_adult: t.info.is_adult === true ? 1 : 0,
					// is_owner: t.info.is_owner === "true" ? 1 : 0,
					is_owned: t.info.is_owner === 'true' ? 1 : 0,
					track_no: no + 1,
					lyrics: t.info.lyrics,

					languages: [
						{
							language_id: 56,
							text: t.info.track_name_foriegn
						}
					],
					genres: t.info.track_genre_id.map((ele: any) => {
						return { genre_id: ele };
					}),
					artists: t.info.track_artist,
					language_id: t.info.language_id.value,
					title_version: t.info.title_version
				};
			});

			return {
				albums: {
					...albums,
					tracks: tracks,
					allowed_release_platforms: albumAdditionalInfoData.releasePlatform,
					excluded_release_countries: albumAdditionalInfoData.excludedCountries
				}
			};
		} catch (err) {
			console.log('데이터변환 ERROR : ', err);
		}
	}

	const handleClickPrev = (e: any) => {
		e.preventDefault();
		if (currentStep === 'TRACK') {
			setCurrentStep('INFO');
		} else if (currentStep === 'SCHEDULE') {
			setCurrentStep('TRACK');
		}
	};

	const handleClickNext = (e: any) => {
		e.preventDefault();
		handleSubmit();
		// setCurrentStep('TRACK');
	};

	const {
		openState: uc_passOpen,
		setOpenState: uf_setPassOpen,
		message: uc_passMessage,
		popupMessage: uf_popupPassMessage
	} = usePopupMessage();

	const {
		openState: uc_open,
		setOpenState: uf_setOpen,
		message: uc_message,
		popupMessage: uf_popupMessage
	} = usePopupMessage();

	const {
		openState: uc_confirmOpen,
		setOpenState: uf_setConfirmOpen,
		message: uc_confirmMessage,
		popupMessage: uf_confirmPopupMessage
	} = usePopupMessage();

	const stepStyleCls = (step) => {
		const nodeActive = {
			width: '19px',
			height: '19px',
			position: 'relative',
			bottom: '6.5px'
		};

		const nodeDeactive = {
			width: '11px',
			height: '11px',
			position: 'relative',
			bottom: '3px'
		};

		return currentStep === step ? nodeActive : nodeDeactive;
	};

	const stepFontStyle = (step: string) => {
		const nodeActive = {
			color: '#FFF',
			fontWeight: 'bold'
		};

		const nodeDeactive = {
			color: '#777',
			fontWeight: 'normal'
		};
		return currentStep === step ? nodeActive : nodeDeactive;
	};

	const resetState = () => {
		ctf_setAlbumInfo({});
		if (ctc_albumInfo.editing) {
			loadAlbumInfo(record, tracks);
		} else {
			clearData();
		}

		setCurrentStep('INFO');
	};

	///
	/// 상단 X버튼을 누를때 이벤트 처리
	///
	const handleClose = () => {
		uf_confirmPopupMessage('창을 닫으시면, 입력하신 내용이 모두 사라집니다.\n그래도 닫으시겠습니까?');
	};

	const doConfirmOk = () => {
		if (refreshDetail) {
			refreshDetail();
		}

		resetState();
		setIsOpenAlbumInfoRegistrationModal(false);
	};
	// 컨텍스트 변수에 세팅
	useEffect(() => {
		getLanguageCodeListReq({ parent_code: 'language' });
		ctf_setAlbumInfo({ editing: editing });
	}, []);

	useEffect(() => {
		if (record) {
			loadAlbumInfo(record, tracks);
		}

		return () => {
			//unmounted 시에는, 즉, 부모화면인 화면상세가 닫힐때는 나갈땐 앨범상태변수 초기화
			ctf_setAlbumInfo({});
			clearData();
			setCurrentStep('INFO');
		};
	}, [record, tracks]);

	return (
		<FullScreenModal
			header={
				<>
					<Row
						justify="FLEX_START"
						style={{
							position: 'absolute',
							left: 0
						}}
					>
						<Row
							justify="FLEX_START"
							style={{
								marginLeft: '60px',
								fontSize: '20px',
								fontWeight: 700,
								color: '#EFFD60'
							}}
						>
							{!ctc_albumInfo.editing ? '앨범발매' : '앨범수정'}
						</Row>
					</Row>

					{/* // 진행스텝 정보 보여주는 부분 */}
					<HeaderText style={{ marginTop: '20px' }}>
						<Row justify="FLEX_START">
							<Icon
								icon={currentStep === 'INFO' ? 'confirmedCheck' : 'unconfirmedCheck'}
								style={stepStyleCls('INFO')}
							/>
							<HDivider
								style={{
									marginTop: '2px',
									position: 'relative'
								}}
								type="UNCONFIRMED"
							/>
							<Icon
								icon={currentStep === 'TRACK' ? 'confirmedCheck' : 'unconfirmedCheck'}
								style={stepStyleCls('TRACK')}
							/>

							{/* 수정모드면 보여주지 않는다. */}
							{!ctc_albumInfo.editing && (
								<>
									<HDivider
										style={{
											marginTop: '2px',
											position: 'relative'
										}}
										type="UNCONFIRMED"
									/>
									<Icon
										icon={currentStep === 'SCHEDULE' ? 'confirmedCheck' : 'unconfirmedCheck'}
										style={stepStyleCls('SCHEDULE')}
									/>
								</>
							)}
						</Row>
						<HDivider margin="4px" type="TRANSPARENT" />
						<Row justify="FLEX_START">
							<AlbumText
								style={{
									...stepFontStyle('INFO'),
									position: 'relative',
									left: '-16px',
									bottom: '6.5px'
								}}
							>
								앨범정보
							</AlbumText>
							<AlbumText
								style={{
									...stepFontStyle('TRACK'),
									position: 'relative',
									left: '130px',
									bottom: '6.5px'
								}}
							>
								트랙정보
							</AlbumText>

							{/* 수정모드에서는 발매일정은 보여주지 않는다. */}
							{!ctc_albumInfo.editing && (
								<AlbumText
									style={{
										...stepFontStyle('SCHEDULE'),
										position: 'relative',
										left: '267px',
										bottom: '6.5px'
									}}
								>
									발매일정
								</AlbumText>
							)}
						</Row>
					</HeaderText>
					{/* <Row justify="FLEX_END" style={{ position: 'absolute', right: '150px' }}>
            <Button>임시저장</Button>
          </Row> */}

					{/* 닫기버튼 */}
					<CloseButton onClick={handleClose}>
						<Icon
							icon="btnClose"
							style={{
								width: '20px',
								height: '20px'
							}}
						/>
					</CloseButton>
				</>
			}
			hide={setIsOpenAlbumInfoRegistrationModal}
			isShown={isOpenAlbumInfoRegistrationModal}
		>
			{handleShowCurrentComponent()}
			<Footer style={currentStep === 'TRACK' ? { background: '#000000' } : {}}>
				<Row justify="SPACE_BETWEEN">
					<Row
						justify="FLEX_START"
						style={{
							marginLeft: '360px',
							alignItems: 'center'
						}}
					>
						{formData?.album_cover ? (
							<img
								alt="preview"
								src={formData?.album_cover?.[0]?.preview}
								style={{
									borderRadius: '10px',
									width: '80px',
									height: '80px'
								}}
							/>
						) : (
							<Icon
								icon={currentStep !== 'TRACK' ? 'noImgW' : 'noImgB'}
								style={{
									width: '80px',
									height: '80px'
								}}
							/>
						)}
						<VDivider margin="10px" type="TRANSPARENT" />
						<Row direction="column" justify="FLEX_START">
							<Text
								style={{
									fontSize: '16px',
									color: `${formData?.album_name ? (currentStep === 'TRACK' ? '#eee' : '#121212') : '#CDCDCD'}`,
									fontWeight: 700,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									minWidth: '500px',
									maxWidth: '500px',
									whiteSpace: 'nowrap'
								}}
							>
								{formData?.album_name || '앨범 제목을 입력하세요.'}
							</Text>
							<HDivider margin="2px" type="TRANSPARENT" />
							<Text
								style={{
									color: `${formData?.album_name ? (currentStep === 'TRACK' ? '#ddd' : '#3B3B3B') : '#CDCDCD'}`,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									minWidth: '500px',
									maxWidth: '500px',
									whiteSpace: 'nowrap'
								}}
							>
								{formData.artist && formData.artist.length > 0
									? formData.artist.reduce((acc, cur) => acc + ', ' + cur.label, '').replace(/^,/, '')
									: '아티스트명을 입력하세요.'}
							</Text>
						</Row>
					</Row>
					<Row justify="FLEX_END" style={{ marginRight: '360px' }}>
						{currentStep !== 'INFO' && (
							<>
								<NextButton onClick={handleClickPrev} style={getPrevNextPrevButtonStyle(false)[currentStep].PREV}>
									이전
								</NextButton>
								<VDivider margin="5px" type="TRANSPARENT" />
							</>
						)}
						<NextButton
							disabled={isDisabledNextButton}
							onClick={handleClickNext}
							style={getPrevNextPrevButtonStyle(isDisabledNextButton)[currentStep].NEXT}
						>
							{currentStep === 'TRACK' ? (
								<Tooltip
									title={<>트랙 1개 이상 TITLE 설정 필수입니다.</>}
									arrow
									placement="top"
									componentsProps={{
										tooltip: {
											sx: {
												backgroundColor: '#f5f5f9',
												color: 'rgba(0, 0, 0, 0.87)',
												maxWidth: '270px',
												fontSize: '12px',
												border: '1px solid #dadde9'
											}
										}
									}}
								>
									<div> 다음</div>
								</Tooltip>
							) : (
								<>다음</>
							)}
						</NextButton>
					</Row>
				</Row>
			</Footer>

			{/* 서버전송전 입력내용 확인하는 창 && mixtape. 유통 안내사항 */}
			<ConfirmModal
				isOpenConfirmModal={isOpenConfirmModal}
				setIsOpenConfirmModal={setIsOpenConfirmModal}
				callback={(excepts) => {
					if (ctc_albumInfo.editing) {
						updateAlbumData(excepts);
					} else {
						sendNewAlbumData(excepts);
					}
				}}
			/>

			{/* 앨범등록 완료 팝업창 */}
			<AlertModal
				isPopupOpen={uc_passOpen}
				alertText={uc_passMessage}
				hidePopup={() => uf_setPassOpen(false)}
				onOkButtonClick={() => finishRegister()}
				okButton={true}
			/>

			{/* 일반 팝업창 */}
			<AlertModal isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />

			{/* 창닫을때 컨펌 팝업창 */}
			<AlertModal
				isPopupOpen={uc_confirmOpen}
				alertText={uc_confirmMessage}
				hidePopup={() => uf_setConfirmOpen(false)}
				cancelButton={true}
				onOkButtonClick={doConfirmOk}
			/>
		</FullScreenModal>
	);
}

export default AlbumInfoRegistrationModal;
