import ChipSelect from '@components/form/ChipSelect';
import MusicVideoAddDialog from '@components/form/MusicVideoAddDialog';
import TTextField from '@components/form/TTextField';
import { AlbumContext, ProfileContext } from '@containers/App';
import { Link, Stack, Tooltip, Typography } from '@mui/material';
import { MacScrollbar } from 'mac-scrollbar';

import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { getCodeList } from '@api/mapper/Code';

import Button from '@components/form/Button';
import Checkbox from '@components/form/Checkbox';
import HDivider from '@components/form/HDivider';

import Label from '@components/form/Label';

import Radio from '@components/form/Radio';
import Row from '@components/form/Row';

import SimpleFile from '@components/form/SimpleFile';
import Text from '@components/form/Text';
import Textarea from '@components/form/Textarea';
import VDivider from '@components/form/VDivider';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';

import {
  ALBUM_INFO_REGISTRATION_TRACK_INFO_ADD_ACTION,
  CODE_LIST_GENRE_ACTION,
  CODE_LIST_LANGUAGE_ACTION,
  CODE_LIST_TRACK_ARTIST_ROLE_ACTION,
} from '@constants/actionNames';
import { useFormError } from '@hooks/useFormError';
import { useModal } from '@hooks/useModal';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import { Icon } from '@images/Icon';
import { getDisabledWithFormError } from '@utils/data';

import ArtistAddDialog from '@containers/ArtistAddModal';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Select from '@components/form/Select';
import AlertModal from '@components/form/AlertModal';

// artists={row.artists}
// 						readonly={false}
// 						deleteHandler={deleteArtist}
// 						searchResult={searchResultArtist}

const ArtistBox = ({ artist, deleteHandler, searchResult, roleId }: any) => {
  const [isOpen, setOpenState] = useState(false);

  return (
    <>
      {artist &&
        artist.map((item: any, index: number) => (
          <>
            <Row
              justify="FLEX_START"
              style={{
                minWidth: 'fit-content',
                flex: 0,
                color: '#121212',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '20px',
                border: '1px solid #121212',
                borderRadius: '20px',
                padding: '6px 8px 6px 16px',
                // marginRight: '8px',
              }}
            >
              {item.artist_name}
              <VDivider margin="4px" type="TRANSPARENT" />
              <Icon
                icon="btnCloseArtist"
                onClick={() => deleteHandler(item.artist_id, item.role_id)}
                style={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                }}
              />
            </Row>
          </>
        ))}
      <Icon
        icon="btnAddArtist"
        onClick={() => setOpenState(true)}
        style={{
          width: '32px',
          height: '32px',
          cursor: 'pointer',
        }}
      />
      <ArtistAddDialog
        isOpenArtistAddModal={isOpen}
        setIsOpenArtistAddModal={setOpenState}
        mode="S001"
        setResult={searchResult}
        roleId={roleId}
        partTypeId={roleId === 90 ? 84 : 85}
      />
    </>
  );
};

function TrackEditorBox({ setIsDisabledNextButton, selectedTrackNumber, trackFiles, trackDatum, albumInfoFormData }) {
  const { isShown: isOpenAddMusicVedioModal, toggle: setIsOpenAddMusicVedioModal } = useModal();
  const [remakeFiles, setRemakeFiles] = useState([]);
  const [ownerFiles, setOwnerFiles] = useState([]);
  const reduxForm = useReduxForm(ALBUM_INFO_REGISTRATION_TRACK_INFO_ADD_ACTION, (params) => {});

  const { ctc_albumInfo } = useContext(AlbumContext);

  const { setFormData, formData, formFieldsError, validation, handleSubmit } = reduxForm;
  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });
  const setAlbumRegistrationFormData = useCallback(
    (e: any, dataIndex: string, isValue?: boolean, isCheck?: boolean) => {
      let value = '';
      if (isValue) {
        value = e;
      } else if (isCheck) {
        value = e.target.checked;
      } else {
        value = e.target.value;
      }

      setFormData(dataIndex, value);
      checkFormError(dataIndex, value, formData);
    },
    [formData],
  );

  const { getRootProps: getRemakeRootProps, getInputProps: getRemakeInputProps } = useDropzone({
    accept: '.pdf' as any,
    onDrop: (acceptedFiles: any) => {
      setRemakeFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });
  const { getRootProps: getOwnerRootProps, getInputProps: getOwnerInputProps } = useDropzone({
    accept: '.pdf' as any,
    onDrop: (acceptedFiles: any) => {
      setOwnerFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const { requestForSingle: getGenreCodeListReq, resData: getGenreCodeListRes } = useRequest({
    reduxActionName: CODE_LIST_GENRE_ACTION,
    mapperFunc: getCodeList,
  });

  const { requestForSingle: getLanguageCodeListReq, resData: getLanguageCodeListRes } = useRequest({
    reduxActionName: CODE_LIST_LANGUAGE_ACTION,
    mapperFunc: getCodeList,
  });

  const { requestForSingle: getTrackArtistRoleCodeListReq, resData: getTrackArtistRoleCodeListRes } = useRequest({
    reduxActionName: CODE_LIST_TRACK_ARTIST_ROLE_ACTION,
    mapperFunc: getCodeList,
  });

  const handleClickDeleteRemakeFile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setRemakeFiles([]);
  };

  const handleClickDeleteOwnerFile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOwnerFiles([]);
  };

  const deleteArtist = (id: any, roleId: any) => {
    const copyData = JSON.parse(JSON.stringify(formData?.track_artist));

    const findIdx = copyData.findIndex((item: any) => item.artist_id === id && item.role_id === roleId);

    if (findIdx > -1) {
      copyData.splice(findIdx, 1);
      setAlbumRegistrationFormData(copyData, 'track_artist', true);
    }
  };
  const { curProfileContext } = useContext(ProfileContext);

  const handleOpenMusicVideoModal = (e: any) => {
    e.preventDefault();
    setIsOpenAddMusicVedioModal();
  };

  useEffect(() => {
    getGenreCodeListReq({ parent_code: 'genre' });
    getLanguageCodeListReq({ parent_code: 'language' });
    getTrackArtistRoleCodeListReq({ parent_code: 'track_artist_role' });
  }, []);

  useEffect(() => {
    let isDisabled = getDisabledWithFormError(
      formError,
      ['track_name', 'track_name_foriegn', 'track_artist', 'track_genre_id', 'language_id'],
      formData,
    );
    if (formData?.is_remake === 'true' && !formData?.remakeFiles?.length) {
      isDisabled = true;
    }
    if (formData?.is_owner === 'true' && !formData?.ownerFiles?.length) {
      isDisabled = true;
    }
    // note: 하기 저작 중 1가지 필수 입력 Validation
    const ARTIST_CODE = {
      lyricist: 97,
      composer: 96,
      arranger: 98
    };

    const validationAuthor =formData?.track_artist?.some(val => {
      return val?.role_id === ARTIST_CODE.lyricist || val?.role_id === ARTIST_CODE.composer || val?.role_id === ARTIST_CODE.arranger;
    }) || false;
    if (!validationAuthor){
      isDisabled = true;
    }
    if (isDisabled) {
      setIsDisabledNextButton(true);
    } else {
      setIsDisabledNextButton(false);
    }
  }, [formError, formData]);


  useEffect(() => {
    if (trackFiles?.[selectedTrackNumber]?.name) {
      setAlbumRegistrationFormData(trackFiles?.[selectedTrackNumber]?.name, 'track_name', true);
      setAlbumRegistrationFormData(trackFiles?.[selectedTrackNumber]?.name, 'track_name_foriegn', true);
    }
  }, [trackFiles?.[selectedTrackNumber]?.name]);

  useEffect(() => {
    // setAlbumRegistrationFormData('false', 'is_remake', true);
    // setAlbumRegistrationFormData('false', 'is_owner', true);
  }, []);

  useEffect(() => {
    setAlbumRegistrationFormData(remakeFiles, 'remakeFiles', true);
  }, [remakeFiles]);

  useEffect(() => {
    setAlbumRegistrationFormData(ownerFiles, 'ownerFiles', true);
  }, [ownerFiles]);

  const setArtistAddResult = (artist: any) => {
    if (artist === null) {
      return;
    }

    const arr = formData?.track_artist?.length ? JSON.parse(JSON.stringify(formData?.track_artist)) : [];
    const findIdx = arr.findIndex((ele: any) => ele.artist_id === artist.id && ele.role_id === artist.role_id);

    if (findIdx === -1) {
      arr.push({
        artist_id: artist.id,
        artist_name: artist.artist_name,
        languages: artist.languages,
        role_id: artist.role_id,
        part_type_id: artist.part_type_id,
      });

      setAlbumRegistrationFormData(arr, 'track_artist', true);
    }
  };

  useEffect(() => {
    if (ctc_albumInfo.editing === true) {
      return;
    }
    if (!curProfileContext || !curProfileContext.id) {
      return;
    }
    const artist = {
      artist_id: curProfileContext.id,
      artist_name: curProfileContext.artist_name,
      languages: curProfileContext.languages?.reduce((acc, cur) => acc + ',' + cur.name, '').replace(/\s*,/g, ''),
      role_id: 90,
      part_type_id: 84,
    };

    if (!formData.artist || formData.artist.length === 0) {
      setAlbumRegistrationFormData([artist], 'track_artist', true);
    }
  }, [curProfileContext]);

  const setMVFormData = (record) => {
    setFormData('track_video', record.videoFiles);
    setFormData('track_thumnail', record.thumbnailFiles);
    setFormData('mv_doc', record.videoRatingCertiFile);
    setFormData('rating_agency', record.reviewAgent);
    setFormData('rating_agency_name', record.broadcaster);
    setFormData('rating_cd', record.movieRating);
    setFormData('rating_date', record.reviewDate);
  };

  return (
    <MacScrollbar style={{ maxHeight: '800px' }}>
      <Stack direction="row" justifyContent="center">
        <Stack p={3} spacing={1} sx={{ width: '600px' }}>
          <ErrorMessage formError={formError.track_artist} />
          <Row justify="SPACE_BETWEEN">
            <Row justify="FLEX_START" style={{ flex: 5 }}>
              <Checkbox
                checked={formData?.is_main}
                id="is_main"
                label="TITLE"
                onChange={(e) => {
                  setAlbumRegistrationFormData(e, 'is_main', false, true);
                }}
              />
              <VDivider margin="1px" type="TRANSPARENT" />
              <Tooltip
                title={<>트랙 1개 이상 TITLE 설정 필수입니다.</>}
                arrow
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#f5f5f9',
                      color: 'rgba(0, 0, 0, 0.87)',
                      maxWidth: '270px',
                      fontSize: '12px',
                      border: '1px solid #dadde9',
                    },
                  },
                }}
              >
                <InfoOutlinedIcon fontSize="small" sx={{ color: '#DCDCDC' }} />
              </Tooltip>

              <VDivider margin="20px" type="TRANSPARENT" />
              <Checkbox
                checked={formData?.is_adult}
                id="is_adult"
                label="19금"
                onChange={(e) => {
                  setAlbumRegistrationFormData(e, 'is_adult', false, true);
                }}
              />
              <VDivider margin="1px" type="TRANSPARENT" />
              <Tooltip
                title={
                  <ul>
                    <li style={{ marginBottom: '5px' }}>
                      - 가사에 <b>선정적인 내용 및 욕설</b>이 포함되어 있는 경우, <br /> 19금 체크 부탁드립니다.
                    </li>
                    <li>
                      - 가사에 선정적인 내용 및 욕설이 포함되어 있으나 19금 체크가 안 되어 있는 경우, mixtape. 정책에 따라{' '}
                      <b>19금으로 설정되거나 가사 서비스가 어려울 수 있습니다.</b>
                    </li>
                  </ul>
                }
                arrow
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#f5f5f9',
                      color: 'rgba(0, 0, 0, 0.87)',
                      maxWidth: '270px',
                      fontSize: '12px',
                      border: '1px solid #dadde9',
                    },
                  },
                }}
              >
                <InfoOutlinedIcon fontSize="small" sx={{ color: '#DCDCDC' }} />
              </Tooltip>
            </Row>
          </Row>
          <Row
            justify="SPACE_BETWEEN"
            style={{
              flex: 5,
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                color: '#121212',
                lineHeight: '17px',
                textDecorationLine: 'underline',
              }}
              textType="DESCRIPTION"
            >
              {trackFiles?.[selectedTrackNumber]?.trackFile?.path}
            </Text>
            <VDivider margin="12px" type="TRANSPARENT" />
            {!ctc_albumInfo.editing && (
              <Button
                onClick={handleOpenMusicVideoModal}
                style={{
                  minWidth: '153px',
                  height: '50px',
                  color: '#121212',
                  background: '#fff',
                  border: '1px solid #121212',
                }}
              >
                뮤직비디오 업로드
              </Button>
            )}
          </Row>
          <HDivider margin="14px" type="TRANSPARENT" />
          <Label htmlFor="track_name">트랙명</Label>
          <HDivider margin="5.5px" type="TRANSPARENT" />
          <Label
            htmlFor="track_name"
            required={true}
            style={{
              fontSize: '12px',
              fontWeight: 400,
            }}
          >
            국내
          </Label>
          <HDivider margin="3px" type="TRANSPARENT" />
          <TTextField
            id="track_name"
            maxLength={251}
            onChange={(e: ChangeEvent) => {
              setAlbumRegistrationFormData(e, 'track_name');
            }}
            placeholder="트랙명을 입력하세요."
            type="text"
            value={formData.track_name ?? ''}
          />
          <ErrorMessage formError={formError.track_name} />
          <HDivider margin="8px" type="TRANSPARENT" />
          <Label
            htmlFor="track_name_foriegn"
            required={true}
            style={{
              fontSize: '12px',
              fontWeight: 400,
            }}
          >
            해외
          </Label>
          <HDivider margin="3px" type="TRANSPARENT" />
          <TTextField
            id="track_name_foriegn"
            maxLength={251}
            onChange={(e: ChangeEvent) => {
              setAlbumRegistrationFormData(e, 'track_name_foriegn');
            }}
            placeholder="트랙명을 입력하세요."
            type="text"
            value={formData.track_name_foriegn ?? ''}
          />
          <ErrorMessage formError={formError.track_name_foriegn} />
          <HDivider margin="8px" type="TRANSPARENT" />
          <Label
            htmlFor="title_version"
            style={{
              fontSize: '12px',
              fontWeight: 400,
            }}
          >
            버전
          </Label>
          <HDivider margin="3px" type="TRANSPARENT" />
          <TTextField
            id="title_version"
            maxLength={251}
            onChange={(e: ChangeEvent) => {
              setAlbumRegistrationFormData(e, 'title_version');
            }}
            placeholder="트랙 버전 정보를 입력하세요. (ex. Prod. mixtape. / Holiday Remix / Instrumental Version)"
            type="text"
            value={formData.title_version ?? ''}
          />
          <HDivider margin="14px" type="TRANSPARENT" />
          <Label htmlFor="track_genre_id" required={true}>
            장르
          </Label>
          <HDivider margin="5.5px" type="TRANSPARENT" />
          <ChipSelect
            maxSelect={2}
            maxTooltip="장르는 최대 2개까지 선택 가능합니다."
            value={formData.track_genre_id ? [...formData.track_genre_id] : []}
            placeholder="장르 선택 (최대 2개 선택)"
            onChange={(e: ChangeEvent) => {
              setAlbumRegistrationFormData(e, 'track_genre_id');
            }}
            itemData={getGenreCodeListRes}
          />
          <ErrorMessage formError={formError.track_genre_id} />
          <HDivider margin="14px" type="TRANSPARENT" />
          <Label htmlFor="track_artist_name" required={true}>
            아티스트
          </Label>
          <HDivider margin="5.5px" type="TRANSPARENT" />
          <Row
            justify="FLEX_START"
            style={{
              alignItems: 'flex-start',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            <ArtistBox
              artist={formData?.track_artist?.filter((artist: any) => artist.role_id === 90)}
              roleId={90}
              searchResult={setArtistAddResult}
              deleteHandler={deleteArtist}
            ></ArtistBox>
          </Row>
          {/* <ErrorMessage formError={formError.track_artist} /> */}

          <HDivider margin="8px" type="TRANSPARENT" />

          <Label htmlFor="featuring_artist">피쳐링 아티스트</Label>
          <Stack direction="row" alignItems="center" gap="5px">
            <InfoOutlinedIcon fontSize="small" sx={{ color: '#5b5b5b' }} />
            <Text
              style={{
                fontSize: '13px',
                fontWeight: 400,
                color: '#5b5b5b',
              }}
              textType="DESCRIPTION"
            >
              피쳐링 아티스트가 있는 경우, 추가해 주세요. 미 입력 시, 해외 플랫폼에서 정보가 누락될 수 있습니다.
            </Text>
          </Stack>
          <HDivider margin="5.5px" type="TRANSPARENT" />
          <Row
            justify="FLEX_START"
            style={{
              alignItems: 'flex-start',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            <ArtistBox
              artist={formData?.track_artist?.filter((artist: any) => artist.role_id === 91)}
              roleId={91}
              searchResult={setArtistAddResult}
              deleteHandler={deleteArtist}
            ></ArtistBox>
          </Row>

          <HDivider margin="8px" type="TRANSPARENT" />
          <Label htmlFor="track_lyricist_name" required={true}>저작자</Label>
          <Stack direction="row" alignItems="center" gap="5px">
            <InfoOutlinedIcon fontSize="small" sx={{ color: '#5b5b5b' }} />
            <Text
              style={{
                fontSize: '13px',
                fontWeight: 400,
                color: '#5b5b5b',
              }}
              textType="DESCRIPTION"
            >
               하기 저작자 중 1가지는 필수로 입력해 주세요.
            </Text>
          </Stack>
          <div style={{marginLeft:'20px'}}>
            <Label htmlFor="track_lyricist_name">작사</Label>
            <HDivider margin="5.5px" type="TRANSPARENT" />
            <Row
              justify="FLEX_START"
              style={{
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              <ArtistBox
                artist={formData?.track_artist?.filter((artist: any) => artist.role_id === 97)}
                roleId={97}
                searchResult={setArtistAddResult}
                deleteHandler={deleteArtist}
              ></ArtistBox>
            </Row>
            <HDivider margin="14px" type="TRANSPARENT" />
            <Label htmlFor="track_composer_name">작곡</Label>
            <HDivider margin="5.5px" type="TRANSPARENT" />
            <Row
              justify="FLEX_START"
              style={{
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              <ArtistBox
                artist={formData?.track_artist?.filter((artist: any) => artist.role_id === 96)}
                roleId={96}
                searchResult={setArtistAddResult}
                deleteHandler={deleteArtist}
              ></ArtistBox>
            </Row>
            <HDivider margin="14px" type="TRANSPARENT" />
            <Label htmlFor="track_arranger_name">편곡</Label>
            <HDivider margin="5.5px" type="TRANSPARENT" />
            <Row
              justify="FLEX_START"
              style={{
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              <ArtistBox
                artist={formData?.track_artist?.filter((artist: any) => artist.role_id === 98)}
                roleId={98}
                searchResult={setArtistAddResult}
                deleteHandler={deleteArtist}
              ></ArtistBox>
            </Row>
            <HDivider margin="14px" type="TRANSPARENT" />
            <Label required={true}>참여 아티스트</Label>
            <HDivider margin="2px" type="TRANSPARENT" />
            <Stack direction="row" gap="5px">
              <InfoOutlinedIcon fontSize="small" sx={{ color: '#5b5b5b' }} />
              <Typography fontSize="13px" color="#5b5b5b">
                하기 참여 아티스트 중 1가지는 필수로 입력해 주세요.
                <br />
                해당 참여 아티스트가 없는 경우, 메인 아티스트를 가장 유사한 역할에 입력해 주세요.
              </Typography>
            </Stack>
            <Label htmlFor="track_arranger_name">그래픽 디자이너</Label>
            <HDivider margin="5.5px" type="TRANSPARENT" />
            <Row
              justify="FLEX_START"
              style={{
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              <ArtistBox
                artist={formData?.track_artist?.filter((artist: any) => artist.role_id === 617)}
                roleId={617}
                searchResult={setArtistAddResult}
                deleteHandler={deleteArtist}
              ></ArtistBox>
            </Row>
            <HDivider margin="8px" type="TRANSPARENT" />
            <Label htmlFor="track_arranger_name">믹싱 엔지니어</Label>
            <HDivider margin="5.5px" type="TRANSPARENT" />
            <Row
              justify="FLEX_START"
              style={{
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              <ArtistBox
                artist={formData?.track_artist?.filter((artist: any) => artist.role_id === 618)}
                roleId={618}
                searchResult={setArtistAddResult}
                deleteHandler={deleteArtist}
              ></ArtistBox>
            </Row>
            <HDivider margin="8px" type="TRANSPARENT" />
            <Label htmlFor="track_arranger_name">레코드 엔지니어</Label>
            <HDivider margin="5.5px" type="TRANSPARENT" />
            <Row
              justify="FLEX_START"
              style={{
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              <ArtistBox
                artist={formData?.track_artist?.filter((artist: any) => artist.role_id === 620)}
                roleId={620}
                searchResult={setArtistAddResult}
                deleteHandler={deleteArtist}
              ></ArtistBox>
            </Row>
            <HDivider margin="8px" type="TRANSPARENT" />
            <Label htmlFor="track_arranger_name">프로듀서</Label>
            <HDivider margin="5.5px" type="TRANSPARENT" />
            <Row
              justify="FLEX_START"
              style={{
                alignItems: 'flex-start',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              <ArtistBox
                artist={formData?.track_artist?.filter((artist: any) => artist.role_id === 619)}
                roleId={619}
                searchResult={setArtistAddResult}
                deleteHandler={deleteArtist}
              ></ArtistBox>
            </Row>
          </div>


          <HDivider margin="8px" type="TRANSPARENT" />
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
            <Stack sx={{ width: '100%' }}>
              <Label htmlFor="is_remake">리메이크한 곡인가요?</Label>
              <HDivider margin="5px" type="TRANSPARENT" />
              <Radio
                id="is_remake"
                onChange={(e: ChangeEvent) => setAlbumRegistrationFormData(e, 'is_remake')}
                options={[
                  {
                    label: '예',
                    value: 'true',
                  },
                  {
                    label: '아니오',
                    value: 'false',
                  },
                ]}
                value={formData?.is_remake || 'false'}
              />
              {formData?.is_remake === 'true' && (
                <>
                  <HDivider margin="9.5px" type="TRANSPARENT" />
                  <SimpleFile
                    description="원 저작자 서명 완료 개작동의서 업로드"
                    fileList={formData?.remakeFiles}
                    getInputProps={getRemakeInputProps}
                    getRootProps={getRemakeRootProps}
                    style={{ minHeight: '48px' }}
                  />
                  <HDivider margin="5px" type="TRANSPARENT" />
                  <Text
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: '#3B3B3B',
                    }}
                    textType="DESCRIPTION"
                  >
                    리메이크 음원 발매 시, 개작 동의서 날인본(친필서명도 가능)을 첨부해 주세요.
                  </Text>
                  <Link
                    href="https://www.komca.or.kr/form_trust/%EC%A0%80%EC%9E%91%EB%AC%BC%EB%93%B1%EB%A1%9D_%EA%B0%9C%EC%9E%91%EB%8F%99%EC%9D%98%EC%84%9C(%EC%A0%80%EC%9E%91%EB%AC%BC%EB%93%B1%EB%A1%9D%EC%9A%A9).pdf"
                    underline="always"
                    target="_blank"
                    style={{
                      fontSize: '12px',
                      color: '#121212',
                    }}
                  >
                    개작 동의서 (저작물 등록용) 파일 다운로드
                  </Link>
                </>
              )}
            </Stack>

            <Stack sx={{ width: '100%' }}>
              <Label htmlFor="is_owner">외부에서 구매한 비트 혹은 샘플을 사용했나요?</Label>
              <HDivider margin="5px" type="TRANSPARENT" />
              <Radio
                id="is_owner"
                onChange={(e: ChangeEvent) => {
                  setAlbumRegistrationFormData(e, 'is_owner');
                }}
                options={[
                  {
                    label: '예',
                    value: 'true',
                  },
                  {
                    label: '아니오',
                    value: 'false',
                  },
                ]}
                value={formData?.is_owner || 'false'}
              />
              {formData?.is_owner === 'true' && (
                <>
                  <HDivider margin="9.5px" type="TRANSPARENT" />
                  <SimpleFile
                    description="독점 소유 비트 인증 파일 업로드<br />비트 구매 관련 라이선스, 서류 파일 업로드"
                    fileList={formData?.ownerFiles}
                    getInputProps={getOwnerInputProps}
                    getRootProps={getOwnerRootProps}
                    onClickDelete={handleClickDeleteOwnerFile}
                    style={{ minHeight: '48px' }}
                  />
                </>
              )}
            </Stack>
          </Stack>
          <HDivider margin="14px" type="TRANSPARENT" />
          {/* 언어선택 */}
          <Label htmlFor="language_id" required={true} style={{ marginBottom: '5px' }}>
            언어
          </Label>
          <Select
            onChange={(e: ChangeEvent) => {
              setAlbumRegistrationFormData(e, 'language_id', true);
            }}
            options={getLanguageCodeListRes?.map((item: any) => ({
              label: item.code_name,
              value: item.id,
            }))}
            placeholder="언어 선택"
            value={formData.language_id ?? ''}
          />
          <ErrorMessage formError={formError.language_id} />

          <HDivider margin="14px" type="TRANSPARENT" />
          <Label htmlFor="lyrics">가사</Label>
          <HDivider margin="5.5px" type="TRANSPARENT" />
          <Textarea
            maxLength={10000}
            onChange={(e: any) => {
              setAlbumRegistrationFormData(e, 'lyrics');
            }}
            placeholder="- 가사에 선정적인 내용 및 욕설이 포함되어 있는 경우, 19금 체크 부탁드립니다.&#13;&#13;
            - 가사에 선정적인 내용 및 욕설이 포함되어 있으나 19금 체크가 안 되어 있는 경우,
            mixtape. 정책에 따라 19금으로 설정되거나 가사 서비스가 어려울 수 있습니다.&#13;&#13;
            - 상표명이 가사에 포함되는 경우, 각 플랫폼 정책에 따라 가사 서비스가 어려울 수 있습니다."
            style={{ height: '232px' }}
            value={formData.lyrics}
          ></Textarea>
          <ErrorMessage formError={formError.lyrics} />
          <HDivider margin="70px" type="TRANSPARENT" />
        </Stack>
        <MusicVideoAddDialog open={isOpenAddMusicVedioModal} openHanlder={setIsOpenAddMusicVedioModal} callback={setMVFormData} />

        {/* <MVAddDialog */}
        {/*   isOpenAddMusicVedioModal={isOpenAddMusicVedioModal} */}
        {/*   reduxForm={reduxForm} */}
        {/*   setIsOpenAddMusicVedioModal={setIsOpenAddMusicVedioModal} */}
        {/* /> */}
        {/* 아티스트 */}

        {/* {getTrackArtistRoleCodeListRes?.map((role: any) => {
          return (
            <ArtistAddDialog
              key={role.id}
              isOpenArtistAddModal={uc_artistAddOpen}
              setIsOpenArtistAddModal={uf_setAristAddOpen}
              mode="S001"
              setResult={setArtistAddResult}
              roleId={role.id}
              partTypeId={role.id === 90 ? 84 : 85}
            />
          );
        })} */}
        {/* <ArtistAddDialog
          isOpenArtistAddModal={uc_artistAddOpen}
          setIsOpenArtistAddModal={uf_setAristAddOpen}
          mode="S001"
          setResult={setArtistAddResult}
          roleId={90}
          partTypeId={role.id === 90 ? 84 : 85}
        /> */}
        {/* 작곡가 */}
        {/* <ArtistAddDialog
          isOpenArtistAddModal={uc_composerAddOpen}
          setIsOpenArtistAddModal={uf_setComposerAddOpen}
          mode="S001"
          setResult={setComposerAddResult}
        /> */}

        {/* 작사가 */}
        {/* <ArtistAddDialog
          isOpenArtistAddModal={uc_writerAddOpen}
          setIsOpenArtistAddModal={uf_setWriterAddOpen}
          mode="S001"
          setResult={setWriterAddResult}
        /> */}

        {/* 편곡자 */}
        {/* <ArtistAddDialog
          isOpenArtistAddModal={uc_arrangerAddOpen}
          setIsOpenArtistAddModal={uf_setArrangerAddOpen}
          mode="S001"
          setResult={setArrangerAddResult}
        /> */}
      </Stack>
    </MacScrollbar>
  );
}

export default TrackEditorBox;
