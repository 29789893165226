import React, { useEffect, useState } from 'react';

import { addMusicVideo } from '@api/mapper/AlbumManagement';
import Modal from '@components/form/Modal';
import { MUSIC_VEDIO_INFO_ADD_ACTION } from '@constants/actionNames';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';

import Completed from './Completed';
import Info from './Info';
import SelectTrack from './SelectTrack';

export type TCurrentStep = 'SELECT_TRACK' | 'INFO' | 'COMPLETED';


function AddMusicVideoModal({tracks, album, isOpenAddMusicVedioModal, setIsOpenAddMusicVedioModal }) {
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('SELECT_TRACK');
  const { request: addMusicVideoReq, resData: addMusicVideoRes } = useRequest({
    reduxActionName: MUSIC_VEDIO_INFO_ADD_ACTION,
    mapperFunc: addMusicVideo,
  });


  const reduxForm = useReduxForm(MUSIC_VEDIO_INFO_ADD_ACTION, (params) => addMusicVideoReq(params));
  const { clearData } = reduxForm;
  const doBeforeClosing = () => {
    setIsOpenAddMusicVedioModal(false)
  }



  useEffect(() => {
    if (!isOpenAddMusicVedioModal) {
      clearData();
      setCurrentStep('SELECT_TRACK');
    }
  }, [isOpenAddMusicVedioModal]);

  return (
    <Modal
      closeButtonStyle={
        currentStep === 'INFO'
          ? {
              paddingBottom: '30px',
            }
          : {}
      }
      headerText={currentStep === 'INFO' ? `아래 안내사항을 확인하시고\n뮤직비디오 파일을 제출해 주세요.` : ''}
      hide={setIsOpenAddMusicVedioModal}
      isShown={isOpenAddMusicVedioModal}
       wrapperStyle={{ width: '600px' }}
      modalStyle={{width:'100%'}}
    >
      <>
        <SelectTrack tracks={tracks} album={album} reduxForm={reduxForm} setCurrentStep={setCurrentStep} />
        {/* {currentStep === 'SELECT_TRACK' && <SelectTrack tracks={tracks} reduxForm={reduxForm} setCurrentStep={setCurrentStep} />} */}
        {/* {currentStep === 'INFO' && <Info reduxForm={reduxForm} setCurrentStep={setCurrentStep} />} */}
        {/* {currentStep === 'COMPLETED' && ( */}
        {/*   <Completed setCurrentStep={setCurrentStep} setIsOpenAddMusicVedioModal={setIsOpenAddMusicVedioModal} /> */}
        {/* )} */}



      </>
    </Modal>
  );
}

export default AddMusicVideoModal;
