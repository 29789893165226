import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { getIsExistId } from '@api/mapper/User';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Label from '@components/form/Label';
import Layout from '@components/form/Layout';
import Text from '@components/form/Text';
import { FindPasswordButton } from '@components/molecules/FindPassword/Button';
import { FindPasswordInputWithErrorText } from '@components/molecules/FindPassword/Input';
import { FindPasswordErrorText } from '@components/molecules/FindPassword/Text';
import { AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION } from '@constants/actionNames';
import { useFormError } from '@hooks/useFormError';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import { getDisabledWithFormError, isEmpty } from '@utils/data';

import { TCurrentStep } from '.';

type TIsExistIdProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  setId?: (id: string) => void;
};

function IsExistId({ setCurrentStep, setId }: TIsExistIdProps) {
  const [isExist, setIsExist] = useState<boolean>(true);
  const { request, resData, errorMessage } = useRequest({
    reduxActionName: AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
    mapperFunc: getIsExistId,
  });

  const { formData, formFieldsError, validation, setFormData, handleSubmit } = useReduxForm(
    AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
    (params) => request(params),
  );

  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const setFindPasswordIsExistIdFormData = (e: any, dataIndex: string) => {
    const { value } = e.target;
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
    setId(value);

    if (!isExist) {
      setIsExist(true);
    }
  };

  const getIsDisabledIsExistBtn = () => {
    if (isExist) {
      const isDisabled = getDisabledWithFormError(formError, ['user_id']);

      if (isDisabled) {
        return true;
      }
    }

    return false;
  };

  const handleClickFindPasswordIsExistId = (e: any) => {
    e.preventDefault();
    handleSubmit();
  };

  useEffect(() => {
    if (resData) {
      setIsExist(true);
      setCurrentStep('AUTH_NUMBER');
    }
    if (errorMessage) {
      setIsExist(false);
    }
  }, [resData, errorMessage]);

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="9px" type="TRANSPARENT" />
        <Text textType="DESCRIPTION">비밀번호를 재설정할 아이디를 입력해 주세요.</Text>
        <HDivider margin="8px" type="TRANSPARENT" />
        <Label htmlFor="user_id">아이디</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <FindPasswordInputWithErrorText
          id="user_id"
          isError={!isExist}
          onChange={(e: ChangeEvent) => {
            setFindPasswordIsExistIdFormData(e, 'user_id');
          }}
          placeholder="아이디"
          type="text"
          value={formData.user_id ?? ''}
        />
        <HDivider margin="5px" type="TRANSPARENT" />
        {!isExist && <FindPasswordErrorText>존재하지 않는 아이디입니다.</FindPasswordErrorText>}
        <HDivider margin="15px" type="TRANSPARENT" />
        <FindPasswordButton disabled={getIsDisabledIsExistBtn()} onClick={handleClickFindPasswordIsExistId}>
          아이디 입력
        </FindPasswordButton>
      </Form>
    </Layout>
  );
}

export default IsExistId;
