import React, { useState } from 'react';

import Button from '@components/form/Button';
import Row from '@components/form/Row';
import { Icon } from '@images/Icon';

import VDivider from './VDivider';

type TImageFileProps = {
  getRootProps: any;
  getInputProps: any;
  description: any;
  fileList: Array<any>;
  isExistDescription?: any;
};

function ImageFile({ getRootProps, getInputProps, description, isExistDescription, fileList }: TImageFileProps) {
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  if (fileList?.length) {
    return (
      <>
        <Row
          align="center"
          direction="row"
          justify="CENTER"
          onMouseEnter={() => {
            setIsMouseEnter(true);
          }}
          onMouseLeave={() => {
            setIsMouseEnter(false);
          }}
        >
          <img
            alt="preview"
            onLoad={() => {
              URL.revokeObjectURL(fileList[0]?.preview);
            }}
            onDragEnter={(e) => {
              e.stopPropagation();
              setIsMouseEnter(true);
            }}
            onDragLeave={(e) => {
              e.stopPropagation();
              // setIsMouseEnter(false);
            }}
            src={fileList[0]?.preview}
            style={{
              borderRadius: '10px',
              width: '320px',
              height: '320px',
            }}
          />
          {isMouseEnter && (
            <Row
              align="center"
              justify="CENTER"
              {...getRootProps({ className: 'dropzone' })}
              style={{
                background: 'rgba(0, 0, 0, 0.6)',
                border: '1px dashed #DDDDDD',
                borderRadius: '10px',
                color: '#FFFFFF',
                fontSize: '14px',
                fontFamily: 'Pretendard',
                textAlign: 'center',
                cursor: 'pointer',
                width: '320px',
                minHeight: '320px',
                position: 'absolute',
                // top: '110px',
              }}
            >
              <input {...getInputProps()} />
              {isExistDescription || description}
            </Row>
          )}
        </Row>
      </>
    );
  }
  return (
    <Row
      align="center"
      justify="CENTER"
      {...getRootProps({ className: 'dropzone' })}
      style={{
        border: '1px dashed #DDDDDD',
        borderRadius: '10px',
        color: '#777',
        fontSize: '14px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '320px',
        fontFamily: 'Pretendard',
        minHeight: '320px',
      }}
    >
      <input {...getInputProps()} />
      {description}
    </Row>
  );
}

export default ImageFile;
