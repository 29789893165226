import Layout from '@components/form/Layout';
import styled from '@emotion/styled';

export const AlbumManagementAlbums = styled(Layout)`
  padding-bottom: 100px;
  background: #1e1e1e;
  color: #fff;
  padding-left: calc(36% - 360px);
  padding-right: calc(36% - 360px);
`;

export const AlbumManagementReleased = styled(Layout)`
  background: #1e1e1e;
  color: #fff;
  padding-left: calc(36% - 360px);
  padding-right: calc(36% - 360px);
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
`;

export const AlbumManagementProgressBox = styled(Layout)`
  background: #000;
  padding: 150px calc(36% - 360px) 60px calc(36% - 360px);
  color: #fff;
  flex-direction: row;
`;

export const AlbumManagementInfoInProgress = styled(Layout)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0.6;
`;

export const AlbumManagementImageInProgress = styled(Layout)`
  padding-top: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0.4;
`;

export const ReleaseFirstAlbumLayout = styled(Layout)`
  padding-top: 190px;
  padding-bottom: 120px;
  background: #1e1e1e;
  color: #fff;
`;

export const SignUpMembershipForReleaseLayout = styled(Layout)`
  padding-top: 190px;
  padding-bottom: 120px;
  background: #1e1e1e;
  color: #fff;
`;

export const AlbumDetailLayout = styled(Layout)`
  padding-top: 150px;
  background: transparent;
  color: #fff;
  justify-content: space-between;
  flex-direction: row;
  padding-left: calc(20% - 364px);
  padding-right: calc(20% - 364px);
  align-items: flex-start;
`;
