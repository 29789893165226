import Button from '@components/form/Button';
import styled from '@emotion/styled';

export const ShowMoreButton = styled(Button)`
  position: relative;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  text-decoration-line: underline;

  /* color/primary/lemon */

  color: #effd60;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  height: 0;
  background: transparent;
`;

export const DownloadGuideBookButton = styled(Button)`
  background: transparent;
  height: auto;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  text-decoration-line: underline;

  /* color/121212 */

  color: #121212;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const CancelUpdateProfileButton = styled(Button)`
  padding: 17px 36px;
  background: transparent;
  height: auto;
  border: 1px solid #121212;

  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  /* color/121212 */

  color: #121212;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ConfirmUpdateProfileButton = styled(Button)`
  padding: 17px 36px;
  border-radius: 10px;
  height: auto;
`;
