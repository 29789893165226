import React from 'react';

import styled from '@emotion/styled';
import { MacScrollbar } from 'mac-scrollbar';

const ContainerBox = styled(MacScrollbar)`
  height: 240px;
  width: 100%;
  //max-width: 394px;
  padding: 10px 0;
  background: #f5f5f5;
  border-radius: 10px;
  overflow: scroll;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: #121212;
`;

const DivBox = styled.div`
  height: 240px;
  width: 100%;
  //max-width: 394px;
  padding: 30px 0;
  background: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: #121212;
`;

function TermsBox({ children, ...rest }: { children: JSX.Element; style?: any }) {
  return <ContainerBox {...rest}>{children}</ContainerBox>;
}

export default TermsBox;

export function TermsBox2({ children, ...rest }: { children: JSX.Element; style?: any }) {
  return <DivBox {...rest}>{children}</DivBox>;
}
