import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { getAuthNumber } from '@api/mapper/User';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Input from '@components/form/Input';
import InputPrefixSuffix from '@components/form/InputPrefixSuffix';
import Label from '@components/form/Label';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Select from '@components/form/Select';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { FindPasswordButton, GetAuthNumberButton, ResendAuthNumberButton } from '@components/molecules/FindPassword/Button';
import { ReConfirmNumberText } from '@components/molecules/FindPassword/Text';
import { USER_INFO_EDIT_DORMANT_ACCOUNT_ACTION } from '@constants/actionNames';
import { BANK_LIST } from '@constants/enum';
import { useFormError } from '@hooks/useFormError';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import { Icon } from '@images/Icon';
import { isEmpty } from '@utils/data';

import { TCurrentStep } from '.';

type TAuthNumberCertificationProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  isShowAuthNumberInput: boolean;
  setIsShowAuthNumberInput: any;
};

function AuthNumberCertification({
  setCurrentStep,
  isShowAuthNumberInput,
  setIsShowAuthNumberInput,
}: TAuthNumberCertificationProps) {
  const { request: getAuthNumberReq, resData: getAuthNumberRes } = useRequest({
    reduxActionName: USER_INFO_EDIT_DORMANT_ACCOUNT_ACTION,
    mapperFunc: getAuthNumber,
  });

  const { formData, formFieldsError, validation, setFormData, handleSubmit } = useReduxForm(
    USER_INFO_EDIT_DORMANT_ACCOUNT_ACTION,
    (params) => getAuthNumberReq(params),
  );

  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const setEditDormantAccountFormData = (e: any, dataIndex: string, isValue?: boolean) => {
    let value = '';
    if (isValue) {
      value = e;
    } else {
      value = e.target.value;
    }
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
  };

  const getIsDisabledAuthNumBtn = () => {
    const { bank, accountNumber, accountUser } = formError;
    if (!isEmpty(bank) || !isEmpty(accountNumber) || !isEmpty(accountUser)) {
      return true;
    }
    if (bank === undefined || accountNumber === undefined || accountUser === undefined) {
      return true;
    }
    return false;
  };

  const handleClickGetAuthNumber = (e: any) => {
    e.preventDefault();
    getAuthNumberReq({
      ...formData,
    });
    setIsShowAuthNumberInput(true);
  };

  const handleClickEditDormantAccount = (e: any) => {
    e.preventDefault();
    handleSubmit();
    setCurrentStep('COMPLETED');
  };

  useEffect(() => {
    if (getAuthNumberRes && getAuthNumberRes.code !== "404") {
      setIsShowAuthNumberInput(true);
    }
  }, [getAuthNumberRes]);

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="9px" type="TRANSPARENT" />
        <Text textType="DESCRIPTION" style={{marginBottom:'5px'}}>음원 수익을 지급받을 계좌 정보를 입력해 주세요.</Text>
        <Text textType="DESCRIPTION" style={{marginBottom:'5px'}}>회원가입 시 입력한 이름과 예금주명은 동일해야 합니다.</Text>
        <Text textType="DESCRIPTION"> 계좌 정보는 1년에 1회만 수정 가능합니다.</Text>
        <HDivider margin="8px" type="TRANSPARENT" />
        <Label htmlFor="bank">은행 선택</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <Select
          onChange={(e: ChangeEvent) => {
            setEditDormantAccountFormData(e, 'bank', true);
          }}
          options={BANK_LIST}
          placeholder="은행선택"
          value={formData.bank ?? ''}
        />
        <HDivider margin="8px" type="TRANSPARENT" />
        <Label htmlFor="accountNumber">계좌번호 입력</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <Input
          id="accountNumber"
          onChange={(e: ChangeEvent) => {
            setEditDormantAccountFormData(e, 'accountNumber');
          }}
          placeholder="숫자만 입력가능합니다."
          type="text"
          value={formData.accountNumber ?? ''}
        />
        <HDivider margin="10px" type="TRANSPARENT" />
        <Label htmlFor="accountUser">예금주</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <Row justify="SPACE_BETWEEN">
          <Input
            id="accountUser"
            onChange={(e: ChangeEvent) => {
              setEditDormantAccountFormData(e, 'accountUser');
            }}
            placeholder="예금주"
            style={{ flex: 7 }}
            type="text"
            value={formData.accountUser ?? ''}
          />
          <VDivider margin="5px" type="TRANSPARENT" />
          <GetAuthNumberButton
            disabled={getIsDisabledAuthNumBtn()}
            onClick={(e) => {
              e.preventDefault();
              setIsShowAuthNumberInput(true);
              getAuthNumberReq({
                ...formData,
              });
            }}
            style={{ flex: 3 }}
          >
            계좌인증
          </GetAuthNumberButton>
        </Row>
        {isShowAuthNumberInput && (
          <>
            <HDivider margin="10px" type="TRANSPARENT" />
            <Label htmlFor="authCode">계좌 인증코드</Label>
            <HDivider margin="6px" type="TRANSPARENT" />
            <InputPrefixSuffix
              id="authCode"
              onChange={(e: ChangeEvent) => {
                setEditDormantAccountFormData(e, 'authCode');
              }}
              placeholder="인증코드 입력"
              prefix={
                <>
                  <div
                    style={{
                      paddingRight: 0,
                      fontWeight: 400,
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: '#CDCDCD',
                    }}
                  >
                    먹태
                  </div>
                </>
              }
              suffix={
                <>
                  <div className="time">00:00</div>
                  <div>
                    <Icon icon="btnUnCheck" />
                  </div>
                </>
              }
              type="number"
              value={formData.authCode ?? ''}
            />
            <HDivider margin="5px" type="TRANSPARENT" />
            <Row justify="FLEX_START">
              <ReConfirmNumberText>인증코드를 다시 확인해 주세요</ReConfirmNumberText>
              <VDivider margin="8px" type="TRANSPARENT" />
              <ResendAuthNumberButton onClick={handleClickGetAuthNumber}>인증코드 재전송</ResendAuthNumberButton>
            </Row>
          </>
        )}
        <HDivider margin="15px" type="TRANSPARENT" />
        <FindPasswordButton onClick={handleClickEditDormantAccount}>계좌 정보 변경</FindPasswordButton>
      </Form>
    </Layout>
  );
}

export default AuthNumberCertification;
