import { $mixtapeGetUser } from '@utils/auth';
import React, { useState } from 'react';

import Modal from '@components/form/Modal';
import { Radio, Stack, Typography } from '@mui/material';
import MixButton from '@components/ui/MixButton';
import VDivider from '@components/form/VDivider';
import styled from '@emotion/styled';

type TChangeMembershipModalProps = {
  membership: any;
  setIsOpenChangeMembershipInfoModal: () => void;
  isOpenChangeMembershipInfoModal: boolean;
  callback: () => void;
};

const Guide = styled.ul`
  width: 100%;
  padding: 20px;
  margin-bottom: 50px;
  border-radius: 12px;
  background-color: #f6f6f6;

  & li {
    position: relative;
    padding-left: 16px;
    margin-bottom: 8px;
    font-size: 14px;
    color: #767676;

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: black;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ChangeMembershipInfoModal = ({
  membership,
  isOpenChangeMembershipInfoModal,
  setIsOpenChangeMembershipInfoModal,
  callback,
}: TChangeMembershipModalProps) => {
  const user = $mixtapeGetUser()
  return (
    <Modal
      modalStyle={{ width: '660px' }}
      closeButtonStyle={{ width: '24px', heigth: '24px' }}
      hide={setIsOpenChangeMembershipInfoModal}
      isShown={isOpenChangeMembershipInfoModal}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" padding="0 40px">
        <Typography fontSize="34px" fontWeight="bold" mb="50px">
          멤버십 변경
        </Typography>
        <Typography fontSize="24px" color="#767676" textAlign="center" mb="30px" lineHeight="40px">
          <span style={{ fontWeight: 'bold', color: '#191919' }}>{membership.goods_price.toLocaleString()}원</span> 추가 결제로
          <br /> 멤버십 업그레이드가 가능합니다.
        </Typography>
        <Typography fontSize="18px" color="#767676" mb="30px" lineHeight="30px">
          📅 &nbsp; Basic 멤버십 잔여기간 : {membership.remain_date}일
          <br />➖ &nbsp; 환불 예정액 : <span style={{ color: '#FF3120' }}>{membership.return_amount.toLocaleString()}원</span>
          <br />➕ &nbsp; 신규 결제액 : <span style={{ fontWeight: 'bold', color: '#191919' }}>{user.pro_yn === 'N' ? '88,000원(11% 할인)' : '연 99,000원'} </span>
        </Typography>
        <Typography fontSize="16px" color="#767676" mb="60px" lineHeight="27px">
          ▪ 해당 할인가 적용 기간은 1년이며 다음 결제일에는 정상가로 결제됩니다.
          <br />▪ 해당 멤버십을 중도 해지하시는 경우, 추후 동일 혜택 적용이 어렵습니다.
        </Typography>
        <Guide>
          <li>현재 이용 중인 Basic 멤버십은 잔여 기간에 따라 일할 계산하여 부분 환불 처리 후, Pro 멤버십 요금이 결제됩니다.</li>
          <li>Pro 멤버십 이용 기간은 변경 신청일로부터 1년이며 멤버십 종료일에 정기결제가 진행됩니다.</li>
        </Guide>
        <Stack direction="row" gap="22px" mb="35px">
          <MixButton
            onClick={() => {
              setIsOpenChangeMembershipInfoModal();
            }}
            text="닫기"
            butonType="OUTLINE"
            padding="22px 124px"
            bgColor="white"
            fontColor="black"
          />
          <MixButton
            onClick={() => {
              callback();
            }}
            text="변경"
            padding="22px 124px"
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ChangeMembershipInfoModal;
