import MainFooter from '@components/molecules/MainFooter';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import AuthHeader from '@components/molecules/AuthHeader';

import { routePath } from '@constants/path';

import { getAccessToken } from '@utils/auth';
import history from '@utils/history';

import Finish from './Finish';
import Info from './Info';
import Terms from './Terms';
import Membership from '@containers/Login/Membership';
import MainHeader from '@components/molecules/MainHeader';

export type TCurrentStep = 'TERMS' | 'INFO' | 'FINISH';

const FORM_VALIDATION_FIELD_COUNT = 2;

function SignUp(): ReactElement {
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('TERMS');
  // const [currentStep, setCurrentStep] = useState<TCurrentStep>('FINISH');
  // const { data: termsData } = useReduxData(AUTH_SIGN_UP_TERMS_ACTION);

  useEffect(() => {
    if (getAccessToken()) {
      const redirectUri = routePath.album.albumManagement.path;
      history.push(redirectUri);
    }
  }, []);

  const handleShowCurrentComponent = () => {
    if (currentStep === 'INFO') {
      return (
        <>
          <AuthHeader logo="logoW" />
          <Info setCurrentStep={setCurrentStep} />
        </>
      );
    }
    if (currentStep === 'FINISH') {
      return (
        <>
          <MainHeader logo="logoW" />
          <Membership />
        </>
      );
    }

    return (
      <>
        <AuthHeader logo="logoW" />
        <Terms setCurrentStep={setCurrentStep} />
      </>
    );
  };

  return (
    <>
      {handleShowCurrentComponent()}
      <MainFooter />
    </>
  );
}

export const checkOnlyNumber = (e) => {
  if (
    !/\d/.test(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'Delete' &&
    e.key !== 'Tab'
  ) {
    e.preventDefault();
  }
};
export default SignUp;
