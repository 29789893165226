import history from '@utils/history';
import { connectRouter } from 'connected-react-router';
import { combineReducers, Reducer } from 'redux';

import globalReducer from './globalReducer';

const createReducer = (asyncReducers?: Reducer): Reducer =>
  combineReducers({
    globalReducer,
    router: connectRouter(history),
    ...asyncReducers,
  });

export default createReducer;
