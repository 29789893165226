import React, { useState } from 'react';

import Button from '@components/form/Button';
import Row from '@components/form/Row';
import { Icon } from '@images/Icon';

import VDivider from './VDivider';

type TImageFileProps = {
  getRootProps: any;
  getInputProps: any;
  description: any;
};

function TrackFile({ getRootProps, getInputProps, description }: TImageFileProps) {
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  return (
    <Row
      align="center"
      justify="CENTER"
      {...getRootProps({ className: 'dropzone' })}
      style={{
        border: '1px dashed #DDDDDD',
        borderRadius: '10px',
        color: '#FFF',
        fontSize: '14px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '100%',
      }}
    >
      <input {...getInputProps()} />
      {description}
    </Row>
  );
}

export default TrackFile;
