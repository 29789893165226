import { BaseSelect } from '@components/form/ChipSelect';
import { AlbumContext } from '@containers/App';
import { useModal } from '@hooks/useModal';
import { MenuItem, Tooltip } from '@mui/material';
import React, { ChangeEvent, Dispatch, SetStateAction, useContext } from 'react';
import MusicVideoAddDialog from '@components/form/MusicVideoAddDialog';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Select from '@components/form/Select';
import Text from '@components/form/Text';
import { ConfirmButton } from '@components/molecules/FindPassword/Button';
import { useFormError } from '@hooks/useFormError';

import { TCurrentStep } from '.';

function SelectTrack({
  album,
                       tracks,
                       setCurrentStep,
                       reduxForm,
                     }) {

  const {
    ctf_setAlbumInfo,
  } = useContext(AlbumContext);


  const {
    formData,
    setFormData,
    formFieldsError,
    validation,
  } = reduxForm;
  const {
    isShown: isOpenAddMusicVedioModal,
    toggle: setIsOpenAddMusicVedioModal,
  } = useModal();
  const { checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const confirm = () => {
    setIsOpenAddMusicVedioModal(true)
    // setCurrentStep('INFO');
  };

  const setAddMusicVideoFormData = (e: any, dataIndex: string, isValue?: boolean) => {
    let value = '';
    if (isValue) {
      value = e;
    } else {
      value = e.target.value;
    }
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
  };

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin='12px' type='TRANSPARENT' />
        <Row justify='CENTER'>
          <Text textType='DESCRIPTION'>뮤직비디오를 추가 제출하려는 트랙 번호를 선택해 주세요.</Text>
        </Row>
        <HDivider margin='15px' type='TRANSPARENT' />
        <BaseSelect
          value={formData.track_id ?? ''}
          onChange={(e: ChangeEvent) => {
            setAddMusicVideoFormData(e, 'track_id');
          }}
          placeholder='트랙 선택'

          >
          {tracks?.map((item: any) => {
            const disabled = item.musicvideos !== false
            if(disabled){
              return (
                <Tooltip title='뮤직비디오는 트랙당 한 개만 제출가능합니다.'>
                  <div>
                  <MenuItem disabled={true} value={item.id}>{`${item.track_no}. ${item.track_name}`}</MenuItem>
                  </div>
                </Tooltip>
                )

            }else{
              return (<MenuItem value={item.id}>{`${item.track_no}. ${item.track_name}`}</MenuItem>)
            }

        })}

        </BaseSelect>



        <HDivider margin='21px' type='TRANSPARENT' />
        <ConfirmButton
          disabled = {!formData.track_id}
          onClick={() => {
            confirm();
          }}
        >
          다음
        </ConfirmButton>
      </Form>
      <MusicVideoAddDialog
        open={isOpenAddMusicVedioModal}
        releaseDate={album.release_date}
        openHanlder={setIsOpenAddMusicVedioModal}
        update={true}
        trackId={formData.track_id}
        callback={()=>{
          ctf_setAlbumInfo({ refreshDetail: true });
        }
        }
      />
    </Layout>
  );
}

export default SelectTrack;
