import MainHeader from '@components/molecules/MainHeader';
import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { Button, ButtonGroup, Pagination, Stack, ThemeProvider, Typography, createTheme } from '@mui/material';
import MainFooter from '@components/molecules/MainFooter';
import { MixGrid } from '@components/ui/MixGrid';
import styled from '@emotion/styled';
import { BackdropContext } from '@hooks/usePopupMessage';
import Input from '@components/form/Input';
import moment from 'moment';
import { useHistory } from 'react-router';
import SearchIcon from '@mui/icons-material/Search';

const NoticeFilterButtonGroup = styled(ButtonGroup)`
	justify-content: center;
	& button {
		width: 256px;
		border-radius: 0;
		font-size: 14px;
		padding-top: 13px;
		padding-bottom: 13px;
	}
`;

const SelectRow = styled(Stack)({
	marginBottom: '30px',
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: 'white',
		height: '38px'
	}
});

const StyledInputBox = styled.div`
	border: 1px solid #fff;
	border-radius: 6px;
	display: flex;
	align-items: center;
	background: #1d1d1d;
	overflow: hidden;
	padding: 0 !important;
`;

interface INoticeGridParams {
	noticeList: any;
	pageInfo: any;
}

const NoticeGrid = ({ noticeList, pageInfo }: INoticeGridParams) => {
	const history = useHistory();

	const getPostNumber = (index, curPage, total, perpage) => {
		const startNumber = total - (curPage - 1) * perpage;

		return startNumber - index;
	};

	const columns = [
		{
			field: 'null',
			headerName: '',
			width: '2%'
		},
		{
			field: 'id',
			headerName: '번호',
			width: '10%',
			customCell: (row: any, index: number) => {
				return row.top_check ? (
					<span
						style={{
							padding: '4px 10px',
							borderRadius: '11px',
							backgroundColor: '#EEFD60',
							color: 'black',
							fontSize: '12px',
							fontWeight: 'bold'
						}}
					>
						공지
					</span>
				) : (
					<>{pageInfo && <>{getPostNumber(index, pageInfo.current_page, pageInfo.total, pageInfo.per_page)}</>}</>
				);
			}
		},
		{
			field: 'category_name',
			headerName: '분류',
			width: '15%'
		},
		{
			field: 'subject',
			headerName: '제목',
			width: '58%'
		},
		{
			field: 'created_at',
			headerName: '작성일',
			width: '15%',
			formatValue: (value: string) => {
				return moment(value).format('YYYY-MM-DD');
			}
		}
	];
	const onClickRow = (row: any) => {
		history.push({
			pathname: `/notice/${row.id}`
			// state: { ...row },
		});
	};

	return <MixGrid columns={columns} rows={noticeList} pointField="top_check" onClickRow={onClickRow}></MixGrid>;
};

const darkTheme = createTheme({
	typography: {
		fontFamily: 'Pretendard',
		fontWeightBold: 600,
		fontWeightRegular: 300
	},
	palette: {
		mode: 'dark',
		secondary: {
			main: '#EFFD60'
		}
	}
});

const Notice = () => {
	const [curPage, setCurpage] = useState(1);
	const [curCategory, setCategory] = useState(627);
	const [pageInfo, setPageInfo] = useState<{
		current_page: number;
		last_page: number;
		total: number;
		per_page: number;
		isFirst: boolean;
		isLast: boolean;
	}>();
	const [keyword, setKeyword] = useState('');

	const [noticeList, setNoticeList] = useState([]);

	const { ctf_sendHttpGet } = useContext(BackdropContext);

	const getNoticeList = async () => {
		const sessionUserInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')!) : null;

		const result = await ctf_sendHttpGet(sessionUserInfo === null ? `/board` : '/user/board', {
			board_name: 'board_notices',
			page: curPage,
			keyword,
			category_id: curCategory
		});

		setNoticeList(result.data.contents.data);
		setPageInfo(result.data.contents.pageInfo);
	};

	useEffect(() => {
		getNoticeList();
	}, [curPage, curCategory]);

	const changeCurPage = (event: React.ChangeEvent<unknown>, value: number) => {
		setCurpage(value);
	};

	const changeCategory = (id: number) => {
		if (id === curCategory) {
			return;
		}
		setCategory(id);
	};

	const getButtonStyle = (id: number) => {
		const style = {
			backgroundColor: '#1D1D1D',
			color: '#FFFFFF',
			border: '1px solid #fff',
			'&:hover': {
				color: '#fff'
			}
		};

		if (id === curCategory) {
			style.backgroundColor = '#FFFFFF';
			style.color = '#191919';
		}
		return style;
	};

	return (
		<>
			<MainHeader logo="logoW" />
			<ThemeProvider theme={darkTheme}>
				<Stack
					direction="column"
					paddingY="200px"
					color="white"
					justifyContent="center"
					alignItems="center"
					bgcolor="#1E1E1E"
				>
					<Stack direction="column" alignItems="center" style={{ minWidth: '1280px', maxWidth: '1280px' }}>
						<Typography fontSize="50px" fontWeight="bold">
							공지사항
						</Typography>

						<NoticeFilterButtonGroup variant="outlined" color="inherit" fullWidth style={{ margin: '80px 0' }}>
							<Button
								sx={getButtonStyle(627)}
								onClick={() => {
									changeCategory(627);
								}}
							>
								전체
							</Button>
							<Button
								sx={getButtonStyle(628)}
								onClick={() => {
									changeCategory(628);
								}}
							>
								알림/소식
							</Button>
							<Button
								sx={getButtonStyle(630)}
								onClick={() => {
									changeCategory(630);
								}}
							>
								이벤트 당첨
							</Button>
						</NoticeFilterButtonGroup>
						<SelectRow width="100%" direction="row" justifyContent="flex-end" gap="12px">
							<StyledInputBox>
								<Input
									id="keyword"
									type="text"
									placeholder="검색어를 입력해주세요."
									onChange={(e: ChangeEvent) => {
										setKeyword(e.target.value);
									}}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											if (curPage !== 1) {
												setCurpage(1);
											} else {
												getNoticeList();
											}
										}
									}}
									value={keyword}
									style={{
										flexGrow: 1,
										width: '190px',
										height: '14px',
										backgroundColor: '#1D1D1D',
										padding: '12px 20px 12px 20px',
										color: 'white',
										border: 0
									}}
								/>
								<Button
									style={{
										backgroundColor: '#1D1D1D',
										borderWidth: '1px',
										height: '20px'
									}}
									onClick={() => {
										if (curPage !== 1) {
											setCurpage(1);
										} else {
											getNoticeList();
										}
									}}
								>
									<SearchIcon sx={{ color: '#fff' }} />
								</Button>
							</StyledInputBox>
						</SelectRow>

						<NoticeGrid noticeList={noticeList} pageInfo={pageInfo} />

						{pageInfo && pageInfo.total > 0 && (
							<Stack mt="97px">
								<Pagination
									count={Math.ceil(pageInfo.total / pageInfo.per_page)}
									page={curPage}
									onChange={changeCurPage}
									color="secondary"
									shape="rounded"
								/>
							</Stack>
						)}
					</Stack>
				</Stack>
			</ThemeProvider>
			<MainFooter />
		</>
	);
};

export default Notice;
