import { apiRequest } from '@utils/api';

import { TErrorData, TMapper } from './types';

type TGetCodeListParams = {
  parent_code: string;
};

export const getCodeList: TMapper<TGetCodeListParams, any> = async (params) => {
  const result = await apiRequest({
    url: `/codes?parent_code=${params.parent_code}`,
    method: 'GET',
  });

  return result;
};
