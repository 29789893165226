import { BackdropContext } from '@hooks/usePopupMessage';

import React, { ReactElement, useContext, useEffect, useState } from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import Toggle from '@components/form/Toggle';
import VDivider from '@components/form/VDivider';
import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import { EditButton } from '@components/molecules/UserInfoSetting/Button';
import { UserInfoSettingLayout } from '@components/molecules/UserInfoSetting/Layout';
import {
	UserInfoSettingBottomText,
	UserInfoSettingIdText,
	UserInfoSettingInfoText,
	UserInfoSettingPromotionTitle
} from '@components/molecules/UserInfoSetting/Text';

import { routePath } from '@constants/path';
import EditDormantAccountModal from '@containers/UserInfoSetting/EditDormantAccountModal';
import EditEmailModal from '@containers/UserInfoSetting/EditEmailModal';
import EditPasswordModal from '@containers/UserInfoSetting/EditPasswordModal';
import EditPhoneNumberModal from '@containers/UserInfoSetting/EditPhoneNumberModal';
import { useModal } from '@hooks/useModal';

import { Icon } from '@images/Icon';
import { $mixtapeGetUser, $mixtapeSetUser, $mixtapeUserSeq, getAuthInSessionStorage, removeAuth } from '@utils/auth';
import history from '@utils/history';
import EditRrnModal from './EditRrnModal';
import { Button, Stack, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { startTossPayment } from '@utils/toss';
import AlertModal from '@components/form/AlertModal';
import { sendHttpPost } from '@utils/api';
import styled from '@emotion/styled';
import MembershipBenefitModal from './MembershipBenefitModal';
import CancelCompleteModal from './CancelCompleteModal/index';
import MixButton from '@components/ui/MixButton';
import CancelMembershipModal from './CancelMembershipModal';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import moment from 'moment';
import { getCookies, setCookies } from '@utils/cookies';
import ChangeMembershipModal from './ChangeMembershipModal';
import ChangeMembershipInfoModal from './ChangeMembershipInfoModal';
import ChangeCompleteModal from './ChangeCompleteModal';

const CancelButton = styled(Button)`
	width: 159px;
	height: 38px;
	margin-top: 20px;
	margin-left: 37px;
	padding: 10px 34px;
	background-color: #ffffff1a;
	border: 1px solid #7e7e7e;
	font-size: 14px;
	font-weight: 300;
	color: #9b9b9b;
`;

const Membership = ({ userMembership, setAlertText, setIsAlertOpen }: any) => {
	const [membership, setMembership] = useState(userMembership);
	const [membershipType, setMembershipType] = useState(userMembership?.goodsType);
	const { isShown: isOpenChangeMembershipModal, toggle: setIsOpenChangeMembershipModal } = useModal();
	const { isShown: isOpenChangeMembershipInfoModal, toggle: setIsOpenChangeMembershipInfoModal } = useModal();
	const { isShown: isOpenChangeCompleteModal, toggle: setIsOpenChangeCompleteModal } = useModal();

	useEffect(() => {
		setMembership(userMembership);
		setMembershipType(userMembership?.goodsType);
	}, [userMembership]);

	const payToss = async (mode: 'create' | 'modify') => {
		const result = await startTossPayment({ mode, membershipType });

		if (result) {
			popupMessage(result);
		}
	};

	const popupMessage = (txt: any) => {
		setAlertText(txt);
		setIsAlertOpen(true);
	};

	return (
		<>
			<HDivider margin="15px" type="TRANSPARENT" />
			<HDivider margin="0px" type="USERINFO_SETTING" />
			<HDivider margin="15px" type="TRANSPARENT" />
			{/* TODO: 분기처리 필요 */}
			{/* <Stack direction="row" justifyContent="start" alignItems="start" color="#88EBFE" mb="20px"> */}
			{/*   <CheckCircleOutlineIcon /> */}
			{/*   <VDivider margin="6px" type="TRANSPARENT" /> */}
			{/*   <Typography>멤버십이 변경되었습니다.</Typography> */}
			{/* </Stack> */}
			<div style={{ marginBottom: '25px' }}>
				<span style={{ fontSize: '18px', fontWeight: 700 }}>멤버십 정보</span>
			</div>
			{membership && !moment().isAfter(moment(membership.end_at)) ? (
				<>
					<Stack direction="row" alignItems="center" gap="10px" mb="20px">
						{membership.goodsType === 'Basic' ? (
							<img width="50px" src="/assets/icon/membership_basic_L.svg"></img>
						) : (
							<img width="50px" src="/assets/icon/membership_pro_L.svg"></img>
						)}

						<Typography fontSize="16px" fontWeight="500">
							멤버십 이용 중
						</Typography>
					</Stack>
					<span
						style={{ fontSize: '16px', color: '#9B9B9B' }}
					>{`- 멤버십이  ${new Date(membership.end_at).getFullYear()}년 ${
						new Date(membership.end_at).getMonth() + 1
					}월 ${new Date(membership.end_at).getDate()}일에 종료됩니다.`}</span>
					{membership.goodsType === 'Basic' ? (
						<MixButton
							text="멤버십 변경"
							bgColor="#88EBFF"
							fontColor="#000000"
							fontWeight={600}
							style={{ marginTop: '20px' }}
							onClick={() => {
								setIsOpenChangeMembershipModal();
							}}
						/>
					) : (
						<MixButton
							text="Basic으로 변경은 고객센터로 문의해 주세요."
							bgColor="#CB8CFC"
							fontColor="#000000"
							fontWeight={600}
							style={{ marginTop: '20px' }}
							onClick={() => {}}
						/>
					)}
				</>
			) : (
				<Typography fontSize="16px" fontWeight="500">
					이용 중인 멤버십이 없습니다.
				</Typography>
			)}
			<ChangeMembershipModal
				isOpenChangeMembershipModal={isOpenChangeMembershipModal}
				setIsOpenChangeMembershipModal={setIsOpenChangeMembershipModal}
				callback={(type) => {
					setIsOpenChangeMembershipModal();
					setIsOpenChangeMembershipInfoModal();
					setMembershipType(type);
				}}
			/>
			{membership && (
				<ChangeMembershipInfoModal
					membership={membership}
					isOpenChangeMembershipInfoModal={isOpenChangeMembershipInfoModal}
					setIsOpenChangeMembershipInfoModal={setIsOpenChangeMembershipInfoModal}
					callback={() => {
						setIsOpenChangeMembershipInfoModal();
						payToss('modify');
						// setIsOpenChangeCompleteModal();
					}}
				/>
			)}
			<ChangeCompleteModal
				isOpenChangeCompleteModal={isOpenChangeCompleteModal}
				setIsOpenChangeCompleteModal={setIsOpenChangeCompleteModal}
				callback={() => {
					setIsOpenChangeCompleteModal();
				}}
			/>
		</>
	);
};

const PaymentInfo = ({ userInfo, userMembership, setAlertText, setIsAlertOpen }: any) => {
	const { isShown: isOpenCancelMembershipModal, toggle: setIsOpenCancelMembershipModal } = useModal();
	const { isShown: isOpenMembershipBenefitModal, toggle: setIsOpenMembershipBenefitModal } = useModal();
	const { isShown: isOpenCancelCompleteModal, toggle: setIsOpenCancelCompleteModal } = useModal();
	const [membershipState, setMembershipState] = useState(undefined);
	const [membership, setMembership] = useState(userMembership);

	useEffect(() => {
		setMembership(userMembership);
		setMembershipState(getCookies('membership_state'));
	}, [userMembership]);

	const { ctf_sendHttpPost } = useContext(BackdropContext);

	const payToss = async (mode: 'create' | 'modify', membershipType: string) => {
		const result = await startTossPayment({ mode, membershipType });

		if (result) {
			popupMessage(result);
		}
	};

	const popupMessage = (txt: any) => {
		setAlertText(txt);
		setIsAlertOpen(true);
	};

	const cancelMembership = async () => {
		const userInfo = $mixtapeGetUser();

		const res = await ctf_sendHttpPost(`/payment/membership_modify`, {
			user_id: userInfo.user_id,
			pay_automatic_use: membership.pay_automatic_use === 'Y' ? 'N' : 'Y',
			orderId: membership.orderId
		});

		if (res && res.data && res.data.code === '200') {
			// 구독취소 또는 재시작일 시 팝업문구를 당일에 띄워야하기 때문에 쿠키에 저장
			setCookies('membership_state', res.data.state, moment().hours(23).minutes(59).seconds(59).toDate().toString());
			setMembership({ ...userMembership, ...res.data.membership });
			setMembershipState(res.data.state);
			if (res.data.state === 'cancel') {
				setIsOpenCancelCompleteModal();
			}
		}
	};

	const onCancelMembership = () => {
		setIsOpenCancelMembershipModal();
	};

	// is_membership_delay

	return (
		membership && (
			<>
				<HDivider margin="15px" type="TRANSPARENT" />
				<HDivider margin="0px" type="USERINFO_SETTING" />
				<HDivider margin="15px" type="TRANSPARENT" />
				<div style={{ marginBottom: '35px' }}>
					<span style={{ fontSize: '18px', fontWeight: 700 }}>결제정보</span>
				</div>
				{membershipState === 'cancel' && (
					<Stack direction="row" justifyContent="start" alignItems="start" color="#FF695D" mb="20px">
						<ErrorOutlineIcon />
						<VDivider margin="6px" type="TRANSPARENT" />
						<Typography>
							멤버십이 해지되었습니다.
							<br />
							{`${moment(membership.end_at).format('YYYY년 MM월 DD일')} 까지 믹스테이프를 이용하실 수 있습니다.`}
						</Typography>
					</Stack>
				)}
				{membershipState === 'restart' && (
					<Stack direction="row" justifyContent="start" alignItems="start" color="#88EBFE" mb="20px">
						<CheckCircleOutlineIcon />
						<VDivider margin="6px" type="TRANSPARENT" />
						<Typography>회원님의 멤버십이 재시작 되었습니다.</Typography>
					</Stack>
				)}

				<Stack direction="row" justifyContent="space-between" alignItems="center" mb="3px">
					<Stack direction="row" justifyContent="start" alignItems="center">
						<CreditCardIcon sx={{ color: '#5b5b5b' }} />
						<VDivider margin="6px" type="TRANSPARENT" />

						<UserInfoSettingInfoText style={{ marginRight: '12px' }}>
							{membership.cardCompany || ''}
						</UserInfoSettingInfoText>
						<UserInfoSettingInfoText>{membership.cardNumber || ''}</UserInfoSettingInfoText>
					</Stack>
					<EditButton onClick={() => payToss('modify', undefined)}>수정</EditButton>
				</Stack>

				{/* 멤버십 종료일 이후 문구 삭제 */}
				{!moment().isAfter(moment(membership.end_at)) && (
					<span style={{ marginLeft: '37px', fontSize: '16px', color: '#9B9B9B' }}>
						{membership.pay_automatic_use === 'Y'
							? `- 다음 결제일은 ${new Date(membership.end_at).getFullYear()}년 ${
									new Date(membership.end_at).getMonth() + 1
								}월 ${new Date(membership.end_at).getDate()}일 입니다.`
							: `- 멤버십이 ${new Date(membership.end_at).getFullYear()}년 ${
									new Date(membership.end_at).getMonth() + 1
								}월 ${new Date(membership.end_at).getDate()}일에 종료됩니다.`}
					</span>
				)}

				{/* 자동결제인 상태에만 노출 */}
				{membership.pay_automatic_use === 'Y' && (
					<span style={{ marginLeft: '37px', fontSize: '16px', color: '#9B9B9B' }}>
						- 결제 정보 변경 시, 결제 수단 확인을 위해 100원 테스트 결제 후 바로 취소됩니다.
					</span>
				)}

				{membership.pay_automatic_use === 'Y' ? (
					<CancelButton onClick={() => onCancelMembership()}>멤버십 해지하기</CancelButton>
				) : (
					<MixButton
						text="멤버십 구독하기"
						bgColor="#EFFD60"
						fontColor="#000000"
						fontWeight={600}
						style={{ marginTop: '20px' }}
						onClick={() => cancelMembership()}
					></MixButton>
				)}

				<CancelMembershipModal
					isOpenCancelMembershipModal={isOpenCancelMembershipModal}
					setIsOpenCancelMembershipModal={setIsOpenCancelMembershipModal}
					callback={() => {
						setIsOpenCancelMembershipModal();
						setIsOpenMembershipBenefitModal();
					}}
				/>
				<MembershipBenefitModal
					isOpenMembershipBenefitModal={isOpenMembershipBenefitModal}
					setIsOpenMembershipBenefitModal={setIsOpenMembershipBenefitModal}
					callback={() => {
						setIsOpenMembershipBenefitModal();
						cancelMembership();
					}}
				/>
				<CancelCompleteModal
					isOpenCancelCompleteModal={isOpenCancelCompleteModal}
					setIsOpenCancelCompleteModal={setIsOpenCancelCompleteModal}
					callback={() => {
						setIsOpenCancelCompleteModal();
					}}
				/>
			</>
		)
	);
};

function UserInfoSetting(): ReactElement {
	const [alertText, setAlertText] = useState('');
	const [isAlertOpen, setIsAlertOpen] = useState(false);

	const sessData = getAuthInSessionStorage();
	if (!sessData) {
		setAlertText('로그인 정보가 없습니다.');
	}

	const [uc_userData, uf_setUserData] = useState({});

	const [promotionTelStatus, setPromotionTelStatus] = useState<boolean>(false);
	const [promotionEmailStatus, setPromotionEmailStatus] = useState<boolean>(false);
	const { isShown: isOpenEditEmailModal, toggle: setIsOpenEditEmailModal } = useModal();
	const { isShown: isOpenEditPhoneNumberModal, toggle: setIsOpenEditPhoneNumberModal } = useModal();
	const { isShown: isOpenEditPasswordModal, toggle: setIsOpenEditPasswordModal } = useModal();
	const { isShown: isOpenEditDormantAccountModal, toggle: setIsOpenEditDormantAccountModal } = useModal();
	const { isShown: isOpenRrnModal, toggle: setIsOpenRrnModal } = useModal();

	const hideAlert = () => {
		setIsAlertOpen(false);
	};

	const { ctf_sendHttpPost, ctf_sendHttpGet, ctf_sendHttpPatch } = useContext(BackdropContext);
	const goEditPhoneNumber = (e: any) => {
		setIsOpenEditPhoneNumberModal();
	};

	const goEditEmail = (e: any) => {
		e.preventDefault();
		setIsOpenEditEmailModal();
	};

	const goEditPassword = (e: any) => {
		e.preventDefault();
		setIsOpenEditPasswordModal();
	};

	const goEditAccount = (e: any) => {
		e.preventDefault();
		setIsOpenEditDormantAccountModal();
	};

	const goEditRrn = (e: any) => {
		e.preventDefault();
		setIsOpenRrnModal();
	};

	const goLogout = async () => {
		const res = await ctf_sendHttpPost('/logout', {});
		removeAuth();
		const redirectUri = routePath.portal.path;
		history.push(redirectUri);
	};

	useEffect(() => {
		callUserInfo();

		const search = window.location.search;

		const urlInfo = search.split('&').reduce((prev, curr) => {
			const arr = curr.split('=');
			// @ts-ignore
			const obj = { ...prev };
			// @ts-ignore
			obj[arr[0].replace('?', '')] = arr[1];
			return obj;
		}, {});

		// @ts-ignore
		if (urlInfo.customerKey && urlInfo.authKey) {
			if (urlInfo?.membershipType !== undefined) {
				sendFinalPayment(urlInfo.customerKey, urlInfo.authKey, urlInfo.membershipType);
			} else {
				// @ts-ignore
				sendFinalPayment(urlInfo.customerKey, urlInfo.authKey);
			}
		} else if (urlInfo.message) {
			// @ts-ignore
			setMsg(decodeURI(urlInfo.message));
		}
	}, []);

	const sendFinalPayment = async (ck: any, ak: any, membershipType: string) => {
		const param: any = {
			customerKey: ck,
			authKey: ak,
			userEmail: $mixtapeGetUser().email,
			userName: $mixtapeGetUser().name,
			orderId: $mixtapeGetUser().membership.orderId
		};

		// membershipType === undefined ? $mixtapeGetUser().membership.goodsType :
		if (membershipType) {
			param.memberUpdate = 'Pro';
		}
		const res = await sendHttpPost('/payment/pay_modify', { ...param });

		if (res.data?.code === '200') {
			await callUserInfo();
			history.push('/userinfo/userinfo-setting');
			const msg =
				membershipType === undefined
					? res.data?.message
					: `${$mixtapeGetUser()?.email}  주소로 확인 이메일을 보내드립니다. \n 
      ${moment($mixtapeGetUser().membership.end_at).format('YYYY년 MM월 DD일')}까지 Pro 멤버십을 이용하실 수 있습니다.`;
			setAlertText(msg);
			setIsAlertOpen(true);
		} else {
			setAlertText(res.data?.message);
			setIsAlertOpen(true);
		}
	};

	const callUserInfo = async () => {
		const userSeq = $mixtapeUserSeq();
		const res = await ctf_sendHttpGet(`/users/${userSeq}`);

		if (!res.error) {
			uf_setUserData(res.data);
			$mixtapeSetUser(res.data);
		}
	};

	const goTerms = () => {
		window.open('https://about.mixtape.so/policy/useterms', '_blank');
	};

	const goPrivacy = () => {
		window.open('https://about.mixtape.so/policy/privacy', '_blank');
	};

	useEffect(() => {
		setPromotionTelStatus(uc_userData.promotion_phone === 1);
		setPromotionEmailStatus(uc_userData.promotion_email === 1);
	}, [uc_userData]);

	return (
		<>
			<MainHeader logo="logoW" />
			<UserInfoSettingLayout>
				<Form style={{ width: '460px' }}>
					{uc_userData && (
						<>
							<Text textType="TITLE">{uc_userData.name || ''}</Text>
							<HDivider margin="6px" type="TRANSPARENT" />
							<Stack direction="row" alignItems="center" gap="5px">
								<UserInfoSettingIdText>&nbsp;{uc_userData.user_id || ''}</UserInfoSettingIdText>

								{/* {uc_userData.membership ? (
                  uc_userData.membership.goodsType === 'Basic' ? (
                    <img width="12px" src="/assets/icon/membership_basic.svg"></img>
                  ) : (
                    <img width="12px" src="/assets/icon/membership_pro.svg"></img>
                  )
                ) : (
                  <></>
                )} */}
							</Stack>

							<Membership
								userMembership={uc_userData.membership}
								setAlertText={setAlertText}
								setIsAlertOpen={setIsAlertOpen}
							/>

							{uc_userData.membership && (
								<>
									<PaymentInfo
										userInfo={uc_userData}
										userMembership={uc_userData.membership}
										setAlertText={setAlertText}
										setIsAlertOpen={setIsAlertOpen}
									/>
								</>
							)}

							<HDivider margin="15px" type="TRANSPARENT" />
							<HDivider margin="0px" type="USERINFO_SETTING" />
							<HDivider margin="15px" type="TRANSPARENT" />
							<Row justify="SPACE_BETWEEN" style={{ alignItems: 'center' }}>
								<Row justify="FLEX_START">
									<Icon
										icon="iconTel"
										style={{
											width: '24px',
											height: '24px'
										}}
									/>
									<VDivider margin="6px" type="TRANSPARENT" />
									<UserInfoSettingInfoText>{uc_userData.phone_number || ''}</UserInfoSettingInfoText>
								</Row>
								<EditButton onClick={goEditPhoneNumber}>수정</EditButton>
							</Row>
							<HDivider margin="10px" type="TRANSPARENT" />
							<Row justify="SPACE_BETWEEN" style={{ alignItems: 'center' }}>
								<Row justify="FLEX_START">
									<Icon
										icon="iconEmail"
										style={{
											width: '24px',
											height: '24px'
										}}
									/>
									<VDivider margin="6px" type="TRANSPARENT" />
									<UserInfoSettingInfoText>{uc_userData.email || ''}</UserInfoSettingInfoText>
								</Row>
								<EditButton onClick={goEditEmail}>수정</EditButton>
							</Row>
							<HDivider margin="10px" type="TRANSPARENT" />
							<Row justify="SPACE_BETWEEN" style={{ alignItems: 'center' }}>
								<Row justify="FLEX_START">
									<Icon
										icon="iconPassword"
										style={{
											width: '24px',
											height: '24px'
										}}
									/>
									<VDivider margin="6px" type="TRANSPARENT" />

									<UserInfoSettingInfoText onClick={goEditPassword} style={{ cursor: 'pointer' }}>
										비밀번호 변경
									</UserInfoSettingInfoText>
								</Row>
							</Row>
							<HDivider margin="30px" type="TRANSPARENT" />
							<UserInfoSettingPromotionTitle>프로모션 정보수신 동의</UserInfoSettingPromotionTitle>
							<HDivider margin="15px" type="TRANSPARENT" />
							<Row justify="SPACE_BETWEEN" style={{ alignItems: 'center' }}>
								<Row justify="FLEX_START">
									<Icon
										icon="iconTel"
										style={{
											width: '24px',
											height: '24px'
										}}
									/>
									<VDivider margin="6px" type="TRANSPARENT" />
									<UserInfoSettingInfoText>휴대전화</UserInfoSettingInfoText>
								</Row>
								<Toggle
									setToggle={() => {
										setPromotionTelStatus((prevState) => {
											(async (p) => {
												const userSeq = $mixtapeUserSeq();
												const val = prevState ? 0 : 1;
												const res = await ctf_sendHttpPatch(`/user/${userSeq}`, { promotion_phone: val });
											})(prevState);
											return !prevState;
										});
									}}
									toggle={promotionTelStatus}
								/>
							</Row>
							<HDivider margin="10px" type="TRANSPARENT" />
							<Row justify="SPACE_BETWEEN" style={{ alignItems: 'center' }}>
								<Row justify="FLEX_START">
									<Icon
										icon="iconEmail"
										style={{
											width: '24px',
											height: '24px'
										}}
									/>
									<VDivider margin="6px" type="TRANSPARENT" />
									<UserInfoSettingInfoText>이메일</UserInfoSettingInfoText>
								</Row>
								<Toggle
									setToggle={() => {
										setPromotionEmailStatus((prevState) => {
											(async (p) => {
												const userSeq = $mixtapeUserSeq();
												const val = prevState ? 0 : 1;
												const res = await ctf_sendHttpPatch(`/user/${userSeq}`, { promotion_email: val });
												// if (!res.error) {
												//   userInfo.promotion_email = val;
												// }
											})(prevState);
											return !prevState;
										});
									}}
									toggle={promotionEmailStatus}
								/>
							</Row>
							<HDivider margin="15px" type="TRANSPARENT" />
							<HDivider margin="0px" type="USERINFO_SETTING" />
							<HDivider margin="15px" type="TRANSPARENT" />
							<Row justify="CENTER">
								<UserInfoSettingBottomText style={{ cursor: 'pointer' }} onClick={goPrivacy}>
									개인정보처리방침
								</UserInfoSettingBottomText>
								<VDivider margin="12px" style={{ height: '12px' }} type="GRAY2" />
								<UserInfoSettingBottomText style={{ cursor: 'pointer' }} onClick={goTerms}>
									이용약관
								</UserInfoSettingBottomText>
								<VDivider margin="12px" style={{ height: '12px' }} type="GRAY2" />
								<UserInfoSettingBottomText onClick={goLogout} style={{ cursor: 'pointer' }}>
									로그아웃
								</UserInfoSettingBottomText>
							</Row>
						</>
					)}
				</Form>
				<AlertModal isPopupOpen={isAlertOpen} alertText={alertText} hidePopup={hideAlert} />
			</UserInfoSettingLayout>
			<MainFooter />

			<EditEmailModal
				isOpenEditEmailModal={isOpenEditEmailModal}
				setIsOpenEditEmailModal={setIsOpenEditEmailModal}
				callback={callUserInfo}
			/>
			<EditPhoneNumberModal
				isOpenEditPhoneNumberModal={isOpenEditPhoneNumberModal}
				setIsOpenEditPhoneNumberModal={setIsOpenEditPhoneNumberModal}
				callback={callUserInfo}
				userData={uc_userData}
			/>
			<EditPasswordModal
				isOpenEditPasswordModal={isOpenEditPasswordModal}
				setIsOpenEditPasswordModal={setIsOpenEditPasswordModal}
			/>
			<EditDormantAccountModal
				isOpenEditDormantAccountModal={isOpenEditDormantAccountModal}
				setIsOpenEditDormantAccountModal={setIsOpenEditDormantAccountModal}
			/>
			<EditRrnModal isOpenRrnModal={isOpenRrnModal} setIsOpenRrnModal={setIsOpenRrnModal} />
		</>
	);
}

export default UserInfoSetting;
