/* eslint-disable no-plusplus */
import _ from 'lodash';
import validator from 'validator';

export function isNullObject(obj: any) {
  return !(obj && Object.keys(obj).length);
}

export function clearStorage() {
  sessionStorage.clear();
  localStorage.clear();
}

export function storageNullCheck(data: string | null) {
  switch (data) {
    case 'undefined':
    case 'null':
    case null:
      return false;
    default:
      return true;
  }
}

export function isEmpty(val: any): boolean {
  if (typeof val === 'number') {
    return _.isNil(val) || Number.isNaN(val);
  }
  if (typeof val === 'boolean') {
    return _.isNil(val);
  }

  return _.isEmpty(val);
}

// 비밀번호
export function isNotStrongPassword(val?: string): boolean {
  return !validator.isStrongPassword(val ?? '', {
    minLength: 9, // 9자리 이상
    minLowercase: 1, // 소문자 포함
    minUppercase: 1, // 대문자 포함
    minNumbers: 1, // 숫자 포함
    minSymbols: 1, // 특수기호 포함
    returnScore: false,
    pointsPerUnique: 1,
    pointsPerRepeat: 0.5,
    pointsForContainingLower: 10,
    pointsForContainingUpper: 10,
    pointsForContainingNumber: 10,
    pointsForContainingSymbol: 10,
  });
}

export function roundBy(val1 = 1) {
  return function round(val2: number) {
    return Math.round(val2 * 10 ** val1) / 10 ** val1;
  };
}

export function calculateJSNums(val1: number, val2: number, operator: 'add' | 'subtract', round = 1) {
  if (isEmpty(val1) || isEmpty(val2)) return 0;

  if (operator === 'add') {
    return roundBy(round)(val1 + val2);
  }
  if (operator === 'subtract') {
    return roundBy(round)(val1 - val2);
  }

  return 0;
}

export function isJSON(val: any) {
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
}

export function isCanStringify(val: any) {
  try {
    JSON.stringify(val);
  } catch (e) {
    return false;
  }
  return true;
}

export function setDataInSessionStorage(key: string, data: any) {
  if (isCanStringify(data)) {
    const stringifiedData = JSON.stringify(data);
    sessionStorage.setItem(key, stringifiedData);
  }
}

export function getDataInSessionStorage(key: string) {
  const data = sessionStorage.getItem(key) ?? '';

  if (isJSON(data)) {
    const stringifiedData = JSON.parse(data);
    return stringifiedData;
  }
  return null;
}

export const replaceAll = (str: string, searchStr: string, replaceStr: string) => str.split(searchStr).join(replaceStr);

export const encode = (val: any) => {
  if (typeof val === 'string') {
    return replaceAll(replaceAll(val, '[', '\\['), ']', '\\]');
  }
  return val;
};

export const encodeObj = (obj: any) => {
  if (!obj) return {};
  const copied = { ...obj };
  Object.keys(copied).forEach((key: string) => {
    copied[key] = encode(copied[key]);
  });

  return copied;
};

export const autoHypenPhone = (str: string) => {
  str = str.replace(/[^0-9]/g, '');
  let tmp = '';
  if (str.length < 4) {
    return str;
  }
  if (str.length < 7) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3);
    return tmp;
  }
  if (str.length < 11) {
    tmp += str.substr(0, 3);
    tmp += '-';
    tmp += str.substr(3, 3);
    tmp += '-';
    tmp += str.substr(6);
    return tmp;
  }
  tmp += str.substr(0, 3);
  tmp += '-';
  tmp += str.substr(3, 4);
  tmp += '-';
  tmp += str.substr(7);
  return tmp;

  return str;
};

// export const getDisabledWithFormError = (formError: any, fieldArr: Array<string>, formData?: any) =>
//   fieldArr?.some((field) => {
//     const error = formError[field];
//
//     if (formData && formData[field] && error === undefined) {
//       return !isEmpty(error);
//     }
//     return !isEmpty(error) || error === undefined;
//   });

export const getDisabledWithFormError = (formError: any, fieldArr: Array<string>, formData?: any) => {
  const nationality = sessionStorage.getItem('userType');
  for (const val of fieldArr) {
    if (nationality === 'LOCAL' && val === 'foreigner_no') {
      continue;
    }

    if (nationality === 'FOREIGNER' && val.startsWith('user_rrr')) {
      continue;
    }
    const error = formError[val];

    if (error != null) {
      return true;
    }
    if (formData && !formData[val]) {
      return true;
    }
  }

  return false;
};

export function byteCounter(text: string, blank = 0) {
  if (!text) {
    return 0;
  }
  // blank === 0 -> 공백 미포함  ,  blank !== 1 -> 공백 포함
  let byte = 0;
  // byte 를 0으로 두고, 한글자씩 체크하면서 한자 한문 한글이면 2를 올려주고, 그 외는 1을 올려주겠습니다.
  if (blank === 0 && typeof text === 'string') {
    // 공백 미포함일 때는, 미리 줄바꿈과 공백을 빈칸으로 처리합니다.
    text = text.replace(/\s+/g, '');
  }

  for (let i = 0; i < text.length; i++) {
    // 정규식.test() 함수는 인수가 정규식을 만족하는지 판단하여 true or false 값을 반환합니다.
    // 한글표현 정규식 : ㄱ-ㅎㅏ-ㅣ가-힣
    // 한자표현 정규식 : 一-龥
    // 일본어표현 정규식 : ぁ-ゔァ-ヴー々〆〤
    // 이 모든것을 /[]/ 안에 포함시켜서 연달아 써주면 "or" 처리됩니다.
    // 한, 중, 일 언어라면, byte를 2 더해주고, 아니라면 1을 더해주고, 최종적으로 byte를 return 합니다.
    if (/[ㄱ-ㅎㅏ-ㅣ가-힣一-龥ぁ-ゔァ-ヴー々〆〤]/.test(text[i])) {
      byte += 2;
    } else {
      byte += 1;
    }
  }
  return byte;
}

export const CC_RELEASE_STEP = [
  {
    code: 'inspection',
    level: 2,
    label: '자료검수중',
  },
  {
    code: 'inspection-complete',
    level: 2,
    label: '자료검수완료',
  },
  {
    code: 'release-reject',
    level: 2,
    label: '발매반려',
  },
  {
    code: 'release-complete',
    level: 3,
    label: '발매완료',
  },
  {
    code: 'update',
    level: 2,
    label: '수정 신청',
  },
  {
    code: 'update_approve_wait',
    level: 2,
    label: '수정 검토 중',
  },
  {
    code: 'update-reject',
    level: 2,
    label: '수정반려',
  },
  {
    code: 'update-complete',
    level: 2,
    label: '수정완료',
  },
  {
    code: 'temp-save',
    level: 2,
    label: '임시저장',
  },
  {
    code: 'dismiss',
    level: 3,
    label: '삭제',
  },
];

export const cf_getStepInfoByCode = (code: string) => {
  return CC_RELEASE_STEP.find((step) => step.code === code);
};
