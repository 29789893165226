import React, { Dispatch, SetStateAction } from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import { ConfirmButton } from '@components/molecules/FindPassword/Button';
import { routePath } from '@constants/path';
import history from '@utils/history';

import { TCurrentStep } from '.';

type TCantFoundIdProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  setIsOpenEditPasswordModal: any;
};

function Completed({ setCurrentStep, setIsOpenEditPasswordModal }: TCantFoundIdProps) {
  const confirm = () => {
    setIsOpenEditPasswordModal();
    setCurrentStep('AUTH_NUMBER');
  };

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="48px" type="TRANSPARENT" />
        <Row justify="CENTER">
          <Text textType="DESCRIPTION">새로운 비밀번호로 변경되었습니다.</Text>
        </Row>
        <HDivider margin="46px" type="TRANSPARENT" />
        <ConfirmButton
          onClick={() => {
            confirm();
          }}
        >
          확인
        </ConfirmButton>
      </Form>
    </Layout>
  );
}

export default Completed;
