import React from 'react';

import styled from '@emotion/styled';

export type TFormParams = any;

const StyledForm = styled.form<TFormParams>`
  display: flex;
  flex-direction: column;
`;

const Form = ({ ...rest }: TFormParams) => (
  <StyledForm
    {...rest}
    onSubmit={(e: any) => {
      e.preventDefault();
    }}
  />
);

export default Form;
