import Layout from '@components/form/Layout';
import Text from '@components/form/Text';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const AlbumManagementTypeInProgress = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #effd60;
`;

export const AlbumManagementTitleInProgress = styled(Typography)`
  width: 730px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;

  color: #ffffff;
`;

export const AlbumManagementSingerInProgress = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
`;

export const AlbumManagementReleaseDateInProgress = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #ffffff;
`;

export const AlbumManagementUCPInProgress = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* color/777 */

  color: #777777;
`;

export const AlbumManagementReleasedTitle = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* color/fff */

  color: #ffffff;
`;

export const AlbumManagementReleasedSubTitle = styled(Text)`
  ont-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* color/ddd */

  color: #dddddd;
`;

// export const AlbumManagementTitleInProgress = styled(Typography)`
//
//   width: 730px;
//   word-break: break-all;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   font-family: Pretendard;
//   font-style: normal;
//   font-weight: 700;
//   font-size: 42px;
//
//   color: #ffffff;
// `;

export const AlbumTitleTextInTable = styled(Typography)`
  text-align: left;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: 380px;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AlbumSubTitleTextInTable = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* color/#e8e8e8 */

  color: #e8e8e8;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const AlbumReleaseDescText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;

  /* color/fff */

  color: #ffffff;
`;

export const MembershipTitleText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #effd60;
`;

export const MembershipDescTitleText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #fff;
`;

export const MembershipDescText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`;

export const AlbumDetailLabelText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  //width:70px;
  /* color/bcbcbc */

  color: #bcbcbc;
`;

export const AlbumDetailDateText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  /* color/fff */

  color: #ffffff;
`;

export const AlbumDetailValueText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: right;

  /* color/fff */

  color: #ffffff;
`;

export const AlbumText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: right;
`;

export const AlbumProgressActiveText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: right;

  /* color/fff */

  color: #ffffff;
`;

export const AlbumProgressUnActiveText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  text-align: right;

  /* color/777 */

  color: #777777;
`;

export const AlbumProgressActiveDateText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: right;

  /* color/fff */

  color: #ffffff;
`;

export const AlbumProgressUnActiveDateText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: right;

  /* color/777 */

  color: #777777;
`;

export const AlbumDetailTrackTitle = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  /* identical to box height, or 133% */

  color: #ffffff;
`;

export const AlbumDetailLyricsText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #ffffff;
`;
