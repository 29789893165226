import HDivider from '@components/form/HDivider';
import Row from '@components/form/Row';
import VDivider from '@components/form/VDivider';
import { CancelButton, ConfirmButton } from '@components/molecules/FindId/Button';
import { useModal } from '@hooks/useModal';
import React, { useState } from 'react';
import Text from '@components/form/Text';
import Modal, { Content } from '@components/form/Modal';
import ReactDOM from 'react-dom';

export function popupMessage(txt: any) {
  let shown = true;

  const uid = '___popup_message___';
  let div = document.getElementById(uid);

  const hidePopup = () => {
    shown = false;
    const subDiv = document.getElementById(uid);
    if (subDiv) {
      document.body.removeChild(subDiv);
    }
  };

  if (!div) {
    div = document.createElement('div');
    div.id = uid;
    div.addEventListener('click', hidePopup);
  }
  document.body.appendChild(div);
  div.style.background = '#000000';
  div.style.width = '100vw';
  div.style.height = '100vh';
}

// @ts-ignore
function NoticeModal({ alertText }) {
  const [b, a] = useState(true);
  const hidePopup = () => a(false);
  return (
    <Modal headerText="Notice" isShown={b} modalStyle={{ width: '600px' }} hide={hidePopup}>
      <div
        style={{
          padding: '20px 0 20px 0',
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '24px',
        }}
      >
        {alertText}
      </div>
    </Modal>
  );
}

// @ts-ignore
function AlertModal({
  isPopupOpen,
  hidePopup,
  alertText,
  okButton = true,
  cancelButton = false,
  onOkButtonClick = null,
  onCancelButtonClick = null,
}) {
  const handleOkClick = () => {
    if (onOkButtonClick !== null) {
      onOkButtonClick();
    }
    hidePopup();
  };

  const handleCancelClick = () => {
    if (onCancelButtonClick !== null) {
      onCancelButtonClick();
    }
    hidePopup();
  };

  return (
    <Modal headerText="Notice" isShown={isPopupOpen} modalStyle={{ width: '600px', border: '#ccc solid 1px' }} hide={hidePopup}>
      <Row
        key="AM00001"
        justify="CENTER"
        direction="column"
        style={{
          padding: '40px 0 0 0 ',
          width: '100%',
          minHeight: '200px',
          alignItems: 'center',
          fontSize: '16px',
        }}
      >
        {/* {alertText.split('\n')
          .map((ele) => (<Text key='AM000244'>{ele}</Text>))
        } */}

        <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: alertText }} />

        {(okButton || cancelButton) && (
          <Row justify="CENTER" key="AM00002" style={{ gap: '15px' }}>
            {cancelButton && <CancelButton onClick={handleCancelClick}>취소</CancelButton>}
            {okButton && <ConfirmButton onClick={handleOkClick}>확인</ConfirmButton>}
          </Row>
        )}
      </Row>
    </Modal>
  );
}

export default AlertModal;
