import { useDispatch, useSelector } from 'react-redux';

import getActionCreators from '@/core/redux/getActionCreators';
import { useInjectResourceControllerReducer } from '@/core/redux/getReducer';
import getSelectors from '@/core/redux/getSelectors';
import { createStructuredSelector } from 'reselect';

export type TUseReduxData = {
  data: any;
  setData: any;
  parameters: any;
  setParameters: any;
  clearData: any;
  clearParameters: any;
};

export function useReduxData(reduxActionName: string): TUseReduxData {
  const { makeSelectData, makeSelectParameters } = getSelectors(reduxActionName);
  useInjectResourceControllerReducer(reduxActionName);
  const { data = {}, parameters = {} } = useSelector(
    createStructuredSelector({
      data: makeSelectData,
      parameters: makeSelectParameters,
    }),
  );
  const { setParameters, setData, clearData, clearParameters } = getActionCreators(reduxActionName);

  const dispatch = useDispatch();

  function setReduxData(name: string, value: any) {
    dispatch(setData({ [name]: value }));
  }
  function setReduxDataObj(obj: Record<any, any>) {
    dispatch(setData(obj));
  }

  function setReduxParameters(name: string, value: any) {
    dispatch(setParameters({ [name]: value }));
  }
  function setReduxParametersObj(obj: Record<any, any>) {
    dispatch(setParameters(obj));
  }

  return {
    data,
    parameters,
    setParameters: (param1: string | Record<any, any>, value?: any) => {
      if (typeof param1 === 'string') setReduxParameters(param1, value);
      if (typeof param1 === 'object') setReduxParametersObj(param1);
    },
    setData: (param1: string | Record<any, any>, value?: any) => {
      if (typeof param1 === 'string') setReduxData(param1, value);
      if (typeof param1 === 'object') setReduxDataObj(param1);
    },
    clearData: () => {
      dispatch(clearData());
    },
    clearParameters: () => {
      dispatch(clearParameters());
    },
  };
}
