import React, { Fragment, ReactElement, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import history from '@utils/history';
import Layout from '@components/form/Layout';
import { routePath } from '@constants/path';
import AlbumInfoRegistrationModal from '@containers/AlbumManagement/AlbumInfoRegistrationModal';
import styled from '@emotion/styled';
import { useModal } from '@hooks/useModal';
import { TIcon } from '@images/png';
import mixtapeLogo from '@images/png/logo_w.png';
import { Button, Menu, MenuItem, Stack, ThemeProvider, createTheme } from '@mui/material';
import { $mixtapeGetUser, removeAuth } from '@utils/auth';
import { BackdropContext } from '@hooks/usePopupMessage';

type TMainHeaderProps = {
	logo: TIcon;
};

const TextButton = styled(Button)<{ iscurmenu: string }>`
	/* margin: 0 3.39vw; */
	/* min-width: 5.21vw; */
	white-space: nowrap;
	background-color: transparent;
	color: ${(props) => (props.iscurmenu === 'true' ? '#EFFD60' : 'white')};
	font-size: 18px;
	font-weight: 300;
	&:hover {
		color: #effd60;
	}
`;

const ActionButton = styled(Button)`
	flex: none;
	width: 170px;
	height: 54px;
	padding: 16px 45px;
	font-size: 18px;
	font-weight: 300;
`;

const theme = createTheme({
	components: {
		MuiMenu: {
			styleOverrides: {
				paper: {
					backgroundColor: '#121212 !important',
					color: 'white !important',
					padding: '10px 20px'
				}
			}
		}
	}
});

const Menus = ({ gradeInfo, setIsOpenAlbumInfoRegistrationModal }: any) => {
	const { pathname } = useLocation();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [curMenu, setCurMenu] = useState<string>('');

	const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
		setAnchorEl(event.currentTarget);
		setCurMenu(id);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setCurMenu('');
	};

	const onMoveMenu = (menu: any) => {
		// 앨범 발매일 경우
		if (menu.path === '/album/album-release') {
			// 멤버십이 없거나 멤버십이 있눈데 유예기간인 경우
			if (!gradeInfo.isMembership || (gradeInfo.isMembership && gradeInfo.isMembershipDelay)) {
				history.push('/membership-info');
			} else {
				// 멤버십은 있는데 계좌인증이 안되 있는 경우
				if (!gradeInfo.isAccountInfo) {
					history.push('/payment-completed');
				} else {
					setIsOpenAlbumInfoRegistrationModal();
				}
			}
		}
		// 앨범 관리일 경우
		else if (menu.path === '/album/album-management' || menu.path === '/settlement/settlement-management') {
			if (gradeInfo.isMembership && !gradeInfo.isAccountInfo) {
				history.push('/payment-completed');
			} else {
				history.push(menu.path);
			}
		} else {
			history.push(menu.path);
		}

		handleClose();
	};

	return (
		<>
			<ThemeProvider theme={theme}>
				<Stack direction="row" gap="3.3vw">
					{[
						{
							id: 'intro',
							label: '믹스테이프란?',
							isShow: true,
							menu: [
								{
									label: '믹스테이프 소개',
									path: '/intro/introduction',
									isShow: true
								},
								{
									label: '서비스 이용 방법',
									path: '/intro/manual',
									isShow: true
								},
								{
									label: '멤버십 안내',
									path: '/intro/membership',
									isShow: true
								},
								{
									label: '유통 서비스 채널',
									path: '/intro/channel',
									isShow: true
								}
							]
						},
						{
							id: 'album',
							label: '나의 앨범',
							isShow:
								// 멤버십이 있거나 멤버십이 없는데 발매이력이 있는경우 노출
								(gradeInfo.isMember && gradeInfo.isMembership) ||
								(gradeInfo.isMember && !gradeInfo.isMembership && gradeInfo.isAlbumRelease),
							menu: [
								{
									label: '앨범 발매',
									path: '/album/album-release',
									isShow: gradeInfo.isMembership && !gradeInfo.isMembershipDelay
								},
								{
									label: '앨범 관리',
									path: '/album/album-management',
									isShow: true
								},
								{
									label: '정산 관리',
									path: '/settlement/settlement-management',
									isShow: true
								},
								{
									label: '프로필 관리',
									path: routePath.profile.profileManagement.path,
									isShow: gradeInfo.isMembership
								}
							]
						},

						// {gradeInfo.isMember && gradeInfo.isMembership && (
						//   <TextButton
						//     iscurmenu={(pathname === routePath.profile.profileManagement.path).toString()}
						//     onClick={() => history.push(routePath.profile.profileManagement.path)}
						//   >
						//     프로필 관리
						//   </TextButton>
						// )}
						{
							id: 'promotion',
							label: '프로모션',
							isShow: true,
							menu: [
								{
									label: '엑셀러레이팅',
									path: '/promotion/accelerating',
									isShow: true
								}
							]
						},
						{
							id: 'help',
							label: '고객센터',
							isShow: true,
							menu: [
								{
									label: '공지사항',
									path: '/help/notice',
									isShow: true
								},
								{
									label: 'FAQ',
									path: '/help/faq',
									isShow: true
								},
								// {
								//   label: '수정요청',
								//   path: '/help/updateInfo',
								// },
								{
									label: '1:1 문의',
									path: '/help/qna',
									isShow: true
								}
							]
						}
					]
						.filter((menu) => menu.isShow)
						.map((pMenu, idx) => {
							return (
								<Fragment key={pMenu.id}>
									<TextButton
										iscurmenu={pathname.includes(pMenu.id).toString()}
										onClick={(e) => handleClick(e, pMenu.id)}
									>
										{pMenu.label}
									</TextButton>
									<Menu
										anchorEl={anchorEl}
										open={curMenu === pMenu.id}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left'
										}}
									>
										{pMenu.menu
											.filter((cMenu) => cMenu.isShow)
											.map((cMenu) => {
												return (
													<MenuItem
														key={cMenu.path}
														sx={{
															'&:hover': {
																color: '#effd60'
															},
															padding: '12px 16px'
														}}
														onClick={() => onMoveMenu(cMenu)}
													>
														{cMenu.label}
													</MenuItem>
												);
											})}
									</Menu>
								</Fragment>
							);
						})}
				</Stack>
			</ThemeProvider>
			{/* {gradeInfo.isMember && gradeInfo.isMembership && (
        <TextButton
          iscurmenu={(pathname === routePath.profile.profileManagement.path).toString()}
          onClick={() => history.push(routePath.profile.profileManagement.path)}
        >
          프로필 관리
        </TextButton>
      )} */}
		</>
	);
};

function MainHeader({ logo }: TMainHeaderProps): ReactElement {
	const userInfo = $mixtapeGetUser();
	const { pathname } = useLocation();
	const { isShown: isOpenAlbumInfoRegistrationModal, toggle: setIsOpenAlbumInfoRegistrationModal } = useModal();
	const { ctf_sendHttpPost } = useContext(BackdropContext);

	// const [isMember, setIsMember] = useState(false);
	// const [isMembership, setIsMembership] = useState(false);
	// const [isAccountInfo, setIsAccountInfo] = useState(false);
	// const [isMembershipDelay, setIsMembershipDelay] = useState(false);
	// const [isAlbumRelease, setIsAlbumRelease] = useState(false);

	const [gradeInfo, setGradeInfo] = useState<{
		isMember: boolean;
		isMembership: boolean;
		isAccountInfo: boolean;
		isMembershipDelay: boolean;
		isAlbumRelease: boolean;
	}>({
		isMember: false,
		isMembership: false,
		isAccountInfo: false,
		isMembershipDelay: false,
		isAlbumRelease: false
	});

	useEffect(() => {
		const sessionUserInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')!) : null;

		setGradeInfo({
			isMember: sessionUserInfo !== null,
			isMembership: sessionUserInfo !== null ? sessionUserInfo.is_membership : false,
			isAccountInfo:
				sessionUserInfo !== null
					? sessionUserInfo.user_rrn && sessionUserInfo.account_number && sessionUserInfo.account_holder
					: false,
			isMembershipDelay: sessionUserInfo !== null ? sessionUserInfo.is_membership_delay : false,
			isAlbumRelease: sessionUserInfo !== null ? sessionUserInfo.is_album_release : false
		});

		// setIsMember(sessionUserInfo !== null);

		// if (sessionUserInfo !== null) {
		//   setIsMembership(JSON.parse(sessionUserInfo!).is_membership);

		//   setIsAccountInfo(
		//     JSON.parse(sessionUserInfo!).user_rrn &&
		//       JSON.parse(sessionUserInfo!).account_number &&
		//       JSON.parse(sessionUserInfo!).account_holder,
		//   );
		//   setIsMembershipDelay(JSON.parse(sessionUserInfo!).is_membership_delay);
		//   setIsAlbumRelease(JSON.parse(sessionUserInfo!).is_album_release);
		// }
	}, [sessionStorage.getItem('userInfo')]);

	// @ts-ignore

	// 프로필 선택하면 로컬데이터에 저장한다.

	const goUserInfoSetting = () => {
		history.push(routePath.userInfo.userInfoSetting.path);
	};

	const handleClickOpenAlbumRegistration = (e: any) => {
		e.preventDefault();

		// 멤버십이 없거나 멤버십이 있눈데 유예기간인 경우
		if (!gradeInfo.isMembership || (gradeInfo.isMembership && gradeInfo.isMembershipDelay)) {
			history.push('/membership-info');
		} else {
			// 멤버십은 있는데 계좌인증이 안되 있는 경우
			if (!gradeInfo.isAccountInfo) {
				history.push('/payment-completed');
			} else {
				setIsOpenAlbumInfoRegistrationModal();
			}
		}
	};

	useEffect(() => {
		if (isOpenAlbumInfoRegistrationModal) {
			if (typeof window !== 'undefined' && window.document) {
				document.body.style.overflow = 'hidden';
			}
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [isOpenAlbumInfoRegistrationModal]);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [curMenu, setCurMenu] = useState<string>('');

	const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
		setAnchorEl(event.currentTarget);
		setCurMenu(id);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setCurMenu('');
	};

	const goLogout = async () => {
		const res = await ctf_sendHttpPost('/logout', {});
		removeAuth();
		const redirectUri = routePath.portal.path;
		history.push(redirectUri);
	};

	// @ts-ignore
	return (
		<>
			<Layout layoutType="MAIN_HEADER">
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					width="calc(100% - 10vw)"
					paddingX="5vw"
				>
					<Stack direction="row" alignItems="center" flex="1">
						<img
							src={mixtapeLogo}
							style={{
								// width: '180px',
								height: '45px',
								cursor: 'pointer',
								marginRight: '8.23vw'
							}}
							onClick={() => {
								history.push('/');
							}}
						/>

						<Menus
							gradeInfo={gradeInfo}
							setIsOpenAlbumInfoRegistrationModal={setIsOpenAlbumInfoRegistrationModal}
						></Menus>
					</Stack>
					{gradeInfo.isMember && (
						<Stack direction="row" justifyContent="flex-end" alignItems="center">
							<ThemeProvider theme={theme}>
								<TextButton
									iscurmenu={pathname.includes('setting').toString()}
									onClick={(e) => handleClick(e, 'setting')}
									sx={{
										marginLeft: '0px!important',
										marginRight: gradeInfo.isMembershipDelay ? '0px!important' : '2.08vw!important',
										textTransform: 'none'
									}}
									// onClick={goUserInfoSetting}
								>
									{userInfo.user_id} 님
								</TextButton>
								<Menu
									anchorEl={anchorEl}
									open={curMenu === 'setting'}
									onClose={handleClose}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left'
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left'
									}}
								>
									<MenuItem
										sx={{
											'&:hover': {
												color: '#effd60'
											},
											padding: '12px 16px'
										}}
										onClick={() => goUserInfoSetting()}
									>
										계정설정
									</MenuItem>
									<MenuItem
										sx={{
											'&:hover': {
												color: '#effd60'
											},
											padding: '12px 16px'
										}}
										onClick={() => goLogout()}
									>
										로그아웃
									</MenuItem>
								</Menu>
							</ThemeProvider>

							{!gradeInfo.isMembershipDelay && (
								<ActionButton
									onClick={handleClickOpenAlbumRegistration}
									style={{
										background: '#effd60',
										color: '#121212'
									}}
								>
									앨범발매
								</ActionButton>
							)}
						</Stack>
					)}
				</Stack>
				<AlbumInfoRegistrationModal
					isOpenAlbumInfoRegistrationModal={isOpenAlbumInfoRegistrationModal}
					setIsOpenAlbumInfoRegistrationModal={setIsOpenAlbumInfoRegistrationModal}
				/>
			</Layout>
		</>
	);
}

export default MainHeader;
