import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Modal from '@components/form/Modal';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { SelectUserTypeButton } from '@components/molecules/SignUp/Button';
import { routePath } from '@constants/path';
import { setDataInSessionStorage } from '@utils/data';
import history from '@utils/history';

type TSelectUserTypeModalProps = {
  setIsOpenSignUpModal: () => void;
  isOpenSignUpModal: boolean;
};

function SelectUserTypeModal({ setIsOpenSignUpModal, isOpenSignUpModal }: TSelectUserTypeModalProps) {
  const goUserSignUp = (userType: 'LOCAL' | 'FOREIGNER') => {
    const redirectUri = routePath.signup.path;
    sessionStorage.setItem('userType', userType);
    history.push(redirectUri);
  };

  return (
    <Modal
      headerText=""
      // wrapperStyle={{ width: '660px' }}
      modalStyle={{ width: '660px' }}
      hide={setIsOpenSignUpModal}
      isShown={isOpenSignUpModal}
    >
      <Layout>
        <Form>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Text textType="TITLE" style={{ marginTop: '30px', marginBottom: '20px' }}>
              회원가입
            </Text>
            <Text textType="DESCRIPTION" style={{ fontSize: '18px', color: '#767676', marginBottom: '40px' }}>
              회원님께 해당하는 유형을 선택해 주세요.
            </Text>
            <Stack direction="row" gap={'16px'}>
              <SelectUserTypeButton
                onClick={() => {
                  goUserSignUp('FOREIGNER');
                }}
              >
                <img width={'44px'} src="/assets/icon/global.svg" style={{ marginBottom: '17px' }}></img>
                <Typography fontSize="18px" fontWeight={700}>
                  외국인 회원
                </Typography>
                <Typography fontSize="14px" color="#767676">
                  만 19세 이상
                </Typography>
              </SelectUserTypeButton>
              <SelectUserTypeButton
                onClick={() => {
                  goUserSignUp('LOCAL');
                }}
              >
                <img width={'44px'} src="/assets/icon/user.svg" style={{ marginBottom: '17px' }}></img>
                <Typography fontSize="18px" fontWeight={700}>
                  내국인 회원
                </Typography>
                <Typography fontSize="14px" color="#767676">
                  만 19세 이상
                </Typography>
              </SelectUserTypeButton>
            </Stack>
            <Text textType="DESCRIPTION" style={{ color: '#767676', marginTop: '40px', marginBottom: '50px' }}>
              19세 미만은 회원 가입이 불가능합니다.
            </Text>
          </Stack>
          {/* <Stack justifyContent='center' alignItems='center' mt={5} mb={3}>
            <Typography sx={{fontSize:'18px', fontFamily:'inherit'}}>
              회원 종류를 선택해 주세요.
            </Typography>
            </Stack>
          <HDivider margin="10px" type="TRANSPARENT" />
          <Stack spacing={3}>
            <SelectUserTypeButton
              onClick={() => {
                goUserSignUp('LOCAL');
              }}
            >
              만 19세 이상 내국인
            </SelectUserTypeButton>

            <SelectUserTypeButton
              onClick={() => {
                goUserSignUp('FOREIGNER');
              }}
            >
              만 19세 이상 외국인
            </SelectUserTypeButton>





            <Typography pt={1} textAlign='center' sx={{fontSize:'15px', fontFamily:'inherit'}}>
              만 19세 미만 회원가입은<br/> 베타서비스 이후 오픈 예정입니다.
            </Typography>
          </Stack> */}
        </Form>
      </Layout>
    </Modal>
  );
}

export default SelectUserTypeModal;
