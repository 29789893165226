import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { certificateAuthNumber, getAuthNumber } from '@api/mapper/User';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Input from '@components/form/Input';
import InputSuffix from '@components/form/InputSuffix';
import Label from '@components/form/Label';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { FindPasswordButton, GetAuthNumberButton, ResendAuthNumberButton } from '@components/molecules/FindPassword/Button';
import { ReConfirmNumberText } from '@components/molecules/FindPassword/Text';
import { AUTH_FIND_PASSWORD_AUTHENTICATION_ACTION, AUTH_FIND_PASSWORD_GET_NUMBER_ACTION } from '@constants/actionNames';
import { useFormError } from '@hooks/useFormError';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import { useTimer } from '@hooks/useTimer';
import { Icon } from '@images/Icon';
import { autoHypenPhone, getDisabledWithFormError, isEmpty, replaceAll } from '@utils/data';

import { TCurrentStep } from '.';
import AlertDialog from '@components/form/AlertDialog';
import { usePopupMessage } from '@hooks/usePopupMessage';

type TAuthNumberCertificationProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  isShowAuthNumberInput: boolean;
  setIsShowAuthNumberInput: any;
  id?: string;
  setAuthId?: (authId: string) => void;
};

function AuthNumberCertification({
  setCurrentStep,
  isShowAuthNumberInput,
  setIsShowAuthNumberInput,
  id,
  setAuthId,
}: TAuthNumberCertificationProps) {
  const { openState: uc_open, setOpenState: uf_setOpen, message: uc_message, popupMessage: uf_popupMessage } = usePopupMessage();
  
  const [certificationStatus, setCertificationStatus] = useState<'READY' | 'SUCCESS' | 'FAIL'>('READY');
  const { request: getAuthNumberReq, resData: getAuthNumberRes } = useRequest({
    reduxActionName: AUTH_FIND_PASSWORD_GET_NUMBER_ACTION,
    mapperFunc: getAuthNumber,
  });

  const {
    request: certificateAuthNumberReq,
    resData: certificateAuthNumberRes,
    requestLoading: certificateAuthNumberLoading,
    errorMessage: certificateAuthNumberErrorMessage,
  } = useRequest({
    reduxActionName: AUTH_FIND_PASSWORD_AUTHENTICATION_ACTION,
    mapperFunc: certificateAuthNumber,
  });

  const { formData, formFieldsError, validation, setFormData } = useReduxForm(AUTH_FIND_PASSWORD_GET_NUMBER_ACTION, (params) =>
    getAuthNumberReq(params),
  );

  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const { minutes, seconds, setMinutes, setSeconds } = useTimer({ stopCondition: certificationStatus === 'SUCCESS' });

  const setFindPasswordFormData = (e: any, dataIndex: string, isValue?: boolean) => {
    let value = '';
    if (isValue) {
      value = e;
    } else {
      value = e.target.value;
    }
    if (dataIndex === 'phone_num') {
      value = autoHypenPhone(value);
    }
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
  };

  const getIsDisabledAuthNumBtn = () => {
    const isDisabled = getDisabledWithFormError(formError, ['name', 'phone_num']);

    if (isDisabled) {
      return true;
    }

    if (isShowAuthNumberInput) {
      return true;
    }
    return false;
  };

  const handleClickFindPassword = (e: any) => {
    e.preventDefault();
    setCurrentStep('RESET');
  };

  useEffect(() => {
    if (minutes === 0 && parseInt(seconds, 10) === 0) {
      setCertificationStatus('FAIL');
    }
    if (certificateAuthNumberErrorMessage) {
      setCertificationStatus('FAIL');
    }
    if (certificateAuthNumberRes) {
      setCertificationStatus('SUCCESS');
    }
  }, [minutes, seconds, certificateAuthNumberRes, certificateAuthNumberErrorMessage]);

  useEffect(() => {
    if(getAuthNumberRes){
      if(getAuthNumberRes.code === "404"){
        uf_popupMessage('회원 가입 시, 등록한 정보와 일치하지 않습니다. \n다시 확인해 주세요.');
      }else{
        if (setAuthId) {
          setAuthId(getAuthNumberRes.res_auth_id);
        }
        setIsShowAuthNumberInput(true);
      }
    } 

  }, [getAuthNumberRes]);


  

  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="9px" type="TRANSPARENT" />
        <Text textType="DESCRIPTION">비밀번호 재설정을 위해 휴대전화 번호를 인증해 주세요.</Text>
        <HDivider margin="8px" type="TRANSPARENT" />
        <Label htmlFor="name">이름</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <Input
          id="name"
          onChange={(e: ChangeEvent) => {
            setFindPasswordFormData(e, 'name');
          }}
          placeholder="이름"
          type="text"
          value={formData.name ?? ''}
        />
        <HDivider margin="10px" type="TRANSPARENT" />
        <Label htmlFor="phone_num">휴대전화</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <Row justify="SPACE_BETWEEN">
          <Input
            id="phone_num"
            onChange={(e: ChangeEvent) => {
              setFindPasswordFormData(e, 'phone_num');
            }}
            placeholder="010-0000-0000"
            style={{ flex: 7 }}
            type="text"
            value={formData.phone_num ?? ''}
          />
          <VDivider margin="5px" type="TRANSPARENT" />
          <GetAuthNumberButton
            disabled={getIsDisabledAuthNumBtn()}
            onClick={(e) => {
              e.preventDefault();
              // setIsShowAuthNumberInput(true);
              getAuthNumberReq({
                user_id: id,
                phone_num: replaceAll(formData.phone_num, '-', ''),
                auth_type: 'find-pw',
              });
              setSeconds(0);
              setMinutes(3);
            }}
            style={{ flex: 3 }}
          >
            인증번호 받기
          </GetAuthNumberButton>
        </Row>
        {isShowAuthNumberInput && (
          <>
            <HDivider margin="10px" type="TRANSPARENT" />
            <Label htmlFor="auth_code">인증번호</Label>
            <HDivider margin="6px" type="TRANSPARENT" />
            <InputSuffix
              id="auth_code"
              onChange={(e: ChangeEvent) => {
                if (!certificateAuthNumberLoading) {
                  setFindPasswordFormData(e, 'auth_code');
                  const { value } = e.target as any;
                  if (value.length === 6) {
                    certificateAuthNumberReq({
                      phone_num: replaceAll(formData.phone_num, '-', ''),
                      auth_code: value,
                      res_auth_id: getAuthNumberRes.res_auth_id,
                    });
                  }
                }
              }}
              placeholder="인증번호 입력"
              suffix={
                <>
                  <div
                    className={minutes === 0 && parseInt(seconds, 10) === 0 ? 'time' : 'time_enable'}
                  >{`${minutes}:${seconds}`}</div>
                  <div>
                    <Icon icon={certificationStatus === 'SUCCESS' ? 'btnCheck' : 'btnUnCheck'} />
                  </div>
                </>
              }
              type="number"
              value={formData.auth_code ?? ''}
            />
            {certificationStatus === 'FAIL' && (
              <>
                <HDivider margin="5px" type="TRANSPARENT" />
                <Row justify="FLEX_START">
                  <ReConfirmNumberText>인증번호를 다시 확인해 주세요</ReConfirmNumberText>
                  <VDivider margin="8px" type="TRANSPARENT" />
                  <ResendAuthNumberButton
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowAuthNumberInput(true);
                      getAuthNumberReq({
                        phone_num: replaceAll(formData.phone_num, '-', ''),
                        auth_type: 'find-id',
                      });
                      setSeconds(0);
                      setMinutes(3);
                      setCertificationStatus('READY');
                    }}
                  >
                    인증번호 재전송
                  </ResendAuthNumberButton>
                </Row>
              </>
            )}
          </>
        )}
        <HDivider margin="15px" type="TRANSPARENT" />
        <FindPasswordButton disabled={certificationStatus !== 'SUCCESS'} onClick={handleClickFindPassword}>
          다음
        </FindPasswordButton>
      </Form>
      <AlertDialog isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />
    </Layout>
  );
}

export default AuthNumberCertification;
