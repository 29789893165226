import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

// AlbumManagement
import AlbumManagementContainer from '@containers/AlbumManagement';
// Login
import LoginContainer from '@containers/Login';
// NotFound
import NotFoundErrorContainer from '@containers/NotFoundError';
// Portal
import PortalContainer from '@containers/Portal';
// ProfileManagement
import ProfileManagementContainer from '@containers/ProfileManagement';
// SettlementManagement
import SettlementManagementContainer from '@containers/SettlementManagement';
// SignUp
import SignUpContainer from '@containers/SignUp';
// UserInfoSetting
import UserInfoSettingContainer from '@containers/UserInfoSetting';

import IntroductionContainer from '@containers/Intro/Introduction';
import MembershipGuideContainer from '@containers/Intro/MembershipGuide';
import MenualContainer from '@containers/Intro/Manual';
import ChannelContainer from '@containers/Intro/Channel';

import AcceleratingContainer from '@containers/Promotion/Accelerating';

import FaqContainer from '@containers/Help/Faq';
import FaqWriteContainer from '@containers/Help/Faq/Write';
import NoticeContainer from '@containers/Help/Notice';
import NoticeDetailContainer from '@containers/Help/Notice/Detail';

import QnaContainer from '@containers/Help/Qna';

import TermsContainer from "@containers/Terms"

type TErrorObj = {
  message?: string;
};

type TErrorFallbackParams = {
  error: TErrorObj;
};

function ErrorFallback({ error = {} }: TErrorFallbackParams) {
  return (
    <div>
      <span>{error.message}</span>
    </div>
  );
}

const selector = (state: any) => state.globalReducer.requestLoading;

const Loadable = () => {
  const requestLoading: any = useSelector(selector);
  if (requestLoading) {
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.3)',
          zIndex: 500,
        }}
      >
        <TailSpin
          ariaLabel="tail-spin-loading"
          // color="#4fa94d"
          color="#EFFD60"
          height="40"
          radius="0"
          visible
          width="40"
          wrapperClass=""
          wrapperStyle={{
            position: 'fixed',
            top: '50%',
            left: '50%',
          }}
        />
      </div>
    );
  }
  return null;
};

const withSpinner = (Component: any) => (props: any) =>
  (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Component {...props} />
      <Loadable />
    </ErrorBoundary>
  );

export const Portal = withSpinner(PortalContainer);
export const Login = withSpinner(LoginContainer);
export const SignUp = withSpinner(SignUpContainer);
// 앨범 관리
export const AlbumManagement = withSpinner(AlbumManagementContainer);

// 계정설정
export const ProfileManagement = withSpinner(ProfileManagementContainer);
// 정산
export const SettlementManagement = withSpinner(SettlementManagementContainer);
export const UserInfoSetting = withSpinner(UserInfoSettingContainer);
export const NotFoundError = withSpinner(NotFoundErrorContainer);

// 믹스테이프 소개
export const Introduction = withSpinner(IntroductionContainer);
export const MembershipGuide = withSpinner(MembershipGuideContainer);
export const Menual = withSpinner(MenualContainer);
export const Channel = withSpinner(ChannelContainer);

export const Accelerating = withSpinner(AcceleratingContainer);

// 고객센터
export const Faq = withSpinner(FaqContainer);
export const FaqWrite = withSpinner(FaqWriteContainer);
export const Notice = withSpinner(NoticeContainer);
export const NoticeDetail = withSpinner(NoticeDetailContainer);
export const Qna = withSpinner(QnaContainer);

export const Terms = withSpinner(TermsContainer);