import { Grid, IconButton, Link, Stack, SvgIcon, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

import Layout from '@components/form/Layout';
import Text from '@components/form/Text';
import { Icon } from '@images/Icon';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import BlogIcon from './assets/icon/blog.svg';

const snsButtonStyle = {
  color: '#FFFFFF',
  width: 43,
  height: 43,
  border: '#FFF solid 1px',
  '&:hover': {
    color: '#effd60',
    borderColor: '#effd60',
  },
};
const linkStyle = {
  fontWeight: 400,
  fontSize: '14px',
  color: '#FFFFFF',
  cursor: 'pointer',
};
const txtStyle = {
  fontWeight: 400,
  fontSize: '14px',
  color: '#BCBCBC',
  fontFamily: 'inherit',
};

function MainFooter(): ReactElement {
  const openInstagram = () => {
    window.open('https://www.instagram.com/mixtape.so/', '_blank');
  };
  const openFacebook = () => {
    window.open('https://www.facebook.com/mixtape.so', '_blank');
  };

  const openYoutube = () => {
    window.open('https://www.youtube.com/@mixtape.official', '_blank');
  };

  const openBlog = () => {
    window.open('https://blog.mixtape.so/', '_blank');
  };

  return (
    <Layout
      layoutType="FOOTER"
      style={{
        paddingLeft: '60px',
        paddingRight: '55px',
      }}
    >
      <Grid container>
        {/* <Stack direction="column" justify="FLEX_START" style={{ alignItems: 'flex-start', hieght: '100%' }}>  */}
        <Grid item={true} xs={8}>
          <Stack direction="column" alignItems="flex-start">
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  ...txtStyle,
                  fontSize: '16px',
                }}
                mr={4}
              >
                Follow mixtape.
              </Typography>
              <Stack direction="row" spacing={2}>
                <IconButton sx={snsButtonStyle} onClick={openInstagram}>
                  <InstagramIcon />
                </IconButton>
                <IconButton sx={snsButtonStyle} onClick={openFacebook}>
                  <FacebookIcon />
                </IconButton>

                <IconButton sx={snsButtonStyle} onClick={openYoutube}>
                  <YouTubeIcon />
                </IconButton>
                <IconButton sx={snsButtonStyle} onClick={openBlog}>
                  {/* <SvgIcon component={() => <img src="/assets/icon/blog.svg" alt="blog" />}></SvgIcon> */}
                  <img width="20px" src="/assets/icon/blog.svg"></img>
                </IconButton>
              </Stack>
            </Stack>

            <Stack direction="row" mt={6} spacing={3}>
              <Link href="/terms/privacy" underline="hover" style={linkStyle}>
                개인정보처리방침
              </Link>

              <Link href="/terms/terms-of-use" underline="hover" style={linkStyle}>
                이용약관
              </Link>

              <Link href="/help/qna" underline="hover" style={linkStyle}>
                고객센터
              </Link>

              <Link
                href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1208167338"
                target="_blank"
                underline="hover"
                style={linkStyle}
              >
                사업자정보 확인
              </Link>
            </Stack>

            <Stack spacing={1.5} mt={3}>
              <Typography sx={txtStyle}>
                (주)와이지플러스 07326 서울특별시 영등포구 국제금융로 10 19F (여의도동, Three IFC)
              </Typography>
              <Stack direction="row" spacing={4}>
                <Typography sx={txtStyle}>대표이사 최성준</Typography>
                <Typography sx={txtStyle}>사업자등록번호 120-81-67338</Typography>
                <Typography sx={txtStyle}>통신판매업신고번호 2022-서울영등포-3059</Typography>
              </Stack>
              <Stack direction="row" spacing={4}>
                <Text
                  style={{
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#BCBCBC',
                  }}
                  textType="DESCRIPTION"
                >
                  대표전화 02-3140-4600
                </Text>

                <Text
                  style={{
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#BCBCBC',
                  }}
                  textType="DESCRIPTION"
                >
                  이메일&nbsp;
                  <Link
                    href="mailto:mixtape@ygmail.net"
                    target="_blank"
                    underline="hover"
                    style={{
                      ...linkStyle,
                      color: '#BCBCBC',
                    }}
                  >
                    mixtape@ygmail.net
                  </Link>
                </Text>

                <Text
                  style={{
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#BCBCBC',
                  }}
                  textType="DESCRIPTION"
                >
                  © YG PLUS
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item={true} container direction="row" justifyContent="flex-end" alignItems="flex-end" pr={7} xs={4}>
          <Icon
            icon="powered_by_ygp"
            style={{
              height: '20px',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open('http://www.ygplus.com/', '_blank');
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default MainFooter;
