import { useDispatch, useSelector } from 'react-redux';

import getActionCreators from '@/core/redux/getActionCreators';
import { useInjectResourceControllerReducer } from '@/core/redux/getReducer';
import getSelectors from '@/core/redux/getSelectors';
import { AWaited } from '@api/mapper/types';
import { request } from '@api/request';
import { createStructuredSelector } from 'reselect';

// import useHttpError from './useHttpError';

type TUseRequestParams<T> = {
	reduxActionName: string;
	mapperFunc: T;
	successMessage?: string;
	isAutoCatchingError?: boolean;
};

type TSelectedResults<T extends (...args: any[]) => any> = Partial<{
	resData: AWaited<ReturnType<T>>['data'];
	requestLoading: boolean;
	requestSuccess: boolean;
	error: any;
	errorData: any;
	errorMessage: any;
	parameters: Partial<Parameters<T>[0]>;
	reqParameters: Partial<Parameters<T>[0]>;
}>;

interface TUseRequestReturn<T extends (...args: any[]) => any> extends Omit<TSelectedResults<T>, 'parameters'> {
	request: (data?: Parameters<T>[0]) => void;
	requestForSingle: any;
	clearData: any;
	parameters: any;
}

export function useRequest<T extends (...args: any[]) => any>({
	reduxActionName,
	mapperFunc,
	successMessage,
	isAutoCatchingError = true
}: TUseRequestParams<T>): TUseRequestReturn<T> {
	const {
		makeSelectParameters,
		makeSelectData,
		makeSelectRequestLoading,
		makeSelectRequestSuccess,
		makeSelectRequestError,
		makeSelectRequestErrorMessage,
		makeSelectRequestErrorData
	} = getSelectors(reduxActionName);
	useInjectResourceControllerReducer(reduxActionName);

	const { resData, requestLoading, requestSuccess, error, errorData, errorMessage, parameters } = useSelector(
		createStructuredSelector<TSelectedResults<T>>({
			resData: makeSelectData,
			requestLoading: makeSelectRequestLoading,
			requestSuccess: makeSelectRequestSuccess,
			error: makeSelectRequestError,
			errorData: makeSelectRequestErrorData,
			errorMessage: makeSelectRequestErrorMessage,
			parameters: makeSelectParameters
		})
	);

	const dispatch = useDispatch();

	// useHttpError({ errorMessage, isAutoCatchingError });

	const { clearData } = getActionCreators(reduxActionName);

	const defaultOptionsForRequest = {
		reduxActionName,
		mapperFunc,
		dispatch,
		successMessage,
		isSingleRequest: false
	};

	return {
		resData,
		requestLoading,
		requestSuccess,
		error,
		errorData,
		errorMessage,
		parameters,
		request: (data = {}) => request({ ...defaultOptionsForRequest, params: { ...(parameters ?? {}), ...data } }),
		requestForSingle: (data = {}) =>
			request({
				...defaultOptionsForRequest,
				params: { ...(parameters ?? {}), ...data },
				isSingleRequest: true
			}),
		clearData: () => dispatch(clearData())
	};
}
