import ScrollToTop from '@containers/App/Nav/ScrollToTop';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import initializeStore from '@/core/redux/initializeStore';
import App from '@containers/App';
import history from '@utils/history';
import { ConnectedRouter } from 'connected-react-router';
import { GlobalScrollbar } from 'mac-scrollbar';
import '@/mac-scrollbar.css';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('b08f56d812e504e42ac3d0b47f359c96Tz04NjI2NyxFPTE3NDE4NTM2NTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
const store = initializeStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop />
      <GlobalScrollbar />
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
