import Layout from '@components/form/Layout';
import styled from '@emotion/styled';

export const SettlementViewBox = styled(Layout)`
  padding-top: 162px;
  color: #fff;
  background: #000;
  justify-content: center;
  align-items: center;
`;

export const SettlementTitleBox = styled(Layout)`
  background: #1e1e1e;
  color: #fff;
  padding-left: calc(36% - 360px);
  padding-right: calc(36% - 360px);
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
`;

export const SettlementTableContainer = styled(Layout)`
  background: #1e1e1e;
  color: #fff;
  padding-left: calc(36% - 360px);
  padding-right: calc(36% - 360px);
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
`;
