import React, { Dispatch, SetStateAction } from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import { ConfirmButton } from '@components/molecules/FindPassword/Button';

import { TCurrentStep } from '.';

type TCompletedProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  setIsOpenUpdateProfileModal: any;
};

function Completed({ setCurrentStep, setIsOpenUpdateProfileModal }: TCompletedProps) {
  const confirm = () => {
    setIsOpenUpdateProfileModal();
    setCurrentStep('UPDATE');
  };


  return (
    <Layout>
      <Form style={{ width: '100%' }}>
        <HDivider margin="48px" type="TRANSPARENT" />
        <Row direction='column' justify="CENTER">
          <Text textType="DESCRIPTION">아티스트 정보 수정 신청이 완료되었습니다.</Text>
          <Text textType="DESCRIPTION">수정 신청한 내용은 관리자 검토 후 반영됩니다.</Text>
        </Row>
        <HDivider margin="46px" type="TRANSPARENT" />
        <ConfirmButton
          onClick={() => {
            confirm();
          }}
        >
          확인
        </ConfirmButton>
      </Form>
    </Layout>
  );
}

export default Completed;
