import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';

import { certificateAuthNumber, findId, getAuthNumber } from '@api/mapper/User';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Input from '@components/form/Input';
import InputSuffix from '@components/form/InputSuffix';
import Label from '@components/form/Label';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { FindIdButton, GetAuthNumberButton, ResendAuthNumberButton } from '@components/molecules/FindId/Button';
import { ReConfirmNumberText } from '@components/molecules/FindId/Text';
import { AUTH_FIND_ID_ACTION, AUTH_FIND_ID_AUTHENTICATION_ACTION, AUTH_FIND_ID_GET_NUMBER_ACTION } from '@constants/actionNames';
import { useFormError } from '@hooks/useFormError';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import { useTimer } from '@hooks/useTimer';
import { Icon } from '@images/Icon';
import { autoHypenPhone, getDisabledWithFormError, isEmpty, replaceAll } from '@utils/data';

import { TCurrentStep } from '.';

type TAuthNumberCertificationProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
  isShowAuthNumberInput: boolean;
  setIsShowAuthNumberInput: any;
};

function AuthNumberCertification({
  setCurrentStep,
  isShowAuthNumberInput,
  setIsShowAuthNumberInput,
}: TAuthNumberCertificationProps) {
  const [certificationStatus, setCertificationStatus] = useState<'READY' | 'SUCCESS' | 'FAIL'>('READY');
  const { request, resData, errorMessage } = useRequest({
    reduxActionName: AUTH_FIND_ID_ACTION,
    mapperFunc: findId,
  });
  const { request: getAuthNumberReq, resData: getAuthNumberRes } = useRequest({
    reduxActionName: AUTH_FIND_ID_GET_NUMBER_ACTION,
    mapperFunc: getAuthNumber,
  });
  const {
    request: certificateAuthNumberReq,
    resData: certificateAuthNumberRes,
    requestLoading: certificateAuthNumberLoading,
    errorMessage: certificateAuthNumberErrorMessage,
  } = useRequest({
    reduxActionName: AUTH_FIND_ID_AUTHENTICATION_ACTION,
    mapperFunc: certificateAuthNumber,
  });
  const [authId, setAuthId] = useState(null);
  const { formData, formFieldsError, validation, setFormData, handleSubmit } = useReduxForm(AUTH_FIND_ID_ACTION, (params) =>
    request({ name: params.name, phone_number: params.phone_num, res_auth_id: authId }),
  );
  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const { minutes, seconds, setMinutes, setSeconds } = useTimer({ stopCondition: certificationStatus === 'SUCCESS' });

  const setFindIdFormData = (e: any, dataIndex: string, isValue?: boolean) => {
    let value = '';
    if (isValue) {
      value = e;
    } else {
      value = e.target.value;
    }
    if (dataIndex === 'phone_num') {
      value = autoHypenPhone(value);
    }
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
  };

  const getIsDisabledAuthNumBtn = () => {
    const isDisabled = getDisabledWithFormError(formError, ['name', 'phone_num']);

    if (isDisabled) {
      return true;
    }
    if (isShowAuthNumberInput) {
      return true;
    }
    return false;
  };

  const handleClickFindId = (e: any) => {
    e.preventDefault();
    handleSubmit();
  };

  useEffect(() => {
    if(getAuthNumberRes){
      if(getAuthNumberRes.code === "404"){
        setCurrentStep('FOUND');
      }else{
        setAuthId(getAuthNumberRes.res_auth_id);
        setIsShowAuthNumberInput(true);
      }
    } 
  }, [getAuthNumberRes]);

  useEffect(() => {
    if (minutes === 0 && parseInt(seconds, 10) === 0) {
      setCertificationStatus('FAIL');
    }
    if (certificateAuthNumberErrorMessage) {
      setCertificationStatus('FAIL');
    }
    if (certificateAuthNumberRes) {
      setCertificationStatus('SUCCESS');
    }
  }, [minutes, seconds, certificateAuthNumberRes, certificateAuthNumberErrorMessage]);

  useEffect(() => {
    if(resData && resData.code === "404") {
      setCurrentStep('CANTFOUND');
    } else if (resData) {
      setCurrentStep('FOUND');
    };
    // if (resData) {
    //   setCurrentStep('FOUND');
    // }
    // if (errorMessage) {
    //   setCurrentStep('CANTFOUND');
    // }
  }, [resData]);

  return (
    <Layout>
      <Form>
        <HDivider margin="9px" type="TRANSPARENT" />
        <Row direction="column" justify="CENTER">
          <Text textType="DESCRIPTION">회원정보에 등록한 휴대전화 번호 인증 후 아이디를 확인하실 수 있습니다.</Text>
          <Text textType="DESCRIPTION">정보 보호를 위해 아이디 뒷자리는 숨김 처리됩니다.</Text>
        </Row>
        <HDivider margin="8px" type="TRANSPARENT" />
        <Label htmlFor="name">이름</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <Input
          id="name"
          onChange={(e: ChangeEvent) => {
            setFindIdFormData(e, 'name');
          }}
          placeholder="이름"
          type="text"
          value={formData.name ?? ''}
        />
        <HDivider margin="10px" type="TRANSPARENT" />
        <Label htmlFor="phone_num">휴대전화</Label>
        <HDivider margin="6px" type="TRANSPARENT" />
        <Row justify="SPACE_BETWEEN">
          <Input
            id="phone_num"
            onChange={(e: ChangeEvent) => {
              setFindIdFormData(e, 'phone_num');
            }}
            placeholder="010-0000-0000"
            style={{ flex: 7 }}
            type="text"
            value={formData.phone_num ?? ''}
          />
          <VDivider margin="5px" type="TRANSPARENT" />
          <GetAuthNumberButton
            disabled={getIsDisabledAuthNumBtn()}
            onClick={(e) => {
              e.preventDefault();

              getAuthNumberReq({
                phone_num: replaceAll(formData.phone_num, '-', ''),
                auth_type: 'find-id',
                name: formData.name,
              });
              setSeconds(0);
              setMinutes(3);
            }}
            style={{ flex: 3 }}
          >
            인증번호 받기
          </GetAuthNumberButton>
        </Row>
        {isShowAuthNumberInput && (
          <>
            <HDivider margin="10px" type="TRANSPARENT" />
            <Label htmlFor="auth_code">인증번호</Label>
            <HDivider margin="6px" type="TRANSPARENT" />
            <InputSuffix
              id="auth_code"
              onChange={(e: ChangeEvent) => {
                if (!certificateAuthNumberLoading) {
                  setFindIdFormData(e, 'auth_code');
                  const { value } = e.target as any;
                  if (value.length === 6) {
                    certificateAuthNumberReq({
                      phone_num: replaceAll(formData.phone_num, '-', ''),
                      auth_code: value,
                      name: formData.name,
                      res_auth_id: authId,
                    });
                  }
                }
              }}
              placeholder="인증번호 입력"
              suffix={
                <>
                  <div
                    className={minutes === 0 && parseInt(seconds, 10) === 0 ? 'time' : 'time_enable'}
                  >{`${minutes}:${seconds}`}</div>
                  <div>
                    <Icon icon={certificationStatus === 'SUCCESS' ? 'btnCheck' : 'btnUnCheck'} />
                  </div>
                </>
              }
              type="number"
              value={formData.auth_code ?? ''}
            />
            {certificationStatus === 'FAIL' && (
              <>
                <HDivider margin="5px" type="TRANSPARENT" />
                <Row justify="FLEX_START">
                  <ReConfirmNumberText>인증번호를 다시 확인해 주세요</ReConfirmNumberText>
                  <VDivider margin="8px" type="TRANSPARENT" />
                  <ResendAuthNumberButton
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowAuthNumberInput(true);
                      getAuthNumberReq({
                        phone_num: replaceAll(formData.phone_num, '-', ''),
                        auth_type: 'find-id',
                        name: formData.name,
                      });
                      setSeconds(0);
                      setMinutes(3);
                      setCertificationStatus('READY');
                    }}
                  >
                    인증번호 재전송
                  </ResendAuthNumberButton>
                </Row>
              </>
            )}
          </>
        )}
        <HDivider margin="15px" type="TRANSPARENT" />
        <FindIdButton disabled={certificationStatus !== 'SUCCESS'} onClick={handleClickFindId}>
          아이디 찾기
        </FindIdButton>
      </Form>
    </Layout>
  );
}

export default AuthNumberCertification;
