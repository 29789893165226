import AlertModal from '@components/form/AlertModal';
import { ProfileContext } from '@containers/App';
import { BackdropContext } from '@hooks/usePopupMessage';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { Link, Stack, Tooltip, Typography } from '@mui/material';
import { callProfileData } from '@utils/api';
import { $mixtapeUserSeq } from '@utils/auth';

import { floor } from 'lodash';
import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

import { addProfile } from '@api/mapper/User';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import ImageFile from '@components/form/ImageFile';
import Input from '@components/form/Input';

import Label from '@components/form/Label';
import Layout from '@components/form/Layout';
import AddIcon from '@mui/icons-material/Add';

import Row from '@components/form/Row';

import Text from '@components/form/Text';
import Textarea from '@components/form/Textarea';
import VDivider from '@components/form/VDivider';

import {
  CancelUpdateProfileButton,
  ConfirmUpdateProfileButton,
  DownloadGuideBookButton,
} from '@components/molecules/ProfileManagement/Button';
import { PROFILE_INFO_ADD_ACTION, USER_INFO_EDIT_DORMANT_ACCOUNT_ACTION } from '@constants/actionNames';
import { useFormError } from '@hooks/useFormError';
import { useReduxForm } from '@hooks/useReduxForm';
import { Icon } from '@images/Icon';

const MAX_LENGTH_OF_ARTIST_INTRODUCTION = 4000;

function UpdateProfile({
                         setCurrentStep,
                         selectedProfile,
                         setIsOpenUpdateProfileModal,
                         callback,
                       }) {
  const { refreshProfileData } = useContext(ProfileContext);

  const [imageFiles, setImageFiles] = useState([]);

  const checkImgFile = (file: any, sizePx: any, param: any, func: any) => {
    if (!file.type.startsWith('image/')) {
      popupMessage('이미지파일만 선택가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const fileMB = floor((file.size / 1024 / 1024) * 100) / 100;
      if (fileMB > 10) {
        popupMessage('10메가를 초과하면 안됩니다.');
        return;
      }

      const uint = new Uint8Array(reader.result);
      const blob = new Blob([uint], { type: file.type });
      const img = new Image();
      img.src = URL.createObjectURL(blob);
      img.onload = () => {
        if (img.width != img.height) {
          popupMessage('프로필 이미지는 10MB 이하의 2,000 X 2,000 px, JPG 파일만 등록 가능합니다.');
          return;
        }
        if (img.width > sizePx) {
          popupMessage(`이미지는 아래 규격을 반드시 지켜야 합니다\n1300 X 1300 px 이상 2000 X 2000 px 이하, 10MB 이하의 정방형`);
          return;
        }
        func(file);
      };

    };
  };

  const {
    ctf_setLoadingText,
    ctf_sendHttpGet,
    ctf_sendHttpPost,
    ctf_sendHttpPut,
  } = useContext(BackdropContext);

  const {
    getRootProps: getThumnailRootProps,
    getInputProps: getThumnailInputProps,
  } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: (acceptedFiles: any) => {

      if (acceptedFiles.length !== 1) {

        popupMessage('파일을 하나만 선택하여 주십시오.');
        return;
      }

      const file = acceptedFiles[0];
      checkImgFile(file, 3000, null, (f: any) => {

        setFormData('artist_image',
          [Object.assign(f, { preview: URL.createObjectURL(f) })],
        );
      });

    },
  });

  const {
    formData,
    formFieldsError,
    validation,
    setFormData,
    clearData,
  } = useReduxForm(PROFILE_INFO_ADD_ACTION, (params) =>
    addProfile(params),
  );

  const {
    formError,
    checkFormError,

  } = useFormError({
    formFieldsError,
    validation,
  });

  ///
  ///
  //
  const setUpdateProfileFormData = (e: any, dataIndex: string, isValue?: boolean) => {
    let value = '';
    if (isValue) {
      value = e;
    } else {
      value = e.target.value;
    }
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
  };

  // 이미지 세팅
  useEffect(() => {

    if (selectedProfile) {
      const clone = {
        ...selectedProfile,
        artist_image: [],
      };
      if (selectedProfile.artist_image?.length > 0) {
        const last = selectedProfile.artist_image.length - 1;
        clone.artist_image.push({
          ...selectedProfile.artist_image[last],
          preview: selectedProfile.artist_image[last].image_url,
        });
      }
      setFormData(clone);
    }

    return () => {
      clearData();
    };

    // setFormData({ artist_name: selectedProfile.artist_name });
    // setFormData({ biography: selectedProfile.biography });
  }, [selectedProfile]);

  // 3.22 장우주 변경 요청건 - 패러미터 변경
  //  {
  //   'artists': {
  //     'user_seq': '34',
  //     'artist_name': '블랙핑크 262626fefefef!!!@@',
  //     'biography': null,
  //     'artist_image': [
  //       {
  //         'artist_id': '26',
  //         'image_path': '/NAS/DEV/img/1/6/0/1/160164e3762366d3e7dedfa45fd73f25.jpg',
  //         'origin_name': '191222005802.jpg',
  //         'sort': 0,
  //         'is_main': 1,
  //       },
  //     ],
  //   },
  // };

  const updateProfile = async () => {
    const imgUpdate = formData.artist_image && formData.artist_image.length > 0 && !formData.artist_image[0].preview.startsWith('http');



    if (!formData.artist_name && !formData.biography && !imgUpdate) {
      popupMessage('변경하고자 하는 항목을 입력하여 주십시오.');
      return;
    }

    const params = {
      artists: {
        user_seq:$mixtapeUserSeq(),
        artist_name: formData.artist_name,
        biography: formData.biography,
      }
    };


    // 이름과 바이오그래피가 없으면 파일업로드만 처리
    let imgRes = null;
    if (imgUpdate) {
      imgRes = await upLoadFile();
    }

    if (imgRes?.length > 0) {
      params.artists.artist_image = [{
        artist_id: selectedProfile.id,
        image_path: imgRes[0].image_path,
        origin_name: imgRes[0].origin_name,
        sort: 0,
        is_main: 1,
      }];
    }
    const res = await ctf_sendHttpPost(`/artists-request/${selectedProfile.id}`, params);
    if (res.error) {
      popupMessage('프로필 수정 신청을 실패하였습니다.\다시 시도하여 주십시오.');
      return;
    }

    setCurrentStep('COMPLETED');
    setProfileData();

  };

///
  /// 업데이트 처리후 서버전송 하는 정보
  ///
  const requestAfterUpdate = async (fileResult: any) => {
    const params = {
      artists: {
        artist_name: formData.artist_name,
        biography: formData.biography,
      },
    };

  };

  const setProfileData = async () => {
    const profileRes = await callProfileData();
    refreshProfileData(profileRes);

    const res = await ctf_sendHttpGet(`/artists/${selectedProfile.id}`, {});
    if (res.error) {
      return;
    } else {
      callback(res.data);
    }
  };

  ///
  /// 파일업로드 처리
  ///   artist_image : {host}/uploads  → type: artist_update, target_id:{artists.id}
  async function upLoadFile() {
    if (!formData.artist_image || formData.artist_image.length == 0) {
      return null;
    }

    const frm = new FormData();
    const file = formData.artist_image[0];
    if (file.preview.startsWith('http')) {
      return null;
    }
    // old version
    // frm.append('type', 'artist');
    // new version
    frm.append('type', 'artist_update');
    frm.append('target_id', selectedProfile.id);
    frm.append('file', file);

    const response = await ctf_sendHttpPost('/uploads', frm);
    if (response.error) {
      popupMessage('이미지 파일 업로드를 실패하였습니다.');
      return null;
    }

    return response.data;
  }

  function closeWindow() {
    setIsOpenUpdateProfileModal(false);
  }

  const [alertText, setAlertText] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const popupMessage = (txt: any) => {
    setAlertText(txt);
    setIsAlertOpen(true);

  };
  const hideAlert = () => {
    setIsAlertOpen(false);
  };

  const getTooltipContent = () => {
    return (
      <Stack spacing={2} alignItems='center'>
        <Typography sx={{
          fontFamily: 'inherit',
          fontSize: 'inherit',
        }} align='center'>1,300 X 1,300 px 이상 2,000 X 2,000 px 이하, 10MB 이하의 정방형 JPG 파일만
          업로드 가능하며<br /> Apple Music, Spotify는 아티스트가 직접 교체해야 합니다.</Typography>

        <Link
          href='https://drive.google.com/file/d/1C2pAe-5G1zripBlrrpqgaAFAXiH8o_co/view?usp=sharing'
          target='_blank'
          sx={{
            color: '#222',
            fontSize: '14px',
            '&.MuiLink-underlineAlways': {
              textDecorationColor: '#888888',
            },
            '&.MuiLink-underlineAlways:hover': {
              textDecorationColor: '#222',
            },
          }}>
          Apple Music, Spotify 아티스트 페이지 사용 가이드북 다운로드
        </Link>
      </Stack>
    );
  };
  const tooltipStyle = {
    color: '#222222',
    bgcolor: '#FFFFFF',
    borderRadius: '10px',
    border: '#444444 solid 1px',
    maxWidth: 'none',
    fontSize: '14px',
    fontFamily: 'inherit',
  };
  return (
    <Layout>
      <Form style={{ width: '100%' }}>

        <HDivider margin='9px' type='TRANSPARENT' />
        <Row align='flex-start' justify='SPACE_BETWEEN'>
          <Row direction='column' justify='FLEX_START'>
            <Stack direction='row' alignItems='center'>
              <Text style={{
                color: '#3B3B3B',
                fontWeight: 700,
              }}>아티스트 프로필 사진</Text>
              <VDivider margin='5px' type='TRANSPARENT' />
              <Tooltip
                title={getTooltipContent()}
                componentsProps={{
                  tooltip: {
                    sx: tooltipStyle,
                  },
                }}
              >
                <HelpOutlineRoundedIcon fontSize='small' sx={{ color: '#00000080' }} />
              </Tooltip>
            </Stack>
            <HDivider margin='6px' type='TRANSPARENT' />
            <ImageFile
              description={
                <Stack alignItems='center' sx={{ width: '320px' }} spacing={4} mt={3} mb={4}>
                  <AddIcon sx={{ fontSize: 120 }} />
                  <p>업로드 하려면 이곳을 클릭하거나<br />원하는 파일을 드래그하세요.</p>

                </Stack>
              }
              fileList={formData.artist_image}
              getInputProps={getThumnailInputProps}
              getRootProps={getThumnailRootProps}

            />
            <p style={{fontSize:'12px'}}>프로필 이미지는 아래 규격을 반드시 지켜야 합니다.<br />
              <span style={{fontWeight:'bold'}}>2,000 X 2,000 px, 10MB 이하의 정방형 JPG 이미지</span></p>
          </Row>

          <VDivider margin='20px' type='TRANSPARENT' />
          <Row direction='column' justify='FLEX_START'>
            <Stack direction='row' alignItems='center'>
              <Typography pr={1.3} sx={{
                color: '#3B3B3B',
                fontWeight: 700,
                fontFamily: 'inherit',
              }}>아티스트명</Typography>
              <Tooltip
                title='아티스트명은 국내 플랫폼만 수정 가능합니다.'
                componentsProps={{
                  tooltip: {
                    sx: tooltipStyle,
                  },
                }}
              >
                <HelpOutlineRoundedIcon fontSize='small' sx={{ color: '#00000080' }} />
              </Tooltip>
            </Stack>
            <Form style={{ width: '100%' }}>
              <HDivider margin='6px' type='TRANSPARENT' />
              <Label htmlFor='artist_name'>국내</Label>
              <HDivider margin='3px' type='TRANSPARENT' />
              <Input
                id='artist_name'
                onChange={(e: ChangeEvent) => {
                  setUpdateProfileFormData(e, 'artist_name');
                }}
                placeholder='최대 한글 100자, 이모지 반영 불가'
                style={{ flex: 7 }}
                type='text'
                value={formData.artist_name ?? ''}
              />
              <HDivider margin='6px' type='TRANSPARENT' />

              <Label htmlFor='artistNameForiegn'>해외</Label>
              <HDivider margin='3px' type='TRANSPARENT' />
              <Input
                disabled
                id='artistNameForiegn'
                onChange={(e: ChangeEvent) => {
                  setUpdateProfileFormData(e, 'artistNameForiegn');
                }}
                placeholder='최대 영문 100자, 이모지 반영 불가'
                style={{ flex: 7 }}
                type='text'
                value={formData.languages && formData.languages.length > 0 ? formData.languages[0].name : '-'}
              />
              <HDivider margin='15px' type='TRANSPARENT' />
              <Text style={{
                color: '#3B3B3B',
                fontWeight: 700,
              }}>아티스트 소개글</Text>
              <HDivider margin='6px' type='TRANSPARENT' />
              <Textarea
                maxLength={MAX_LENGTH_OF_ARTIST_INTRODUCTION}
                onChange={(e: any) => {
                  setUpdateProfileFormData(e, 'biography');
                }}
                placeholder='아티스트 소개글'
                style={{ height: '232px' }}
                defaultValue={formData.biography ?? ''}
              />

              <HDivider margin='5px' type='TRANSPARENT' />
              <Row justify='SPACE_BETWEEN'>
                {formData.biography?.replace(/<br\s*\/?>/gm, '\n').length >= MAX_LENGTH_OF_ARTIST_INTRODUCTION && (
                  <Text style={{
                    fontSize: '12px',
                    color: 'red',
                  }}>최대 입력 가능한 글자 수를 초과하였습니다.</Text>
                )}
                <Row justify='FLEX_END'>
                  <Text style={{
                    fontSize: '12px',
                    paddingRight: '3px',
                  }}>
                    {formData.biography?.replace(/<br\s*\/?>/gm, '\n')
                      .length
                      .toLocaleString() ?? 0}
                  </Text>
                  <Text style={{ fontSize: '12px' }}>{` / ${MAX_LENGTH_OF_ARTIST_INTRODUCTION.toLocaleString()}`}</Text>
                </Row>
              </Row>
            </Form>
          </Row>
        </Row>
        <HDivider margin='15px' type='TRANSPARENT' />
        <Row justify='CENTER'>
          <CancelUpdateProfileButton style={{ width: '200px' }} onClick={closeWindow}>취소</CancelUpdateProfileButton>
          <VDivider margin='3px' type='TRANSPARENT' />
          <ConfirmUpdateProfileButton style={{ width: '200px' }} onClick={updateProfile}>수정
            신청</ConfirmUpdateProfileButton>
        </Row>
      </Form>
      <AlertModal
        isPopupOpen={isAlertOpen}
        alertText={alertText}
        hidePopup={hideAlert}
      />
    </Layout>
  );
}

export default UpdateProfile;
