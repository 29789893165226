import { popupMessage } from '@components/form/AlertModal';
import Modal from '@components/form/Modal';
import {
	getPaymentCustomerKey,
	getPaymentFailUrl,
	getPaymentSuccessUrl,
	getPaymentClientKey,
	getPaymentModifySuccessUrl,
	getPaymentModifyFailUrl
} from '@config/env';
import { $mixtapeGetUser, getUserId, loadLocalStorage } from '@utils/auth';
import { result } from 'lodash';

export async function startTossPayment({
	mode,
	membershipType = undefined
}: {
	mode: 'create' | 'modify';
	membershipType?: string;
}) {
	const userInfo = $mixtapeGetUser();
	let promise = new Promise((resolve, reject) => {
		let userId = null;

		if (!userInfo || !userInfo.user_id) {
			resolve('사용자 정보가 없습니다.');
		} else {
			userId = userInfo.user_id;
		}

		//@ts-ignor
		const tossPayments = TossPayments(getPaymentClientKey()); // 클라이언트 키로 초기화하기

		try {
			tossPayments
				.requestBillingAuth('카드', {
					// 결제 수단 파라미터

					customerKey: userId,
					successUrl:
						mode === 'create'
							? getPaymentSuccessUrl(membershipType as string)
							: getPaymentModifySuccessUrl(membershipType), // 카드등록 성공시 이동하는 url
					failUrl: mode === 'create' ? getPaymentFailUrl() : getPaymentModifyFailUrl() // 카드등록 실패시 이동하는 url
				})
				.catch(function (error: any) {
					if (error.code === 'USER_CANCEL') {
						resolve('결제를 취소하셨습니다.');
					} else if (error.code === 'INVALID_CARD_COMPANY') {
						//alert('결제 중 오류가 발생 하셨습니다.');  // 유효하지 않은 카드 코드에 대한 에러 처리
						resolve('결제 중 오류가 발생 하셨습니다.');
					} else {
						// alert('결제진행 중 오류가 발생 하셨습니다.');
						resolve('결제진행 중 오류가 발생 하셨습니다.');
					}
				});
		} catch (e) {
			resolve(e);
		}
	});

	return await promise;
}
