import React, { ButtonHTMLAttributes, ReactElement } from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

type THandleCheckButtonTypeParams = undefined;

export interface IButtonParams extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: THandleCheckButtonTypeParams;
  disabled?: boolean;
}

const handleCheckButtonType = (type: THandleCheckButtonTypeParams) => {
  switch (type) {
    default:
      return css`
        height: 64px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        background: #121212;
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
      `;
  }
};

const StyledButton = styled.button<IButtonParams>`
  cursor: pointer;

  &:disabled {
    background: #dedede;
    color: #fff;
    cursor: not-allowed;
  }

  ${({ buttonType }) => handleCheckButtonType(buttonType)}
`;

const Button = ({ children, ...rest }: IButtonParams): ReactElement => <StyledButton {...rest}>{children}</StyledButton>;

export default Button;
