
export const gf_getImageInfo  = async (file: any) => {
  const promise = new Promise((resolve, reject) => {
    const retVal = (error:boolean, errorMessage:string, info:any=null)=>{
      return {
        error:error,
        errorMessage:errorMessage,
        info:info
      }}
    const imgInfo = {}

    if (!file.type.startsWith('image/')) {
      resolve(retVal(true, '이미지파일이 아닙니다.'));

    }

    imgInfo.type = file.type
    try{
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        try{
          imgInfo.size = file.size

          // @ts-ignore
          const uint = new Uint8Array(reader.result);
          const blob = new Blob([uint], { type: file.type });
          const img = new Image();
          img.src = URL.createObjectURL(blob);
          img.onload = () => {
            imgInfo.width = img.width
            imgInfo.height = img.height

            resolve(retVal(false, '', imgInfo))
          };
        }catch (ex) {
          resolve( retVal(true, ex.message))
        }


      };

    }catch (e){
      resolve(retVal(true, e.message))
    }

  });
  return await promise
}


///
/// 주민번호 외국인번호 체크로직
///
export function gf_checkSSN(ssn:any) {
  if(!ssn) return false
  let sum = 0;
  const month = ssn.substr(2,2);
  const day = ssn.substr(4,2);

  if(ssn.length != 13) {
    return false;
  }


  //월의 경우 13월을 넘지 않아야 한다.
  if(month < 13 && month != 0 && day != 0) {
    //2월의 경우
    if(month == 2) {
      //29일을 넘지 않아야 한다.
      if(day > 29) return false;
    }
    else if(month == 4 || month == 6 || month == 9 || month == 11){

      // 4,6,9,11월의 경우 30일을 넘지 않아야 한다.
      if(day > 30) return false;
    } else {
      // 그외 월이 31일을 넘지 않아야 한다.
      if(day > 31) return false;
    }
  }else {
    return false;
  }

  for(let i = 0; i < 12; i++) {
    sum += Number(ssn.substr(i, 1)) * ((i % 8) + 2);
  }



  if(ssn.substr(6,1) == 1 || ssn.substr(6,1) == 2 || ssn.substr(6,1) == 3 || ssn.substr(6,1) == 4 || ssn.substr(6,1) == 9 || ssn.substr(6,1) == 0) {

    //내국인 주민번호 검증(1900(남/여) 2000(남/여))

    if(((11 - (sum % 11)) % 10) == Number(ssn.substr(12,1))) {

      return true;

    }

    return false;

  }else if(ssn.substr(6,1) == 5 || ssn.substr(6,1) == 6 || ssn.substr(6,1) == 7 || ssn.substr(6,1) == 8) {

    //외국인 주민번호 검증(1900(남/여) 2000(남/여))

    if(Number(ssn.substr(8,1)) % 2 != 0) {
      return false;

    }

    if((((11 - (sum % 11)) % 10 + 2) % 10) == Number(ssn.substr(12, 1))){
      return true;
    }
    return false;
  }
  return true;  //정상 주민번호

}