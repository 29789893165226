import React from 'react';

import styled from '@emotion/styled';
import { Icon } from '@images/Icon';

const CheckboxContainer = styled.div`
  display: flex;
`;

const CheckBox = styled.label<{ checked: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #dcdcdc;
  color: ${({ checked }) => (checked ? `#FFFFFF` : `#DCDCDC`)};
  background: ${({ checked }) => (checked ? `#121212` : `#FFFFFF`)};
  border-radius: 50%;
  cursor: pointer;

  & > svg {
    position: absolute;
  }
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Label = styled.label`
  display: inline-block;
  padding-left: 10px;
  cursor: pointer;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #5b5b5b;
  white-space: pre-line;
`;

interface CheckboxProps {
  id: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string | JSX.Element | null;
  hiddenCheckBoxProps?: any;
  checkBoxStyle?: any;
}

function Checkbox({ id, checked, onChange, label, hiddenCheckBoxProps, checkBoxStyle }: CheckboxProps) {
  return (
    <CheckboxContainer>
      <CheckBox checked={checked} htmlFor={id} style={{ ...checkBoxStyle }}>
        <HiddenCheckbox checked={checked} id={id} onChange={onChange} type="checkbox" {...hiddenCheckBoxProps} />
        <Icon icon={checked ? 'btnCheck' : 'btnUnCheck'} style={{ width: '20px', height: '20px' }} />
      </CheckBox>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
    </CheckboxContainer>
  );
}

export default Checkbox;
