import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

const baseStyle = {
  fontSize: '13px',
  fontFamily: 'Pretendard,serif',
  fontWeight: 'thin',
};

export const DarkTooltip = withStyles({
  tooltip: {
    ...baseStyle,
    color: '#f1f1f1',
    backgroundColor: '#232323',
    border: '1px solid #ffffff30',
    padding: '10px',
  },
})(Tooltip);

export const TextOnlyTooltip = withStyles({
  tooltip: {
    ...baseStyle,
    color: 'black',
    backgroundColor: 'transparent',
  },
})(Tooltip);
