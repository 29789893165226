import AlertDialog from '@components/form/AlertDialog';
import AlertModal from '@components/form/AlertModal';
import ChipSelect, { BaseSelect } from '@components/form/ChipSelect';
import TTextField from '@components/form/TTextField';
import VibeSelect from '@components/form/VibeSelect';
import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import { useReduxData } from '@hooks/useReduxData';
import { InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import { $mixtapeInit, $mixtapeSetUser, saveLocalStorage, setAuth } from '@utils/auth';
import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { getCodeList } from '@api/mapper/Code';
import { certificateAuthCode, getAuthCode, signUp } from '@api/mapper/User';
import HDivider from '@components/form/HDivider';

import Label from '@components/form/Label';
import Radio from '@components/form/Radio';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';
import { GetAuthNumberButton, NextButton, PrevButton, ResendAuthNumberButton } from '@components/molecules/SignUp/Button';
import { SignUpLayout } from '@components/molecules/SignUp/Layout';
import { ReConfirmNumberText } from '@components/molecules/SignUp/Text';
import {
  AUTH_SIGN_UP_ACTION,
  AUTH_SIGN_UP_AUTHENTICATION_ACCOUNT_ACTION,
  AUTH_SIGN_UP_GET_CODE_ACTION,
  AUTH_SIGN_UP_TERMS_ACTION,
  CODE_LIST_ARTIST_ROLE_ACTION,
  CODE_LIST_ARTIST_TYPE_ACTION,
  CODE_LIST_GENDER_ACTION,
  CODE_LIST_GENRE_ACTION,
} from '@constants/actionNames';

import { useFormError } from '@hooks/useFormError';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import { useTimer } from '@hooks/useTimer';
import { Icon } from '@images/Icon';
import { autoHypenPhone, getDataInSessionStorage, getDisabledWithFormError, isEmpty, replaceAll } from '@utils/data';

import { checkOnlyNumber, TCurrentStep } from './index';

type TInfoProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
};

function Info({ setCurrentStep }: TInfoProps) {
  const [phoneCertificationStatus, setPhoneCertificationStatus] = useState<'READY' | 'SUCCESS' | 'FAIL'>('READY');

  const [formErrorNetwork, setFormErrorNetwork] = useState<any>(null);
  const [isNotSamePassword, setIsNotSamePassword] = useState<boolean>(true);
  const [isShowAuthNumberInput, setIsShowAuthNumberInput] = useState<boolean>(false);

  const { request, resData, errorMessage } = useRequest({
    reduxActionName: AUTH_SIGN_UP_ACTION,
    mapperFunc: signUp,
  });
  // @ts-ignore

  const reduxForm = useReduxForm(AUTH_SIGN_UP_ACTION, (params) => request({ ...params }));
  const { data: termsData, setData: setTermsData } = useReduxData(AUTH_SIGN_UP_TERMS_ACTION);
  useEffect(() => {
    if (resData) {
      $mixtapeInit();
      $mixtapeSetUser(resData);
      // saveLocalStorage('userInfo', resData);
      setCurrentStep('FINISH');
    }
  }, [resData]);
  const { setFormData, formData, formFieldsError, validation, handleSubmit } = reduxForm;
  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const { requestForSingle: getGenderCodeListReq, resData: getGenderCodeListRes } = useRequest({
    reduxActionName: CODE_LIST_GENDER_ACTION,
    mapperFunc: getCodeList,
  });
  const { requestForSingle: getGenreCodeListReq, resData: getGenreCodeListRes } = useRequest({
    reduxActionName: CODE_LIST_GENRE_ACTION,
    mapperFunc: getCodeList,
  });
  const { requestForSingle: getArtistTypeCodeListReq, resData: getArtistTypeCodeListRes } = useRequest({
    reduxActionName: CODE_LIST_ARTIST_TYPE_ACTION,
    mapperFunc: getCodeList,
  });
  const { requestForSingle: getArtistRoleCodeListReq, resData: getArtistRoleCodeListRes } = useRequest({
    reduxActionName: CODE_LIST_ARTIST_ROLE_ACTION,
    mapperFunc: getCodeList,
  });

  const { request: getAuthCodeReq, resData: getAuthCodeRes } = useRequest({
    reduxActionName: AUTH_SIGN_UP_GET_CODE_ACTION,
    mapperFunc: getAuthCode,
  });
  const {
    request: accountCertificateAuthCodeReq,
    resData: accountCertificateAuthCodeRes,
    requestLoading: accountCertificateAuthCodeLoading,
    errorMessage: accountCertificateAuthCodeErrorMessage,
  } = useRequest({
    reduxActionName: AUTH_SIGN_UP_AUTHENTICATION_ACCOUNT_ACTION,
    mapperFunc: certificateAuthCode,
  });

  const {
    minutes: phoneMinutes,
    seconds: phoneSeconds,
    setMinutes: setPhoneMinutes,
    setSeconds: setPhoneSeconds,
  } = useTimer({
    stopCondition: phoneCertificationStatus === 'SUCCESS',
  });

  const { openState: uc_open, setOpenState: uf_setOpen, message: uc_message, popupMessage } = usePopupMessage();
  const goBack = () => {
    setCurrentStep('TERMS');
  };

  const [isPopupOpen, openPopup] = useState(false);

  function hidePopup() {
    openPopup(false);
  }

  const setSignUpFormData = (e: any, dataIndex: string, isValue?: boolean) => {
    let value = '';
    if (isValue) {
      value = e;
    } else {
      value = e.target.value;
    }

    if (dataIndex === 'password') {
      if (value !== formData.passwordConfirm) {
        setIsNotSamePassword(true);
      } else {
        setIsNotSamePassword(false);
      }
    }
    if (dataIndex === 'passwordConfirm') {
      if (value !== formData.password) {
        setIsNotSamePassword(true);
      } else {
        setIsNotSamePassword(false);
      }
    }

    if (dataIndex === 'phone_number') {
      value = autoHypenPhone(value);
    }

    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
  };

  ///
  /// 전화인증 버튼 후크로 활성화 여부 결정
  ///
  const [mobileAuthBtnEnabled, setMobileAuthBtnEnabled] = useState(true);

  /// 전화인증버튼 활성화 체크
  function checkMobileButtonState(val: string) {
    const enabled = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(val);
    setMobileAuthBtnEnabled(!enabled);
  }

  const handleClick = (e: any) => {
    e.preventDefault();
    const foreignerFlag = sessionStorage.getItem('userType') === 'LOCAL' ? 0 : 1;

    handleSubmit({
      foreigner_state: foreignerFlag,
    });
    // setCurrentStep('FINISH');
  };

  useEffect(() => {
    setFormData({
      isExistRelease: 'false',
      promotion_phone: termsData.promotion ? true : false,
      promotion_email: termsData.promotion ? true : false,
    });

    getGenderCodeListReq({ parent_code: 'gender' });
    getGenreCodeListReq({ parent_code: 'genre' });
    getArtistTypeCodeListReq({ parent_code: 'artist_type' });
    getArtistRoleCodeListReq({ parent_code: 'artist_role' });
  }, []);

  useEffect(() => {
    if (resData) {
      $mixtapeSetUser(resData);

      // 회원가입이 성공이면 자동로그인 처리한다.
      processAfterSignUp();
      // window.scrollTo({
      //   top: 0,
      // });
    }
  }, [resData]);

  useEffect(() => {
    if (errorMessage) {
      setFormErrorNetwork('회원가입에 실패했습니다.');
    }
  }, [errorMessage]);

  const processAfterSignUp = async () => {
    await processLogin();
    setCurrentStep('FINISH');
  };

  const [authId, setAuthId] = useState('');

  /// 모바일 인증번호를 요청한다.
  async function callMobileAuth() {
    const params = {
      phone_num: replaceAll(formData.phone_number, '-', ''),
      auth_type: 'sign-up',
    };
    const res = await ctf_sendHttpPost(`/token/sms-check`, params);

    if (!res.error) {
      setIsShowAuthNumberInput(true);
      setPhoneSeconds(0);
      setPhoneMinutes(3);
      setPhoneCertificationStatus('READY');

      setAuthId(res.data.res_auth_id);
    } else {
      formError.phone_number = res.data.message;
    }
  }

  const processLogin = async () => {
    const { user_id, password } = formData;
    const res = await ctf_sendHttpPost(`/sign-in`, { user_id, password });

    // AUTH저장 -> AUTH에는 저것만 저장한다.
    setAuth({
      access_token: res.data.token,
      useSeq: res.data.user.seq,
    });
    // 전역변수에 user정보 저장한다.
    $mixtapeSetUser(res.data.user);
  };

  /// 모바일 인증 입력값 화인 요청
  async function callMobileAuthCheck(authCode: any) {
    const params = {
      phone_num: replaceAll(formData.phone_number, '-', ''),
      auth_code: authCode,
      res_auth_id: authId,
    };
    const res = await ctf_sendHttpPost(`/token/sms-auth-check`, params);

    if (!res.error) {
      formError.phone_number = res.data?.message ?? '인증되었습니다.';
      setPhoneCertificationStatus('SUCCESS');
    } else {
      formError.phone_number = res.data?.message ?? '';
      setPhoneCertificationStatus('FAIL');
    }
  }

  const { ctf_sendHttpGet, ctf_sendHttpPost } = useContext(BackdropContext);

  async function checkDuplUserId(userId: string) {
    // 3.26 check-id에서 verify-id로 변경
    const res = await ctf_sendHttpGet(`/verify-id/${userId}`, {});
    if (!res.error) {
      popupMessage(`아이디 : ${userId}\n이미 사용중이거나 탈퇴한 아이디입니다.\n다시 입력하세요.`);
      setFormData('user_id', '');
    }
  }

  async function checkDuplEmail(email: string) {
    if (email.trim() === '') {
      return;
    }
    const res = await ctf_sendHttpGet(`/check-email/${email}`, {});
    if (res.error) {
      popupMessage(`이메일 : ${email}\n이미 사용중이거나 탈퇴한 이메일입니다.\n다시 입력하세요.`);
      setFormData('email', '');
    }
  }

  const getCodeMenuItems = (item: any) => {
    return (
      <MenuItem key={item.id} value={item.id}>
        {item.code_name}
      </MenuItem>
    );
  };

  useEffect(() => {
    let isDisabled = true;

    if (formData.isExistRelease === 'true') {
      isDisabled = getDisabledWithFormError(formError, ['user_id', 'password', 'name', 'phone_number', 'email', 'vibe']);
    } else {
      isDisabled = getDisabledWithFormError(formError, [
        'user_id',
        'password',
        'name',
        'phone_number',
        'email',
        'artist_name',
        'artist_name_foriegn',
        'type_id',
        'gender_id',
        'genres',
        'roles',
      ]);
    }

    isDisabled = isDisabled || isNotSamePassword;
    // console.log(2, isNotSamePassword)
    isDisabled = isDisabled || phoneCertificationStatus !== 'SUCCESS';
    // console.log(phoneCertificationStatus)
    //     console.log(4, isDisabled)
    setSubmitBtnDisabled(isDisabled);
  }, [formError, phoneCertificationStatus]);

  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);

  return (
    <SignUpLayout>
      <Stack style={{ width: '460px' }}>
        <Text textType="TITLE">정보 입력</Text>
        <HDivider margin="28px" type="TRANSPARENT" />

        <Label htmlFor="singup_user_id" required={true} style={{ marginBottom: '6px' }}>
          아이디
        </Label>
        <TTextField
          id="signup_user_id"
          // autoFocus
          autocomplete="off"
          onChange={(e: ChangeEvent) => {
            setSignUpFormData(e, 'user_id');
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === '') {
              return;
            }
            checkDuplUserId(e.target.value.trim());
          }}
          placeholder="5~20자 영문 소문자, 숫자, 특수기호를 사용하세요."
          value={formData.user_id ?? ''}
        />

        <ErrorMessage formError={formError.user_id} />

        <HDivider margin="13px" type="TRANSPARENT" />

        <Label htmlFor="signup_password" required={true} style={{ marginBottom: '6px' }}>
          비밀번호
        </Label>
        <TTextField
          id="signup_password"
          onChange={(e: ChangeEvent) => {
            setSignUpFormData(e, 'password');
          }}
          placeholder="8~30자 영문 대/소문자, 숫자, 특수문자를 사용하세요."
          type="password"
          value={formData.password ?? ''}
        />
        <ErrorMessage formError={formError.password} />
        <HDivider margin="13px" type="TRANSPARENT" />
        <Label htmlFor="signup_passwordConfirm" style={{ marginBottom: '6px' }}>
          비밀번호 확인
        </Label>
        <TTextField
          id="signup_passwordConfirm"
          onChange={(e: ChangeEvent) => {
            setSignUpFormData(e, 'passwordConfirm');
          }}
          placeholder="비밀번호 확인"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {' '}
                <Icon icon={!isNotSamePassword ? 'btnCheck' : 'btnUnCheck'} />
              </InputAdornment>
            ),
          }}
          type="password"
          value={formData.passwordConfirm ?? ''}
        />
        <HDivider margin="13px" type="TRANSPARENT" />

        <Label htmlFor="signup_name" required={true} style={{ marginBottom: '6px' }}>
          이름 (본명)
        </Label>
        {/* 회원명 */}
        <TTextField
          id="signup_name"
          onChange={(e: ChangeEvent) => {
            setSignUpFormData(e.target.value, 'name', true);
          }}
          onBlur={(e) => {
            setSignUpFormData(e.target.value, 'name', true);
          }}
          placeholder="한글, 영문 대/소문자를 사용하세요."
          value={formData.name ?? ''}
        />
        <ErrorMessage formError={formError.name} />

        <HDivider margin="13px" type="TRANSPARENT" />
        <Label htmlFor="email" required={true} style={{ marginBottom: '6px' }}>
          이메일
        </Label>
        <TTextField
          id="email"
          onChange={(e: ChangeEvent) => {
            setSignUpFormData(e, 'email');
          }}
          onBlur={(e) => {
            checkDuplEmail(e.target.value);
          }}
          placeholder="mixtape@mixtape.so"
          value={formData.email ?? ''}
        />
        <ErrorMessage formError={formError.email} />
        <HDivider margin="13px" type="TRANSPARENT" />

        <Label htmlFor="phone_number" required={true} style={{ marginBottom: '6px' }}>
          휴대전화
        </Label>
        <Row justify="SPACE_BETWEEN">
          <TTextField
            id="phone_number"
            InputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            onChange={(e: ChangeEvent) => {
              setSignUpFormData(e, 'phone_number');
              //@ts-ignore
              checkMobileButtonState(e.target.value.replace(/-/gi, ''));
            }}
            onKeyDown={checkOnlyNumber}
            placeholder="숫자만 입력하세요."
            style={{ flex: 7 }}
            type="text"
            value={formData.phone_number ?? ''}
          />
          <VDivider margin="5px" type="TRANSPARENT" />
          <GetAuthNumberButton
            disabled={mobileAuthBtnEnabled}
            onClick={(e) => {
              e.preventDefault();
              callMobileAuth();
            }}
            style={{ flex: 3 }}
          >
            인증번호 받기
          </GetAuthNumberButton>
        </Row>
        <ErrorMessage formError={formError.phone_number} />

        <HDivider margin="13px" type="TRANSPARENT" />
        {isShowAuthNumberInput && (
          <>
            <Label htmlFor="auth_code" style={{ marginBottom: '6px' }}>
              인증번호
            </Label>
            <HDivider margin="6px" type="TRANSPARENT" />
            <TTextField
              id="auth_code"
              disabled={phoneCertificationStatus === 'SUCCESS'}
              onChange={(e: ChangeEvent) => {
                setSignUpFormData(e, 'auth_code');
                //폰인증이 성공이면 인증 체크를 할필요가 없다.
                if (phoneCertificationStatus === 'SUCCESS') {
                  return;
                }
                const { value } = e.target as any;
                if (value.length === 6) {
                  (async () => {
                    await callMobileAuthCheck(value);
                  })();
                }
              }}
              placeholder="인증번호 입력"
              InputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                endAdornment: (
                  <InputAdornment position="end">
                    {phoneCertificationStatus !== 'SUCCESS' && (
                      <div className={phoneMinutes === 0 && parseInt(phoneSeconds, 10) === 0 ? 'time' : 'time_enable'}>
                        {`${phoneMinutes}:${phoneSeconds}`}&nbsp;
                      </div>
                    )}
                    <Icon icon={phoneCertificationStatus === 'SUCCESS' ? 'btnCheck' : 'btnUnCheck'} />
                  </InputAdornment>
                ),
              }}
              type="number"
              value={formData.auth_code ?? ''}
            />
            {phoneCertificationStatus === 'FAIL' && (
              <>
                <HDivider margin="5px" type="TRANSPARENT" />
                <Row justify="FLEX_START">
                  <ReConfirmNumberText>인증번호를 다시 확인해 주세요</ReConfirmNumberText>
                  <VDivider margin="8px" type="TRANSPARENT" />
                  <ResendAuthNumberButton
                    onClick={(e) => {
                      e.preventDefault();
                      callMobileAuth();
                    }}
                  >
                    인증번호 재전송
                  </ResendAuthNumberButton>
                </Row>
              </>
            )}
          </>
        )}
        <HDivider margin="30px" type="TRANSPARENT" />
        <Text textType="HEADER3">아티스트 정보</Text>
        <HDivider margin="4px" type="TRANSPARENT" />
        <HDivider margin="0px" />

        <HDivider margin="12px" type="TRANSPARENT" />
        <Text>앨범 발매 이력이 있으신가요?</Text>

        <HDivider margin="9px" type="TRANSPARENT" />
        <Radio
          onChange={(e: ChangeEvent) => {
            setSignUpFormData(e, 'isExistRelease');
          }}
          options={[
            {
              label: '예 (이력 있음)',
              value: 'true',
            },
            {
              label: '아니오 (이력 없음)',
              value: 'false',
            },
          ]}
          value={formData.isExistRelease}
        />
        <HDivider margin="9px" type="TRANSPARENT" />
        {formData.isExistRelease === 'true' ? (
          <>
            <VibeSelect
              value={formData.vibeId}
              placeholder="아티스트명을 입력해 주세요."
              onChange={(e, v) => {
                setSignUpFormData(v, 'vibe', true);
              }}
            />
            <HDivider margin="8px" type="TRANSPARENT" />
          </>
        ) : (
          <>
            <Label htmlFor="artist_name" style={{ marginBottom: '12px', marginTop: '20px' }}>
              아티스트명
            </Label>
            <Label required={true} htmlFor="artist_name" style={{ marginBottom: '6px' }}>
              국내
            </Label>
            <TTextField
              id="artist_name"
              onChange={(e: ChangeEvent) => {
                setSignUpFormData(e, 'artist_name');
              }}
              placeholder="최대 한글 100자, 이모지 반영 불가"
              value={formData.artist_name ?? ''}
            />
            <ErrorMessage formError={formError.artist_name} />

            <HDivider margin="8px" type="TRANSPARENT" />
            <Label htmlFor="artist_name_foriegn" required={true} style={{ marginBottom: '6px' }}>
              해외
            </Label>
            <TTextField
              id="artist_name_foriegn"
              onChange={(e: ChangeEvent) => {
                setSignUpFormData(e, 'artist_name_foriegn');
              }}
              placeholder="최대 영문 100자, 이모지 반영 불가"
              value={formData.artist_name_foriegn ?? ''}
            />
            <ErrorMessage formError={formError.artist_name_foriegn} />

            <HDivider margin="13px" type="TRANSPARENT" />
            <Label htmlFor="type_id" required={true} style={{ marginBottom: '6px' }}>
              아티스트 타입
            </Label>
            <BaseSelect
              value={formData.type_id}
              onChange={(e: ChangeEvent) => {
                setSignUpFormData(e, 'type_id');
              }}
              placeholder="아티스트 타입"
            >
              {getArtistTypeCodeListRes?.map((item: any) => getCodeMenuItems(item))}
            </BaseSelect>
            <ErrorMessage formError={formError.type_id} />

            <HDivider margin="13px" type="TRANSPARENT" />
            <Label htmlFor="gender_id" required={true} style={{ marginBottom: '6px' }}>
              아티스트 성별
            </Label>
            <BaseSelect
              value={formData.gender_id}
              onChange={(e: ChangeEvent) => {
                setSignUpFormData(e, 'gender_id');
              }}
              placeholder="아티스트 성별"
            >
              {getGenderCodeListRes?.map((item: any) => getCodeMenuItems(item))}
            </BaseSelect>
            <ErrorMessage formError={formError.gender_id} />
            <HDivider margin="13px" type="TRANSPARENT" />

            <Label htmlFor="genres" required={true} style={{ marginBottom: '6px' }}>
              주요 장르
            </Label>
            <ChipSelect
              maxTooltip="장르는 최대 2개까지 선택 가능합니다."
              maxSelect={2}
              maxTooltip="장르는 최대 2개까지 선택 가능합니다."
              value={formData.genres ? [...formData.genres] : []}
              onChange={(e: ChangeEvent) => {
                setSignUpFormData(e, 'genres');
              }}
              placeholder="주요 장르 (최대 2개 선택)"
              itemData={getGenreCodeListRes}
            />

            <ErrorMessage formError={formError.genres} />

            <HDivider margin="13px" type="TRANSPARENT" />
            <Label htmlFor="roles" required={true} style={{ marginBottom: '6px' }}>
              주요 역할
            </Label>
            <ChipSelect
              value={formData.roles ? [...formData.roles] : []}
              onChange={(e: ChangeEvent) => {
                setSignUpFormData(e, 'roles');
              }}
              placeholder="주요 역할"
              itemData={getArtistRoleCodeListRes}
            />

            <ErrorMessage formError={formError.roles} />
          </>
        )}
        <HDivider margin="30px" type="TRANSPARENT" />

        {formErrorNetwork ? (
          <>
            <Row justify="CENTER">{formErrorNetwork && <ErrorMessage message={formErrorNetwork} />}</Row>
            <HDivider margin="12px" type="TRANSPARENT" />
          </>
        ) : (
          <>
            <HDivider margin="14px" type="TRANSPARENT" />
          </>
        )}
        <Row justify="SPACE_BETWEEN">
          <PrevButton onClick={goBack} style={{ width: '100%' }}>
            이전
          </PrevButton>
          <VDivider margin="5px" type="TRANSPARENT" />
          <NextButton onClick={handleClick} disabled={submitBtnDisabled} style={{ width: '100%' }}>
            회원가입
          </NextButton>
        </Row>
      </Stack>

      <AlertDialog isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />
    </SignUpLayout>
  );
}

export default Info;
