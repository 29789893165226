import { Stack } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import { EditButtonModal } from '@components/molecules/UserInfoSetting/Button';
import { Icon } from '@images/Icon';

import { TCurrentStep } from '.';


function Finish({ setCurrentStep, setIsOpenEditEmailModal, callback}) {
  const confirm = () => {
    // const redirectUri = routePath.login.path;
    setIsOpenEditEmailModal();
    setCurrentStep('INIT');
    callback()
  };

  return (
    <Stack spacing={5}>


        <Row justify="CENTER">
          <Icon icon="iconComplete" style={{ width: '64px', height: '64px' }} />
        </Row>

        <Row direction="column" justify="CENTER">
          <Text textType="DESCRIPTION">이메일 변경이 완료되었습니다. </Text>
          <Text textType="DESCRIPTION">확인 버튼을 누르면 팝업이 닫힙니다.</Text>
        </Row>

        <EditButtonModal
          onClick={() => {
            confirm();
          }}
        >
          확인
        </EditButtonModal>

    </Stack>
  );
}

export default Finish;
