import React from 'react';
import { useTable } from 'react-table';

import HDivider from '@components/form/HDivider';
import styled from '@emotion/styled';
import { MacScrollbar } from 'mac-scrollbar';

const Styles = styled(MacScrollbar)`
  width: 100%;
  max-height: 434px;
  border-bottom: 1px solid #dcdcdc;

  table {
    border-spacing: 0;
    width: 100%;
    background: transparent;

    thead {
      position: sticky;
      top: 0;
      z-index: 99;
    }

    th {
      background: #fff;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #777777;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-bottom: 10px;
      padding-bottom: 6px;
    }

    td {
      background: #transparent;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #121212;
      flex: none;
      order: 0;
      flex-grow: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      text-overflow: ellipsis;

      div {
        display: block;
      }
    }
  }
`;

type TTableProps = {
  columns: Array<any>;
  data: Array<any>;
};
const Table = ({ columns, data }: TTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <Styles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((header: any) => (
            <tr {...header.getHeaderGroupProps()}>
              {header.headers.map((col: any, hidx) => (
                <th key={'__' + hidx} {...col.getHeaderProps()}>
                  {col.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, trIdx) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: any, cellIdx) => (
                    <td key={trIdx + '__' + cellIdx} {...cell.getCellProps()} style={cell.column.style ?? {}}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
};

export default Table;
