import Layout from '@components/form/Layout';
import styled from '@emotion/styled';

export const ProfileManagementLayout = styled(Layout)`
  padding-top: 190px;
  padding-bottom: 120px;
  background: #1e1e1e;
  color: #fff;
  justify-content: space-between;
  flex-direction: row;
  align-items:flex-start;
  padding-left: calc(20% - 264px);
  padding-right: calc(20% - 264px);
`;

export const ProfileListLayout = styled(Layout)`
  min-width: 460px;
  height: 630px;
  background: #222;
  flex: 0.4;
  border-radius: 10px;
  justify-content: flex-start;
  padding-top: 32px;
  margin-left:40px;
`;
