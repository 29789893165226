import InputPrefix from '@components/form/InputPrefix';
import InputPrefixSuffix from '@components/form/InputPrefixSuffix';
import styled from '@emotion/styled';

export const AlbumListSearchInput = styled(InputPrefixSuffix)`
  width: 278px;
  height: 40px;
  background: #323232 !important;
  border-radius: 20px !important;
  color: #fff;

  ::placeholder {
    /* font/txt_14 */

    font-family: Pretendard;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* color/txt_5b5b5b */

    color: #5b5b5b !important;
  }
`;
