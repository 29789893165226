import React from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

const ToggleBtn = styled.button<any>`
  width: 46px;
  height: 24px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  background-color: ${(props) => (!props.toggle ? '#3B3B3B' : '#EFFD60')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
`;
const Circle = styled.div<any>`
  background-color: #121212;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  left: 7%;
  transition: all 0.5s ease-in-out;
  ${(props) =>
    props.toggle &&
    css`
      transform: translate(22px, 0);
      transition: all 0.3s ease-in-out;
    `}
`;

function Toggle({ toggle, setToggle }: any) {
  const clickedToggle = (e: any) => {
    e.preventDefault();
    setToggle((prev: boolean) => !prev);
  };
  return (
    <ToggleBtn onClick={clickedToggle} toggle={toggle}>
      <Circle toggle={toggle} />
    </ToggleBtn>
  );
}

export default Toggle;
