import Layout from '@components/form/Layout';
import styled from '@emotion/styled';

export const SignUpLayout = styled(Layout)`

  padding-top: 190px;
  padding-bottom: 120px;
  background: #fff;
  overflow: hidden;
`;
