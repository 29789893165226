import Text from '@components/form/Text';
import styled from '@emotion/styled';

export const ReConfirmNumberText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ff6465;
`;

export const FundIdValueText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* color/121212 */

  color: #121212;
`;
