import React, { useContext, useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import Layout from '@components/form/Layout';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Text from '@components/form/Text';
import { Stack } from '@mui/material';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import TTextField from '@components/form/TTextField';
import { checkOnlyNumber } from '@containers/SignUp';
import { gf_checkSSN } from '@utils/misc';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';
import { FindPasswordButton } from '@components/molecules/FindId/Button';
import { EditButtonModal } from '@components/molecules/UserInfoSetting/Button';
import { $mixtapeUserSeq } from '@utils/auth';
import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import AlertDialog from '@components/form/AlertDialog';
// import {
//   AUTH_FIND_PASSWORD_ACTION,
//   AUTH_FIND_PASSWORD_GET_NUMBER_ACTION,
//   AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
// } from '@constants/actionNames';
// import AuthNumberCertification from '@containers/UserInfoSetting/RrnModal/AuthNumberCertification';
// import Completed from '@containers/UserInfoSetting/RrnModal/Completed';
// import ResetPassword from '@containers/UserInfoSetting/RrnModal/ResetPassword';
// import { useReduxData } from '@hooks/useReduxData';

// export type TCurrentStep = 'AUTH_NUMBER' | 'RESET' | 'COMPLETED';

type TRrnModalProps = {
  setIsOpenRrnModal: () => void;
  isOpenRrnModal: boolean;
};

function EditRrnModal({ isOpenRrnModal, setIsOpenRrnModal }: TRrnModalProps) {
  // const [isShowAuthNumberInput, setIsShowAuthNumberInput] = useState<boolean>(false);
  // const [currentStep, setCurrentStep] = useState<TCurrentStep>('AUTH_NUMBER');
  // const { clearData: clearFindPasswordData } = useReduxData(AUTH_FIND_PASSWORD_ACTION);
  // const { clearData: clearFindPasswordGetAuthNumberData } = useReduxData(AUTH_FIND_PASSWORD_GET_NUMBER_ACTION);
  // const { clearData: clearFindPasswordIsExistId } = useReduxData(AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION);

  // useEffect(() => {
  //   if (!isOpenRrnModal) {
  //     clearFindPasswordData();
  //     clearFindPasswordGetAuthNumberData();
  //     clearFindPasswordIsExistId();
  //     setCurrentStep('AUTH_NUMBER');
  //     setIsShowAuthNumberInput(false);
  //   }
  // }, [isOpenRrnModal]);

  // const {
  //   watch,
  //   formState: { errors },
  //   setError,
  //   clearErrors,
  //   setValue,
  //   control,
  // } = useFormContext();

  // const [update_user_rrn_front, update_user_rrn_back, isForeigner] = watch([
  //   'update_user_rrn_front',
  //   'update_user_rrn_back',
  //   'isForeigner',
  // ]);

  // // useEffect(() => {
  // //   if (update_user_rrn_front && update_user_rrn_back) {
  // //     const checked = gf_checkSSN(update_user_rrn_front + '' + update_user_rrn_back);

  // //     if (!checked) {
  // //       setError('update_user_rrn_front', { message: `올바르지 않은 주민번호 입니다.` });
  // //       setValue('regiNoChecked', false);
  // //     } else {
  // //       clearErrors(['update_user_rrn_front']);
  // //       setValue('regiNoChecked', true);
  // //     }
  // //   }
  // // }, [update_user_rrn_front, update_user_rrn_back]);

  const { ctf_sendHttpGet, ctf_sendHttpPatch } = useContext(BackdropContext);
  const { openState: uc_open, setOpenState: uf_setOpen, message: uc_message, popupMessage: uf_popupMessage } = usePopupMessage();
  const {
    watch,
    getValues,
    formState: { errors },
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: 'all',
    defaultValues: {
      update_user_rrn_front: '',
      update_user_rrn_back: '',
      isCheck: false,
    },
  });

  const [update_user_rrn_front, update_user_rrn_back, isCheck] = watch([
    'update_user_rrn_front',
    'update_user_rrn_back',
    'isCheck',
  ]);

  useEffect(() => {
    if (update_user_rrn_front && update_user_rrn_back) {
      const checked = gf_checkSSN(update_user_rrn_front + '' + update_user_rrn_back);

      if (!checked) {
        setError('update_user_rrn_front', { message: `올바르지 않은 주민번호 입니다.` });
        setValue('isCheck', false);
      } else {
        clearErrors(['update_user_rrn_front']);
        setValue('isCheck', true);
      }
    }
  }, [update_user_rrn_front, update_user_rrn_back]);

  const onChangeRrn = async () => {
    const data = getValues();
    if (!data.update_user_rrn_front || !data.update_user_rrn_back) {
      return;
    }

    const userSeq = $mixtapeUserSeq();
    const params = { user_rrn: `${data.update_user_rrn_front}-${data.update_user_rrn_back}` };
    const res = await ctf_sendHttpPatch(`/user/${userSeq}`, params);

    if (res.error) {
      uf_popupMessage(`주민번호 변경을 실패하였습니다.\n${res.errorMessage}`);
      return false;
    } else {
      setIsOpenRrnModal();
    }
  };

  return (
    <Modal headerText="주민번호 재설정" hide={setIsOpenRrnModal} isShown={isOpenRrnModal}>
      <Layout>
        <Form style={{ width: '100%' }}>
          <HDivider margin="9px" type="TRANSPARENT" />
          <Text textType="DESCRIPTION">변경할 주민번호를 입력해주세요.</Text>
          <HDivider margin="8px" type="TRANSPARENT" />
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }} spacing={2} pt={2}>
            <Controller
              name="update_user_rrn_front"
              control={control}
              rules={{ required: `주민번호 앞자리를 입력하세요` }}
              render={({ field }) => (
                <TTextField
                  {...field}
                  sx={{ width: '50%' }}
                  id="update_user_rrn_front"
                  onKeyDown={checkOnlyNumber}
                  inputProps={{ maxLength: 6 }}
                  placeholder="앞 6자리"
                />
              )}
            />
            <span>-</span>
            <Controller
              name="update_user_rrn_back"
              control={control}
              rules={{ required: `주민번호 뒷자리를 입력하세요` }}
              render={({ field }) => (
                <TTextField
                  {...field}
                  sx={{ width: '50%' }}
                  id="update_user_rrn_back"
                  onKeyDown={checkOnlyNumber}
                  inputProps={{ maxLength: 7 }}
                  placeholder="뒤 7자리"
                  type="password"
                />
              )}
            />
          </Stack>

          {(errors?.update_user_rrn_front || errors?.update_user_rrn_back) && errors?.update_user_rrn_front ? (
            <ErrorMessage message={errors?.update_user_rrn_front?.message ?? ''} />
          ) : (
            <ErrorMessage message={errors?.update_user_rrn_back?.message ?? ''} />
          )}

          <HDivider margin="15px" type="TRANSPARENT" />

          <EditButtonModal disabled={!isCheck} onClick={onChangeRrn}>
            주민번호 변경
          </EditButtonModal>

          <AlertDialog
            isPopupOpen={uc_open}
            alertText={uc_message}
            hidePopup={() => {
              uf_setOpen(false);
            }}
          />
        </Form>
      </Layout>
    </Modal>
  );
}

export default EditRrnModal;
