import React from 'react';
import Modal from '@components/form/Modal';
import { $mixtapeGetUser } from '@utils/auth';
import { Stack, Typography } from '@mui/material';
import MixButton from '@components/ui/MixButton';
import moment from 'moment';

export type TCurrentStep = 'AUTH_NUMBER' | 'RESET' | 'COMPLETED';

type TChangeCompleteModalProps = {
  setIsOpenChangeCompleteModal: () => void;
  isOpenChangeCompleteModal: boolean;
  callback: () => void;
};

const checkStyle = {
  marginRight: '16px',
  color: '#D4D4D8',
  fontSize: '18px',
  fontWeight: 600,
};

const ChangeCompleteModal = ({
  isOpenChangeCompleteModal,
  setIsOpenChangeCompleteModal,
  callback,
}: TChangeCompleteModalProps) => {
  const userInfo = $mixtapeGetUser();

  return (
    <Modal
      modalStyle={{ width: '660px' }}
      closeButtonStyle={{ width: '24px', heigth: '24px' }}
      hide={setIsOpenChangeCompleteModal}
      isShown={isOpenChangeCompleteModal}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" padding="15px" textAlign="center">
        <Typography fontSize="34px" fontWeight="bold" mb="50px">
          멤버십이 <span style={{ color: '#FF3120' }}>즉시 변경</span>되었습니다.
        </Typography>
        <Typography fontSize="18px" lineHeight="30px" color="#191919" mb="50px">
          {userInfo.email} 주소로 확인 이메일을 보내드립니다.
          <br />
          {userInfo.membership && (
            <>
              {moment(userInfo.membership.end_at).format('YYYY년 MM월 DD일')}까지{' '}
              <span style={{ fontWeight: 'bold' }}>Pro 멤버십</span>을 이용하실 수 있습니다.
            </>
          )}
        </Typography>
        <MixButton
          style={{ marginBottom: '35px' }}
          padding="21px 109px"
          onClick={() => {
            callback();
          }}
          text="변경완료"
        />
      </Stack>
    </Modal>
  );
};

export default ChangeCompleteModal;
