import styled from '@emotion/styled';
import Button from '@mui/material/Button';
export const StyledButton = styled(Button)`
  height: 64px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: #121212;
  color: #ffffff;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  &:disabled {
    background: #dedede;
    color: #fff;
    cursor: not-allowed;
  }
`;
