import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import {
  AUTH_FIND_PASSWORD_ACTION,
  AUTH_FIND_PASSWORD_GET_NUMBER_ACTION,
  AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
} from '@constants/actionNames';
import AuthNumberCertification from '@containers/FindPassword/AuthNumberCertification';
import Completed from '@containers/FindPassword/Completed';
import IsExistId from '@containers/FindPassword/IsExistId';
import ResetPassword from '@containers/FindPassword/ResetPassword';
import { useReduxData } from '@hooks/useReduxData';

export type TCurrentStep = 'INIT' | 'AUTH_NUMBER' | 'RESET' | 'COMPLETED';

type TFindPasswordModalProps = {
  setIsOpenFindPasswordModal: () => void;
  isOpenFindPasswordModal: boolean;
};

function FindIdModal({ isOpenFindPasswordModal, setIsOpenFindPasswordModal }: TFindPasswordModalProps) {
  const [isShowAuthNumberInput, setIsShowAuthNumberInput] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('INIT');
  const { clearData: clearFindPasswordData } = useReduxData(AUTH_FIND_PASSWORD_ACTION);
  const { clearData: clearFindPasswordGetAuthNumberData } = useReduxData(AUTH_FIND_PASSWORD_GET_NUMBER_ACTION);
  const { clearData: clearFindPasswordIsExistId } = useReduxData(AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION);

  const [id, setId] = useState('');
  const [authId, setAuthId] = useState('');

  useEffect(() => {
    if (!isOpenFindPasswordModal) {
      clearFindPasswordData();
      clearFindPasswordGetAuthNumberData();
      clearFindPasswordIsExistId();
      setCurrentStep('INIT');
      setIsShowAuthNumberInput(false);
    }
  }, [isOpenFindPasswordModal]);

  return (
    <Modal headerText="비밀번호 재설정" hide={setIsOpenFindPasswordModal} isShown={isOpenFindPasswordModal}>
      <>
        {currentStep === 'INIT' && <IsExistId setCurrentStep={setCurrentStep} setId={setId} />}
        {currentStep === 'AUTH_NUMBER' && (
          <AuthNumberCertification
            id={id}
            isShowAuthNumberInput={isShowAuthNumberInput}
            setCurrentStep={setCurrentStep}
            setIsShowAuthNumberInput={setIsShowAuthNumberInput}
            setAuthId={setAuthId}
          />
        )}
        {currentStep === 'RESET' && <ResetPassword setCurrentStep={setCurrentStep} authId={authId} />}
        {currentStep === 'COMPLETED' && (
          <Completed setCurrentStep={setCurrentStep} setIsOpenFindPasswordModal={setIsOpenFindPasswordModal} />
        )}
      </>
    </Modal>
  );
}

export default FindIdModal;
