import Button from '@components/form/Button';
import Modal from '@components/form/Modal';
import { routePath } from '@constants/path';
import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import history from '@utils/history';
import React from 'react';

interface RemindPwModalProps {
  changePw: () => void;
  goNextTime: () => void;
  setIsOpenRemindPwModal: () => void;
  isOpenRemindPwModal: boolean;
}

const NextButton = styled(Button)`
  width: 200px;
  height: 64px;
  font-size: 18px;
  font-weight: 300;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  color: black;
`;

const PwChangeButton = styled(Button)`
  width: 360px;
  height: 64px;
  font-size: 18px;
  font-weight: 300;
  border-radius: 10px;
`;

const RemindPwModal = ({ isOpenRemindPwModal, changePw, goNextTime, setIsOpenRemindPwModal }: RemindPwModalProps) => {
  // const goNextTime = () => {
  //   setIsOpenRemindPwModal();
  //   login();
  // };

  // const setNewPassword = () => {
  //   setIsOpenRemindPwModal();
  //   setIsOpenFindPasswordModal();
  // };

  return (
    <Modal
      hide={setIsOpenRemindPwModal}
      isShown={isOpenRemindPwModal}
      wrapperStyle={{ width: '660px' }}
      modalStyle={{ width: '100%' }}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography fontSize="34px" fontWeight="bold" mb="40px">
          비밀번호 변경안내
        </Typography>
        <Typography fontSize="18px" color="#767676" textAlign="center" mb="67px">
          회원님의 개인정보 보호를 위해 3개월마다
          <br />
          비밀번호 변경 안내를 시행하고 있습니다. <br />
          안전한 서비스 이용을 위해 <span style={{ fontWeight: 700, color: '#FF3120' }}>비밀번호 변경</span>을 권고드립니다.
          <br />
          <br />
          지금 변경하시겠습니까?
        </Typography>
        <Stack direction="row" gap="27px">
          <NextButton onClick={() => goNextTime()}>3개월 뒤에 변경하기</NextButton>
          <PwChangeButton onClick={() => changePw()}>비밀번호 재설정</PwChangeButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default RemindPwModal;
