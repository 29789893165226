import { getUserInfo } from '@api/mapper/User';
import row from '@components/form/Row';
import { BackdropContext } from '@hooks/usePopupMessage';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import {
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { $mixtapeGetUser, loadLocalStorage } from '@utils/auth';
import date from 'date-and-time';
import moment from 'moment';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import HDivider from '@components/form/HDivider';

import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import { SettlementViewBox } from '@components/molecules/SettlementManagment/Layout';
import { SettlementAccountBox } from '@components/molecules/SettlementManagment/Row';
import {
  SettlementAmountText,
  SettlementAmountZeroDesc,
  SettlementAmountZeroText,
  SettlementViewBoxTitle,
} from '@components/molecules/SettlementManagment/Text';
import { Icon } from '@images/Icon';

import SettlementList from './SettlementList';

function SettlementManagement(): ReactElement {
  const columns = useMemo(
    () => [
      {
        accessor: 'date',
        Header: '연-월',
        style: {
          width: '20%',
          textAlign: 'center',
          fontSize: '14px',
        },
        Cell: (val: any) => {
          return (
            <Tooltip
              title={getMoneyTooltip(val.row.original)}
              componentsProps={{
                tooltip: {
                  sx: {
                    background: '#323232',
                    fontSize: '12px',
                    maxWidth: 'none',
                    color: '#f1f1f1',
                  },
                },
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>{val.value}</Typography>
            </Tooltip>
          );
        },
      },
      {
        accessor: 'revenue',
        Header: '정산 금액',
        style: {
          width: '80%',
          textAlign: 'left',
        },
        Cell: (val: any) => {
          return (
            <Tooltip
              title={getMoneyTooltip(val.row.original)}
              componentsProps={{
                tooltip: {
                  sx: {
                    background: '#323232',
                    fontSize: '12px',
                    maxWidth: 'none',
                    color: '#f1f1f1',
                  },
                },
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>{val.value}</Typography>
            </Tooltip>
          );
        },
      },
    ],
    [],
  );

  const { ctf_sendHttpGet } = useContext(BackdropContext);
  const [uc_curMoney, uf_setCurlMoney] = useState('');
  const [uc_data, uf_setData] = useState([]);
  const [uc_settlementTitle, uf_setSettlementTile] = useState('');
  const userInfo = $mixtapeGetUser();

  const TooltipCell = styled(TableCell)`
    color: #f2f2f2;
    font-size: 13px;
    border: 1px solid #f1f1f1f1;
    font-family: 'Pretendard';
    font-weight: normal;

    &:first-of-type {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  `;
  const TooltipHeaderCell = styled(TooltipCell)`
    font-weight: bold;
    text-align: center;
  `;

  ///
  /// 서버에서 내려온 데이터를 배열데이터로 변환한다.
  /// 원본데이터는 날짜를 키로 갖는 오브젝트
  ///
  const arrangeData = (data) => {
    if (!data) return [];

    const topDate = data.top_date_key;

    const keysArray = Object.keys(data)
      .filter((key) => key !== 'top_date_key')
      .map((key) => ({
        date: key,
        // saleMonth: moment(key).subtract(2, 'M').format('YYYY년 M월'),
        // payMonth: moment(key).add(1, 'M').format('YYYY년 M월'),
        isOpen: data[key].top_state,
        revenue: String.fromCharCode(8361) + data[key]['total']['revenue'].toLocaleString(),
        albums: data[key]['albums'],
      }));

    // const findIdx = keysArray.findIndex((obj) => obj.isOpen);

    // let curYYYYMM = moment().subtract(1, 'M').format('YYYY-MM');
    // let saleMonth = moment().subtract(3, 'M').format('YYYY년 M월');
    // let payMonth = moment().format('YYYY년 M월');

    // if (findIdx > -1) {
    //   curYYYYMM = keysArray[findIdx].date;
    //   saleMonth = keysArray[findIdx].saleMonth;
    //   payMonth = keysArray[findIdx].payMonth;
    // }

    // 정산 오픈 했지만 정산금액이 0원인 경우
    // if (!keysArray.some((obj: any) => obj.isOpen)) {
    //   // 마지막 정산달 +1 === 현재달일 경우
    //   if (moment(keysArray[0].date).add(1, 'M').format('YYYY-MM') === moment().format('YYYY-MM')) {
    //     curYYYYMM = moment().format('YYYY-MM');
    //     saleMonth = moment().subtract(2, 'M').format('YYYY년 M월');
    //     payMonth = moment().add(1, 'M').format('YYYY년 M월');
    //   } else {
    //     curYYYYMM = moment().subtract(1, 'M').format('YYYY-MM');
    //     saleMonth = moment().subtract(3, 'M').format('YYYY년 M월');
    //     payMonth = moment().format('YYYY년 M월');
    //   }
    // }

    const settlementTitle = `${moment(topDate).subtract(2, 'M').format('YYYY년 M월')} 판매분 정산 예정 금액 (${moment(topDate)
      .add(1, 'M')
      .format('YYYY년 M월')}말 입금)`;
    uf_setSettlementTile(settlementTitle);

    uf_setCurlMoney(data[topDate]?.total?.revenue?.toLocaleString());

    // 판매월
    // let saleMonth = '';
    // // 입금날짜
    // let payMonth = '';

    // // 정산 기준날짜
    // let curYYYYMM = '';

    // // 현재날짜
    // const now = moment('2023-09-25');

    // const openAt = moment(data.open_at);

    // // const openAt = data.open_at;

    // // 오늘날짜와 정산날짜가 같을 경우
    // if (now.format('MM-DD') === moment(openAt).format('MM-DD')) {
    //   curYYYYMM = now.format('YYYY-MM');
    //   saleMonth = moment().subtract(2, 'M').format('YYYY년 M월');
    //   payMonth = moment().add(1, 'M').format('YYYY년 M월');
    // } else {
    //   curYYYYMM = now.subtract(1, 'M').format('YYYY-MM');
    //   saleMonth = moment().subtract(3, 'M').format('YYYY년 M월');
    //   payMonth = moment().format('YYYY년 M월');
    // }

    // const settlementTitle = `${saleMonth} 판매분 정산 예정 금액 (${payMonth}말 입금)`;
    // uf_setSettlementTile(settlementTitle);

    // uf_setCurlMoney(data[curYYYYMM]?.total?.revenue?.toLocaleString());

    // console.log('test', moment().hour() === 17);
    // const isLastDay = moment().endOf('day').format('DD') === moment().format('DD');
    // // 기존에 날짜 기준으로 가져오는 데이터를//// 정산금액
    // let curYYYYMM = moment().subtract(1, 'M').format('YYYY-MM');
    // // const curYYYYMM = moment().subtract(1, 'M').format('YYYY-MM');

    // // 정산페이지 타이틀
    // let saleMonth = moment().subtract(3, 'M').format('YYYY년 M월');
    // let payMonth = moment().format('YYYY년 M월');

    // // const saleMonth = moment().subtract(3, 'M').format('YYYY년 M월');
    // // const payMonth = moment().format('YYYY년 M월');
    // // const settlementTitle = `${saleMonth} 판매분 정산 예정 금액 (${payMonth}말 입금)`;
    // // uf_setSettlementTile(settlementTitle);

    // //마지막 날 일경우
    // if (isLastDay && moment().hour() >= 17) {
    //   curYYYYMM = moment().format('YYYY-MM');
    //   saleMonth = moment().subtract(2, 'M').format('YYYY년 M월');
    //   payMonth = moment().add(1, 'M').format('YYYY년 M월');
    // }

    // // 상단 안내문구
    // const settlementTitle = `${saleMonth} 판매분 정산 예정 금액 (${payMonth}말 입금)`;
    // uf_setSettlementTile(settlementTitle);

    // // 안ㅐ문구 밑에 나오는 실제 정산금액
    // uf_setCurlMoney(data[curYYYYMM]?.total?.revenue?.toLocaleString());

    // const arr = [];
    // for (const k in data) {
    //   // 정산리스트에서 보여주는 내용은 그대로 정산월을 보여줘야해서 주석처리
    //   // const modiMonth = moment(k + '-01 00:00:00')
    //   //   .add(2, 'M')
    //   //   .format('YYYY-MM');

    //   const modiMonth = k;

    //   const nrd = {
    //     date: modiMonth,
    //     revenue: String.fromCharCode(8361) + data[k]['total']['revenue'].toLocaleString(),
    //     albums: data[k]['albums'],
    //   };
    //   arr.push(nrd);
    // }

    // console.log('arr', arr);

    return keysArray;
  };

  const setMoneyData = async () => {
    const res = await ctf_sendHttpGet('/settlements', {});

    uf_setData(arrangeData(res.data));
  };

  useEffect(() => {
    setMoneyData();
  }, []);

  const getMoneyTooltip = (rd) => {
    return (
      <Stack p={2} spacing={2}>
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: 'Pretendard',
          }}
        >
          {rd.date}
        </Typography>
        <Table sx={{ minWidth: '500px' }}>
          <TableHead>
            <TableRow sx={{ background: '#ffffff10' }}>
              <TooltipHeaderCell>앨범명</TooltipHeaderCell>
              <TooltipHeaderCell>정산금액</TooltipHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rd.albums?.map((ele) => {
              return (
                <TableRow key={'tr001' + ele.id}>
                  <TooltipCell> {ele.album_name}</TooltipCell>
                  <TooltipCell style={{ textAlign: 'right' }}>
                    {String.fromCharCode(8361) + ele.revenue.toLocaleString()}
                  </TooltipCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Stack>
    );
  };

  const getTooltipContent = () => {
    return (
      <Stack p={2} spacing={2}>
        <Typography
          sx={{
            fontSize: '16px',
            fontFamily: 'Pretendard',
          }}
        >
          수익 정산 일정 안내
        </Typography>
        <Table>
          <TableHead>
            <TableRow sx={{ background: '#ffffff10' }}>
              <TooltipHeaderCell>구분</TooltipHeaderCell>
              <TooltipHeaderCell>일정</TooltipHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="tr001">
              <TooltipCell>판매내역 리포트 제공 일정</TooltipCell>
              <TooltipCell>
                <p style={{ fontWeight: 'normal' }}>판매월(M) 기준</p>
                <p>익익월(M+2) 말일자까지 제공</p>
              </TooltipCell>
            </TableRow>
            <TableRow key="tr002">
              <TooltipCell>수익 지급 일정</TooltipCell>
              <TooltipCell>
                <p>판매월(M) 기준</p>
                <p>익익익월(M+3) 말일까지 지급</p>
              </TooltipCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    );
  };
  return (
    <>
      <MainHeader logo="logoW" />
      <SettlementViewBox>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <SettlementViewBoxTitle>{uc_settlementTitle}</SettlementViewBoxTitle>
          <Tooltip
            title={getTooltipContent()}
            componentsProps={{
              tooltip: {
                sx: {
                  background: '#323232',
                  fontSize: '12px',
                  maxWidth: 'none',
                  color: '#f1f1f1',
                },
              },
            }}
          >
            <HelpOutlineRoundedIcon
              sx={{
                fontSize: '20px',
                color: '#FFFFFF99',
              }}
            />
          </Tooltip>
        </Stack>
        <HDivider margin="16px" type="TRANSPARENT" />
        {uc_curMoney ? (
          <SettlementAmountText>{String.fromCharCode(8361) + uc_curMoney}</SettlementAmountText>
        ) : (
          <>
            <SettlementAmountZeroText>
              정산 예정 금액이 없습니다. 판매월 기준 익익월 말일까지 제공됩니다.
            </SettlementAmountZeroText>
            <HDivider margin="4px" type="TRANSPARENT" />
            <SettlementAmountZeroDesc>{`(예시 : 4월  판매분 >> 6월 말일까지 제공)`}</SettlementAmountZeroDesc>
          </>
        )}
        <HDivider margin="21px" type="TRANSPARENT" />
        <SettlementAccountBox justify="CENTER">
          <Text style={{ color: '#bcbcbc' }}>계좌정보</Text>
          <VDivider margin="12px" type="TRANSPARENT" />
          <Text>{userInfo.bank?.code_name}</Text>
          <VDivider margin="5px" type="TRANSPARENT" />
          <Text>{userInfo.account_number}</Text>
          <VDivider height="12px" margin="16px" type="GRAY2" />
          <Text>예금주 </Text>
          <VDivider margin="5px" type="TRANSPARENT" />
          <Text>{userInfo.account_holder}</Text>
        </SettlementAccountBox>
        <HDivider margin="36px" type="TRANSPARENT" />
      </SettlementViewBox>
      <SettlementList columns={columns} data={uc_data || []} />

      <MainFooter />
    </>
  );
}

export default SettlementManagement;
