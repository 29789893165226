import TTextField from '@components/form/TTextField';
import { TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ko from 'date-fns/locale/ko';

const styles = {
  paperprops: {
    'div[role=presentation]': {
      display: 'flex',
      '& .PrivatePickersFadeTransitionGroup-root:first-of-type': {
        order: 2,
      },
      '& .PrivatePickersFadeTransitionGroup-root:nth-of-type(2)': {
        order: 1,
        '& div::after': {
          content: '년',
        },
      },
      '& .MuiButtonBase-root': {
        order: 3,
      },
    },
  },
  mobiledialogprops: {
    '.PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
      fontSize: '1.5rem', // 選択した日付のフォントサイズを変更
    },
    'div[role=presentation]:first-of-type': {
      display: 'flex',
      '& .PrivatePickersFadeTransitionGroup-root:first-of-type': {
        order: 2,
      },
      '& .PrivatePickersFadeTransitionGroup-root:nth-of-type(2)': {
        order: 1,
        '& > div::after': {
          content: '"年"',
        },
      },

      '& .MuiButtonBase-root': {
        order: 3,
      },
    },
  },
};

function TDatePicker(props) {
 
  return <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={ko}
    dateFormats={{ monthAndYear: "yyyy년 MM월" }}
    localeText={{
      previousMonth: "지난 달", // < のツールチップ
      nextMonth: "다음 달", // > のツールチップ
    }}
  >
    <DatePicker
      value={props.value}
      onChange={props.handleChange}
      format='yyyy-MM-dd'
      slots={{
        textField: TTextField,
      }}
      slotProps={{textField:{placeholder:props.placeholder}}}

      DialogProps={{ sx: styles.mobiledialogprops }}
      PaperProps={{ sx: styles.paperprops }}
      {...props}
    />
  </LocalizationProvider>;

}

export default TDatePicker;
