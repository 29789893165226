import AlertModal from '@components/form/AlertModal';

import AuthHeader from '@components/molecules/AuthHeader';
import MainFooter from '@components/molecules/MainFooter';
import { Stack } from '@mui/material';
import history from '@utils/history';
import { startTossPayment } from '@utils/toss';
import React, { useState } from 'react';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Text from '@components/form/Text';
import { SignUpMembershipButton } from '@components/molecules/SignUp/Button';
import { SignUpLayout } from '@components/molecules/SignUp/Layout';
import { MembershipDescText } from '@components/molecules/SignUp/Text';
import { useHistory } from 'react-router';

function PaymentFailed() {
  const history = useHistory()
  // const payToss = async () => {
  //   const result = await startTossPayment({ mode: 'create' });
  //
  //   if (result) {
  //     popupMessage(result);
  //   }
  // };

  const [alertText, setAlertText] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const popupMessage = (txt: any) => {
    setAlertText(txt);
    setIsAlertOpen(true);
  };
  const hideAlert = () => {
    setIsAlertOpen(false);
  };
  return (
    <>
      <AuthHeader logo="logoW" />
      <SignUpLayout>
        {/* <Form style={{ */}
        {/*   width: '600px', */}
        {/*   height: 'calc(100vh - 632px)', */}
        {/*   justifyContent: 'center', */}
        {/*   alignItems: 'center', */}
        {/* }}> */}
        <Stack alignItems="center" pb={8} pt={8}>
          <Text textType="TITLE">멤버십 가입 실패</Text>
          <Stack alignItems="flex-start" pt={8} pb={8} spacing={1.5} sx={{ width: '500px' }}>
            <MembershipDescText>카드 정보를 다시 확인하시고, 다시 한번 시도해 보시기 바랍니다.</MembershipDescText>
            <MembershipDescText>
              만약 문제가 지속되면 해당 카드 발급 기관에 문의하시거나
              <br /> 다른 카드로 시도해 보시기 바랍니다.
            </MembershipDescText>
            <MembershipDescText>
              또한, 체크카드의 경우 잔액이 부족한 경우에는 가입이 실패될 수 있으므로, 카드 등록 전에 꼭 잔액을 확인해주시기
              바랍니다.
            </MembershipDescText>
          </Stack>
          <SignUpMembershipButton onClick={()=> history.push('/membership-info')}>멤버십 가입하기</SignUpMembershipButton>
        </Stack>
        {/* </Form> */}
      </SignUpLayout>
      ;{/* <MainFooter /> */}
      <AlertModal isPopupOpen={isAlertOpen} alertText={alertText} hidePopup={hideAlert} />
    </>
  );
}

export default PaymentFailed;
