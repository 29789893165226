import Input from '@components/form/Input';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const FindPasswordInputWithErrorText = styled(Input)`
  ${({ isError }: any) =>
    isError &&
    css`
      border: 1px solid #ff6465;
      color: #ff6465;
    `}
`;
