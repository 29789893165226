///
/// 카드확인 완료이후 정기결제 처리를 담당하는 페이지
///
///

import AlertModal from '@components/form/AlertModal';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import MainFooter from '@components/molecules/MainFooter';
import { SignUpMembershipButton } from '@components/molecules/SignUp/Button';
import { SignUpLayout } from '@components/molecules/SignUp/Layout';
import { MembershipDescTitleText, MembershipTitleText } from '@components/molecules/SignUp/Text';
import { routePath } from '@constants/path';
import MembershipCompleted from '@containers/Payment/MembershipCompleted';
import PaymentFailed from '@containers/Payment/PaymentFailed';
import { BackdropContext } from '@hooks/usePopupMessage';
import { sendHttpPost } from '@utils/api';
import { startTossPayment } from '@utils/toss';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

import AuthHeader from '@components/molecules/AuthHeader';
import { $mixtapeGetUser, $mixtapeSetUser, $mixtapeUserSeq, getAuthInSessionStorage, loadLocalStorage } from '@utils/auth';
import history from '@utils/history';
import { useLocation } from 'react-router';
function Payment(): ReactElement {
  const { ctf_sendHttpGet, ctf_sendHttpPatch } = useContext(BackdropContext);

  const userInfo = $mixtapeGetUser();
  const {search} = useLocation();
  const membershipType = new URLSearchParams(search).get('membershipType');

  const sendFinalPayment = async (ck: any, ak: any) => {
    const res = await sendHttpPost('/payment/pay_start', {
      customerKey: ck,
      authKey: ak,
      userEmail: userInfo.email,
      userName: userInfo.name,
      goodsType: membershipType,
    });

    if (res.data?.code === '200') {
      await callUserInfo();
      history.push(routePath.paymentCompleted.path);
    } else {
      history.push(routePath.paymentFailed.path);
    }
  };

  const callUserInfo = async () => {
    const userSeq = $mixtapeUserSeq();
    const res = await ctf_sendHttpGet(`/users/${userSeq}`);

    if (!res.error) {
      $mixtapeSetUser(res.data);
    }
  };
  const [msg, setMsg] = useState('');

  useEffect(() => {
    const search = window.location.search;
    // @ts-ignore
    const ooo = search.split('&').reduce((prev, curr) => {
      const arr = curr.split('=');
      // @ts-ignore
      const obj = { ...prev };
      // @ts-ignore
      obj[arr[0].replace('?', '')] = arr[1];
      return obj;
    }, {});

    // @ts-ignore
    if (ooo.customerKey && ooo.authKey) {
      // @ts-ignore
      sendFinalPayment(ooo.customerKey, ooo.authKey);
    } else {
      // @ts-ignore
      setMsg(decodeURI(ooo.message));
    }
  }, []);
  const [alertText, setAlertText] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const payToss = async () => {
    const result = await startTossPayment({ mode: 'create',membershipType: membershipType as string });
    setMsg('결제중입니다.');
    if (result) {
      popupMessage(result);
    }
  };

  const popupMessage = (txt: any) => {
    setAlertText(txt);
    setIsAlertOpen(true);
    setMsg(txt);
  };
  const hideAlert = () => {
    setIsAlertOpen(false);
  };
  const getView = () => {
    return (
      <SignUpLayout>
        <Form
          style={{
            width: '600px',
            height: 'calc(100vh - 612px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {msg !== '' ? (
            <>
              <MembershipTitleText>오류가 발생하였습니다.</MembershipTitleText>
              <HDivider margin="25px" type="TRANSPARENT" />
              <p dangerouslySetInnerHTML={{ __html: msg.replace(/\n/g, '<br />') }} />

              <HDivider margin="25px" type="TRANSPARENT" />
              <SignUpMembershipButton onClick={payToss}>mixtape. 가입하기</SignUpMembershipButton>
            </>
          ) : (
            <MembershipTitleText>결제 처리중입니다.</MembershipTitleText>
          )}
        </Form>
        <AlertModal isPopupOpen={isAlertOpen} alertText={alertText} hidePopup={hideAlert} />
      </SignUpLayout>
    );
  };

  return (
    <>
      <AuthHeader logo="logoW" />
      {getView()}
      {/* <PaymentFailed /> */}
      <MainFooter />
    </>
  );
}

export default Payment;
