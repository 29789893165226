import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';

import Button from '@components/form/Button';
import Row from '@components/form/Row';
import { Icon } from '@images/Icon';

import VDivider from './VDivider';

type TImageFileProps = {
  getRootProps: any;
  getInputProps: any;
  description: any;
  fileList: Array<any>;
  isExistDescription?: any;
};

function ImageFile({ getRootProps, getInputProps, description, isExistDescription, fileList }: TImageFileProps) {
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  // console.log({fileList})
  if (fileList?.length) {
    return (
      <Row direction="column" justify="FLEX_START">
        <Row
          align="center"
          direction="row"
          justify="CENTER"
          onMouseEnter={(e) => {
            setIsMouseEnter(true);
          }}
          onMouseLeave={(e) => {
            setIsMouseEnter(false);
          }}
          // onDragEnter={(e) => {
          //   console.log(e)
          //   setIsMouseEnter(true);
          // }
          // }
          // onDragLeave={(e) => {
          //
          //   setIsMouseEnter(false);
          // }
          // }
        >
          <img
            alt="미리보기"
            src={fileList[0]?.preview}
            style={{
              borderRadius: '10px',
              width: '320px',
              height: '320px',
            }}
            onDragEnter={(e) => {
              e.stopPropagation();
              setIsMouseEnter(true);
            }}
            onDragLeave={(e) => {
              e.stopPropagation();
              // setIsMouseEnter(false);
            }}
          />
          {isMouseEnter && (
            <Stack
              justifyContent="center"
              alignItems="center"
              {...getRootProps({ className: 'dropzone' })}
              sx={{
                background: '#00000060',
                border: '1px dashed #DDDDDD',
                borderRadius: '10px',
                color: '#DDDDDD !important',
                fontSize: '14px',
                textAlign: 'center',
                cursor: 'pointer',
                width: '320px',
                position: 'absolute',
              }}
            >
              <input {...getInputProps()} />
              {isExistDescription || description}
            </Stack>
          )}
        </Row>
      </Row>
    );
  }
  return (
    <Row
      align="center"
      justify="CENTER"
      {...getRootProps({ className: 'dropzone' })}
      style={{
        border: '1px dashed #DDDDDD',
        borderRadius: '10px',
        color: '#777',
        fontSize: '14px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '320px',
      }}
    >
      <input {...getInputProps()} />
      {description}
    </Row>
  );
}

export default ImageFile;
