function createActionTypes(type: string, key = 'global') {
  const actionType = {
    [`${type}_READY`]: `${key}/${type}_READY`,
    [`${type}_START`]: `${key}/${type}_START`,
    [`${type}_SUCCEEDED`]: `${key}/${type}_SUCCEEDED`,
    [`${type}_FAILED`]: `${key}/${type}_FAILED`,
  };

  return actionType;
}

export default function getActionTypes(reduxActionName: string): any {
  const result = {
    ...createActionTypes('REQUEST', reduxActionName),
    SET_PARAMETERS: `${reduxActionName}/SET_PARAMETERS`,
    SET_REQ_PARAMETERS: `${reduxActionName}/SET_REQ_PARAMETERS`,
    SET_DATA: `${reduxActionName}/SET_DATA`,
    CLEAR_DATA: `${reduxActionName}/CLEAR_DATA`,
  };

  return result;
}
