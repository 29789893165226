import MainHeader from '@components/molecules/MainHeader';
import React from 'react';
import { Divider, Typography, Stack, styled } from '@mui/material';
import { flatforms } from './data';
import MainFooter from '@components/molecules/MainFooter';

const badgeStyle = {
  // flex: 'none',
  // width: '42px',
  // heigth: '12px',
  padding: '4px 12px',
  background: '#444444',
  fontSize: '11px',
  color: '#D4D4D8',
};

const PointDivider = () => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '1px',
        backgroundColor: 'white',
        marginTop: '8px',
        marginBottom: '36px',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '150px',
          height: '2px',
          backgroundColor: '#EFFD60',
        }}
      ></div>
    </div>
  );
};

const ChannelCard = ({ channel }: { channel: any }) => {
  return (
    <Stack direction="column" width="320px">
      <a href={channel.link} target="_blank" style={{ display: 'inline-block' }}>
        {channel.imgShape === undefined || !channel.imgShape ? (
          <img width="320px" src={channel.image} alt={channel.title} style={{ display: 'block' }} />
        ) : (
          <p
            style={{
              width: '300px',
              height: '140px',
              lineHeight: '140px',
              background: '#fff',
              borderRadius: '20px',
              textAlign: 'center',
            }}
          >
            <img src={channel.image} alt={channel.title} style={{ width: '90%', verticalAlign: 'middle' }} />
          </p>
        )}
      </a>
      <span style={{ marginLeft: '18px' }}>
        <Typography
          fontSize="14px"
          sx={{
            position: 'relative',
            marginBottom: '8px',
            paddingLeft: '8px',
            '::before': {
              position: 'absolute',
              top: '3px',
              left: '0px',
              display: 'block',
              content: '""',
              width: '3px',
              height: '16px',
              background: '#EFFD60',
              borderRadius: '3px',
            },
          }}
        >
          {channel.title}
        </Typography>
        <Stack direction="row" flexWrap="wrap" rowGap="8px" columnGap="6px">
          {channel.countries.map((country: string) => {
            return <div style={badgeStyle}>{country}</div>;
          })}
        </Stack>
      </span>
    </Stack>
  );
};

const Channel = () => {
  return (
    <>
      <MainHeader logo="logoW" />
      <Stack direction="column" paddingY="200px" color="white" justifyContent="center" alignItems="center" bgcolor="#1E1E1E">
        <Stack direction="column" gap="80px" style={{ minWidth: '1280px', maxWidth: '1280px' }}>
          <Stack direction="column" alignItems="center" gap="40px">
            <Typography fontSize="50px" fontWeight="bold">
              유통서비스 채널
            </Typography>
            <Typography fontSize="18px" lineHeight="32px" textAlign="center">
              믹스테이프는 대한민국을 포함하여, 전 세계 190여 개 국가 음악 플랫폼과 소셜 플랫폼에 직접 음악을 공급하고 있습니다.
              <br /> 또한, 지속적으로 유통 서비스 채널을 발굴하여, 아티스트 분들의 음악 매출을 확대하고 있어요.
            </Typography>
          </Stack>
          <Stack direction="column" width="100%" gap="80px">
            {flatforms.map((flatform) => {
              return (
                <Stack direction="column">
                  <Typography fontSize="16px" textAlign="center">
                    {flatform.title}
                  </Typography>
                  <PointDivider />
                  <Stack direction="row" flexWrap="wrap" rowGap="24px">
                    {flatform.channels.map((channel) => {
                      return <ChannelCard channel={channel} />;
                    })}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
      <MainFooter />
    </>
  );
};

export default Channel;
