import { getUserInfo } from '@api/mapper/User';
import AlertDialog from '@components/form/AlertDialog';
import TTextField from '@components/form/TTextField';
import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import { Stack } from '@mui/material';
import { $mixtapeGetUser, $mixtapeUserSeq, getUserSeq, loadLocalStorage } from '@utils/auth';
import React, { useContext } from 'react';

import Text from '@components/form/Text';
import { EditButtonModal } from '@components/molecules/UserInfoSetting/Button';

import { Controller, useForm } from 'react-hook-form';

import { TCurrentStep } from '.';

function Init({ setCurrentStep }) {
  const {
    watch,
    getValues,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });
  const { ctf_sendHttpGet, ctf_sendHttpPatch } = useContext(BackdropContext);
  const { openState: uc_open, setOpenState: uf_setOpen, message: uc_message, popupMessage: uf_popupMessage } = usePopupMessage();
  const userInfo = $mixtapeGetUser();

  async function checkDuplEmail(email: string) {
    if (email.trim() === '') {
      return false;
    }
    const res = await ctf_sendHttpGet(`/check-email/${email}`, {});

    if (res.error) {
      uf_popupMessage(`이메일 : ${email}\n이미 사용중이거나 탈퇴한 이메일입니다.\n다시 입력하세요.`);
      return false;
    }
    return true;
  }

  async function changeEmail() {
    const data = getValues();
    if (!data.email) {
      return;
    }
    const check = await checkDuplEmail(data.email);
    if (!check) {
      return;
    }
    const userSeq = $mixtapeUserSeq();
    const params = { email: data.email };
    const res = await ctf_sendHttpPatch(`/user/${userSeq}`, params);

    if (res.error) {
      uf_popupMessage(`이메일 변경을 실패하였습니다.\n${res.errorMessage}`);
      return false;
    } else {
      setCurrentStep('FINISH');
    }
  }

  return (
    <form onSubmit={handleSubmit(changeEmail)}>
      <Stack spacing={2}>
        <Text textType="DESCRIPTION">회원정보 중 연락처 이메일을 수정하기 위해 인증절차가 필요합니다.</Text>

        <Controller
          name="email"
          control={control}
          rules={{
            required: '새로운 이메일을 입력하세요.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: '이메일 형식을 확인해주세요.',
            },
            validate: {
              sameCheck: (v) => userInfo.email !== v || '기존 이메일과 동일합니다.',
            },
          }}
          render={({ field }) => (
            <TTextField {...field} placeholder="이메일을 입력하세요." type="email" helperText={errors?.email?.message ?? ' '} />
          )}
        />

        <EditButtonModal disabled={!!errors!.email || !watch('email')}>이메일 변경</EditButtonModal>
        <AlertDialog isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />
      </Stack>
    </form>
  );
}

export default Init;
