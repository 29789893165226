import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import Membership from '@containers/Login/Membership';
import React from 'react';

const MembershipInfo = () => {
  return (
    <>
      <MainHeader logo="logoW" />
      <Membership />
      <MainFooter />
    </>
  );
};

export default MembershipInfo;
