import { BackdropContext } from '@hooks/usePopupMessage';
import { apiRequest } from '@utils/api';
import date from 'date-and-time';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { getAlbumSchedules } from '@api/mapper/AlbumManagement';
import Calendar from '@components/form/Caldendar';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { SignUpLayout } from '@components/molecules/SignUp/Layout';
import { ALBUM_INFO_SCHEDULE_LIST_ACTION } from '@constants/actionNames';
import styled from '@emotion/styled';
import { useFormError } from '@hooks/useFormError';
import { useRequest } from '@hooks/useRequest';
import moment from 'moment';
import { Typography } from '@mui/material';

const checkStyle = {
  marginRight: '16px',
  color: '#D4D4D8',
  fontSize: '18px',
  fontWeight: 600,
};

const CalendarContainer = styled(Row)`
  .react-calendar {
    width: 100%;
    height: 512px;
    background: #ffffff;
    border-radius: 0px 0px 10px 10px;
    border: none;
  }
`;

const DAY_OF_THE_WEEK_MAPPER: Record<number, string> = {
  0: '일',
  1: '월',
  2: '화',
  3: '수',
  4: '목',
  5: '금',
  6: '토',
};
type TInfoProps = {
  setCurrentStep: Dispatch<SetStateAction<any>>;
  reduxForm: any;
  setIsDisabledNextButton: any;
};
function Schedule({ setCurrentStep, reduxForm, setIsDisabledNextButton }: TInfoProps) {
  const { setFormData, formData } = reduxForm;
  // const { requestForSingle: getAlbumSchedulesReq, resData: availableDates } = useRequest({
  //   reduxActionName: ALBUM_INFO_SCHEDULE_LIST_ACTION,
  //   mapperFunc: getAlbumSchedules,
  // });

  const { ctf_sendHttpPost, ctf_sendHttpPut, ctf_sendHttpGet } = useContext(BackdropContext);

  //
  // const res: Record<string, any> = await apiRequest({
  //   url: `/schedules`,
  //   method: 'GET',
  // });

  async function callTargetDates() {
    const res = await ctf_sendHttpGet('/schedules', {});
    if (res.error) {
      alert('발매가능일 조회 실패');
      return;
    }

    uf_setAvailableDates(res.data);
  }

  const getSelectedRemainingAccount = (date: any, time: string) => {
    if (!uc_availableDates || !time || !date) {
      return 0;
    }

    return uc_availableDates[date.format('YYYY-MM-DD')] ? uc_availableDates[date.format('YYYY-MM-DD')][time] : 0;
  };

  const selectReleaseDate = (date: any) => {
    setFormData('releaseDate', date);
    setFormData('selectedTime', null);
  };

  useEffect(() => {
    callTargetDates();
  }, []);

  const [uc_availableDates, uf_setAvailableDates] = useState({});
  const [uc_startDate, uf_setStartDate] = useState(new Date());
  useEffect(() => {
    if (!uc_availableDates) {
      return;
    }
    const ads = Object.keys(uc_availableDates);
    if (!ads || ads.length < 1) {
      return;
    }
    const first = ads[0];

    // uf_setAvailableDates(availableDates)
    uf_setStartDate(new Date(first));
  }, [uc_availableDates]);

  useEffect(() => {
    if (!formData?.releaseDate || !formData?.selectedTime) {
      setIsDisabledNextButton(true);
    } else {
      setIsDisabledNextButton(false);
    }
  }, [formData?.releaseDate, formData?.selectedTime]);

  return (
    <SignUpLayout style={{ overflow: 'scroll', background: '#F9F9F9' }}>
      <Form style={{ marginLeft: '360px', marginRight: '360px' }}>
        <Text style={{ textAlign: 'center' }} textType="TITLE">
          발매일정
        </Text>
        <Typography fontSize="14px" lineHeight="28px">
          <span style={checkStyle}>✓</span>발매 일자와 시각은 한국 기준입니다. (KST) <br />
          <span style={checkStyle}>✓</span>발매 신청 후 일정 변경이 어려울 수 있으니 신중하게 일정을 선택해주세요.
          <br />
          <span style={checkStyle}>✓</span>애플뮤직은 플랫폼 정책 이슈로 신청하신 <b>발매일 다음 날 자정</b>에 오픈됩니다. <br />
          <span style={{verticalAlign: "middle", ...checkStyle}}>✓</span><img width="50px" src="/assets/icon/membership_pro_L.svg" style={{verticalAlign: "middle"}}></img> <span style={{ color: '#FF3120', fontWeight: 'bold', verticalAlign: "middle" }}>주 3회</span>{' '}
          <img width="50px" src="/assets/icon/membership_basic_L.svg" style={{verticalAlign: "middle"}}></img> <span style={{ color: '#FF3120', fontWeight: 'bold', verticalAlign: "middle" }}>주 1회</span>
          <span style={{verticalAlign: "middle"}}> 발매가
          가능합니다. </span><br />
        </Typography>

        {/* <HDivider margin="6px" type="TRANSPARENT" /> */}
        {/* <Text style={{ textAlign: 'center', color: '#' }} textType="DESCRIPTION">
          발매일자, 시각은 한국 기준입니다. (KST)
        </Text> */}
        <HDivider margin="5px" type="TRANSPARENT" />
        <Row justify="SPACE_BETWEEN">
          <Row justify="SPACE_BETWEEN" style={{ flexDirection: 'column', flex: 0.6 }}>
            <Row
              justify="SPACE_BETWEEN"
              style={{
                width: '900px',
                background: '#fff',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <Text style={{ marginTop: '33px', marginLeft: '30px', fontSize: '18px', fontWeight: 700 }} textType="TITLE">
                발매일자
              </Text>
              <Text style={{ marginTop: '33px', marginRight: '30px', fontSize: '18px', fontWeight: 'bold' }} textType="TITLE">
                {formData.releaseDate
                  ? `${formData.releaseDate.format('M.DD')} (${DAY_OF_THE_WEEK_MAPPER[formData.releaseDate.day()]})`
                  : ''}
              </Text>
            </Row>
            <CalendarContainer justify="CENTER">
              <Calendar
                date={!!formData.releaseDate ? formData.releaseDate : uc_startDate}
                setDate={selectReleaseDate}
                availableDates={uc_availableDates}
              />
            </CalendarContainer>
            <Row
              justify="FLEX_END"
              style={{
                width: '900px',
                background: '#fff',
                borderRadius: '0px 0px 10px 10px',
              }}
            >
              <div style={{ width: '12px', height: '12px', background: '#121212', borderRadius: '50%', marginBottom: '32px' }} />
              <VDivider margin="4px" type="TRANSPARENT" />
              <Text style={{ color: '#5B5B5B', marginBottom: '32px' }} textType="DESCRIPTION">
                선택
              </Text>
              <VDivider margin="15px" type="TRANSPARENT" />
              <div style={{ width: '12px', height: '12px', background: '#DDDDDD', borderRadius: '50%', marginBottom: '32px' }} />
              <VDivider margin="4px" type="TRANSPARENT" />
              <Text style={{ color: '#5B5B5B', marginRight: '32px', marginBottom: '32px' }} textType="DESCRIPTION">
                불가
              </Text>
            </Row>
          </Row>
          <VDivider margin="20px" type="TRANSPARENT" />
          <Row
            justify="FLEX_START"
            style={{ flexDirection: 'column', flex: 0.4, padding: '32px 30px', background: '#fff', borderRadius: '10px' }}
          >
            <Row justify="SPACE_BETWEEN" style={{ width: '100%' }}>
              <Text style={{ fontSize: '18px', lineHeight: '25px', fontWeight: 700 }} textType="TITLE">
                발매시간
              </Text>
              <Text style={{ fontSize: '16px', lineHeight: '25px', fontWeight: 700 }} textType="TITLE">
                {formData?.selectedTime ? `오후 ${formData?.selectedTime === '12' ? '12' : '6'}시` : ''}
              </Text>
            </Row>
            <HDivider margin="18.5px" type="TRANSPARENT" />
            <Row
              justify="CENTER"
              onClick={() => {
                if (getSelectedRemainingAccount(formData.releaseDate, '12') > 0) {
                  setFormData('selectedTime', '12');
                }
              }}
              style={{
                background: getSelectedRemainingAccount(formData.releaseDate, '12') > 0 ? '#121212' : '#DDDDDD',
                width: '200px',
                padding: '30px 0',
                flexDirection: 'column',
                borderRadius: '10px',
                cursor: getSelectedRemainingAccount(formData.releaseDate, '12') > 0 ? 'pointer' : 'not-allowed',
              }}
            >
              <Text
                style={{
                  fontSize: '16px',
                  lineHeight: '23px',
                  fontWeight: 700,
                  color: `${formData.selectedTime === '12' ? '#FFFD60' : '#FFF'}`,
                }}
                textType="TITLE"
              >
                오후 12시
              </Text>
              <HDivider margin="10px" type="TRANSPARENT" />
              <Text style={{ color: `${formData.selectedTime === '12' ? '#FFFD60' : '#FFF'}` }} textType="DESCRIPTION">
                잔여 구좌
              </Text>
              <Text style={{ color: `${formData.selectedTime === '12' ? '#FFFD60' : '#FFF'}` }} textType="DESCRIPTION">
                {`${uc_availableDates[formData.releaseDate?.format('YYYY-MM-DD')]?.[12] ?? 0}개`}
              </Text>
            </Row>
            <HDivider margin="15px" type="TRANSPARENT" />
            <Row
              justify="CENTER"
              onClick={() => {
                if (getSelectedRemainingAccount(formData.releaseDate, 18) > 0) {
                  setFormData('selectedTime', '18');
                }
              }}
              style={{
                background: getSelectedRemainingAccount(formData.releaseDate, 18) > 0 ? '#121212' : '#DDDDDD',
                width: '200px',
                padding: '30px 0',
                flexDirection: 'column',
                borderRadius: '10px',
                cursor: getSelectedRemainingAccount(formData.releaseDate, 18) > 0 ? 'pointer' : 'not-allowed',
              }}
            >
              <Text
                style={{
                  fontSize: '16px',
                  lineHeight: '23px',
                  fontWeight: 700,
                  color: `${formData.selectedTime === '18' ? '#FFFD60' : '#FFF'}`,
                }}
                textType="TITLE"
              >
                오후 6시
              </Text>
              <HDivider margin="10px" type="TRANSPARENT" />
              <Text style={{ color: `${formData.selectedTime === '18' ? '#FFFD60' : '#FFF'}` }} textType="DESCRIPTION">
                잔여 구좌
              </Text>
              <Text style={{ color: `${formData.selectedTime === '18' ? '#FFFD60' : '#FFF'}` }} textType="DESCRIPTION">
                {`${uc_availableDates[formData.releaseDate?.format('YYYY-MM-DD')]?.[18] ?? 0}개`}
              </Text>
            </Row>
            <HDivider margin="17.5px" type="TRANSPARENT" />
            <Row justify="FLEX_END" style={{ width: '100%' }}>
              <div style={{ width: '12px', height: '12px', background: '#121212', borderRadius: '50%' }} />
              <VDivider margin="4px" type="TRANSPARENT" />
              <Text style={{ color: '#5B5B5B' }} textType="DESCRIPTION">
                선택
              </Text>
              <VDivider margin="15px" type="TRANSPARENT" />
              <div style={{ width: '12px', height: '12px', background: '#DDDDDD', borderRadius: '50%' }} />
              <VDivider margin="4px" type="TRANSPARENT" />
              <Text style={{ color: '#5B5B5B' }} textType="DESCRIPTION">
                불가
              </Text>
            </Row>
          </Row>
        </Row>
        <HDivider margin="70px" type="TRANSPARENT" />
      </Form>
    </SignUpLayout>
  );
}

export default Schedule;
