import AlertModal, { popupMessage } from '@components/form/AlertModal';
import { TAutoComplete } from '@components/form/ChipSelect';
import InputSuffix from '@components/form/InputSuffix';
import Label from '@components/form/Label';
import Row from '@components/form/Row';
import { getCookies, setCookies } from '@utils/cookies';
import { isUndefined } from 'lodash';
import TTextField from '@components/form/TTextField';
import VDivider from '@components/form/VDivider';
import { useTimer } from '@hooks/useTimer';
import { $mixtapeGetUser, loadLocalStorage } from '@utils/auth';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';

import { checkOnlyNumber } from '@containers/SignUp';
import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import { Icon } from '@images/Icon';
import { replaceAll } from '@utils/data';

import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';

import HDivider from '@components/form/HDivider';
import Text from '@components/form/Text';
import {
  GetAuthNumberButton,
  ResendAuthNumberButton,

} from '@components/molecules/SignUp/Button';

import { MembershipDescText, ReConfirmNumberText } from '@components/molecules/SignUp/Text';
import { Autocomplete, Stack } from '@mui/material';

function BankAuthForm() {
  const [accountCertificationStatus, setAccountCertificationStatus] = useState<'READY' | 'SUCCESS' | 'FAIL'>('READY');
  const userInfo = $mixtapeGetUser()
  /// 은행계좌 인증버튼 활성화 체크 함수
  const [bankOtp, setBankOtp] = useState('');
  const {
    openState: uc_open,
    setOpenState: uf_setOpen,
    message: uc_message,
    popupMessage: uf_popupMessage,
  } = usePopupMessage();

  const {
    ctf_sendHttpGet,
    ctf_sendHttpPost,
  } = useContext(BackdropContext);



  const [isShowAuthCodeInput, setIsShowAuthCodeInput] = useState<boolean>(false);



  const {
    minutes: accountMinutes,
    seconds: accountSeconds,
    setMinutes: setAccountMinutes,
    setSeconds: setAccountSeconds,
  } = useTimer({ stopCondition: accountCertificationStatus === 'SUCCESS' });

  const {
    watch,
    formState: {
      errors,
    },
    getValues,
    setValue,
    control,
  } = useFormContext();

  /// 은행 계좌 인증코드 요청
  const callBankAuthCode = async (e) => {
    e.preventDefault()
    if (Number(getCookies('authLimit')) >= 5 ) {
      uf_popupMessage('하루 계좌인증은 5번까지 가능합니다. 내일 이용해주세요.');
      return
    }
    const val = getValues();

    const params = {
      bank_cd: val.bank.cd,
      account_id: replaceAll(val.account_number, '-', ''),
      bank_name: val.account_holder,
    };

    const res = await ctf_sendHttpPost(`/token/bank_check`, params);

    if (!res.error) {
      if(res.data.code !== '200'){
        uf_popupMessage('계좌정보 조회를 실패하였습니다 :\n' + res.message);
        return
      }
      setBankOtp(res.data.res_uniq_id);

      setIsShowAuthCodeInput(true);
      setAccountSeconds(0);
      setAccountMinutes(3);
      const count = isUndefined(getCookies('authLimit')) ? "1" : Number(getCookies('authLimit')) +1
      setCookies('authLimit',String(count) as string,String(new Date().getTime()))
    } else {
      setIsShowAuthCodeInput(false);
      uf_popupMessage('계좌정보 조회를 실패하였습니다 :\n' + res.errorMsg?.response?.data?.message);

    }

  };

  
  // 화면 로딩후 은행코딩 가져옴
  const [uc_bankCodes, uf_setBankCodes] = useState([]);
  const callBankCoodes = async () => {
    //은행 코드: {Host}/codes?parent_code=banks
    const params = { parent_code: 'banks' };
    const res = await ctf_sendHttpGet(`/codes`, params, false);

    if (!res.error) {
      uf_setBankCodes(res.data);
    }
  };


  const [account_holder, account_number, bank, authChecked] = watch(['account_holder', 'account_number', 'bank', 'authChecked']);

  useEffect(() => {
    callBankCoodes();
  }, []);


  // 계좌인증버튼 활성화 여부 결정
  ///
  /// 은행버튼은 후크로 활성화 여부 결정
  ///
  const [uc_bankAuthBtnDisabled, uf_setBankAuthBtnDisabled] = useState(true);
  useEffect(() => {

    const _userName = userInfo?.name;
    let enabled = true;
    enabled = enabled && !!_userName && !!account_holder && _userName === account_holder;
    enabled = enabled && !!bank;
    enabled = enabled && !!account_number;

    uf_setBankAuthBtnDisabled(!enabled)

  }, [account_holder, account_number, bank]);

  
  
  
  async function callBankAuthCodeCheck(otp: any) {

    const params = {
      res_uniq_id: bankOtp,
      bank_otp: otp,
    };
    const res = await ctf_sendHttpPost(`/token/bank_auth_check`, params);
    let result = 'READY';
    if (!res.error) {
      setAccountCertificationStatus('SUCCESS');
      setValue('authChecked', true)
    } else {
      setAccountCertificationStatus('FAIL');
      setValue('authChecked', false)
    }
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Text textType='HEADER3'>계좌 정보</Text>

      <p style={{ lineHeight: 1.6 }}>음원 수익을 지급받을 계좌 정보를 입력해 주세요.<br />
        회원가입 시 입력한 이름과 예금주명은 동일해야 합니다.<br />
        계좌 정보는 1년에 1회만 수정 가능합니다.</p>

      <Stack
        spacing={5}
        sx={{
          background: '#F5F5F5',
          padding: '26px 24px',
        }}
      >
        <Stack spacing={1}>
          <Label htmlFor='bank' required={true}>은행 선택</Label>
          <Controller
            name='bank'
            control={control}
            rules={{ required: '은행을 선택하여 주십시오' }}
            render={({ field: { onChange, value } }) => (
              <TAutoComplete
                onChange={(event, item) => {

                  onChange(item);
                }}
                value={value}
                placeholder='은행명을 입력하세요.'
                isOptionEqualToValue={(option, value) => {
                  // return option.id === value.id;
                  return value === undefined || value === "" || option.cd === value.cd
                }}
                getOptionLabel={(option) => option?.label ?? ''}

                options={uc_bankCodes?.map((item: any) => ({
                  label: item.code_name,
                  id: item.id,
                  cd: item.code_disc
                }))}
              />


              )}
          />
          <ErrorMessage message={errors?.bank?.message ?? ''} />
        </Stack>
        <Stack spacing={1}>
          <Label htmlFor='account_number' required={true}>계좌 번호 입력</Label>
          <Controller
            name='account_number'
            control={control}
            rules={{ required: '계좌 번호를 입력하세요.' }}
            render={({ field }) => (
              <TTextField
                {...field}
                id='account_number'
                onKeyDown={checkOnlyNumber}
                {...field}

                sx={{
                  borderRadius: '10px',
                  background: '#FFF',
                }}
                type='number'
                placeholder='숫자만 입력하세요.'

              />)}
          />
          <ErrorMessage message={errors?.account_number?.message ?? ''} />
        </Stack>
        <Stack spacing={1}>
          <Label htmlFor='account_holder' required={true} style={{ marginBottom: '6px' }}>예금주</Label>
          <Stack direction='row' spacing={1}>
            <Controller
              name='account_holder'
              control={control}
              rules={{ required: '예금주 이름을 입력하세요.',
                validate: {
                  isEqual: v => v === userInfo.name || '예금주명과 회원가입 정보의 이름이 일치하지 않습니다.',
                }
              }}
              render={({ field }) => (
                <TTextField
                  {...field}
                  id='account_holder'
                   sx={{
                    width:'50%',
                    borderRadius: '10px',
                    background: '#FFF',
                  }}
                  placeholder='예금주'
                />
              )}
            />


            <GetAuthNumberButton
              // 후크변수로 대체
              disabled={uc_bankAuthBtnDisabled}
              onClick={callBankAuthCode}
            >
              계좌인증
            </GetAuthNumberButton>
          </Stack>

          <ErrorMessage message={errors?.account_holder?.message ?? ''} />
        </Stack>
        {isShowAuthCodeInput && (
          <Stack>

            <Label htmlFor='bank_otp' style={{ marginBottom: '10px' }}>계좌 인증코드</Label>

            <Text textType='DESCRIPTION'>입력하신 계좌로 1원을 입금했습니다.</Text>
            <Text textType='DESCRIPTION' style={{ marginBottom: '10px' }}>보낸 사람(입금자명)에 보이는 숫자 6자리를 입력해 주세요.</Text>
            <Controller
              name='bank_otp'
              control={control}
              rules={{ required: '인증코드 입력' }}
              render={({ field }) => (
                <InputSuffix
                  id='bank_otp'
                  disabled={authChecked}
                  onChange={(e: ChangeEvent) => {
                    if (!authChecked) {

                      const { value } = e.target as any;
                      if (value.length === 6) {
                        callBankAuthCodeCheck(value);
                      }

                    }
                  }}
                  placeholder='인증코드 입력'

                  suffix={
                    <>
                      {accountCertificationStatus !== 'SUCCESS' && <div
                        className={accountMinutes === 0 && parseInt(accountSeconds, 10) === 0 ? 'time' : 'time_enable'}
                      >{`${accountMinutes}:${accountSeconds}`}</div>
                      }
                      <div>
                        <Icon icon={authChecked ? 'btnCheck' : 'btnUnCheck'} />
                      </div>
                    </>
                  }
                  type='number'

                />
              )}
            />
            {accountCertificationStatus === 'FAIL' && (
              <>
                <HDivider margin='5px' type='TRANSPARENT' />
                <Row justify='FLEX_START'>
                  <ReConfirmNumberText>인증코드를 다시 확인해 주세요</ReConfirmNumberText>
                  <VDivider margin='8px' type='TRANSPARENT' />
                  <ResendAuthNumberButton
                    onClick={(e) => {
                      e.preventDefault();
                      callBankAuthCode();
                    }}
                  >
                    인증코드 재전송
                  </ResendAuthNumberButton>
                </Row>
              </>
            )}
          </Stack>
        )}

      </Stack>

      <AlertModal

        isPopupOpen={uc_open}
        alertText={uc_message}
        hidePopup={() => uf_setOpen(false)}
      />
    </Stack>
  )
    ;
}

export default BankAuthForm;
