import MainHeader from '@components/molecules/MainHeader';
import { $mixtapeGetUser } from '@utils/auth';
import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import MainFooter from '@components/molecules/MainFooter';
import MixLogoSlide from '@components/ui/MixLogoSlide';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import history from '@utils/history';
import { Link } from 'react-router-dom';

const checkStyle = {
  marginRight: '16px',
  color: '#D4D4D8',
  fontSize: '18px',
  fontWeight: 600,
};

const MembershipCard = styled.div<{ isPro?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 24px;

  .membership-badge {
    width: max-content;
    text-align: center;
    vertical-align: middle;
    margin-bottom: 20px;
    padding: 5px 19px;
    border-radius: 15px;
    font-size: 12px;
  }

  .card-line {
    margin: 30px 0;
    height: 1px;
    background: #d4d4d8;
  }

  .subscribe-btn {
    padding: 10px 32px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
  }

  ${(props) => {
    if (props.isPro) {
      return css`
        min-width: 500px;
        background-color: black;
        color: white;

        & .subscribe-btn {
          background-color: #effd60;
          color: #191919;
        }
      `;
    } else {
      return css`
        min-width: 340px;
        height: 505px;
        height: 555px;
        background-color: white;
        color: #191919;

        & .membership-badge {
          border: 1px solid #d4d4d8;
          color: #191919;
        }

        & .subscribe-btn {
          background-color: #000;
          color: white;

          &:hover {
            background-color: #000;
          }
        }
      `;
    }
  }};
`;

const MembershipTable = styled.table`
  width: 100%;
  margin-bottom: 80px;

  & th,
  td {
    font-size: 18px;
    font-weight: 300;
  }

  & th div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 12px;
    font-size: 24px;
    font-weight: 600;
  }

  & th:nth-child(2) {
    border-right: 1px solid #d4d4d8;
  }

  & td {
    padding: 20px 12px;
    border-top: 1px solid #d4d4d8;
    border-bottom: 1px solid #d4d4d8;
  }
`;

const GOTermsBtn = styled(Button)`
  width: max-content;
  padding: 8px 46px;
  margin: 20px;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 300;
  border: 1px solid white;
  background-color: transparent;
  color: white;
`;

const EmailFormEx = styled.div`
  padding: 45px 50px;
  margin-left: 20px;
  border: 1px solid white;
  border-radius: 10px;
  background-color: #393939;
  opacity: 0.9;
`;

const trData = [
  {
    title: '유통 수수료',
    basic: '0%',
    pro: '0%',
  },
  {
    title: '전세계 음원 플랫폼',
    basic: '✓',
    pro: '✓',
  },
  {
    title: '소셜 플랫폼 발매',
    basic: '✓',
    pro: '✓',
  },
  {
    title: '앨범 발매 가능 횟수',
    basic: '주 1회',
    pro: '주 3회',
    isProPointStyle: true,
  },
  {
    title: '아티스트 프로필 등록 무제한',
    basic: '✓',
    pro: '✓',
  },
  {
    title: '앨범 발매일 예약 (2주 후부터)',
    basic: '✓',
    pro: '✓',
  },
  {
    title: 'Youtube Content ID 발급 요청',
    basic: '-',
    pro: '✓ (수수료 15%)',
    isProPointStyle: true,
  },
  {
    title: 'YouTube 공식 아티스트 채널(OAC) 신청',
    basic: '-',
    pro: '✓',
    isProPointStyle: true,
  },
  {
    title: '무료 UPC/ISRC 발급',
    basic: '✓',
    pro: '✓',
  },
  {
    title: '서비스 채널, 국가 제외 제한 설정',
    basic: '✓',
    pro: '✓',
  },
  {
    title: '믹스테이프 유튜브 채널 MV 업로드',
    basic: '-',
    pro: '✓',
    isProPointStyle: true,
  },
  {
    title: '발매 후 앨범 수정 가능 횟수',
    basic: '앨범 당 총 2회',
    pro: '무제한',
    isProPointStyle: true,
  },
  {
    title: '공식 정산 리포트 제공',
    basic: '✓',
    pro: '✓',
  },
  {
    title: '엑셀러레이팅 프로그램 지원',
    basic: '✓',
    pro: '✓',
  },
  {
    title: '트랙 수 제한',
    basic: '최대 20트랙',
    pro: '최대 30트랙',
    isProPointStyle: true,
  },
];

const MembershipGuide = () => {
  const sessionUserInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')!) : null;

  const goSubcribe = (type: "basic" | "pro") => {
    if (!sessionUserInfo) {
      history.push('/login');
    } else {
      if (type === "basic"){
        history.push('/membership-info/basic');
      } else {
        history.push('/membership-info/pro');
      }
    }
  };


  return (
    <>
      <MainHeader logo="logoW" />
      <Stack direction="column" paddingY="200px" color="white" justifyContent="center" alignItems="center" bgcolor="#1E1E1E">
        {/*  style={{ minWidth: '1280px', maxWidth: '1280px' }} */}
        <Stack direction="column" gap="40px" alignItems="center" style={{ minWidth: '1280px', maxWidth: '1280px' }}>
          <Typography fontSize="50px" fontWeight="bold">
            유료 멤버십 안내
          </Typography>
          <Typography fontSize="18px">
            한 달 5,500원 가격으로 유통 수수료 없이 전 세계 음악 플랫폼에 자유롭게 앨범을 발매할 수 있어요.
          </Typography>
          <Stack direction="row" mt="40px" gap="20px">
            {/* 베이직 */}
            <MembershipCard>
              <div className="membership-badge">BASIC</div>
              <Typography fontSize="36px" fontWeight="bold" color="#191919">
                Basic
              </Typography>
              <hr className="card-line" />
              <Typography fontSize="18px" lineHeight="48px" mb="48px">
                <span style={checkStyle}>✓</span>유통 수수료 0% <br />
                <span style={checkStyle}>✓</span>자유로운 발매 일정 선택 (주 1회 발매 가능) <br />
                <span style={checkStyle}>✓</span>전 세계 음악 플랫폼, 소셜미디어 채널 발매 <br />
                <span style={checkStyle}>✓</span>발매 이후 2회 수정 가능 <br />
                <span style={checkStyle}>✓</span>트랙 수 최대 20개 <br />
              </Typography>
              <hr className="card-line" />
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Typography fontSize="24px" color="#191919">
                    ₩ 66,000
                  </Typography>
                  <Typography fontSize="18px" color="#D4D4D8">
                    &nbsp;/ year
                  </Typography>
                </Stack>

                {(!sessionUserInfo || !sessionUserInfo.is_membership) && (
                  <Button
                    className="subscribe-btn"
                    onClick={() => goSubcribe("basic")}
                    endIcon={<img height="10px" src="/assets/icon/next-w.svg"></img>}
                  >
                    구독하기
                  </Button>
                )}
              </Stack>
            </MembershipCard>
            {/* 프로 */}
            <MembershipCard isPro>
              <Stack direction="row" justifyContent="space-between">
                <div>
                  <Stack direction="row" alignItems="center" gap="12px">
                    <div className="membership-badge" style={{ border: '1px solid #EEFD60', color: '#EEFD60' }}>
                      NEW
                    </div>
                    <div className="membership-badge" style={{ border: '1px solid #FFFFFF', color: '#FFFFFF' }}>
                      EVENT
                    </div>
                    <div className="membership-badge" style={{ border: '1px solid #B760FC', color: '#B760FC' }}>
                      PRO
                    </div>
                  </Stack>
                  <Typography fontSize="36px" fontWeight="bold" color="white">
                    Pro
                  </Typography>
                </div>
                {/* <img width="92px" src="/assets/icon/august_open.svg"></img> */}
              </Stack>
              <hr className="card-line" />
              <Typography fontSize="18px" lineHeight="48px">
                <span style={checkStyle}>✓</span>유통 수수료 0% <br />
                <span style={checkStyle}>✓</span>자유로운 발매 일정 선택 (주 3회 발매 가능) <br />
                <span style={checkStyle}>✓</span>전 세계 음악 플랫폼, 소셜미디어 채널 발매 <br />
                <span style={checkStyle}>✓</span>YouTube 공식 아티스트 채널(OAC) 신청 가능 <br />
                <span style={checkStyle}>✓</span>믹스테이프 유튜브 채널 MV 업로드
                <br />
                <span style={checkStyle}>✓</span>Youtube Content ID 발급 요청 가능 (수수료 15%) <br />
                <span style={checkStyle}>✓</span>발매 이후 무제한 수정 가능 <br />
                <span style={checkStyle}>✓</span>트랙 수 최대 30개 <br />
              </Typography>

              <hr className="card-line" />
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Typography fontSize="18px" color="#767676" mr="10px" sx={{ textDecoration: 'line-through' }}>
                    &nbsp;99,000원&nbsp;
                  </Typography>
                  <Typography fontSize="24px" color="#EEFD60">
                    ₩ 88,000
                  </Typography>
                  <Typography fontSize="18px" color="#D4D4D8">
                    &nbsp;/ year
                  </Typography>
                </Stack>
                {(!sessionUserInfo || !sessionUserInfo.is_membership) && (
                  <Button
                    className="subscribe-btn"
                    onClick={() => goSubcribe("pro")}
                    endIcon={<img height="10px" src="/assets/icon/next.svg"></img>}
                  >
                    구독하기
                  </Button>
                )}
              </Stack>
              <hr className="card-line" />
              <Typography fontSize="16px" color="#767676" lineHeight="24px">
                ▪ 해당 할인가 적용 기간은 1년이며 다음 결제일에는 정상가로 결제됩니다.
                <br /> ▪ 해당 멤버십을 중도 해지하시는 경우, 추후 동일 혜택 적용이 어렵습니다.
              </Typography>
            </MembershipCard>
          </Stack>
          <MixLogoSlide style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }} />
          <MembershipTable>
            <thead>
              <tr>
                <th style={{ width: '40%' }}>&nbsp;</th>
                <th style={{ textAlign: 'left' }}>
                  <div style={{ borderBottom: '8px solid #D4D4D8' }}>
                    <span>Basic</span>
                    <span style={{ fontWeight: 300 }}>
                      연 66,000 <span style={{ fontSize: '12px' }}>원 (부가세 포함)</span>
                    </span>
                  </div>
                </th>
                <th style={{ textAlign: 'left' }}>
                  <div style={{ borderBottom: '8px solid #60E2FD' }}>
                    <span>Pro</span>
                    <span style={{ fontWeight: 300 }}>
                      연 99,000 <span style={{ fontSize: '12px' }}>원 (부가세 포함)</span>
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {trData.map((tr) => {
                return (
                  <tr>
                    <td>{tr.title}</td>
                    <td>{tr.basic}</td>
                    <td style={{ color: tr.isProPointStyle ? '#60E2FD' : 'white' }}>{tr.pro}</td>
                  </tr>
                );
              })}
            </tbody>
          </MembershipTable>
          <Stack direction="column" gap="20px" mb="40px" style={{ minWidth: '1040px', maxWidth: '1040px' }}>
            <Typography fontSize="40px" fontWeight="bold">
              구독 안내
            </Typography>
            <Typography fontSize="16px" lineHeight="40px">
              <span
                style={{
                  marginRight: '12px',
                  color: '#EFFD60',
                }}
              >
                °
              </span>
              멤버십 결제 금액에는 부가세가 포함되어 있습니다.
              <br />
              <span
                style={{
                  marginRight: '12px',
                  color: '#EFFD60',
                }}
              >
                °
              </span>
              멤버십은 결제일로부터 1년 단위로 자동 갱신되는 상품이며, 추가 비용이나 약정은 없습니다.
              <br />
              <span
                style={{
                  marginRight: '12px',
                  color: '#EFFD60',
                }}
              >
                °
              </span>
              서비스에 <span style={{ fontWeight: 600 }}>부정적 영향</span>을 미치거나,{' '}
              <span style={{ fontWeight: 600 }}>어뷰징(abusing) 행위</span>를 할 경우 멤버십이 중지 처리 될 수 있습니다.
              <br />
              <span
                style={{
                  marginRight: '12px',
                  color: '#EFFD60',
                }}
              >
                °
              </span>
              19세 미만은 회원 가입 및 멤버십 구매가 불가능합니다.
              <br />
              <span
                style={{
                  marginRight: '12px',
                  color: '#EFFD60',
                }}
              >
                °
              </span>
              <img width="50px" src="/assets/icon/membership_pro_L.svg"></img> 믹스테이프는 Youtube CID 발급 권한을 보유하고
              있으나 Youtube 정책에 따라{' '}
              <span style={{ textDecoration: 'underline' }}>CID 발급 및 영상 수익화 요청이 반려될 수 있습니다.</span>
              <br />
              <span
                style={{
                  marginRight: '12px',
                  color: '#EFFD60',
                }}
              >
                °
              </span>
              <img width="50px" src="/assets/icon/membership_pro_L.svg"></img> Content ID 발급 방법 및 자격요건에 대한 자세한
              내용은{' '}
              <Link
                to="/notice/6"
                style={{
                  color: '#fff',
                  textDecoration: 'underline',
                }}
              >
                '공지사항'
              </Link>
              에서 확인해주세요.
              <br />
              <span
                style={{
                  marginRight: '12px',
                  color: '#EFFD60',
                }}
              >
                °
              </span>{' '}
              <img width="50px" src="/assets/icon/membership_basic_L.svg"></img>{' '}
              <img width="50px" src="/assets/icon/membership_pro_L.svg"></img> 2024년 10월 정산분부터 기존 Basic 이용 회원의
              Youtube CID를 통해 발생하는 수익에 대해서도 수수료 15%가 발생됩니다.
              <br />
              <span
                style={{
                  marginRight: '12px',
                  color: '#EFFD60',
                }}
              >
                °
              </span>
              <img width="50px" src="/assets/icon/membership_pro_L.svg"></img>{' '}
              <span style={{ textDecoration: 'underline' }}>
                믹스테이프 유튜브 채널에 업로드되는 MV는 심의가 불필요하나 내부 정책 상 부적합한 영상은 제외될 수 있습니다.
              </span>
            </Typography>
            <Stack direction="column" ml="15px" padding="30px 40px" gap="13px" borderRadius="5px" bgcolor="#ffffff19">
              <Typography fontSize="16px" color="#60E2FD">
                Youtube CID란?
              </Typography>
              <Typography fontSize="16px" lineHeight="32px" color="#FFFFFF">
                ‘Youtube Content ID’로 콘텐츠 소유자가 자신의 저작물을 보호하고 관리할 수 있도록 도와주는 도구입니다.
                <br /> 콘텐츠 소유자가 저작물을 인증할 수 있는 파일(음악, 비디오 등)을 제공하게 되면 이 파일을 '지문 파일'로
                전환시켜 보관하고, 이 지문 파일을 통해 저작권이 포함된 콘텐츠를 찾아 소유자에게 알려 주는 역할을 합니다.
              </Typography>
              <br /> <br />
              <Typography fontSize="16px" color="#60E2FD">
                YouTube 공식 아티스트 채널이란?
              </Typography>
              <Typography fontSize="16px" lineHeight="32px" color="#FFFFFF">
                YouTube 공식 아티스트 채널(Official Artist Channel)은 아티스트의 콘텐츠를 하나의 채널로 통합하여 쉽게 접근하고
                소통할 수 있도록 만 든 공식 채널로 구독자와 콘텐츠를 한 곳에 모아 보다 더 효과적으로 홍보할 수 있습니다. 자격
                요건과 신청 방법은{' '}
                <Link
                  to="/notice/6"
                  style={{
                    color: '#fff',
                    textDecoration: 'underline',
                  }}
                >
                  공지사항
                </Link>
                에서 확인해주 세요.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="column" gap="20px" style={{ minWidth: '1040px', maxWidth: '1040px' }}>
            <Typography fontSize="40px" fontWeight="bold">
              변경 안내
            </Typography>
            <Stack>
              <Typography fontSize="16px" lineHeight="40px">
                <span style={{ marginRight: '12px', color: '#EFFD60' }}>°</span>기존 멤버십을 즉시 변경하고자 할 경우, 아래와 같이
                처리됩니다.
              </Typography>
              <Typography fontSize="14px" lineHeight="32px" color="#999999" ml="20px">
                1. <b> 앨범 발매 이력이 있거나 결제일로부터 30일을 경과</b>하여 요청하는 경우
                <br />
                &nbsp; &nbsp; - 정상가로 구매했다면 신청일 기준 일할 계산하여 부분 환불 처리 후, 변경하는 멤버십 요금 결제하여
                변경
                <br />
                &nbsp; &nbsp; - <b>할인가</b>로 구매했다면 신청일 기준 <b>정상가 기준</b>으로 일할 계산하여 부분 환불 처리 후,
                변경하는 멤버십 요금 결제하여 변경
                <br />
                2. <b>앨범 발매 이력이 없고 결제일로부터 30일 이내</b> 요청하는 경우
                <br /> &nbsp; &nbsp; - 100% 전액 환불 후, 변경하는 멤버십 요금 결제하여 멤버십 변경
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="column" gap="20px" style={{ minWidth: '1040px', maxWidth: '1040px' }}>
            <Typography fontSize="40px" fontWeight="bold">
              환불 안내
            </Typography>
            <Stack>
              <Typography fontSize="16px" lineHeight="40px">
                <span style={{ marginRight: '12px', color: '#EFFD60' }}>°</span>주요 환불 정책은 다음과 같고, 세부 정보는 유료
                이용약관을 참고해주세요.
              </Typography>
              <Typography fontSize="14px" lineHeight="32px" color="#999999" ml="20px">
                1. 멤버십 구독 후, <b>앨범 발매를 안한</b> 경우
                <br />
                &nbsp;1) 결제일로부터 <span style={{ color: '#FF3120' }}>30일 이내</span> 중도 해지를 하는 경우, 100% 전액 환불 및
                멤버십 중도 해지
                <br />
                &nbsp;2) 결제일로부터 <span style={{ color: '#FF3120' }}>30일을 경과</span>하여 중도 해지를 하는 경우
                <br />
                &nbsp; &nbsp; (1) 정상가로 구매했다면 신청일 기준 일할 계산 후 부분 환불 및 멤버십 중도 해지
                <br />
                &nbsp; &nbsp; (2) <span style={{ color: '#FF3120' }}>할인가</span>로 구매했다면 신청일 기준{' '}
                <span style={{ color: '#FF3120' }}>정상가 기준</span>으로 일할 계산 후 부분 환불 및 멤버십 중도 해지
                <br />
                2. 멤버십 구독 후, <b>앨범 발매를 진행</b>한 경우
                <br />
                &nbsp;1) 정상가로 구매했다면 신청일 기준 일할 계산 후 부분 환불 및 멤 버십 중도 해지
                <br /> &nbsp;2) <span style={{ color: '#FF3120' }}>할인가</span>로 구매했다면 신청일 기준{' '}
                <span style={{ color: '#FF3120' }}>정상가 기준</span>으로 일할 계산 후 부분 환불 및 멤버십 중도 해지
              </Typography>
              <GOTermsBtn
                onClick={() => {
                  history.push('/terms/terms-of-pay-use');
                }}
                endIcon={<img height="10px" src="/assets/icon/next-w.svg"></img>}
              >
                유료 이용약관
              </GOTermsBtn>
              <Typography fontSize="16px" lineHeight="40px" mb="12px">
                <span style={{ marginRight: '12px', color: '#EFFD60' }}>°</span>정기결제 해지를 원하는 경우,{' '}
                <Link to="/userinfo/userinfo-setting" style={{ color: '#fff', textDecoration: 'underline' }}>
                  ‘계정설정’
                </Link>{' '}
                에서 가능합니다.
                <br />
                <span style={{ marginRight: '12px', color: '#EFFD60' }}>°</span>환불 및 중도 해지를 원하는 경우, 아래 양식에 맞춰
                mixtape@ygmail.net로 보내주세요.
              </Typography>
              <EmailFormEx>
                <Typography fontSize="16px" lineHeight="32px">
                  [멤버십 해지 메일 양식 ㅣ 가입한 이메일 주소로 보내주세요]
                  <br />
                  - mixtape. 멤버십 해지를 신청합니다. <br />- 아이디 :<br /> - 휴대폰번호 : <br />- 이름 : <br />- 해지 신청 사유
                  :
                </Typography>
              </EmailFormEx>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <MainFooter />
    </>
  );
};

export default MembershipGuide;
