import React from 'react';
import Modal from '@components/form/Modal';
import { $mixtapeGetUser } from '@utils/auth';
import { Stack } from '@mui/material';
import MixButton from '@components/ui/MixButton';
import moment from 'moment';

export type TCurrentStep = 'AUTH_NUMBER' | 'RESET' | 'COMPLETED';

type TCancelCompleteModalProps = {
  setIsOpenCancelCompleteModal: () => void;
  isOpenCancelCompleteModal: boolean;
  callback: () => void;
};

const checkStyle = {
  marginRight: '16px',
  color: '#D4D4D8',
  fontSize: '18px',
  fontWeight: 600,
};

const CancelCompleteModal = ({
  isOpenCancelCompleteModal,
  setIsOpenCancelCompleteModal,
  callback,
}: TCancelCompleteModalProps) => {
  const userInfo = $mixtapeGetUser();

  return (
    <Modal
      modalStyle={{ width: '660px' }}
      closeButtonStyle={{ width: '24px', heigth: '24px' }}
      hide={setIsOpenCancelCompleteModal}
      isShown={isOpenCancelCompleteModal}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" padding="15px" textAlign="center">
        <span style={{ fontSize: '34px', fontWeight: 600 }}>멤버십이 해지되었습니다.</span>
        <span style={{ fontSize: '18px', margin: '50px 0', lineHeight: '30px' }}>
          {userInfo.email} 주소로 확인 이메일을 보내드립니다.
          <br />
          {userInfo.membership &&
            `${moment(userInfo.membership.end_at).format('YYYY년 MM월 DD일')} 까지 믹스테이프를 이용하실 수 있습니다.`}
        </span>
        <MixButton
          style={{ marginBottom: '35px' }}
          padding="21px 109px"
          onClick={() => {
            callback();
          }}
          text="해지완료"
        />
      </Stack>
    </Modal>
  );
};

export default CancelCompleteModal;
