import AlbumStepper from '@components/molecules/AlbumManagement/AlbumStepper';
import { AlbumContext, ProfileContext } from '@containers/App';
import { BackdropContext } from '@hooks/usePopupMessage';
import { Box, ButtonBase, IconButton, Skeleton, Stack, styled, Tooltip } from '@mui/material';
import { sendHttpGet } from '@utils/api';
import date from 'date-and-time';
import React, { ChangeEvent, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import { getAlbumList } from '@api/mapper/AlbumManagement';
import HDivider from '@components/form/HDivider';
import Pagination from '@components/form/Pagination';
import Row from '@components/form/Row';
import VDivider from '@components/form/VDivider';
import { AlbumListSearchInput } from '@components/molecules/AlbumManagement/InputPrefix';
import {
	AlbumManagementAlbums,
	AlbumManagementImageInProgress,
	AlbumManagementInfoInProgress,
	AlbumManagementProgressBox,
	AlbumManagementReleased
} from '@components/molecules/AlbumManagement/Layout';
import {
	AlbumManagementReleaseDateInProgress,
	AlbumManagementReleasedSubTitle,
	AlbumManagementReleasedTitle,
	AlbumManagementSingerInProgress,
	AlbumManagementTitleInProgress,
	AlbumManagementTypeInProgress,
	AlbumManagementUCPInProgress,
	AlbumSubTitleTextInTable,
	AlbumTitleTextInTable
} from '@components/molecules/AlbumManagement/Text';

import { Icon } from '@images/Icon';
import { $mixtapeUserSeq, getUserId, getUserSeq } from '@utils/auth';

import AlbumInfoDetailModal from './AlbumInfoDetailModal';
import Table from './Table';
import Brightness1RoundedIcon from '@mui/icons-material/Brightness1Rounded';

const G_PAGE_LIMIT = 10;

function AlbumList(): ReactElement {
	// const {
	//   isShown: isOpenAlbumInfoDetailModal,
	//   toggle: setIsOpenAlbumInfoDetailModal,
	// } = useModal();
	const [isOpenAlbumInfoDetailModal, setIsOpenAlbumInfoDetailModal] = useState(false);
	const [uc_currentPage, uf_setCurrentPage] = useState(1);
	const [uc_totalPage, uf_setTotalPage] = useState(1);
	const [uc_curLastIdx, uf_setCurLastIdx] = useState(0);
	const [uc_keyword, uf_setKeyword] = useState('');

	const [dataList, setDataList] = useState<any>([]);
	const [uc_lastAlbums, uf_setLastAlbums] = useState([]);

	const { ctf_sendHttpGet } = useContext(BackdropContext);

	const rowClick = (row: any) => {
		uf_setCurAlbumId(row.original.id);
		setIsOpenAlbumInfoDetailModal(true);
	};

	useEffect(() => {
		if (isOpenAlbumInfoDetailModal) {
			if (typeof window !== 'undefined' && window.document) {
				document.body.style.overflow = 'hidden';
			}
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [isOpenAlbumInfoDetailModal]);

	///
	/// 앨범리스트를 조회한다.
	///
	async function searchMyAlbums() {
		const params = {
			limit: G_PAGE_LIMIT,
			page: uc_currentPage,
			user: $mixtapeUserSeq()
		};
		const res = await ctf_sendHttpGet('/albums', params);
		if (res.error) {
		} else {
			setDataList(res.data.data);
			uf_setTotalPage(res.data.last_page);
		}
	}

	function getDotButton(idx, curIdx) {
		const style = {
			color: idx === curIdx ? '#dddddd' : '#333',

			'&:hover': {
				color: '#effd60',
				borderColor: '#effd60'
			}
		};
		return (
			<IconButton
				key={`icon-button-${idx}`}
				size="small"
				sx={style}
				onClick={() => {
					changeCurLastAlbum(idx);
				}}
			>
				<Brightness1RoundedIcon sx={{ fontSize: 15 }} />
			</IconButton>
		);
	}

	async function searchLastAlbums() {
		const params = {
			limit: 5,
			page: 1,
			user: $mixtapeUserSeq()
		};
		const res = await ctf_sendHttpGet('/albums', params);
		if (res.error) {
		} else {
			uf_setLastAlbums(res.data.data);

			// 최신앨범 가장 최근 것을 보여주도록 세팅한다.
			if (res.data.data.length > 0) {
				uf_setLastAlbumRd(res.data.data[0]);
			}
		}
	}

	async function searchAlbumsByKeyword() {
		const params = {
			limit: G_PAGE_LIMIT,
			page: uc_currentPage,
			user: $mixtapeUserSeq(),
			keyword: uc_keyword
		};
		const res = await ctf_sendHttpGet('/albums', params);
		if (res.error) {
		} else {
			setDataList(res.data.data);
			uf_setTotalPage(res.data.last_page);
		}
	}

	const columns = useMemo(
		() => [
			{
				accessor: 'album_name',
				Header: '앨범명',
				Cell: (value: any) => {
					const { cell, row } = value;

					return (
						<Row justify="FLEX_START">
							<VDivider margin="7px" type="TRANSPARENT" />
							<Icon
								url={row.original.cover.length > 0 ? row.original.cover[0].image_url : null}
								style={{
									borderRadius: '10px',
									width: '64px',
									height: '64px'
								}}
							/>
							<VDivider margin="8px" type="TRANSPARENT" />
							<Row direction="column" justify="FLEX_START">
								<HDivider margin="3px" type="TRANSPARENT" />
								<Tooltip title={cell.value}>
									<AlbumTitleTextInTable>{cell.value}</AlbumTitleTextInTable>
								</Tooltip>
								<HDivider margin="3px" type="TRANSPARENT" />
								<AlbumSubTitleTextInTable>
									{row.original.artists.length == 0
										? '-'
										: row.original.artists
												.reduce((acc: any, cur: any) => acc + ', ' + cur.artist_name, '')
												.replace(/^,/, '')}
								</AlbumSubTitleTextInTable>
							</Row>
						</Row>
					);
				},
				style: {
					width: '40%'
				}
			},
			{
				accessor: 'type_name',
				Header: '앨범 타입',
				style: {
					width: '15%'
				}
			},
			{
				accessor: 'tracks_count',
				Header: '트랙 수',
				style: {
					width: '15%'
				}
			},
			{
				accessor: 'release_date',
				Header: '발매일',
				style: {
					width: '15%'
				},
				Cell: (val: any) => {
					return date.format(new Date(val.value), 'YYYY.MM.DD');
				}
			},
			{
				accessor: 'state',
				Header: '상태',
				style: {
					width: '15%'
				},
				Cell: (val: any) => {
					const { cell, row } = val;

					if (row.original.release_state === 1) {
						return '발매 완료';
					} else {
						switch (cell.value) {
							case 'approve_wait':
								return '발매 대기 중';

							case 'approve': // 담장자 승인 완료 후 발매일 전까지 상태
								return '검수 완료';
							case 'release': // 담장자 승인 완료 후 발매일 전까지 상태
								return '발매 완료';

							case 'reject':
								return '자료 확인 요청';

							case 'update_approve_wait':
								return '수정 검토 중';

							case 'update_reject':
								return '자료 확인 요청';

							case 'update_approve':
								return '수정 완료';

							default:
								return '수정 완료';
						}
					}
				}
			}
		],
		[]
	);

	useEffect(() => {
		searchMyAlbums();
	}, [uc_currentPage]);

	// @ts-ignore
	const { ctc_albumInfo, ctf_setAlbumInfo } = useContext(AlbumContext);

	const [uc_lastAlbumRd, uf_setLastAlbumRd] = useState({});
	useEffect(() => {
		searchLastAlbums();
	}, []);

	useEffect(() => {
		if (ctc_albumInfo.refresh === true || ctc_albumInfo.refreshDetail === true) {
			searchMyAlbums();
			searchLastAlbums();
		}
	}, [ctc_albumInfo]);

	const [uc_curAlbumId, uf_setCurAlbumId] = useState(null);

	function changeCurLastAlbum(index) {
		uf_setCurLastIdx(index);
		uf_setLastAlbumRd(uc_lastAlbums[index]);
	}

	return (
		<>
			<Stack spacing={2} sx={{ background: '#000000' }} alignItems="center">
				<AlbumManagementProgressBox>
					<AlbumManagementInfoInProgress>
						<AlbumManagementTypeInProgress>
							{uc_lastAlbumRd.type_name ?? (
								<Skeleton
									variant="text"
									sx={{
										bgcolor: '#444',
										fontSize: '14px'
									}}
									width={300}
								/>
							)}
						</AlbumManagementTypeInProgress>
						<HDivider margin="5.5px" type="TRANSPARENT" />
						<Tooltip title={uc_lastAlbumRd.album_name ?? ''}>
							<AlbumManagementTitleInProgress>
								{uc_lastAlbumRd.album_name ?? (
									<Skeleton
										variant="text"
										sx={{
											bgcolor: '#444',
											fontSize: '30px'
										}}
										width={400}
									/>
								)}
							</AlbumManagementTitleInProgress>
						</Tooltip>
						<HDivider margin="10px" type="TRANSPARENT" />

						{/* 아티스트 */}
						<AlbumManagementSingerInProgress>
							{' '}
							{uc_lastAlbumRd.artists ? (
								uc_lastAlbumRd.artists
									.reduce((acc: any, cur: any) => acc + ', ' + cur.artist_name, '')
									.replace(/^,/, '')
							) : (
								<Skeleton variant="text" sx={{ bgcolor: '#444' }} width={400} />
							)}
						</AlbumManagementSingerInProgress>
						<HDivider margin="19px" type="TRANSPARENT" />

						<AlbumManagementReleaseDateInProgress>
							{uc_lastAlbumRd.release_date ? (
								date.format(new Date(uc_lastAlbumRd.release_date), 'YYYY.MM.DD hA ([KST]) 발매 예정')
							) : (
								<Skeleton variant="text" sx={{ bgcolor: '#444' }} width={200} />
							)}
						</AlbumManagementReleaseDateInProgress>
						<HDivider margin="3px" type="TRANSPARENT" />
						{uc_lastAlbumRd.ucp && (
							<AlbumManagementUCPInProgress>UCP : {uc_lastAlbumRd.ucp}</AlbumManagementUCPInProgress>
						)}

						<HDivider margin="24px" type="TRANSPARENT" />
						<AlbumStepper record={uc_lastAlbumRd} />
					</AlbumManagementInfoInProgress>
					<VDivider margin="70px" type="TRANSPARENT" />
					<AlbumManagementImageInProgress>
						{uc_lastAlbumRd.cover ? (
							<img
								src={uc_lastAlbumRd.cover && uc_lastAlbumRd.cover.length > 0 ? uc_lastAlbumRd.cover[0].image_url : null}
								style={{
									width: '320px',
									height: '320px',
									borderRadius: '10px'
								}}
							/>
						) : (
							<Skeleton variant="rounded" sx={{ bgcolor: '#444' }} width={320} height={320} />
						)}
					</AlbumManagementImageInProgress>
				</AlbumManagementProgressBox>
				{uc_lastAlbums.length > 1 && (
					<Stack direction="row" spacing={4} pb={5}>
						{uc_lastAlbums.map((ele, idx) => {
							return getDotButton(idx, uc_curLastIdx);
						})}
					</Stack>
				)}
			</Stack>

			<AlbumManagementReleased>
				<HDivider margin="40px" type="TRANSPARENT" />
				<AlbumManagementReleasedTitle>나의 앨범</AlbumManagementReleasedTitle>
				<HDivider margin="7px" type="TRANSPARENT" />
				<AlbumManagementReleasedSubTitle>
					- mixtape.에서 발매 신청, 완료된 앨범 목록입니다. <br />- 등록 이후 수정이 필요하신 경우 발매일{' '}
					<span style={{ fontWeight: 'bold', color: '#EFFD60' }}>2주 전까지 </span>완료해 주세요. <br />- 멤버십 종료로
					서비스 중지된 앨범의 서비스 재개를 원하시는 경우, 고객센터로 문의해 주세요.
				</AlbumManagementReleasedSubTitle>
			</AlbumManagementReleased>
			<AlbumManagementAlbums>
				<HDivider margin="5px" type="TRANSPARENT" />
				<AlbumListSearchInput
					containerStyle={{
						background: '#323232',
						borderRadius: '20px',
						border: 'none',
						alignSelf: 'flex-end'
					}}
					id="albumListSearchInput"
					onChange={(e: ChangeEvent) => {
						const { value } = e.target as any;
						uf_setKeyword(value.trim());
					}}
					onKeyPress={(e) => {
						if (e.key === 'Enter' && uc_keyword != '') {
							searchAlbumsByKeyword();
						} else {
							searchMyAlbums();
						}
					}}
					placeholder="앨범명, 아티스트명 검색"
					suffix={
						<div>
							<Icon
								icon="iconSearch"
								style={{
									cursor: 'pointer',
									width: '16px',
									height: '16px'
								}}
								onClick={(e) => {
									if (uc_keyword != '') {
										searchAlbumsByKeyword();
									} else {
										searchMyAlbums();
									}
								}}
							/>
						</div>
					}
					type="text"
					value={uc_keyword}
				/>
				<HDivider margin="10px" type="TRANSPARENT" />
				<Table columns={columns} data={dataList} rowClick={rowClick} />
				<HDivider margin="15px" type="TRANSPARENT" />
				{uc_totalPage > 0 && (
					<Pagination currentPage={uc_currentPage} setCurrentPage={uf_setCurrentPage} totalPage={uc_totalPage} />
				)}
			</AlbumManagementAlbums>
			<AlbumInfoDetailModal
				isOpenAlbumInfoDetailModal={isOpenAlbumInfoDetailModal}
				setIsOpenAlbumInfoDetailModal={setIsOpenAlbumInfoDetailModal}
				albumnId={uc_curAlbumId}
			/>
		</>
	);
}

export default AlbumList;
