import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import React from 'react';

const PrivacyTable = styled.table`
  width: 100%;
  overflow-y: scroll;
  border-collapse: collapse;
  tr {
    /* border-bottom: 2px solid #cccccc; */
  }
  th,
  td {
    padding: 17px 10px;
  }
  .th {
    font-weight: 700;
    border-bottom: 2px solid #000000;
    text-align: left;
  }
`;

const TermsOfUse = () => {
  return (
    <Stack direction="column" width="100%">
      <Typography fontSize="14px" color="#CB8CFC">
        믹스테이프의 서비스 이용과 관련하여 필요한 사항을 규정합니다.
      </Typography>
      <div style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
        {`
제1장    총칙

		제1조    목적		
		본 약관은 플랫폼 이용에 있어서 회사와 이용자의 권리 및 의무 등에 관한 사항을 규정함을 목적으로 합니다.
		
		제2조    용어의 정의
		본 약관에서 사용하는 용어의 뜻은 다음과 같습니다.
		1. 플랫폼 : 주식회사 와이지플러스(이하 “회사”)가 서비스를 제공하기 위하여 운영하는 도메인 명이 “www.mixtape.so” 인 플랫폼을 의미합니다.
		2. 약관 등 : 플랫폼 이용에 관하여 회사와 이용자 간의 권리 및 의무 등을 규정하는 약관 및 방침으로서, 제3조 제1항을 따릅니다.
		3. 이용자 : 회사가 플랫폼을 통하여 제공하는 서비스를 이용하는 자를 의미합니다.
		4. 회원 : 회사와 서비스 이용계약을 체결하고 회원 아이디를 부여 받은 이용자를 의미합니다.
		5. 아이디 : 플랫폼에서 회원을 식별하기 위한 문자 또는 숫자 등의 조합을 의미합니다.
		6. 비밀번호 : 회원임을 확인하기 위한 것으로서 아이디와 합치되는 문자, 특수문자 또는 숫자 등의 조합을 의미합니다.
		
		제3조    약관 등의 구성 및 효력 범위
		① 약관 등에는 본 약관, 개인정보처리방침, 기타 회사가 필요에 의하여 제정 또는 개정하여 제4조의 절차에 따라 동의 또는 동의 간주된 각종 방침으로 구성됩니다.
		② 약관 등은 플랫폼을 이용하고자 하는 모든 이용자에게 그 효력이 발생되고 적용됩니다.
		
		제4조    약관 등의 변경
		① 회사는 「콘텐츠산업 진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」 등 관련 법령을 위반하지 않는 범위에서 약관 등을 변경할 수 있습니다.
		② 제1항에 따라 약관 등을 변경할 경우, 회사는 시행일 및 변경사유를 명시하여 개정 약관 등(현행 약관 등과의 비교표를 포함합니다)을 플랫폼 초기화면에서 아래 각 호에서 정하는 기간 및 시행일로부터 상당한 기간 동안 공지합니다. 변경 약관 등에 동의하지 아니하는 이용자는 플랫폼 이용을 중지하여야 하며, 아래 각 호의 기간이 경과된 이후에도 계속해서 플랫폼 이용을 하는 경우 변경 약관 등에 동의한 것으로 간주됩니다.
		1. 변경하고자 하는 약관 등의 내용이 중대하거나 이용자에게 불리한 경우 : 시행일로부터 30일 전부터 시행일까지
		2. 제1호에 해당하지 않는 경우 : 시행일로부터 7일 전부터 시행일까지
		③ 제1항에 따라 약관 등을 변경할 경우에 회사는 회원에게 아래 각 호에서 정하는 기한까지 변경 약관 등(현행 약관 등과의 비교표를 포함합니다) 및 변경사유를 전자우편주소로 전송하고, 동의를 받습니다.
		1. 변경하고자 하는 약관 등의 내용이 중대하거나 회원에게 불리한 경우 : 시행일로부터 30일 전까지
		2. 제1호에 해당하지 않는 경우 : 시행일로부터 7일 전까지
		④ 변경 약관 등은 제2항에 따라 동의 간주된 이용자 및 제3항에 따라 동의한 회원에게 적용됩니다. 다만, 회사가 제3항에 따라 회원에게 고지하면서 『이 기한 내에 변경 약관 등의 적용에 대해 거절의 의사를 표시하지 않으면 동의한 것으로 간주합니다』라는 사실을 명확히 표시한 경우에 그 기한까지 거절의 의사를 표시하지 않은 회원은 동의한 것으로 간주되어 변경 약관 등의 적용을 받습니다.
		⑤ 회사는 변경 약관 등의 적용을 거절한 회원에게 변경 전 약관 등을 적용합니다. 다만, 변경 전 약관 등을 적용할 수 없는 정당한 사유 또는 불가피한 사유가 있는 경우에는 그러하지 않습니다.
		⑥ 제5항 단서의 경우에 회원은 탈퇴할 수 있습니다. 이 경우에 회사는 회원에게 위약금 등을 청구하지 않습니다.
		
		제5조    약관 등의 해석 등
		① 회사는 신의성실의 원칙에 따라 공정하게 약관 등을 해석하며, 이용자에 따라 다르게 해석하지 않습니다.
		② 회사는 약관 등의 뜻이 명백하지 아니한 경우에 이용자에게 유리하게 해석합니다.
		③ 약관 등에서 정하지 아니한 사항에 대해서는 「콘텐츠산업 진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」 등 관련 법령, 개인정보처리방침, 회사가 필요에 의하여 제정 또는 개정하여 제4조의 절차에 따라 동의 또는 동의 간주된 각종 방침 및/또는 회사와 회원간 합의에서 정한 바에 따릅니다.
		
		제6조    개별 약정의 우선 등
		① 회사와 회원이 약관 등의 내용과 다르게 합의한 사항이 있을 때에는 그 합의 사항은 본 약관 등보다 우선하여 적용됩니다.
		② 제1항에 따른 개별 약정이 「콘텐츠산업 진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」 등 관련 법령의 강행규정을 위반하여 무효인 경우에 본 약관 등에서 정한 바에 따릅니다.
		
		제7조    신의성실의 원칙
		회사와 이용자는 상대방의 신의에 좇아 성실하게 약관 등 등에 따른 권리를 행사하고, 의무를 이행합니다.
		
		제2장    회원 가입 및 탈퇴 등
		
		제8조    회원가입의 신청
		① 플랫폼의 회원가입을 희망하는 이용자(이하 “회원가입신청자”)는 본 약관 등의 내용에 동의하고 플랫폼 회원의 가입을 위하여 요청되는 필수 정보를 기재한 후 “회원가입” 또는 이에 준하는 플랫폼 상의 버튼을 클릭하는 등의 방법으로 회원가입신청을 합니다.
		② 회원가입신청자는 회원가입신청 시 아래 각 호의 필수 정보를 기재하여야 합니다. 이 때, 회사는 아래 각 호의 필수 정보 외 추가 정보의 기재를 요청할 수 있으나, 회원가입신청자가 동 추가 정보를 기재하지 않았다는 이유로 회원 가입을 거절하지 않습니다.
		1. 회원의 성명
		2. 아이디 및 비밀번호
		3. 전자우편주소, 휴대전화번호
		4. 주민등록번호 또는 외국인등록번호
		5. 계좌정보
		
		제9조    회원가입신청의 승낙, 유보 또는 거절
		① 회사는 제8조에 따른 회원가입신청자의 회원가입신청에 대하여 승낙함을 원칙으로 합니다.
		② 회사는 제1항에도 불구하고 아래 각 호에 해당하는 경우에는 회원가입신청에 대하여 해당 사유가 해소될 때까지 승낙을 유보하거나, 승낙을 거절할 수 있습니다.
		1. 허위의 정보를 기재하거나 회원가입신청 시 요청된 필수 정보를 기재하지 아니한 경우
		2. 타인의 개인정보를 무단으로 이용하여 가입신청한 경우
		3. 만 19세 미만의 미성년자가 가입신청한 경우
		4. 본인의 귀책사유로 과거 회원 자격을 상실한 적이 있던 회원가입신청자가 다시 회원 가입 신청을 한 경우
		5. 회원가입신청자가 대한민국 외의 지역에 거주 중인 경우
		6. 법령을 위반하여, 또는 사회의 안녕질서 내지 미풍양속을 해할 수 있는 목적으로 회원가입신청을 한 경우
		7. 악성 프로그램 또는 버그를 이용하거나 기타 플랫폼의 취약점을 악용하는 등의 부정한 방법을 통하여 플랫폼을 이용한 적이 있거나 이용할 목적인 경우
		8. 회사의 플랫폼 운영 관련 기술상, 설비상 또는 업무상 문제가 있어 플랫폼 관련 서비스의 원활한 제공이 어려운 경우
		9. 기타 회원가입신청자의 귀책사유로 인하여 회사가 승낙을 유보하거나 승낙하지 아니하기로 결정한 경우
		③ 회사는, 제2항에 따라 회원가입신청자의 회원가입신청을 유보하거나 승낙하지 아니하기로 결정한 경우, 이를 해당 회원가입신청자에게 제12조에 따라 통지하여야 합니다.
		
		제10조  회원자격의 취득 및 등록사항의 변경
		① 회원가입의 절차는 회사가 “회원가입완료” 또는 이에 준하는 표시를 회원가입절차 중 또는 그 후 표시한 시점에 완료되며, 이로써 회원자격을 취득합니다.
		② 회원은 회원가입신청시 등록 또는 기재한 사항에 변경이 있는 경우, 상당한 기간 내 회원정보 수정 등의 방법으로 해당 변경 내용을 회사에 알려야 합니다.
		
		제11조  회원탈퇴 및 자격상실 등
		① 회원은 회사에게 언제든지 탈퇴를 요청할 수 있으며, 회사는 즉시 회원탈퇴를 처리합니다. 다만, 정당한 사유가 있는 경우에 탈퇴처리를 유보할 수 있습니다.
		② 회원이 플랫폼을 이용하면서 다음 각 호의 어느 하나에 해당하는 행위를 한 경우, 회사는 회원자격을 일시적으로 제한하거나 정지시킬 수 있습니다.
		1. 다른 이용자의 플랫폼 이용을 방해하거나 정보를 도용하는 등 플랫폼 운영 질서를 침해하는 행위
		2. 계약 및 약관 등에서 금지하는 행위
		3. 법령ㆍ선량한 풍속 기타 사회질서에 반하는 행위
		③ 회원자격이 제한 또는 정지된 적이 있는 회원이 제2항에서 정한 행위를 2회 이상 하거나 30일 이내에 회원자격 제한 또는 정지사유가 시정되지 아니한 경우에 회사는 그 회원의 자격을 상실시킬 수 있습니다.
		④ 회사가 회원자격을 상실시킨 경우 그 회원 등록을 말소합니다. 다만, 정당한 사유가 있는 경우 회원 등록의 말소를 유보할 수 있습니다.
		⑤ 회사는 제2항 또는 제3항에 따라 회원자격의 제한 또는 정지, 자격상실 등의 조치를 취한 경우에 제12조에 따라 그 회원에게 그 사실 및 사유를 지체없이 통지하고, 아래 각 호에 따른 기간 동안 소명기회를 부여합니다.
		1. 회원자격의 제한 또는 정지 등 : 1주일 이상
		2. 자격상실 : 30일 이상
		⑥ 회사는 제2항 또는 제3항에 따른 회원자격의 제한 또는 정지, 자격상실 등과 별도로 그 회원의 행위로 인해 손해를 입은 경우에 배상을 청구할 수 있습니다. 다만, 회원이 고의 또는 과실 없음을 증명한 경우에는 그러하지 않습니다.
		
		제12조  회원에 대한 통지
		① 개별 회원에 대한 통지를 하는 경우, 회사는 회원이 지정한 전자우편주소로 그 내용을 전송합니다.
		② 전체 회원에게 통지할 경우, 회사는 회원이 쉽게 알 수 있도록 플랫폼에 7일 이상 그 내용을 게시합니다. 다만, 회원의 플랫폼 이용에 관하여 중대한 영향을 미치는 사항에 대하여는 제1항에 따른 통지를 합니다.
		③ 제1항 및 제2항 단서에도 불구하고, 회원이 지정한 전자우편주소가 없는 경우 또는 회원이 지정한 전자우편주소로 통지할 수 없는 경우에 회사는 제2항 본문에서 정한 방법으로 통지할 수 있습니다. 이 경우, 회사가 회원의 휴대전화번호를 알고 있는 때에는 그 내용을 확인할 수 있는 방법을 전화 또는 문자로 안내합니다.
		
		제3장    각 당사자의 권리 및 의무
		
		제13조  회사의 관리의무
		회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령에 따라 플랫폼의 안정성 유지 및 회원의 개인정보보호 등을 위한 보안시스템을 구축하고, 운영합니다.
		
		제14조  개인정보보호
		① 회사는 개인정보보호법과 기타 관련 법령 및 회사의 개인정보처리방침에 따라 회원의 개인정보를 수집, 이용, 제공, 위탁 등 처리(이하 통칭하여 “처리”)하며 안전하게 관리합니다.
		② 회사는 필요에 따라 수시로 개인정보처리방침의 내용을 개정할 수 있으며, 이와 같은 개정에 대하여는 제3조를 준용합니다.
		
		제15조  플랫폼의 이용시간 및 일시 이용 중지 등
		① 회사는 이용자가 연중 및 24시간 플랫폼을 이용할 수 있도록 플랫폼을 운영합니다.
		② 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 플랫폼 이용을 일시적으로 중지할 수 있습니다.
		③ 제2항의 경우, 회사는 제12조에 따라 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
		④ 회사는 원활한 플랫폼 운영을 위해 필요한 경우에 정기점검을 실시할 수 있으며, 정기점검일로부터 최소 1주일 이전에 정기점검시간을 플랫폼 초기화면에 게시합니다.
		⑤ 사업종목의 전환ㆍ폐업ㆍ파산 등의 이유로 회사가 플랫폼을 운영할 수 없는 경우에 회사는 제12조에 따라 회원에게 통지합니다.
		
		제16조  정보의 제공 및 광고정보의 전송 등
		① 회사는 이용자가 플랫폼을 이용함에 있어 필요한 정보를 플랫폼에 게시하거나 비상업용 정보에 한해 회원의 전자우편주소로 전송하는 등의 방법으로 이용자에게 제공할 수 있습니다. 이 경우에 회원은 언제든지 수신을 거절할 수 있으며, 회사는 수신을 거절한 회원에게 전화ㆍ전자우편 등으로 정보를 제공하지 않습니다.
		② 회사는 수신동의한 회원에 한해 전자우편 또는 휴대전화 문자 서비스 등을 이용하여 영리 목적의 광고성 정보를 전송할 수 있습니다. 다만, 플랫폼 이용관계를 통하여 회원으로부터 직접 연락처를 수집한 회사가 그 거래가 종료한 날로부터 6개월 이내에 자신이 처리하고 회원과 거래한 것과 같은 종류의 서비스에 대한 영리목적의 광고성 정보를 전송하는 경우에는 수신 동의하지 않은 회원에게 그 정보를 전송할 수 있습니다.
		③ 제2항에도 불구하고, 오후 9시부터 그 다음 날 오전 8시까지의 시간에 영리목적의 광고성 정보를 전송하려는 회사는 회원으로부터 별도의 사전 동의를 받습니다. 다만, 전자우편으로 정보를 전송하는 경우에는 그러하지 않습니다.
		④ 제2항의 경우에 회원은 그 동의를 언제든지 철회할 수 있습니다.
		⑤ 회사는 제1항 또는 제4항에 따라 수신거절 또는 동의 철회를 하고자 하는 회원이 전화요금 등의 비용을 부담하지 않고 수신을 거절하거나 동의를 철회할 수 있도록 필요한 조치를 취합니다.
		⑥ 회사는 제1항 또는 제4항에 따라 수신을 거절하거나 동의를 철회한 회원에게 그 처리결과를 통지합니다.
		
		제17조  아이디 및 비밀번호 관리의무 등
		① 회원은 아이디와 비밀번호를 선량한 관리자의 주의의무로 관리하며, 제3자에게 이용 허락할 수 없습니다. 다만, 회사가 동의한 경우에는 그러하지 않습니다.
		② 제1항 본문을 위반한 경우에 회사는 회원에게 발생한 손해에 대하여 책임을 지지 않습니다. 다만, 회원의 손해발생에 대해 회사에게 책임이 있는 경우에 회사는 그 범위 내에서 책임을 부담합니다.
		③ 회원은 아이디 및 비밀번호가 도용되거나 제3자에 의해 사용되고 있음을 알게 된 경우에 즉시 회사에게 그 사실을 통지하고, 회사의 안내에 따라야 합니다.
		④ 제3항의 경우, 회원이 회사에게 그 사실을 통지하지 않거나 통지한 경우라도 회사의 안내에 따르지 않아 발생한 회원의 손해에 대하여 회사는 책임을 지지 않습니다. 다만, 회사에게 책임 있는 경우에는 그 범위 내에서 책임을 부담합니다.
		⑤ 회원은 도용 등을 방지하기 위하여 주기적으로 비밀번호를 변경하며, 회사는 회원에게 비밀번호의 변경을 권고할 수 있습니다.
		
		제4장    기타
		
		제18조  보증의 부인
		회사는, 약관 등 또는 관련 법령에 달리 명시되어 있지 아니한 이상, 플랫폼을 ‘있는 그대로’ 제공하며, 플랫폼 이용과 관련하여 특정 기능이나 서비스의 정확성, 안정성, 가용성 또는 이용자의 사적 필요성을 충족할 능력 및/또는 적합성에 관하여 어떠한 구체적인 약정이나 보증도 하지 아니합니다.
		
		제19조  회사의 면책
		회사 및 회사의 임직원 그리고 대리인은 아래 각 호의 경우에 대해서 플랫폼 이용의 제공 및/또는 관련 손해에 대한 책임이 면제됩니다.
		1. 전쟁, 내란, 소요사태, 천재지변 또는 이에 준하는 불가항력적 사유로 인하여 플랫폼 이용을 원활히 제공할 수 없거나 손해가 발생되는 경우
		2. 이용자의 귀책사유로 인하여 플랫폼 이용에 대하여 장애가 발생되거나 손해가 발생되는 경우
		3. 이용자가 제공한 정보의 허위 또는 부정확성에 기인하여 플랫폼 이용에 대한 장애가 발생되거나 손해가 발생되는 경우
		4. 회사의 관리 영역이 아닌 설비, 공중통신선로 또는 관련 서비스 등의 장애 등에 기인하여 플랫폼 이용에 대한 장애가 발생되거나 손해가 발생되는 경우
		5. 플랫폼 서버에 대한 제3자의 모든 불법적인 접속, 이용, 중단행위 또는 침해에 기인하여 플랫폼 이용에 대한 장애가 발생되거나 손해가 발생되는 경우
		6. 제3자가 플랫폼을 이용하여 불법적으로 전송ㆍ유포하거나, 전송ㆍ유포되도록 한 일체의 바이러스 및 기타 악성 프로그램 등에 기인하여 플랫폼 이용에 대한 장애가 발생되거나 손해가 발생되는 경우
		
		제20조  권리의 유보
		플랫폼을 이용한다고 해서 플랫폼 및/또는 그 구성 요소에 대하여 이용자에게 소유권, 지식재산권 또는 기타 권리가 부여되는 것은 아닙니다.
		
		제21조  일부무효
		약관 등의 일부 내용이 무효이거나 불법으로서 강제될 수 없는 것으로 판정되는 경우라도 해당 조항 내지 내용을 제외한 나머지 내용은 여전히 유효하며, 약관 등은 처음부터 그러한 문제되는 내용이 없었던 것처럼 해석됩니다. 단, 해당 내용이 없었다면 어느 한 당사자가 약관 등을 체결하지 않았을 것임이 명백한 경우에는 그러하지 아니 합니다.
		
		제22조  권리 포기의 부인
		회원이 약관 등을 준수하지 않았을 때 회사가 즉시 대응을 하지 않는다고 하더라도, 이는 회사가 보유하고 있거나 보유할 수 있는 권리의 포기로 해석 내지 취급되지 아니 합니다.
		
		제23조  준거법 및 재판관할
		① 약관 등의 해석ㆍ적용이나 약관 등의 적용ㆍ이행ㆍ해석ㆍ관련분쟁 등에 대하여 이용자의 거주지 관할 법령에서 달리 규정하고 있지 아니한 이상, 대한민국 법령이 그 준거법이 됩니다.
		② 플랫폼, 그 이용 및/또는 약관 등과 관련되거나 그로부터 야기된 일체의 분쟁에 대하여는 대한민국 서울의 서울중앙지방법원이 전속관할을 가집니다.
		
		부칙
		제1조 시행일
		본 약관은 2023년 3월 20일부터 적용됩니다.`}
      </div>
      {/* <Typography fontSize="14px" color="#CB8CFC" mt="40px">
        이전 이용약관 보기 (2023년 03월 01일 ~ 2024년 03월 01일)
      </Typography> */}
    </Stack>
  );
};

export default TermsOfUse;
