import Radio from '@components/form/Radio';

import S002_01 from '@containers/ArtistAddModal/S002_01';
import S002_02 from '@containers/ArtistAddModal/S002_02';

import React, { useEffect, useState } from 'react';

import Text from '@components/form/Text';
import { MacScrollbar } from 'mac-scrollbar';
import { Stack } from '@mui/material';

//@ts-ignore
function S002({ setCurStep, setIsOpenArtistAddModal, setResult, curStep }) {
  const [historyYN, setHistoryYN] = useState('Y');

  useEffect(() => {
    // if (history === 'S002') {
    //   setHistoryYN('NONE');
    // }
    // if (history === 'S002_01') {
    //   setHistoryYN('Y');
    // }
    // if (history === 'S002-02') {
    //   setHistoryYN('N');
    // }
  }, []);

  return (
    <MacScrollbar style={{ maxHeight: '800px' }}>
      <Stack direction="column" p="0px 30px 30px">
        {curStep === 'S002' ? (
          <>
            <Text style={{ marginBottom: '10px' }}>앨범 발매 이력이 있으신가요?</Text>
            <Radio
              value={historyYN}
              onChange={(e) => {
                setHistoryYN(e.target.value);
              }}
              options={[
                {
                  label: '예 (이력 있음)',
                  value: 'Y',
                },
                {
                  label: '아니오 (이력 없음)',
                  value: 'N',
                },
              ]}
            />
            {historyYN === 'Y' && <S002_01 setIsOpenArtistAddModal={setIsOpenArtistAddModal} setResult={setResult} />}
            {historyYN === 'N' && <S002_02 setIsOpenArtistAddModal={setIsOpenArtistAddModal} setResult={setResult} />}
          </>
        ) : (
          <>
            {curStep === 'S002_01' && <S002_01 setIsOpenArtistAddModal={setIsOpenArtistAddModal} setResult={setResult} />}
            {curStep === 'S002_02' && <S002_02 setIsOpenArtistAddModal={setIsOpenArtistAddModal} setResult={setResult} />}
          </>
        )}
      </Stack>
      {/* <Row style={{ width: '100%' }} direction="column" justify="FLEX_START">
        <HDivider margin="9px" type="TRANSPARENT" />
        <Text>앨범 발매 이력이 있으신가요?</Text>
        <HDivider margin="9px" type="TRANSPARENT" />

        <Radio
          value={historyYN}
          onChange={(e) => {
            setHistoryYN(e.target.value);
          }}
          options={[
            {
              label: '예 (이력 있음)',
              value: 'Y',
            },
            {
              label: '아니오 (이력 없음)',
              value: 'N',
            },
          ]}
        />
      </Row> */}
      {/* <HDivider margin="20px" type="TRANSPARENT" /> */}
      {/* <Row style={{ width: '100%' }} direction="column" justify="FLEX_START">
        {historyYN === 'Y' && <S002_01 setIsOpenArtistAddModal={setIsOpenArtistAddModal} setResult={setResult} />}
        {historyYN === 'N' && <S002_02 setIsOpenArtistAddModal={setIsOpenArtistAddModal} setResult={setResult} />}
      </Row> */}
    </MacScrollbar>
  );
}

export default S002;
