import _ from 'lodash';

export function setNestedObjects(
  dataIndex: string | number | (string | number)[],
  value: any,
  data: Record<any, any>,
): Record<any, any> {
  let keys: (string | number)[];
  if (typeof dataIndex === 'string' || typeof dataIndex === 'number') {
    keys = [_.cloneDeep(dataIndex)];
  } else {
    keys = _.cloneDeep(dataIndex);
  }
  let object: Record<any, any> = _.cloneDeep(data);

  const shiftKey = keys.shift();
  if (!object) {
    if (typeof shiftKey === 'number') {
      object = [];
    } else {
      object = {};
    }
  }
  if (shiftKey !== undefined) {
    if (keys.length === 0) {
      object[shiftKey] = value;
    } else {
      object[shiftKey] = setNestedObjects(keys, value, object[shiftKey]);
    }
  }
  return object;
}

export function getNestedObjects(dataIndex: string | number | (string | number)[], object: Record<any, any>): any {
  let keys: (string | number)[];
  if (typeof dataIndex === 'string' || typeof dataIndex === 'number') {
    keys = [dataIndex];
  } else {
    keys = dataIndex;
  }
  const value = keys.reduce((acc, key) => {
    if ((acc && acc[key]) || (acc && typeof acc[key] === 'boolean')) {
      const child = acc[key];
      return child;
    }
    return null;
  }, object);
  return value;
}

export function searchNestedObject(value: string, object: Record<string, any> | string) {
  if (!(object && value)) {
    return null;
  }
  if (typeof object !== 'object') {
    return object === value;
  }
  const keyArr: string[] = [];
  Object.keys(object).some((current) => {
    const child = object[current];
    const check = searchNestedObject(value, child);
    if (check) {
      keyArr.push(current);
      if (typeof check === 'object') {
        keyArr.push(...check);
      }
      return true;
    }
    return null;
  });

  if (keyArr.length) {
    return keyArr;
  }
  return null;
}
