import Button from '@components/form/Button';
import styled from '@emotion/styled';

export const GoLogoutButton = styled(Button)`
  height: 52px;
  border-radius: 10px;
  padding: 17px 33px;
  background: #fff;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  /* color/121212 */

  color: #121212;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  border: 1px solid #121212;
`;

export const SetActivationButton = styled(Button)`
  max-width: 190px;
  height: 52px;
  padding: 17px 52px;
  background: #121212;
  border-radius: 10px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
`;

export const RegisterButton = styled(Button)`
  width: 180px;
  height: 50px;
  padding: 17px 52px;
  background: #ffffff;
  border-radius: 10px;
  border-color: #666;
  border-style: solid;
  border-width: 1px;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;

  /* identical to box height */

  text-align: center;

  color: #121212;
`;
