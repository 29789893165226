import {
  ALBUM_INFO_REGISTRATION_ACTION,
  ALBUM_INFO_REGISTRATION_TRACK_INFO_ADD_ACTION,
  AUTH_FIND_ID_ACTION,
  AUTH_FIND_PASSWORD_ACTION,
  AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
  AUTH_LOGIN_ACTION,
  AUTH_SIGN_UP_ACTION,
} from '@constants/actionNames';
import { byteCounter, isEmpty } from '@utils/data';
import { gf_checkSSN } from '@utils/misc';
import { setNestedObjects } from '@utils/nestedObjects';
import emojiRegex from 'emoji-regex';
type TValidationCheck = {
  reduxActionName: string;
  formData: any;
};

export function validationCheck({ reduxActionName, formData }: TValidationCheck): any {
  const messages: any = {};
  const suffixStartIndex = reduxActionName.indexOf('/');
  const slicedReduxActionName = suffixStartIndex > -1 ? reduxActionName.slice(0, suffixStartIndex) : reduxActionName;
  const emoji = emojiRegex();
  switch (slicedReduxActionName) {
    case AUTH_LOGIN_ACTION:
      if (isEmpty(formData?.user_id)) {
        messages.user_id = { id: 'login.user_id' };
      }
      if (isEmpty(formData?.password)) {
        messages.password = { id: 'login.password' };
      }
      break;
    case AUTH_SIGN_UP_ACTION:
      // 정규표현식 잘못되어수정
      if (!/^[a-zA-Z0-9._-]{5,20}$/.test(formData?.user_id)) {
        messages.user_id = { id: 'signup.user_id' };
      }

      if (
        !/^.*(?=^.{8,30}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+~=\-_.\(\)\[\]\{\},<>/\\\|;:'"\?*]).*$/.test(formData?.password)
      ) {
        messages.password = { id: 'signup.password.exp' };
      }
      if (/[^a-z|A-Z|ㄱ-ㅎ|가-힣]$/.test(formData?.name) || isEmpty(formData?.name)) {
        messages.name = { id: 'signup.name' };
      }
      if (!/^[0-9]{6}$/.test(formData?.user_rrn_front)) {
        messages.user_rrn_front = { id: 'signup.user_rrn_front' };
      }
      if (!/^[0-9]{7}$/.test(formData?.user_rrn_back)) {
        messages.user_rrn_back = { id: 'signup.user_rrn_back' };
      }

      if (!gf_checkSSN(`${formData?.user_rrn_front}${formData?.user_rrn_back}`)) {
        messages.user_rrn_back = { id: 'signup.user_rrn' };
      }

      if (!gf_checkSSN(formData?.foreigner_no)) {
        messages.foreigner_no = { id: 'signup.foreigner_no' };
      }
      if (!/^(?:(010-\d{4})|(01[1|6|7|8|9]-\d{3,4}))-(\d{4})$/.test(formData?.phone_number)) {
        messages.phone_number = { id: 'signup.phone_number' };
      }
      if (!/^[0-9a-zA-Z_.-]*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(formData?.email)) {
        messages.email = { id: 'signup.email' };
      }
      if (!formData?.isExistRelease || formData?.isExistRelease === 'false') {
        if (!/.{1,100}/.test(formData?.artist_name)) {
          messages.artist_name = { id: 'signup.artist_name' };
        }

        if (emoji.test(formData?.artist_name)) {
          messages.artist_name = { id: 'signup.artist_name' };
        }
        if (!/.{1,250}/.test(formData?.artist_name_foriegn)) {
          messages.artist_name_foriegn = { id: 'signup.artist_name_foriegn' };
        }
        if (emoji.test(formData?.artist_name_foriegn)) {
          messages.artist_name_foriegn = { id: 'signup.artist_name_foriegn' };
        }
        if (isEmpty(formData?.type_id)) {
          messages.type_id = { id: 'signup.type_id' };
        }
        if (isEmpty(formData?.gender_id)) {
          messages.gender_id = { id: 'signup.gender_id' };
        }
        if (isEmpty(formData?.genres)) {
          messages.genres = { id: 'signup.genres' };
        }
        if (isEmpty(formData?.roles)) {
          messages.roles = { id: 'signup.roles' };
        }
        if (isEmpty(formData?.bank_id)) {
          messages.bank_id = { id: 'signup.bank_id' };
        }
        if (!/^[0-9]{1,50}$/.test(formData?.account_number)) {
          messages.account_number = { id: 'signup.account_number' };
        }
        if (formData?.name !== formData?.account_holder) {
          messages.account_holder = { id: 'signup.account_holder_not_same' };
        } else if (/[^a-z|A-Z|ㄱ-ㅎ|가-힣]$/.test(formData?.account_holder)) {
          messages.account_holder = { id: 'signup.account_holder' };
        }
      }
      break;
    case AUTH_FIND_ID_ACTION:
      if (isEmpty(formData?.name)) {
        messages.name = { id: 'findId.name' };
      }
      if (isEmpty(formData?.phone_num)) {
        messages.phone_num = { id: 'findId.phone_num' };
      }
      break;
    case AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION:
      if (isEmpty(formData?.user_id)) {
        messages.user_id = { id: 'findPassword.isExistId.user_id' };
      }
      break;
    case AUTH_FIND_PASSWORD_ACTION:
      if (
        !/^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+~=\-_.\(\)\[\]\{\},<>/\\\|;:'"\?*]).*$/.test(formData?.password)
      ) {
        messages.password = { id: 'signup.password.exp' };
      }
      break;
    // 앨범등록시 체크
    case ALBUM_INFO_REGISTRATION_ACTION:
      if (byteCounter(formData?.album_name) > 250 || isEmpty(formData?.album_name)) {
        messages.album_name = { id: 'albuminfo.album_name' };
      }
      if (byteCounter(formData?.album_name_foriegn) > 250 || isEmpty(formData?.album_name_foriegn)) {
        messages.album_name_foriegn = { id: 'albuminfo.album_name_foriegn' };
      }

      if (emoji.test(formData?.album_name)) {
        messages.album_name = { id: 'albuminfo.album_name' };
      }

      if (emoji.test(formData?.album_name_foriegn)) {
        messages.album_name_foriegn = { id: 'albuminfo.album_name_foriegn' };
      }

      if (formData?.artist?.length <= 0) {
        messages.artist = { id: 'albuminfo.artist' };
      }
      if (isEmpty(formData?.genre_id)) {
        messages.genre_id = { id: 'albuminfo.genre_id' };
      }
      if (isEmpty(formData?.type_id)) {
        messages.type_id = { id: 'albuminfo.type_id' };
      }
      if (formData?.agency_name?.length > 300 || isEmpty(formData?.agency_name)) {
        messages.agency_name = { id: 'albuminfo.agency_name' };
      }
      if (isEmpty(formData?.description)) {
        messages.description = { id: 'albuminfo.description' };
      }
      if (formData?.album_cover?.length <= 0) {
        messages.album_cover = { id: 'albuminfo.album_cover' };
      }
      break;
    case ALBUM_INFO_REGISTRATION_TRACK_INFO_ADD_ACTION:
      if (byteCounter(formData?.track_name) > 250 || isEmpty(formData?.track_name)) {
        messages.track_name = { id: 'trackinfo.track_name' };
      }
      if (byteCounter(formData?.track_name_foriegn) > 250 || isEmpty(formData?.track_name_foriegn)) {
        messages.track_name_foriegn = { id: 'trackinfo.track_name_foriegn' };
      }
      if (formData?.track_artist?.length <= 0) {
        messages.track_artist = { id: 'trackinfo.track_artist' };
      }
      if (
        (formData?.track_artist?.length > 0 &&
          formData?.track_artist?.filter((artist: any) => artist.role_id === 90).length === 0) ||
        formData?.track_artist?.filter(
          (artist: any) => artist.role_id === 617 || artist.role_id === 618 || artist.role_id === 619 || artist.role_id === 620,
        ).length === 0
      ) {
        messages.track_artist = { id: 'trackinfo.track_artist' };
      }
      if (isEmpty(formData?.track_genre_id)) {
        messages.track_genre_id = { id: 'trackinfo.track_genre_id' };
      }
      if (isEmpty(formData?.language_id)) {
        messages.language_id = { id: 'trackinfo.language_id' };
      }

      if (formData?.is_remake === 'true' && !formData?.remakeFiles?.length) {
        messages.is_remake = { id: 'trackinfo.is_remake' };
      }
      if (formData?.is_owner === 'true' && !formData?.ownerFiles?.length) {
        messages.is_owner = { id: 'trackinfo.is_owner' };
      }
      break;
    default:
      break;
  }

  return messages;
}

export function constructValidation(reduxActionName: string) {
  return (name: string | number | (string | number)[], value: any, formData: any): Record<string, any> =>
    validationCheck({ reduxActionName, formData: setNestedObjects(name, value, formData) });
}
