/* eslint-disable no-nested-ternary */
import React from 'react';
import RSelect from 'react-select';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

const StyledSelect = styled(RSelect)`
  &.Select__control {
    min-height: 56px !important;
    width: 100%;
    border: 1px solid #a1a1a1;
    border-radius: 0;
    cursor: pointer;
  }

  &.Select__control:hover {
    border-color: #a1a1a1;
  }

  &.Select__control--is-focused {
    box-shadow: 0 0 0 1px black;
    outline: none;
  }

  &.Select__indicator-separator {
    display: none;
  }

  &.Select__menu {
    color: #3c3d3e;
  }
`;

const colourStyles = (controlStyle?: any) => ({
  control: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    backgroundColor: 'white',
    height: '50px',
    borderRadius: '10px',
    paddingLeft: '0',
    border: '1px solid #dcdcdc !important',
    boxShadow: isFocused ? 0 : 0,
    '&:hover': {
      border: isFocused ? 0 : 0,
    },
    minWidth: '158px',
    ...controlStyle,
  }),

  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
    ...styles,
    backgroundColor: isSelected ? data.color : isFocused ? '#CDCDCD' : undefined,
    color: '#3B3B3B',
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontSize: '14px',
    ':active': {
      ...styles[':active'],
      backgroundColor: '#CDCDCD',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '50px',
    whiteSpace: 'nowrap',
  }),
  input: (styles: any) => ({ ...styles, color: '#3B3B3B', fontSize: '14px', paddingLeft: '9px' }),
  placeholder: (styles: any) => ({ ...styles, color: '#CDCDCD', fontSize: '14px', paddingLeft: '9px' }),
  singleValue: (styles: any, { data }: any) => ({ ...styles, paddingLeft: '9px', fontSize: '14px' }),
});
const Select = ({ children, ...rest }: any) => (
  <StyledSelect
    components={{
      IndicatorSeparator: () => null,
    }}
    maxMenuHeight={150}
    styles={colourStyles(rest.controlStyle)}
    {...rest}
  >
    {children}
  </StyledSelect>
);

export default Select;
