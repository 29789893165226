import { getApiServerURL } from '@config/env';
import { BackdropContext, showBackdrop, useBackdrop } from '@hooks/usePopupMessage';
import { $mixtapeUserSeq, getAccessToken, getUserSeq } from '@utils/auth';
import axios from 'axios';
import qs from 'query-string';
import { useContext } from 'react';

import { encodeObj } from './data';

axios.defaults.paramsSerializer = (params: any) => {
  const encodedParams = encodeObj(params);
  return qs.stringify(encodedParams);
};

const apiServerURL = getApiServerURL();

export function getDefaultHeaders() {

  const auth = getAccessToken();
  if (!auth) {
    return {};
  }
  return {
    Authorization: `Bearer ${auth}`,
  };
}

type TMakeApiRequestParams = {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  headers?: any;
  params?: any;
  data?: any;
  responseType?: any;
};

async function makeApiRequest({
                                url,
                                method,
                                headers,
                                params,
                                data,
                                responseType,
                              }: TMakeApiRequestParams) {
  // alert(`${apiServerURL}${url}`)
  const requestObj: TMakeApiRequestParams = {
    url: `${apiServerURL}${url}`,
    method,
    headers: getDefaultHeaders(),
  };
  if (headers) {
    requestObj.headers = { ...requestObj.headers, ...headers };
  }
  if (params) {
    requestObj.params = params;
  }
  if (data) {
    requestObj.data = data;
  }
  if (responseType) {
    requestObj.responseType = responseType;
  }
  const result = await axios(requestObj);

  return result;
}

type TApiRequestParams = {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  headers?: any;
  params?: any;
  data?: any;
  responseType?: string;
  callbackFunc?: any;
  callbackParams?: any;
};

export async function apiRequest({
                                   url,
                                   method,
                                   headers,
                                   params,
                                   data,
                                   responseType,
                                 }: TApiRequestParams) {
  const result = await makeApiRequest({
    url,
    method,
    headers,
    params,
    data,
    responseType,
  });
  const response = {
    headers: result.headers,
    data: typeof result.data !== 'object' ? { response: result.data } : result.data,
  };

  return response;
}

export async function requestViveApi(keyword: String) {
  const url = `/vibeWeb/musicapiweb/v4/search/artist?query=${keyword}&start=1&display=100&sort=RELEVANCE`;
  // @ts-ignore
  const result = await axios.get(url);
  return result;
}

function showloading() {
  let layer = document.getElementById('freezing');
  if (!layer) {
    return;
  }
  layer.style.display = 'block';
}

function hideloading() {
  let layer = document.getElementById('freezing');
  if (!layer) {
    return;
  }
  layer.style.display = 'none';
}

export async function sendHttpPost(url: any, params: any) {
  showloading();
  try {
    const result = await apiRequest({
      url,
      method: 'POST',
      data:params,
    });
    hideloading();
    return result;
  } catch (ex) {

    hideloading();
    return {error:true, errorMsg:ex, data:null };
  }

}


export async function sendHttpPut(url: any, params: any) {
  showloading();
  try {
    const result = await apiRequest({
      url,
      method: 'PUT',
      data:params,
    });
    hideloading();
    return result;
  } catch (ex) {

    hideloading();
    return {error:true, errorMsg:ex, data:null };
  }

}

export async function sendHttpGet(url: any, params: any, freezing: boolean = true) {


  if (freezing) showloading();
  try {
    const result = await apiRequest({
      url,
      method: 'GET',
      params,
    });
    if (freezing) hideloading();
    return result;
  } catch (ex) {

    if (freezing) hideloading();
    return {error:true, errorMsg:ex, data:null };
  }

}


export async function callProfileData(){
  const userseq = $mixtapeUserSeq()
  const res = await sendHttpGet(`/profile/${userseq}`, { }, false);

  if(res.error){
    return []
  }else{
    if(res.data && res.data.length > 0){
      // @ts-ignore
      const arr = res.data.filter(profile=>profile.artists.is_deleted === 0).map( ele => {
        return {...ele.artists}
      })

      return arr
    }else{
      return []
    }


  }



}

