import React, { HTMLAttributes, ReactElement, useEffect } from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

type THandleCheckLayoutTypeParams = 'HEADER' | 'FOOTER' | 'FULL' | 'MAIN_HEADER' | undefined;

export interface ILayoutParams extends HTMLAttributes<HTMLDivElement> {
  layoutType?: THandleCheckLayoutTypeParams;
}

const handleCheckLayoutType = (type: THandleCheckLayoutTypeParams) => {
  switch (type) {
    case 'HEADER':
      return css`
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 90px;
        background: #121212;
        z-index: 99;
      `;
    case 'MAIN_HEADER':
      return css`
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 90px;
        background: #121212;
        z-index: 99;
        justify-content: space-between;
        flex-direction: row;
        padding-left: 60px;
      `;
    case 'FOOTER':
      return css`
        height: 370px;
        background: #121212;
        z-index: 99;
        color: #fff;
      `;
    case 'FULL':
      return css``;
    default:
      return css``;
  }
};

const StyledLayout = styled.div<ILayoutParams>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ layoutType }) => handleCheckLayoutType(layoutType)}
`;

const Layout = ({ ...rest }: ILayoutParams): ReactElement =>{

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return <StyledLayout {...rest} />;
}

export default Layout;
