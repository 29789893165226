import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getActionCreators from '@/core/redux/getActionCreators';
import { useInjectResourceControllerReducer } from '@/core/redux/getReducer';
import getSelectors from '@/core/redux/getSelectors';
import { constructValidation, validationCheck } from '@/core/validation/validation';
import { createStructuredSelector } from 'reselect';

export type TUseReduxForm = {
  formData: any;
  formFieldsError: any;
  validation: any;
  setFormFieldsError: any;
  setFormData: any;
  checkFields: any;
  handleSubmit: any;
  clearData: any;
};

export function useReduxForm(reduxActionName: string, request: (data: any) => any): TUseReduxForm {
  const { makeSelectParameters } = getSelectors(reduxActionName);
  useInjectResourceControllerReducer(reduxActionName);
  const { paramsForRequest = {} } = useSelector(
    createStructuredSelector({
      paramsForRequest: makeSelectParameters,
    }),
  );

  const { setParameters, clearData } = getActionCreators(reduxActionName);
  const validation = constructValidation(reduxActionName);
  const [formFieldsError, setFormFieldsError] = useState<Record<any, any>>();
  const dispatch = useDispatch();

  function setFormData(name: string, value: any) {
    dispatch(setParameters({ [name]: value }));
  }

  function setFormDataObject(obj: Record<any, any>) {
    dispatch(setParameters(obj));
  }

  function checkFields() {
    const formError = validationCheck({ reduxActionName, formData: paramsForRequest });
    setFormFieldsError(formError);
    return formError;
  }

  async function handleSubmit(additionalParams?: Record<string, any>) {
    const formError = checkFields();
      if (additionalParams) {
        request({ ...(paramsForRequest as Record<string, any>), ...additionalParams });
      } else {
        request(paramsForRequest);
      }
  }

  return {
    formData: paramsForRequest,
    formFieldsError,
    setFormFieldsError,
    validation: (name: string | number | (string | number)[], value: any, _formData: any) => validation(name, value, _formData),
    setFormData: (param1: string | Record<any, any> | any[], value?: any) => {
      if (typeof param1 === 'string') setFormData(param1, value);
      if (typeof param1 === 'object') setFormDataObject(param1);
    },
    checkFields: () => checkFields(),
    handleSubmit: (additionalParams?: any) => handleSubmit(additionalParams),
    clearData: () => {
      dispatch(clearData());
    },
  };
}
