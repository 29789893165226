import AlertModal from '@components/form/AlertModal';
import HDivider from '@components/form/HDivider';
import Row from '@components/form/Row';
import VDivider from '@components/form/VDivider';
import { CancelButton, ConfirmButton } from '@components/molecules/FindId/Button';
import { useModal } from '@hooks/useModal';
import { Dialog, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import Text from '@components/form/Text';
import Modal, { Content } from '@components/form/Modal';
import ReactDOM from 'react-dom';

// @ts-ignore
function AlertDialog({
  isPopupOpen,
  hidePopup,
  alertText,
  okButton = true,
  cancelButton = false,
  onOkButtonClick = null,
  onCancelButtonClick = null,
}) {
  const handleOkClick = () => {
    if (onOkButtonClick !== null) {
      onOkButtonClick();
    }
    hidePopup();
  };

  const handleCancelClick = () => {
    if (onCancelButtonClick !== null) {
      onCancelButtonClick();
    }
    hidePopup();
  };

  return (
    <Dialog
      fullWidth={true}
      open={isPopupOpen}
      onClose={() => {
        hidePopup(false);
      }}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px',
        },
      }}
    >
      <DialogTitle>Notice</DialogTitle>
      <Row
        key="AM00001"
        justify="CENTER"
        direction="column"
        style={{
          padding: '40px 0px 0 0px ',
          width: '600px',
          minHeight: '200px',
          alignItems: 'center',
          fontSize: '16px',
        }}
      >
        {alertText.split('\n').map((ele) => (
          <Text key="AM00003">{ele}</Text>
        ))}

        {(okButton || cancelButton) && (
          <Row justify="CENTER" key="AM00002" style={{ gap: '15px' }}>
            {cancelButton && <CancelButton onClick={handleCancelClick}>취소</CancelButton>}
            {okButton && <ConfirmButton onClick={handleOkClick}>확인</ConfirmButton>}
          </Row>
        )}
      </Row>
    </Dialog>
  );
}

export default AlertDialog;
