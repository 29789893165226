import React from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Modal from '@components/form/Modal';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { GoLogoutButton, SetActivationButton } from '@components/molecules/DormantAccount/Button';
import { routePath } from '@constants/path';
import history from '@utils/history';

type TChangePasswordModalProps = {
  setIsOpenChangePasswordModal: any;
  isOpenChangePasswordModal: boolean;
  setIsOpenFindPasswordModal: any;
};

function ChangePasswordModal({
  setIsOpenChangePasswordModal,
  isOpenChangePasswordModal,
  setIsOpenFindPasswordModal,
}: TChangePasswordModalProps) {
  const goNextTime = (e: any) => {
    e.preventDefault();
    const redirectUri = routePath.login.path;
    setIsOpenChangePasswordModal(false);
    history.push(redirectUri);
  };

  const setNewPassword = (e: any) => {
    e.preventDefault();
    const redirectUri = routePath.login.path;
    setIsOpenChangePasswordModal(false);
    setIsOpenFindPasswordModal(true);
    history.push(redirectUri);
  };

  return (
    <Modal headerText="아이디 찾기" hide={setIsOpenChangePasswordModal} isShown={isOpenChangePasswordModal}>
      <Layout>
        <Form style={{ width: '100%' }}>
          <HDivider margin="27px" type="TRANSPARENT" />
          <Text textType="DESCRIPTION">
            회원님의 개인정보 보호를 위해 3개월마다 비밀번호 변경 안내를 시행 하고 있습니다. 안전한 서비스 이용을 위해 비밀번호
            변경을 권고 드립니다.
          </Text>
          <HDivider margin="15px" type="TRANSPARENT" />
          <Text textType="DESCRIPTION">지금 변경 하시겠습니까?</Text>
          <HDivider margin="8px" type="TRANSPARENT" />
          <Row justify="SPACE_BETWEEN">
            <GoLogoutButton onClick={goNextTime} style={{ flex: 1 }}>
              다음에 하기
            </GoLogoutButton>
            <VDivider margin="5px" type="TRANSPARENT" />
            <SetActivationButton onClick={setNewPassword} style={{ minWidth: '235px' }}>
              비밀번호 재설정
            </SetActivationButton>
          </Row>
        </Form>
      </Layout>
    </Modal>
  );
}

export default ChangePasswordModal;
