import AlertModal from '@components/form/AlertModal';
import AuthHeader from '@components/molecules/AuthHeader';
import MainFooter from '@components/molecules/MainFooter';
import { routePath } from '@constants/path';
import BankAuthForm from '@containers/Payment/BankAuthForm';
import RegidentNoForm from '@containers/Payment/RegidentNoForm';
import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';
import { Stack } from '@mui/material';
import { $mixtapeGetUser, $mixtapeSetUser, $mixtapeUserSeq } from '@utils/auth';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import Text from '@components/form/Text';

import { SignUpMembershipButton } from '@components/molecules/SignUp/Button';
import { SignUpLayout } from '@components/molecules/SignUp/Layout';

import history from '@utils/history';

import date from 'date-and-time';

import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import MainHeader from '@components/molecules/MainHeader';

function MembershipCompleted() {
  let userInfo = $mixtapeGetUser();

  // 일반경고창용
  const { openState: uc_open, setOpenState: uf_setOpen, message: uc_message, popupMessage: uf_popupMessage } = usePopupMessage();

  // 회원가입후 비로그인 상태에서
  // 띄우는 안내 알러트창
  const {
    openState: uc_confirmOpen,
    setOpenState: uf_setConfirmOpen,
    message: uc_confirmMessage,
    popupMessage: uf_confirmPopupMessage,
  } = usePopupMessage();

  // const now = new Date();
  // const nextPayDate = date.format(date.addYears(now, 1), 'YYYY년 MM월 DD일');

  const nextPayDate = moment(userInfo.membership_at).format('YYYY년 MM월 DD일');

  const { ctf_sendHttpGet, ctf_sendHttpPatch } = useContext(BackdropContext);

  const goAlbumMenu = async () => {
    const userSeq = retrieveUserSeq();
    const res = await ctf_sendHttpGet(`/users/${userSeq}`);

    if (!res.error) {
      $mixtapeSetUser(res.data);
      // saveLocalStorage('userInfo', res.data);
    }
    history.push(routePath.album.albumManagement.path);
  };

  const retrieveUserSeq = () => {
    let seq = $mixtapeUserSeq();
    if (!seq) {
      seq = userInfo.seq;
    }

    return seq;
  };

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      authChecked: false,
      regiNoChecked: false,
      account_holder: userInfo.name,
      isForeigner: userInfo.foreigner_state,
    },
  });

  //const watchAllFields = methods.watch();
  const [bank, account_number, account_holder, user_rrn_front] = methods.watch([
    'bank',
    'account_number',
    'account_holder',
    'user_rrn_front',
  ]);
  const [authChecked, user_rrn_back, forno_front, forno_back, isForeigner, regiNoChecked] = methods.watch([
    'authChecked',
    'user_rrn_back',
    'forno_front',
    'forno_back',
    'isForeigner',
    'regiNoChecked',
  ]);

  const [uc_startButtonDisabled, uf_setStartButtonDisabled] = useState(false);
  useEffect(() => {
    const disabled =
      !regiNoChecked || !authChecked || !bank || !account_number || !account_holder || !user_rrn_front || !user_rrn_back;
    uf_setStartButtonDisabled(disabled);
  }, [bank, account_number, account_holder, user_rrn_front, authChecked, user_rrn_back, forno_front, forno_back]);

  ///
  /// 시작하기 버튼을 누르면 주민번호 등의 정보를 저장한다.
  ///
  const startMixtape = (e) => {
    e.preventDefault();
    callUpdateUser();
  };

  const callUpdateUser = async () => {
    const val = methods.getValues();
    const userSeq = retrieveUserSeq();
    const regiNo = `${val.user_rrn_front}-${val.user_rrn_back}`;
    const params = {
      user_rrn: regiNo,
      account_holder: val.account_holder,
      account_number: val.account_number,
      bank_id: val.bank.id,
    };
    const res = ctf_sendHttpPatch(`/user/${userSeq}`, params);
    if (!res.error) {
      goAlbumMenu();
    } else {
      uf_confirmPopupMessage('모두 완료되었습니다.\n로그인 페이지로 이동합니다. ');
    }
  };
  // 회원가입후 멤버십 완료되었을 경우,
  // 로그인페이지 이동 안내창에서 확인 버튼을 누르면 호출되는 함수
  const doConfirmOk = () => {
    history.push(routePath.login.path);
  };

  // @ts-ignore
  return (
    <>
      <MainHeader logo="logoW" />
      {/* <AuthHeader logo="logoW" /> */}
      <SignUpLayout>
        <FormProvider {...methods}>
          <form>
            <Stack justifyContent="center" alignItems="center" spacing={12} sx={{ width: '600px' }}>
              <Stack justifyContent="center" alignItems="center" spacing={6} sx={{ width: '600px' }}>
                <Text textType="TITLE">멤버십 가입 완료</Text>
                <p
                  style={{
                    fontSize: '16px',
                    textAlign: 'center',
                    lineHeight: 1.6,
                  }}
                >
                  mixtape. 멤버십이 시작되었습니다.
                  <br />
                  다음 결제일은 {nextPayDate}입니다.
                  <br />
                  <br />
                  음원 수익 지급을 위해 주민등록번호와 계좌번호를 입력해주세요.
                </p>
              </Stack>
              {/* 주민등록번호 / 외국인 등록번호 */}
              <RegidentNoForm />

              <BankAuthForm />
              <SignUpMembershipButton disabled={uc_startButtonDisabled} onClick={startMixtape}>
                mixtape. 시작하기
              </SignUpMembershipButton>
            </Stack>
          </form>
        </FormProvider>
      </SignUpLayout>
      ;
      <MainFooter />
      <AlertModal isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />
      <AlertModal
        isPopupOpen={uc_confirmOpen}
        alertText={uc_confirmMessage}
        hidePopup={() => uf_setConfirmOpen(false)}
        onOkButtonClick={doConfirmOk}
      />
    </>
  );
}

export default MembershipCompleted;
