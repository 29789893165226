import { createContext, useContext, useState } from 'react';



export const usePopupMessage = () => {
  const [openState, setOpenState] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('NO MESSAGE')

  const popupMessage = (txt:string) => {
    setMessage(txt)
    setOpenState(true)
  }



  return {
    openState, setOpenState,
    message, popupMessage
  }
}

// 백드롭 컨텍스트
export const BackdropContext = createContext({});

export const useBackdrop = () =>{
  const [ctc_backdropOpen, ctf_setBackdropOpen] = useState(false);
  const [ctc_loadingText, ctf_setLoadingText] = useState('');
  function ctf_showBackdrop(open: boolean, txt: any='') {
    if (txt) {
      ctf_setLoadingText(txt);
    }
    if(!open){
      ctf_setLoadingText('');
    }
    ctf_setBackdropOpen(open);

  }

  return {ctc_backdropOpen, ctf_setBackdropOpen, ctc_loadingText, ctf_setLoadingText, ctf_showBackdrop}
}

export function showBackdrop(open,txt){
  const {ctf_showBackdrop} = useContext(BackdropContext)
  ctf_showBackdrop(open, txt)
}
