import MainFooter from '@components/molecules/MainFooter';
import Login from '@containers/Login/Login';
import Membership from '@containers/Login/Membership';
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import AuthHeader from '@components/molecules/AuthHeader';
import { routePath } from '@constants/path';
import { $mixtapeGetUser, getAccessToken, getAuthInSessionStorage, loadLocalStorage } from '@utils/auth';
import history from '@utils/history';
import MainHeader from '@components/molecules/MainHeader';

const FORM_VALIDATION_FIELD_COUNT = 2;

///
/// 로그인 처리전 페이지
/// 로그인 정보를 파악해서 조건에 따라 해당 페이지로 보낸다.
///
function LoginFacade(): ReactElement {
  const [currentStep, setCurrentStep] = useState('LOGIN');

  useEffect(() => {
    const auth = getAccessToken();

    if (auth) {
      const userInfo = $mixtapeGetUser();
      // 로그인 하고 나서 멤버십이 없으면 멤버십 등록 페이지로
      // 멤버십은 있는데 주민번호 계좌정보 없으면 계좌정보 등록페이지로
      const membershipCompleted = !!userInfo.account_holder && !!userInfo.account_number && !!userInfo.user_rrn;

      if (userInfo.is_membership === 0) {
        setCurrentStep('MEMBERSHIP');
      } else {
        if (membershipCompleted === true) {
          history.push(routePath.album.albumManagement.path);
        } else {
          // 멤버쉽에서 필수정보 미등록 상태이면 가입완료 페이지로...
          history.push(routePath.paymentCompleted.path);
        }
      }
    }
  }, []);

  const handleShowCurrentComponent = () => {
    if (currentStep === 'LOGIN') {
      return (
        <>
          <AuthHeader logo="logoW" />
          <Login setCurrentStep={setCurrentStep} />
        </>
      );
    }
    if (currentStep === 'MEMBERSHIP') {
      // @ts-ignore
      return (
        <>
          <MainHeader logo="logoW" />
          <Membership setCurrentStep={setCurrentStep} />
        </>
      );
    }

    return (
      <>
        <AuthHeader logo="logoW" />
        <Login setCurrentStep={setCurrentStep} />
      </>
    );
  };

  return (
    <>
      {handleShowCurrentComponent()}
      <MainFooter />
    </>
  );
}

export default LoginFacade;
