import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import { AUTH_FIND_ID_ACTION, AUTH_FIND_ID_GET_NUMBER_ACTION } from '@constants/actionNames';
import { useReduxData } from '@hooks/useReduxData';

import AuthNumberCertification from './AuthNumberCertification';
import CantFoundId from './CantFoundId';
import FoundId from './FoundId';

export type TCurrentStep = 'INIT' | 'FOUND' | 'CANTFOUND';

type TFindIdModalProps = {
  setIsOpenFindIdModal: () => void;
  setIsOpenFindPasswordModal: () => void;
  isOpenFindIdModal: boolean;
};

function FindIdModal({ setIsOpenFindIdModal, setIsOpenFindPasswordModal, isOpenFindIdModal }: TFindIdModalProps) {
  const [isShowAuthNumberInput, setIsShowAuthNumberInput] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('INIT');
  const { clearData: clearFindIdData } = useReduxData(AUTH_FIND_ID_ACTION);
  const { clearData: clearFindIdGetAuthNumberData } = useReduxData(AUTH_FIND_ID_GET_NUMBER_ACTION);

  useEffect(() => {
    if (!isOpenFindIdModal) {
      clearFindIdData();
      clearFindIdGetAuthNumberData();
      setCurrentStep('INIT');
      setIsShowAuthNumberInput(false);
    }
  }, [isOpenFindIdModal]);

  return (
    <Modal headerText="아이디 찾기" hide={setIsOpenFindIdModal} isShown={isOpenFindIdModal}>
      <>
        {currentStep === 'INIT' && (
          <AuthNumberCertification
            isShowAuthNumberInput={isShowAuthNumberInput}
            setCurrentStep={setCurrentStep}
            setIsShowAuthNumberInput={setIsShowAuthNumberInput}
          />
        )}
        {currentStep === 'FOUND' && (
          <FoundId
            setCurrentStep={setCurrentStep}
            setIsOpenFindIdModal={setIsOpenFindIdModal}
            setIsOpenFindPasswordModal={setIsOpenFindPasswordModal}
          />
        )}
        {currentStep === 'CANTFOUND' && (
          <CantFoundId setCurrentStep={setCurrentStep} setIsOpenFindIdModal={setIsOpenFindIdModal} />
        )}
      </>
    </Modal>
  );
}

export default FindIdModal;
