import { DEFAULT_TABLE_PAGE, DEFAULT_TABLE_SIZE } from '@constants/table';
import { apiRequest, sendHttpPost } from '@utils/api';
import { replaceAll } from '@utils/data';

export const getAlbumList = async (params: any) => {
	const { page = DEFAULT_TABLE_PAGE, limit = DEFAULT_TABLE_SIZE, user, orderBy } = params;
	const reqData: any = {
		page,
		limit,
		user
	};

	if (orderBy) {
		reqData.orderBy = orderBy;
	}

	const result = await apiRequest({
		url: `/albums`,
		method: 'GET',
		params: reqData
	});

	return result;
};

export const addMusicVideo = async (params: any) => {
	const result = await apiRequest({
		url: `/album/musicVideo`,
		method: 'POST',
		params
	});

	return result;
};

/// 앨범등록
export const addAlbumInfo = async (params: any) => {
	const result = await apiRequest({
		url: `/albums`,
		method: 'POST',
		data: params
	});

	return result;
};

export const getAlbumSchedules = async () => {
	const res: Record<string, any> = await apiRequest({
		url: `/schedules`,
		method: 'GET'
	});

	const data = res?.data
		? Object.keys(res?.data)?.reduce((acc: any, cur: any) => {
				const key = cur?.split('-')?.join('');
				acc[key] = res?.data[cur];
				return acc;
			}, {})
		: {};

	return {
		...res,
		data
	};
};

// get dsp
export const getDspList = async (params: any) => {
	const result = await apiRequest({
		url: `/dsps`,
		method: 'GET'
	});
	return result;
};
