import Text from '@components/form/Text';
import styled from '@emotion/styled';

export const ProfileMgmtSingerTitleText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  /* identical to box height, or 100% */

  /* color/fff */

  color: #ffffff;
`;

export const ProfileMgmtReleaseDateText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* color/ddd */

  color: #dddddd;
`;

export const ProfileMgmtDescTitle = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  /* color/fff */

  color: #ffffff;
`;

export const ProfileMgmtDescText = styled.p`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;

  /* or 150% */

  /* color/fff */

  color: #ffffff;
`;

export const ProfileListTitle = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  /* color/#e8e8e8 */

  color: #e8e8e8;
`;
