// import Button from '@components/form/Button';
import { StyledButton } from '@components/form/StyledButton';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';

export const ReleaseAlbumButton = styled(StyledButton)`
  width: auto;
  padding-left: 202px;
  padding-right: 202px;
  height: 64px;
  background: #effd60;
  border-radius: 10px;

  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #121212;

  &:hover {
    background: #eafc88;
  }
`;

export const SignUpMembershipButton = styled(StyledButton)`
  width: 100%;
  height: 64px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: #effd60;
  color: #121212;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;

export const AlbumDetailActionButton = styled(StyledButton)`
  border: 1px solid #effd60;
  border-radius: 10px;


  text-align: center;

  /* color/primary/lemon */

  color: #effd60;
  width: 100%;

  &:disabled {
    color: rgba(239, 253, 96, 0.32);
    border: 1px solid rgba(239, 253, 96, 0.32);
    background: rgba(255, 255, 255, 0.1);
    cursor: not-allowed !important;
  }
`;
