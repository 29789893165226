import React, { Dispatch, SetStateAction, useCallback } from 'react';

import Checkbox from '@components/form/Checkbox';
import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Layout from '@components/form/Layout';
import Text from '@components/form/Text';
import { NextButton } from '@components/molecules/SignUp/Button';
import { SignUpLayout } from '@components/molecules/SignUp/Layout';
import TermsBox, { TermsBox2 } from '@components/molecules/SignUp/TermsBox';
import { AUTH_SIGN_UP_TERMS_ACTION } from '@constants/actionNames';
import { useReduxData } from '@hooks/useReduxData';

import { TCurrentStep } from './index';

type TTermsProps = {
  setCurrentStep: Dispatch<SetStateAction<TCurrentStep>>;
};

function Terms({ setCurrentStep }: TTermsProps) {
  const { data: termsData, setData: setTermsData } = useReduxData(AUTH_SIGN_UP_TERMS_ACTION);

  const setTermsOfUse = (dataIndex: string, checked: boolean) => {
    if (dataIndex === 'all') {
      let newTermsData = {
        all: false,
        use: false,
        privateInfo: false,
        promotion: false,
        locationInfo: false,
      };

      if (checked) {
        newTermsData = {
          all: true,
          use: true,
          privateInfo: true,
          promotion: true,
          locationInfo: true,
        };
      }
      setTermsData(newTermsData);
    }

    if (dataIndex !== 'all') {
      const newTermsData = {
        ...termsData,
        [dataIndex]: checked,
      };
      newTermsData.all = newTermsData.use && newTermsData.privateInfo && newTermsData.promotion; //&& newTermsData.locationInfo;
      setTermsData(newTermsData);
    }
  };

  const getIsDisabled = useCallback(() => {
    return !termsData.use || !termsData.privateInfo;
  }, [termsData]);

  const handleClick = () => {
    setCurrentStep('INFO');
  };

  return (
    <SignUpLayout>
      <Form style={{ maxWidth: '460px' }}>
        <Text textType="TITLE">약관 동의</Text>
        <HDivider margin="28px" type="TRANSPARENT" />

        <Checkbox
          checked={termsData.all}
          id="all"
          label="mixtape 이용약관, 개인정보 수집 및 이용, 프로모션 정보 수신(선택)에 모두 동의합니다."
          onChange={(e) => {
            setTermsOfUse('all', e.target.checked);
          }}
        />
        <HDivider margin="24px" type="TRANSPARENT" />

        <Checkbox
          checked={termsData.use}
          id="use"
          label={
            <>
              <span>mixtape. 이용약관 동의</span>
              <span style={{ color: '#ff6465' }}> (필수)</span>
            </>
          }
          onChange={(e) => {
            setTermsOfUse('use', e.target.checked);
          }}
        />
        <HDivider margin="5px" type="TRANSPARENT" />
        <TermsBox2>
          <iframe
            style={{ border: 'none' }}
            width="100%"
            marginWidth={30}
            height="100%"
            src={process.env.REACT_APP_URL + '/docs/terms_of_use.html'}
          />
        </TermsBox2>
        <HDivider margin="30px" type="TRANSPARENT" />

        <Checkbox
          checked={termsData.privateInfo}
          id="privateInfo"
          label={
            <>
              <span>개인정보 수집 및 이용 동의</span>
              <span style={{ color: '#ff6465' }}> (필수)</span>
            </>
          }
          onChange={(e) => {
            setTermsOfUse('privateInfo', e.target.checked);
          }}
        />
        <HDivider margin="5px" type="TRANSPARENT" />
        <TermsBox2>
          <iframe
            style={{ border: 'none' }}
            width="100%"
            height="100%"
            marginWidth={30}
            marginHeight={20}
            src={process.env.REACT_APP_URL + '/docs/privacy.html'}
          />
        </TermsBox2>
        <HDivider margin="30px" type="TRANSPARENT" />

        {/* <Checkbox */}
        {/*   checked={termsData.locationInfo} */}
        {/*   id="locationInfo" */}
        {/*   label={ */}
        {/*     <> */}
        {/*       <span>mixtape. 유료 이용약관 동의</span> */}
        {/*       <span style={{ color: '#ff6465' }}> (필수)</span> */}
        {/*     </> */}
        {/*   } */}
        {/*   onChange={(e) => { */}
        {/*     setTermsOfUse('locationInfo', e.target.checked); */}
        {/*   }} */}
        {/* /> */}
        {/* <HDivider margin="5px" type="TRANSPARENT" /> */}
        {/* <TermsBox2> */}
        {/*   <iframe style={{border:'none'}} width='100%' border="0" cellspacing="0" height='100%' src={process.env.REACT_APP_URL + '/docs/paid.html'}/> */}
        {/* </TermsBox2> */}
        {/* <HDivider margin="30px" type="TRANSPARENT" /> */}

        <Checkbox
          checked={termsData.promotion}
          id="promotion"
          label={
            <>
              <span>프로모션 정보 수신 동의</span>
              <span> (선택)</span>
            </>
          }
          onChange={(e) => {
            setTermsOfUse('promotion', e.target.checked);
          }}
        />
        <HDivider margin="5px" type="TRANSPARENT" />
        <TermsBox2>
          <iframe
            style={{ border: 'none' }}
            width="100%"
            marginWidth={30}
            height="100%"
            src={process.env.REACT_APP_URL + '/docs/promotion.html'}
          />
        </TermsBox2>
        <HDivider margin="30px" type="TRANSPARENT" />

        <NextButton disabled={getIsDisabled()} onClick={handleClick}>
          다음
        </NextButton>
      </Form>
    </SignUpLayout>
  );
}

export default Terms;
