import { Icon } from '@images/Icon';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Modal, { CloseButton, Header, HeaderText } from '@components/form/Modal';

import Finish from './Finish';
import Init from './Init';

export type TCurrentStep = 'INIT' | 'FINISH';

type TEditEmailModalProps = {
  setIsOpenEditEmailModal: () => void;
  isOpenEditEmailModal: boolean;
};

function EditEmailModal({
                          setIsOpenEditEmailModal,
                          isOpenEditEmailModal,
                          callback
                        }) {
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('INIT');

  useEffect(() => {
    if (!isOpenEditEmailModal) {
      setCurrentStep('INIT');
    }
  }, [isOpenEditEmailModal]);

  const closeHandler = (event, reason) => {
    if (reason) {
      return;
    }
    callback()
    setIsOpenEditEmailModal(false);
  };
  const headerText = currentStep === 'INIT' ? '이메일 변경' : '이메일 변경 완료';
  return (
    <Dialog
      maxWidth={false}
      onClose={closeHandler}
      open={isOpenEditEmailModal}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          padding: '25px 40px 40px 40px',

        }}
      }
    >

      <Stack sx={{width:'420px'}}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' pb={4}>
        <HeaderText>{headerText}</HeaderText>
        <IconButton
          onClick={closeHandler}
        >
          <CloseIcon />
        </IconButton>
      </Stack>


      {currentStep === 'INIT' && <Init setCurrentStep={setCurrentStep} />}
      {currentStep === 'FINISH' &&
        <Finish setCurrentStep={setCurrentStep}
                setIsOpenEditEmailModal={setIsOpenEditEmailModal}
                callback={callback}
        />
        }

      </Stack>
    </Dialog>
  );
}

export default EditEmailModal;
