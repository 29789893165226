import MainHeader from '@components/molecules/MainHeader';
import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import MainFooter from '@components/molecules/MainFooter';
import styled from '@emotion/styled';

const WriteTable = styled.table`
  width: 100%;
  margin-bottom: 40px;
  color: black;

  & th {
    width: 200px;
  }

  & th,
  td {
    font-size: 16px;
    font-weight: 300;
  }

  & th,
  td {
    padding: 25px 0px;
    border-top: 1px solid #d4d4d8;
    border-bottom: 1px solid #d4d4d8;
  }
`;

const ActionButton = styled(Button)`
  flex: none;
  padding: 10px 70px;
  font-size: 18px;
  font-weight: 300;
  border-color: #191919;
  border-radius: 10px;
`;

const FaqWrite = () => {
  return (
    <>
      <MainHeader logo="logoW" />
      <Stack direction="column" paddingY="200px" color="white" justifyContent="center" alignItems="center" bgcolor="white">
        <Stack direction="column" alignItems="center" style={{ minWidth: '1280px', maxWidth: '1280px' }}>
          {/* <Stack direction="column" alignItems="center" gap="80px"> */}
          <Typography fontSize="50px" fontWeight="bold" color="#191919" mb="80px">
            FAQ
          </Typography>
          <WriteTable>
            <tbody>
              <tr>
                <th>작성자</th>
                <td>고객1</td>
                <th>작성일</th>
                <td>2024.03.11 16:00:00</td>
              </tr>
              <tr>
                <th>제목</th>
                <td colSpan={3}>제목입니다.</td>
              </tr>
              <tr>
                <th>분류</th>
                <td colSpan={3}>게시글 분류</td>
              </tr>
              <tr>
                <th>내용</th>
                <td colSpan={3}>내용부분</td>
              </tr>
              <tr>
                <th>파일 첨부</th>
                <td colSpan={3}>파일 첨부부분</td>
              </tr>
            </tbody>
          </WriteTable>
          <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
            <ActionButton variant="outlined" sx={{ color: '#191919' }}>
              목록
            </ActionButton>
            <Stack direction="row" gap="27px">
              <ActionButton variant="outlined" sx={{ color: '#191919' }}>
                취소
              </ActionButton>
              <ActionButton sx={{ backgroundColor: '#191919', color: 'white' }}>등록</ActionButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <MainFooter />
    </>
  );
};

export default FaqWrite;
