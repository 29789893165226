import { apiRequest } from '@utils/api';
import { getAccessToken } from '@utils/auth';

import { TErrorData, TMapper } from './types';

type TLoginParams = {
  user_id: string;
  password: string;
};

type TSingUpParams = any;

export const login: TMapper<TLoginParams, any> = async (params) => {
  const loginResult = await apiRequest({
    url: `/sign-in`,
    method: 'POST',
    data: params,
  });

  // if (loginResult) {
  //   const tokenResult = await apiRequest({
  //     url: `/user-auth`,
  //     method: 'GET',
  //     headers: {
  //       Authorization: `Bearer ${loginResult.data.token}`,
  //     },
  //   });
  //   if (tokenResult) {
  //     return loginResult;
  //   }
  // }

  return loginResult;
};

export const logout = async () => {
  const logoutResult = await apiRequest({
    url: `/logout`,
    method: 'POST',
  });

  return logoutResult;
};

export const signUp: TMapper<TSingUpParams, any> = async (params) => {
  const realParams = { user_rrn: `${params.user_rrn_front ?? ''}-${params.user_rrn_back ?? ''}` };
  for (let [key, val] of Object.entries(params)) {
    if (key === 'bank_id') {
      realParams[key] = val.value;
    } else if (key === 'vibe') {
    } else {
      realParams[key] = val;
    }
  }
  // @ts-ignore
  realParams['languages'] = [realParams.artist_name_foriegn];

  if (params['isExistRelease'] === 'true') {
    const vibe = params.vibe;
    realParams['artist_id'] = vibe.artistId;
    realParams['artist_name'] = vibe.artistName;
    realParams['vibe_image'] = vibe.imageUrl;
  }

  const singUpReqData = {
    ...realParams,
    // user_id: params.user_id ?? '',
    // password: params.password ?? '',
    // name: params.name ?? '',
    // user_rrn: `${params.user_rrn_front ?? ''}-${params.user_rrn_back ?? ''}`,
    // phone_number: params.phone_number ?? '',
    // email: params.email ?? '',
    // foreigner_state: params.foreigner_state ?? '0',
    // bank_id: params.bank_id?.value ?? '',
    // account_number: params?.account_number ?? '',
    // account_holder: params?.account_holder ?? '',
    // gender_id: params.gender_id?.value ?? '',
  };

  const signUpResult = await apiRequest({
    url: `/sign-up`,
    method: 'POST',
    data: singUpReqData,
  });

  return signUpResult;
};

type TGetUserInfo = {
  id: string;
};

export const getUserInfo: TMapper<TGetUserInfo, any> = async (params) => {
  const result = await apiRequest({
    url: `/user/${params.id}`,
    method: 'GET',
  });

  return result;
};

type TFindId = {
  name: string;
  phone_number: string;
  res_auth_id?: string | null;
};

export const findId: TMapper<TFindId, any> = async (params) => {
  const result = await apiRequest({
    url: `/find-id`,
    method: 'POST',
    data: params,
  });

  return result;
};

type TGetAuthNumber = {
  phone_num: string;
  auth_type: 'find-id' | 'find-pw' | 'sign-up';
  user_id?: string;
  name?: string;
};

export const getAuthNumber: TMapper<TGetAuthNumber, any> = async (params) => {
  const result = await apiRequest({
    url: `/token/sms-check`,
    method: 'POST',
    data: params,
  });

  return result;
};

type TCertificateAuthNumber = {
  phone_num: string;
  auth_code: string;
  name?: string;
  res_auth_id?: string | null;
};
export const certificateAuthNumber: TMapper<TCertificateAuthNumber, any> = async (params) => {
  const result = await apiRequest({
    url: `/token/sms-auth-check`,
    method: 'POST',
    data: params,
  });

  return result;
};

type TResetPassword = {
  user_id: string;
  name: string;
  phone_number: string;
  password: string;
  res_auth_id?: string;
};
export const resetPassword: TMapper<TResetPassword, any> = async (params) => {
  const result = await apiRequest({
    url: `/find-pw`,
    method: 'POST',
    data: params,
  });

  return result;
};

type TGetAuthCode = {
  bank_cd: string;
  account_id: string;
  bank_name: string;
};

export const getAuthCode: TMapper<TGetAuthCode, any> = async (params) => {
  const result = await apiRequest({
    url: `/token/bank_check`,
    method: 'POST',
    data: params,
  });

  return result;
};

type TCertificateAuthCode = {
  res_uniq_id: string;
  bank_otp: string;
};

export const certificateAuthCode: TMapper<TCertificateAuthCode, any> = async (params) => {
  const result = await apiRequest({
    url: `/token/bank_auth_check`,
    method: 'POST',
    data: params,
  });

  return result;
};

export const getIsExistId: TMapper<TLoginParams, any> = async (params) => {
  const result = await apiRequest({
    url: `/check-id/${params.user_id}`,
    method: 'GET',
  });

  return result;
};

export const activateUser: TMapper<TLoginParams, any> = async (params) => {
  const result = await apiRequest({
    url: `/user/activate`,
    method: 'POST',
    data: params,
  });

  return result;
};

///
/// 프로필 추가
///

export const addProfile: TMapper<TLoginParams, any> = async (params) => {
  const result = await apiRequest({
    url: `/profile`,
    method: 'POST',
    data: params,
  });

  return result;
};
