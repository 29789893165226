import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import {
  AUTH_FIND_PASSWORD_ACTION,
  AUTH_FIND_PASSWORD_GET_NUMBER_ACTION,
  AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
} from '@constants/actionNames';
import AuthNumberCertification from '@containers/UserInfoSetting/EditPasswordModal/AuthNumberCertification';
import Completed from '@containers/UserInfoSetting/EditPasswordModal/Completed';
import ResetPassword from '@containers/UserInfoSetting/EditPasswordModal/ResetPassword';
import { useReduxData } from '@hooks/useReduxData';
import { $mixtapeGetUser } from '@utils/auth';

export type TCurrentStep = 'AUTH_NUMBER' | 'RESET' | 'COMPLETED';

type TEditPasswordModalProps = {
  setIsOpenEditPasswordModal: () => void;
  isOpenEditPasswordModal: boolean;
};

function EditPasswordModal({ isOpenEditPasswordModal, setIsOpenEditPasswordModal }: TEditPasswordModalProps) {
  const [isShowAuthNumberInput, setIsShowAuthNumberInput] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<TCurrentStep>('AUTH_NUMBER');
  const { clearData: clearFindPasswordData } = useReduxData(AUTH_FIND_PASSWORD_ACTION);
  const { clearData: clearFindPasswordGetAuthNumberData } = useReduxData(AUTH_FIND_PASSWORD_GET_NUMBER_ACTION);
  const { clearData: clearFindPasswordIsExistId } = useReduxData(AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION);

  const userInfo = $mixtapeGetUser();
  const [authId, setAuthId] = useState('');

  useEffect(() => {
    if (!isOpenEditPasswordModal) {
      clearFindPasswordData();
      clearFindPasswordGetAuthNumberData();
      clearFindPasswordIsExistId();
      setCurrentStep('AUTH_NUMBER');
      setIsShowAuthNumberInput(false);
    }
  }, [isOpenEditPasswordModal]);

  return (
    <Modal headerText="비밀번호 재설정" hide={setIsOpenEditPasswordModal} isShown={isOpenEditPasswordModal}>
      <>
        {currentStep === 'AUTH_NUMBER' && (
          <AuthNumberCertification
            id={userInfo.user_id}
            isShowAuthNumberInput={isShowAuthNumberInput}
            setCurrentStep={setCurrentStep}
            setIsShowAuthNumberInput={setIsShowAuthNumberInput}
            setAuthId={setAuthId}
          />
        )}
        {currentStep === 'RESET' && <ResetPassword setCurrentStep={setCurrentStep} authId={authId} />}
        {currentStep === 'COMPLETED' && (
          <Completed setCurrentStep={setCurrentStep} setIsOpenEditPasswordModal={setIsOpenEditPasswordModal} />
        )}
      </>
    </Modal>
  );
}

export default EditPasswordModal;
