import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { routePath } from '@constants/path';

import {
  AlbumManagement,
  Introduction,
  MembershipGuide,
  Channel,
  Menual,
  Accelerating,
  Login,
  NotFoundError,
  ProfileManagement,
  SettlementManagement,
  SignUp,
  UserInfoSetting,
  Faq,
  Notice,
  NoticeDetail,
  Qna,
  FaqWrite,
  Terms,
} from './Loadable';
import MembershipInfo from '@containers/Payment/MembershipInfo';
import ProMembership from '@containers/Portal/popup/ProMembershipPopup/ProMembershipPage';

export default function RoutesLayout() {
  return (
    <Switch>
      <Route component={ProMembership} exact path="/notice/pro_membership"></Route>
      {/* <Route exact path="/"> */}
      {/*   <Redirect to={routePath.album.albumManagement.path} /> */}
      {/* </Route> */}

      {/* Login */}
      {/* <Route component={Login} exact path={routePath.login.path} /> */}
      {/* SignUp */}
      {/* <Route component={SignUp} exact path={routePath.signup.path} /> */}
      {/* AlbumManagement */}
      {/* <Route component={AlbumManagement} exact path={routePath.album.albumManagement.path} /> */}
      {/* ProfileManagement */}
      <Route component={ProfileManagement} exact path={routePath.profile.profileManagement.path} />
      {/* SettlementManagement */}
      <Route component={SettlementManagement} exact path={routePath.album.settlementManagement.path} />
      {/* UserInfoSetting */}
      <Route component={UserInfoSetting} exact path={routePath.userInfo.userInfoSetting.path} />
      {/* NotFoundError */}

      <Route component={Introduction} exact path={routePath.intro.introduction.path} />
      <Route component={MembershipGuide} exact path={routePath.intro.membership.path} />
      <Route component={Menual} exact path={routePath.intro.manual.path} />
      <Route component={Channel} exact path={routePath.intro.channel.path} />

      <Route component={Accelerating} exact path={routePath.promotion.accelerating.path} />

      <Route component={Faq} exact path={routePath.help.faq.path} />
      <Route component={FaqWrite} exact path={routePath.help.faqWrite.path} />

      <Route component={Notice} exact path={routePath.help.notice.path} />
      <Route component={NoticeDetail} exact path={routePath.help.noticeDetail.path} />

      <Route component={Qna} exact path={routePath.help.qna.path} />

      <Route component={UserInfoSetting} path={routePath.userInfo.userInfoSetting.path} />

      <Route component={Terms} path={routePath.terms.path} />

      {/* <Route component={MembershipInfo} path={'/membership-info'}></Route> */}
      <Route component={MembershipInfo} path={'/membership-info/:type?'}></Route>


    </Switch>
  );
}
