export const VALIDATION_MESSAGES: Record<string, string> = {
  'common.required': '필수 입력 값입니다.',
  'login.user_id': '아이디를 입력해 주세요.',
  'login.password': '비밀번호를 입력해 주세요.',
  'signup.user_id': '5~20자 영문 소문자, 숫자, 특수기호(_),(-)를 사용하세요.',
  'signup.password.exp': '8~30자 영문 대/소문자, 숫자, 특수문자를 사용하세요.',
  'signup.name': '한글, 영문 대/소문자를 사용하세요.',
  'signup.user_rrn_front': '주민등록번호를 다시 한 번 확인해 주세요.',
  'signup.user_rrn_back': '주민등록번호를 다시 한 번 확인해 주세요.',
  'signup.user_rrn': '정상적인 주민등록번호가 아닙니다.',
  'signup.foreigner_no': '정상적인 외국인등록번호가 아닙니다. 다시 한 번 확인해 주세요.',

  'signup.phone_number': '휴대전화번호를 다시 한 번 확인해 주세요.',
  'signup.artist_name': '최대 100자까지 입력 가능하며 이모지는 사용이 불가능합니다.',
  'signup.artist_name_foriegn': '최대 250자까지 입력 가능하며 이모지는 사용이 불가능합니다.',
  'signup.type_id': '아티스트 타입을 입력해 주세요.',
  'signup.gender_id': '아티스트 성별을 입력해 주세요.',
  'signup.genres': '주요 장르를 입력해 주세요.',
  'signup.roles': '주요 역할을 입력해 주세요.',
  'signup.bank_id': '은행을 선택해 주세요.',
  'signup.account_number': '숫자만 입력가능합니다.',
  'signup.account_holder': '한글, 영문 대/소문자를 사용하세요.',
  'signup.account_holder_not_same': '예금주명과 회원가입 정보의 이름이 일치하지 않습니다.',
  'signup.email': '이메일 형식을 확인해 주세요.',
  'albuminfo.album_name': '앨범 제목은 최대 한글 128자/영문 250자이며, 이모지는 반영 불가합니다.',
  'albuminfo.album_name_foriegn': '앨범 제목은 최대 한글 128자/영문 250자이며, 이모지는 반영 불가합니다.',
  'albuminfo.artist': '아티스트를 입력해 주세요.',
  'albuminfo.genre_id': '장르를 입력해 주세요.',
  'albuminfo.type_id': '앨범 타입을 입력해 주세요.',
  'albuminfo.agency_name': '기획사명을 입력해 주세요.',
  'albuminfo.description': '앨범 소개를 입력해 주세요.',

  'albuminfo.country_id': '국가를 입력해 주세요.',
  'albuminfo.album_cover': '앨범커버를 등록해 주세요.',
  'trackinfo.track_name': '트랙명은 최대 한글 128자/영문 250자입니다.',
  'trackinfo.track_name_foriegn': '트랙명은 최대 한글 128자/영문 250자입니다.',
  'trackinfo.track_artist': '아티스트를 확인해주세요.',
  'trackinfo.track_genre_id': '장르를 입력해 주세요.',
  'trackinfo.language_id': '언어를 입력해 주세요.',
  'trackinfo.is_remake': '원 저작자의 개작동의서를 업로드해 주세요.',
  'trackinfo.is_owner': '비트 독점 구매 관련 라이센스, 서류 등을 제출해 주세요.',
};
