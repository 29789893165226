import React from 'react';
import { useTable } from 'react-table';

import HDivider from '@components/form/HDivider';
import styled from '@emotion/styled';
import Text from '@components/form/Text';
const Styles = styled.div`
  width: 100%;
  .no-data-box {
    display: flex;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
  }
  table {
    border-spacing: 0;
    width: 100%;
    background: transparent;

    tr th {
      &:first-of-type {
        text-align: left;
        padding-left: 84px;
      }
    }

    th {
      font-family: 'Pretendard';
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #888;
    }

    tr td {
      &:first-of-type {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }

    tbody tr {
      cursor: pointer;
    }

    td {
      background: #323232;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #e8e8e8;
      flex: none;
      order: 0;
      flex-grow: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
`;

type TTableProps = {
  columns: Array<any>;
  data: Array<any>;
  rowClick?: (row: any) => void;
};
const Table = ({ columns, data, rowClick }: TTableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <Styles>
      <table {...getTableProps()} style={{ borderSpacing: '0 20px' }}>
        <thead>
          {headerGroups.map((header: any) => (
            <tr {...header.getHeaderGroupProps()}>
              {header.headers.map((col: any) => (
                <th {...col.getHeaderProps()}>{col.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => rowClick && rowClick(row)}>
                {row.cells.map((cell: any) => (
                  <td {...cell.getCellProps()} style={cell.column.style ?? {}}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!rows ||
        (rows.length == 0 && (
          <div className="no-data-box">
            <Text>검색 결과가 없습니다.</Text>
          </div>
        ))}
    </Styles>
  );
};

export default Table;
