import Text from '@components/form/Text';
import styled from '@emotion/styled';

export const UserInfoSettingIdText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #dddddd;
`;

export const UserInfoSettingInfoText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* color/fff */

  color: #ffffff;
`;

export const UserInfoSettingPromotionTitle = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  /* color/fff */

  color: #ffffff;
`;

export const UserInfoSettingBottomText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* color/bcbcbc */

  color: #bcbcbc;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const EditErrorText = styled(Text)`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #ff6465;
`;
