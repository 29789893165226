/* eslint-disable no-nested-ternary */
import TTextField from '@components/form/TTextField';
import styled from '@emotion/styled';
import { Autocomplete, Box, Checkbox, MenuItem, Tooltip, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledSelect = styled(Select)`
  border-radius: 10px;
  font-family: 'Pretendard';
  font-size: 14px;
  color: #121212;
  min-height: 50px;
`;

const StyledAutoComplete = styled(Autocomplete)`
  border-radius: 10px;
  font-family: 'Pretendard';
  font-size: 14px;
  color: #121212;
  min-height: 50px;
`;

const selectStyle = {
  padding: '1px',
  color: '#dcdcdc',
  flex: 1,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#dcdcdc',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#dcdcdc',
  },
  '& .MuiSvgIcon-root': {
    color: '#CCC',
  },
  '& .MuiInputBase-input': {
    padding: '1px 10px 1px 10px !important',
  },
  '&:before': {
    borderBottom: `1px solid #cccccc`,
  },
  '&:hover': {
    ':before': {
      borderBottom: `1px solid #001122`,
    },
  },
  '& .MuiMenuItem-root': {
    backgroundColor: 'dark.primary',
  },
  '& .MuiMenu-paper': {
    backgroundColor: 'dark.primary',
  },
};

const selectMenuStyle = {
  maxHeight: 40 * 10 + 8,
  '& .MuiMenu-paper': {
    border: '1px solid #cccccc',
    borderRadius: '10px',
    marginTop: '3px',
    backgroundColor: 'dark.primary',
    color: '#121212',
    fontSize: '12px',
    fontFamily: 'Pretendard',
  },
  '& .MuiMenuItem-root': {
    color: '#121212',
    fontSize: '13px',
  },

  '& .MuiMenuItem-root:hover': {
    backgroundColor: '#f1f1f1',
    color: '#121212',
    fontSize: '13px',
  },
  '& .Mui-selected': {
    backgroundColor: '#b4dfff',

    fontSize: '13px',
  },
};

const newIcon = (props) => {
  return <ExpandMoreIcon {...props} />;
};

const ChipSelect = ({ children, itemData = [], maxSelect = 0, maxTooltip = null, ...rest }: any) => {
  const [uc_selectedValues, uf_setSelectedValues] = useState([]);
  const makeChips = (selected, data) => {
    // uf_setSelectedValues(selected);

    return (
      <Box
        sx={{
          padding: 0,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.5,
        }}
      >
        {selected.map((value) => {
          const lbl = data.find((ele) => ele.id === value) ?? value;
          return <Chip key={value} label={lbl.code_name} sx={{ borderRadius: '8px' }} />;
        })}
      </Box>
    );
  };
  const getCodeMenuItems = (item: any) => {
    const disabled = maxSelect > 0 && rest.value.length === maxSelect && rest.value.findIndex((ele) => ele === item.id) < 0;
    const menuItems = (
      <MenuItem disabled={disabled} key={item.id} value={item.id}>
        <Checkbox
          disabled={maxSelect > 0 && rest.value.length === maxSelect && rest.value.findIndex((ele) => ele === item.id) < 0}
          checked={rest.value.indexOf(item.id) > -1}
        />
        {item.code_name}
      </MenuItem>
    );
    return disabled && maxTooltip ? (
      <Tooltip title={maxTooltip}>
        <div> {menuItems}</div>
      </Tooltip>
    ) : (
      menuItems
    );
  };

  return (
    <StyledSelect
      components={{
        IndicatorSeparator: () => null,
      }}
      IconComponent={newIcon}
      {...rest}
      multiple
      renderValue={(value) => makeChips(value, itemData)}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },

        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        sx: selectMenuStyle,
      }}
      sx={{
        ...selectStyle,
        '& .MuiSelect-select .notranslate::after': rest.placeholder
          ? {
              content: `" ${rest.placeholder}"`,
              opacity: 0.45,
            }
          : {},
      }}
    >
      {children}
      {itemData.map((item: any) => getCodeMenuItems(item))}
    </StyledSelect>
  );
};

export default ChipSelect;

export const BaseSelect = ({ children, itemData = [], ...rest }: any) => {
  return (
    <StyledSelect
      IconComponent={newIcon}
      components={{
        IndicatorSeparator: () => null,
      }}
      {...rest}
      size="small"
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },

        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        sx: selectMenuStyle,
      }}
      sx={{
        ...selectStyle,
        ...rest.sx,

        '& .MuiSelect-select .notranslate::after': rest.placeholder
          ? {
              content: `" ${rest.placeholder.trim()}"`,
              opacity: 0.45,
            }
          : {},
      }}
    >
      {children}
      {itemData.map((item: any) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.code_name}
          </MenuItem>
        );
      })}
    </StyledSelect>
  );
};

export function TAutoComplete(props) {
  return (
    <StyledAutoComplete
      includeInputInList
      autoComplete
      IconComponent={newIcon}
      components={{
        IndicatorSeparator: () => null,
      }}
      renderInput={(params) => (
        <TTextField
          {...params}
          sx={{
            background: '#FFF',
            borderRadius: '10px ',
          }}
          placeholder={props.placeholder}
        />
      )}
      MenuProps={{
        // anchorOrigin: {
        //   vertical: 'bottom',
        //   horizontal: 'center',
        // },
        //
        // transformOrigin: {
        //   vertical: 'top',
        //   horizontal: 'center',
        // },
        sx: selectMenuStyle,
      }}
      sx={{
        '& .MuiAutocomplete-listbox': {
          border: '2px solid grey',
          minHeight: 400,
          color: 'green',
          fontSize: 18,
          //hover discussed above
          '& li': {
            //list item specific styling
            border: '2px solid green',
            borderRadius: 4,
          },
        },
      }}
      {...props}
    />
  );
}
