import MainFooter from '@components/molecules/MainFooter';
import MainHeader from '@components/molecules/MainHeader';
import MixSwiper from '@components/ui/MixSwiper';
import styled from '@emotion/styled';
import { Button, Collapse, List, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import Masonry from '@mui/lab/Masonry';
import { css } from '@emotion/core';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 200px 0;
	background-color: #1e1e1e;
	color: white;

	& .swiper-pagination-horizontal {
		bottom: 20px !important;
	}
`;

const LogoCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 324px;
	height: 150px;
	padding: 30px 0px 20px 0px;
	background: transparent linear-gradient(122deg, #e8ebf2 0%, #f2f3f7 100%) 0% 0% no-repeat padding-box;
	box-shadow: 10px 10px 16px #0f296b1e;
	border-radius: 24px;
	color: black;
`;

// const MixMasonry = styled.div`
//   column-count: 2;
//   column-gap: 3%;
//   column-width: 50%;

//   &.item {
//   }
// `;

const BenefitCard = styled.div<{ cardNum: number }>`
	display: flex;
	flex-direction: column;
	width: 500px !important;
	/* justify-content: space-between; */
	gap: 30px;
	padding: 50px 40px;
	background: transparent linear-gradient(134deg, #393939 0%, #242424 100%) 0% 0% no-repeat padding-box;
	box-shadow: 10px 10px 16px #0f296b1e;
	border-radius: 6px;

	${(props) => {
		switch (props.cardNum) {
			case 1:
			case 3:
			case 5:
				return css`
					margin-right: 12px !important;
				`;

			case 2:
			case 4:
				return css`
					margin-left: 12px !important;
				`;
		}
	}};

	${(props) => {
		switch (props.cardNum) {
			case 1:
			case 2:
				return css`
					margin-bottom: 12px !important;
				`;

			case 3:
			case 4:
				return css`
					margin-top: 12px !important;
					margin-bottom: 12px !important;
				`;
			case 5:
				return css`
					margin-top: 12px !important;
				`;
		}
	}};

	& .number {
		margin-bottom: 30px;
		font-size: 24px;
		font-weight: 600;
		text-shadow: 2px 2px 1px #ffffff3d;
	}

	& .title {
		font-size: 28px;
		font-weight: 600;
		line-height: 1;
	}

	& .contents {
		font-size: 14px;
		font-weight: 300;
	}

	& hr {
		margin: 0;
	}

	& li {
		position: relative;
		padding-left: 10px;
		margin-bottom: 12px;

		&::before {
			content: '';
			position: absolute;
			top: 10px;
			left: 0;
			width: 3px;
			height: 3px;
			border-radius: 50%;
			background-color: #fff;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const AlbumCard = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;

	& .card img {
		width: 16.67vw;
		height: 16.67vw;
		border-radius: 24px;
	}

	& .album-title {
		margin-left: 5px;
		font-size: 18px;
		font-weight: 300;
		line-height: 21px;
	}

	& .album-artist {
		margin-left: 5px;
		font-size: 18px;
		font-weight: 300;
		color: #767676;
		line-height: 21px;
	}
`;

const FaqWrap = styled.div`
	width: 1024px;

	& .parent-list {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 24px 40px;
		border-top: 1px solid white;
		font-size: 18px;
		font-weight: 300;
	}

	& .child-list {
		padding: 25px 60px;
		background: #444444 0% 0% no-repeat padding-box;
		line-height: 28px;
		font-size: 14px;
		font-weight: 300;
		color: #effd60;
	}

	&:last-child {
		border-bottom: 1px solid white;
	}
`;

const ViewMoreBtn = ({ siteName }: { siteName: string }) => {
	const goSite = () => {
		switch (siteName) {
			case 'hiphople':
				window.open('https://hiphople.com/', '_blank');
				break;
			case 'baund':
				window.open('https://www.baund.com/', '_blank');
				break;
			case 'musichub':
				window.open('https://musichub.kr/', '_blank');
				break;
		}
	};
	return (
		<Stack direction="row" gap="6px" alignItems="center" style={{ cursor: 'pointer' }} onClick={goSite}>
			<img height="14px" src="/assets/icon/home.svg"></img>
			<Typography fontSize="12px"> View More</Typography>
			<img height="9px" src="/assets/icon/next.svg"></img>
		</Stack>
	);
};

const Faq = ({ faq }: { faq: { question: string; answer: string } }) => {
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<>
			<div className="parent-list" onClick={handleClick}>
				{faq.question}
				{open ? (
					<img height="14px" style={{ transform: `rotate(-90deg)` }} src="/assets/icon/next-w.svg"></img>
				) : (
					<img height="14px" style={{ transform: `rotate(90deg)` }} src="/assets/icon/next-w.svg"></img>
				)}
			</div>
			<Collapse className="child-list" in={open} timeout="auto" unmountOnExit>
				<div dangerouslySetInnerHTML={{ __html: faq.answer.replace(/\n/g, '<br />') }}></div>
			</Collapse>
		</>
	);
};
const FAQ_LIST = [
	{
		question: '선정 기준이 어떻게 되나요?',
		answer:
			'특별한 선정 기준이 정해져 있지는 않지만, 내부 전문가분들이 정량적, 정성적 판단을 종합적으로 고려하고 있어요.<br />아티스트의 미래 성장 가능성이 뚜렷하게 보인다면, 우선적으로 선정할 예정입니다.'
	},
	{
		question: '다른 뮤직 엑셀러레이팅 프로그램과의 차이는 무엇인가요?',
		answer:
			'다른 프로그램과 달리, 본 프로그램은 실제 음악 시장에서 성장할 수 있는 기회를 제공하려고 해요.<br />일반적인 접근법 보단 아티스트에 맞춘 홍보 프로그램을 제공하여 미래 성장을 지원하고자 합니다.<br />아티스트가 희망하는 경우 대면 미팅을 통해 앨범 제작 및 홍보 컨설팅도 진행할 계획이예요.'
	},
	{
		question: '믹스테이프 유료 멤버십 회원만 지원이 가능한가요?',
		answer:
			'네, 본 프로그램은 유료 믹스테이프 멤버십 회원에 한하여 적용돼요.<br />멤버십에 가입한 후에는 반드시 신보 앨범을 1개 이상 발매해야 합니다.'
	},
	{
		question: '몇팀을 선발하나요?',
		answer: '매달 여러 팀을 선발하고 있어요. 경우에 따라 1팀 이상이 선정되거나, 선정되지 않을 수 있습니다.'
	},
	{
		question: '매달 지원해도 가능한가요?',
		answer: '네, 매달 새로운 신보 앨범을 발매한다면, 매달 지원해도 무방합니다.'
	},
	{
		question: '최종 선정 결과는 어떻게 확인하나요?',
		answer:
			'매달 중순 경에 1팀씩 선정하여, 개별 연락을 드리고 있어요.<br />최종 선정 결과는 공지사항 게시판을 통해 확인할 수 있습니다.'
	},
	{
		question: '선정 혜택을 선택적으로 고를 수 있나요?',
		answer: '네, 상호 협의 후 선택적으로 혜택을 받을 수 있습니다.'
	},
	{
		question: '엑셀러레이팅 관련 세부 문의는 어떻게 하나요?',
		answer: '믹스테이프 고객센터를 통해 문의해주시면 돼요.'
	}
];
const BENEFITS_CONTENT = [
	{
		title: ['HIPHOP LE', ' 웹/소셜 배너 지원'],
		contents: [
			'HIPHOP LE 웹 배너',
			'HIPHOP LE 모바일 배너',
			'HIPHOP LE 인스타그램 포스팅',
			'HIPHOP LE : https://www.hiphople.com',
			'수급 가능한 소재와 HIPHOP LE 측 컨디션에 따라 진행되는 광고 내용이 달라질 수 있습니다.'
		],
		height: 483,
		domain: 'www.hiphople.com'
	},
	{
		title: ['Baund', 'App. 홍보 지원'],
		contents: [
			'Baund 어플리케이션 팝업(앱 구동 직후)',
			'Baund 어플리케이션 배너 - 메인 / 상세페이지 내',
			'Baund Picks! (플레이탭)',
			'Baund Magazine (컬처탭)',
			'유튜브 콘텐츠, 공연/이벤트 정보 노출 (컬처탭)',
			'Baund SNS 포스팅',
			'수급 가능한 소재와 Baund 측 컨디션에 따라 진행되는 광고 내용이 달라질 수 있습니다.',
			'아티스트가 직접 Baund 플랫폼에 콘텐츠를 업로드할 수도 있습니다.'
		],
		height: 559,
		domain: 'www.baund.com'
	},
	{
		title: ['RAP HOUSE', '무대 공연 지원'],
		contents: [
			'BAUND와 공동으로 주최하는 <OPEN MIC> 컴피티션에 지원하여 선정될 경우, <RAP HOUSE> 콘서트에서 공연이 가능합니다.'
		],
		height: 327,
		domain: null
	},
	{
		title: ['뮤직허브', '포인트'],
		contents: [
			'선정팀은 뮤직허브 10만원 상당 포인트(1,000P) 지원 (약 20여명의 인플루언서에게 본인곡 피칭)',
			'참가자 전원 뮤직허브 1만원 상당 포인트(100P) 지원'
		],
		height: 327,
		domain: 'www.musichub.kr'
	},
	{
		title: ['강남역 옥외광고', '구좌 현물 지원'],
		contents: [
			'아래 이미지에 나온 빨간색 구좌에 2주간 노출됩니다. (위치 : 서울 강남구 강남대로 422, 씨티빌딩)',
			'유동 인구가 많은 강남대로에 설치된 유일무이한 ‘Curved- LED’ 전광판이며, 주목도가 높습니다.'
		],
		height: 327,
		domain: null
	}
];
const Accelerating = () => {
	const [isAfterSeptemberFirst, setIsAfterSeptemberFirst] = useState(false);

	useEffect(() => {
		const currentDate = new Date();
		const targetDate = new Date(currentDate.getFullYear(), 8, 1, 0, 0, 0); // 8 = September (months are 0-based)
		if (currentDate >= targetDate) {
			setIsAfterSeptemberFirst(true);
		} else {
			setIsAfterSeptemberFirst(false);
		}
	}, []);

	return (
		<>
			<MainHeader logo="logoW" />
			<Wrapper>
				<Typography fontSize="50px" fontWeight="bold" mb="80px">
					엑셀러레이팅 :: 시즌 1
				</Typography>
				<MixSwiper
					slidesPerView={1}
					centeredSlides
					style={{ width: '100%', height: '100%', marginBottom: '120px' }}
					isDarkMode
				>
					<SwiperSlide>
						<div style={{ position: 'relative' }}>
							<div style={{ width: '100%', height: '650px', background: '#191919', opacity: 0.7 }}>
								<img
									width="100%"
									height="650px"
									style={{ objectFit: 'cover' }}
									src="/assets/img/accelerating_banner1.png"
								></img>
							</div>

							<div
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									textAlign: 'center'
								}}
							>
								<Typography fontSize="60px" fontWeight="bold" noWrap>
									“아티스트 한 분 한 분에게 새로운 가치를 전달하자.”
								</Typography>
								<Typography fontSize="40px" color="#60E2FD">
									2023. 9. 1 ~ 2024. 8. 31 (매달 선정)
								</Typography>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div style={{ position: 'relative' }}>
							<div style={{ width: '100%', background: '#191919', opacity: 0.7 }}>
								<img width="100%" height="650px" src="/assets/img/accelerating_banner2.png"></img>
							</div>

							<div
								style={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%, -50%)',
									textAlign: 'center'
								}}
							>
								<Typography fontSize="60px" fontWeight="bold" noWrap>
									엑셀러레이팅 :: 시즌 1
								</Typography>
								<Typography fontSize="40px" color="#60E2FD">
									with HIPHOP LE, Baund and musichub
								</Typography>
							</div>
						</div>
					</SwiperSlide>
				</MixSwiper>
				<Typography fontSize="56px" fontWeight="bold" mb="80px">
					With HIPHOP LE, Baund and musichub
				</Typography>
				<Stack direction="row" gap="26px" mb="100px">
					<LogoCard>
						<Typography fontSize="12px">국내 최대 규모의 힙합/R&B 미디어 커뮤니티</Typography>
						<img height="40px" src="/assets/logo/hiphople_logo.png"></img>
						<ViewMoreBtn siteName="hiphople" />
					</LogoCard>
					<LogoCard>
						<Typography fontSize="12px">음악 기반 소셜 엔터테인먼트 플랫폼</Typography>
						<img height="56px" src="/assets/logo/baund_logo.svg"></img>
						<ViewMoreBtn siteName="baund" />
					</LogoCard>
					<LogoCard>
						<Typography fontSize="12px">아티스트 음원 홍보 플랫폼</Typography>
						<img height="80px" src="/assets/logo/musichub_logo.svg"></img>
						<ViewMoreBtn siteName="musichub" />
					</LogoCard>
				</Stack>
				<Typography fontSize="18px" lineHeight="32px" mb="100px">
					그래서 힙합엘이, 바운드 그리고 뮤직허브와 함께 “엑셀러레이팅 프로그램 :: 시즌1”을 준비했어요.
					<br />
					앞으로 매달 아티스트를 선정하여, YG PLUS의 음원 유통 역량을 개별 아티스트에게 집중 지원할 예정입니다.
					<br />
					선정팀은 온라인, 오프라인 마케팅 혜택을 누리며, 후속 케어를 받으면서 추가 기회를 얻을 수 있습니다.
					<br />
					단언컨대, 이번 ‘엑셀러레이팅 프로그램 :: 시즌1’은 새로운 기회와 가치를 제공할 것이라 믿습니다.
					<br />
					자세한 내용은 아래 공고문을 확인하시고, 추가 문의사항이 계시면 고객센터를 통해 문의해 주세요.
				</Typography>

				{!isAfterSeptemberFirst ? (
					<>
						<Stack
							direction="row"
							justifyContent="space-between"
							minWidth="940px"
							maxWidth="940px"
							p="40px"
							mb="60px"
							border="2px solid #767676"
							borderRadius="6px"
							boxShadow="10px 10px 16px #0F296B1E"
						>
							<div>
								<Typography fontSize="28px" fontWeight="bold" color="#EFFD60" mb="30px">
									• 지원기간
								</Typography>

								<Typography fontSize="14px" lineHeight="32px">
									2023. 9. 1 ~ 2024. 8. 31 / 1년간 (매달 선정)
								</Typography>
							</div>
							<div>
								<Typography fontSize="28px" fontWeight="bold" color="#EFFD60" mb="30px">
									• 지원방법
								</Typography>

								<Typography fontSize="14px" lineHeight="32px">
									1. 믹스테이프 유료 멤버십 회원 가입
									<br />
									2. 믹스테이프 서비스를 통해 앨범 발매 진행
									<br />
									3. 앨범 발매 후 페이지 하단 [엑셀러레이팅 시즌 1 :: 지원하기] 버튼 클릭 후 지원서 제출
								</Typography>
							</div>
						</Stack>
						<Button
							style={{
								flex: 'none',
								width: '350px',
								height: '54px',
								background: '#effd60',
								fontSize: '18px',
								color: 'black',
								textTransform: 'none',
								marginBottom: '120px'
							}}
							onClick={() => {
								window.open(
									'https://docs.google.com/forms/d/e/1FAIpQLSf1YvfnPgH6j543-oIdpcPp0E_WnBuCoyMfIK5EtITVuCqSEw/viewform',
									'_blank'
								);
							}}
						>
							엑셀러레이팅 시즌 1 :: 지원하기
						</Button>
					</>
				) : (
					<Stack
						minWidth="940px"
						maxWidth="940px"
						p="40px"
						mb="60px"
						border="2px solid #767676"
						borderRadius="6px"
						boxShadow="10px 10px 16px #0F296B1E"
					>
						<Typography fontSize="14px" lineHeight="32px" textAlign={'center'}>
							엑셀러레이팅 <strong style={{ color: '#effd60' }}> 시즌 1이 종료</strong>되었습니다. <br />
							더 많은 아티스트 분들의 활동을 지원하기 위한
							<br />
							<strong style={{ color: '#effd60' }}>시즌 2</strong>로 돌아올 테니 조금만 기다려주세요!
						</Typography>
					</Stack>
				)}

				<Typography fontSize="56px" fontWeight="bold" mb="55px">
					선정혜택
				</Typography>

				<div
					style={{
						minWidth: '1024px',
						maxWidth: '1024px',
						marginBottom: '120px'
					}}
				>
					<Masonry columns={2} spacing="0" sx={{ display: 'flex', minWidth: '1024px', maxWidth: '1024px' }}>
						{BENEFITS_CONTENT.map((benefit, idx) => (
							<BenefitCard key={idx} cardNum={idx + 1}>
								<span className="number">0{idx + 1}</span>
								<span className="title">
									<span style={{ color: '#B760FC' }}>{benefit.title[0]}</span> {benefit.title[1]}
								</span>
								<hr
									style={{
										width: '100%',
										height: '1px',
										backgroundColor: '#EDEDED'
									}}
								/>
								<ul>
									{benefit.contents.map((content, idx) => (
										<li key={idx} className="contents">
											{content}
										</li>
									))}
								</ul>

								{benefit.domain && (
									<span
										style={{
											textAlign: 'end',
											color: '#B760FC'
										}}
									>
										{benefit.domain}
									</span>
								)}

								{idx === 4 && <img width="100%" src="/assets/img/accelerating_benefit.png"></img>}
							</BenefitCard>
						))}
					</Masonry>
				</div>
				<Typography fontSize="56px" fontWeight="bold" mb="80px">
					Recently played artists
				</Typography>
				<Stack direction="column" width="100%" gap="60px" mb="120px">
					<Stack direction="row" justifyContent="space-between" paddingX="5vw">
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_1_test.jpg"></img>
							</div>
							<span className="album-title">just.</span>
							<span className="album-artist">에레미레</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_2.jpg"></img>
							</div>
							<span className="album-title">My Best</span>
							<span className="album-artist">CREEP</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_3.jpg"></img>
							</div>
							<span className="album-title">Time is a DIAMOND</span>
							<span className="album-artist">킴다(KIMDA)</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_4_test.jpg"></img>
							</div>
							<span className="album-title">SEASIDE</span>
							<span className="album-artist">MaddJin</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_5.jpg"></img>
							</div>
							<span className="album-title">Recovered Frequencies</span>
							<span className="album-artist">Demian Love</span>
						</AlbumCard>
					</Stack>
					<Stack direction="row" justifyContent="space-between" paddingX="5vw">
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_6.jpg"></img>
							</div>
							<span className="album-title">What a Day</span>
							<span className="album-artist">wakeupfinn</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_7.jpg"></img>
							</div>
							<span className="album-title">Checkout</span>
							<span className="album-artist">방찬우</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_8.jpg"></img>
							</div>
							<span className="album-title">PANiC</span>
							<span className="album-artist">JU:XN</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_9.png"></img>
							</div>
							<span className="album-title">I got u (feat. 고래 (GORAE))</span>
							<span className="album-artist">COLOR WHITE</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_10.jpg"></img>
							</div>
							<span className="album-title">See more (Prod. wavyn)</span>
							<span className="album-artist">ASEL(아셀)</span>
						</AlbumCard>
					</Stack>
					<Stack direction="row" justifyContent="space-between" paddingX="5vw">
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_11.jpg"></img>
							</div>
							<span className="album-title">Brunette</span>
							<span className="album-artist">oyster</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_12.jpg"></img>
							</div>
							<span className="album-title">Have a good time</span>
							<span className="album-artist">GUE</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_13.jpg"></img>
							</div>
							<span className="album-title">Bleeding</span>
							<span className="album-artist">Ordinary Heroes</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_14.jpeg"></img>
							</div>
							<span className="album-title">착각</span>
							<span className="album-artist">녹음, Scarlett, Glitch Finn</span>
						</AlbumCard>
						<AlbumCard>
							<div className="card">
								<img src="/assets/img/accelerating/6_15.jpg"></img>
							</div>
							<span className="album-title">27 club</span>
							<span className="album-artist">MEEK DRIB</span>
						</AlbumCard>
					</Stack>
				</Stack>
				<Typography fontSize="56px" fontWeight="bold" mb="80px">
					FAQ
				</Typography>
				<FaqWrap>
					{FAQ_LIST.map((faq, idx) => {
						return <Faq key={idx} faq={faq}></Faq>;
					})}
				</FaqWrap>
			</Wrapper>
			<MainFooter />
		</>
	);
};

export default Accelerating;
