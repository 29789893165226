// Login
export const AUTH_LOGIN_ACTION = 'login';
// SignUp
export const AUTH_SIGN_UP_ACTION = 'signup';
// SignUp - GetAuthNumber
export const AUTH_SIGN_UP_GET_NUMBER_ACTION = 'sign_up_get_auth_number';
// SignUp - GetAuthCode
export const AUTH_SIGN_UP_GET_CODE_ACTION = 'sign_up_get_auth_code';
export const AUTH_SIGN_UP_AUTHENTICATION_PHONE_ACTION = 'sign-up-authentication-phone';
export const AUTH_SIGN_UP_AUTHENTICATION_ACCOUNT_ACTION = 'sign-up-authentication-account';

// Terms
export const AUTH_SIGN_UP_TERMS_ACTION = 'terms';
// FindID
export const AUTH_FIND_ID_ACTION = 'find_id';
// FindID - GetAuthNumber
export const AUTH_FIND_ID_GET_NUMBER_ACTION = 'find_id_get_auth_number';
export const AUTH_FIND_ID_AUTHENTICATION_ACTION = 'find_id_authentication';
// FindPassword
export const AUTH_FIND_PASSWORD_ACTION = 'find_password';
// FindPassword - GetAuthNumber
export const AUTH_FIND_PASSWORD_GET_NUMBER_ACTION = 'find_password_get_auth_number';
export const AUTH_FIND_PASSWORD_AUTHENTICATION_ACTION = 'find_password_authentication';
// FindPassword - IsExistId
export const AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION = 'find_password_is_exist_id';
// DormantAccount
export const AUTH_DORMANT_ACCOUNT_ACTION = 'dormant-account';
// Logout
export const AUTH_LOGOUT_ACTION = 'logout';
// EditEmail
export const USER_INFO_EDIT_EMAIL_ACTION = 'user-info-edit-email';
// UserInfoSetting - EditPhoneNumber
export const USER_INFO_EDIT_PHONE_NUMBER_ACTION = 'user-info-edit-phone-number';
// UserInfoSetting - EditAccount
export const USER_INFO_EDIT_DORMANT_ACCOUNT_ACTION = 'user-info-edit-dormant-account';
// GETUserInfo
export const USER_INFO_GET_ACTION = 'user-info-get';

// Add Profile
export const PROFILE_INFO_ADD_ACTION = 'profile-info-add';
// Update Profile
export const PROFILE_INFO_UPDATE_ACTION = 'profile-info-update';

// AlbumManagement
export const ALBUM_LIST_ACTION = 'album-list';
export const ALBUM_INFO_REGISTRATION_ACTION = 'album-info-registration';
export const MUSIC_VEDIO_INFO_ADD_ACTION = 'music-video-info-add';

export const CODE_LIST_BANK_ACTION = 'code-list-bank';
export const CODE_LIST_GENDER_ACTION = 'code-list-gender';
export const CODE_LIST_GENRE_ACTION = 'code-list-genre';
export const CODE_LIST_ARTIST_TYPE_ACTION = 'code-list-artist-type';
export const CODE_LIST_ARTIST_ROLE_ACTION = 'code-list-artist-role';
export const CODE_LIST_LANGUAGE_ACTION = 'code-list-language';
export const CODE_LIST_ALBUM_TYPE_ACTION = 'code-list-album-type';
export const CODE_LIST_COUNTRY_ACTION = 'code-list-country';
export const CODE_LIST_TRACK_ARTIST_ROLE_ACTION = 'code-list-track-artist-role';

export const ARTIST_LIST_ACTION = 'artist-list';
export const ALBUM_INFO_REGISTRATION_TRACK_INFO_ADD_ACTION = 'album-info-registration-track-info-add';
export const ALBUM_INFO_SCHEDULE_LIST_ACTION = 'album-info-schedule-list';
export const DSP_LIST_ACTION = 'dsp_list_action';
