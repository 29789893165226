import { AudioFileRounded } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { Icon } from '@images/Icon';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

export const ItemTypes = {
  CARD: 'card',
};

const TrackCard = ({
  id,
  trackInfo,
  text,
  index,
  moveCard,
  deleteTrack,
  preview,
  setSelectedTrackNumber,
  wavfile,
  changeFile,
}: any) => {
  const [audio, setAudio] = useState<any>(null);
  const [play, setPlay] = useState<boolean>(false);
  const ref = useRef<any>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({
      id,
      index,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const changeWavFile = ({ target }) => {
    changeFile(target.files, index);
  };

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  useEffect(() => {
    if (preview) {
      setAudio(new Audio(preview));
    }
  }, [preview]);

  useEffect(() => {
    if (play) {
      audio?.play();
      audio.onended = function () {
        audio.pause();
      };
    } else {
      audio?.pause();
    }
  }, [play]);

  return (
    <Stack
      direction="row"
      //justifyContent='space-between'
      alignItems="center"
      data-handler-id={handlerId}
      ref={ref}
      mb={4.5}
      sx={{
        width: '100%',

        background: '#222222',
        borderRadius: '10px',
        opacity,
        color: '#fff',
      }}
    >
      <Stack direction="row" alignItems="center" spacing={4} p={4.5} sx={{ flex: 1 }}>
        <Icon
          icon="iconList"
          style={{
            width: '24px',
            height: '24px',
          }}
        />

        <Stack direction="row" spacing={4} alignItems="center">
          <Text
            style={{
              fontSize: '16px',
              fontWeight: 700,
              color: '#fff',
              lineHeight: '42px',
            }}
            textType="DESCRIPTION"
          >
            {index + 1}.
          </Text>

          <Stack spacing={1} sx={{ width: '380px' }}>
            <Stack direction="row" spacing={3}>
              <Tooltip title={trackInfo?.track_name || text}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 700,
                    color: '#fff',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                    overflow: 'hidden',
                    maxWidth: '230px',
                    whiteSpace: 'nowrap',
                    fontFamily: 'Pretendard',
                  }}
                >
                  {trackInfo?.track_name || text}
                </Typography>
              </Tooltip>
              <Stack direction="row" alignItems="center" spacing={1}>
                {trackInfo?.is_main && (
                  <Icon
                    icon="iconFlagTitle"
                    style={{
                      width: '57px',
                      height: '24px',
                    }}
                  />
                )}
                {trackInfo?.is_adult && (
                  <Icon
                    icon="iconFlag19"
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                )}
                {trackInfo?.track_video?.length > 0 && (
                  <Chip
                    label="MV"
                    sx={{
                      backgroundColor: '#EFFD60',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      height: '24px',
                      padding: '0 0',
                    }}
                  />
                )}
              </Stack>
            </Stack>

            <Text
              style={{
                color: trackInfo?.track_artist?.[0]?.national ? '#FFFFFF' : '#FFFFFF80',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '500px',
                whiteSpace: 'nowrap',
              }}
              textType="DESCRIPTION"
            >
              {trackInfo?.track_artist?.[0]?.national || '아티스트명'}
            </Text>
          </Stack>
        </Stack>

        <Box sx={{ flex: 1 }}>{wavfile ? wavfile.name : 'No Music File'}</Box>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '250px' }}>
          <Tooltip open={!preview} title="음원 파일이 없습니다.">
            <IconButton
              size="small"
              component="label"
              disabled={!preview}
              onClick={() => {
                if (!!preview) {
                  setPlay((prev: boolean) => !prev);
                }
              }}
              sx={{
                border: '#FFF solid 0px',
                background: '#454545',
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                color: '#FFF',
              }}
            >
              {!play ? <PlayArrowIcon /> : <PauseIcon />}
            </IconButton>
          </Tooltip>

          <Stack direction="row" spacing={1.5}>
            {!!preview && (
              <Tooltip title="트랙 상세 정보 입력">
                <IconButton
                  size="small"
                  component="label"
                  onClick={() => setSelectedTrackNumber(index)}
                  sx={{
                    border: '#FFF solid 1px',
                    borderRadius: '50%',
                    width: '32px',
                    height: '32px',
                    color: '#FFF',
                  }}
                >
                  <DriveFileRenameOutlineOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="음원 파일 변경">
              <IconButton
                size="small"
                component="label"
                sx={{
                  border: '#FFF solid 1px',
                  borderRadius: '50%',
                  width: '32px',
                  height: '32px',
                  color: '#FFF',
                }}
              >
                <input hidden accept="audio/wav" type="file" onChange={changeWavFile} />
                <AudioFileRounded fontSize="inherit" />
              </IconButton>
            </Tooltip>

            <Tooltip title="트랙 삭제">
              <IconButton
                size="small"
                component="label"
                onClick={() => deleteTrack(id)}
                sx={{
                  border: '#FFF solid 1px',
                  borderRadius: '50%',
                  width: '32px',
                  height: '32px',
                  color: '#FFF',
                }}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Stack>
          {trackInfo?.track_artist?.length && preview ? (
            <CheckCircleRoundedIcon sx={{ color: '#1bcf92' }} fontSize="large" />
          ) : (
            <Tooltip title="트랙 상세 정보를 입력해 주세요.">
              <ErrorRoundedIcon sx={{ color: '#ff6465' }} fontSize="large" />
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TrackCard;
