import { TCommonCode } from '@/types/common';
import { getCodeList } from '@api/mapper/Code';
import { ALBUM_INFO_REGISTRATION_ACTION, CODE_LIST_COUNTRY_ACTION } from '@constants/actionNames';
import { useReduxData } from '@hooks/useReduxData';
import { useRequest } from '@hooks/useRequest';
import { TextField } from '@mui/material';
import { isUndefined, find, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';

type TCoreValue = 'id' | 'code_name';
type TSelectedCodeValue = Pick<TCommonCode, TCoreValue>;

const ExcludedServiceCountries = ({}) => {
	// @ts-ignore
	const {
		requestForSingle: getCountryCodeListReq,
		resData: getCountryCodeListRes
	}: { requestForSingle: any; resData: TCommonCode[] } = useRequest({
		reduxActionName: CODE_LIST_COUNTRY_ACTION,
		mapperFunc: getCodeList
	});

	const { data: albumInfoData, setData: setAlbumInfoData } = useReduxData(ALBUM_INFO_REGISTRATION_ACTION);
	const [selected, setSelected] = useState<TSelectedCodeValue[] | []>([]);

	useEffect(() => {
		getCountryCodeListReq({ parent_code: 'country' });
	}, []);

	useEffect(() => {
		if (!isUndefined(getCountryCodeListRes) && !isEmpty(albumInfoData?.excludedCountries)) {
			const updateState = albumInfoData.excludedCountries.map((id) => {
				const target = find(getCountryCodeListRes, { id: id });
				return {
					id: target!.id,
					code_name: target!.code_name
				};
			});
			setSelected(updateState);
		}
	}, [getCountryCodeListRes]);

	const handleSelect = (e, newValue) => {
		const updateState = newValue.map((selectValues) => {
			return JSON.stringify({ id: Number(selectValues.id), code_name: selectValues.code_name });
		});
		// @ts-ignore
		const selectedData = [...new Set(updateState)].map((v) => JSON.parse(v as string));
		const ids = selectedData.map((item) => item.id);
		setSelected(selectedData);
		setAlbumInfoData({
			...albumInfoData,
			excludedCountries: ids
		});
	};

	return (
		<>
			<h4 style={{ color: 'gray', margin: '5px' }}>발매를 원하지 않는 국가를 아래에 입력해 주세요</h4>
			{/* 장르선택 */}
			<div style={{ background: '#f5f5f5', width: '100%', padding: '40px 15px 40px' }}>
				<span
					style={{ color: '#5b5b5b', float: 'left', lineHeight: '56px', marginRight: '15px', fontWeight: 'normal' }}
				>
					서비스 제외 국가
				</span>
				<Autocomplete
					style={{ width: '300px', float: 'left' }}
					multiple
					id="aao"
					value={selected}
					options={isUndefined(getCountryCodeListRes) ? [] : [...getCountryCodeListRes]}
					getOptionLabel={(option: any) => option.code_name}
					onChange={(e, newValue) => handleSelect(e, newValue)}
					renderInput={(params) => <TextField {...params} placeholder="서비스 제외 국가 선택" />}
				/>
			</div>
			{/* note: 기획자 요구 시 태그 구현  */}
			{/* {selected.length */}
			{/* 	? (selected as TSelectedCodeValue[]).map((item) => ( */}
			{/* 			<div key={item.id}> */}
			{/* 				<Row */}
			{/* 					justify="FLEX_START" */}
			{/* 					style={{ */}
			{/* 						minWidth: 'fit-content', */}
			{/* 						flex: 0, */}
			{/* 						color: '#121212', */}
			{/* 						fontSize: '12px', */}
			{/* 						fontWeight: 400, */}
			{/* 						lineHeight: '20px', */}
			{/* 						border: '1px solid #121212', */}
			{/* 						borderRadius: '20px', */}
			{/* 						padding: '6px 8px 6px 16px' */}
			{/* 						// marginRight: '8px', */}
			{/* 					}} */}
			{/* 				> */}
			{/* 					{item.code_name} */}
			{/* 					<VDivider margin="4px" type="TRANSPARENT" /> */}
			{/* 					<Icon */}
			{/* 						icon="btnCloseArtist" */}
			{/* 						onClick={() => deleteSelectedCountry(item.id)} */}
			{/* 						style={{ */}
			{/* 							width: '20px', */}
			{/* 							height: '20px', */}
			{/* 							cursor: 'pointer' */}
			{/* 						}} */}
			{/* 					/> */}
			{/* 				</Row> */}
			{/* 			</div> */}
			{/* 		)) */}
			{/* 	: ''} */}
		</>
	);
};
export default ExcludedServiceCountries;
