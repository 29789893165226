/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import { TextField } from '@mui/material';

import Select from '@mui/material/Select';
import { withStyles } from '@mui/styles';
import React from 'react';

// import React, { useState } from 'react';

const outlinedInputStyles = {
  root: {
    '& .MuiInputBase-root': {
      height: 50,
      fontSize: 13,
      fontFamily: 'Pretendard',
      color: '#121212',
    },
    '& input::placeholder': {
      color: '#333 !important',
    },
    '& label.Mui-focused': {
      fontSize: '12px',
      color: '#121212',
    },

    '& .MuiInputBase-input': {
      padding: 15,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',

      '& fieldset': {
        borderRadius: '10px',
        borderColor: '#dcdcdc',
      },

      // '&:hover fieldset': {
      //   borderColor: 'yellow',
      // },
      '&.Mui-focused fieldset': {
        borderColor: '#121212',
        fontSize: '12px',
      },
    },
  },
};

const TTextField = ({ children, ...rest }: any) => {
  return (
    <TextField
      inputProps={{ ...rest.inputProps, autoComplete: 'new-password' }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      {...rest}
      size="small"
    >
      {children}
    </TextField>
  );
};
export default withStyles(outlinedInputStyles)(TTextField);
// export default TTextField;
