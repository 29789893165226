import { DEFAULT_TABLE_PAGE, DEFAULT_TABLE_SIZE } from '@constants/table';
import { apiRequest } from '@utils/api';

export const getArtistList = async (params: any) => {
  const { page = DEFAULT_TABLE_PAGE, limit = DEFAULT_TABLE_SIZE, user, orderBy, keyword } = params;
  const reqData: any = {
    page,
    limit,
    user,
    keyword,
  };

  if (orderBy) {
    reqData.orderBy = orderBy;
  }

  if (keyword) {
    reqData.keyword = keyword;
  }

  const result = await apiRequest({
    url: `/artists`,
    method: 'GET',
    params: reqData,
  });

  return result;
};

export const addMusicVideo = async (params: any) => {
  const result = await apiRequest({
    url: `/album/musicVideo`,
    method: 'POST',
    params,
  });

  return result;
};

export const addAlbumInfo = async (params: any) => {
  const result = await apiRequest({
    url: `/album/info`,
    method: 'POST',
    params,
  });

  return result;
};
