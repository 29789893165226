import { useEffect, useState } from 'react';

import { getNestedObjects, setNestedObjects } from '@utils/nestedObjects';

type TUseFormError = {
  formFieldsError?: Record<string, any>;
  validation?: (name: string | number | (string | number)[], value: any, formData: any) => Record<string, any>;
};

type TUseFormErrorReturn = {
  formError: Record<string, any>;
  checkFormError: (name: string | number | (string | number)[], value: any, formData: any) => void;
};

export function useFormError({ formFieldsError, validation }: TUseFormError): TUseFormErrorReturn {
  const [formError, setFormError] = useState<Record<any, any>>({});

  function checkFormError(name: string | number | (string | number)[], value: any, formData: any): void {
    if (validation) {
      const message = getNestedObjects(name, validation(name, value, formData));

      setFormError(setNestedObjects(name, message, formError));
    }
  }

  useEffect(() => {
    if (formFieldsError) {
      setFormError(formFieldsError);
    }
  }, [formFieldsError]);

  return {
    formError,
    checkFormError,
  };
}
