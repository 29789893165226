import ExceptConfirm from '@containers/AlbumManagement/ConfirmModal/ExceptConfirm';
import ReleasePlatform from '@containers/AlbumManagement/ConfirmModal/ReleasePlatform';
import { BackdropContext } from '@hooks/usePopupMessage';
import { Tooltip, Typography } from '@mui/material';
import date from 'date-and-time';

import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';

import Checkbox from '@components/form/Checkbox';
import HDivider from '@components/form/HDivider';
import Modal from '@components/form/Modal';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { Footer } from '@components/molecules/AlbumManagement/FullScreenModal';
import TrackTable from '@components/molecules/AlbumManagement/TrackTable';
import { NextButton } from '@components/molecules/SignUp/Button';
import TermsBox, { TermsBox2 } from '@components/molecules/SignUp/TermsBox';
import { ALBUM_INFO_REGISTRATION_ACTION } from '@constants/actionNames';
import { useReduxData } from '@hooks/useReduxData';
import { Icon } from '@images/Icon';
import ExcludedServiceCountries from './ExcludedServiceCountries';

export type TCurrentStep = 'SELECT_TRACK' | 'INFO' | 'COMPLETED';

function ConfirmModal({ isOpenConfirmModal, setIsOpenConfirmModal, callback }) {
	const { parameters: formData, setParameters: setFormData } = useReduxData(ALBUM_INFO_REGISTRATION_ACTION);

	const columns = useMemo(
		() => [
			{
				accessor: 'is_main',
				Header: 'TITLE',
				Cell: (value: any) => {
					const { cell, row } = value;

					return (
						<Row
							justify="CENTER"
							style={{
								minWidth: '40px',
								maxWidth: '40px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap'
							}}
						>
							{cell?.value ? (
								<Icon
									icon="iconCheck"
									style={{
										width: '12px',
										height: '8px'
									}}
								/>
							) : (
								''
							)}
						</Row>
					);
				}
			},

			{
				accessor: 'track_name',
				Header: '트랙명',
				Cell: (value: any) => {
					const { cell, row } = value;
					return (
						<Row
							justify="CENTER"
							style={{
								minWidth: '140px',
								maxWidth: '140px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap'
							}}
						>
							{cell?.value ?? ''}
						</Row>
					);
				}
			},
			{
				accessor: 'track_artist',
				Header: '아티스트명',
				Cell: (value: any) => {
					const { cell, row } = value;

					return (
						<Row
							justify="CENTER"
							style={{
								minWidth: '90px',
								maxWidth: '90px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap'
							}}
						>
							{cell.value
								?.filter((item: any) => item.role_id === 90)
								.reduce((a, c) => `${a} , ${c.artist_name}`, '')
								.replace(/^\s*,/, '')}
						</Row>
					);
				}
			},
			{
				accessor: 'track_writer',
				Header: '작사',
				Cell: (value: any) => {
					const { cell, row } = value;
					return (
						<Row
							justify="CENTER"
							style={{
								minWidth: '90px',
								maxWidth: '90px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap'
							}}
						>
							{row?.values.track_artist
								?.filter((item: any) => item.role_id === 97)
								.reduce((a, c) => `${a} , ${c.artist_name}`, '')
								.replace(/^\s*,/, '')}
						</Row>
					);
				}
			},
			{
				accessor: 'track_composer',
				Header: '작곡',
				Cell: (value: any) => {
					const { cell, row } = value;

					return (
						<Row
							justify="CENTER"
							style={{
								minWidth: '90px',
								maxWidth: '90px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap'
							}}
						>
							{row?.values.track_artist
								?.filter((item: any) => item.role_id === 96)
								.reduce((a, c) => `${a} , ${c.artist_name}`, '')
								.replace(/^\s*,/, '')}
						</Row>
					);
				}
			},
			{
				accessor: 'track_arranger',
				Header: '편곡',
				Cell: (value: any) => {
					const { cell, row } = value;
					return (
						<Row
							justify="CENTER"
							style={{
								minWidth: '90px',
								maxWidth: '90px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap'
							}}
						>
							{row?.values.track_artist
								?.filter((item: any) => item.role_id === 98)
								.reduce((a, c) => `${a} , ${c.artist_name}`, '')
								.replace(/^\s*,/, '')}
						</Row>
					);
				}
			},

			{
				accessor: 'track_video',
				Header: 'MV',
				Cell: (value: any) => {
					const { cell, row } = value;

					return (
						<Row
							justify="CENTER"
							style={{
								minWidth: '40px',
								maxWidth: '40px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap'
							}}
						>
							{!!cell?.value?.length && cell?.value?.length > 0 ? (
								<Icon
									icon="iconCheck"
									style={{
										width: '12px',
										height: '8px'
									}}
								/>
							) : (
								''
							)}
						</Row>
					);
				}
			}
		],
		[]
	);

	const getPrevNextPrevButtonStyle = (isDisabled: boolean): Record<string, any> => {
		if (isDisabled) {
			return {
				STAT: {
					PREV: {},
					NEXT: {
						width: '128px',
						height: '52px',
						background: '#DEDEDE'
					}
				}
			};
		}
		return {
			STAT: {
				PREV: {},
				NEXT: {
					width: '128px',
					height: '52px',
					background: '#121212'
				}
			}
		};
	};

	const setGuideOfUse = (dataIndex: string, checked: boolean) => {
		if (dataIndex === 'guideAll') {
			let newTermsData = {
				guideAll: false,
				guide1: false,
				guide3: false,
				guide4: false
			};

			if (checked) {
				newTermsData = {
					guideAll: true,
					guide1: true,
					guide3: true,
					guide4: true
				};
			}
			setFormData({ ...formData, ...newTermsData });
		}

		if (dataIndex !== 'guideAll') {
			const newTermsData = {
				...formData,
				[dataIndex]: checked
			};
			newTermsData.guideAll = newTermsData.guide1 && newTermsData.guide3 && newTermsData.guide4;
			setFormData({ ...formData, ...newTermsData });
		}
	};

	const { ctf_sendHttpGet } = useContext(BackdropContext);

	const [uc_genreCodes, uf_setGenreCodes] = useState([]);

	const callGenreCodes = async () => {
		const res = await ctf_sendHttpGet('/codes', { parent_code: 'genre' }, false);
		if (res.error) {
			return [];
		}
		if (!res.error) {
			uf_setGenreCodes(res.data);
		}
	};

	useEffect(() => {
		callGenreCodes();
	}, []);

	const [uc_submitBtnStat, uf_setSubmitBtnStat] = useState(false);
	useEffect(() => {
		uf_setSubmitBtnStat(formData.guide1 === true && formData.guide3 === true);
	}, [formData]);

	const header = (
		<Row justify="SPACE_BETWEEN">
			<Row
				justify="FLEX_START"
				style={{
					flex: 0.55,
					fontSize: '20px',
					fontWeight: 700,
					color: '#121212'
				}}
			>
				발매 주요 정보 확인
			</Row>
			<VDivider margin="52px" type="TRANSPARENT" />
			<Row
				justify="FLEX_START"
				style={{
					flex: 0.45,
					fontSize: '20px',
					fontWeight: 700,
					color: '#121212'
				}}
			>
				mixtape. 유통 안내사항
			</Row>
		</Row>
	);

	const getReleaseDateTxt = () => {
		const rd = new Date(formData.releaseDate);
		const dDay = new Date(date.format(rd, `YYYY-MM-DD`) + ` ${formData.selectedTime}:00:00`);

		const txt = date.format(dDay, 'YYYY.MM.DD (dd) A hh시 발매').replace('AM', '오전').replace('PM', '오후');
		const wd = [
			{
				en: 'Mo',
				ko: '월요일'
			},
			{
				en: 'Tu',
				ko: '화요일'
			},
			{
				en: 'We',
				ko: '수요일'
			},
			{
				en: 'Th',
				ko: '목요일'
			},
			{
				en: 'Fr',
				ko: '금요일'
			},
			{
				en: 'Sa',
				ko: '토요일'
			},
			{
				en: 'Su',
				ko: '일요일'
			}
		];
		const ret = wd.reduce((a, c) => a.replace(c.en, c.ko), txt);
		return ret;
	};

	const finishCheck = () => {
		setIsOpenConfirmModal();
		callback();
	};

	return (
		<Modal
			header={header}
			hide={setIsOpenConfirmModal}
			hideCloseButton
			isShown={isOpenConfirmModal}
			modalStyle={{
				width: '1200px',
				background: '#FFFFFF',
				height: '100%',
				borderRadius: '10px'
			}}
			wrapperStyle={{
				width: '1200px',
				background: '#FFFFFF',
				height: '90%',
				borderRadius: '10px'
			}}
		>
			<Row justify="SPACE_BETWEEN">
				<Row justify="FLEX_START">
					<Row
						justify="FLEX_START"
						style={{
							marginTop: '18px',
							flexDirection: 'column',
							flex: 0.55,
							fontSize: '20px',
							fontWeight: 700,
							color: '#121212'
						}}
					>
						<HDivider margin="9px" type="TRANSPARENT" />
						<Text textType="DESCRIPTION">발매 신청 완료 후 정보 수정 요청 시 시간이 오래 걸릴 수 있습니다.</Text>
						<Text textType="DESCRIPTION">최종 자료를 꼼꼼하게 검수한 후 '발매신청' 버튼을 눌러 주세요.</Text>
						<HDivider margin="17px" type="TRANSPARENT" />
						<Text style={{ fontSize: '16px' }} textType="TITLE">
							{getReleaseDateTxt()}
						</Text>
						<HDivider margin="9px" type="TRANSPARENT" />
						<Row justify="SPACE_BETWEEN" style={{ width: '100%' }}>
							<Row justify="FLEX_START">
								<Row justify="FLEX_START" style={{ flex: 0.4 }}>
									<Icon
										url={formData.album_cover?.length ? formData.album_cover[0].preview : null}
										style={{
											width: '240px',
											height: '240px',
											borderRadius: '10px'
										}}
									/>
								</Row>
								<VDivider margin="20px" type="TRANSPARENT" />
								<Row justify="FLEX_START" style={{ flexDirection: 'column' }}>
									<Text
										style={{
											fontSize: '14px',
											fontWeight: 700,
											padding: 0
										}}
									>
										앨범명
									</Text>
									<HDivider margin="4px" type="TRANSPARENT" />
									<Row
										justify="FLEX_START"
										style={{
											width: '100%',
											gap: '12px'
										}}
									>
										<Text textType="DESCRIPTION">국내</Text>

										{/* <Text style={{ color: '#121212' }} textType='DESCRIPTION'> */}
										{/*   {formData?.album_name} */}
										{/* </Text> */}
										<Tooltip title={formData?.album_name}>
											<Typography
												sx={{
													fontSize: '14px',
													fontWeight: 400,
													color: '#121212',
													textOverflow: 'ellipsis',
													wordBreak: 'break-all',
													overflow: 'hidden',
													maxWidth: '250px',
													whiteSpace: 'nowrap',
													fontFamily: 'Pretendard'
												}}
											>
												{formData?.album_name}
											</Typography>
										</Tooltip>
									</Row>
									<HDivider margin="4px" type="TRANSPARENT" />
									<Row
										justify="FLEX_START"
										style={{
											width: '100%',
											gap: '12px'
										}}
									>
										<Text textType="DESCRIPTION">해외</Text>

										<Text style={{ color: '#121212' }} textType="DESCRIPTION">
											{formData?.album_name_foriegn}
										</Text>
									</Row>
									<HDivider margin="12px" type="TRANSPARENT" />
									<Text
										style={{
											fontSize: '14px',
											fontWeight: 700,
											padding: 0
										}}
										textType="DESCRIPTION"
									>
										앨범 아티스트
									</Text>
									<HDivider margin="4px" type="TRANSPARENT" />
									<Row
										justify="FLEX_START"
										style={{
											width: '100%',
											gap: '12px'
										}}
									>
										<Text textType="DESCRIPTION">국내</Text>
										<Text style={{ color: '#121212' }} textType="DESCRIPTION">
											{formData?.artist?.reduce((a, c) => `${a} , ${c.label}`, '').replace(/^\s*,/, '')}
										</Text>
									</Row>
									<HDivider margin="4px" type="TRANSPARENT" />
									<Row
										justify="FLEX_START"
										style={{
											width: '100%',
											gap: '12px'
										}}
									>
										<Text textType="DESCRIPTION">해외</Text>

										<Text style={{ color: '#121212' }} textType="DESCRIPTION">
											{formData.artist?.reduce((a, c) => `${a} , ${c.foreign}`, '').replace(/^\s*,/, '')}
										</Text>
									</Row>
									<HDivider margin="12px" type="TRANSPARENT" />
									<Row justify="SPACE_BETWEEN" style={{ width: '100%' }}>
										<Row
											justify="FLEX_START"
											style={{
												flex: 0.5,
												flexDirection: 'column'
											}}
										>
											<Text
												style={{
													fontSize: '14px',
													fontWeight: 700,
													padding: 0
												}}
												textType="DESCRIPTION"
											>
												장르
											</Text>
											<HDivider margin="4px" type="TRANSPARENT" />
											<Text
												style={{
													fontSize: '14px',

													padding: 0
												}}
												textType="DESCRIPTION"
											>
												{formData.genre_id
													?.map((ele) => {
														const obj = uc_genreCodes.find((cd) => cd.id === ele);
														return obj?.code_name ? obj.code_name : '';
													})
													.join(', ')}
											</Text>
										</Row>
										<VDivider margin="12px" type="TRANSPARENT" />
										<Row
											justify="FLEX_START"
											style={{
												flex: 0.5,
												flexDirection: 'column'
											}}
										>
											<Text
												style={{
													fontSize: '14px',
													fontWeight: 700,
													padding: 0
												}}
												textType="DESCRIPTION"
											>
												앨범 타입
											</Text>
											<HDivider margin="4px" type="TRANSPARENT" />
											<Text
												style={{
													fontSize: '14px',

													padding: 0
												}}
												textType="DESCRIPTION"
											>
												{formData?.type_id?.label}
											</Text>
										</Row>
									</Row>
								</Row>
							</Row>
						</Row>
						<HDivider margin="14px" type="TRANSPARENT" />
						<Text style={{ fontSize: '16px' }} textType="TITLE">
							TRACK LIST
						</Text>
						<HDivider margin="9px" type="TRANSPARENT" />
						<div>
							<TrackTable columns={columns} data={formData.track?.map((t: any) => t.info)} />
						</div>
					</Row>
					<VDivider margin="40px" type="TRANSPARENT" />
					<Row
						justify="FLEX_START"
						style={{
							flexDirection: 'column',
							flex: 0.45,
							fontSize: '15px',
							fontWeight: 700,
							color: '#121212',
							overflow: 'hidden',
							paddingBottom: '112px'
						}}
					>
						<HDivider margin="18px" type="TRANSPARENT" />
						<Checkbox
							checked={formData.guideAll}
							id="all"
							label="소유권 등 권리 분쟁 안내, 권리 분쟁 안내, 타사 발매 음원 재발매 안내, 발매 국가 및 플랫폼 안내(선택)에 모두 동의합니다."
							onChange={(e) => {
								setGuideOfUse('guideAll', e.target.checked);
							}}
						/>
						<HDivider margin="15px" type="TRANSPARENT" />
						<Checkbox
							checked={formData.guide1}
							id="use"
							label={
								<>
									<span>소유권 등 권리 분쟁 안내</span>
									<span style={{ color: '#ff6465' }}> (필수)</span>
								</>
							}
							onChange={(e) => {
								setGuideOfUse('guide1', e.target.checked);
							}}
						/>
						<HDivider margin="5px" type="TRANSPARENT" />
						<TermsBox2 style={{ height: '160px ' }}>
							<iframe
								style={{ border: 'none' }}
								width="100%"
								marginWidth={30}
								height="100%"
								src={process.env.REACT_APP_URL + '/docs/ownership.html'}
							/>
						</TermsBox2>
						<HDivider margin="15px" type="TRANSPARENT" />
						<Checkbox
							checked={formData.guide3}
							id="guide3"
							label={
								<>
									<span>타사 발매 음원 재발매 안내 동의</span>
									<span style={{ color: '#ff6465' }}> (필수)</span>
								</>
							}
							onChange={(e) => {
								setGuideOfUse('guide3', e.target.checked);
							}}
						/>
						<HDivider margin="5px" type="TRANSPARENT" />
						<TermsBox2 style={{ height: '160px ' }}>
							<iframe
								style={{ border: 'none' }}
								width="100%"
								marginWidth={30}
								height="100%"
								src={process.env.REACT_APP_URL + '/docs/remake.html'}
							/>
						</TermsBox2>
						<HDivider margin="15px" type="TRANSPARENT" />
						<Checkbox
							checked={formData.guide4}
							id="guide4"
							label={<span>mixtape. 발매 국가 및 플랫폼 안내 (선택)</span>}
							onChange={(e) => {
								setGuideOfUse('guide4', e.target.checked);
							}}
						/>
						<HDivider margin="5px" type="TRANSPARENT" />
						<TermsBox2 style={{ height: '160px ' }}>
							<iframe
								style={{ border: 'none' }}
								width="100%"
								marginWidth={30}
								height="100%"
								src={process.env.REACT_APP_URL + '/docs/release.html'}
							/>
						</TermsBox2>
						<HDivider margin="15px" type="TRANSPARENT" />
						<ReleasePlatform allCheckedToPlatform={formData.guide4 || false} />
						<HDivider margin="15px" type="TRANSPARENT" />
						<ExcludedServiceCountries />
						<HDivider margin="15px" type="TRANSPARENT" />
					</Row>
				</Row>
			</Row>

			<Footer
				style={{
					background: '#fff',
					height: '112px',
					borderRadius: '0 0 10px 10px'
				}}
			>
				<Row justify="CENTER">
					<NextButton
						onClick={() => setIsOpenConfirmModal()}
						style={{
							height: '52px',
							width: '128px',
							border: '1px solid #777777',
							background: 'transparent',
							color: '#121212'
						}}
					>
						취소
					</NextButton>
					<VDivider margin="5px" type="TRANSPARENT" />
					<NextButton
						disabled={!uc_submitBtnStat}
						onClick={finishCheck}
						style={getPrevNextPrevButtonStyle(!uc_submitBtnStat).STAT.NEXT}
					>
						발매신청
					</NextButton>
				</Row>
			</Footer>
		</Modal>
	);
}

export default ConfirmModal;
