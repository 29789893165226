import React, { useEffect, useState } from 'react';

import Modal from '@components/form/Modal';
import {
  AUTH_FIND_PASSWORD_ACTION,
  AUTH_FIND_PASSWORD_GET_NUMBER_ACTION,
  AUTH_FIND_PASSWORD_IS_EXIST_ID_ACTION,
} from '@constants/actionNames';
import AuthNumberCertification from '@containers/UserInfoSetting/EditPasswordModal/AuthNumberCertification';
import Completed from '@containers/UserInfoSetting/EditPasswordModal/Completed';
import ResetPassword from '@containers/UserInfoSetting/EditPasswordModal/ResetPassword';
import { useReduxData } from '@hooks/useReduxData';
import { $mixtapeGetUser } from '@utils/auth';
import { Button, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import MixButton from '@components/ui/MixButton';
import history from '@utils/history';
import { useModal } from '@hooks/useModal';

export type TCurrentStep = 'AUTH_NUMBER' | 'RESET' | 'COMPLETED';

type TMembershipBenefitModal = {
  setIsOpenMembershipBenefitModal: () => void;
  isOpenMembershipBenefitModal: boolean;
  callback: () => void;
};

const checkStyle = {
  marginRight: '16px',
  color: '#D4D4D8',
  fontSize: '18px',
  fontWeight: 600,
};

const MembershipBenefitModal = ({
  isOpenMembershipBenefitModal,
  setIsOpenMembershipBenefitModal,
  callback,
}: TMembershipBenefitModal) => {
  return (
    <Modal
      modalStyle={{ width: '660px' }}
      closeButtonStyle={{ width: '24px', heigth: '24px' }}
      hide={setIsOpenMembershipBenefitModal}
      isShown={isOpenMembershipBenefitModal}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" padding="15px">
        <span style={{ fontSize: '34px', fontWeight: 600, marginBottom: '50px' }}>mixtape. 멤버십 혜택 안내</span>

        <Typography fontSize="18px" lineHeight="30px" mb="30px" color="#191919">
          <span style={checkStyle}>✓</span>유통 수수료 0% <br />
          <span style={checkStyle}>✓</span>자유로운 발매 일자 및 시간 선택
          <br />
          <span style={checkStyle}>✓</span>YouTube Music, Spotify, Apple Music 등 <br />
          <span style={{ marginLeft: '31px' }}>전 세계 음악 플랫폼 발매</span>
          <br />
          <span style={checkStyle}>✓</span>Instagram, Facebook, TikTok 등 소셜미디어 채널 발매
          <br />
        </Typography>

        <span style={{ fontSize: '24px', fontWeight: 600, marginBottom: '50px' }}>
          <span style={{ color: '#FF3120' }}>해지 </span>하시겠어요?
        </span>

        <Stack direction="row" gap="22px" mb="35px">
          <MixButton
            onClick={() => {
              setIsOpenMembershipBenefitModal();
            }}
            text="닫기"
            butonType="OUTLINE"
            padding="22px 124px"
            bgColor="white"
            fontColor="black"
          />
          <MixButton
            onClick={() => {
              callback();
            }}
            text="해지"
            padding="22px 124px"
            bgColor="#000000"
            fontColor="white"
          />
        </Stack>
      </Stack>
    </Modal>
  );
};

export default MembershipBenefitModal;
