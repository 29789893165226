import React, { HTMLAttributes, ReactElement } from 'react';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

type ThandleCheckTextTypeParams = undefined | 'TITLE' | 'DESCRIPTION' | 'HEADER3';

interface ITextParams extends HTMLAttributes<HTMLSpanElement> {
  textType?: ThandleCheckTextTypeParams;
}

const handleCheckRowType = (textType: ThandleCheckTextTypeParams) => {
  switch (textType) {
    case 'TITLE': {
      return css`
        font-family: Pretendard;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
      `;
    }
    case 'DESCRIPTION': {
      return css`
        font-family: Pretendard;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #5b5b5b;
      `;
    }
    case 'HEADER3': {
      return css`
        font-family: Pretendard;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #000;
      `;
    }
    default:
      return css`
        font-family: Pretendard;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #5b5b5b;
      `;
  }
};

const StyledText = styled.span<ITextParams>`
  white-space: pre-line;
  ${({ textType }) => handleCheckRowType(textType)}
`;

const Text = ({ ...rest }: ITextParams): ReactElement => <StyledText {...rest} />;

export default Text;
