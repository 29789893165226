import AlertDialog from '@components/form/AlertDialog';

import MusicVideoAddDialog from '@components/form/MusicVideoAddDialog';

import TTextField from '@components/form/TTextField';

import { BackdropContext, usePopupMessage } from '@hooks/usePopupMessage';

import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react';

import { login } from '@api/mapper/User';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';

import Label from '@components/form/Label';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';

import AuthHeader from '@components/molecules/AuthHeader';
import ErrorMessage from '@components/molecules/Core/ErrorMessage';
import { FindButton, LoginButton, SignUpButton } from '@components/molecules/Login/Button';
import { LoginLayout } from '@components/molecules/Login/Layout';
import { AUTH_LOGIN_ACTION } from '@constants/actionNames';
import { routePath } from '@constants/path';
import ChangePasswordModal from '@containers/ChangePassword';
import DormantAccountModal from '@containers/DormantAccount';
import FindIdModal from '@containers/FindId';
import FindPasswordModal from '@containers/FindPassword';
import SelectUserTypeModal from '@containers/SignUp/SelectUserTypeModal';
import { useFormError } from '@hooks/useFormError';
import { useModal } from '@hooks/useModal';
import { useReduxForm } from '@hooks/useReduxForm';
import { useRequest } from '@hooks/useRequest';
import {
  $mixtapeGetUser,
  $mixtapeInit,
  $mixtapeSetUser,
  $mixtapeUserSeq,
  getAccessToken,
  removeAuth,
  setAuth,
} from '@utils/auth';
import { getDisabledWithFormError } from '@utils/data';
import history from '@utils/history';
import crypto from 'crypto';
import { Stack } from '@mui/material';
import RemindPwModal from './component/RemindPwModal';
import moment from 'moment';
import MainHeader from '@components/molecules/MainHeader';

// @ts-ignore
function Login({ setCurrentStep }): ReactElement {
  const { ctf_sendHttpGet, ctf_sendHttpPost } = useContext(BackdropContext);

  const { openState: uc_open, setOpenState: uf_setOpen, message: uc_message, popupMessage: uf_popupMessage } = usePopupMessage();

  const [formErrorNetwork, setFormErrorNetwork] = useState<any>(null);

  const { isShown: isOpenSignUpModal, toggle: setIsOpenSignUpModal } = useModal();
  const { isShown: isOpenFindIdModal, toggle: setIsOpenFindIdModal } = useModal();
  const { isShown: isOpenFindPasswordModal, toggle: setIsOpenFindPasswordModal } = useModal();
  const { isShown: isOpenDormantAccountModal, toggle: setIsOpenDormantAccountModal } = useModal();
  const { isShown: isOpenChangePasswordModal, toggle: setIsOpenChangePasswordModal } = useModal();
  const { isShown: isOpenRemindPwModal, toggle: setIsOpenRemindPwModal } = useModal();

  const { request } = useRequest({
    reduxActionName: AUTH_LOGIN_ACTION,
    mapperFunc: login,
  });

  const { formData, formFieldsError, validation, setFormData } = useReduxForm(AUTH_LOGIN_ACTION, (params) => request(params));
  const { formError, checkFormError } = useFormError({
    formFieldsError,
    validation,
  });

  const goSignUp = (event: any) => {
    setIsOpenSignUpModal();
  };

  const goFindLoginId = (event: any) => {
    event.preventDefault();
    setIsOpenFindIdModal();
  };

  const goFindPassword = (event: any) => {
    event.preventDefault();
    setIsOpenFindPasswordModal();
  };

  const setLoginFormData = (e: any, dataIndex: string) => {
    const { value } = e.target;
    setFormData(dataIndex, value);
    checkFormError(dataIndex, value, formData);
    if (setFormErrorNetwork) {
      setFormErrorNetwork(null);
    }
  };

  const getIsDisabled = () => {
    const isDisabled = getDisabledWithFormError(formError, ['user_id', 'password']);
    return isDisabled;
  };

  ///
  /// 페이지 첫 접근시 처리하는 로직
  ///
  useEffect(() => {
    if (getAccessToken()) {
      const userInfo = $mixtapeGetUser();

      if (userInfo.is_membership === 0) {
        // 가입이 안되어있으면 멤버십가입페이지로 이동
        setCurrentStep('MEMBERSHIP');
      } else {
        const membershipCompleted = !!userInfo.account_holder && !!userInfo.account_number && !!userInfo.user_rrn;
        if (membershipCompleted === true) {
          history.push(routePath.album.albumManagement.path);
        } else {
          // 멤버쉽에서 필수정보 미등록 상태이면 가입완료 페이지로...
          history.push(routePath.paymentCompleted.path);
        }
      }
    }
  }, []);

  const processLogin = async () => {
    // 로그인전에 무조건 초기화
    $mixtapeInit();

    const params = formData;
    const res = await ctf_sendHttpPost(`/sign-in`, params);

    if (res.error) {
      if (res.data?.code == '400') {
        uf_popupMessage(res.data.message);
      } else {
        uf_popupMessage(`로그인을 실패하였습니다\n${res.errorMsg}`);
      }
      return;
    }

    // AUTH저장 -> AUTH에는 저것만 저장한다.
    setAuth({
      access_token: res.data.token,
      useSeq: res.data.user.seq,
    });
    // 전역변수에 user정보 저장한다.
    $mixtapeSetUser(res.data.user);

    if (res.data.user.password_change) {
      setIsOpenRemindPwModal();
      return;
    }

    // 로그인 후처리
    goAfterLogin();
  };

  ///
  /// 서버에서 로그인 처리 결과가 정상으로되면 그 다음 이동할 곳으로 간다.
  ///
  const goAfterLogin = () => {
    const userInfo = $mixtapeGetUser();
    triggerChannelTalkEvent();

    const membershipCompleted = !!userInfo.account_holder && !!userInfo.account_number && !!userInfo.user_rrn;

    /// 멤버십 가입한 회원이면 앨범메뉴로 이동
    if (userInfo.is_membership === 1) {
      if (membershipCompleted === true) {
        history.push(routePath.album.albumManagement.path);
      } else {
        // 멤버쉽에서 필수정보 미등록 상태이면 가입완료 페이지로...
        history.push(routePath.paymentCompleted.path);
      }
    } else {
      setCurrentStep('MEMBERSHIP');
    }
  };

  const changePw = () => {
    removeAuth();
    setIsOpenRemindPwModal();
    setIsOpenFindPasswordModal();
  };

  const goNextTime = () => {
    setIsOpenRemindPwModal();
    setNextTime();
    goAfterLogin();
  };

  const { ctf_sendHttpPatch } = useContext(BackdropContext);

  const setNextTime = () => {
    const res = ctf_sendHttpPatch(`/user/${$mixtapeGetUser().seq}`, { password_at: moment().format('YYYY-MM-DD HH:mm:ss') });

    if (res.data) {
      $mixtapeSetUser(res.data);
    }
  };

  const triggerChannelTalkEvent = () => {
    // 채널톡용 해싱처리 키
    const secretKey = '7013e654052114d4e6dbd6077a44144c50a8487e55ab2daf608bbf5af191e898';

    const userInfo = $mixtapeGetUser();
    //const channelTalkHash = sha256.hmac(userInfo.user_id, Buffer.from(secretKey, "hex")); //hash_hmac('sha256', $userId, pack("H*", $secretKey));

    const channelTalkHash = crypto.createHmac('sha256', Buffer.from(secretKey, 'hex')).update(userInfo.user_id).digest('hex');
    const channelTalkParams = {
      profile: {
        name: userInfo.name,
        email: userInfo.email,
        mobileNumber: userInfo.phone_number,
        CUSTOM_VALUE_1: userInfo.is_membership === 1,
      },
      memberId: userInfo.user_id,
      memberHash: channelTalkHash,
    };
    // 채널톡용 이벤트
    const channleTalkEvent = new CustomEvent('channelTalkLoggedIn', { detail: channelTalkParams });
    window.dispatchEvent(channleTalkEvent);
  };

  ///
  /// 엔터키를 누르면 로그인한다.
  ///
  const processEnterKey = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      processLogin();
    }
  };

  const { isShown: isOpenAddMusicVedioModal, toggle: setIsOpenAddMusicVedioModal } = useModal();

  return (
    <>
      <MainHeader logo="logoW" />
      {/* <AuthHeader logo="logoW" /> */}
      <LoginLayout>
        <Form autocomplete="off">
          <Text textType="TITLE" style={{ marginBottom: '46px' }}>
            로그인
          </Text>

          <Label htmlFor="user_id" style={{ marginBottom: '12px' }}>
            아이디
          </Label>
          <TTextField
            id="user_id"
            autoFocus
            onChange={(e: ChangeEvent) => {
              setLoginFormData(e, 'user_id');
            }}
            placeholder="아이디"
            type="text"
            value={formData.user_id ?? ''}
          />
          <ErrorMessage formError={formError.user_id} />

          <HDivider margin="13px" type="TRANSPARENT" />

          <Label htmlFor="password">비밀번호</Label>
          <HDivider margin="6px" type="TRANSPARENT" />
          <TTextField
            id="password"
            onChange={(e: ChangeEvent) => {
              setLoginFormData(e, 'password');
            }}
            //@ts-ignore
            onKeyDown={processEnterKey}
            placeholder="비밀번호"
            type="password"
            value={formData.password ?? ''}
          />
          <ErrorMessage formError={formError.password} />

          <HDivider margin="8px" type="TRANSPARENT" />

          {/* [TODO] 로그인 상태유지 정책확인후 구현할것 */}
          {/* <Checkbox */}
          {/*   checked={isLoginStatus} */}
          {/*   id="checkbox_labeled" */}
          {/*   label="로그인 상태 유지" */}
          {/*   onChange={(e) => { */}
          {/*     setIsLoginStatus(e.target.checked); */}
          {/*   }} */}
          {/* /> */}
          {formErrorNetwork ? (
            <>
              <Row justify="CENTER">{formErrorNetwork && <ErrorMessage message={formErrorNetwork} />}</Row>
              <HDivider margin="12px" type="TRANSPARENT" />
            </>
          ) : (
            <>{/* <HDivider margin="24px" type="TRANSPARENT" /> */}</>
          )}

          <Row justify="FLEX_END" style={{ marginBottom: '40px' }}>
            <FindButton style={{ height: '16px' }} onClick={goFindLoginId}>
              아이디 찾기
            </FindButton>
            <VDivider height="12px" margin="8px" type="GRAY" />
            <FindButton style={{ height: '16px' }} onClick={goFindPassword}>
              비밀번호 찾기
            </FindButton>
            {/* <FindButton onClick={()=>{setIsOpenAddMusicVedioModal(true)}}>찾기</FindButton> */}
          </Row>
          <LoginButton disabled={getIsDisabled()} onClick={processLogin}>
            로그인
          </LoginButton>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            color={'#767676'}
            marginY={'40px'}
            sx={{
              '::before': {
                display: 'inline-block',
                content: '""',
                flex: 1,
                height: '1px',
                margin: '0 10px',
                background: '#767676',
              },
              '::after': {
                display: 'inline-block',
                content: '""',
                flex: 1,
                height: '1px',
                margin: '0 10px',
                background: '#767676',
              },
            }}
          >
            믹스테이프가 처음이신가요?
          </Stack>
          <SignUpButton disabled={getIsDisabled()} onClick={goSignUp}>
            회원가입
          </SignUpButton>
        </Form>
      </LoginLayout>

      <AlertDialog isPopupOpen={uc_open} alertText={uc_message} hidePopup={() => uf_setOpen(false)} />
      <MusicVideoAddDialog
        open={isOpenAddMusicVedioModal}
        openHanlder={setIsOpenAddMusicVedioModal}
        callback={(record) => {
          alert(JSON.stringify(record));
        }}
      />
      <SelectUserTypeModal isOpenSignUpModal={isOpenSignUpModal} setIsOpenSignUpModal={setIsOpenSignUpModal} />
      <FindIdModal
        isOpenFindIdModal={isOpenFindIdModal}
        setIsOpenFindIdModal={setIsOpenFindIdModal}
        setIsOpenFindPasswordModal={setIsOpenFindPasswordModal}
      />
      <FindPasswordModal
        isOpenFindPasswordModal={isOpenFindPasswordModal}
        setIsOpenFindPasswordModal={setIsOpenFindPasswordModal}
      />
      <DormantAccountModal
        isOpenDormantAccountModal={isOpenDormantAccountModal}
        setIsOpenDormantAccountModal={setIsOpenDormantAccountModal}
      />
      <ChangePasswordModal
        isOpenChangePasswordModal={isOpenChangePasswordModal}
        setIsOpenChangePasswordModal={setIsOpenChangePasswordModal}
        setIsOpenFindPasswordModal={setIsOpenFindPasswordModal}
      />
      <RemindPwModal
        isOpenRemindPwModal={isOpenRemindPwModal}
        changePw={changePw}
        goNextTime={goNextTime}
        // login={login}
        setIsOpenRemindPwModal={setIsOpenRemindPwModal}
        // setIsOpenFindPasswordModal={setIsOpenFindPasswordModal}
      />
    </>
  );
}

export default Login;
