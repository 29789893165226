import HDivider from '@components/form/HDivider';
import {
  AlbumProgressActiveDateText,
  AlbumProgressActiveText,
  AlbumProgressUnActiveDateText,
  AlbumProgressUnActiveText,
} from '@components/molecules/AlbumManagement/Text';
import { Skeleton } from '@mui/material';
import date from 'date-and-time';
import React from 'react';
import Row from '@components/form/Row';
import { Icon } from '@images/Icon';

const AlbumStepper = ({ record }: any) => {
  const getPassedIcon = () => {
    return (
      <>
        <HDivider
          style={{
            marginTop: '2px',
            position: 'relative',
          }}
          type="CONFIRMED"
        />
        <Icon
          icon="confirmedCheck"
          style={{
            width: '19px',
            height: '19px',
            position: 'relative',

            bottom: '6.5px',
          }}
        />
      </>
    );
  };

  const getWaitingIcon = () => {
    return (
      <>
        <HDivider
          style={{
            marginTop: '2px',
            position: 'relative',
          }}
          type="UNCONFIRMED"
        />
        <Icon
          icon="unconfirmedCheck"
          style={{
            width: '11px',
            height: '11px',
            position: 'relative',

            bottom: '3px',
          }}
        />
      </>
    );
  };

  const getStep3Node = () => {
    if (record.release_state) {
      return getPassedIcon();
    } else {
      return getWaitingIcon();
    }
  };

  const getState = () => {
    switch (record.state) {
      case 'approve_wait':
        return '발매 대기 중';

      case 'approve': // 담장자 승인 완료 후 발매일 전까지 상태
        return '검수 완료';
      case 'release': // 담장자 승인 완료 후 발매일 전까지 상태
        return'발매 완료';

			case 'reject':
				return '자료 확인 요청';

			case 'update_approve_wait':
				return '수정 검토 중';

			case 'update_reject':
				return '자료 확인 요청';

			case 'update_approve':
				return '수정 완료';

      default:
        return '수정 완료'
    }
    // switch (record.state) {
    //   case 'approve_wait':
    //   case 'approve':
    //     return '발매 대기중!!!!!!!!!';
    //   case 'reject':
    //     return '자료 확인 요청';
    //   case 'update_approve_wait':
    //     return '수정 검토 중';
    //   case 'update_reject':
    //     return '자료 확인 요청';
    //   case 'update_approve':
    //     return '수정 완료';
    //   default:
    //     return '발매 대기';
    // }
  };

  ///
  /// 단계별 라벨 텍스트 묶음을 가져온다.
  ///
  function getLabelNodes() {
    return (
      <Row justify="FLEX_START" style={{ position: 'relative' }}>
        <AlbumProgressActiveText
          style={{
            position: 'absolute',
            right: '-190px',
            top: '0px',
            width: '120px',
          }}
        >
          발매 신청
        </AlbumProgressActiveText>

        <AlbumProgressActiveText
          style={{
            position: 'absolute',
            right: '-390px',
            top: '0px',
            width: '120px',
          }}
        >
          {getState()}
        </AlbumProgressActiveText>

        {record.release_state ? (
          <AlbumProgressActiveText
            style={{
              position: 'absolute',
              right: '-585px',
              top: '0px',
              width: '120px',
            }}
          >
            발매 완료
          </AlbumProgressActiveText>
        ) : (
          <AlbumProgressUnActiveText
            style={{
              position: 'absolute',
              right: '-585px',
              top: '0px',
              width: '120px',
            }}
          >
            앨범 발매
          </AlbumProgressUnActiveText>
        )}
      </Row>
    );
  }

  function getDateNodes() {
    return (
      <Row justify="FLEX_START" style={{ position: 'relative' }}>
        <AlbumProgressActiveDateText
          style={{
            position: 'absolute',
            right: '-190px',
            top: '20px',
            width: '120px',
          }}
        >
          신청일 {record.created_at ? date.format(new Date(record.created_at), 'YYYY.MM.DD') : '-'}
        </AlbumProgressActiveDateText>

        <AlbumProgressUnActiveDateText
          style={{
            position: 'absolute',
            right: '-585px',
            top: '20px',
            width: '180px',
          }}
        >
          발매일 {date.format(new Date(record.release_date), 'YYYY.MM.DD')}
        </AlbumProgressUnActiveDateText>
      </Row>
    );
  }

  return (
    <Row direction="column" justify="FLEX_START">
      <Row justify="FLEX_START">
        <HDivider style={{ position: 'absolute' }} />
        {record && record.state ? (
          <>
            {getPassedIcon()}
            {getPassedIcon()}
            {getStep3Node()}
          </>
        ) : (
          <Skeleton variant="rounded" sx={{ bgcolor: '#444' }} width={600} height={25} />
        )}
      </Row>
      <HDivider margin="4px" type="TRANSPARENT" />
      {/* 단계별 라벨텍스트 표시 */}
      {record && record.state ? (
        <>{getLabelNodes()}</>
      ) : (
        <Skeleton variant="rounded" sx={{ bgcolor: '#444' }} width={600} height={50} />
      )}

      <HDivider margin="3px" type="TRANSPARENT" />
      {/*   단계별 일자 표시 */}
      {record && record.state ? (
        <>{getDateNodes()}</>
      ) : (
        <Skeleton variant="rounded" sx={{ bgcolor: '#444' }} width={600} height={20} />
      )}
    </Row>
  );
};

export default AlbumStepper;
