import Button from '@components/form/Button';
import InputSuffix from '@components/form/InputSuffix';
import { RegisterButton } from '@components/molecules/DormantAccount/Button';
import { BackdropContext } from '@hooks/usePopupMessage';
import { Icon } from '@images/Icon';
import { noImage } from '@images/png';
import { Badge, BadgeProps, Stack, styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { $mixtapeUserSeq } from '@utils/auth';
import React, { useContext, useEffect, useState } from 'react';

import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { MacScrollbar } from 'mac-scrollbar';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 8,
    left: 18,
    padding: '4px',
    fontSize: '4px',
  },
}));
//@ts-ignore
function S001({ setCurStep, setResult, setIsOpenArtistAddModal }) {
  const userSeq = $mixtapeUserSeq();
  const columns = [
    {
      field: 'artist_image',
      headerName: '이미지',
      headerAlign: 'center',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        let source = '';
        let invisible = true;

        if (!params) {
          return source;
        }
        // [todo] 유저시퀀스가오면 내 프로필 뱃지 보여줄지 여부 결정
        invisible = params.row.profile_user !== userSeq;

        if (params?.value && params.value.length > 0) {
          source = params.value[0].image_url;
          // invisible = params.value[0].user_seq === userSeq
        } else {
          source = noImage;
        }

        return (
          <StyledBadge
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            color="primary"
            badgeContent="내 프로필"
            invisible={invisible}
          >
            <img src={source} height="60px" />
          </StyledBadge>
        );
      },
    },
    {
      field: 'artist_name',
      headerName: '아티스트명',
      headerAlign: 'center',
      width: 250,
      align: 'center',
    },

    {
      field: 'gender_name',
      headerName: '성별',
      headerAlign: 'center',
      align: 'center',
      width: 100,
    },
    {
      field: 'id',
      headerName: '코드',
      headerAlign: 'center',
      align: 'center',
      width: 100,
    },

    {
      field: 'last_album',
      headerName: '최근발매・참여앨범',
      headerAlign: 'center',
      align: 'center',
      width: 180,
      // sortable: false,
      valueGetter: (p: any) => (p.row.last_album === true ? 'O' : 'X'),
    },
    {
      field: 'genres',
      headerName: '장르',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      valueGetter: (p: any) => p.row.genres.map((genre: any) => genre.genre_name).join(', '),
    },
  ];

  useEffect(() => {}, []);

  ///
  /// 그리드의 행을 클릭하면 선택된 레코드가 콜백으로 전달된 결과값 받는 함수를 호출하여 전달한다.
  ///

  const handleRowClick = (params, event) => {
    event.preventDefault();

    if (setResult) {
      setResult({ sender: 'S001', ...params.row });
    }

    setIsOpenArtistAddModal(false);
  };

  const { ctf_sendHttpGet } = useContext(BackdropContext);

  const [records, setRecords] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [resultMessage, setResultMessage] = useState('아티스트명을 입력하여 주세요');

  async function searchData() {
    if (keyword.trim().length === 0) {
      return;
    }

    const params = {
      limit: 20,
      page: 1,
      keyword: keyword,
      list: 'except-reject',
    };

    // TODO: 반려제외 리스트 출력
    const res = await ctf_sendHttpGet('/artists', params);

    if (res.error) {
      setRecords([]);
      setResultMessage(res.errorMsg + '');
    } else {
      if (res.data.data.length === 0) {
        setResultMessage('mixtape.에 등록되지 않은 아티스트입니다.\n신규등록을 하여 주세요.');
      }

      setRecords(res.data.data);
    }
  }

  const pressEnter = (e) => {
    if (e.key === 'Enter') {
      searchData();
    }
  };
  return (
    <MacScrollbar style={{ maxHeight: '800px' }}>
      <Stack direction="column" alignItems="center">
        <Stack direction="row" justifyContent="center" mb="10px">
          <Stack direction="column">
            <InputSuffix
              style={{
                flex: 7,
                width: '580px',
              }}
              placeholder="아티스트 이름 입력"
              onKeyDown={(e) => pressEnter(e)}
              // onKeyPress={(e) => {
              //   if (e.key === 'Enter') {
              //     searchData();
              //   }
              // }}
              onChange={(e) => setKeyword(e.target.value)}
              suffix={
                <Button
                  style={{
                    backgroundColor: 'transparent',
                    borderWidth: '0px',
                    height: '20px',
                  }}
                  onClick={searchData}
                >
                  <Icon
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '20px',
                    }}
                    icon="iconSearch"
                  />
                </Button>
              }
            />
            <Text textType="DESCRIPTION" style={{ marginTop: '10px' }}>
              mixtape.에 정보가 등록된 아티스트 목록입니다.{' '}
            </Text>
            <Text textType="DESCRIPTION"> 검색내역에서 아티스트를 찾을 수 없다면 '신규등록' 버튼을 클릭해 주세요 </Text>
          </Stack>

          <VDivider type="TRANSPARENT" margin="5px"></VDivider>
          <RegisterButton
            onClick={() => {
              setCurStep('S002');
            }}
          >
            신규등록
          </RegisterButton>
        </Stack>

        <div
          style={{
            height: 500,
            width: '100%',
          }}
        >
          <DataGridPro
            rowHeight={70}
            onRowClick={handleRowClick}
            columns={columns}
            rows={records}
            disableColumnMenu
            components={{
              hideFooter: true,
              hideFooterPagination: true,
              Footer: () => <></>,
              NoRowsOverlay: () => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    {/* {resultMessage} */}
                    {resultMessage.split('\n').map((ele) => (
                      <Text>{ele}</Text>
                    ))}
                  </div>
                );
              },
            }}
          />
        </div>
      </Stack>
    </MacScrollbar>
  );
}

export default S001;
