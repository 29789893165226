import MainHeader from '@components/molecules/MainHeader';
import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import MainFooter from '@components/molecules/MainFooter';
import mixtapeLogo from '@images/png/logo_w.png';
import MixSwiper from '@components/ui/MixSwiper';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import history from '@utils/history';
import { $mixtapeGetUser } from '@utils/auth';
import MixLogoSlide from '@components/ui/MixLogoSlide';

const Introduction = () => {
  const userInfo = $mixtapeGetUser();

  const goIntroChannel = () => {
    history.push('/intro/channel');
  };

  const goJoinUs = () => {
    if (userInfo) {
      history.push('/intro/membership');
    } else {
      history.push('/signup');
    }
  };

  const goAccelerating = () => {
    history.push('/promotion/accelerating');
  };

  return (
    <>
      <MainHeader logo="logoW" />
      <Stack direction="column" color="white" justifyContent="center" alignItems="center" paddingY="200px" bgcolor="#1E1E1E">
        {/* 소개 영역 */}
        <Stack direction="column" paddingX="5vw" justifyContent="center" alignItems="center">
          <Typography fontSize="50px" fontWeight="bold" mb="116px">
            믹스테이프 소개
          </Typography>
          <video autoPlay loop muted width="100%" style={{ marginBottom: '116px' }}>
            <source src="http://down.mixtape.so/NAS/mixtape_typograpy.webm" type="video/webm"></source>
          </video>
          <Typography fontSize="65px" lineHeight="76px" mb="57px">
            Sell Your Music
          </Typography>
          <Typography fontSize="250px" fontWeight="bold" lineHeight="295px" mb="136px">
            Worldwide
          </Typography>
          <Typography fontSize="28px" lineHeight="56px" textAlign="center" mb="150px">
            믹스테이프는 뮤지션을 위해 가장 기본적인 것부터 고민한
            <br />
            차세대 글로벌 음원 유통 서비스예요.
            <br />
            단순한 음원 유통 서비스를 넘어, 아티스트
            <br />
            한분 한분에게 더 가치 있는 완성을 제공하고자 노력했어요.
            <br />
            새로운 가치를 찾는 <span style={{ color: '#EFFD60' }}>mixtape.</span>와 함께 다음 세대의 주인공이 되어 주세요.
          </Typography>

          {/* 지구 */}
          <Stack direction="row" width="100%" justifyContent="center" alignItems="center" gap="100px" mb="180px">
            <Stack width="50%" justifyContent="center" alignItems="center">
              <img width="100%" style={{ maxWidth: '632px' }} src="/assets/img/earth.png"></img>
            </Stack>

            <Stack direction="column" width="50%" gap="40px" justifyContent="center">
              <Typography fontSize="18px" lineHeight="32px">
                <span style={{ color: '#EFFD60' }}>mixtape.</span>은 전 세계의 음악 플랫폼들과 직접 소통합니다.
                <br />
                YouTube Music, Spotify, Apple Music 등 전 세계 모든 곳에 당신의 음악을 선보이세요.
              </Typography>
              <Typography fontSize="18px" lineHeight="32px">
                자유롭게 음악을 발매할 수 있는 글로벌 음악 유통 서비스를 준비했어요.
                <br />
                <span style={{ color: '#EFFD60' }}>당신이 원하는 일정에</span> 앨범 발매가 가능합니다.
                <br />더 이상 유통사가 정해놓은 틀에 얽매이지 마세요.
              </Typography>
            </Stack>
          </Stack>

          {/* 로고 */}
          <Stack justifyContent="center" alignItems="center" marginBottom="200px">
            <MixLogoSlide style={{ width: '90vw' }} />

            <Button
              style={{
                flex: 'none',
                width: '230px',
                height: '54px',
                background: '#effd60',
                fontSize: '18px',
                color: 'black',
                textTransform: 'none',
              }}
              onClick={goIntroChannel}
            >
              See All Stores
            </Button>
          </Stack>
        </Stack>

        {/* 유통수수료 */}
        <Stack
          direction="row"
          bgcolor="#C9BB3F"
          width="100%"
          paddingY="200px"
          color="black"
          justifyContent="center"
          alignItems="center"
          style={{ position: 'relative' }}
          overflow="hidden"
        >
          <Stack direction="row" width="100%" justifyContent="center" alignItems="center" paddingX="5vw">
            <Stack direction="column" gap="40px" width="50%" marginLeft="3vw" marginRight="8vw">
              <Typography fontSize="36px" fontWeight="bold" lineHeight="32px">
                유통 수수료 0%
              </Typography>
              <Typography fontSize="18px" lineHeight="32px">
                유료 멤버십에 가입하면, 모든 음원 수익은 100% 아티스트에게 돌아갑니다.
                <br />
                앞으로는 앨범 작업에만 집중하시고, 당신의 음악으로 이룬 소중한 성과를 온전히 누려보세요.
                <br />
                아티스트 커리어의 시작! 믹스테이프와 함께 시작해 보세요.
              </Typography>
            </Stack>
            <Stack width="50%" justifyContent="center" alignItems="center">
              <img src="/assets/img/0_percent.svg" width="100%" style={{ maxWidth: '790px' }}></img>
            </Stack>
          </Stack>
          <img style={{ position: 'absolute', bottom: '-112px' }} width="240px" src="/assets/logo/mixtape_logo.svg"></img>
        </Stack>

        {/* 멤버십 안내 */}
        <Stack direction="row" justifyContent="center" alignItems="center" width="100%" paddingY="200px">
          {/* <Stack direction="row" paddingX="5vw"> */}
          <Stack direction="row" paddingX="5vw" width="100%">
            <Stack width="50%" justifyContent="center" alignItems="center">
              <img width="100%" style={{ maxWidth: '385px' }} src="/assets/img/mp3.png"></img>
            </Stack>

            <Stack direction="column" width="50%" marginLeft="50px" gap="40px" justifyContent="center">
              <Typography fontSize="36px" fontWeight="bold" lineHeight="57px">
                유료 멤버십 안내
                <br />
                연간 ₩66,000 ~ (부가세 포함)
              </Typography>
              <Typography fontSize="18px" lineHeight="32px">
                한 달 5,500원 가격으로 유통 수수료 없이 <br />전 세계 음악 플랫폼에 자유롭게 앨범을 발매할 수 있어요.
              </Typography>
              <Typography fontSize="18px" lineHeight="32px" marginBottom="20px">
                • 유통 수수료 0% <br />• 자유로운 발매 일정 선택 <br />• Youtube Music, Spotify, Apple Music, Melon, VIBE 등 전
                세계 앨범 발매 <br />• Instagram, Facebook, TikTok 등 소셜미디어 채널 앨범 발매
              </Typography>
              <Button
                style={{
                  flex: 'none',
                  width: '230px',
                  height: '54px',
                  background: '#effd60',
                  fontSize: '18px',
                  color: 'black',
                  textTransform: 'none',
                }}
                onClick={goJoinUs}
              >
                Join Us
              </Button>
            </Stack>
          </Stack>
        </Stack>

        <img src={mixtapeLogo} width="100%" style={{ maxWidth: '269px', marginBottom: '200px' }}></img>

        {/* 엑셀러레이팅 */}
        {/* <Stack width="100%" gap="110px">
          <MixSwiper
            slidesPerView={4}
            centeredSlides={false}
            spaceBetween={16}
            isDarkMode
            style={{ width: '100%', height: '100%' }}
          >
            <SwiperSlide>
              <Stack position="relative">
                <img src="/assets/img/accelerating/6_1_test.jpg"></img>
                <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'black', opacity: 0.37 }}></div>
                <div style={{ position: 'absolute', top: '20px', left: '20px', width: `calc(100% - 40px)` }}>
                  <Typography>6월 아티스트 : 에레미레</Typography>
                  <hr style={{ height: '2px', background: 'white' }} />
                </div>
              </Stack>
            </SwiperSlide>
            <SwiperSlide>
              <Stack position="relative">
                <img src="/assets/img/accelerating/6_2.jpg"></img>
                <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'black', opacity: 0.37 }}></div>
                <div style={{ position: 'absolute', top: '20px', left: '20px', width: `calc(100% - 40px)` }}>
                  <Typography>6월 아티스트 : CREEP</Typography>
                  <hr style={{ height: '2px', background: 'white' }} />
                </div>
              </Stack>
            </SwiperSlide>
            <SwiperSlide>
              <Stack position="relative">
                <img src="/assets/img/accelerating/6_3.jpg"></img>
                <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'black', opacity: 0.37 }}></div>
                <div style={{ position: 'absolute', top: '20px', left: '20px', width: `calc(100% - 40px)` }}>
                  <Typography>6월 아티스트 : 킴다(KIMDA)</Typography>
                  <hr style={{ height: '2px', background: 'white' }} />
                </div>
              </Stack>
            </SwiperSlide>
            <SwiperSlide>
              <Stack position="relative">
                <img src="/assets/img/accelerating/6_4_test.jpg"></img>
                <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'black', opacity: 0.37 }}></div>
                <div style={{ position: 'absolute', top: '20px', left: '20px', width: `calc(100% - 40px)` }}>
                  <Typography>6월 아티스트 : MaddJin</Typography>
                  <hr style={{ height: '2px', background: 'white' }} />
                </div>
              </Stack>
            </SwiperSlide>
            <SwiperSlide>
              <Stack position="relative">
                <img src="/assets/img/accelerating/6_5.jpg"></img>
                <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'black', opacity: 0.37 }}></div>
                <div style={{ position: 'absolute', top: '20px', left: '20px', width: `calc(100% - 40px)` }}>
                  <Typography>6월 아티스트 : Demian Love</Typography>
                  <hr style={{ height: '2px', background: 'white' }} />
                </div>
              </Stack>
            </SwiperSlide>
          </MixSwiper>

          <Stack direction="column" width="100%" justifyContent="center" alignItems="center" textAlign="center" gap="40px">
            <Typography fontSize="36px" fontWeight="bold" lineHeight="32px">
              엑셀러레이팅 프로그램
            </Typography>
            <Typography fontWeight="18px" lineHeight="32px">
              <span style={{ textDecoration: 'underline' }}>“아티스트 한 분 한 분에게 새로운 가치를 전달하자.”</span>
              <br />
              그래서 힙합엘이, 바운드 그리고 뮤직허브와 함께 “엑셀러레이팅 프로그램 :: 시즌1”을 준비했어요. <br />
              앞으로 매달 아티스트를 선정하여, YG PLUS의 음원 유통 역량을 개별 아티스트에게 집중 지원할 예정입니다. <br />
              선정팀은 온라인, 오프라인 마케팅 혜택을 누리며, 후속 케어를 받으면서 추가 기회를 얻을 수 있습니다.
            </Typography>
            <Button
              style={{
                flex: 'none',
                width: '230px',
                height: '54px',
                background: '#effd60',
                fontSize: '18px',
                color: 'black',
                textTransform: 'none',
              }}
              onClick={goAccelerating}
            >
              View More
            </Button>
          </Stack>
        </Stack> */}
      </Stack>
      <MainFooter />
    </>
  );
};
4;
export default Introduction;
