import { useInjectReducer } from '@hooks/useInjectReducer';
import { isEmpty } from '@utils/data';
import { LOCATION_CHANGE } from 'connected-react-router';
import produce from 'immer';
import { Reducer } from 'redux';

import getActionTypes from './getActionTypes';
import getLifeTimeKeys from './getLifeTimeKeys';

export default function getReducer(reduxActionName: string): Reducer {
  const {
    SET_PARAMETERS,
    SET_REQ_PARAMETERS,
    SET_DATA,
    REQUEST_READY,
    REQUEST_START,
    REQUEST_SUCCEEDED,
    REQUEST_FAILED,
    CLEAR_DATA,
  } = getActionTypes(reduxActionName);
  const initialState = {};
  const reducer = (state = initialState, action: any) => {
    const result = produce(state, (draft: any): any => {
      switch (action.type) {
        case CLEAR_DATA:
          draft.parameters = undefined;
          draft.data = undefined;
          break;
        case SET_PARAMETERS:

          draft.parameters = isEmpty(action.parameters) ? {} : { ...(draft.parameters ?? {}), ...action.parameters };
          break;
        case SET_REQ_PARAMETERS:
          draft.reqParameters = isEmpty(action.reqParameters) ? {} : { ...(draft.reqParameters ?? {}), ...action.reqParameters };
          break;
        case SET_DATA:
          draft.data = isEmpty(action.data) ? {} : { ...(draft.data ?? {}), ...action.data };
          break;
        case LOCATION_CHANGE: {
          draft.check = false;
          const lifeTimes = getLifeTimeKeys(reduxActionName);
          if (lifeTimes?.includes('*')) {
            draft.check = true;
          }
          // 등록한 routes인지 검사
          lifeTimes.forEach((route: any) => {
            const { pathname } = action.payload.location;
            const param = pathname.slice(pathname.lastIndexOf('/') + 1);
            if (pathname === (route.includes(':id') ? route.replace(':id', param) : route)) {
              draft.check = true;
            }
          });
          // 검사결과 등록된 routes가 아니면 filter옵션과 formData 초기화
          if (!draft.check) {
            draft.parameters = undefined;
            draft.data = undefined;
          }
          break;
        }
        case REQUEST_READY:
          draft.requestLoading = false;
          draft.requestSuccess = false;
          draft.requestError = false;
          draft.requestErrorMessage = '';
          draft.requestErrorData = undefined;
          break;
        case REQUEST_START:
          draft.requestLoading = true;
          draft.requestError = false;
          draft.requestSuccess = false;
          break;
        case REQUEST_SUCCEEDED:
          draft.requestLoading = false;
          draft.requestError = false;
          draft.requestSuccess = true;
          draft.data = action.data;
          break;
        case REQUEST_FAILED:
          draft.requestLoading = false;
          draft.requestError = true;
          draft.requestSuccess = false;
          draft.requestErrorMessage = action.errorMessage;
          draft.requestErrorData = action.error;
          break;
      }
    });

    return result;
  };

  return reducer;
}

export const useInjectResourceControllerReducer = (reduxActionName: string): any => {
  const reducer = getReducer(reduxActionName);
  useInjectReducer({ key: reduxActionName, reducer });
};
