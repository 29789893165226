import Button from '@components/form/Button';
import styled from '@emotion/styled';

export const EditButton = styled(Button)`
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-decoration-line: underline;

  /* color/bcbcbc */

  color: #bcbcbc;

  background: transparent;
  height: auto;
`;

export const EditButtonModal = styled(Button)`
  height: 52px;
  border-radius: 10px;
  padding: 17px 50px;
  background: #121212;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
`;

export const AuthDormantAccountButton = styled(Button)`
  height: 50px;
  border-radius: 10px;
  padding: 17px 26px 16px;
  background: #121212;
  font-family: Pretendard;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #fff;

  &:disabled {
    background: #fff;
    color: #000;
    border: 1px solid #dedede;
    cursor: not-allowed;
  }
`;
